<div class="input-component-holder container-fluid ovi-font-family" style="height: 100%;">
    <mat-toolbar class="input-header block-1">
        <div i18n *ngIf="!this.modify" class="header-name">Create Ticket Request </div>
        <div i18n *ngIf="this.modify" class="header-name">Modify Ticket Request </div>
        <div class="header-close"><button mat-icon-button mat-dialog-close close-button class="ie-close-icon">
                <mat-icon class="icon-style">highlight_off</mat-icon>
            </button></div>
    </mat-toolbar>
    <div class="popup-margin" style="height:calc(100% - 100px);width: calc(100% - 16px);overflow: auto;">
        <div class="form-row sub-head">
            <div style="display: flex;">
                <div matLine class="entity-title" *ngIf="entityMetaData['entityId'] !== null">
                    <div i18n>Asset Serial Number :</div>
                    {{entityMetaData['entityDetail']['assetSerialNumber']}}
                </div>
                <div matLine class="entity-title" *ngIf="entityMetaData['entityId'] !== null" style="padding-left: 20px;">
                    <div i18n>Asset Name :</div>
                    {{entityMetaData['entityDetail']['assetName']}}
                </div>
                <div matLine class="entity-title" style="padding-left: 20px;" *ngIf="this.modify">
                    <div i18n>Ticket.No:</div>
                    {{entityMetaData['data']['requestIdentifier']}}
                </div>
            </div>
        </div>
        <div class="mat-custom-form-field-box act-content" [formGroup]="ticketRequestForm">
            <div class="form-row block-3">
                <div class="row-field-1">
                    <mat-label i18n class="ovi-lbl-text-size">Title <span class="mandatory-field">*</span></mat-label>
                    <!-- <div style="color: red; display: inline-block;">*</div> -->
                    <mat-form-field class="example-full-width form-field" appearance="outline">
                        <input i18n-placeholder matInput placeholder="Title" formControlName="title">
                        <mat-error *ngIf="ticketRequestForm.controls.title.invalid">
                            <span i18n [hidden]="!ticketRequestForm.controls['title'].errors.required">
                                Title is required.</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row-field-1">
                    <mat-label i18n class="ovi-lbl-text-size">Summary</mat-label>
                    <mat-form-field class="example-full-width form-field" appearance="outline">
                        <textarea i18n-placeholder input matInput placeholder="Summary" rows=2
                            formControlName="remarks"></textarea>
                        <mat-error *ngIf="ticketRequestForm.controls.remarks.invalid">
                            <span i18n [hidden]="!ticketRequestForm.controls['remarks'].errors.maxlength">Maximmum
                                allowed characters are 50 </span>
                            <span i18n [hidden]="!ticketRequestForm.controls['remarks'].errors.pattern">
                                Please enter only number and character
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row-field-1">
                    <mat-label i18n class="ovi-lbl-text-size">Status</mat-label>
                    <mat-form-field class="example-full-width form-field" appearance="outline">
                        <mat-select i18n-placeholder placeholder="Status" formControlName="statusId">
                            <mat-option *ngFor="let request of ticketRequestStatus" [value]="request.code">
                                {{request.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row-field-1">
                    <mat-label i18n class="ovi-lbl-text-size">Assigned Type</mat-label>
                    <mat-form-field class="example-full-width form-field" appearance="outline">
                        <mat-select i18n-placeholder placeholder="Assigned Type"
                         formControlName="assignedType" (selectionChange)="getAssignedType($event.value)">
                            <mat-option *ngFor="let type of assignedStatusType" [value]="type.code">
                                {{type.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row-field-1">
                    <mat-label i18n class="ovi-lbl-text-size">Assigned To <span class="mandatory-field">*</span></mat-label>
                    <!-- <div style="color: red; display: inline-block;">*</div> -->
                    <mat-form-field class="example-full-width form-field" appearance="outline">
                        <input i18n-placeholder matInput placeholder="Assigned To" appTypeHit
                            (typeHit)="getSearchUser($event)" [matAutocomplete]="auto" autocomplete="off"
                            formControlName="isAssignedTo">
                        <mat-autocomplete
                            [displayWith]="this.userEnabled === true ? getUserList.bind(UserNameList) : ''"
                            #auto="matAutocomplete">
                            <mat-option *ngFor="let option of UserNameList" [value]="option.id"
                                (click)="getuserDetails(option,$event)">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="ticketRequestForm.controls.isAssignedTo.invalid">
                            <span i18n [hidden]="!ticketRequestForm.controls['isAssignedTo'].errors.required">Assigned To is
                                required.</span>
                            <span i18n [hidden]="!ticketRequestForm.controls['isAssignedTo'].errors.requireMatch">Please
                                select value
                                from the option</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row-field-1">
                    <mat-label i18n class="ovi-lbl-text-size">Comments</mat-label>
                    <mat-form-field class="example-full-width form-field" appearance="outline">
                        <textarea i18n-placeholder input matInput placeholder="Comments" rows=2
                            formControlName="comments"></textarea>
                        <!-- <mat-error *ngIf="ticketRequestForm.controls.comments.invalid">
                            <span i18n [hidden]="!ticketRequestForm.controls['comments'].errors.required">
                                Comments is required.</span>
                        </mat-error> -->
                    </mat-form-field>
                </div>
                <div class="row-field-1" style="display: flex;">
                    <div class="example-form m-top-5" style="margin-top: -30px;">
                        <button i18n class="file-upload-button" [disabled]="ticketRequestForm.controls.isAssignedTo.invalid">
                            <input type="file" multiple #fileInput accept="image/x-png,image/jpeg"
                                class="custom-file-input" id="file3"
                                (change)="handleFileSelect($event); onFileSelect(fileInput);" />
                            Upload File
                        </button>
                        <span i18n class="file-info" *ngIf="!isFileSelected">Please select .jpeg / .png / .pdf / .txt file
                            format only</span>
                        <span class="file-info" *ngIf="isFileSelected">{{fileInfo}}</span>
                    </div>
                </div>
            </div>
        </div>
        <mat-expansion-panel *ngIf="isUploadTable" [expanded]="isuploadExpanded ? true : false">
            <mat-expansion-panel-header style="background: #dfe0e1; height: 45px !important;">
                <mat-panel-title i18n class="ovi-popup-title-text-size top-styl">
                    upload files
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="ast-styl-1" style="margin: 3px;">
                <mat-table #table [dataSource]="ticketUploadDataSource" class="mat mat-tbl-styl">

                    <ng-container matColumnDef="type">
                        <mat-header-cell i18n *matHeaderCellDef class="mat-sort-header-button mat-tbl-styl_1">
                            Uploader Name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="tbl-width">
                            {{element.userName}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <mat-header-cell i18n *matHeaderCellDef class="mat-sort-header-button mat-tbl-styl_1">
                            File Name </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{element.imageSize}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="preview">
                        <mat-header-cell i18n *matHeaderCellDef class="mat-sort-header-button mat-tbl-styl_1">
                            Image
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <!-- <button i18n mat-button appDebounceClick
                                style="border-radius: 3px;background:#f33c3c;color:white;"
                                (debounceClick)="getFileDownload(element)" [debounceTime]="300" cdkFocusInitial> Preview
                            </button> -->
                            <img *ngIf="element.fileType !='application/pdf'" [src]="element.image" alt="image"
                                style="border: 0.5px solid grey; height: 35px; width: 30%;">
                            <img *ngIf="element.fileType ==='application/pdf'" src="/assets/Alert/common_icons/pdf_icon.png" alt="image"
                                style="border: 0.5px solid grey; height: 35px; width: 30px;">
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <mat-header-cell i18n *matHeaderCellDef class="mat-sort-header-button mat-tbl-styl_1">
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <mat-icon class="icon-style"  appDebounceClick  style="color: #fb2222;cursor: pointer;"
                            (debounceClick)="delete(element)" [debounceTime]="300">highlight_off</mat-icon>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="ticketUploadColumns">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: ticketUploadColumns;" (click)="selection.toggle(row)">
                    </mat-row>
                </mat-table>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="this.modify" [expanded]="isPreviousExpanded ? true : false">
            <mat-expansion-panel-header>
                <mat-panel-title i18n class="ovi-popup-title-text-size top-styl">
                    HISTORY
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="form-row" style="padding-top: 10px;">
                <div i18n class="ovi-popup-title-text-size top-styl" style="font-weight: 600;">COMMENTS</div>
            </div>
            <mat-divider class="divider"></mat-divider>
            <app-status-tracking [statusDetails]="tktHistoryData" [attachment]="attachmentData"
                [trackLineData]="this.tktHistoryData['key'][this.tktHistoryData['key'].length-1]"></app-status-tracking>
        </mat-expansion-panel>
    </div>
    <div #footer style="height: 49px;">
        <button *ngIf="!this.modify" i18n mat-button class="active-btn" style="margin: 5px;float: right;"
            [debounceTime]="500" [disabled]="ticketRequestForm.invalid" appDebounceClick
            (debounceClick)="SaveRequest()">Send Request</button>
        <button *ngIf="this.modify" i18n mat-button class="active-btn" style="margin: 5px;float: right;"
            [debounceTime]="500" [disabled]="ticketRequestForm.invalid" appDebounceClick
            (debounceClick)="UpdateRequest(entityMetaData.requestId)">Update Ticket</button>
        <button i18n mat-button class="cancle-btn" style="margin: 5px;float: right;" mat-dialog-close>Cancel</button>
    </div>
</div>