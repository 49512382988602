import { Component, OnInit,  ViewEncapsulation, Inject, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { ConfigurationService } from '../../../services';
import { UntypedFormBuilder, UntypedFormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-common-dialog',
    templateUrl: './common-dialog.component.html',
    styleUrls: ['./common-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
  })

  
export class CommonDialogComponent implements OnInit {
  public selectedBlock = null;
  public selectedFloor = null;
  // public tagId = 200000076;
  // public tagType = 'TAT-PA';
  public tagId = null;
  public tagType = null;
  public popupType = '';
  public selectedLoc: any;
  public type = "popup";
  public reqDetail: any = null;
  public reqType: any = null;
  public contextOptions = {'show' : {
    'navbar' : true,
    'navMenu': true,
    'blockSelect': true,
    'floorSelect': true,
    'searchBox' : true,
    'navBlkImg': true,
    'navBlkContent': true,
    'navBlkList': true,
    'filterOption': true,
    'mobileView' : false
  }};
  public maxHeight;
  public content = null;
  public contentDetail = {};
  public performerId = new UntypedFormControl();
  displayedColumns: string[] = ['blockName','fromTime','toTime','duration'];
  dataSourceViewHistory = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  perfomerList: any = [{}];
  perfom: any;
  constructor(public dialog: MatDialog, public thisDialogRef: MatDialogRef<CommonDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  public toastr: ToastrService,  private configurationServices: ConfigurationService,public fb: UntypedFormBuilder,private _dateFormat: DatePipe){
    if(data.hasOwnProperty('content')) {
      this.content = data['content'];
      this.contentDetail = data;
    } else {
      this.contentDetail['content'] = 'floorplan';
    }
  }
  ngOnInit(){
    if(this.contentDetail['content'] == 'history'){
      this.perfomerList = this.data.performer
      if(this.perfomerList.length) {
        this.perfomerList = this.perfomerList.filter(val => val.status == "RQ-CO");
        this.performerId.setValue(this.perfomerList[0]['id']);
      }
      this.poterTrackHistory();
    }
    if(this.data.hasOwnProperty('type') && this.data.type == "track") {
      this.contextOptions["show"]["navbar"] = false;
      this.contextOptions["show"]["filterOption"] = false;
      this.reqDetail = this.data.data;
      this.reqType = this.data.type;
    }
    // console.log(this.data)
    this.maxHeight = window.innerHeight-60;
    // this.selectedBlock = parseInt(this.data.blockId);
    if(this.data.reqType && this.data.reqType == 'porter'){
      this.contextOptions.show.navMenu = false;
      this.popupType = this.data.reqType.toUpperCase();
      this.reqDetail = this.data.reqDetail;
      this.reqType = this.data.reqType;
      this.selectedFloor = this.data.reqDetail.sourceFloorId;
      // porter floor shown first
      if(this.reqDetail['performer'].length) {
        let performerList = this.reqDetail['performer'].filter(val => val.status != 'RQ-RJ' && val.floorId != null)
        if(performerList.length) {
          this.selectedFloor = performerList[0].floorId
        }
      }
    } else{
    this.selectedFloor = parseInt(this.data.floorId);
    this.tagId = this.data.tagSerialNumber;
    if(this.data.type && this.data.type == 'globalSearch'){
      this.reqType = this.data.type;
    }
    if(this.data.tagTypeId != null){
      this.tagType = this.data.tagTypeId;
    } else if(this.data.tagTypeId == null){
      if(this.data.category == 'Asset' || this.data.tagType == 'Asset'){
        this.tagType = 'TAT-AS';
      }
    }
    if(this.data.category != null){
    this.popupType = this.data.category.toUpperCase();
    } else if(this.data.tagType != null){
      this.popupType = this.data.tagType.toUpperCase();
    }
    this.contextOptions['show'].navMenu = true;
    }
    this.selectedLoc = null;
    if (window.innerWidth <= 599) {
      this.contextOptions.show.mobileView = true;
    } else {
      this.contextOptions.show.mobileView = false;
    }
  }
  public formInputUpdatedData(data){
    if (data.statusCode === 1) {
      this.toastr.success('<i>Success</i>', `${data.message}`);
    } else {
      this.toastr.warning('<i>Warning</i>', `${data.message}`);
    }
    this.thisDialogRef.close(data);
  }

  public poterTrackHistory(){
    let actualTime = this._dateFormat.transform(this.data.actualTime, 'yyyy-MM-dd');
    let actualDropTime = this._dateFormat.transform(this.data.actualDropTime, 'yyyy-MM-dd');
    // this.configurationServices.getAllMovementHistory(fromDate, toDate, this.performerId.value,"TAT-PO").subscribe(res =>{
    //   let results = res.results;
    //   this.dataSourceViewHistory = new MatTableDataSource<any>(results);
    //   this.dataSourceViewHistory.paginator = this.paginator;
    // })

    this.configurationServices.getStaffLocations(actualTime, actualDropTime, this.performerId.value).subscribe(res =>{
      if(res.statusCode == 1 || res.results.data.length ) {
        let results = res.results['data']['journey '];
        this.dataSourceViewHistory = new MatTableDataSource<any>(results);
        this.dataSourceViewHistory.paginator = this.paginator;
      }
    })
  }
}
