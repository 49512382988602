export class CreateTicketRequest {
    constructor(
       public type: string,
       public title: string,
       public remarks: string,
       public status: string,
       public isAssignedTo: any,
       public performer: any[] = [],
       public nonPerformer: any[] = [],
       public comments: string,
       public attachFiles: any[] = []
    ) {}
}

export class EditTicketRequest {
    constructor(
       public ticketId: number,
       public type: string,
       public title: string,
       public remarks: string,
       public status: string,
       public isAssignedTo: any,
       public performer: any[] = [],
       public nonPerformer: any[] = [],
       public comments: string,
       public attachFiles: any[] = []
    ) {}
}