/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class HospitalService {
    public env_key = environment.env_key;
    constructor(private apiService: ApiService) { }
    
    // Used in Report >> Asset Report


    // Used in Dashboard Widget >> Floor Plan and Report >> Floor Plan


    // Used in Report >> Patient Report
  

    // Component: Bill
    getBills() {
        if (this.env_key == 'dev') {
            return [
                {
                    'id': 1,
                    'billId': 'BILL000201',
                    'billType': 'Monthly',
                    'toPay': '20000',
                    'dueDate': '16-02-2019',
                    'status': 'Pending'
                },
                {
                    'id': 2,
                    'billId': 'BILL000202',
                    'billType': 'Monthly',
                    'toPay': '5000',
                    'dueDate': '17-02-2019',
                    'status': 'Pending'
                },
                {
                    'id': 3,
                    'billId': 'BILL000203',
                    'billType': 'Monthly',
                    'toPay': '1000',
                    'dueDate': ' - ',
                    'status': 'Closed'
                },
                {
                    'id': 4,
                    'billId': 'BILL000204',
                    'billType': 'Weekly',
                    'toPay': '20000',
                    'dueDate': '19-02-2019',
                    'status': 'Pending'
                },
                {
                    'id': 5,
                    'billId': 'BILL000207',
                    'billType': 'Weekly',
                    'toPay': '8750',
                    'dueDate': '17-02-2019',
                    'status': 'Pending'
                },
                {
                    'id': 6,
                    'billId': 'BILL000208',
                    'billType': 'Monthly',
                    'toPay': '700',
                    'dueDate': ' - ',
                    'status': 'Closed'
                },
                {
                    'id': 7,
                    'billId': 'BILL000209',
                    'billType': 'Monthly',
                    'toPay': '3000',
                    'dueDate': '27-02-2019',
                    'status': 'Pending'
                }
            ];
        } else {
            return [];
        }
    }

    // Component: Import Setting
    importBulkUser(bulkData) {
        return this.apiService.post(environment.base_value.import_bulk_user, bulkData);
    }
    saveImportTag(importTag) {
        return this.apiService.post(environment.base_value.save_import_tag, importTag);
    }
    importBulkEmployee(bulkData) {
        return this.apiService.post(environment.base_value.import_bulk_employee, bulkData);
    }

    // Component: Manage Facility, Patient, Support Ticket, User Mannagement
    getAllCustomers() {
        return this.apiService.get(environment.base_value.get_all_customer);
    }
    getAllCustomerRole(){
        return this.apiService.get(environment.base_value.get_all_customer_role);
    }
    saveCustomerRole(cusData){
        return this.apiService.post(environment.base_value.save_customer_role, cusData);
    }
    // used in both Manage Facility, Support Ticket and User Mannagement
    getCustomerList() {
        return this.apiService.get(environment.base_value.get_customer_list);
    }
    // used in both Manage Facility, Support Ticket and User Mannagement
    getRegionList(cust_id) {
        return this.apiService.get(environment.base_value.get_region_list + '/' + cust_id);
    }
    saveCustomer(customerData) {
        return this.apiService.post(environment.base_value.save_customer, customerData);
    }
    updateCustomer(userData) {
        return this.apiService.put(environment.base_value.update_customer + '/' + userData.id, userData);
    }
    deleteCustomer(custId) {
        return this.apiService.delete(environment.base_value.delete_customer + '/' + custId + '/' + 'true');
    }
    getMultipleImages(id ) {
        return this.apiService.get(environment.base_value.get_multiple_images + '/'+id + '?type=Customer');
    }
    // Component: Patient

    updatePatient(data) {
        return this.apiService.put(environment.base_value.update_patient, data);
        // return this.apiService.put(environment.base_value.update_patient+'/'+data.id, data);
    }

    // Component: Support Ticket, User Mannagement

    // used in both Support Ticket and User Mannagement
    getFacilityList(reg_id) {
        return this.apiService.get(environment.base_value.get_facility_list + '/' + reg_id);
    }
    saveTicket(data) {
        return this.apiService.post(environment.base_value.save_ticket, data);
    }

    // Component: User Management
    getAllUsers(isMyTeamUsers?: boolean, roleCode?: string,name?:string, pageStart?: number, pageSize?: number) {
        let param = "?as=loc";
        let facilityId = localStorage.getItem(btoa('facilityId'))
        if (isMyTeamUsers) {
            return this.apiService.get(environment.base_value.get_user + '?isMyTeamUsers=' + isMyTeamUsers);
        } else if (roleCode !== null && roleCode !== undefined) {
            return this.apiService.get(environment.base_value.get_user + '?roleTypeId=' + roleCode);
        } else {
            param = pageStart != null ? param + '&pageStart=' + pageStart : param;
            param = pageSize != null  ? param + '&pageSize=' + pageSize : param;
            param = name != null  ? param + '&name=' + name : param ;
            param = facilityId != null  ? param + '&facilityId=' + facilityId : param ;
            return this.apiService.get(environment.base_value.get_user_v2 + param); 
                    }              
    }

    getAllUserslist(name?:string,pageStart?: number, pageSize?: number) {
        let facilityId = localStorage.getItem(btoa('facilityId'))
        let param = "?as=loc";
           param = pageStart != null ? param + '&pageStart=' + pageStart : param;
            param = pageSize != null  ? param + '&pageSize=' + pageSize : param;
            param = name != null  ? param + '&name=' + name : param ;
            param = facilityId != null  ? param + '&facilityId=' + facilityId : param ;
        return this.apiService.get(environment.base_value.get_all_user_list  + param );         
    }
    getAllUsersid(id) {   
        return this.apiService.get(environment.base_value.get_user_v2 + '?userId=' + id );
      }
      getAllUsersimage(id) {   
        return this.apiService.get(environment.base_value.get_user_img + '/' + id );
      }
    
      getAllentitydata(name,type) {   
        let facilityId = localStorage.getItem(btoa('facilityId'))
        return this.apiService.get(environment.base_value.get_ALL_fetch_entity_data + '?entityId='+  facilityId  + '&entityType='+  type  + '&name=' + name + '&isSearch=' + true );
      }   
    searchPhoneNo(data) {
        return this.apiService.get(environment.base_value.search_by_phoneno + '/' + data);
    }
    searchEmail(data) {
        return this.apiService.get(environment.base_value.search_by_email + '/' + data);
    }
    searchUserName(data) {
        return this.apiService.get(environment.base_value.search_by_username + '/' + data);
    }
    saveUser(userData) {
        return this.apiService.post(environment.base_value.save_user, userData);
    }
    editUser(userData) {
        return this.apiService.put(environment.base_value.edit_user + '/' + userData.id, userData);

    }
    updateUserPool(data, id) {
        return this.apiService.put(environment.base_value.edit_user + '/' + id, data);
    }
    saveShift(shiftData) {
        return this.apiService.post(environment.base_value.get_shift, shiftData);
    }
    updateShift(id, shiftData) {
        return this.apiService.put(environment.base_value.get_shift + '/' + id, shiftData);
    }
    getAllShift() {
        return this.apiService.get(environment.base_value.get_all_shift);
    }
    getShiftHistory(id, type) {
        return this.apiService.get(environment.base_value.get_shift + '?entityId=' + id + '&entityType=' + type);
    }

    // Component: Location Management
    getHomeLocationData(key: string) {
        return this.apiService.get(environment.base_value.get_to_location_search + '/' + key);
    }

    //deletemultiimages in customer
    deleteMultipleImages(attachmentId) {
        return this.apiService.delete(environment.base_value.delete_multiple_images + '/' + attachmentId);
    }
    //delete user Education & proficiencies 
    deleteuserproficiency(id) {
        return this.apiService.delete(environment.base_value.delete_userProficiency + '/' + id);
    }
    deleteusereducation(id) {
        return this.apiService.delete(environment.base_value.delete_userEducation + '/' + id);
    }
    // Not In Use

    // getLocationImageById(id) { // 
    //     return this.apiService.get(environment.base_value.get_image_location_by_id + id);
    // }
    // saveAllTicket(data) { // 
    //     return this.apiService.post(environment.base_value.save_all_ticket, data); // w/o Login - - - this API will call
    // }

    // getPatientInfoWithDate(id, date) { // 
    //     return this.apiService.get(environment.base_value.get_patient_info + '?patientId=' + id + '&date=' + date);
    // }
    // getTag() { // 
    //     return this.apiService.get(environment.base_value.get_all_tag + '/CUST002');
    // }

    // getAllConsumable() { // 
    //     if (this.env_key === 'dev') {
    //         return [
    //             {
    //                 id: 1,
    //                 itemName: 'Disposable Syringe',
    //                 requestedQuantity: '50',
    //                 suppliedQuantity: 20,
    //                 price: 10,
    //                 totalPrice: 1000
    //             },
    //             {
    //                 id: 2,
    //                 itemName: 'Surgical Tape',
    //                 requestedQuantity: '10',
    //                 suppliedQuantity: 5,
    //                 price: 400,
    //                 totalPrice: 2000
    //             },
    //             {
    //                 id: 3,
    //                 itemName: 'Elastic Crepe Bandage ',
    //                 requestedQuantity: '50',
    //                 suppliedQuantity: 20,
    //                 price: 10,
    //                 totalPrice: 1000
    //             },
    //             {
    //                 id: 4,
    //                 itemName: 'Gauze',
    //                 requestedQuantity: '10',
    //                 suppliedQuantity: 5,
    //                 price: 400,
    //                 totalPrice: 2000
    //             },
    //             {
    //                 id: 5,
    //                 itemName: 'Laproscopy Veress Needle',
    //                 requestedQuantity: '10',
    //                 suppliedQuantity: 4,
    //                 price: 1000,
    //                 totalPrice: 4000
    //             },
    //             {
    //                 id: 6,
    //                 itemName: 'Disposable Glove',
    //                 requestedQuantity: '100',
    //                 suppliedQuantity: 70,
    //                 price: 100,
    //                 totalPrice: 7000
    //             }
    //         ];
    //     } else {
    //         return [];
    //     }
    // }
    // getOutpatientList() {
    //     if (this.env_key == 'dev') {
    //         return [{
    //             'id': 1,
    //             'tagId': 'TAG30023',
    //             'patientId': 'PAT003222',
    //             'name': 'Janaki',
    //             'age': 25,
    //             'sex': 'Female',
    //             'clinicName': 'Apollo Clinic',
    //             'speciality': 'General',
    //             'AdmittedDr': 'Dr. Joseph',
    //             'currentlocation': 'Reception',
    //             'inTime': '10-02-19 09:23',
    //             'outTime': '11-02-19 09:23',
    //             'status': 'Active'
    //         },
    //         {
    //             'id': 2,
    //             'tagId': 'TAG30024',
    //             'patientId': 'PAT003224',
    //             'name': 'Raman',
    //             'age': 28,
    //             'sex': 'Male',
    //             'clinicName': 'Apollo Clinic',
    //             'speciality': 'Ortho',
    //             'AdmittedDr': 'Dr. Joseph',
    //             'currentlocation': 'Reception',
    //             'inTime': '09-02-19 09:23',
    //             'outTime': '09-02-19 09:23',
    //             'status': 'Active'
    //         },
    //         {
    //             'id': 3,
    //             'tagId': 'TAG30025',
    //             'patientId': 'PAT003225',
    //             'name': 'Mohammed',
    //             'age': 24,
    //             'sex': 'Male',
    //             'clinicName': 'Apollo Clinic',
    //             'speciality': 'General',
    //             'AdmittedDr': 'Dr. Joseph',
    //             'currentlocation': 'Reception',
    //             'inTime': '10-02-19 09:23',
    //             'outTime': '11-02-19 09:23',
    //             'status': 'Active'
    //         },
    //         {
    //             'id': 4,
    //             'tagId': 'TAG30026',
    //             'patientId': 'PAT003226',
    //             'name': 'Rahul',
    //             'age': 23,
    //             'sex': 'Male',
    //             'clinicName': 'Apollo Med',
    //             'speciality': 'Ortho',
    //             'AdmittedDr': 'Dr. Kumar',
    //             'currentlocation': 'Reception',
    //             'inTime': '09-02-19 09:23',
    //             'outTime': '09-02-19 09:23',
    //             'status': 'Active'
    //         },
    //         {
    //             'id': 5,
    //             'tagId': 'TAG30027',
    //             'patientId': 'PAT003227',
    //             'name': 'Meenachi',
    //             'age': 25,
    //             'sex': 'Female',
    //             'clinicName': 'Apollo Clinic',
    //             'speciality': 'General',
    //             'AdmittedDr': 'Dr. Joseph',
    //             'currentlocation': 'Reception',
    //             'inTime': '10-02-19 09:23',
    //             'outTime': '11-02-19 09:23',
    //             'status': 'Active'
    //         },
    //         {
    //             'id': 6,
    //             'tagId': 'TAG30028',
    //             'patientId': 'PAT003228',
    //             'name': 'Kamal',
    //             'age': 58,
    //             'sex': 'Male',
    //             'clinicName': 'Apollo Med',
    //             'speciality': 'Ortho',
    //             'AdmittedDr': 'Dr. Kumar',
    //             'currentlocation': 'Reception',
    //             'inTime': '09-02-19 09:23',
    //             'outTime': '09-02-19 09:23',
    //             'status': 'Active'
    //         },
    //         {
    //             'id': 7,
    //             'tagId': 'TAG30029',
    //             'patientId': 'PAT003229',
    //             'name': 'Kannan',
    //             'age': 25,
    //             'sex': 'Male',
    //             'clinicName': 'Apollo Clinic',
    //             'speciality': 'General',
    //             'AdmittedDr': 'Dr. Joseph',
    //             'currentlocation': 'Reception',
    //             'inTime': '10-02-19 09:23',
    //             'outTime': '11-02-19 09:23',
    //             'status': 'Active'
    //         },
    //         {
    //             'id': 8,
    //             'tagId': 'TAG30030',
    //             'patientId': 'PAT003230',
    //             'name': 'Sunder',
    //             'age': 28,
    //             'sex': 'Male',
    //             'clinicName': 'Apollo Med',
    //             'speciality': 'Ortho',
    //             'AdmittedDr': 'Dr. Kumar',
    //             'currentlocation': 'Reception',
    //             'inTime': '09-02-19 09:23',
    //             'outTime': '09-02-19 09:23',
    //             'status': 'Active'
    //         }
    //         ];
    //     } else {
    //         return [];
    //     }
    // }

}
