<div class="clock-face" #clockFace>
    <div *ngIf="unit !== timeUnit.MINUTE;else minutesFace" class="clock-face__container">
        <div class="clock-face__number clock-face__number--outer"
             [style.transform]="'rotateZ('+ time.angle +'deg) translateX(-50%)' | styleSanitizer"
             *ngFor="let time of faceTime.slice(0, 12); trackBy: trackByTime">
			<span [style.transform]="'rotateZ(-'+ time.angle +'deg)' | styleSanitizer"
                  [ngClass]="{'active': (time.time === selectedTime.time) && !isClockFaceDisabled, 'disabled': time.disabled}">{{time.time}}</span>
        </div>
        <div class="clock-face__inner" *ngIf="faceTime.length > 12"
             [style.top]="'calc(50% - ' + innerClockFaceSize + 'px)'">
            <div class="clock-face__number clock-face__number--inner"
                 [style.transform]="'rotateZ('+ time.angle +'deg) translateX(-50%)' | styleSanitizer"
                 [style.height.px]="innerClockFaceSize"
                 *ngFor="let time of faceTime.slice(12, 24); trackBy: trackByTime">
			<span [style.transform]="'rotateZ(-'+ time.angle +'deg)' | styleSanitizer"
                  [ngClass]="{'active': (time.time === selectedTime.time) && !isClockFaceDisabled, 'disabled': time.disabled}">
                {{time.time}}</span>
            </div>
        </div>
    </div>

    <span class="clock-face__clock-hand" [ngClass]="{'clock-face__clock-hand_minute': unit === timeUnit.MINUTE}"
          #clockHand [hidden]="isClockFaceDisabled"></span>
</div>
<ng-template #minutesFace>
    <div class="clock-face__container">
        <div class="clock-face__number clock-face__number--outer"
             [style.transform]="'rotateZ('+ time.angle +'deg) translateX(-50%)' | styleSanitizer"
             *ngFor="let time of faceTime; trackBy: trackByTime">
	<span [style.transform]="'rotateZ(-'+ time.angle +'deg)' | styleSanitizer"
          [ngClass]="{'active': ((selectedTime.time === time.time) && time.time % 5 === 0) && !isClockFaceDisabled, 'disabled': time.disabled}">
	{{time.time % 5 === 0 ? time.time : ''}}</span>
        </div>
    </div>
</ng-template>
