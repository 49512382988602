/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { CommonService, ConfigurationService } from "../../../services";
import { ConfirmationDialog } from "../confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogComponent } from "../layout-save/layout-save.component";

@Component({
    selector: 'app-form-component',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class formComponent implements OnInit {
    public formTemplate: UntypedFormGroup;
    public formTemplateObject = [];
    public today = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    fileType: string;
    @Input() formTemplateData : any;
    @Input() type : any;
    @Input() formInput : any = { "id" : null , "entityId" : null , "entityType" : null , "pfFormTemplateId" : null, "content" : "form","entityData": null};
    @Input() formConfig : any;
    @Output() formInputUpdatedData = new EventEmitter();
    @Output() formTemValue = new EventEmitter();
    @Output() formTemplateUpdatedData = new EventEmitter();
    @Output() isSaveEnabled = new EventEmitter();
    public selectedSearchData : any = new UntypedFormControl(null);
    public col:any;
    public isOpen = false;
    public isFormBind = false;
    public data : any;
    public updateIndexi: any;
    public updateIndexj: any;
    public formTemplateDataObject: any;
    public formTemplateName: any;
    public layout : any;
    public entityFormStatusId = 'EFS-DR';
    public currentStatusEditEnable = false;
    public authorizedUser = false;
    public saveEnable = false;
    public nextWorkflowUser = null;
    public sideBarEnable = false;
    public formHistDetails = [];
    public entityFormHistId: any;
    public statusJson = null;
    public formTemplateDetail = {"name" :null, "css" : JSON.stringify({"header":null,"footer":null,"content":null}), "header" : null, "footer" : null, "footerButton": null, "responseResult": null};
    constructor(public datepipe: DatePipe,public configurationService:ConfigurationService,public dialog: MatDialog, public commonService:CommonService,private activatedRoute: ActivatedRoute,
        @Optional()  private dialogRefr: MatDialogRef<formComponent>){
    }
    ngOnInit() {
        this.buildform();
    }
    ngOnChanges(changes: SimpleChanges){
        // this.formTemplateData = changes.formTemplateData.currentValue;
        this.buildform();
    }
    buildform(){
        let dataObject1
        if(this.type == 'gridster'){
            // dataObject1 = this.formTemplateData['dataItems'];
            // this.col = this.formTemplateData['jsonValue']['col'];
            if(this.formTemplateData['id']){
                this.configurationService.getFormTemplates(this.formTemplateData['id']).subscribe(res =>{
                    if(res.statusCode === 1){
                        let formTempData = res.results[0];
                        this.formTemplateDetail['name'] = formTempData.name;
                        if(formTempData['css'] != null && formTempData['css'] != ""){
                            try{
                                this.formTemplateDetail['css'] = JSON.parse(formTempData['css']);
                            }catch(e){
                                console.log(e);
                            }
                        }
                        if(formTempData['layout']){
                            this.layout = JSON.parse(formTempData['layout']);
                        }
                        this.formTemplateName = formTempData['name'];
                        dataObject1 = formTempData['dataItems'];
                        if(formTempData.jsonValue){
                            let jsonValue = JSON.parse(formTempData.jsonValue);
                            if(jsonValue['col']){
                                this.col = jsonValue['col'];
                            }
                        }
                        this.isFormBind = true;
                        this.createDataObject(dataObject1);
                    }
                });
            }
        } else if(this.type == 'create'){
            dataObject1 = this.formTemplateData;
            this.col = 2;
            if(this.formConfig['layout']){
                this.layout = JSON.parse(this.formConfig['layout']);
            }
            if(this.formConfig){
                let jsonValue = JSON.parse(this.formConfig.jsonValue);
                if(jsonValue['col']){
                    this.col = jsonValue['col'];
                }
                if(jsonValue.hasOwnProperty('footer')){
                    this.formTemplateDetail.footer = jsonValue['footer'];
                    this.formTemplateDetail.header = jsonValue['header'];
                    
                }
                if(this.formConfig['css'] != null && this.formConfig['css'] != ""){
                    try{
                        this.formTemplateDetail['css'] = JSON.parse(this.formConfig['css']);
                    }catch(e){
                        console.log(e);
                    }
                }
            }
            this.createDataObject(dataObject1);
        } else if(this.formInput){
            if(this.formInput.hasOwnProperty('sideBar') && this.formInput['sideBar']){
                this.sideBarEnable = true;
                this.commonService.getEntityFormHistory(this.formInput['id']).subscribe(res =>{
                    if(res.statusCode == 1){
                        this.formHistDetails = res['results'].reverse();
                        this.entityFormHistId = this.formHistDetails[0]['id']
                    }
                    
                })
            }
            this.isFormBind = true;
            if(this.formInput['pfFormTemplateId']){
                this.configurationService.getFormTemplates(this.formInput['pfFormTemplateId']).subscribe(res =>{
                    if(res.statusCode === 1){
                        let formTempData = res.results[0];
                        this.formTemplateDetail['name'] = formTempData.name;
                        if(formTempData['css'] != null && formTempData['css'] != ""){
                            try{
                                this.formTemplateDetail['css'] = JSON.parse(formTempData['css']);
                            }catch(e){
                                console.log(e);
                            }
                        }
                        if(formTempData['layout']){
                            this.layout = JSON.parse(formTempData['layout']);
                        }
                        this.formTemplateName = formTempData['name'];
                        dataObject1 = formTempData['dataItems'];
                        if(formTempData.jsonValue){
                            let jsonValue = JSON.parse(formTempData.jsonValue);
                            if(jsonValue['col']){
                                this.col = jsonValue['col'];
                            }
                            if(jsonValue['footer']){
                                this.formTemplateDetail.footer = jsonValue['footer'];
                                this.formTemplateDetail.header = jsonValue['header'];
                            }
                            if(jsonValue['status']){
                                this.statusJson = jsonValue['status'];
                                if(this.sideBarEnable && this.formHistDetails.length){
                                    for(let i=0;i<this.formHistDetails.length;i++){
                                        let statusName = jsonValue['status'].filter(res => res.code == this.formHistDetails[i]['entityFormStatusId']);
                                        if(statusName.length){
                                            this.formHistDetails[i]['entityFormStatusName'] = statusName[0]['name'];
                                        }
                                    }
                                }
                                if(this.formInput['id'] == null){
                                    //newly created form are in draft status
                                    let workflowLevel = jsonValue['status'].filter(res => res.code == 'EFS-DR')
                                    if(workflowLevel.length){
                                        this.formTemplateDetail['footerButton'] = workflowLevel[0];
                                    }
                                } else if(this.formInput['id'] != null && this.formInput.hasOwnProperty('entityFormStatus')){
                                    let workflow = [];
                                    // get nextlevelentitystatus of edit value
                                    let currentStatusData = jsonValue['status'].filter(res => res.code == this.formInput['entityFormStatus']);
                                    if(currentStatusData.length){
                                        let level = currentStatusData[0]['level'];
                                        level = level+ 1;
                                        let nextWorkflowStatus = jsonValue['status'].filter(res => res.level == level)
                                        if(nextWorkflowStatus.length){
                                            this.currentStatusEditEnable = nextWorkflowStatus[0]['edit'];
                                        }
                                    }

                                    if(formTempData['pfWorkflows'].length){
                                        workflow = formTempData['pfWorkflows'].filter(res => res.identifyingId == localStorage.getItem(btoa('userId')) && res.isActive);
                                    }
                                    if(workflow.length){
                                        let workflowLevel = jsonValue['status'].filter(res => res.level == Number(workflow[0]['workflowLevelId']))
                                        let level = workflowLevel[0]['level'];
                                        level = level-1;
                                        let nextLevel = level+2;
                                        if(level>=0){
                                            let previousWorkflowLevel = jsonValue['status'].filter(res => res.level == level)
                                            if(previousWorkflowLevel.length){
                                                // compare previous level entityFormStatus with current status of entityform
                                                if(previousWorkflowLevel[0]['code'] == this.formInput['entityFormStatus']){
                                                    if(workflowLevel.length){
                                                        // compare current entity form status with workflowlevel form status
                                                        if(this.formInput['entityFormStatus'] !=  workflowLevel[0]['code']){
                                                            this.formTemplateDetail['footerButton'] = workflowLevel[0];
                                                            // get the next workflow level user details
                                                            let nextWorkflow = formTempData['pfWorkflows'].filter(res => res.workflowLevelId == String(nextLevel) && res.isActive);
                                                            let nextStatusDetail = jsonValue['status'].filter(res => res.level == nextLevel);
                                                            if(nextWorkflow.length && nextStatusDetail.length){
                                                                this.nextWorkflowUser = "*"+nextStatusDetail[0]['name']+" to "+nextWorkflow[0]['userName']+" ("+ nextWorkflow[0]['description']+ ").";
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        } else{
                                            //current entityformstatus with draft status
                                            if(workflowLevel.length){
                                                this.formTemplateDetail['footerButton'] = workflowLevel[0];
                                            }
                                        }
                                    }

                                }
                            } else{
                                // enable save button, if there is no status info in template option
                                this.saveEnable = true;
                                this.currentStatusEditEnable = true;
                            }
                        }
                        if(this.formInput['entityId'] != null && this.formInput['entityData'] == null){
                            if(this.formInput['entityType'] == 'asset'){
                                this.configurationService.getAssetsById(this.formInput['entityId']).subscribe(res =>{
                                    this.formInput['entityData'] = res;
                                    this.createDataObject(dataObject1,this.formInput['id']);
                                })
                            }
                        } else {
                            this.createDataObject(dataObject1,this.formInput['id']);
                        }
                    }
                });
            }
        }
    }
    private createDataObject(dataObject1,entityFormId?){
        dataObject1 = dataObject1.filter(val => val.status)
        this.formTemplateDataObject = dataObject1;
        let lent = dataObject1.length;
        let dataObject = []
        let tempObj = []
        dataObject1.forEach((element,index) => {
            if(!element.hasOwnProperty('position')) {
                element['position'] = null;
            }
            if(this.layout){
                if(this.layout.hasOwnProperty(element['name'])){
                    element['position'] = this.layout[element['name']];
                }
            }
        });
        dataObject1 = this.commonService.sortByKey(dataObject1,'position');
        let posValue = null;
        if(this.layout){
            dataObject1.forEach((element, index) => {
                element['tempCustomCss'] = {"content" : null, "di" : null};
                try{
                    if(typeof(element.customCss) === 'string'){
                        let customCss = JSON.parse(element.customCss);
                        if(customCss.hasOwnProperty('di')) {
                            element['tempCustomCss'] = customCss;
                        } else {
                            element['tempCustomCss']["di"] = customCss;
                            element['tempCustomCss']["content"] = null;
                        }
                    }
                } catch(e){
                    element['tempCustomCss'] = {"content" : null, "di" : null};
                }
                try{
                    if(typeof(element.customValue) === 'string' && element.outputResponse == null){
                        element['outputResponse'] = JSON.parse(element.customValue);
                    }
                } catch(e) {

                }
                try{
                    if(typeof(element.defaultValue) === 'string' && element.defaultValue != null){
                        element['defaultValue'] = JSON.parse(element.defaultValue);
                    }
                } catch(e) {

                }
                try{
                    element['dataBind'] = {"key":"code","value":"value"};
                    if(element.hasOwnProperty('validation')){
                        let validationJson = JSON.parse(element.validation);
                        if(validationJson.hasOwnProperty('dataBind')){
                            element['dataBind'] = validationJson['dataBind'];
                        }
                    }
                } catch(e) {

                }
                let pos2 = null;
                if(element['position']) {
                    pos2 = element['position'].substring(0,3)
                }
                if(posValue == pos2){
                    tempObj.push(element);
                    if(this.col <= tempObj.length){
                        this.col = tempObj.length;
                    }
                    if(dataObject1.length - 1 == index) {
                        dataObject.push(tempObj);
                    }
                } else{
                    posValue = pos2;
                    if(tempObj.length) {
                        dataObject.push(tempObj);
                    }
                    tempObj = [];
                    tempObj.push(element);
                    if(dataObject1.length - 1 == index) {
                        dataObject.push(tempObj);
                    }
                }
            });
        } else{
            for(let i=0;i<lent;i++){
                dataObject1[i]['tempCustomCss'] = null;
                if(typeof(dataObject1[i].customCss) === 'string'){
                    let customCss = JSON.parse(dataObject1[i].customCss);
                    if(customCss.hasOwnProperty('di')) {
                        dataObject1[i]['tempCustomCss'] = customCss;
                    } else {
                        dataObject1[i]['tempCustomCss']["di"] = customCss;
                        dataObject1[i]['tempCustomCss']["content"] = null;
                    }
                    
                }
                if(tempObj.length<this.col){
                    tempObj.push(dataObject1[i]);
                } else{
                    dataObject.push(tempObj);
                    tempObj = [];
                    tempObj.push(dataObject1[i]);
                }
                if(i==(lent-1)){
                    dataObject.push(tempObj);
                }
            }
        }
        this.formTemplateObject = [];
        // setTimeout(() => this.formDataItemBinding(dataObject,entityFormId), 800);
        this.formDataItemBinding(dataObject,entityFormId);
    }
    formDataItemBinding(dataObject,entityFormId?){
        const formGroup = {};
        let index=0;
        for(let i=0;i<dataObject.length;i++){
            this.formTemplateObject[i] = Object.keys(dataObject[i]).map(prop => {
                if(dataObject[i][prop]['validation']){
                    return Object.assign({}, { key: dataObject[i][prop]['name'],validator: JSON.parse(dataObject[i][prop]['validation']) }, dataObject[i][prop]);
                } else{
                    return Object.assign({}, { key: dataObject[i][prop]['name'],validator: {} }, dataObject[i][prop]);
                }
            });
            for (let propObj of Object.keys(dataObject[i])) {
                if(dataObject[i][propObj]['type'] == 'DI-DTE'){
                    if(dataObject[i][propObj]['defaultValue'] == "Blank"){
                        formGroup[dataObject[i][propObj]['name']] = new UntypedFormControl(null);
                    } else{
                        formGroup[dataObject[i][propObj]['name']] = new UntypedFormControl(this.today || null);
                    }
                } else{
                    let defaultValue = dataObject[i][propObj]['defaultValue'] || null;
                    if(this.formInput){
                        if(this.formInput.hasOwnProperty('entityData') && this.formInput['entityId'] && this.formInput['entityData'] && dataObject[i][propObj]['contextValue']){
                            let contextValue;
                            try{
                                contextValue = JSON.parse(dataObject[i][propObj]['contextValue']);
                                if(contextValue.hasOwnProperty('IN')){
                                    if(this.formInput['entityData'].hasOwnProperty(contextValue['IN'])){
                                        defaultValue = this.formInput['entityData'][contextValue['IN']]
                                    }
                                }
                            } catch(e){
                                console.log(e)
                            }
                        }
                    }
                    formGroup[dataObject[i][propObj]['name']] = new UntypedFormControl(defaultValue, this.mapValidators(dataObject[i][propObj]['validation']));
                }
            }
            index=index+1;
        }
        this.formTemplate = new UntypedFormGroup(formGroup);
        if(entityFormId){
            this.getEntityFormDetails(entityFormId);
        }
    }
    getEntityFormDetails(entityFormId){
        this.configurationService.getEntityForm(entityFormId).subscribe(res =>{
            if(res.statusCode == 1 && res.results.length){
                this.formEntityBinding(res.results[0])
                if(res.results[0]['createdBy'] == localStorage.getItem(btoa('userId'))){
                    this.authorizedUser = true;
                }
            }
        })
    }
    formEntityBinding(entityFormDetail){
        this.entityFormStatusId =  entityFormDetail['entityFormStatusId'];
        let formValue = entityFormDetail['formValue'];
        for (let key in formValue) {
            if(this.formTemplate.value.hasOwnProperty(key)){
                this.formTemplate.controls[key].setValue(formValue[key]);
            }
        }
    }
    private mapValidators(validators) {
        const formValidators = [];
        if (validators) {
            validators = JSON.parse(validators);
          for (const validation of Object.keys(validators)) {
            if (validation === 'required' && validators[validation]) {
              formValidators.push(Validators.required);
            } else if (validation === 'minLength') {
              formValidators.push(Validators.minLength(validators[validation]));
            } else if (validation === 'maxLength') {
              formValidators.push(Validators.maxLength(validators[validation]));
            }
          }
        }
        return formValidators;
      }
      uploadFile(event,indexi,indexj){
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (files && file) {
              this.fileType = file.type;
              const reader = new FileReader();
              reader.onload = this._handleReaderLoaded.bind(this,indexi,indexj);
              reader.readAsBinaryString(file);
              
            }
        }
    }
    _handleReaderLoaded(indexi,indexj,readerEvt) {
        // console.log(indexi,indexj,readerEvt)
        const binaryString = readerEvt.target.result;
        
        const base64Data = btoa(binaryString);
        this.formTemplateObject[indexi][indexj]['value'] = base64Data;
    
    }
    getSearchText(selectedData,indexi,indexj){
        this.formTemplateObject[indexi][indexj]['value'] = selectedData['id'];
    }
    displayFn(field: any,option: any): string {
        const searchData = option;
        const fieldDat = field;
        if (searchData) {
            const selectedVal = fieldDat.outputResponse;
            if(fieldDat.hasOwnProperty("dataBind")){
                const displayData = selectedVal.find(obj => obj[fieldDat.dataBind.key] === searchData);
                return displayData[fieldDat.dataBind.value];
            }
        } 
        return '';
    }
    getSearchlist(id,indexi,indexj) {
        if(id){
          let searchData = '';
          searchData += id.target.value;
          if (searchData.length >= 2 && this.formTemplateObject[indexi][indexj]['pfModelData'].hasOwnProperty('url')) {
            if(this.formTemplateObject[indexi][indexj]['pfModelData']['url']){
                this.configurationService.getApiSearchData(this.formTemplateObject[indexi][indexj]['pfModelData']['url'],id.target.value).subscribe(res => {
                    this.formTemplateObject[indexi][indexj]['outputResponse'] = res.results;
                });
            }
          } else {
            this.selectedSearchData.setValue(null)
            this.formTemplateObject[indexi][indexj]['outputResponse'] = [];
          }
        } else{
            this.selectedSearchData.setValue(null)
            this.formTemplateObject[indexi][indexj]['outputResponse'] = [];
        }
    }
    getWidgetData(value){
        if (this.validate(this.formTemplate.controls['fdt'].value, this.formTemplate.controls['tdt'].value)) {
            this.formTemValue.emit([this.formTemplate,this.formTemplateObject,value]);
        }
    }
    validate(sDate: string, eDate: string) {
        let validDate = true;
        if ((sDate != null && eDate != null) && (eDate < sDate)) {
            validDate = false;
            console.log("From Date should not be greater than To Date")
        }
        return validDate;
    }
    deleteDataItem(indexi,indexj){
        if(this.formTemplateObject[indexi][indexj]['pfFormTemplateDataItemId'] == null){
            this.formTemplateObject[indexi].splice(indexj,1);
        } else{
            this.formTemplateObject[indexi][indexj]['status'] = false;
        }
        let newArr = [];
        for(let i = 0; i < this.formTemplateObject.length; i++)
        {
            newArr = newArr.concat(this.formTemplateObject[i]);
        }
        this.createDataObject(newArr);
        this.formTemplateUpdatedData.emit(newArr);
    }
    editDataItem(indexi,indexj){
        // console.log(this.formTemplateObject[indexi][indexj]);
        this.data = this.formTemplateObject[indexi][indexj];
        this.updateIndexi = indexi;
        this.updateIndexj = indexj;
        // const dialogRef = this.dialog.open(editFormComponent, {
        //     data: data,width: '60%',height: '70%', panelClass: 'custom-dialog-container2', disableClose: true
        // });
        // dialogRef.afterClosed().subscribe(result => {
        //     if (result) {
        //         this.formTemplateObject[indexi][indexj] = result;
        //         this.updateDataItem();
        //     }
        // });
    }
    updateDataValue(result){
        this.formTemplateObject[this.updateIndexi][this.updateIndexj] = result;
        this.updateDataItem();
    }
    updateDataItem(){
        let newArr = [];
        for(let i = 0; i < this.formTemplateObject.length; i++)
        {
            newArr = newArr.concat(this.formTemplateObject[i]);
        }
        this.formTemplateUpdatedData.emit(newArr);
    }
    exitValue(){
        this.isOpen = !this.isOpen;
        this.isSaveEnabled.emit(!this.isOpen)
    }
    returnStatus(){
        const dialogRef = this.dialog.open(ConfirmationDialog, {
            panelClass:['confirmation-popup'], disableClose: true,
            data: {
            title: "Confirmation", message: "Do you want to return the form?",
            buttonText: { ok: 'Yes', cancel: 'No' },'isRemark': 0,formStatusEnable: true
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.formTemplateDetail['responseResult']  = result;
            if(this.formTemplateDetail['responseResult']['confirmButtonText'] == 'Yes'){
                if(this.statusJson){
                    let level = this.formTemplateDetail['footerButton']['level'];
                    level = level- 1;
                    let prevWorkflowStatus = this.statusJson.filter(res => res.level == level);
                    if(prevWorkflowStatus.length){
                        this.entityFormStatusId = prevWorkflowStatus[0]['code'];
                        this.postFormData();
                    }
                }
                this.dialogRefr.close();
            }
            return result;
        });
    }
    confirmDelete(indexi,indexj){
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            panelClass:['confirmation-popup'],
            data: {
              title: 'Confirmation',
              message: 'Are you sure you want to delete dataitem?',
              buttonText: {
                ok: 'Yes',
                cancel: 'No'
              }
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result == 'Yes') {
                this.deleteDataItem(indexi,indexj);
            }
          });
    }
    sendForApproveDialog(title,message){
        const dialogRef = this.dialog.open(ConfirmationDialog, {
            panelClass:['confirmation-popup'], disableClose: true,
            data: {
            title: title, message: message,
            buttonText: { ok: 'Yes', cancel: 'No' },'isRemark': 0,formStatusEnable: true
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.formTemplateDetail['responseResult']  = result;
            if(this.formTemplateDetail['responseResult']['confirmButtonText'] == 'Yes'){
                //post form data via popup confiramtion for button like final
                this.postFormData();
                this.dialogRefr.close();
            }
        });
    }
    saveFormData(status?:any){
        if(status){
            //update form data for save button
            this.postFormData();
        } else{
            if(this.formTemplateDetail['footerButton'] != null){
                if(this.formTemplateDetail['footerButton']['code'] == 'EFS-FN' || this.formTemplateDetail['footerButton']['code'] == 'EFS-PA' || this.formTemplateDetail['footerButton']['code'] == 'EFS-AP'){
                    this.sendForApproveDialog("Confirmation","Are you want to save and submit this document for approval?");
                    this.entityFormStatusId = this.formTemplateDetail['footerButton']['code'];
                } else{
                    //post form data for draft button
                    this.postFormData();
                }
            }
        }
    }
    postFormData(){
        let comments = null;
        if(this.formTemplateDetail['responseResult'] && this.formTemplateDetail['responseResult'].hasOwnProperty('comments')){
            comments = this.formTemplateDetail['responseResult']['comments'];
        }
        let postData ={
            "id": null,
            "entityId": this.formInput['entityId'],
            "entityType": this.formInput['entityType'],
            "pfFormTemplateId": this.formInput['pfFormTemplateId'],
            "status": true,
            "formValue": this.formTemplate.value,
            "name": this.formTemplateName,
            "entityFormStatusId":this.entityFormStatusId,
            "comments":comments
        }
        let filterValue = this.formTemplateDataObject.filter(val => val['contextValue'] != null)
        for(let i=0;i<filterValue.length;i++){
            let contextValue;
            try{
                contextValue = JSON.parse(filterValue[i]['contextValue']);
                if(contextValue.hasOwnProperty('OUT')){
                    postData[contextValue['OUT']] = this.formTemplate.value[filterValue[i]['name']]
                }
            } catch(e){
                console.log(e)
            }
        }
        console.log(postData)
        if(this.formInput) {
            if(this.formInput['id']) {
                postData['id'] = this.formInput['id'];
                this.configurationService.updateEntityForm(postData).subscribe(res=> {
                    this.formInputUpdatedData.emit(res);
                });
            } else{
                this.configurationService.saveEntityForm(postData).subscribe(res=> {
                    this.formInputUpdatedData.emit(res);
                });
            }
        }
        
    }
}

@Component({
    selector: 'app-edit-form-component',
    templateUrl: './edit-form.component.html',
    styleUrls: ['./form.component.scss']
})
export class editFormComponent implements OnInit{
    public formDataItem: UntypedFormGroup;
    public labelName = "Edit Form";
    // public validationObj : any;
    // public objKeys: any;
    public dataItemFields = ['defaultValue','labelName','name','position','customCss','multiSelect','tooltip','contextValue','customValue','validation'];
    public enableForm = false;
    public fieldType = 'DI-TXT';
    public defaultDate =['CurrentDate','Blank'];
    public options = [true,false];
    public today = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    @Input() formFieldData : any;
    @Output() dataValue = new EventEmitter();
    @Output() exitValue = new EventEmitter();
    constructor(public datepipe: DatePipe){
    }
    ngOnInit(): void {
        this.labelName = this.formFieldData['labelName'];
        this.fieldType = this.formFieldData['type'];
        // try {
        //     this.validationObj = JSON.parse(this.formFieldData['validation']);
        //     this.objKeys = Object.keys(this.validationObj);
        // } catch(e){
        //     console.log(e)
        // }
        this.createFormGroup();
    }
    public createFormGroup(){
        const formGroup = {};
        for(let i=0;i<this.dataItemFields.length;i++){
            if(this.dataItemFields[i] == 'defaultValue'){
                if(this.formFieldData['type']=='DI-DTE')
                {
                    if(this.formFieldData['defaultValue'] == "new Date()"){
                        formGroup['defaultValue'] = new UntypedFormControl(this.defaultDate[0]||null);
                    }
                } else{
                    formGroup['defaultValue'] = new UntypedFormControl(this.formFieldData['defaultValue']||null);
                }
            } else{
                formGroup[this.dataItemFields[i]] = new UntypedFormControl(this.formFieldData[this.dataItemFields[i]]||null);
            }
        }
        // if(this.objKeys){
        //     for(let i=0;i<this.objKeys.length;i++)
        //     {
        //         formGroup[this.objKeys[i]] = new FormControl(this.validationObj[this.objKeys[i]]||null);
        //     }
        //     this.enableForm = true;
        // }
        this.formDataItem = new UntypedFormGroup(formGroup);
        this.formDataItem.setValidators(this.customValidate)
    }
    customValidate(fg: UntypedFormGroup) {
        let error = null;
        if (fg.get('customCss').value != null && fg.get('customCss').value !== '') {
            let optionValue = fg.get('customCss').value;
            try {
                optionValue = JSON.parse(optionValue);
    
            } catch (e) {
                error = fg.controls['customCss'].setErrors({ invalid: true });
            }
        }
        if (fg.get('contextValue').value != null && fg.get('contextValue').value !== '') {
            let optionValue = fg.get('contextValue').value;
            try {
                optionValue = JSON.parse(optionValue);
    
            } catch (e) {
                error = fg.controls['contextValue'].setErrors({ invalid: true });
            }
        }
        if (fg.get('customValue').value != null && fg.get('customValue').value !== '') {
            let optionValue = fg.get('customValue').value;
            try {
                optionValue = JSON.parse(optionValue);
    
            } catch (e) {
                error = fg.controls['customValue'].setErrors({ invalid: true });
            }
        }
        if (fg.get('validation').value != null && fg.get('validation').value !== '') {
            let optionValue = fg.get('validation').value;
            try {
                optionValue = JSON.parse(optionValue);
    
            } catch (e) {
                error = fg.controls['validation'].setErrors({ invalid: true });
            }
        }
        return error;
    }
    public saveDataItemValidation(){
        let jsonObj = this.formDataItem.value;
        for(let i=0;i<this.dataItemFields.length;i++){
            if(this.dataItemFields[i] == 'defaultValue'){
                if(this.formFieldData['type']=='DI-DTE'){
                    if(jsonObj['defaultValue'] == "CurrentDate"){
                        this.formFieldData['defaultValue'] = "new Date()";
                    }else{
                        this.formFieldData['defaultValue'] = jsonObj['defaultValue'];
                    }
                }else{
                    this.formFieldData['defaultValue'] = jsonObj['defaultValue'];
                }
            } else if(this.dataItemFields[i] == 'position'){
                if(jsonObj[this.dataItemFields[i]] == ""){
                    this.formFieldData[this.dataItemFields[i]] = null;
                } else{
                    this.formFieldData[this.dataItemFields[i]] = jsonObj[this.dataItemFields[i]];
                }
            } else{
                this.formFieldData[this.dataItemFields[i]] = jsonObj[this.dataItemFields[i]];
            }
            if(this.dataItemFields[i] == 'name'){
                this.formFieldData['key'] = jsonObj[this.dataItemFields[i]];
            }
            delete jsonObj[this.dataItemFields[i]];
        }
        // this.formFieldData['validation'] = JSON.stringify(jsonObj);
        this.dataValue.emit(this.formFieldData);
        this.exitPopup();
        // this.thisDialogRef.close(this.data);
    }
    public exitPopup(){
        this.exitValue.emit();
    }
}