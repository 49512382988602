/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Component, OnInit, Input, Output, EventEmitter, Inject, ViewEncapsulation, HostListener, ElementRef } from '@angular/core';
import { OvitagComponent } from '../../../../ovitag/ovitag.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService, CommonService, HospitalService, UpdatePasswordModel } from '../../..';
import { UntypedFormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import * as screenfull from 'screenfull';
import { EditProfile } from '../user-menu/add-profile.model';
import { PasswordValidation } from '../password-validators';
import { ViewProfileComponent, ChangePasswordComponent } from '../user-menu/user-menu.component';
import { CookieService } from 'ngx-cookie-service';
import { ErrorStateMatcherService } from '../../../services/error-state-matcher.service';

@Component({
  selector: 'cdk-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,//need to encapsulation
})
export class SidebarMenuComponent implements OnInit {

  public matcher = new ErrorStateMatcherService();
  public menus: any;
  @Input() iconOnly: boolean;
  @Input() secondaryMenu = false;
  @Input() code: any;
  @Input() menName: any;
  public isAllow = true;
  public isOpen = false;
  public id = null;
  public activate_btn: any = [];
  height: number;
  public ismenuScroll = 'hidden';
  public submenuId: number;
  public submenuOpen = false;
  public empUser = null;
  maxHeight: number;
  customerName: string;
  menuHeight: number;
  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement) {
    if (!targetElement) {
      return;
    }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      if (this.isAllow) {
        this.isAllow = false;
        this.isOpen = true;
      } else {
        this.isAllow = true;
        this.toggleView(this.id);
      }
    }
  }
    encapsulation: ViewEncapsulation.None;
    profileForm: UntypedFormGroup;
    public helpDocument: any;

    username = localStorage.getItem(btoa('current_user')); // current_user
    // currentUser = null;
    @Input() currentUser = null;
  constructor(private dialog: MatDialog, private router: Router,
    public apiService: ApiService,
    public ovitag: OvitagComponent, private elementRef: ElementRef, public common: CommonService,
    private cookieService: CookieService) {
      this.activate_btn = this.common.getActivePermission('button');
     }
    @HostListener('window:resize', ['$event'])
    
    ngOnInit() {
      if (window.location.hostname.includes("kyn")) {
        this.customerName = "kyn";
      }
    this.maxHeight = window.innerHeight - 130;
    this.submenuId = parseInt(localStorage.getItem('submenuId'));
    if (parseInt(localStorage.getItem('userlevel')) === 8) {
      this.helpDocument = '/assets/help/social-distance/index.htm';
    } else {
      this.helpDocument = '/assets/help/rtls/index.htm';
    }
    const permission = JSON.parse(localStorage.getItem('permission'));
    if(permission) {
    const menu = permission.menuItems;
    if (this.code === 'MN_ALL') {
      if (parseInt(localStorage.getItem('userlevel')) === 8) {
        for (let m = 0; m < menu.length; m++) {
          if (menu[m].name === 'Hospital') {
            menu[m].name = 'Organization';
          }
        }
      }
      this.menus = menu;
    } else {
      for (let i = 0; i < menu.length; i++) {
        if (this.code === menu[i].code) {
          const active_menu = menu[i].subMenus;
          this.menus = active_menu;
        }
      }
    }
    } else {
      localStorage.clear();
      this.router.navigate(['/login']);
    }
    if (this.menus.length <= 6) {
      this.height = 400;
    }
    if (this.menus.length <= 2) {
      this.empUser = 10;
    } else {
      this.empUser = (this.menus.length - 2) * 10;
    }
    const data =  this.menus.filter(resFilter => resFilter.subMenus.length !== 0);
    if (data.length !== 0) {
      this.ismenuScroll = 'scroll';
    } else {
      this.ismenuScroll = 'hidden';
    }
    this.menuHeight = ((this.menus.length * 35) + 10);
  }
  openLink() {
    // SONARQUBE-Remove or correct this useless self-assignment.
    const menu_open = this.menus.open;
    this.menus.open = menu_open;
  }

  selectedNav(menuLink) {
    if (menuLink !== '') {
      alert('linked');

    }
  }

  toggleView(id) {
    this.id = id;
    this.ovitag.menuClicked(null , id);
  }

  menuDetails(menu){
    this.common.menuCode = menu.code;
    localStorage.setItem(btoa('menuCode'),this.common.menuCode)
  }
  
  subMenuExpand(submenuId) {
    localStorage.setItem('submenuId',(submenuId));
  }

  logout() {
    this.username = '';
    const landingPage = window.location.pathname.replace('/en', '');
    console.log(landingPage);

    this.common.validateUserPreference('landingPage', landingPage);
    
    // full_screen_mode(userpreference start)
    this.common.validateUserPreference('fullscreenMode', screenfull.isFullscreen);
    // full_screen_mode(userpreference end)

    // this.common.userPreference = null;

    // if (this.cookieService.check(
    //   'landing_page_' + localStorage.getItem(btoa('facilityId')) + '_' +
    //   localStorage.getItem(btoa('userId'))
    // )) {
    //   this.cookieService.delete('landing_page_' + localStorage.getItem(btoa('facilityId')) + '_' +
    //   localStorage.getItem(btoa('userId')));
    //   this.cookieService.set('landing_page_' + localStorage.getItem(btoa('facilityId')) + '_' +
    //   localStorage.getItem(btoa('userId')), landingPage);
    // } else {
    //   this.cookieService.set('landing_page_' + localStorage.getItem(btoa('facilityId')) + '_' +
    //   localStorage.getItem(btoa('userId')), landingPage);
    // }

    //full_screen_mode_ (cookies start)
    // if (this.cookieService.check(
    //   'full_screen_mode_' + localStorage.getItem(btoa('facilityId')) + '_' +
    //   localStorage.getItem(btoa('userId'))
    // )) {
    //   this.cookieService.delete('full_screen_mode_' + localStorage.getItem(btoa('facilityId')) + '_' +
    //   localStorage.getItem(btoa('userId')));
    //   this.cookieService.set('full_screen_mode_' + localStorage.getItem(btoa('facilityId')) + '_' +
    //   localStorage.getItem(btoa('userId')), screenfull.isFullscreen);
    // } else {
    //   this.cookieService.set('full_screen_mode_' + localStorage.getItem(btoa('facilityId')) + '_' +
    //   localStorage.getItem(btoa('userId')), screenfull.isFullscreen);
    // }
    //full_screen_mode_ (cookies end)
    // remove user from local storage to log user out
    localStorage.clear();
    if (screenfull.isFullscreen) {
      screenfull.exit();
    }
    this.router.navigate(['/login']);
}

addProfile() {
    this.dialog.open(ViewProfileComponent,
      {
        height: '300px', width: '450px',data: {
        }, panelClass: 'custom-dialog-container', disableClose: false
    });
}
changePassword() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      height: '280px', width: '370px', panelClass: 'custom-dialog-container2', disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}

