/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Component, Input, ViewChild, ViewChildren, OnInit, OnChanges, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import {ChartService } from '../../../services/chart.service';
import { CommonDialogComponent } from '../common-dialog-component/common-dialog.component';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../../../services';


@Component({
  selector: 'angular-table-component',
  templateUrl: './angulartable.component.html',
  styleUrls: ['./angulartable.component.scss']
})
export class AngularTableComponent implements OnChanges {
    public showTable = false;
    dataSource = new MatTableDataSource<any>();
    HCDisplayedData : any;
    HCDisplayedLabel : any;
    HCDisplayedColumns : any;
    public isSearch : boolean = false;
    @ViewChild(MatPaginator)
    set paginator(value: MatPaginator) {
      this.dataSource.paginator = value;
    }    
    @ViewChild(MatSort)
    set sort(value: MatSort) {
      this.dataSource.sort = value;
    }
    @Input() title: any;
    @Input() tableData : any;
    @Input() tableLabel : any;
    @Input() columns : any;
    @Input() columnData : any;
    @Input() type : any = null;
    @Input() page : any = true;
    @Input() enableExcel : any = true;
    @Input() pageSizeOptions : any = [100, 200, 300, 400, 500];
    @Input() chartData : any;
    @Input() id : any;
    @Input() styles: any;
    @Input() tableStyle: any;
    @Input() applyFilter: any;
    @Input() menuButton: any;
    @Output() triggerAction = new EventEmitter<any>();
    @Output() locationBoundaryId = new EventEmitter<string>();
    @Output() porterDetail = new EventEmitter<string>();
    @ViewChildren('empSD') allMyCanvas: any;  // Observe #mycharts 
    
    displayedColumns = [];
    displayedData = [];
    constructor(public ChartService : ChartService,public dialog: MatDialog, public datepipe: DatePipe,
      public ExcelService: ExcelService) {
      }
    
    ngOnChanges(changes : SimpleChanges) {
      if(this.tableData){
        if(this.tableData.length > 0) {
          this.showTable = false;
          if(this.type === 'ng-table') {
            this.displayedColumns = this.columns;
            this.displayedData  = this.columnData;
            this.dataSource = new MatTableDataSource(this.tableData);
            this.dataSource.paginator = this.paginator;
            this.showTable = true;
          } else {
          
          if(this.chartData){
              setTimeout(() => this.getCharts(this.allMyCanvas,this.chartData), 5000); 
          }
            let data = this.tableData;
            this.dataSource = new MatTableDataSource(data);
            if(this.id == 'dashWid'){
              this.HCDisplayedData = this.columns;
              this.HCDisplayedLabel = this.tableLabel;
              if(this.HCDisplayedLabel == null || this.HCDisplayedLabel.length == 0)
                this.HCDisplayedLabel = this.HCDisplayedData;
              this.HCDisplayedColumns = this.HCDisplayedData;
            } 
            if(this.id != 'report2'){
              this.HCDisplayedData = this.columns;
              this.HCDisplayedColumns = this.HCDisplayedData;
            } else {
              this.HCDisplayedData = this.tableData;
              this.HCDisplayedColumns = this.columns;
            }
            if(this.columns == undefined) {
              this.HCDisplayedData = this.tableData;
              this.HCDisplayedColumns = Object.keys(this.tableData[0]);
            }            
            this.dataSource.paginator = this.paginator;
            if(this.HCDisplayedColumns[0]=='Active' || this.HCDisplayedColumns[0]=='Inactive'){
              this.page = false;
            }
            this.showTable = true;
            if (this.id == 'mrfile-missing' && this.applyFilter !== undefined && this.applyFilter !== null && this.applyFilter !== '') {
              this.applyFilter = this.applyFilter.trim(); // Remove whitespace
              this.applyFilter = this.applyFilter.toLowerCase(); // Datasource defaults to lowercase matches
              this.applyFilter = this.applyFilter;
              this.dataSource.filter = this.applyFilter;
          }
          }
        }
      }
    } 
    filterTable(filterVal : string){
      filterVal = filterVal.trim(); // Remove whitespace
      filterVal = filterVal.toLowerCase(); // Datasource defaults to lowercase matches
      filterVal = filterVal;
      this.dataSource.filter = filterVal;
    }
    downloadExcel() {
      const startDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
      const sheetName = this.title;
      const fileName = this.title;
      const data = this.tableData;
      this.ExcelService.singleSheet(data, sheetName, fileName, startDate);
    }
    getLastLocation(data){
      let details = {
        floorId: data['floor_id'],
        tagSerialNumber: data['Tag serial no'],
        tagTypeId: "TAT-MR",
        type: "globalSearch"
      }
      const dialogRef = this.dialog.open(CommonDialogComponent, {
        data: details,
        panelClass: ["medium-popup"],
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
      });
    }
    getCharts(chartArray, chartData) {
      let canvasCharts = chartArray._results;  // Get array with all canvas
      canvasCharts.map((myCanvas1, i) => {
        chartData[i]['canvasId'] = myCanvas1.nativeElement.getContext('2d'); 
        let chart = this.ChartService.drawChart(chartData[i]);
      }); 
    }
    removeLocationBoundary(selectedId) {
      this.locationBoundaryId.emit(selectedId);
    }
    viewLocationHistory(porterDet){
      this.porterDetail.emit(porterDet);
    }

    actionTrigger(key, data, keyVal) {
      this.triggerAction.emit({key, data, keyVal});
    }
}
