<div *ngIf="!isShowSearch" class="global-search-1 ovi-font-family" style="float: right;height: 100%;">
    <button mat-icon-button style="margin-top:1px; width: 28px;border-radius: 20px;background: #dedede;height: 28px;cursor: pointer" 
        [disabled]="activate_btn && !activate_btn.includes('NB_SRH')" (click)="openSearch()">
        <mat-icon style="width: 25px; font-size: 18px; line-height: 5px; color: #000000c4;"
        [ngStyle]="{opacity: activate_btn && !activate_btn.includes('NB_SRH') ? '0.4' : '1'}">search</mat-icon>
    </button>
</div>
<div class="nav-search-bar" *ngIf="isShowSearch">
    <mat-form-field class="global-input" #searchInfix style="background: #80808042;border-radius: 20px;height: 101%;border-bottom-right-radius: 20px;
                border-top-right-radius: 20px;" [floatLabel]="'never'">
        <div style="width: 100%;display: flex;position: relative;">
            <div class="search-icon ovi-lbl-text-size">
                <input i18n-placeholder matInput  placeholder="Search" #searchValue #autocompleteTrigger="matAutocompleteTrigger"
                    [formControl]="seachCtrl" [matAutocomplete]="auto" autofocus
                    (keyup)="inputText(searchValue.value)" (search)="clearDropdown(searchValue.value)" style="margin-left: 10px;margin-top: 3px;width: 98%;flex: 1 0 150px;
                      height: 30px; font-size: 15px;" autocomplete="off">
            </div>
            <!-- <mat-chip-list #chipList aria-label="Key Selection"></mat-chip-list>
            <div class="mat-chip-list-wrapper">
                <mat-chip *ngFor="let key of keys" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(key)">
                    {{key}}
                    <mat-icon matChipRemove *ngIf="removable" style="font-size: 16px;height: 21px;width: 18px;">cancel</mat-icon>
                </mat-chip>
            </div> -->
            <div style="float: right;">
                <button mat-icon-button [disableRipple]="true" class="glo-btt" style="height: 34px; cursor: default;" (click)="isShowSearch = false">
                    <mat-icon style="width: 21px; font-size: 21px; line-height: 12px; color: #000000c4;">search</mat-icon>
                </button>
            </div>
        </div>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" autoActiveFirstOption class="custom-dash-search-autocomplete" classList="custom-dash-search-autocomplete">
            <mat-grid-list cols="4" rowHeight="20px" style="width: inherit; margin:0px 0px;">
                <mat-grid-tile colspan="1" rowspan="2" class="cur-ptr" *ngFor="let filter of filterOptions">
                    <mat-option [value]="filter" class="nav-search-options" [ngStyle]="keys.includes(filter) ? {background: '#3A7AFE', color: 'white'} : {background: 'white', color: '#000000de'}">
                        <span i18n >{filter, select, Today {Today} Patient {Patient} Asset {Asset} User {User}}</span>
                    </mat-option>
                </mat-grid-tile>
            </mat-grid-list>
            <div class="search-result" *ngIf="typeInput">
                <div i18n class="tl-br-styl_2 ovi-lbl-text-size">
                    Enter input to search (Min 3 chars)
                </div>
            </div>
            <div class="search-result" *ngIf="searchlist && !this.searchClose">
                <div *ngIf="searchlist != 'empty'" style="padding-top: 5px; max-height: 208px;">
                    <div style="max-height: 180px;overflow-x: hidden; overflow-y: auto;" >
                    <div *ngFor="let option of searchlist">
                    <div class="search-result-body">
                        <div class="srch-list-style">
                            <img *ngIf="option.category == 'Patient'" class="pad-styl" height="20" width="20"
                                [src]="option.tagSerialNumber != null ? '../../../assets/Alert/common_icons/patient.svg' : '../../../assets/Alert/common_icons/patient_old.svg'">
                            <img *ngIf="option.category == 'CareProvider' || option.category == 'User' || option.category == 'Staff'" class="pad-styl" height="20" width="20"
                                src="../../../assets/Alert/common_icons/user.svg">
                            <img *ngIf="option.category == 'Asset'" class="pad-styl" height="25" width="25"
                                [src]="option.tagSerialNumber != null ? '../../../assets/Alert/common_icons/asset.svg' : '../../../assets/Alert/common_icons/asset_old.svg'">
                            <img *ngIf="option.category == 'Porter'" class="pad-styl" height="25" width="25"
                                src="../../../assets/Alert/common_icons/porter.svg">
                            <img *ngIf="option.category == 'MedicalRecord'" class="pad-styl" height="25" width="25"
                                src="../../../assets/Alert/common_icons/medical_record.svg">
                        </div>
        
                        <div class="ovi-lbl-text-size search-data cur-ptr" (click)="getSearchResult(option)" (click)="searchValue.value=''">
                            <a style="color: black;" *ngIf="option.category == 'Patient'"
                            matTooltip = "{{option.name ? option.name : ''}}{{option.tagSerialNumber ? ' [ '+ option.tagSerialNumber + ' ]' : ''}}{{option.gender ? ', '  + option.gender : ''}}{{option.age ?  ', '  + option.age : ''}}{{option.mobileNumber ?  ', '  + option.mobileNumber : ''}}">
                            {{option.name ? option.name : ''}}{{option.tagSerialNumber ? ' [ '+ option.tagSerialNumber + ' ]' : ''}}{{option.gender ? ', '  + option.gender : ''}}{{option.age ?  ', '  + option.age : ''}}{{option.mobileNumber ?  ', '  + option.mobileNumber : ''}}
                            </a>
                            <a style="color: black;" *ngIf="option.category == 'Asset'" matTooltip = "{{option.name ? option.name : ''}} {{option.tagSerialNumber ? ' [ ' + option.tagSerialNumber + ' ]' : ''}} {{option.tagSerialNumber && option.floorName && ( option.commlost == '1' ? ', '+'( Missing ) ' +  option.floorName  : ', ' +  option.floorName)}}{{option.currentLocationName ? ', ' +  option.currentLocationName : ''}}">
                                {{option.name ? option.name : ''}} {{option.tagSerialNumber ? ' [ ' + option.tagSerialNumber + ' ]' : ''}} {{option.tagSerialNumber && option.floorName && ( option.commlost == '1' ? ', '+'( Missing ) ' +  option.floorName  : ', ' +  option.floorName)}}{{option.currentLocationName ? ', ' +  option.currentLocationName : ''}}
                            </a>
                            <a style="color: black;" *ngIf="option.category != 'Patient' && option.category != 'Asset'" matTooltip = "{{option.name ? option.name : ''}}{{option.tagSerialNumber ? ' [ '+ option.tagSerialNumber + ' ]' : ''}}{{option.tagSerialNumber && option.floorName && ( option.commlost == '1' ?  ', '+'( Missing ) ' +  option.floorName : ', ' +  option.floorName)}}{{option.currentLocationName ? ', ' +  option.currentLocationName : ''}}{{option.gender ?  ', ' + option.gender : ''}}{{option.age ? ', ' + option.age : ''}}{{option.mobileNumber ?  ', ' + option.mobileNumber : ''}}">
                                {{option.name ? option.name : ''}}{{option.tagSerialNumber ? ' [ '+ option.tagSerialNumber + ' ]' : ''}}{{option.tagSerialNumber && option.floorName && ( option.commlost == '1' ?  ', '+'( Missing ) ' +  option.floorName : ', ' +  option.floorName)}}{{option.currentLocationName ? ', ' +  option.currentLocationName : ''}}{{option.gender ?  ', ' + option.gender : ''}}{{option.age ? ', ' + option.age : ''}}{{option.mobileNumber ?  ', ' + option.mobileNumber : ''}}
                            </a>
                        </div>
                    </div>
                    </div>
                    </div>
                    <div class="ovi-lbl-text-size tl-br-styl" appDebounceClick
                    (debounceClick)="globalSearch(searchlist[0]);" [debounceTime]="300"
                    (click)="searchValue.value=''" style="display: flex;justify-content: center;">
                             <a [ngClass]="searchlist != 'empty' ? 'tl-br-styl_1 search-data cur-ptr' : ''" >
                                <small i18n>More</small></a>
                    </div>
                </div>
                <div i18n class="tl-br-styl_2 ovi-lbl-text-size" *ngIf="searchlist == 'empty' && clearSearch == true">
                    Your search returned no matches.
                </div>
            </div>
            <!-- <mat-option *ngFor="let filter of filteredKeys | async" [value]="filter">
                    <mat-checkbox color="primary" style="padding: 0 12px;" [checked]="keys.indexOf(filter) >= 0"></mat-checkbox>
                    {{filter}}
                  </mat-option> -->
        </mat-autocomplete>
        <!-- <button mat-icon-button style="width: 34px; vertical-align: bottom;display: inline-grid;"
            [ngStyle]="{height: keys.length >= 3 ? '40px' : '35px'}" (click)="searchInput()">
            <mat-icon style="width: 34px;">search</mat-icon>
        </button> -->
    </mat-form-field>
</div>
