<div appWindowResize (resized)="onWindowResized($event)" (resizedWidth)="onWindowResizedWidth($event)" [component]="'pop-up'"
    [style.height.px]="popHeight" [style.width.px]="popWidth">
    <mat-toolbar class="input-header block-1" style="height: 35px;">
        <div i18n class="header-name close-align-map" style="text-overflow: ellipsis;">Track Location</div>
        <div class="header-close"><button mat-icon-button mat-dialog-close close-button class="ie-close-icon">
            <mat-icon class="icon-style">highlight_off</mat-icon>
        </button></div>
    </mat-toolbar>
    <div class="leaflet-view" style="overflow: auto;" [style.height.px]="this.contentHeight">
        <iframe width="99%" height="100%" title="googleDirectionApi" referrerpolicy="no-referrer-when-downgrade"
        [src]="urlSafe"></iframe>
    </div>
</div>
