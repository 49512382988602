/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
 ******************************************************************************/
import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { NotFoundComponent } from "./static/not-found/not-found.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoreModule } from "./core/core.module";
import { SidebarMenuModule } from "./shared/modules/entry-component/sidebar-menu/sidebar-menu.module";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Http_Interceptor } from "./../app/http-interceptor";
import { BnNgIdleService } from "bn-ng-idle"; // import bn-ng-idle service
import { MatNativeDateModule, DateAdapter } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DateFormat } from "./../app/shared/modules/entry-component/date-format";
import {
  AuthGuard,
  ErrorService,
  ApiService,
  CommonService,
  //  HttpService,
  ConfigurationService,
  HospitalService,
  DashboardService,
  ReportService,
  WorkflowService,
  // AssetService,  CustomValidators, ShortestPathAlgorithm,
  // NearestTagAlgorithm, Globals
} from "./shared";

import {
  ReaderResolver,
  HealthTestResolver,
  PackageResolver,
  RoutineResolver,
  ActiviteResolver,
  AlertResolver,
  AppTermResolver,
  layoutsResolver,
  formManagementResolver,
  dataItemResolver,
  widgetsResolver,
  widgetNewResolver,
  RuleResolver,
  ConfigResolver
} from "./shared/services/configuration.resolver";
import {
  EmployeeResolver,
  IPResolver,
  ResidentResolver,
  HealthCheckupResolver,
  MessageCentreResolver,
  OPResolver,
  OTResolver,
  PorterResolver,
  VisitorResolver,
  TaskResolver,
  DayCareResolver,
  EmergencyCareResolver,
  AmbulanceResolver,
  FormResolver,
  TicketResolver,
} from "./shared/services/workflow.resolver";
import { SharedPipesModule } from "./shared/pipes/shared-pipes.module";
import { CookieService } from "ngx-cookie-service";
import { DatePipe } from "@angular/common";
import { PushNotificationsService } from "./shared/services/push.notification.service";
import { VersionCheckService } from './shared/services/version-check.service';
import { ManageFacilityResolver } from "./ovitag/hospital/manage-facility/manage-facility.component";
import { UserManagementResolver } from "./ovitag/hospital/user-management/user-management.component";
import { GlobalErrorHandler } from "./shared/services/global-error-handler";
import {ToastrModule} from "ngx-toastr"

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule,
    SidebarMenuModule,
    BrowserAnimationsModule,
    SharedPipesModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right', 
      messageClass : 'toast-title'
    }),
    AppRoutingModule,
  ],
  providers: [
    // {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Http_Interceptor,
      multi: true,
    },
    { provide: DateAdapter, useClass: DateFormat },

    DatePipe,
    DashboardService,
    CookieService,
    AuthGuard,
    ErrorService,
    CommonService,
    ApiService,
    // CustomValidators, ShortestPathAlgorithm, NearestTagAlgorithm, AssetService,  Globals,
    // HttpService,
    ConfigurationService,
    HospitalService,
    ReportService,
    BnNgIdleService,
    WorkflowService,
    ReaderResolver,
    HealthTestResolver,
  
    PackageResolver,
    RoutineResolver,
    ActiviteResolver,
    AlertResolver,
    AppTermResolver,
    layoutsResolver,
    formManagementResolver,
    dataItemResolver,
    widgetsResolver,
    widgetNewResolver,
    RuleResolver,
    EmployeeResolver,
    IPResolver,
    ResidentResolver,
    HealthCheckupResolver,
    MessageCentreResolver,
    OPResolver,
    OTResolver,
    EmergencyCareResolver,
    PorterResolver,
    VisitorResolver,
    TaskResolver,
    DayCareResolver,
    PushNotificationsService,
    VersionCheckService,
    AmbulanceResolver,
    FormResolver,
    TicketResolver,
    ManageFacilityResolver,
    UserManagementResolver,
    ConfigResolver,
    
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
