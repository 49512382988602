/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Component, HostBinding, OnInit , Input, ViewEncapsulation, Output, ViewChild, ElementRef, Renderer2, ChangeDetectionStrategy} from '@angular/core';
import { MediaChange, MediaObserver as ObservableMedia } from '@angular/flex-layout';
import {CommonService } from '../shared';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import * as screenfull from 'screenfull';
​
@Component({
  selector: 'app-ovitag',
  templateUrl: './ovitag.component.html',
  styleUrls: ['./ovitag.component.scss'],
//   changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
​
})
​
export class OvitagComponent implements OnInit {
    @HostBinding('@.disabled')
    animationsDisabled = true;
    @Input() isVisible = true;
    @Input() sidebar: boolean;
    visibility = 'shown';
    code = 'ALL';
    menuCode = null;
    menuValue: string;
    sideNavOpened = false;
    matDrawerOpened = false;
    matDrawerShow = false;
    sideNavMode = 'side';
    public menu: any;
    menuOpen = true;
    drawer: any;
    existPrivateUser = false;
    public isMenuClicked = false;
    parentId = null;
    public menuClickedCode: any = [];
    username = localStorage.getItem(btoa('current_user'));
    public helpDocument: any;
    @ViewChild('sidenav', { static: true }) public sideNav: MatSidenav;
    customerName: string;
    ngOnChanges() {
        this.visibility = this.isVisible ? 'shown' : 'hidden';
    }
    constructor(private dialog: MatDialog,private media: ObservableMedia, private common: CommonService, private router: Router, private renderer: Renderer2) {
        router.events.pipe(
            filter(event => event instanceof NavigationEnd)  
          ).subscribe((event: NavigationEnd) => {
            if (event.url === '/ovitag') {
                this.parentId = 1;
                localStorage.setItem('menuId',(this.parentId));
            }
          });
          
    }
	ngOnInit() {
        if (window.location.hostname.includes("kyn")) {
            this.customerName = "kyn";
        }
        if (parseInt(localStorage.getItem('userlevel')) === 8) {
            this.helpDocument = '/assets/help/social-distance/index.htm';
        } else {
            this.helpDocument = '/assets/help/rtls/index.htm';
        }
        this.parentId = localStorage.getItem('menuId');
        this.existPrivateUser = ('privateUser' in localStorage);
		this.media.asObservable().subscribe((mediaChangeme: MediaChange[]) => {
            this.toggleView();
        });
        this.menu = this.common.getActivePermission('main_menu');
        if(['dev', 'demo'].indexOf(environment.env_key) == -1) {
            console.log = () => {};
            console.warn = () => {};
        }
        this.checkPermission();
        // let roleId = localStorage.getItem('userlevel');
        // let userId = localStorage.getItem(btoa('userId'));
        // this.common.getUserPreference(userId,roleId)
    }
    extendMenu() {
        this.menuOpen = true;
    }
    closeMenu() {
        this.menuOpen = false;
    }
    getRouteAnimation(outlet) {
       return outlet.activatedRouteData.animation;
       // return outlet.isActivated ? outlet.activatedRoute : ''
    }
    displayMenuName(name) {
        this.menuValue = name;
    }
    toggleViewClose() {
        this.sideNavOpened = false;
        // this.isVisible = false;
        // this.visibility = 'hidden';
        this.media.asObservable().subscribe((mediaChangeme: MediaChange[]) => {
            this.toggleView();
        });
        this.sideNav.toggle();
    }
    toggleView() {
        
            this.sideNavMode = 'over';
            this.sideNavOpened = false;
        
	}
    menuDetails(menu){
        localStorage.setItem(btoa('menuCode'),menu.code);
    }
    menuClicked(menu, parentId){
        if (parentId !== null){
            this.parentId = parentId;
            localStorage.setItem('menuId',(this.parentId));
        }
        if (menu !== null) {
            this.menuClickedCode = [menu];
            this.menuCode = this.menuClickedCode[0].code;
            if (this.menuClickedCode[0].link !== null) {
                this.parentId = this.menuClickedCode[0].id;
                localStorage.setItem('menuId',(this.parentId));
                this.menuClickedCode = [];
            }
        } else {
            this.menuClickedCode = [];
            this.menuCode = null;
        }
    }
​
    checkPermission() {
        let session = parseInt(localStorage.getItem(btoa('session_time')));
        const currentTime = new Date().getTime();
        const diff = (session - currentTime) / 1000;
        // console.log(diff)
        if(diff <= 0) {            
            let roleId = localStorage.getItem('userlevel');
            let userId = localStorage.getItem(btoa('userId'));
            this.common.getPermissionbyId(roleId, userId).subscribe((res) => {
                const permission = JSON.stringify(res.results);
                localStorage.setItem('permission', permission);
                localStorage.removeItem('urlLinks');
                let time = (new Date().getTime() + (1 * 60 * 60 * 1000)).toString()
                localStorage.setItem(btoa('session_time'), time)
                // localStorage.setItem('userlevel', roleId);
                console.log('permission updated..')
            })
        }
    }
    
}
