import { I } from "@angular/cdk/keycodes";
import { DatePipe } from "@angular/common";
import { Component, ElementRef, Input, OnInit, Renderer2, SimpleChanges, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { CompactType, GridsterConfig } from "angular-gridster2";
import { ModelWidgets } from "../../../../ovitag/dashboard/dashboard-emp/dashboard-emp.component";
import { ChartjsService, CommonService, DashboardService, WorkflowService, LayoutExcelService, LayoutPdfService, ExcelService } from "../../../services";
import { DashboardWidgetComponent } from "../dashboard-widget/dashboard-widget.component";
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import { ConfirmDialogComponent } from "../layout-save/layout-save.component";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: 'app-gridster-layout',
  templateUrl: './gridster-layout.component.html',
  styleUrls: ['./gridster-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class GridsterLayoutComponent implements OnInit {
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('widgets') widgets: ElementRef;
  @ViewChild('setting') setting: ElementRef;

  @Input() dashId: any;
  @Input() resourceCode: any = null;
  @Input() dashData: any;
  @Input() type: any;
  @Input() inputAction: any;
  public pdfConfigSelected: any ='default';
  public preresourceCode = null;
  public dashboardDetails: any = {};
  public floorId;
  public floorView = false;
  public today = this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
  public selectedDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  public formTemplateValue: any;

  // Gridster configurations
  public options: GridsterConfig;
  public layout = []; // GridsterItem[]
  public clayout = [];
  public defaultLayout = null;
  public isLayoutChanged = false;
  public userId = localStorage.getItem(btoa('userId'));
  public roleId = localStorage.getItem('userlevel');
  public facilityId = localStorage.getItem(btoa('facilityId'));
  public dashboardId = null;
  public screenLock = true;
  public active_btn: any = [];
  public maxHeight = 500;
  public isOpen = false;
  public isOptionOpen = false;
  public isOption: any = null;
  public isUpdateLayout = false;
  public preResponse = null;
  public isAutoRefresh = false;
  public autoInterval = null;
  public dashboardName = 'Dashboard';
  public currentDate: any = new Date();
  public fromDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
  public toDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
  public resultJson: any = null;
  public tempTable: any = [];
  public tableName: any = [];
  public tempChart: any = [];
  public gridsterConfig: any;

  constructor(private renderer: Renderer2, public dashboardService: DashboardService, public datepipe: DatePipe,
    private commonService: CommonService, public ChartService: ChartjsService, public layoutExcelService: LayoutExcelService, public excelService: ExcelService,
    public router: Router, private snackbar: MatSnackBar, public dialog: MatDialog, public workflowService: WorkflowService, public layoutPdfService: LayoutPdfService) {
    // this.onResize(window.innerWidth);
    this.active_btn = this.commonService.getActivePermission('button');
    this.getGridsterConfig();
    this.maxHeight = window.innerHeight - 60;
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.isOption === 'widgets') {
        if (!this.widgets.nativeElement['innerText'].includes(e.target['innerText']) && e.target['innerText'] !== 'widgets') {
          this.isOption = null;
          this.isOptionOpen = false;
        }
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.inputAction != undefined) {
      if (this.inputAction?.type === 'pdf') {
        this.downloadPDF();
      }
      if (this.inputAction?.type === 'excel') {
        this.downloadExcel();
      }
      if (this.inputAction?.type === 'getInsights' || this.inputAction?.type === 'refresh') {
        this.tempTable = [];
        this.tableName = [];
        if (this.inputAction.hasOwnProperty('dashData')) {
          let layout = this.inputAction.dashData.layouts;
          this.bindLayout(layout);
        }
      }
    }
  }
  ngOnInit() {
    if (this.type == 'popup') {
      this.isOpen = true;
    }
    this.checkMetaData();
  }
  checkMetaData() {
    this.gridsterConfiguration(this.screenLock);
    // this.currentWidget.setValue([]);
    // this.getDashboardLayout();
    // this.checkUserPreference();    
    this.dashboardDetails = {};
    this.clayout = [];
    this.layout = [];
    if (this.dashData) {
      this.dashboardDetails['update'] = true;
      this.preResponse = this.dashData;
      this.defaultLayout = JSON.stringify(this.dashData.layouts);
      this.dashboardId = this.dashData.dashboardId;
      this.bindLayout(this.dashData.layouts);
    } else if (this.dashId) {
      this.getDashboardbyId(this.dashId, this.resourceCode);
    } else {
      this.preresourceCode = this.resourceCode;
      this.checkUserPreference();
      //  this.getDashboardLayout(this.resourceCode);
    }
  }
  checkUserPreference() {
    const roleId = localStorage.getItem('userlevel');
    const userId = localStorage.getItem(btoa('userId'));
    this.commonService.getPreference(userId, roleId).subscribe(res => {
      this.commonService.userPreference = res.results;
      const preference = res.results;
      if (preference != null && preference.hasOwnProperty('layout_' + this.resourceCode)) {
        this.dashboardId = preference['layout_' + this.resourceCode].value;
        this.getDashboardbyId(this.dashboardId);
      } else {
        this.getDashboardLayout(this.resourceCode);
      }
      if (preference != null && preference.hasOwnProperty('layoutAutoRefresh')) {
        let layoutRefresh = preference.layoutAutoRefresh.value;
        // this.autoRefresh(JSON.parse(layoutRefresh))
      }
    });
  }
  getGridsterConfig() {
    this.commonService.getConfigFile('gridster-config').subscribe((res) => {
      if(res.statusCode === 1){
        this.gridsterConfig = res.results.contentObject;
      }
    });
  }
  getUserPreference(key = 'dashboard', value = this.dashboardId) {
    const preference = this.commonService.userPreference;
    if (key == 'dashboard' && this.resourceCode) {
      key = 'layout_' + this.resourceCode;
    }
    const postData = {
      'key': key,
      'roleId': localStorage.getItem('userlevel'),
      'userId': localStorage.getItem(btoa('userId')),
      'value': value
    };
    if (preference != null && value) {
      if (preference.hasOwnProperty(key)) {
        if (preference[key].value !== value) {
          const id = preference[key].id;
          this.commonService.updateUserPreference(id, postData).subscribe(res => {
            this.commonService.userPreference = res.results;
          });
        }
      } else {
        this.commonService.saveUserPreference(postData).subscribe(res => {
          this.commonService.userPreference = res.results;
        });
      }
    }
  }
  getDashboardbyId(dashId, resourceCode?: any) {
    this.dashboardDetails['update'] = true;
    const userId = localStorage.getItem(btoa('userId'));
    this.dashboardService.getDashboardbyIds(dashId, userId, resourceCode).subscribe(res => {
      if (res.statusCode === 1) {
        this.preResponse = res.results[0];
        this.dashboardName = res.results[0]?.dashboardName;
        this.tempTable = [];
        this.tableName = [];
        const input = JSON.parse(this.preResponse?.configValue);
        if(input.hasOwnProperty('pdfConfig')){
          this.pdfConfigSelected = input['pdfConfig']['selected'];
        }
        this.gridsterConfiguration(this.screenLock);
        this.defaultLayout = JSON.stringify(res.results[0].layouts);
        this.dashboardId = res.results[0].dashboardId;
        this.bindLayout(res.results[0].layouts);
      } else {
        this.openSnackbar(res.message, 'warning');
      }
    });
  }

  getDashboardLayout(resourceCode = null) {
    this.dashboardDetails['update'] = true;
    this.dashboardService.getCurrentDashboard(this.userId, this.roleId, resourceCode).subscribe(res => {
      if (res.statusCode === 1) {
        this.preResponse = res.results;
        this.dashboardName = res.results?.dashboardName;
        this.tempTable = [];
        this.tableName = [];
        const input = JSON.parse(this.preResponse?.configValue);
        if(input.hasOwnProperty('pdfConfig')){
          this.pdfConfigSelected = input['pdfConfig']['selected'];
        }
        this.gridsterConfiguration(this.screenLock);
        this.defaultLayout = JSON.stringify(res.results.layouts);
        this.dashboardId = res.results.dashboardId;
        this.bindLayout(res.results.layouts);
        this.getUserPreference();
      } else {
        if (res.statusCode === 0) {
          this.getLayoutList();
        } else {
          this.openSnackbar(res.message, 'warning');
        }
      }
    });
  }
  getLayoutList() {
    this.dashboardService.getDashboardDetailsList(this.userId).subscribe(res => {
      if (res.statusCode === 1) {
        let layoutList = res.results;
        if (this.resourceCode) {
          layoutList = layoutList.filter(val => val.linkedResourceCode == this.resourceCode);
          if (layoutList.length) {
            this.dashboardId = layoutList[0].id;
            this.getDashboardbyId(this.dashboardId);
            this.getUserPreference();
          } else {
            this.openSnackbar('Layout is empty.', 'warning');
          }
        }
      }
    });
  }
  bindLayout(layout) {
    this.tempChart = [];
    for (let i = 0; i < layout.length; i++) {
      if (layout[i].widgetTypeId === 'WT-FORM') {
        let temp_lay = layout.splice(i, 1);
        layout.unshift(temp_lay[0]);
      }
    }
    if (this.facilityId === localStorage.getItem(btoa('facilityId'))) {
      this.isLayoutChanged = false;
      if (this.defaultLayout !== JSON.stringify(layout)) {
        if (!this.isUpdateLayout) {
          this.isLayoutChanged = true;
        } else {
          this.isLayoutChanged = false;
        }
      }
      this.layout = [];
      this.clayout = [];
      // this.dashboardDetails = {};
      for (let i = 0; i < layout.length; i++) {
        try {
          this.layout.push(layout[i]);
          if (layout[i].isActive) {
            if (layout[i].code !== 'WD_DBAS' && layout[i].code !== 'WD_DBAST') {
              this.clayout.push(layout[i]);
              if (layout[i].code === 'WD_DBFP' || layout[i].code === 'WD_DBALERT' || layout[i].widgetTypeId === 'WT-FORM') {
                if (layout[i].widgetTypeId === 'WT-FORM' && this.dashboardDetails.hasOwnProperty(layout[i].code)) {
                  this.dashboardDetails[layout[i].code]['count'] = Number(this.dashboardDetails[layout[i].code]['count']) + 1;
                  continue
                }
                this.dashboardDetails[layout[i].code] = { 'show': true, 'count': 0 };
                continue;
              }
              if (this.dashboardDetails['update']) {
                if (layout[i].widgetTypeId === 'WT-FORM') {
                  this.dashboardDetails[layout[i].code]['show'] = true;
                } else {
                  this.dashboardDetails[layout[i].code] = { 'show': false };
                }
                // if (layout[i].modelTypeId === 'MT-QRY' || layout[i].modelTypeId === 'MT-RPT') {
                //   this.getDynamicChartData(layout[i]);
                // } else 

                // if (layout[i].modelTypeId === 'MT-API') {
                //     console.log("layout[i].code:",layout[i].code);
                //     this['callWidget_' + layout[i].code](layout[i].code);
                // }
              }
            }
          }
          // if (i === layout.length - 1 ) {
          //   this.currentWidget.setValue(this.clayout.map(val => val.code));
          //   this.widgetValue = this.clayout.map(val => val.code);
          // }
        } catch (e) {
          console.log(layout[i].code + 'widget not found');
          console.log(e);
        }
      }
      layout = layout.filter(res => ['WD_DBAS', 'WD_DBAST', 'WD_DBPWCT', 'WD_DBLUZ', 'WD_DBHP', 'WD_DBPATSUM', 'WD_DBEMPSDS'].indexOf(res.code) === -1);
      let modelLayout = new Map<string, ModelWidgets>();
      for (let i = 0; i < layout.length; i++) {
        if (layout[i].widgetTypeId !== "WT-FORM" && layout[i].isActive && (layout[i].modelTypeId == "MT-QRY" || layout[i].modelTypeId == "MT-RPT")) {
          if (!modelLayout.has(layout[i].modelId)) {
            modelLayout.set(layout[i].modelId, { widgetIds: layout[i].widgetId, layouts: new Array(layout[i]) });

          }
          else {
            modelLayout.get(layout[i].modelId).widgetIds = modelLayout.get(layout[i].modelId).widgetIds + "," + layout[i].widgetId
            modelLayout.get(layout[i].modelId).layouts.push(layout[i])
          }
        } else if (layout[i].isActive && layout[i].widgetTypeId == "WT-FORM" && this.dashboardDetails[layout[i].code]['count'] == 0) {
          modelLayout.set(layout[i].widgetId, { widgetIds: layout[i].widgetId, layouts: new Array(layout[i]) });
        } else if (layout[i].isActive && layout[i].widgetTypeId == "WT-CHART") {
          modelLayout.set(layout[i].widgetId, { widgetIds: layout[i].widgetId, layouts: new Array(layout[i]) });
        }
      }
      modelLayout.forEach((value: ModelWidgets, key: string) => {
        this.getDynamicChartData(value);
      });


      // if (layout.length >0 ) {
      // this.currentWidget.setValue(this.clayout.map(val => val.code));
      // this.widgetValue = this.clayout.map(val => val.code);
      // }
      if (this.isUpdateLayout) {
        this.saveWidgets(this.layout, true);
      }
    } else {
      this.facilityId = localStorage.getItem(btoa('facilityId'));
      this.checkUserPreference();
    }
  }
  refreshLayout() {
    if (this.isLayoutChanged) {
      this.dashboardDetails['update'] = true;
      this.bindLayout(JSON.parse(this.defaultLayout));
    } else {
      this.dashboardDetails['update'] = true;
      this.bindLayout(this.layout);
    }
  }
  autoRefresh(value) {
    this.isAutoRefresh = value;

    if (this.isAutoRefresh) {
      this.autoInterval = setInterval((val) => this.refreshLayout(), environment.base_value.layout_autorefresh_time);
    } else {
      clearInterval(this.autoInterval);
    }
    if (typeof (value) == 'boolean') {
      value = value.toString();
    }
    this.getUserPreference('layoutAutoRefresh', value);
  }
  updateLayout(gridster, preLayout, type) {
    const layout = [];
    const grid = gridster.gridster.grid;

    for (const i in grid) {
      if (i != null) {
        layout.push(grid[i].item);
      }
    }
    // if (JSON.stringify(layout) ===  preLayout) {
    //   return;
    // }
    // const hiddenLayout = this.layout.filter(res => res.isActive === false);
    // for (const i in hiddenLayout) {
    //   if (i != null) {
    //     layout.push(hiddenLayout[i]);
    //   }
    // }
    // this.dashboardDetails['update'] = false;
    // this.bindLayout(layout);

    preLayout = JSON.parse(this.defaultLayout);
    preLayout = JSON.stringify(preLayout.filter(res => res.isActive));
    this.isLayoutChanged = false;
    if (preLayout !== JSON.stringify(layout)) {
      if (!this.isUpdateLayout) {
        this.isLayoutChanged = true;
      } else {
        this.isLayoutChanged = false;
      }
    }
    this.layout = layout;
  }
  editWidget() {
    const data = this.preResponse;
    data.layouts = this.layout;
    const dialogRef = this.dialog.open(DashboardWidgetComponent, {
      data: data, panelClass: 'custom-widget-popup-container', disableClose: true
    });
    dialogRef.componentInstance.widgetShow.subscribe(res => {
      // this.getPopupWidget(res)
      this.dashboardDetails['update'] = true;
      if (res.isFactory) {
        res.userId = null;
        res.facilityId = null;
      } else {
        res.userId = this.userId;
        res.facilityId = this.facilityId;
      }
      this.saveCurrentDashboard(res);
    });
  }
  selectedOption(event, open) {
    if (open === true) {
      this.isOption = event;
    } else {
      this.isOption = null;
    }
  }

  alignGrid() {
    this.options.compactType = 'compactUp&Left';
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
    this.isLayoutChanged = true;
    this.options.compactType = 'none';
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }
  saveWidgets(layout, update = false) {
    const jsonData = {
      'dashboardId': this.dashboardId,
      'facilityId': this.facilityId,
      'code': this.preResponse.code,
      'dashboardName': this.preResponse.dashboardName,
      'userId': this.userId,
      'layouts': layout,
          };
    
    if (!this.preResponse.userId) {
      if ((this.active_btn.indexOf('BT_DBFAC') === -1)) {
        this.refreshLayout()
        this.openSnackbar('You are not Authorized to edit the Factory Layout', 'warning');
      } else {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          panelClass: ['confirmation-popup'],
          data: {
            title: 'Confirmation',
            message: 'Do you want to update the Factory layout ?',
            buttonText: {
              ok: 'Yes',
              cancel: 'No'
            }
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'Yes') {
            this.saveCurrentDashboard(jsonData);
          } else {
            this.refreshLayout()
            this.openSnackbar('Layout changes are reverted', 'warning');
          }
        });
      }
    } else {
      this.saveCurrentDashboard(jsonData);
    }
  }
  saveCurrentDashboard(jsonData) {
    this.dashboardService.saveCurrentDashboard(jsonData).subscribe(res => {
      this.preResponse = res.results;
      this.dashboardId = res.results.dashboardId;
      this.getUserPreference();
      this.defaultLayout = JSON.stringify(res.results.layouts);
      this.openSnackbar(res.message, 'success');
      this.bindLayout(res.results.layouts);
    });
    this.isUpdateLayout = false;
  }


  getPopupWidget(data) {
    for (let i = 0; i < this.layout.length; i++) {
      if (data.find(val => val.id === this.layout[i].id)) {
        this.layout[i]['isActive'] = true;
      } else {
        this.layout[i]['isActive'] = false;
      }
    }
    this.isUpdateLayout = true;
    this.dashboardDetails['update'] = true;
    this.bindLayout(this.layout);
  }

  processDynamicChartDataRes(modelWidgets, res) {
    let layout = null;
    for (let i = 0; i < modelWidgets.layouts.length; i++) {
      layout = modelWidgets.layouts[i];
      if(res['results'].hasOwnProperty(layout.widgetId)){
        this.dashboardDetails[layout.code] = { 'show': false };
        if (layout.widgetTypeId === 'WT-BAR' || layout.widgetTypeId === 'WT-HBAR' ||
          layout.widgetTypeId === 'WT-PIE' || layout.widgetTypeId === 'WT-LINE' || layout.widgetTypeId === 'WT-CHART') {

          const chartValue = res.results[layout.widgetId];
          if (chartValue != null) {
            // if(chartValue.xAxisLabel && chartValue.xAxisLabel != ''){
              //   chartValue.options.options.scales.xAxes[0].scaleLabel.labelString = chartValue.xAxisLabel;
            // }else{
              //   chartValue.options.options.scales.xAxes[0].scaleLabel.labelString = '';
              //   chartValue.options.options.scales.xAxes[0].scaleLabel.display = false;
            // }
            // if(chartValue.yAxisLabel && chartValue.yAxisLabel != ''){
              //   chartValue.options.options.scales.yAxes[0].scaleLabel.labelString = chartValue.yAxisLabel;
            // }else{
              //   chartValue.options.options.scales.yAxes[0].scaleLabel.labelString = '';
              //   chartValue.options.options.scales.yAxes[0].scaleLabel.display = false;
            // }
            if (layout.widgetTypeId === 'WT-BAR' || layout.widgetTypeId === 'WT-HBAR') {
              const dataset = [];
              const chartBackground = [];
              if (chartValue.options.hasOwnProperty('text') && chartValue.options['text'] !== '') {
                this.dashboardDetails[layout.code]['text'] = chartValue.options['text'];
              }
              // binding array as object(for simple barchart)
              // check the data is array
              if (typeof chartValue.data[0] != "object") {
                if (chartValue.data.length > 0) {
                  let tempDat = chartValue.data.slice();
                  chartValue.data[0] = {};
                  chartValue.data[0].data = tempDat;
                } else {
                  chartValue.data[0] = {};
                  chartValue.data[0].data = [];
                }
              }
              for (let i = 0; i < chartValue.data.length; i++) {
                dataset.push({ label: chartValue.data[i].label, data: chartValue.data[i].data });
                if (chartValue.options['colorOptions'] && chartValue.options['colorOptions'].length) {
                  if (chartValue.options['colorOptions'][i] !== undefined) {
                    chartBackground.push(chartValue.options['colorOptions'][i]);
                  } else if (chartValue.options['colorOptions'][i] === undefined) {
                    chartBackground.push({ backgroundColor: this.commonService.getRandomColor(), borderColor: 'grey' });
                  }
                } else {
                  chartBackground.push({ backgroundColor: this.commonService.getRandomColor(), borderColor: 'grey' });
                }
                // if(chartValue.options['colorOptions'] && chartValue.options['colorOptions'].length == chartValue.data.length){
                //   chartBackground = chartValue.options['colorOptions'];
                // } else{
                //   chartBackground.push({backgroundColor: this.commonService.getRandomColor(), borderColor: 'grey'})
                // }
              }
              if (layout.widgetTypeId === 'WT-BAR') {
                this.bindDynamicCharts(layout.code, 'bar', dataset, chartValue.labels, null, chartBackground, 'Date', 'Count',
                  chartValue.options.options);
              } else if (layout.widgetTypeId === 'WT-HBAR') {
                this.bindDynamicCharts(layout.code, 'horizontalBar', dataset, chartValue.labels, null, chartBackground, 'Date', 'Count',
                  chartValue.options.options);
              }
            } else if (layout.widgetTypeId === 'WT-LINE') {
              const dataset = [];
              const chartBackground = [];
              if (chartValue.options.hasOwnProperty('text') && chartValue.options['text'] !== '') {
                this.dashboardDetails[layout.code]['text'] = chartValue.options['text'];
              }
              for (let i = 0; i < chartValue.data.length; i++) {
                dataset.push({ label: chartValue.data[i].label, data: chartValue.data[i].data, fill: false });
                if (chartValue.options['colorOptions'] && chartValue.options['colorOptions'].length) {
                  if (chartValue.options['colorOptions'][i] !== undefined) {
                    chartBackground.push(chartValue.options['colorOptions'][i]);
                  } else if (chartValue.options['colorOptions'][i] === undefined) {
                    chartBackground.push({ borderColor: this.commonService.getRandomColor() });
                  }
                } else {
                  chartBackground.push({ borderColor: this.commonService.getRandomColor() });
                }
              }
              this.bindDynamicCharts(layout.code, 'line', dataset, chartValue.labels, null, chartBackground, 'Date', 'Count',
                chartValue.options.options);
            } else if (layout.widgetTypeId === 'WT-PIE') {
              let chartBackground = [];
              const pieBackgroundColor = [{ backgroundColor: [] }];
              if (chartValue.options.hasOwnProperty('text') && chartValue.options['text'] !== '') {
                this.dashboardDetails[layout.code]['text'] = chartValue.options['text'];
              }
              if (chartValue.options['colorOptions'] && chartValue.options['colorOptions'][0].backgroundColor.length) {
                for (let i = 0; i < chartValue.data.length; i++) {
                  if (chartValue.options['colorOptions'][0].backgroundColor[i] !== undefined) {
                    pieBackgroundColor[0].backgroundColor.push(chartValue.options['colorOptions'][0].backgroundColor[i]);
                  } else if (chartValue.options['colorOptions'][0].backgroundColor[i] === undefined) {
                    pieBackgroundColor[0].backgroundColor.push(this.commonService.getRandomColor());
                  }
                }
              } else {
                for (let i = 0; i < chartValue.data.length; i++) {
                  pieBackgroundColor[0].backgroundColor.push(this.commonService.getRandomColor());
                }
              }
              chartBackground = pieBackgroundColor;
              if(chartValue.options.hasOwnProperty('colorMap')) {
                let colorDetails = chartValue.options.colorMap;
                for (let i = 0; i <  Object.keys(colorDetails).length; i++) {
                  let labelKey = Object.keys(colorDetails)[i]
                  let labelIndex = chartValue.labels.indexOf(labelKey)
                  chartBackground[0]['backgroundColor'][labelIndex] = colorDetails[labelKey]
                }
              }
              this.bindDynamicCharts(layout.code, 'pie', chartValue.data, chartValue.labels, 'Count', chartBackground, 'Zone', 'Count',
                chartValue.options.options);
            } else if (layout.widgetTypeId === 'WT-CHART') {
              this.dynamicChartBind(layout, chartValue)
            }
            this.tempChart.push(layout.code);
          }

        } else if (layout.widgetTypeId === 'WT-TABLE') {
          // this.dashboardDetails[layout.code]['styles'] = {
          //   'th' : {'background': 'red', 'color' : 'white', 'font-size' : '22px'},
          //   'tr' : {'background': 'red', 'color' : 'white'}
          // };
          this.dashboardDetails[layout.code]['styles'] = {
            'th': '',
            'tr': ''
          };
          const tableData = res.results[layout.widgetId];
          this.dashboardDetails[layout.code]['pagination'] = false;
          this.dashboardDetails[layout.code]['pageSizeOptions'] = false;
          this.dashboardDetails[layout.code]['enableExcel'] = false;
          this.dashboardDetails[layout.code]['filterBy'] = null;
          if (tableData != null) {
            if (tableData.options.hasOwnProperty('pagination')) {
              this.dashboardDetails[layout.code]['pagination'] = tableData.options['pagination'];
            }
            if (tableData.options.hasOwnProperty('pageSizeOptions') && tableData.options['pageSizeOptions'] && tableData.options['pageSizeOptions'].length) {
              this.dashboardDetails[layout.code]['pageSizeOptions'] = tableData.options['pageSizeOptions'];
            }
            if (tableData.options.hasOwnProperty('enableExcel')) {
              this.dashboardDetails[layout.code]['enableExcel'] = tableData.options['enableExcel'];
            }
            if (tableData.options.hasOwnProperty('filterBy')) {
              this.dashboardDetails[layout.code]['filterBy'] = tableData.options['filterBy'];
              if (tableData['options'].hasOwnProperty('filterBy') && tableData['options']['filterBy'].hasOwnProperty('enabled')) {
                this.dashboardDetails[layout.code]['filterBy']['option'] = tableData['data'].map(val => val[tableData.options['filterBy']['key']])
              }
            }

            if (tableData.options.hasOwnProperty('styles')) {
              this.dashboardDetails[layout.code]['styles'] = tableData.options['styles'];
            }
            if (layout.code === 'WD_DBHP') {
              let str = JSON.stringify(tableData.data);
              str = str.replace(/\"Avg TAT\":/g, "\"Avg. Time(mins)\":");
              tableData.data = JSON.parse(str);

              str = JSON.stringify(tableData.data);
              str = str.replace(/\"Total Patients Enrolled\":/g, "\"Patient Count\":");
              tableData.data = JSON.parse(str);
            }
            if (tableData.options.hasOwnProperty('text') && tableData.options['text'] !== '') {
              this.dashboardDetails[layout.code]['text'] = tableData.options['text'];
            }

            this.dashboardDetails[layout.code]['label'] = null;
            if ('model-mapping' in tableData && 'lables' in tableData['model-mapping']
              && tableData['model-mapping']['lables'].length > 0) {
              this.dashboardDetails[layout.code]['label'] = tableData['model-mapping']['lables'];
            }


            this.dashboardDetails[layout.code]['type'] = 'table';
            this.dashboardDetails[layout.code]['data'] = tableData.data;
            if (tableData.labels) {
              this.dashboardDetails[layout.code]['column'] = tableData.labels;
            } else if (tableData.label) {
              this.dashboardDetails[layout.code]['column'] = tableData.label;
            }
            this.dashboardDetails[layout.code]['tableStyle'] = {};
            if (tableData.options.options) {
              this.dashboardDetails[layout.code]['tableStyle'] = tableData.options.options;
            }
            this.dashboardDetails[layout.code]['show'] = true;
            if (this.dashboardDetails[layout.code]['data'].length > 0 && (this.tableName.find(res => res === layout['widgetName']) === undefined)) {
              this.tempTable.push(this.dashboardDetails[layout.code]['data']);
              this.tableName.push(layout['widgetName']);
            }
          }

        } else if (layout.widgetTypeId === 'WT-CARD') {

          const cardData = res.results[layout.widgetId];
          if (cardData != null) {
            this.dashboardDetails[layout.code]['cardStyle'] = res.results[layout.widgetId].options.style;
            if (cardData.options.hasOwnProperty('text') && cardData.options['text'] !== '') {
              cardData.widgetName = cardData.options['text'];
            }
            this.dashboardDetails[layout.code]['type'] = 'card';
            this.dashboardDetails[layout.code]['show'] = true;
            const options = res.results[layout.widgetId].options.options;
            if (options.backgroundColor) {
              if (options.icon === '') {
                this.dashboardDetails[layout.code]['maxCol'] = options.col;
                this.dashboardDetails[layout.code]['detail'] = [
                  {
                    'type': 'text', 'col': options.col, 'bg-color': options.backgroundColor, 'color': options.color,
                    'icon': '', 'fontSize': options.fontSize, 'fontColor': options.fontColor, 'id': layout.code,
                    'title': cardData.widgetName, 'data': { data: cardData.data, label: cardData.labels }
                  }
                ];
              } else {
                this.dashboardDetails[layout.code]['maxCol'] = options.col;
                this.dashboardDetails[layout.code]['detail'] = [
                  {
                    'type': 'text', 'col': options.col, 'bg-color': options.backgroundColor, 'color': options.color,
                    'icon': options.icon, 'iconWidth': options.iconWidth, 'fontSize': options.fontSize,
                    'fontColor': options.fontColor, 'id': layout.code,
                    'title': cardData.widgetName, 'data': cardData.data[0], 'label': cardData.labels[0]
                  }
                ];
              }
            } else {
              this.dashboardDetails[layout.code]['maxCol'] = 3;
              this.dashboardDetails[layout.code]['detail'] = [
                {
                  'type': 'text', 'col': 3, 'bg-color': '#58edd7', 'color': '#656968', 'icon': '', 'fontSize': '25px',
                  'fontColor': '#4c4949', 'id': layout.code, 'title': cardData.widgetName,
                  'data': { data: cardData.data, label: cardData.labels }
                }
              ];
            }
          }

        } else if (layout.widgetTypeId === 'WT-HDR') {

          const headerData = res.results[layout.widgetId];
          if (headerData != null) {
            if (headerData.options.hasOwnProperty('text') && headerData.options['text'] !== '') {
              headerData.widgetName = headerData.options['text'];
            }
            this.dashboardDetails[layout.code]['type'] = 'header';
            this.dashboardDetails[layout.code]['show'] = true;
            const options = res.results[layout.widgetId].options.options;
            if (options.backgroundColor) {
              this.dashboardDetails[layout.code]['detail'] = [{
                'bg-color': options.backgroundColor, 'color': options.color,
                'icon': options.icon !== '' ? options.icon : '', 'iconWidth': options.iconWidth,
                'fontSize': options.fontSize, 'fontColor': options.fontColor, 'id': layout.code, 'title': headerData.widgetName
              }];
            } else {
              this.dashboardDetails[layout.code]['detail'] = [{
                'bg-color': '#b1dafcc9', 'color': '#656968', 'icon': '', 'fontSize': '25px',
                'fontColor': '#4c4949', 'id': layout.code, 'title': headerData.widgetName
              }];
            }
          }

        } else if (layout.widgetTypeId === 'WT-FORM') {
          this.dashboardDetails[layout.code]['type'] = 'form';
          let menuCode = localStorage.getItem(btoa('menuCode'));
          this.dashboardService.getDashboardWidgetData(layout['widgetId'], null).subscribe(res => {
            if (res.statusCode === 1 && res.results[layout['widgetId']].hasOwnProperty('pfFormTemplateData')) {
              this.dashboardDetails[layout.code]['formTemplateData'] = res.results[layout['widgetId']]['pfFormTemplateData'];
              this.dashboardDetails[layout.code]['formTemplateData']['jsonValue'] = JSON.parse(this.dashboardDetails[layout.code]['formTemplateData']['jsonValue']);
              this.dashboardDetails[layout.code]['formTemplateData']['css'] = JSON.parse(this.dashboardDetails[layout.code]['formTemplateData']['css']);
              this.dashboardDetails[layout.code]['show'] = true;
            }
          });
          // this.dashboardDetails[layout.code]['formTemplateData']={"data":[],"options":[],"widgetName":layout.resourceName,"widgetType":layout.widgetTypeId,
          // "formTemplate":{id  : 1,
          //   name : "filterForm",
          //   description  : "new form template",
          //   type : "fixed",
          //   css  : {'background-color':'#b2e6f8e6'},
          //   "col" : 2,
          //   jsonValue : [
          //       [{
          //         id : 1, name : menuCode, type : "label", defaultValue : null, validation : null,
          //         customCss : null, labelName : menuCode, status : true, modelId : null,sequence : 1, value : null
          //         },
          //         {
          //           id : 2, name : "lid", type : "searchText", defaultValue : null, validation : null,
          //           customCss : null, labelName : "Location Search", status : true, modelId : null,sequence : 3, value : null
          //         },
          //         {
          //           id : 3, name : "From Date", type : "date", defaultValue : this.fromDate, validation : {
          //               "required": true},
          //           customCss : null, labelName : "From Date", status : true, modelId : null,sequence : 4, value : null
          //         },
          //         {
          //         id : 4, name : "To Date", type : "date", defaultValue : this.toDate, validation : null,
          //         customCss : null, labelName : "To Date", status : true, modelId : null,sequence : 5, value : null
          //         },
          //         {
          //             id : 5, name : "Submit", type : "getIns", defaultValue : null, validation : null,
          //             customCss : null, labelName : "Get Insights", status : true, modelId : null,sequence : 6, value : null
          //         }]
          //   ]}};
        }
      }
    }
  }

  // TABLE WIDGET STARTS
  getDynamicChartData(modelWidgets: ModelWidgets) {
    let modelInput = null;
    let headerParamKey = [];
    // after clicking getinsights from dynamic menu the input param is update directly by inputAction json
    if (this.inputAction != undefined && this.inputAction.hasOwnProperty('paramJson')) {
      // const widgetParam = [];
      // this.inputAction.paramJson['fid'] = localStorage.getItem(btoa('facilityId')); 
      let params = this.inputAction.paramJson;
      headerParamKey = Object.keys(params)
      // headerParamKey.forEach((key, index) => {
      //   if (index == 0) {
      //     widgetParam.push(key + '=' + params[key]);
      //   } else {
      //     widgetParam.push('&' + key + '=' + params[key]);
      //   }
      // })
      // modelInput = widgetParam.toString();
      // modelInput = modelInput.replace(/,&/g, '&');
      // this.dashboardService.getDashboardWidgetData(modelWidgets.widgetIds, modelInput).subscribe(res => {
      //   if (res.statusCode === 1) {
      //     this.processDynamicChartDataRes(modelWidgets, res)
      //   }

      // });
    }
    // else {
    // compare every widget input params and separate the widgets with default value
    let widIds = modelWidgets.widgetIds ? String(modelWidgets.widgetIds).split(',') : [];
    let wid_map = new Map();
    let widSameParam = "";
    let widSameParamLayouts = [];
    for (let wid = 0; wid < widIds.length; wid++) {
      let iparam = modelWidgets.layouts[wid].inputParams;
      if (iparam.includes('=')) {
        wid_map.set(widIds[wid], [modelWidgets.layouts[wid]])
      } else {
        if (widSameParamLayouts.length) {
          widSameParam = widSameParam + "," + widIds[wid];
        } else {
          widSameParam = widIds[wid];
        }
        widSameParamLayouts.push(modelWidgets.layouts[wid])
      }
    }
    if (widSameParamLayouts.length){
      wid_map.set(widSameParam, widSameParamLayouts);
    }
    wid_map.forEach((value: any, key: string) => {
      modelInput = null;
      const widgetParam = [];
      //  let layout = modelWidgets.layouts[0];
      let layout = value[0];
      let formBtnValidation = null;
      if (this.formTemplateValue && this.formTemplateValue[2].hasOwnProperty('validation')) {
        try {
          formBtnValidation = JSON.parse(this.formTemplateValue[2]['validation']);
        } catch (error) { }
      }
      let iparam = "inputParams";
      // check widget input params with default value 
      if(!layout.inputParams?.includes('=')){
        iparam = "modelInputParams";
      }
      const inputParam = layout[iparam] ? layout[iparam].split(',') : [];
      
      let layoutParam = layout.widgetParam ? layout.widgetParam.split(',') : [];
      if (formBtnValidation && formBtnValidation.hasOwnProperty('action') && formBtnValidation['action'] == "get") {
        let paramIndex = 0;
        let formTempData = this.formTemplateValue[0].value;
        Object.keys(formTempData).forEach(key => {
          if (key != 'gis' && key != 'pdf' && key != 'excel') {
            if (paramIndex == 0) {
              widgetParam.push(key + '=' + formTempData[key]);
            } else {
              widgetParam.push('&' + key + '=' + formTempData[key]);
            }
            paramIndex = paramIndex + 1;
          }
        });

      } else if (layout.inputParams && inputParam.length) {
        for (let i = 0; i < inputParam.length; i++) {
          if (!headerParamKey.includes(inputParam[i])) {
            if (inputParam[i] === 'fid' || inputParam[i] === 'facility_id') {
              if (widgetParam.length === 0) {
                widgetParam.push(inputParam[i] + '=' + localStorage.getItem(btoa('facilityId')));
              } else {
                widgetParam.push('&' + inputParam[i] + '=' + localStorage.getItem(btoa('facilityId')));
              }
            } else if (inputParam[i] === 'fdt') {
              if (widgetParam.length === 0) {
                if (this.fromDate) {
                  widgetParam.push(inputParam[i] + '=' + this.fromDate);
                } else {
                  widgetParam.push(inputParam[i] + '=' + this.selectedDate);
                }
              } else {
                if (this.fromDate) {
                  widgetParam.push('&' + inputParam[i] + '=' + this.fromDate);
                } else {
                  widgetParam.push('&' + inputParam[i] + '=' + this.selectedDate);
                }
              }
            } else if (inputParam[i] === 'tdt') {
              if (widgetParam.length === 0) {
                if (this.toDate) {
                  widgetParam.push(inputParam[i] + '=' + this.toDate);
                } else {
                  widgetParam.push(inputParam[i] + '=' + this.selectedDate);
                }
              } else {
                if (this.toDate) {
                  widgetParam.push('&' + inputParam[i] + '=' + this.toDate);
                } else {
                  widgetParam.push('&' + inputParam[i] + '=' + this.selectedDate);
                }
              }
            } else if (inputParam[i] === 'lid') {
              if (this.resultJson) {
                let locData = this.resultJson[0].filter(x => x.key === 'lid');
                let lid = locData[0]['value']
                if (widgetParam.length === 0) {
                  widgetParam.push(inputParam[i] + '=' + lid);
                } else {
                  widgetParam.push('&' + inputParam[i] + '=' + lid);
                }
              }
            } else if (inputParam[i].includes('=')) {
              if (widgetParam.length === 0) {
                widgetParam.push(inputParam[i]);
              } else {
                widgetParam.push('&' + inputParam[i]);
              }
            }
          } else{
            if (widgetParam.length === 0) {
              widgetParam.push(inputParam[i] + '=' + this.inputAction.paramJson[inputParam[i]]);
            }else{
              widgetParam.push('&' +inputParam[i] + '=' + this.inputAction.paramJson[inputParam[i]]);
            }
          }
        }
      }
      if (layout.widgetParam && layoutParam.length) {
        for (let i = 0; i < layoutParam.length; i++) {
          // set default value from widget param of layout
          if (layoutParam[i].includes('=')) {
            let key = layoutParam[i].split("=")
            let index = widgetParam.findIndex(val => val.includes(key[0]))
            if (index != -1) {
              widgetParam.splice(index, 1)
            }
            if (widgetParam.length === 0) {
              widgetParam.push(layoutParam[i]);
            } else {
              widgetParam.push('&' + layoutParam[i]);
            }
          }
        }
      }
      modelInput = widgetParam.toString();
      modelInput = modelInput.replace(/,&/g, '&');
      this.dashboardService.getDashboardWidgetData(key, modelInput).subscribe(res => {
        if (res.statusCode === 1) {
          this.processDynamicChartDataRes(modelWidgets, res)
        }
      });
    })
  // }

  }


  goToReport(code) {
    if (code === 'WD_DBLUZ') {
      this.router.navigate(['/ovitag/analytic-insights/patient-hc'], { queryParams: { report: 'coaster-details' } });
    }
  }

  dynamicChartBind(layout, chartValue) {
    let dataset = [];
    const chartBackground = [];
    if (chartValue.options.hasOwnProperty('text') && chartValue.options['text'] !== '') {
      this.dashboardDetails[layout.code]['text'] = chartValue.options['text'];
    }
    let type = null;
    let key = 'data'
    if (chartValue.hasOwnProperty('datasets') && chartValue.datasets.length) {
      key = 'datasets'
      type = chartValue.datasets[0]['type']
      dataset = chartValue.datasets;
    }
    for (let i = 0; i < chartValue[key].length; i++) {
      if (key == 'data') {
        dataset.push({ label: chartValue.data[i].label, data: chartValue.data[i].data, fill: false });
      }
      if (chartValue.options['colorOptions'] && chartValue.options['colorOptions'].length) {
        if (chartValue.options['colorOptions'][i] !== undefined) {
          chartBackground.push(chartValue.options['colorOptions'][i]);
        } else if (chartValue.options['colorOptions'][i] === undefined) {
          chartBackground.push({ backgroundColor: this.commonService.getRandomColor(), borderColor: 'grey' });
        }
      } else {
        chartBackground.push({ backgroundColor: this.commonService.getRandomColor(), borderColor: 'grey' });
      }      
    }
    if(chartValue.options.hasOwnProperty('colorMap')) {
      let colorDetails = chartValue.options.colorMap;
      for (let i = 0; i <  Object.keys(colorDetails).length; i++) {
        let labelKey = Object.keys(colorDetails)[i]
        let labelIndex = chartValue.labels.indexOf(labelKey)
        chartBackground[0]['backgroundColor'][labelIndex] = colorDetails[labelKey]
      }
    }
    this.bindDynamicCharts(layout.code, type, dataset, chartValue.labels, null, chartBackground, layout.widgetTypeId, 'Count', chartValue.options.options);
  }

  public bindDynamicCharts(code, chartType, dataValue, labels, label, color, widgetTypeId, yName, options) {
    this.dashboardDetails[code]['type'] = 'chart';
    this.dashboardDetails[code]['chartType'] = chartType;
    this.dashboardDetails[code]['labels'] = labels;
    this.dashboardDetails[code]['datasets'] = chartType !== 'pie' ? dataValue : [{ label: label, data: dataValue }];
    if (widgetTypeId === 'WT-CHART') {
      this.dashboardDetails[code]['datasets'] = dataValue;
      if(this.dashboardDetails[code]['datasets'].length && color.length) {
        for(let i in this.dashboardDetails[code]['datasets']) {
          if(color[i]) {
            this.dashboardDetails[code]['datasets'][i]['backgroundColor'] = color[i]['backgroundColor'];
          }
        }
      }
    }
    this.dashboardDetails[code]['colors'] = color;
        this.dashboardDetails[code]['options'] = options;
    if (!options['plugins']['datalabels'].hasOwnProperty('display')) {
      this.dashboardDetails[code]['options']['plugins']['datalabels']['display'] = function (context) { return context.dataset.data[context.dataIndex] !== 0; };
    }
    this.dashboardDetails[code]['show'] = true;
  }
  public gridsterConfiguration(lockOption) {
    if(this.gridsterConfig){
      this.options = this.gridsterConfig;
    }else{
      this.options = {
        minCols: this.pdfConfigSelected === "default"? 6 : 2,
        maxCols: this.pdfConfigSelected === "default"? 500 * 500 : 4,
        useTransformPositioning: true,
        disableAutoPositionOnConflict: this.pdfConfigSelected === "default"? false : true,
        minRows: 4,
        maxRows: 500 * 500,
        minItemRows: 1,
        maxItemRows: 500 * 500,
        minItemCols: 1,
        maxItemCols: 500 * 500,
        fixedRowHeight: 80,
        fixedColWidth: 80,
        margin: 5,
        mobileModeEnabled: true,
        gridType: 'scrollVertical',
        pushItems: !lockOption,
        pushDirections: { north: false, east: false, south: false, west: false },
        displayGrid: 'onDrag&Resize', // ‘always’, ‘onDrag&Resize’, ‘none’
        compactType: CompactType.None,
        swap: false,
        swapWhileDragging: false,
        compact: false
      };
    }
    this.options['draggable'] = {
      enabled: !lockOption,
      stop: function (item, gridsterItem, event) {
        const preLayout = JSON.stringify(this.layout);
        setTimeout(() => this.updateLayout(gridsterItem, preLayout, 'drag'), 800);
      }.bind(this)
    }
    this.options['resizable'] = {
      enabled: !lockOption,
      stop: function (item, gridsterItem, event) {
        const preLayout = JSON.stringify(this.layout);
        setTimeout(() => this.updateLayout(gridsterItem, preLayout, 'resize'), 800);
      }.bind(this),
      handles: { n: false, e: false, w: false, s: false, ne: true, se: true, sw: true, nw: true }
    }
  }

  getFloorId(data) {
    const e = eval;
    const floorDetail = e('[' + data + ']');
    this.floorId = floorDetail[0].id;
  }
  ngDoCheck() {
    if (this.resourceCode != this.preresourceCode) {
      this.checkMetaData()
    }
    else if (this.commonService.contextChanged === true && this.dashboardId != null) {
      this.screenLock = true;
      this.dashboardDetails['update'] = true;
      this.gridsterConfiguration(this.screenLock);
      this.commonService.contextChanged = false;
      this.checkUserPreference();
    }
  }
  ngOnDestroy() {
    clearInterval(this.autoInterval);
  }

  openSnackbar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 3000,
    });
  }

  downloadPDF() {
    let chartImage = [];
    for (let i = 0; i < this.tempChart.length; i++) {
      chartImage[i] = document.getElementById(this.tempChart[i]);
    }
    let headerName = this.resourceCode.split('_').join(' ');
    let pdfData = { "headerName": headerName, "fromDate": this.fromDate, "toDate": this.toDate, "pdfFileName": this.resourceCode, "chartImage": chartImage, "tableData": this.tempTable, "tableName": this.tableName };
    let gridsterDownload = true;
    if (!gridsterDownload) {
      this.layoutPdfService.pdfCreate(pdfData);
    }
    if (gridsterDownload) {
      let node = document.getElementById('dm-layout');
      let img;
      let filename = String(this.resourceCode) + '-' + this.today + ".pdf";
      let headerName = this.resourceCode.split('_').join(' ');
      let newImage;
      domtoimage.toPng(node, { width: node.scrollWidth, height: node.scrollHeight })
        .then(function (dataUrl) {
          img = new Image();
          img.src = dataUrl;
          newImage = img.src;
          img.onload = function () {
            let pdfWidth = img.width;
            let pdfHeight = img.height;
            let doc;
            if (pdfWidth > pdfHeight) {
              doc = new jsPDF('l', 'px', 'a4');
            } else {
              doc = new jsPDF('p', 'px', 'a4');
            }
            let width = doc.internal.pageSize.getWidth();
            let height = doc.internal.pageSize.getHeight();
            doc.text(headerName, 450, 35);//x,y
            doc.addImage(newImage, 'PNG', 0, 0, width, height);
            doc.save(filename);
          };
        })
        .catch(function (error) { });
    }
  }
  downloadExcel() {
    if (this.tempTable.length) {
      this.excelService.multiSheet(this.tempTable, this.tableName, this.dashboardName, this.fromDate);
    }
    // let excelData : any = {};
    // excelData['tableData'] = this.tempTable;
    // excelData['sheetName'] = this.tableName;
    // excelData['excelFileName'] = this.resourceCode;
    // excelData['fromDate'] = this.fromDate;
    // excelData['toDate'] = this.toDate;
    // this.layoutExcelService.exportAsExcelFile(excelData);
  }
  formTempValue(event) {
    this.formTemplateValue = event;
    if (this.formTemplateValue && this.formTemplateValue[2].hasOwnProperty('name') && this.formTemplateValue[2]['name'] == 'gis') {
      this.fromDate = event[0].controls['fdt'].value;
      this.toDate = event[0].controls['tdt'].value;
      this.resultJson = event[1];
      this.tempTable = [];
      this.tableName = [];
      this.tempChart = [];
      this.isLayoutChanged = false;
      this.refreshLayout();
    } else if (this.formTemplateValue[2] == 'pdf') {
      this.downloadPDF();
    } else if (this.formTemplateValue[2] == 'excel') {
      this.downloadExcel();
    }
  }
}
