
import {throwError as observableThrowError,  Observable, forkJoin } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()

export class ConfigurationService implements Resolve<Observable<any>> {
    constructor(private apiService: ApiService, public http: HttpClient) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return forkJoin([
            // this.getAllGateways(),
            this.getAllReaders().pipe(
                catchError(error => {
                    return observableThrowError(error);
                }))
        ]).pipe(map(result => {
            return {
                gateway: result[0],
                // reader: result[1]
            };
        }));
    }

    // Component: Alert Config, Rule
    getAllAlerts() {
        return this.apiService.get(environment.base_value.alert_config_api);
    }
    getAllAlertsById(id, facilityId) {
        return this.apiService.get(environment.base_value.alert_config_api_by_id + '?id=' + id + '&facilityIds=' + facilityId + '&isOnlyActive=false');
    }
    // used in both Alert Config and Rule
    getAllPfRules() {
        return this.apiService.get(environment.base_value.pf_rule_api);
    }

    getReaderId(type) {
        return this.apiService.get(environment.base_value.get_reader_id + '?readerType=' + type);
    }
    getRecipientName(text, type, roleId?: string, parentCode?: string, isAdmin?: boolean) {
        if (roleId != null) {
            return this.apiService.get(environment.base_value.search_name_by_recipient_type + '?stext=' + text +
            '&recipientTypeId=' + type + '&roleId=' + roleId);
        } else if (parentCode != null) {
            return this.apiService.get(environment.base_value.search_name_by_recipient_type + '?stext=' + text +
            '&recipientTypeId=' + type + '&appTermLinkParentCode=' + parentCode);
        } else if (isAdmin === false) {
            return this.apiService.get(environment.base_value.search_name_by_recipient_type + '?stext=' + text +
            '&recipientTypeId=' + type);
        } else {
            return this.apiService.get(environment.base_value.search_name_by_recipient_type + '?stext=' + text +
            '&recipientTypeId=' + type + '&includeSuperAdmin=true');
        }
    }
    getTicketUser(text,type) {
        return this.apiService.get(environment.base_value.search_name_by_recipient_type + '?stext=' + text + '&recipientTypeId=' + type);
    }
    getRoutineRecipientName(text, type, isTagAssociated?: boolean) {
        return this.apiService.get(environment.base_value.search_name_by_recipient_type + '?stext=' + text +'&recipientTypeId=' + type + '&isTagAssociated=' + isTagAssociated);
    }
    getAlertHealthPackage(type){
        return this.apiService.get(environment.base_value.get_alert_health_package + '?planType=' + type);
    }
    getAlertHealthTests(option, id?: number){
        if(id !== null) {
            return this.apiService.get(environment.base_value.get_alert_health_tests + '?healthPlanId=' + id + '&testOption=' + option);
        } else {
            return this.apiService.get(environment.base_value.get_alert_health_tests + '?testOption=' + option);
        }
    }
    getAlertRoutine(type){
        return this.apiService.get(environment.base_value.get_alert_routine + '?routineTypeId=' + type);
    }
    getAlertRoutineActivity(type, id?: number){
        if(id !== null) {
            return this.apiService.get(environment.base_value.get_alert_routine_activity + '?routineId=' + id + '&activityType=' + type);
        } else {
            return this.apiService.get(environment.base_value.get_alert_routine_activity + '?activityType=' + type);
        }
    }
    saveAlert(post_data) {
        return this.apiService.post(environment.base_value.alert_config_api, post_data);
      }
    // saveAlert(post_data) {
    //     return this.apiService.post(environment.base_value.alert_config_api, post_data);
    // }
    updateAlert(id, post_data) {
        return this.apiService.put(environment.base_value.alert_config_api_update + '/' + id, post_data);
    }

    // Component: Asset, Health Check, Reader
    // used in both Asset and Reader
 
    /*
    Note : Server side pagination functionality removed approved by tilak jan 28, 2021
    getAllAssets(pageStart, pageSize) {
        return this.apiService.get(environment.base_value.get_all_assets + '?pageStart=' + pageStart + '&pageSize=' + pageSize);
    }
    */
    getAssetsById(id) {
        return this.apiService.get(environment.base_value.get_asset_by_id + '/'+id);
    }
    getStaffLocations(fdt, tdt, uid) {
        // 2023-06-01 14:59:50
        const url = 'api/python-wrapper/reports/staff-site-nav/0/0/' + localStorage.getItem(btoa('facilityId')) + '/fdt=' + fdt + '&tdt=' + tdt + '&uid='+ uid + '&lay=h';
        return this.apiService.get(url);
     }
    getAllMovementHistory(fromDate, toDate, id, type) {
        return this.apiService.get(environment.base_value.get_all_movement_history + '?fromDate=' + fromDate + '&toDate=' + toDate + '&associationId=' + id + '&tagAssociationTypeId=' + type);
    }
    // used in both Asset and Health Check
    getLocationData(key: string, isAvailabilityCheck?: boolean) {
        
        if(isAvailabilityCheck != null){
            return this.apiService.get(environment.base_value.get_to_location_search + '/' + key + '?isAvailabilityCheck=' + isAvailabilityCheck);
        } else {
            return this.apiService.get(environment.base_value.get_to_location_search + '/' + key);
        }
    }
    getApiSearchData(url: string,key: string){
        return this.apiService.get(url + '/' + key);
    }

    // Component: Daily Management, Health Check

    // Component: Gateway
 

    // old gateway id generate api

  
    // gateway V3

    // Component: Health Test
    getAllHeathTest() {
        return this.apiService.get(environment.base_value.get_all_health_test);
    }
    // Component: Health Check, Location Mapping
    // used in both Health check and Location Mapping


    // Component: Package

    // Component: Reader
    getAllReaders() { // Need to Discuss
        return this.apiService.get(environment.base_value.get_all_reader);
    }
    getHardwareVersions() {
        return this.apiService.get(environment.base_value.get_hardware_versions);
    }


    // SOFTWARE VERSION IN READER

    UpdateSoftwareVersion(data) {
        return this.apiService.put(environment.base_value.save_software_version + '?readerIds=' + data.id + '&swVersion=' + data.version, null);
    }
  

    // Component: Resource
    getLoginUsers() {
        return this.apiService.get(environment.base_value.get_login_user);
    }
    getAllResource() {
        return this.apiService.get(environment.base_value.get_all_resource);
    }
    getAllPermission() {
        return this.apiService.get(environment.base_value.get_all_permission);
    }
    resourceRoleMap(data) {
        return this.apiService.post(environment.base_value.save_resource_role, data);
    }
    saveResource(data) {
        return this.apiService.post(environment.base_value.save_resource, data);
    }
    updateResource(id, data) {
        return this.apiService.put(environment.base_value.update_resource + '/' + id, data);
        // return this.apiService.put(environment.base_value.update_resource, data);
    }

    // Component: Social Distance Config

    //component:Config Management
   
    getConfig(isCurrentUser){
        return this.apiService.get(environment.base_value.config_file + '?isCurrentUser=' + isCurrentUser );
    }
    saveConfig(data){
        return this.apiService.post(environment.base_value.config_file,data);
    } 
    updateConfig(id,data){
        return this.apiService.put(environment.base_value.config_file + '/' + id,data);
    }

    // Component: Tag

    disassociateTag(data) {
        return this.apiService.post(environment.base_value.disassociate_tag, data);
    }
    //Component: Activities
    getTaskActivities(routineType, activityCategory) {
        return this.apiService.get(environment.base_value.get_all_activities + '?routineType=' + routineType + '&activityCategoryIds=' + activityCategory);
    }
    getAllActivities(){
        return this.apiService.get(environment.base_value.get_all_activities);
    }

    // Component: Routine Management
    getActivity(){
        return this.apiService.get(environment.base_value.get_activity);
    }
    searchActivities(text, type, routineType ?: string, activityCategoryId?:string, activitySubTypeId?:string) {
        let param = '?searchText=' + text + '&activityType=' + type;
        if (routineType !== null) {
            param = param + '&routineType=' + routineType;
        }
        if (activityCategoryId != undefined && activityCategoryId !== null) {
            param = param + '&activityCategoryIds=' + activityCategoryId;
        }
        if (activitySubTypeId != undefined && activitySubTypeId !== null) {
            param = param + '&activitySubTypeId=' + activitySubTypeId;
        }
        return this.apiService.get(environment.base_value.get_all_activities + param);
    }
    getActivitiesById(id){
        return this.apiService.get(environment.base_value.get_all_activities + '?id=' + id);
    }
    getAllRoutine(){
        return this.apiService.get(environment.base_value.get_all_routine);
    }
    getRoutineActivities(id){
        return this.apiService.get(environment.base_value.get_routine_activities + '/' + id);
    }
    getRoutineName(text, type){
        return this.apiService.get(environment.base_value.get_routine_name + '?stext=' + text + '&routineTypeId=' + type);
    }
    getActivitiesList(type){
        return this.apiService.get(environment.base_value.get_routine_list + '?routineTypeId=' + type);
    }
    addRoutine(data){
        return this.apiService.post(environment.base_value.add_routine, data);
    }
    UpdateEntityRoutine(id, data){
        return this.apiService.put(environment.base_value.add_routine + '/' + id, data);
    }
    activateEntityRoutine(data){
        return this.apiService.put(environment.base_value.activate_routine + '/' + data.entityRoutineEventId, data);
    }
    getRoutine(routineId){
        return this.apiService.get(environment.base_value.get_role_routine + '?entityRoutineId=' + routineId);
    }
    getMultipleRoutine(entityType, entityId, patientVisitId) {
        if (entityType === 'Patient') {
            return this.apiService.get(environment.base_value.get_multiple_routine + '?entityId=' + entityId + '&patientVisitId=' + patientVisitId + '&entityType=' + entityType);
        } else {
            return this.apiService.get(environment.base_value.get_multiple_routine + '?entityId=' + entityId + '&entityType=' + entityType);
        }
    }
    saveRoleRoutineActivities(data){
        return this.apiService.post(environment.base_value.get_role_routine, data);
    }
    updateRoleRoutineActivities(data){
        return this.apiService.put(environment.base_value.get_role_routine + '/' + data.entityRoutineId, data);
    }
    deleteRoleRoutine(id){
        return this.apiService.put(environment.base_value.end_routine + '/' + id, id);
    }
    cancelRoleRoutine(id){
        return this.apiService.put(environment.base_value.cancel_routine + '/' + id, id);
    }
    deleteRoleRoutineActivities(routineActivityId){
        return this.apiService.delete(environment.base_value.get_role_routine + '/' + routineActivityId);
    }
    saveRoutine(data){
        return this.apiService.post(environment.base_value.get_all_routine, data);
    }
    updateRoutine(id, data){
        return this.apiService.put(environment.base_value.get_all_routine + '/' + id, data);
    }
    saveRoutineActivities(data){
        return this.apiService.post(environment.base_value.get_routine_activities, data);
    }
    updateRoutineActivities(data){
        return this.apiService.put(environment.base_value.get_routine_activities + '/' + data.routineId, data);
    }
    deleteRoutineActivities(routineActivityId){
        return this.apiService.delete(environment.base_value.get_routine_activities + '/' + routineActivityId);
    }
    getFormTemplates(formTemplateId?){
        if(formTemplateId){
            return this.apiService.get(environment.base_value.get_form_templates+ '?id='+formTemplateId);
        } else{
            return this.apiService.get(environment.base_value.get_form_templates);
        }
    }
    getFormTemplatesV2(){
        return this.apiService.get(environment.base_value.get_form_templates_v2);
    }
    getEntityForm(entityFormId?){
        return this.apiService.get(environment.base_value.entity_form +'/'+ entityFormId);
    }
    saveEntityForm(data){
        return this.apiService.post(environment.base_value.entity_form, data);
    }
    updateEntityForm(data){
        return this.apiService.put(environment.base_value.entity_form + '/' + data.id, data);
    }
    savePfFormTemplate(data){
        return this.apiService.post(environment.base_value.save_form_template, data);
    }
    getDataitems(){
        return this.apiService.get(environment.base_value.get_dataitem);
    }
    saveDataitems(data){
        return this.apiService.post(environment.base_value.get_dataitem, data);
    }
    // Not in Use
    // getTag() {
    //     return this.apiService.get(environment.base_value.get_all_tag + '/CUST002');
    // }
    // getAllDetailAssets() {
    //     return this.apiService.get(environment.base_value.get_all_assets);
    // }
}
