import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, ConfigurationService } from '../../../services';
import { CreateTicketRequest, EditTicketRequest } from './entity-ticket.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-entity-ticket',
  templateUrl: './entity-ticket.component.html',
  styleUrls: ['./entity-ticket.component.scss']
})
export class EntityTicketComponent implements OnInit {
  public ticketRequestForm: any = UntypedFormGroup;
  ticketUploadDataSource = new MatTableDataSource<any>();
  isPreviousExpanded = false;
  isuploadExpanded = true;
  ticketUploadColumns: string[] = ['type', 'name', 'preview','delete'];
  public requestStatus: any[] = [];
  public assignedTypeList: any[] = [];
  public imageData: any[] = [];
  public userList: any;
  public exisUserList: any;
  public assetList: any;
  public userEnabled = false;
  public ticketRequestStatus: any;
  public assignedStatusType: any;
  public isFileSelected: boolean = false;
  public statusDesc: any[] = []
  public base64Data_global: string;
  public barLineData: any;
  attachmentData: any[] = [];
  ticketUser: any;
  UserNameList: any;
  userDetails: any[] = [];
  performerInfo: any[] = [];
  nonPerformerInfo: any[] = [];
  isAssignedTo: any;
  fileType: string;
  fileInfo: string;
  attachFiles: any[] = [];
  attachInfo: any[] = []
  image: any;
  attachDetails: {};
  isSaved: boolean;
  atchData = null;
  modify = null;
  assetDetails: any[] = [];
  existingUserName: any[] = [];
  tktHistoryData = { key: [], data: {}, show: false };
  userHit = false;
  isUploadTable: boolean = false;
  isAddUpload: boolean = false;
  uploadName: any;

  constructor(
    public fb: UntypedFormBuilder,
    protected sanitizer: DomSanitizer,
    public commonService: CommonService,
    private configurationServices: ConfigurationService,
    public thisDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public entityMetaData: any,
    public dialog: MatDialog,
    public toastr: ToastrService,
  ) {
    this.modify = this.entityMetaData['data'];
  }

  ngOnInit(): void {
    this.buildForm();
    this.commonService.getAppTerms('RequestStatus,RecipientType').subscribe((res) => {
      this.requestStatus = res.results.filter(resFilter => resFilter.groupName === 'RequestStatus');
      this.assignedTypeList = res.results.filter(resFilter => resFilter.groupName === 'RecipientType');
      this.assignedStatusType = this.assignedTypeList.filter(rs => rs.code === 'RT-US' || rs.code === 'RT-RO');
      this.ticketRequestStatus = this.requestStatus.filter(rs => rs.code === 'RQ-OP');
    });
    if(this.entityMetaData.hasOwnProperty('requestId') && this.entityMetaData.requestId) {
      this.getTicketRquestbyId(this.entityMetaData.requestId)
    }    
  }
  getTicketRquestbyId(reqId) {
    this.commonService.getTicketRequest(null, 'RQT-TKT', reqId).subscribe((res) => {
      if(res.statusCode == 1) {
        this.entityMetaData['data'] = res.results[0];
        this.modify = this.entityMetaData['data'];
        if (this.entityMetaData['data']['status'] === 'RQ-OP') {
          this.ticketRequestStatus = this.requestStatus.filter(rs => rs.code === 'RQ-IP' || rs.code === 'RQ-CL' || rs.code === this.entityMetaData.data.status);
        } else if (this.entityMetaData['data']['status'] === 'RQ-IP') {
          this.ticketRequestStatus = this.requestStatus.filter(rs => rs.code === 'RQ-CL' || rs.code === this.entityMetaData.data.status);
        } else if (this.entityMetaData['data']['status'] === 'RQ-CL') {
          this.ticketRequestStatus = this.requestStatus.filter(rs => rs.code === this.entityMetaData.data.status);
        } 
        let ticketRqtId = this.entityMetaData['data'].requestId
        this.getAllTktHist(ticketRqtId);
        this.getAllAttachments(ticketRqtId);
        this.assetDetails = this.entityMetaData['data']['nonPerformer'];
        this.existingUserName = this.entityMetaData['data']['performer'];
        this.buildForm();
      }
    });
  }
  public buildForm() {
    this.ticketRequestForm = this.fb.group({
      title: [this.entityMetaData['data'] ? this.entityMetaData['data']['title'] : null, [Validators.required]],
      remarks: [this.entityMetaData['data'] ? this.entityMetaData['data']['remarks'] : null, [Validators.maxLength(50), Validators.pattern(/^[a-zA-Z0-9 _]*$/)]],
      statusId: [this.entityMetaData['data'] ? this.entityMetaData['data']['status'] : 'RQ-OP'],
      assignedType: [this.entityMetaData['data'] && this.entityMetaData['data']['performer'].length ? this.entityMetaData['data']['performer'][0].tagAssociationTypeId : null],
      isAssignedTo: [this.entityMetaData['data'] && this.entityMetaData['data']['performer'].length ? this.entityMetaData['data']['performer'][0].name : null, [Validators.required]],
      comments: [null],
      attachFiles: [this.entityMetaData['data'] ? this.entityMetaData['data']['attachFiles'] : null],
    });
  }

  safeUrl(value) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  getUserList(id) {
    if (id) {
      const username = this as any as { id: string, name: string }[]
      const userId = username.find(obj => obj.id === id).name;
      return userId;
    } else {
      return '';
    }
  }

  handleFileSelect(evt) {
    const files = evt.target.files;
    const allowed_types = ['image/png', 'image/jpeg', 'application/pdf', 'text/plain'];
    if (!allowed_types.includes(evt.target.files[0].type)) {
      this.toastr.warning('Warning', `Please choose only mentioned file formats!`);
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (files && file) {
        this.fileType = file.type;
        const reader = new FileReader();

        reader.onload = (function (f) {
          return function (readerEvt) {
            const binaryString = readerEvt.target.result

            this.base64Data_global = btoa(binaryString);

            this.isFileSelected = true;

            if (f.type.includes('image')) {
              const url = this.safeUrl('data:image/png;base64,' + this.base64Data_global);
              this.image = {
                file: url,
                mimeType: f.type
              }
            }

            if (f.type === 'application/pdf') {
              const url = this.safeUrl('data:application/pdf;base64,' + this.base64Data_global);
              this.image = {
                file: url,
                mimeType: f.type
              }
            }

            if (f.type === 'application/msword') {
              const url = this.safeUrl('data:application/msword;base64,' + this.base64Data_global);
              this.image = {
                file: url,
                mimeType: f.type
              }
            }

            if (f.type === 'text/plain') {
              const url = this.safeUrl('data:text/plain;base64,' + this.base64Data_global);
              // this.imageData.push({
              //   file: url,
              //   mimeType: f.type
              // });
              this.image = {
                file: reader.result,
                mimeType: f.type
              }
            }
            if(file.size < 2 * 1000000){
              this.atchData = this.image
              this.addUploadRow();
            }else {
              this.atchData = null;
              this.isFileSelected = false;
              this.toastr.warningToastr('Warning', `Upload image file should be less that 2MB`, { closeButton: true });
            }
            this.isAddUpload = true;
          };
        })(file).bind(this);
        reader.readAsBinaryString(file); 
      }
    }
  }

  onFileSelect(input: HTMLInputElement): void {
    function formatBytes(bytes: number): string {
      const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const factor = 1024;
      let index = 0;
      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }
      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }
    const file = input.files[0];
    this.fileInfo = `${file.name} (${formatBytes(file.size)})`;
  }

  addUploadRow() {
    if (!this.entityMetaData.data) {
      if (this.ticketRequestForm.controls['assignedType'].value) {
        this.uploadName = this.userDetails[0].name;
      } else {
        this.uploadName = null;
      }
    } else {
      this.uploadName = this.entityMetaData.data.userName;
    }
    this.attachFiles.push({
      fileType: this.atchData.mimeType,
      base64Data: this.base64Data_global,
      attachmentId: null,
      image: this.atchData.file,
      imageSize: this.fileInfo,
      userName: this.uploadName
    });
    this.ticketUploadDataSource = new MatTableDataSource(this.attachFiles);
    this.isUploadTable = true;
    this.fileInfo = null;
    this.isAddUpload = false;
    this.isFileSelected = false;
  }

  delete(imgData) {
    
    this.ticketUploadDataSource.data = this.ticketUploadDataSource.data.filter(i => i !== imgData)
      .map((i, idx) => (i.position = (idx + 1), i));

    this.attachFiles.splice(imgData,1);

    if(this.attachFiles.length === 0 ){
      this.isUploadTable = false;
    }
  }

  getAssignedType(type) {
    this.ticketUser = null;
    this.ticketRequestForm.controls['isAssignedTo'].setValue(null);
    if (type === 'RT-RO') {
      this.configurationServices.getTicketUser(null, type).subscribe(res => {
        this.ticketUser = res.results;
        this.UserNameList = this.ticketUser
        this.userEnabled = true;
      });
    } else {
      this.UserNameList = [];
      this.userEnabled = false;
    }
  }

  getSearchUser(event) {
    let type = this.ticketRequestForm.controls['assignedType'].value;
    if (event.text.length >= 2 && type === 'RT-US') {
      if (event.toHit == true && type === 'RT-US') {
        this.configurationServices.getTicketUser(event.text, type).subscribe(res => {
          this.ticketUser = res.results;
          this.UserNameList = this.ticketUser
          this.userEnabled = true;
        });
      } else {
        this.UserNameList = this.ticketUser
        this.userEnabled = true;
      }
    } else {
      if (type === 'RT-RO') {
        this.configurationServices.getTicketUser(null, type).subscribe(res => {
          this.ticketUser = res.results;
          this.UserNameList = this.ticketUser
          this.userEnabled = true;
        });
        // this.UserNameList = [];
        // this.userEnabled = false;
      }
    }
  }

  getuserDetails(data, event) {
    this.userHit = true
    this.userDetails = this.UserNameList.filter(x => x.id === event.value);
    this.userDetails.push({
      'id': data.id,
      'name': data.name,
      'tagTypeId': this.ticketRequestForm.controls['assignedType'].value,
    });
  }

  public SaveRequest() {
    const createTicketRequest = new CreateTicketRequest(null, null, null, null, null, null, null, null, null);
    createTicketRequest.type = "RQT-TKT";
    createTicketRequest.title = this.ticketRequestForm.controls["title"].value;
    createTicketRequest.remarks = this.ticketRequestForm.controls["remarks"].value;
    createTicketRequest.isAssignedTo = this.ticketRequestForm.controls["isAssignedTo"].value;
    createTicketRequest.status = this.ticketRequestForm.controls["statusId"].value;
    createTicketRequest.comments = this.ticketRequestForm.controls["comments"].value;
    if (this.userDetails) {
      this.userList = {
        id: this.userDetails[0].id,
        type: this.userDetails[0].tagTypeId,
      }
      this.performerInfo.push(this.userList);
    }
    createTicketRequest.performer = this.performerInfo;

    for (let i = 0; i < this.attachFiles.length; i++) {
      this.attachDetails = {
        fileType: this.attachFiles[i].fileType,
        base64Data: this.attachFiles[i].base64Data
      }
      this.attachInfo.push(this.attachDetails);
    }

    createTicketRequest.attachFiles = this.attachInfo;

    if (this.entityMetaData.entityId !== null) {
      this.assetList = {
        id: this.entityMetaData['entityId'],
        type: 'TAT-AS'
      }
      this.nonPerformerInfo.push(this.assetList);
    }
    createTicketRequest.nonPerformer = this.nonPerformerInfo;
    this.commonService.saveTicketRequest(createTicketRequest).subscribe(
      (res) => {
        if (res.statusCode === 1) {
          this.thisDialogRef.close(res.results);
          this.ticketRequestForm.reset();
        }
        this.isSaved = false;
        this.toastr.success("Success", `${res.message}`);
      },
    );
  }

  UpdateRequest(id) {
    const editTicketRequest = new EditTicketRequest(null, null, null, null, null, null, null, null, null, null);
    editTicketRequest.type = "RQT-TKT";
    editTicketRequest.ticketId = id;
    if (this.ticketRequestForm.controls["remarks"].value !== null) {
      editTicketRequest.remarks = this.ticketRequestForm.controls["remarks"].value.trim();
    } else {
      editTicketRequest.remarks = this.ticketRequestForm.controls["remarks"].value;
    }
    editTicketRequest.title = this.ticketRequestForm.controls["title"].value;
    editTicketRequest.status = this.ticketRequestForm.controls["statusId"].value;
    editTicketRequest.comments = this.ticketRequestForm.controls["comments"].value;

    if (this.isFileSelected === true) {
      this.attachDetails = {
        fileType: this.image.mimeType,
        base64Data: this.base64Data_global,
      }
      this.attachFiles.push(this.attachDetails);
    }

    editTicketRequest.attachFiles = this.attachFiles;

    if (this.userHit === false) {
      for (let i = 0; i < this.existingUserName.length; i++) {
        this.exisUserList = {
          id: this.existingUserName[i].id,
          type: this.existingUserName[i].tagAssociationTypeId,
          requestDetailId: this.existingUserName[i].requestDetailId
        }
        this.performerInfo.push(this.exisUserList);
      }
    } else {
      this.userList = {
        id: this.userDetails[0].id,
        type: this.userDetails[0].tagTypeId,
      }
      this.performerInfo.push(this.userList);
    }
    editTicketRequest.performer = this.performerInfo;

    if (this.assetDetails.length) {
      this.assetList = {
        id: this.assetDetails[0].id,
        type: this.assetDetails[0].tagAssociationTypeId,
        requestDetailId: this.assetDetails[0].requestDetailId
      }
      this.nonPerformerInfo.push(this.assetList);
    }

    editTicketRequest.nonPerformer = this.nonPerformerInfo;

    this.commonService.updateTicketRequest(editTicketRequest).subscribe(
      (result) => {
        if (result.statusCode === 1) {
          this.thisDialogRef.close(result.results);
          this.ticketRequestForm.reset();
        }
        this.toastr.success("Success", `${result.message}`);
      },
    );
  }

  getAllTktHist(id) {
    this.commonService.getPorterHistory(id).subscribe((res) => {
      if (res.statusCode === 1) {
        let ticketEvents = res.results;
        ticketEvents.forEach(item => {
          if (this.tktHistoryData['data'].hasOwnProperty(item.statusId)) {
            this.tktHistoryData['data'][item.statusId].push(item);
          } else {
            this.tktHistoryData['data'][item.statusId] = [item];
            this.tktHistoryData['key'].push(item.statusId);
          }
          this.tktHistoryData['show'] = true;
          // this.barLineData = this.tktHistoryData['key'][this.tktHistoryData['key'].length-1];
        });
        // const attach = ticketEvents.map(el => ({
        //   ...el,
        //   ...this.attachmentData.find(arr1El => arr1El.entityId === el.id),
        // }));
      }
    });
  }

  getAllAttachments(id) {
    this.commonService.getAttachment(id, 'Request').subscribe((res) => {
      this.attachmentData = res.results
    });
  }
}
