import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { HospitalService } from '../../../services/hospital.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-common-multiselect',
  templateUrl: './common-multiselect.component.html',
  styleUrls: ['./common-multiselect.component.scss']
})
export class CommonMultiselectComponent implements OnInit {
  @Output() selectedOptionsChange = new EventEmitter<string[]>();
  @Output() selectionChanged = new EventEmitter<string[]>();
  TypeList: any = [];
  selectedOptions: any[] = [];
  public applyFilterValue: any;
  checked: boolean = false;
  existSpeciality = [];
  showCheckbox = false;


  constructor(private hospitalServices: HospitalService, public dialogRef: MatDialogRef<CommonMultiselectComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}


  ngOnInit(): void {
    this.hospitalServices.getAllentitydata(name, this.data.type).subscribe(res => {
      this.showCheckbox = false;
      this.TypeList = res.results;
      this.existSpeciality = this.data.slectedData ? this.data.slectedData.map(val => val[this.data.key]) : [];
      this.TypeList.forEach(item => {
        item['isSelected'] = this.existSpeciality.includes(item.id);
        item['uid'] = this.data.slectedData && this.data.slectedData.filter(val => val[this.data.key] == item.id).length ? this.data.slectedData.filter(val => val[this.data.key] == item.id)[0]['id'] : null;
        if (item['isSelected']) {

          this.selectedOptions.push(item, this.data.type)
        }
      });
      this.showCheckbox = true;

    });
  }


  onCheckboxChange(data, event) {

    if (this.existSpeciality.includes(data.id)) {
      let index = this.selectedOptions.findIndex(val => val.id == data.id)
      this.selectedOptions[index]['isSelected'] = event.checked;
      this.selectedOptions[index]['isActive'] = event.checked;
    } else {
      let index = this.selectedOptions.findIndex(val => val.id == data.id)
      if (index == -1) {
        data['isSelected'] = event.checked;
        data['isActive'] = event.checked;
        this.selectedOptions.push(data, this.data.type);
      } else {
        this.selectedOptions[index]['isSelected'] = event.checked;
        this.selectedOptions[index]['isActive'] = event.checked;
      }
    }

  }
  saveMultiSpec() {
    this.selectedOptions = this.selectedOptions.filter(val => !(val.uid == null && !val.isSelected))
    this.dialogRef.close(this.selectedOptions);
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.applyFilterValue = filterValue;
    if (this.applyFilterValue.length > 2) {
      this.hospitalServices.getAllentitydata(this.applyFilterValue, this.data.type).subscribe(value => {
        this.TypeList = value.results
      })
    } else if (this.applyFilterValue.length == 0 || this.applyFilterValue === '') {
      this.hospitalServices.getAllentitydata(this.applyFilterValue, this.data.type).subscribe(value => {
        this.TypeList = value.results
      })
    }
  }

}
