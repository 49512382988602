/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Component, OnInit, ViewChild, Input, Inject, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormGroupDirective, NgForm, UntypedFormArray, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CreateRegisterPatient, CreateEnrollPatient, CreateCoaster, CreateInfant, CreateEnrollEmployee, EditEnrollEmployee, CreateEnrollVisitor, EditEnrollVisitor, CreateEnrollTempId, EditEnrollTempId, UpdateEnrollPatient, EditInfant, CreateMedicalRecord } from './enroll-patient.model';
import { connect } from 'mqtt';
import { ConfigurationService, CommonService } from '../../../services';
import { DatePipe } from '@angular/common';
import { from, Observable } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { V } from '@angular/cdk/keycodes';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ConfirmationDialog } from '../../entry-component/confirmation-dialog/confirmation-dialog.component';
import * as moment_ from 'moment';
import { startWith, map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ErrorStateMatcherService } from '../../../services/error-state-matcher.service';
import { ToastrService } from 'ngx-toastr';

const moment = moment_;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-enroll-patient',
  templateUrl: './enroll-patient.component.html',
  styleUrls: ['./enroll-patient.component.scss'],
  providers: [DatePipe,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],

})

export class EnrollPatientComponent implements OnInit {

  public registerPatientForm: UntypedFormGroup;
  public createRegisterPatient: CreateRegisterPatient;
  public isDisabled = false;
  dataSource = new MatTableDataSource();
  public genderList: any[] = null;
  public currentDate: any = new Date();
  public checkUHID = true;
  public chkEnrolment = false;
  public checkedRowData: any;
  public matcher = new ErrorStateMatcherService();
  public isEnabledTable = false;
  public isDataNotFound = false;
  public searchCosterlist: any;
  public isEnableCosterDropdown = true;
  public disableCoster = false;

  checked = true;
  public vipList: Array<any> = [];
  public languageList: Array<any> = [];

  displayedColumns = ['select', 'name', 'uhid', 'gender', 'age', 'DOB', 'mobile', 'packageName', 'date', 'edit', 'status'];
  selection = new SelectionModel<Element>(true, []);
  public isEditDate = false;
  public isSelectedEditId = 0;
  public isDiabetic = false;
  public isFasting = false;
  public isVulnerable = false;
  public isPregnant = false;
  public vipTypeId: any;
  public preferredLanguage: any;
  public checkPkgDate: any;
  public selectedDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  public allowEditPkgIcon = false;
  public isClinical = false;
  public isEnrollOption = false;
  public selectedEnrollId: any;

  public isEditIconEnable = false;
  public selectedRowIndex: any;
  public defaultPkgDateShow = true;
  public loading = false;
  public enrollDetails: any;
  public tokenEnrollFlow: any;
  public assignFloorDetails: Array<any> = [];
  public floorId: any;
  public enrollVisitType = null;
  public isExecuteEnroll = false;
  public todayDate = this.datepipe.transform(new Date(), 'dd/MM/yyyy');
  public enrollVisitOption = [{ name: 'New Visit', value: false }, { name: 'Follow-up', value: true }];
  public changeEnrollAction;
  public packageName: any;
  public isExisting;
  public followupAssignFloorDetails: Array<any> = [];
  public visitEvent = '';
  public listItems: any;
  public height: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public selectedRow = '';
  public tagTypeId = null;
  public workflowTypeId = null;
  constructor(
    public form: UntypedFormBuilder, public toastr: ToastrService, public dialog: MatDialog, private commonService: CommonService,
    public thisDialogRef: MatDialogRef<EnrollPatientComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private _dateFormat: DatePipe, private dateAdapter: DateAdapter<Date>, public datepipe: DatePipe) {
    // dateAdapter.setLocale('en-in'); 
  }

  ngOnInit() {
    this.workflowTypeId = this.data.workflowTypeId;
    if (this.data.visitEvent === 'VE-OT') {
      this.visitEvent = this.data.visitEvent;
    }

    this.commonService.getConfigFile('assign-floor').subscribe(res => {
      if (res.results != null) {
        this.assignFloorDetails = res.results.contentObject.filter(filter => filter.isOnlyRegistration === 'false');
        this.followupAssignFloorDetails = res.results.contentObject.filter(filter => filter.isFollowup === 'true');
      }
    });

    this.buildForm();

    
    this.tokenEnrollFlow = JSON.parse(localStorage.getItem(btoa('tokenEnrollFlow')));

    if (this.tokenEnrollFlow === false) {
      this.registerPatientForm.get('coster').valueChanges
        .subscribe(value => {
          if (value === 'mobile') {
            this.registerPatientForm.get('tagSerialNumber').setValidators(null);
            this.registerPatientForm.controls['tagSerialNumber'].setValue(null);
          }
          if (value === 'coaster') {
            this.registerPatientForm.get('tagSerialNumber').setValidators(Validators.required);
          }
          this.registerPatientForm.get('tagSerialNumber').updateValueAndValidity();
        });

      this.registerPatientForm.get('tagSerialNumber').valueChanges
        .subscribe(value => {
          if (value != null && value.lastIndexOf('~') > -1) {
            const splitValue = value.split('=');
            const costerId = splitValue[1].substring(0, 9);
            if (costerId.length <= 9 && costerId.toString().match(/^[0-9]*$/)) {
              this.registerPatientForm.get('tagSerialNumber').setValue(costerId);
              this.registerPatientForm.get('tagSerialNumber').updateValueAndValidity();
            }
          }
        });
      if (this.registerPatientForm.get('coster').value === 'coaster') {
        this.registerPatientForm.get('tagSerialNumber').setValidators(Validators.required);
        this.registerPatientForm.get('tagSerialNumber').updateValueAndValidity();
      }
    }

    if (this.tokenEnrollFlow === true && this.assignFloorDetails.length > 0) {
      this.registerPatientForm.get('assignFloorId').setValidators(Validators.required);
      this.registerPatientForm.get('assignFloorId').updateValueAndValidity();
    }

    
    this.commonService.getAppTermsVerion2('Gender').subscribe(res => {
      this.genderList = res.results;
    });

    this.commonService.getAppTermsVerion2('Language').subscribe(res => {
      this.languageList = res.results;
    });

    this.commonService.getAppTermsVerion2('VipType').subscribe(res => {
      this.vipList = res.results;
    });

    this.customValidator();
  }
  onWindowResized(size) {
    this.height = size;
  }
  onUHID(UHID) {
    this.isClinical = false;
    this.isEnrollOption = false;
    this.loading = true;
    this.defaultPkgDateShow = false;
    this.registerPatientForm.get('assignFloorId').setValue(null);
    this.registerPatientForm.get('assignFloorId').updateValueAndValidity();
    this.registerPatientForm.get('enrollVisit').setValue(null);
    this.registerPatientForm.get('enrollVisit').updateValueAndValidity();
    if (this.enrollDetails) {
      this.enrollDetails = [];
    }
    this.getRegisteredPatients(UHID);
  }

  checkCurrentDate(event) {
    this.checkPkgDate = this.datepipe.transform(event, 'yyyy-MM-dd');
  }

  getTagTypeId(tagTypeId) {
    this.tagTypeId = tagTypeId;
  }

  onChange(event, item) {
    
    if (item.packageDate != null) {
      this.checkPkgDate = this.datepipe.transform(item.packageDate, 'yyyy-MM-dd');
    } else {
      this.checkPkgDate = null;
    }

    if (event.checked === true && item.id && item.patientVisitId) {
      this.commonService.getEnrollVisitOption(item.id, item.patientVisitId).subscribe(res => {
        console.log(res.results);
        this.isExisting = res.results.isExisting;

        if (item.healthPlanId != null) {
          if (!res.results.isFollowUp) {
            if (this.checkPkgDate === this.selectedDate) {
              this.changeEnrollAction = true;
            }
            if (res.results.isExisting) {
              this.enrollVisitType = 'Follow up';
            } else {
              this.enrollVisitType = 'New visit';
            }
            this.registerPatientForm.get('assignFloorId').setValue(null);
          } else {
            this.enrollVisitType = 'Follow up';
            if (this.followupAssignFloorDetails.length > 0) {
              this.floorId = this.followupAssignFloorDetails[0].floorId;
              this.registerPatientForm.get('assignFloorId').setValue(this.followupAssignFloorDetails[0].floorId);
            }
          }
          this.registerPatientForm.get('assignFloorId').updateValueAndValidity();
          this.registerPatientForm.get('enrollVisit').setValue(res.results.isFollowUp);
        } else {
          this.changeEnrollAction = true;
          this.registerPatientForm.get('enrollVisit').setValue(false);
        }
        this.registerPatientForm.get('enrollVisit').updateValueAndValidity();
      });
    } else {
      this.changeEnrollAction = true;
      this.registerPatientForm.get('enrollVisit').setValue(false);
      this.registerPatientForm.get('enrollVisit').updateValueAndValidity();
    }
    this.isDiabetic = item.isDiabetic;
    this.isFasting = item.isFasting;
    this.isVulnerable = item.isVulnerable;
    this.isPregnant = item.isPregnant;
    this.data.vipTypeId = item.vipTypeId;
    this.data.preferedLanguage = item.preferedLanguage;
    this.selectedEnrollId = item.id;
    this.packageName = item.packageName;
   
    this.registerPatientForm.get('date').setValue(this.currentDate);
    this.registerPatientForm.get('date').updateValueAndValidity();
    this.registerPatientForm.get('assignFloorId').setValue(null);
    this.registerPatientForm.get('assignFloorId').updateValueAndValidity();
    if (event.checked === true) {
      this.allowEditPkgIcon = true;
      this.isEditDate = false;
      this.isClinical = true;
      this.isEnrollOption = true;
      this.isEditIconEnable = true;
      this.defaultPkgDateShow = true;
    } else {
      this.allowEditPkgIcon = false;
      this.isEditDate = true;
      this.isClinical = false;
      this.isEnrollOption = false;
      this.isEditIconEnable = false;
      this.defaultPkgDateShow = false;
    }


    this.registerPatientForm.controls['preferedLanguage'].setValue(item.preferedLanguage);
    this.registerPatientForm.controls['vipTypeId'].setValue(item.vipTypeId);
    this.registerPatientForm.get('preferedLanguage').updateValueAndValidity();
    this.registerPatientForm.get('vipTypeId').updateValueAndValidity();

    let selected_condition_check_value: any;
    let selected_condition_check_key: any;
    let collection_check_value: any;

    if (item.isExistinPatient === true) { 
      selected_condition_check_value = item.id;
      selected_condition_check_key = 'id';
      this.selectedRowIndex = item.id;
    } else {
      selected_condition_check_value = item.mainidentifier;
      selected_condition_check_key = 'mainidentifier';
      this.selectedRowIndex = item.mainidentifier;
    }

    if (item.tagSerialNumber != null) {
      
      this.disableCoster = true;

      if (item.tagTypeId === 'TT-MB') {
        this.registerPatientForm.controls['coster'].setValue('mobile');
        this.isEnableCosterDropdown = false;
      } else {
        this.registerPatientForm.controls['coster'].setValue('coaster');
        this.isEnableCosterDropdown = true;
      }
      
      this.registerPatientForm.controls['tagSerialNumber'].setValue(item.tagSerialNumber);
      this.registerPatientForm.get('tagSerialNumber').disable();
      this.registerPatientForm.get('coster').updateValueAndValidity();
      this.registerPatientForm.get('tagSerialNumber').updateValueAndValidity();
    } else {
      
      this.disableCoster = false;

      if (item.tagTypeId === 'TT-MB') {
        this.registerPatientForm.controls['coster'].setValue('mobile');
        this.isEnableCosterDropdown = false;
      } else {
        this.registerPatientForm.controls['coster'].setValue('coaster');
        this.isEnableCosterDropdown = true;
      }
      this.registerPatientForm.get('tagSerialNumber').enable();
      this.registerPatientForm.get('coster'
      ).updateValueAndValidity();
      this.registerPatientForm.get('tagSerialNumber').updateValueAndValidity();
    }

    for (let j = 0; j < this.data.length; j++) {
      if (selected_condition_check_key === 'mainidentifier') {
        collection_check_value = this.data[j].mainidentifier;
        this.data[j].selectedRowIndex = this.data[j].mainidentifier;
      } else {
        collection_check_value = this.data[j].id;
        this.data[j].selectedRowIndex = this.data[j].id;
      }
      
      if (collection_check_value === selected_condition_check_value) {
        this.data[j].checked = !this.data[j].checked;
        if (this.data[j].checked === true) {
          this.checkedRowData = this.data[j];
          this.selectedRow = this.data[j].status;
        } else {
          this.selectedRow = '';
        }
      } else {
        this.data[j].checked = false;
      }
      
    }
  }

  customValidator() {
    this.registerPatientForm.get('mainidentifier').valueChanges
      .subscribe(value => {
        if (value != null) {
          
          if (value.toString().match(/^[0-9-+()]*$/)) {
            
            if (value.length >= 9) {
              this.registerPatientForm.get('mainidentifier').setValidators([Validators.required, Validators.pattern('^[0-9-+()]*$')]);
              
            }
          } else {
            this.registerPatientForm.get('mainidentifier').setValidators([Validators.required]);
          }
        }
      });
  }

  public buildForm() {

    this.registerPatientForm = this.form.group({
      mainidentifier: [this.data.mainidentifier ? this.data.mainidentifier : null, [Validators.required]],
      firstName: [this.data.firstName ? this.data.firstName : null],
      lastName: [this.data.lastName ? this.data.lastName : null],
      age: [this.data.age ? this.data.age : ''],
      birthDate: [this.data.birthDate ? this.data.birthDate : null],
      gender: [{ value: this.data.gender ? this.data.gender : null, disabled: true }],
      mobileNo: [this.data.mobileNo ? this.data.mobileNo : null],
      date: new UntypedFormControl(moment()),
      isPkgDateEdited: [null],
      tagSerialNumber: [null],
      coster: ['coaster'],
      isDiabetic: [null],
      isFasting: [null],
      isVulnerable: [null],
      isPregnant: [null],
      vipTypeId: [this.vipTypeId ? this.vipTypeId : null],
      preferedLanguage: [this.data.preferedLanguage ? this.data.preferedLanguage : null],
      assignFloorId: [null],
      enrollVisit: [null],
      
    });
  }

  enableEdit(item) {
    const filterVal = this.data.filter(filter => filter.id === item.id);
    this.isSelectedEditId = filterVal[0].id;
    this.isEditDate = true;
    
    this.checkPkgDate = this.datepipe.transform(this.registerPatientForm.controls['date'].value, 'yyyy-MM-dd');

    this.allowEditPkgIcon = true;
    this.selectedEnrollId = item.id;
    this.defaultPkgDateShow = false;
    this.isEditIconEnable = true;
    

    if (item.isExistinPatient === true) { 
      this.selectedRowIndex = item.id;
    } else {
      this.selectedRowIndex = item.mainidentifier;
    }
  }

  isEnableCoster(event) {
    if (event === 'coaster') {
      this.isEnableCosterDropdown = true;
    } else {
      this.isEnableCosterDropdown = false;
    }
  }

  searchToCoster(event) {
    if(event.type === 'tagIdSearch' && event.text.length >= 2) {
      if (event.toHit == true) {
        this.commonService.getAllTagByType(event.text, this.workflowTypeId, 'ST-AT').subscribe(res => {
          this.listItems = res.results;
          this.searchCosterlist = this.listItems;
        });
      } else {
        this.searchCosterlist = this.listItems;
      }
    } else {
      this.searchCosterlist = [];
    }
  }

  getRegisteredPatients(UHID) {

    if (UHID === null || UHID === '') {
      this.isEnabledTable = false;
      this.isDataNotFound = false;
      this.loading = false;
      this.data = [];
      this.dataSource = new MatTableDataSource<any[]>(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      return;
    }
    this.checkUHID = false;
    this.isEnabledTable = true;
    this.isDataNotFound = false;
    this.commonService.getRegisteredPatients(UHID).subscribe(res => {
      if (res.statusCode !== 1) {
        this.checkUHID = true;
        this.isDataNotFound = true;
        this.isEnabledTable = false;
      }
      this.data = res.results;

      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].isEnrolled === true) {
          this.data[i].status = 'Yes';
          this.data[i]['checked'] = false;
        } else {
          this.data[i].status = 'No';
          this.data[i]['checked'] = false;
        }
      }

      this.dataSource = new MatTableDataSource<any[]>(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    },
      error => {
        this.checkUHID = false;
        this.loading = false;
      });
  }

  clinicalDetails(event, type) {
    if (type === 'isDiabetic') {
      if (event.checked === true) {
        this.isDiabetic = true;
      } else {
        this.isDiabetic = false;
      }
    } else if (type === 'isFasting') {
      if (event.checked === true) {
        this.isFasting = true;
      } else {
        this.isFasting = false;
      }
    } else if (type === 'isVulnerable') {
      if (event.checked === true) {
        this.isVulnerable = true;
      } else {
        this.isVulnerable = false;
      }
    } else if (type === 'isPregnant') {
      if (event.checked === true) {
        this.isPregnant = true;
      } else {
        this.isPregnant = false;
      }
    }
  }

  getAssignFloor(data) {
    this.floorId = data;
  }

  getEnrollOption(data) {
   
    this.enrollVisitType = 'Follow up';
    if (data === false) {
      if (!this.isExisting) {
        this.enrollVisitType = 'New visit';
      }
      this.registerPatientForm.get('assignFloorId').setValue(null);
    } else {
      if (this.followupAssignFloorDetails.length > 0) {
        this.floorId = this.followupAssignFloorDetails[0].floorId;
        this.registerPatientForm.get('assignFloorId').setValue(this.followupAssignFloorDetails[0].floorId);
      }
    }
    this.registerPatientForm.get('assignFloorId').updateValueAndValidity();
  }
  enrollRegister() {
    this.buildForm();
    this.isEnabledTable = false;
    const id = '';
    
    const dialogRef = this.dialog.open(EnrollRegisterPatientComponent, {
      data: { 'id': id, 'workflowTypeId': 'WF-HC', 'visitType': 'VT-HC' }, 
      panelClass: ['small-popup'], disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      
      const e = eval;
      this.enrollDetails = e(result);
      
    });

  }

  confirmDialogBox() {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      panelClass:['confirmation-popup'], disableClose: true,
      data: {
        title: 'Enroll', message: 'Please choose the visit type',
        buttonText: { cancel: 'New visit', ok: 'Follow up' }, 'enrollVisitType': true, 'isRemark': 1
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.enrollVisitType = result;
      if (this.enrollVisitType === 'New visit' || this.enrollVisitType === 'Follow up') {
        this.saveRegisterPatient();
      }
    });
  }

  saveEnroll() {
  
    this.saveRegisterPatient();
  }

  public saveRegisterPatient() {
    this.isDisabled = true;
    this.createRegisterPatient = new CreateRegisterPatient(
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);

    this.createRegisterPatient.mainidentifier = this.checkedRowData.mainidentifier;
    this.createRegisterPatient.firstName = this.checkedRowData.firstName;
    this.createRegisterPatient.lastName = this.checkedRowData.lastName;
    this.createRegisterPatient.age = this.checkedRowData.age;
    this.createRegisterPatient.birthDate = this._dateFormat.transform(this.checkedRowData.birthDate, 'yyyy-MM-dd');
    this.createRegisterPatient.gender = this.checkedRowData.gender;
    this.createRegisterPatient.mobileNo = this.checkedRowData.mobileNo;
    this.createRegisterPatient.patientVisitId = this.checkedRowData.patientVisitId;
    this.createRegisterPatient.id = this.checkedRowData.id;
    this.createRegisterPatient.tagSerialNumber = this.registerPatientForm.controls['tagSerialNumber'].value;
    this.createRegisterPatient.healthPlanId = this.checkedRowData.healthPlanId;

    /*
    Note : Enroll option removed the health paln, visit id based on current date by Jan 22, 2021

    if (this.checkPkgDate === this.selectedDate) {
      this.createRegisterPatient.healthPlanId = this.checkedRowData.healthPlanId;
      this.createRegisterPatient.patientVisitId = this.checkedRowData.patientVisitId;
    } else {
      this.createRegisterPatient.healthPlanId = null;
      this.createRegisterPatient.patientVisitId = null;
    }
    */


    this.createRegisterPatient.isDiabetic = this.isDiabetic;
    this.createRegisterPatient.isFasting = this.isFasting;
    this.createRegisterPatient.isVulnerable = this.isVulnerable;
    this.createRegisterPatient.isPregnant = this.isPregnant;

    if (this.registerPatientForm.controls['vipTypeId'].value === 'null') {
      this.createRegisterPatient.vipTypeId = null;
    } else {
      this.createRegisterPatient.vipTypeId = this.registerPatientForm.controls['vipTypeId'].value;
    }
    this.createRegisterPatient.preferedLanguage = this.registerPatientForm.controls['preferedLanguage'].value;
    this.createRegisterPatient.externalRegionId = localStorage.getItem(btoa('externalRegionId'));

    if (this.isEnableCosterDropdown === true) {
      if (this.tokenEnrollFlow === true) {
        this.createRegisterPatient.tagTypeId = null;
      } else {
        this.createRegisterPatient.tagTypeId = this.tagTypeId;  
      }
    } else {
      this.createRegisterPatient.tagTypeId = 'TT-MB';
    }

    this.checkedRowData.packageDate = this._dateFormat.transform(this.checkedRowData.packageDate, 'yyyy-MM-dd');
    this.createRegisterPatient.packageDate = this._dateFormat.transform(this.registerPatientForm.controls['date'].value, 'yyyy-MM-dd');

    
    if (this.createRegisterPatient.packageDate !== this.checkedRowData.packageDate &&
      this.checkedRowData.packageDate != null && this.checkedRowData.patientVisitId != null && this.checkPkgDate === this.selectedDate) {
      this.createRegisterPatient.isPkgDateEdited = true;
    } else {
      this.createRegisterPatient.isPkgDateEdited = false;
    }

    if (this.isEditDate === true) {

      const pkgDate = this._dateFormat.transform(
        this.registerPatientForm.controls['date'].value, 'yyyy-MM-dd\'T\''
      );

      const pkgTime = this._dateFormat.transform(
        this.currentDate, 'HH:mm:ss.SSSZ'
      );

      this.createRegisterPatient.packageDate = pkgDate.trim() + pkgTime.trim();

    } else {
      
      const date = this._dateFormat.transform(
        this.currentDate, 'yyyy-MM-dd\'T\''
      );

      const time = this._dateFormat.transform(
        this.currentDate, 'HH:mm:ss.SSSZ'
      );

      if (date !== null) {
        this.createRegisterPatient.packageDate = date.trim() + time.trim();
      } else {
        this.createRegisterPatient.packageDate = null;
      }
    }

    this.createRegisterPatient.assignedFloorId = this.floorId;

    if ((this.enrollVisitType === 'Follow up') || (!this.checkedRowData.tokenNo && this.checkedRowData.status === 'Yes')) {
      const pkgDate = this._dateFormat.transform(
        this.currentDate, 'yyyy-MM-dd\'T\''
      );
      const pkgTime = this._dateFormat.transform(
        this.currentDate, 'HH:mm:ss.SSSZ'
      );
      this.createRegisterPatient.packageDate = pkgDate.trim() + pkgTime.trim();
      this.createRegisterPatient.isPkgDateEdited = true;
      this.createRegisterPatient.healthPlanId = this.checkedRowData.healthPlanId;
      this.createRegisterPatient.patientVisitId = this.checkedRowData.patientVisitId;
    }

    this.createRegisterPatient.visitIdentifier = this.checkedRowData.visitIdentifier;
    console.log(this.createRegisterPatient);
    this.isDisabled = true;

    this.commonService.saveRegisteredPatients(this.createRegisterPatient).subscribe(res => {
      if (res.statusCode !== 1) {
        this.isDisabled = false;
      }

      if (res.statusCode === 1) {
        this.isEnabledTable = false;
        this.defaultPkgDateShow = true;
        this.isEditIconEnable = false;
        this.registerPatientForm.reset();
        this.isDisabled = false;
      }

      this.toastr.success('Success', `${res.message}`);

      this.enrollDetails = res.results;
    },
      error => {
        this.isDisabled = false;
        this.toastr.error('Error', `${error.error.message}`);
      });
  }

}

@Component({
  selector: 'app-enroll-patient',
  templateUrl: './enroll-register.component.html',
  styleUrls: ['./enroll-patient.component.scss'],
  providers: [DatePipe,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },],
  
})

export class EnrollRegisterPatientComponent implements OnInit {

  public enrollPatientForm: UntypedFormGroup;
  public isDisabled = false;
  public createEnrollPatient: CreateEnrollPatient;
  public updateEnrollPatient: UpdateEnrollPatient;
  public createMedicalRecord: CreateMedicalRecord;
  public genderList: any;
  public genderCode: any;
  public matcher = new ErrorStateMatcherService();
  public isEnableCosterDropdown = true;
  public searchCosterlist: any;
  isCheck = false;
  checked = true;
  public vipList: Array<any> = [];
  public languageList: Array<any> = [];
  public tokenEnrollFlow: boolean;
  
  public assignFloorDetails: Array<any> = [];
  public floorId: any;
  public wardId: any;
  public bedList: Array<any> = [];
  public doctorList: Array<any> = [];
  public visitType = 'VT-HC';
  public height: any;

  @Input() max: Date | null;    
  today = new Date();
  curDate = new Date()
  tomorrow = new Date(this.curDate.setDate(this.curDate.getDate() + 1));
  healthPlanList: Array<any> = [];
  public bedEnabled = false;
  public doctorEnabled = false;
  public roomEnabled = false;
  public surgeonEnabled = false;
  public surgeryEnabled = false;
  public serviceEnabled = false;
  public healthPlanEnabled = false;
  requireBedMatchVal: any[];
  requireDoctorMatchVal: any[];
  requireMRDoctorMatchVal: any[];
  requireSurgeryMatchVal: any[];
  public addNewSurgeon = null;
  public isAddNewSurgeon = false;
  public addNewSurgery = null;
  public isAddNewSurgery = false;
  public tagTypeId = null;
  public searchLocation: any = [];
  public locationIdEnabled = false;
  public requireLocationMatchVal: any = [];
  public searchLocationId = null;
  public isAddNewMRSurgeon = false;
  public addNewMRSurgeon = null;
  public isAddNewMRLocation = false;
  public addNewMRLocation = null;
  public workflowTypeId = null;
  public listItems1: any=[];
  searchLocationItems: any=[];
  searchAssociateCosterlist: any=[];
  searchAssociateCosterlistItems: any=[];
  public requireTestNameMatchVal: any = [];
  public testListItems: any;
  public selectedTestType: any;
  bedVisitList: any=[];
  bedOption: any=[];
  validDates: any=[];
  visitStartDate = null;
  visitEndDate = null;
  visitTime = null;
  visitDate = null;
  currentDate = null;
  emergencyPlanId = null;
  emergencyPlanName = null;
  activate_btn: any=[];
  constructor(
    public form: UntypedFormBuilder, public toastr: ToastrService, public dialog: MatDialog, private dateAdapter: DateAdapter<Date>,
    public thisDialogRef: MatDialogRef<EnrollRegisterPatientComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService, private _dateFormat: DatePipe) {
    this.activate_btn = this.commonService.getActivePermission('button');
    this.today.setDate(this.today.getDate());
    // dateAdapter.setLocale('en-in'); 
  }

  ngOnInit() {

    if (this.data.hasOwnProperty('visitType') && (this.data.visitType === 'VT-MR')) {
      const curDate = this._dateFormat.transform(new Date(), 'yyyy-MM-dd');
      const compareDate = this._dateFormat.transform(new Date(this.data.filterMRDate), 'yyyy-MM-dd');
      if (curDate < compareDate) {
        this.today.setDate(new Date(this.data.filterMRDate).getDate());
      } else {
        this.today.setDate(this.today.getDate());
      }
    }

    this.workflowTypeId = this.data.workflowTypeId;
    // assign floor details

    this.commonService.getConfigFile('assign-floor').subscribe(res => {
      if (res.results != null) {
        this.assignFloorDetails = res.results.contentObject.filter(filter => filter.isFollowup === 'false');
      }
    });

    if (this.data.hasOwnProperty('visitType') && (this.data.visitType === 'VT-IP' || this.data.visitType === 'VT-EC' || this.data.visitType === 'VT-RE' || this.data.visitType === 'VT-OP' || this.data.visitType === 'VT-MR' || this.data.visitType === 'VT-DC')) {
      if (this.data.visitType === 'VT-RE') {
        this.visitType = 'VT-RE';
      } else if (this.data.visitType === 'VT-OP') {
        this.visitType = 'VT-OP';
      } else if (this.data.visitType === 'VT-MR') {
        this.visitType = 'VT-MR';
      } else if (this.data.visitType === 'VT-DC') {
        this.visitType = 'VT-DC';
      } else if (this.data.visitType === 'VT-EC') {
        this.visitType = 'VT-EC';
        this.commonService.getConfigFile('default-healthplan').subscribe(res => {
          if (res.results != null) {
            console.log(JSON.parse(res.results.content))
            this.healthPlanList.push(JSON.parse(res.results.content));
            this.emergencyPlanId = this.healthPlanList[0].id;
            this.emergencyPlanName = this.healthPlanList[0].name;
            this.enrollPatientForm.get('healthPlanId').setValue(this.emergencyPlanName);
            this.enrollPatientForm.get('healthPlanId').setValidators(Validators.required);
          }
        });
      } else {
        this.visitType = 'VT-IP';
      }
      if (this.data.id) {
        this.commonService.getInpatientListByVisitId(this.data.id).subscribe(res => {
          this.data = { ...res.results, ...this.data };
          this.genderCode = this.data.gender;
          this.floorId = this.data.assignedFloorId;
          let visitListArrays = this.enrollPatientForm.get('visitList') as UntypedFormArray;
          visitListArrays.controls[0].patchValue(
            { "bedId": this.data.visitEvent !== 'VE-OT' && this.visitType !== 'VT-EC' ? this.data.bedName : null ? this.data.bedName : null,
              "visitDate": this.data.enrollOT && (this.data.visitEvent === 'VE-OT' || this.visitType === 'VT-EC') ? this.today : this.data.visitDate ? this.data.visitDate : null });
          this.enrollPatientForm.patchValue({
            'firstName': this.data.firstName,
            'middleName': this.data.middleName,
            'lastName': this.data.lastName,
            'gender': this.data.gender,
            'mobileNo': this.data.mobileNo,
            'assignFloorId': this.data.assignedFloorId,
            'birthDate': this.data.birthDate,
            'bedId': this.data.bedName ? this.data.bedName : null,
            'doctorId': this.data.doctorName ? this.data.doctorName : null,
            'tagSerialNumber':this.data.tagId
          });
        });
      }
    } else {
      this.visitType = 'VT-HC';
    }

    this.buildForm();

    
    this.tokenEnrollFlow = JSON.parse(localStorage.getItem(btoa('tokenEnrollFlow')));
    
    if (this.tokenEnrollFlow === false) {
      this.enrollPatientForm.get('mobileNo').setValidators([Validators.required, Validators.pattern('^[0-9-+()]*$')]);
      this.enrollPatientForm.get('mobileNo').updateValueAndValidity();
      this.enrollPatientForm.get('gender').setValidators(Validators.required);
      this.enrollPatientForm.get('gender').updateValueAndValidity();
      this.enrollPatientForm.get('birthDate').setValidators(Validators.required);
      this.enrollPatientForm.get('birthDate').updateValueAndValidity();

      if (this.enrollPatientForm.get('coster').value === 'coaster') {
        this.enrollPatientForm.get('tagSerialNumber').setValidators(Validators.required);
        this.enrollPatientForm.get('tagSerialNumber').updateValueAndValidity();
      }

      this.enrollPatientForm.get('coster').valueChanges
        .subscribe(value => {
          if (value === 'mobile') {
            this.enrollPatientForm.get('tagSerialNumber').setValidators(null);
            this.enrollPatientForm.controls['tagSerialNumber'].setValue(null);
          }
          if (value === 'coaster') {
            this.enrollPatientForm.get('tagSerialNumber').setValidators(Validators.required);
          }
          this.enrollPatientForm.get('tagSerialNumber').updateValueAndValidity();
        });
      this.enrollPatientForm.get('tagSerialNumber').valueChanges
        .subscribe(value => {
          if (value != null && value.lastIndexOf('~') > -1) {
            const splitValue = value.split('=');
            
            const costerId = splitValue[1].substring(0, 9);
            if (costerId.length <= 9 && costerId.toString().match(/^[0-9]*$/)) {
              this.enrollPatientForm.get('tagSerialNumber').setValue(costerId);
              this.enrollPatientForm.get('tagSerialNumber').updateValueAndValidity();
            }
          }
        });
    }

    if (this.tokenEnrollFlow === true && this.assignFloorDetails.length > 0) {
      this.enrollPatientForm.get('assignFloorId').setValidators(Validators.required);
      this.enrollPatientForm.get('assignFloorId').updateValueAndValidity();
    }

    if (this.visitType === 'VT-EC' || this.visitType === 'VT-HC') {
      this.enrollPatientForm.get('healthPlanId').setValidators([Validators.required,
        !this.data.patientId ? this.requireSurgeryMatch.bind(this) : '']);
    } else if (this.visitType === 'VT-EC') {
      this.enrollPatientForm.get('healthPlanId').setValue(this.emergencyPlanName);
      this.enrollPatientForm.get('healthPlanId').setValidators(Validators.required);
    } else if (this.data.visitEvent === 'VE-OT') {
      this.enrollPatientForm.get('healthPlanId').setValidators(!this.data.patientId ? this.requireSurgeryMatch.bind(this) : '');
    } else {
      this.enrollPatientForm.get('healthPlanId').setValidators(null);
    }
    this.enrollPatientForm.get('healthPlanId').updateValueAndValidity();

    if (this.visitType === 'VT-OP') {
      this.enrollPatientForm.get('testId').setValidators([Validators.required,
        this.requireTestNameMatch.bind(this)]);
        this.enrollPatientForm.get('startDate').setValidators([Validators.required]);
    } else {
      this.enrollPatientForm.get('testId').setValidators(null);
      this.enrollPatientForm.get('startDate').setValidators(null);
    }
    this.enrollPatientForm.get('testId').updateValueAndValidity();
    this.enrollPatientForm.get('startDate').updateValueAndValidity();

    if (this.data.visitEvent === 'VE-OT' || this.visitType === 'VT-IP' || this.visitType === 'VT-EC' || this.visitType === 'VT-DC' || this.visitType === 'VT-RE' || this.visitType === 'VT-OP') {
      if (this.data.visitEvent === 'VE-OT' || this.visitType === 'VT-EC' || this.visitType === 'VT-DC') {
        this.enrollPatientForm.get('doctorId').setValidators([
          !this.data.patientId ? this.requireDoctorMatch.bind(this) : '']);
        // this.enrollPatientForm.get('bedId').setValidators([
        //   !this.data.patientId ? this.requireBedMatch.bind(this) : '']);
      } else {
        this.enrollPatientForm.get('doctorId').setValidators([Validators.required,
        !this.data.patientId ? this.requireDoctorMatch.bind(this) : '']);
        // this.enrollPatientForm.get('bedId').setValidators([Validators.required,
        // !this.data.patientId ? this.requireBedMatch.bind(this) : '']);
      }
    } else {
      // this.enrollPatientForm.get('bedId').setValidators(null);
      this.enrollPatientForm.get('doctorId').setValidators(null);
    }
    // this.enrollPatientForm.get('bedId').updateValueAndValidity();
    this.enrollPatientForm.get('doctorId').updateValueAndValidity();

    
    this.commonService.getAppTermsVerion2('Gender').subscribe(res => {
      this.genderList = res.results;
    });

    
    this.commonService.getAppTermsVerion2('Language').subscribe(res => {
      this.languageList = res.results;
    });

    
    this.commonService.getAppTermsVerion2('VipType').subscribe(res => {
      this.vipList = res.results;
    });

    
  }
  onWindowResized(size) {
    this.height = size;
  }
  private requireBedMatch(control: UntypedFormControl): ValidationErrors | null {
    if(control.value !== null && control.value !== '') {
    this.requireBedMatchVal = this.bedList.filter(resFilter => resFilter.bedId === control.value || resFilter.id === control.value);
    if(this.data.id && this.data.bedId !== null){
      if(this.data.bedName === control.value){
        this.requireBedMatchVal = this.data.bedName;
      }
    }
    if (this.requireBedMatchVal.length === 0) {
        
        return { requireMatch: true };
        // return null;
      }
    }
    return null;
  }
  private requireDoctorMatch(control: UntypedFormControl): ValidationErrors | null {
    if (control.value !== null && control.value !== '') {
      this.requireDoctorMatchVal = this.doctorList.filter(resFilter => resFilter.id === control.value);
      if(this.visitType === 'VT-DC') { 
        if (this.requireDoctorMatchVal.length === 0) {
          return { requireMatch: true };
        }
      } else {
          if (this.requireDoctorMatchVal.length === 0) {
          this.addNewSurgeon = control.value;
          this.isAddNewSurgeon = true;
        } else {
          this.isAddNewSurgeon = false;
        }
      }
    }
    return null;
  }
  private requireSurgeryMatch(control: UntypedFormControl): ValidationErrors | null {
    if (control.value !== null && control.value !== '') {
      this.requireSurgeryMatchVal = this.healthPlanList.filter(resFilter => resFilter.id === control.value);
      if (this.requireSurgeryMatchVal.length === 0) {
        this.addNewSurgery = control.value;
        this.isAddNewSurgery = true;
      } else {
        this.isAddNewSurgery = false;
      }
    }
    return null;
  }

  public buildForm() {

    this.enrollPatientForm = this.form.group({
      mainidentifier: [this.data.mainidentifier ? this.data.mainidentifier : null],
      firstName: [this.data.firstName ? this.data.firstName : null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      middleName: [this.data.middleName ? this.data.middleName : null, [Validators.maxLength(50)]],
      lastName: [this.data.lastName ? this.data.lastName : null, [Validators.maxLength(50)]],
      birthDate: [null],
      gender: [this.data.gender ? this.data.gender : null],
      mobileNo: [this.data.mobileNo ? this.data.mobileNo : null],
      healthPlanId: [this.visitType === 'VT-EC' ? this.emergencyPlanName : null],
      tagSerialNumber: [this.data.tagSerialNumber ? this.data.tagSerialNumber : null],
      coster: ['coaster'],
      isDiabetic: new UntypedFormControl(false),
      isFasting: new UntypedFormControl(false),
      isVulnerable: new UntypedFormControl(false),
      isPregnant: new UntypedFormControl(false),
      vipTypeId: [this.data.vipTypeId ? this.data.vipTypeId : null],
      preferredLanguage: [this.data.preferredLanguage ? this.data.preferredLanguage : null],
      languagesKnown: [this.data.languagesKnown ? this.data.languagesKnown : null],
      assignFloorId: [null],
      bedId: [null],
      doctorId: [null],
      volume: [null],
      deliveryLocationId: [null, [this.requireLocationMatch.bind(this)]],
      requestedDoctorId: [null, [this.requireMRDoctorMatch.bind(this)]],
      requestedFromDate: [this.today],
      requestedToDate: [null],
      testId: [null, [this.requireTestNameMatch.bind(this)]],
      packageDate: [null],
      startDate: [null],
      isPreBooked: [this.data.isPreBooked ? this.data.isPreBooked : false],
      visitList: this.form.array([this.editVisit(0)]),
      // bedId: [null, [Validators.required, !this.data.patientId ? this.requireBedMatch.bind(this) : '']],
      // doctorId: [null, [Validators.required, !this.data.patientId ? this.requireDoctorMatch.bind(this) : '']]
    });
  }


  private addVisit() {
    const control = <UntypedFormArray>this.enrollPatientForm.controls['visitList'];
    control.push(this.getVisit());
  }

  removeVisit(i: number, type) {
    const control = <UntypedFormArray>this.enrollPatientForm.controls['visitList'];
    if (this.enrollPatientForm.controls['visitList'].value[i].hasOwnProperty('bedId') &&
    this.enrollPatientForm.controls['visitList'].value[i].bedId !== null) {
    this.bedOption = this.bedOption.filter(x => this.enrollPatientForm.controls['visitList'].value[i].bedId.indexOf(x) === -1);
  }
    control.removeAt(i);
    if (type === 'clear' && control.length <= 0) {
      this.addVisit();
    }
  }

  private getVisit() {
    if (this.data.visitEvent === 'VE-OT' || this.visitType === 'VT-IP' || this.visitType === 'VT-EC' || this.visitType === 'VT-DC' || this.visitType === 'VT-RE') {
      if (this.data.visitEvent === 'VE-OT' || this.visitType === 'VT-EC' || this.visitType === 'VT-DC') {
        return this.form.group({
          id: [null],
          visitDate: [this.visitType !== 'VT-DC' ? this.today : null, [Validators.required]],
          bedId: [null, [!this.data.patientId ? this.requireBedMatch.bind(this) : '']],
        });
      } else {
        return this.form.group({
          id: [null],
          visitDate: [this.visitType !== 'VT-DC' ? this.today : null, [Validators.required]],
          bedId: [null, [Validators.required, !this.data.patientId ? this.requireBedMatch.bind(this) : '']],
        });
      }
    } else {
      return this.form.group({
        id: [null],
        visitDate: [null],
        bedId: [null],
      });
    }
  }
  filterBedOptions(value) {
    if (value !== null) {
      this.bedOption.push(value);
    } else {
      this.bedOption = [];
    }
  }
  private editVisit(i) {
    if (this.data.visitEvent === 'VE-OT' || this.visitType === 'VT-IP' || this.visitType === 'VT-EC' || this.visitType === 'VT-DC' || this.visitType === 'VT-RE') {
      if (this.data.visitEvent === 'VE-OT' || this.visitType === 'VT-EC' || this.visitType === 'VT-DC') {
        return this.form.group({
          id: [null],
          visitDate: [this.visitType !== 'VT-DC' ? this.today : null, [Validators.required]],
          bedId: [null, [!this.data.patientId ? this.requireBedMatch.bind(this) : '']],
        });
      } else {
        return this.form.group({
          id: [null],
          visitDate: [this.visitType !== 'VT-DC' ? this.today : null, [Validators.required]],
          bedId: [null,  !this.data.patientId ? this.requireBedMatch.bind(this) : ''],
        });
      }
    } else {
      return this.form.group({
        id: [null],
        visitDate: [null],
        bedId: [null],
      });
    }
  }
  dateFilter = (d: Date | null): boolean => {
    if(this.visitType === 'VT-DC') {
      this.validDates = [];
      const control = <UntypedFormArray>this.enrollPatientForm.controls['visitList'];
      for (let i = 0; i < control.length; i++) {
        this.validDates.push(new Date(this.enrollPatientForm.controls['visitList'].value[i].visitDate));
      }
      const day = (new Date(d) || new Date()).getTime();
      return !this.validDates.find(x=>x.getTime()==day);
    } else {
      return true;
    }
  }
  getDoctorList(type, id) {
    if (id) {
      if (type === 'doctor') {
        const doctors = this as any as { id: string, name: string }[]
        return doctors.find(obj => obj.id === id).name
      } else if (type === 'surgeon') {
        const doctors = this as any as { id: string, name: string }[]
        return doctors.find(obj => obj.id === id).name
      }
    } else {
      return ''
    }
  }
  getBedList(type, id) {
    if (id) {
      if (type === 'bed') {
        const bed = this as any as { bedId: string, bedName: string, locationId: string, locationName: string }[]
        let bedId;
        let loctionName = bed.find(obj => obj.bedId === id).locationName;
        if(loctionName === undefined){
          bedId = bed.find(obj => obj.bedId === id).bedName;
        }else{
          bedId = bed.find(obj => obj.bedId === id).bedName + ', ' + loctionName;
        }
        return bedId;
      } else if (type === 'OTRoom') {
        const room = this as any as { id: string, name: string }[];
        const roomId = room.find(obj => obj.id === id).name;
        return roomId;
      }
    } else {
      return '';
    }
  }

  getSurgeryList(type, id) {
    if (id && (type === 'surgery' || type === 'healthPlan')) {
      const surgery = this as any as { id: string, name: string }[];
      return surgery.find(obj => obj.id === id).name;
    } else {
      return '';       
    }
  }

  getTestList(type, id) {
    if (id && (type === 'service')) {
      const service = this as any as { id: string, name: string }[];
      return service.find(obj => obj.id === id).name;
    } else {
      return '';
    }
  }
  clearUHID(event) {
    if (event.target.value === '') {
      this.enrollPatientForm.reset();
      this.enrollPatientForm.patchValue({
        'requestedFromDate': this.today
      });
      if (this.visitType === 'VT-EC') {
        const arrays = this.enrollPatientForm.get('visitList') as UntypedFormArray;
        arrays.controls[0].patchValue({ 'visitDate': this.today });
        this.enrollPatientForm.get('healthPlanId').setValue(this.emergencyPlanName);
        this.enrollPatientForm.get('healthPlanId').setValidators(Validators.required);
      }
    }
  }
  getUHID(value) {
    if (value === '') {
      this.enrollPatientForm.reset();
      this.enrollPatientForm.patchValue({
        'requestedFromDate': this.today
      });
    }
    const uhid = value;
    this.enrollPatientForm.reset();
    if (this.data.visitEvent === 'VE-OT' || this.visitType === 'VT-EC') {
      const arrays = this.enrollPatientForm.get('visitList') as UntypedFormArray;
      arrays.controls[0].patchValue({ 'visitDate': this.today });
    }
    if (this.visitType === 'VT-EC') {
      this.enrollPatientForm.get('healthPlanId').setValue(this.emergencyPlanName);
      this.enrollPatientForm.get('healthPlanId').setValidators(Validators.required);
    }
    this.commonService.getUHID(uhid).subscribe(res => {
      const visitEvent = this.data.visitEvent;
      this.data = res.results;
      if (visitEvent === 'VE-OT' && this.data.patientVisitId !== null) {
        this.data.enrollOT = true;
      }
      this.data.visitEvent = visitEvent;
      this.genderCode = this.data.gender;
      this.floorId = this.data.assignedFloorId;
      this.data.visitType = this.visitType;
      this.enrollPatientForm.patchValue({
        'mainidentifier': this.data.mainidentifier,
        'firstName': this.data.firstName,
        'middleName': this.data.middleName,
        'lastName': this.data.lastName,
        'gender': this.data.gender,
        'mobileNo': this.data.mobileNo,
        'assignFloorId': this.data.assignedFloorId,
        'birthDate': this.data.birthDate,
        'bedId': this.data.bedId ? this.data.bedId + ', ' + this.data.bedName : null,
        'doctorId': this.data.doctorId ? this.data.doctorId + ', ' + this.data.doctorName : null,
        'requestedFromDate': this.today,
      });
    },
      error => {
        this.toastr.error('Error', `${error.error.message}`);
        this.enrollPatientForm.reset();
        this.enrollPatientForm.patchValue({
          'requestedFromDate': this.today
        });
        this.data.mainidentifier = uhid;
        this.buildForm();
    
        if (this.visitType === 'VT-HC') {
          this.enrollPatientForm.get('healthPlanId').setValidators([Validators.required,
            !this.data.patientId ? this.requireSurgeryMatch.bind(this) : '']);
        } else if (this.visitType === 'VT-EC') {
          this.enrollPatientForm.get('healthPlanId').setValue(this.emergencyPlanName);
          this.enrollPatientForm.get('healthPlanId').setValidators(Validators.required);
        } else if (this.data.visitEvent === 'VE-OT') {
          this.enrollPatientForm.get('healthPlanId').setValidators(!this.data.patientId ? this.requireSurgeryMatch.bind(this) : '');
        } else {
          this.enrollPatientForm.get('healthPlanId').setValidators(null);
        }
        this.enrollPatientForm.get('healthPlanId').updateValueAndValidity();
    
        if (this.data.visitEvent === 'VE-OT' || this.visitType === 'VT-IP' || this.visitType === 'VT-EC' || this.visitType === 'VT-DC' || this.visitType === 'VT-RE' || this.visitType === 'VT-OP') {
          if (this.data.visitEvent === 'VE-OT' || this.visitType === 'VT-EC' || this.visitType === 'VT-DC') {
            this.enrollPatientForm.get('doctorId').setValidators([
              !this.data.patientId ? this.requireDoctorMatch.bind(this) : '']);
            // this.enrollPatientForm.get('bedId').setValidators([
            //   !this.data.patientId ? this.requireBedMatch.bind(this) : '']);
          } else {
            this.enrollPatientForm.get('doctorId').setValidators([Validators.required,
            !this.data.patientId ? this.requireDoctorMatch.bind(this) : '']);
            // this.enrollPatientForm.get('bedId').setValidators([Validators.required,
            // !this.data.patientId ? this.requireBedMatch.bind(this) : '']);
          }
        } else {
          // this.enrollPatientForm.get('bedId').setValidators(null);
          this.enrollPatientForm.get('doctorId').setValidators(null);
        }
        // this.enrollPatientForm.get('bedId').updateValueAndValidity();
        this.enrollPatientForm.get('doctorId').updateValueAndValidity();
      });
  }
  isEnableCoster(event) {
    if (event === 'coaster') {
      this.isEnableCosterDropdown= true;
    } else {
      this.isEnableCosterDropdown = false;
    }
  }
  searchToCoster(event) {
    if(event.type === 'associateTagId' && event.text.length >= 2) {
      if (event.toHit == true) {
        this.commonService.getAllTagByType(event.text, this.workflowTypeId, 'ST-AT').subscribe(res => {
          this.searchAssociateCosterlistItems = res.results;
          this.searchAssociateCosterlist = this.searchAssociateCosterlistItems;
        });
      } else {
        this.searchAssociateCosterlist = this.searchAssociateCosterlistItems;
      } 
    } else {
      this.searchAssociateCosterlist = [];
    }
    if(event.type === 'coasterId' && event.text.length >= 2) {
      if (event.toHit == true) {
        this.commonService.getAllTagByType(event.text, this.workflowTypeId, 'ST-AT').subscribe(res => {
          this.listItems1 = res.results;
          this.searchCosterlist = this.listItems1;
        });
      } else {
        this.searchCosterlist = this.listItems1;
      }
    } else {
      this.searchCosterlist = [];
    }
  }
  getTagTypeId(tagTypeId) {
    this.tagTypeId = tagTypeId;
  }
  getGenderType(data) {
    this.genderCode = data.code;
  }

  getAssignFloor(data) {
    this.floorId = data;
  }
  getWardLocation(wardId) {
    console.log(wardId);
    this.wardId = wardId;

  }

  searchAssociationDetails(name, type, event, i) {
    if(i !== null) {
      this.currentDate = this._dateFormat.transform(this.today, 'yyyy-MM-dd');
      this.visitDate = this._dateFormat.transform(this.enrollPatientForm.controls['visitList'].value[i].visitDate, 'yyyy-MM-dd');
      this.visitTime = this._dateFormat.transform(this.today, 'HH:mm:ss');
      if (this.visitDate === this.currentDate) {
        this.visitStartDate = this._dateFormat.transform(this.enrollPatientForm.controls['visitList'].value[i].visitDate, 'yyyy-MM-dd') + ' ' + this.visitTime;
      } else {
        this.visitStartDate = this._dateFormat.transform(this.enrollPatientForm.controls['visitList'].value[i].visitDate, 'yyyy-MM-dd 00:00:00');
      }
      this.visitEndDate = this._dateFormat.transform(this.enrollPatientForm.controls['visitList'].value[i].visitDate, 'yyyy-MM-dd 23:59:00');
    }
    if (name !== '') {
      if (type === 'bed' || type === 'OTRoom') {
        if (name.length >= 2) {
          if (type === 'bed' && (event.keyCode <= 90 && event.keyCode >= 48 || event.keyCode == 8 || event.keyCode == 32)) {
            this.bedEnabled = true;
            this.commonService.searchBedLocation(name, this.visitStartDate, this.visitEndDate).pipe(
              debounceTime(3000),
              distinctUntilChanged(),
            ).subscribe(res => {
              this.bedList = res.results;
              if (this.data.id && this.data.bedId !== null) {
                this.bedList = this.bedList.filter(x => x.bedId !== this.data.bedId);
                this.bedList = this.bedList.concat([{bedId: this.data.bedId, bedName: this.data.bedName,
                locationId: this.data.locationId}]);
              }
            });
          } else if (type === 'bed' || type === 'OTRoom' && (event.keyCode == 38 || event.keyCode == 40)) {
            const bedList = this.bedList;
            this.bedList = bedList;
          } else if (type = 'OTRoom' && (event.keyCode <= 90 && event.keyCode >= 48 || event.keyCode == 8 || event.keyCode == 32)) {
            this.roomEnabled = true;
            this.commonService.getSpecialityLoc(null, 'LC_OT', name).pipe(
              debounceTime(3000),
              distinctUntilChanged(),
            ).subscribe(res => {
              this.bedList = res.results;
            });
          }
        }
      } else {
        this.bedList = [];
      }
      if (type === 'doctor' || type === 'surgeon') {
        if (name.length >= 3) {
          let userType = 'UT_DOCTOR';
          let roleType = null;
          if (event.keyCode <= 90 && event.keyCode >= 48 || event.keyCode == 8 || event.keyCode == 32 || event.keyCode == 190) {
            if (type === 'surgeon') {
              this.surgeonEnabled = true;
              userType = 'UT_SURGEON';
            } else {
              this.doctorEnabled = true;
              roleType = 'RO-DO';
            }
            this.commonService.searchDoctor(name, userType, roleType).pipe(
              debounceTime(3000),
              distinctUntilChanged(),
            ).subscribe(res => {
              this.doctorList = res.results;
            });
          } else if (type === 'surgeon' || type === 'doctor' && (event.keyCode == 38 || event.keyCode == 40)) {
            const doctorList = this.doctorList;
            this.doctorList = doctorList;
          }
        }
      } else {
        this.doctorList = [];
      }
      if (type === 'surgery' || type === 'healthPlan') {
        if (name.length >= 3) {
          let planType = 'HP-HC';
          if (event.keyCode <= 90 && event.keyCode >= 48 || event.keyCode == 8 || event.keyCode == 32 || event.keyCode == 190) {
            if (type === 'surgery') {
              this.surgeryEnabled = true;
              if(this.visitType === 'VT-EC') {
                planType = 'HP-EC';
              } else {
                planType = 'HP-OT';
              }
            } else {
              this.healthPlanEnabled = true;
            }
            this.commonService.getOTHealthPlan(planType, name).pipe(
              debounceTime(3000),
              distinctUntilChanged(),
            ).subscribe(res => {
              this.healthPlanList = res.results;
            });
          }
        } else if (type === 'surgery' && (event.keyCode == 38 || event.keyCode == 40)) {
          const healthPlanList = this.healthPlanList;
          this.healthPlanList = healthPlanList;
        }
      } else {
        this.healthPlanList = [];
      }
      if (type === 'service') {
        if (name.length >= 3) {
          if (event.keyCode <= 90 && event.keyCode >= 48 || event.keyCode == 8 || event.keyCode == 32 || event.keyCode == 190) {
            this.serviceEnabled = true;
            this.commonService.getAllHeathTestWithoutAdminTest(name).pipe(
              debounceTime(3000),
              distinctUntilChanged(),
            ).subscribe(res => {
              this.testListItems = res.results;
            });
          }
        } else if (type === 'service' && (event.keyCode == 38 || event.keyCode == 40)) {
          const testListItems = this.testListItems;
          this.testListItems = testListItems;
        }
      } else {
        this.testListItems = [];
      }
    } else {
      this.bedList = [];
      this.doctorList = [];
      this.healthPlanList = [];
      this.testListItems = [];
    }
  }

  getLocationSearch(event) {
    this.locationIdEnabled = true;
    if(event.type === 'location' && event.text.length >= 2) {
      if (event.toHit == true) {
        this.commonService.getLocationSearch(event.text).subscribe(res => {
          this.searchLocationItems = res.results;
          this.searchLocation = this.searchLocationItems;
          this.searchLocationId = null;
        });
      } else {
        this.searchLocation = this.searchLocationItems;
      }
    } else {
      this.searchLocation = [];
    }
  }
  getLocationIdList(id) {
    if (id) {
      const location = this as any as { id: string, name: string }[];
      const locationId = location.find(obj => obj.id === id).name;
      return locationId;
    } else {
      return '';
    }
  }

  private requireLocationMatch(control: UntypedFormControl): ValidationErrors | null {
    if (control.value !== null && control.value !== '' && this.locationIdEnabled) {
      this.requireLocationMatchVal = this.searchLocation.filter(resFilter => resFilter.id === control.value);
      if (this.requireLocationMatchVal.length === 0) {
        this.addNewMRLocation = control.value;
        this.isAddNewMRLocation = true;
      } else {
        this.isAddNewMRLocation = false;
      }
    }
    return null;
  }
  private requireMRDoctorMatch(control: UntypedFormControl): ValidationErrors | null {
    if (control.value !== null && control.value !== '') {
      this.requireMRDoctorMatchVal = this.doctorList.filter(resFilter => resFilter.id === control.value);
      if (this.requireMRDoctorMatchVal.length === 0) {
        this.addNewMRSurgeon = control.value;
        this.isAddNewMRSurgeon = true;
      } else {
        this.isAddNewMRSurgeon = false;
      }
    }
    return null;
  }

  private requireTestNameMatch(control: UntypedFormControl): ValidationErrors | null {
    if (control.value !== null && control.value !== '') {
    this.requireTestNameMatchVal = this.testListItems.filter(resFilter => resFilter.id === control.value);
    if (this.requireTestNameMatchVal.length === 0) {
        return { requireMatch: true };
      }
    }
    return null;
  }

  getTestNameDetails(data) {
    this.selectedTestType = data.testType;
  }

  public saveRegisterPatient() {
    if (this.visitType === 'VT-MR') {
      this.createMRRequest();
      return;
    }
    
    this.createEnrollPatient = new CreateEnrollPatient(null, null, null, null, null, null, null, null, null, null, null, null, null,
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
    this.createEnrollPatient.mainidentifier = this.enrollPatientForm.controls['mainidentifier'].value;
    this.createEnrollPatient.firstName = this.enrollPatientForm.controls['firstName'].value;
    this.createEnrollPatient.middleName = this.enrollPatientForm.controls['middleName'].value;
    this.createEnrollPatient.lastName = this.enrollPatientForm.controls['lastName'].value;
    this.createEnrollPatient.birthDate = this._dateFormat.transform(this.enrollPatientForm.controls['birthDate'].value, 'yyyy-MM-dd');
    if (this.genderCode === undefined) {
      this.createEnrollPatient.gender = null;
    } else {
      this.createEnrollPatient.gender = this.genderCode;
    }
    this.createEnrollPatient.mobileNo = this.enrollPatientForm.controls['mobileNo'].value;
    this.createEnrollPatient.tagSerialNumber = this.enrollPatientForm.controls['tagSerialNumber'].value;

    this.createEnrollPatient.isDiabetic = this.enrollPatientForm.controls['isDiabetic'].value;
    this.createEnrollPatient.isFasting = this.enrollPatientForm.controls['isFasting'].value;
    this.createEnrollPatient.isVulnerable = this.enrollPatientForm.controls['isVulnerable'].value;
    this.createEnrollPatient.isPregnant = this.enrollPatientForm.controls['isPregnant'].value;
    this.createEnrollPatient.vipTypeId = this.enrollPatientForm.controls['vipTypeId'].value;

    if (this.enrollPatientForm.controls['vipTypeId'].value === 'null') {
      this.createEnrollPatient.vipTypeId = null;
    } else {
      this.createEnrollPatient.vipTypeId = this.enrollPatientForm.controls['vipTypeId'].value;
    }

    this.createEnrollPatient.preferedLanguage = this.enrollPatientForm.controls['preferredLanguage'].value;

    if (this.isEnableCosterDropdown === true) {
      
        this.createEnrollPatient.tagTypeId = this.tagTypeId;
      
    } else {
      this.createEnrollPatient.tagTypeId = 'TT-MB';
    }

    if (this.floorId === undefined) {
      this.createEnrollPatient.assignedFloorId = null;
    } else {
      this.createEnrollPatient.assignedFloorId = this.floorId;
    }

    
    if (this.visitType === 'VT-IP' || this.visitType === 'VT-EC' || this.visitType === 'VT-RE' || this.visitType === 'VT-OP'  || this.visitType === 'VT-DC') {
      this.createEnrollPatient.visitTypeId = this.visitType;

      if (this.data.visitEvent === 'VE-OT' || this.visitType === 'VT-EC') {
        const OTEventDetails = {};
        if (this.data.visitEvent === 'VE-OT') {
          if(this.data.id !== null) {
            this.createEnrollPatient.id = this.data.id ;
          } else {
            this.createEnrollPatient.id = null;
          }
          this.createEnrollPatient.checkExisting = true;
        }
        if (this.enrollPatientForm.controls['visitList'].value[0].bedId != null) {
          OTEventDetails['locationId'] = this.enrollPatientForm.controls['visitList'].value[0].bedId;
        }

        if (this.enrollPatientForm.controls['doctorId'].value != null) {
          if (this.isAddNewSurgeon) {
            OTEventDetails['userId'] = null;
            OTEventDetails['firstName'] = this.addNewSurgeon;
          } else {
            OTEventDetails['userId'] = this.enrollPatientForm.controls['doctorId'].value;
            OTEventDetails['firstName'] = null;
          }
          
        }

        this.createEnrollPatient.visitEvent = OTEventDetails;
        this.createEnrollPatient.eventType = this.data.visitEvent;
        
        if (this.isAddNewSurgery) {
          this.createEnrollPatient.healthPlanId = null;
          this.createEnrollPatient.planName = this.addNewSurgery;
        } else {
          this.createEnrollPatient.healthPlanId = this.enrollPatientForm.controls['healthPlanId'].value;
          this.createEnrollPatient.planName = null;
        }
        if (this.data.enrollOT) {
          this.createEnrollPatient.patientVisitId = this.data.patientVisitId;
          if (this.data.tagSerialNumber !== null) {
            this.createEnrollPatient.tagTypeId = this.data.tagTypeId;
          }
          if (this.enrollPatientForm.controls['visitList'].value[0].visitDate != null) {
            this.createEnrollPatient.packageDate = this._dateFormat.transform(
              this.enrollPatientForm.controls['visitList'].value[0].visitDate, 'yyyy-MM-ddTHH:mm:ss');
          }
        }
      } else {
        if (this.enrollPatientForm.controls['visitList'].value[0].bedId != null) {
          this.createEnrollPatient.bedId = this.enrollPatientForm.controls['visitList'].value[0].bedId;
          this.createEnrollPatient.locationId = this.wardId;
        }

        if (this.enrollPatientForm.controls['doctorId'].value != null) {
          this.createEnrollPatient.doctorId = this.enrollPatientForm.controls['doctorId'].value;
        }
      }
    }

    if (this.visitType === 'VT-HC' || this.visitType === 'VT-EC') {
      if(this.visitType === 'VT-EC') {
        this.createEnrollPatient.healthPlanId = this.emergencyPlanId;
      } else {
        this.createEnrollPatient.healthPlanId = this.enrollPatientForm.controls['healthPlanId'].value;
      }
      this.createEnrollPatient.visitTypeId = this.visitType;
    }
    if (this.visitType === 'VT-DC') {
      const control = <UntypedFormArray>this.enrollPatientForm.controls['visitList'];
      this.bedVisitList = [];
      console.log(control.length)
      for (let i = 0; i < control.length; i++) {
        const bedVisit = {
          'bedId': this.enrollPatientForm.controls['visitList'].value[i].bedId,
          'visitDate': this._dateFormat.transform(this.enrollPatientForm.controls['visitList'].value[i].visitDate, 'yyyy-MM-dd HH:mm:ss')
        };
      this.bedVisitList.push(bedVisit);
      }
      this.createEnrollPatient.visits = this.bedVisitList;
    }

    if (this.visitType === 'VT-OP') {
      this.createEnrollPatient.startDate    = this._dateFormat.transform(
        this.enrollPatientForm.controls['startDate'].value, 'yyyy-MM-dd HH:mm:ss');
      this.createEnrollPatient.testId       = this.enrollPatientForm.controls['testId'].value;
      this.createEnrollPatient.testType     = this.selectedTestType;
      this.createEnrollPatient.isPreBooked  = this.enrollPatientForm.controls['isPreBooked'].value;
    }

    console.log(this.createEnrollPatient);
    
    this.isDisabled = true;
    this.commonService.saveRegisteredPatients(this.createEnrollPatient).subscribe(res => {
      if (res.statusCode !== 1) {
        this.isDisabled = false;
      }

      if (res.statusCode === 1) {
        this.bedEnabled = false;
        this.doctorEnabled = false;
        this.roomEnabled = false;
        this.surgeonEnabled = false;
        this.isDisabled = false;
        this.thisDialogRef.close(res.results);
        this.enrollPatientForm.reset();
      }

      this.toastr.success('Success', `${res.message}`);

    },
      error => {
        if(error.error.errorCode === "TWAPI159") {
          const dialogRef = this.dialog.open(ConfirmationDialog, {
            panelClass:['confirmation-popup'], disableClose: true,
            data: {
              title: 'OT Enroll', message: "A previous OT event has been detected for this patient. Press YES to proceed with the existing event or NO to create a new one.",
              buttonText: { ok: 'Yes', cancel: 'No' },
              'enrollData': this.createEnrollPatient, 'isRemark': 1, 'oTEnroll': true
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            this.thisDialogRef.close('confirm');
          });
        } else {
          this.isDisabled = false;
          this.toastr.error('Error', `${error.error.message}`);
        }
      });
  }

  public updateRegisterPatient() {
    this.updateEnrollPatient = new UpdateEnrollPatient(null, null, null, null, null, null, null, null, null, null, null, null, null, null);
    this.updateEnrollPatient.firstName = this.enrollPatientForm.controls['firstName'].value;
    this.updateEnrollPatient.middleName = this.enrollPatientForm.controls['middleName'].value;
    this.updateEnrollPatient.lastName = this.enrollPatientForm.controls['lastName'].value;
    this.updateEnrollPatient.birthDate = this._dateFormat.transform(this.enrollPatientForm.controls['birthDate'].value, 'yyyy-MM-dd');
    if (this.genderCode === undefined) {
      this.updateEnrollPatient.gender = null;
    } else {
      this.updateEnrollPatient.gender = this.genderCode;
    }
    this.updateEnrollPatient.mobileNo = this.enrollPatientForm.controls['mobileNo'].value;

    this.updateEnrollPatient.assignedFloorId = this.floorId;

    if (this.visitType === 'VT-IP' || this.visitType === 'VT-EC' || this.visitType === 'VT-RE' || this.visitType === 'VT-DC') {
      this.updateEnrollPatient.visitTypeId = this.visitType;
      this.updateEnrollPatient.patientId = this.data.patientId;
      this.updateEnrollPatient.locationId = this.data.locationId;
      if (!this.bedEnabled && !this.roomEnabled && this.enrollPatientForm.controls['visitList'].value[0].bedId !== '') {
        this.updateEnrollPatient.bedId = this.data.bedId;
      } else if (this.enrollPatientForm.controls['visitList'].value[0].bedId === '') {
        this.updateEnrollPatient.bedId = null;
        this.updateEnrollPatient.locationId = null;
      } else {
        this.updateEnrollPatient.bedId = this.enrollPatientForm.controls['visitList'].value[0].bedId;
        this.updateEnrollPatient.locationId = this.wardId;
      }

      if (!this.doctorEnabled && !this.surgeonEnabled && this.enrollPatientForm.controls['doctorId'].value !== '') {
        this.updateEnrollPatient.doctorId = this.data.doctorId;
      } else if (this.enrollPatientForm.controls['doctorId'].value === '') {
        this.updateEnrollPatient.doctorId = null;
      }  else {
        this.updateEnrollPatient.doctorId = this.enrollPatientForm.controls['doctorId'].value;
      }
    }
    if (this.data.visitDate === this.enrollPatientForm.controls['visitList'].value[0].visitDate) {
      this.updateEnrollPatient.visitDate = this.data.visitDate;
    } else {
      this.updateEnrollPatient.visitDate = this._dateFormat.transform(
        this.enrollPatientForm.controls['visitList'].value[0].visitDate, 'yyyy-MM-dd HH:mm:ss');
    }
    // this.updateEnrollPatient.visitDate = this.data.visitDate;
    this.updateEnrollPatient.patientVisitId = this.data.patientVisitId;

    console.log(this.updateEnrollPatient);


    this.commonService.updateRegisteredPatients(this.updateEnrollPatient).subscribe(res => {

      if (res.statusCode === 1) {
        this.bedEnabled = false;
        this.doctorEnabled = false;
        this.roomEnabled = false;
        this.surgeonEnabled = false;
        this.thisDialogRef.close(res.results);
        this.enrollPatientForm.reset();
      }

      this.toastr.success('Success', `${res.message}`);

    },
      error => {
        this.isDisabled = false;
        this.toastr.error('Error', `${error.error.message}`);
      });

  }

  createMRRequest() {
    this.createMedicalRecord = new CreateMedicalRecord(null, null, null, null, null, null, null, null, null, null, null, null, null, null);
    this.createMedicalRecord.mainIdentifier = this.enrollPatientForm.controls['mainidentifier'].value;
    this.createMedicalRecord.firstName = this.enrollPatientForm.controls['firstName'].value;
    this.createMedicalRecord.middleName = this.enrollPatientForm.controls['middleName'].value;
    this.createMedicalRecord.lastName = this.enrollPatientForm.controls['lastName'].value;
    this.createMedicalRecord.dob = this._dateFormat.transform(this.enrollPatientForm.controls['birthDate'].value, 'yyyy-MM-dd');
    if (this.genderCode === undefined) {
      this.createMedicalRecord.gender = null;
    } else {
      this.createMedicalRecord.gender = this.genderCode;
    }
    this.createMedicalRecord.mobileNo = this.enrollPatientForm.controls['mobileNo'].value;
    this.createMedicalRecord.volume = this.enrollPatientForm.controls['volume'].value;
    // this.createMedicalRecord.deliveryLocationId = this.enrollPatientForm.controls['deliveryLocationId'].value;
    // this.createMedicalRecord.requestedDoctorId = this.enrollPatientForm.controls['requestedDoctorId'].value;
    if (this.enrollPatientForm.controls['requestedToDate'].value !== null) {
      const requestedToDate = this._dateFormat.transform(this.enrollPatientForm.controls['requestedToDate'].value, 'yyyy-MM-dd') + ' ' +this._dateFormat.transform(this.today, 'HH:mm');
      this.createMedicalRecord.requestedToDate = this._dateFormat.transform(requestedToDate, 'dd-MM-yyyy HH:mm');
    }
    if (this.enrollPatientForm.controls['requestedFromDate'].value !== null) {
      const requestedFromDate = this._dateFormat.transform(this.enrollPatientForm.controls['requestedFromDate'].value, 'yyyy-MM-dd') + ' ' +this._dateFormat.transform(this.today, 'HH:mm');
      this.createMedicalRecord.requestedFromDate = this._dateFormat.transform(requestedFromDate, 'dd-MM-yyyy HH:mm');
    }

    if (this.enrollPatientForm.controls['deliveryLocationId'].value !== null) {
      if (this.isAddNewMRLocation) {
        this.createMedicalRecord.deliveryLocationId = null;
        this.createMedicalRecord.deliveryLocation = this.addNewMRLocation;
      } else {
        this.createMedicalRecord.deliveryLocationId =  this.enrollPatientForm.controls['deliveryLocationId'].value;
        this.createMedicalRecord.deliveryLocation = null;
      }
    }

    if (this.enrollPatientForm.controls['requestedDoctorId'].value !== null) {
      if (this.isAddNewMRSurgeon) {
        this.createMedicalRecord.requestedDoctorId = null;
        this.createMedicalRecord.requestedDoctor = this.addNewMRSurgeon;
      } else {
        this.createMedicalRecord.requestedDoctorId = this.enrollPatientForm.controls['requestedDoctorId'].value;
        this.createMedicalRecord.requestedDoctor = null;
      }
    }
    console.log(this.createMedicalRecord);
    // return;

    this.commonService.createMedicalRecordRequest(this.createMedicalRecord).subscribe(res => {

      if (res.statusCode === 1) {
        this.locationIdEnabled = false;
        this.doctorEnabled = false;
        this.thisDialogRef.close(res.results);
        this.enrollPatientForm.reset();
      }

      this.toastr.success('Success', `${res.message}`);

    },
      error => {
        this.isDisabled = false;
        this.toastr.error('Error', `${error.error.message}`);
      });

  }

}



@Component({
  selector: 'app-enroll-patient',
  templateUrl: './enroll-employee-register.component.html',
  styleUrls: ['./enroll-patient.component.scss'],
  providers: [DatePipe,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  
})

export class EnrollRegisterEmployeeComponent implements OnInit, AfterViewInit {

  public enrollEmployeeForm: UntypedFormGroup;
  public createEnrollEmployee: CreateEnrollEmployee;
  public editEnrollEmployee: EditEnrollEmployee;
  public createEnrollVisitor: CreateEnrollVisitor;
  public editEnrollVisitor: EditEnrollVisitor;
  public createEnrollTempId: CreateEnrollTempId;
  public editEnrollTempId: EditEnrollTempId;
  public facilityId: any;
  public genderList: any;
  public genderCode: any;
  public matcher = new ErrorStateMatcherService();
  public employeeTagList: any = [];
  public vitalTagList: any = [];
  public selected = false;
  public selectedTags: any = [];
  public vitalTagId = null;
  public employeeTagId = null;
  public employeeId = null;
  public today = new Date();
  public tempEmpId = false;
  public tempEmpIdList = [];
  public tempDataId: any;
  public countrycodeList = [];
  public countryOptions: Observable<any>;
  public visitorType: any[] = [];
  public visitorCheckout = null;
  public height: any;
  @ViewChild('input', { read: MatAutocompleteTrigger }) autoTrigger: MatAutocompleteTrigger;
  public emptyList: boolean;
  public workflowTypeId = null;
  employeeTagListItems: any=[];
  vitalTagListItems: any=[];
  constructor(
    public form: UntypedFormBuilder, public toastr: ToastrService, public dialog: MatDialog, public datepipe: DatePipe,
    public thisDialogRef: MatDialogRef<EnrollRegisterEmployeeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonServices: CommonService, private dateAdapter: DateAdapter<Date>, public _dateFormat: DatePipe) {
    this.facilityId = localStorage.getItem('ZmFjaWxpdHlJZA==');
    // dateAdapter.setLocale('en-in');
  }

  ngOnInit() {
    this.workflowTypeId = this.data.workflowTypeId;
    this.commonServices.getAppTerms('VisitorType').subscribe(res => {
      this.visitorType = res.results;
    });
    this.commonServices.getAppTermsVerion2('CountryCode').subscribe(res => {
      this.countrycodeList = res.results;
    });

    this.buildForm();
    if (this.data.eType === 'tempId') {
      this.enrollEmployeeForm.get('employeeTagSerialNumber').setValidators(Validators.required);
      this.enrollEmployeeForm.get('employeeTagSerialNumber').updateValueAndValidity();
    } else {
      this.enrollEmployeeForm.get('employeeTagSerialNumber').setValidators(null);
      this.enrollEmployeeForm.get('employeeTagSerialNumber').updateValueAndValidity();
    }
    if (this.data.eType === 'visitor') {
      if (this.data.checkoutTime != null) {
        this.enrollEmployeeForm.get('employeeTagSerialNumber').disable();
      }
      this.enrollEmployeeForm.get('visitorTypeId').setValidators(Validators.required);
      this.enrollEmployeeForm.get('visitorTypeId').updateValueAndValidity();
      
      this.enrollEmployeeForm.get('mainidentifier').setValidators(null);
      this.enrollEmployeeForm.get('mainidentifier').updateValueAndValidity();
    } else {
      this.enrollEmployeeForm.get('visitorTypeId').setValidators(null);
      this.enrollEmployeeForm.get('visitorTypeId').updateValueAndValidity();
    }
    
    this.commonServices.getAppTermsVerion2('Gender').subscribe(res => {
      this.genderList = res.results;
    });
    if ('tagId' in this.data) {
      this.employeeTagId = this.data.tagId;
      this.enrollEmployeeForm.get('employeeTagSerialNumber').setValue(this.data.tagId);
      this.enrollEmployeeForm.get('employeeTagSerialNumber').updateValueAndValidity();
    }
    if ('tags' in this.data) {
      this.employeeId = this.data.mainidentifier;
      this.enrollEmployeeForm.get('mainidentifier').setValue(this.data.mainidentifier);
      this.enrollEmployeeForm.get('mainidentifier').updateValueAndValidity();
      const tagIds = this.data.tags;
      for (let j = 0; j < tagIds.length; j++) {
        console.log(tagIds[j]['tagTypeId']);
        if (tagIds[j]['tagTypeId'] === 'TT-IDCO') {
          this.employeeTagId = tagIds[j]['tagSerialNumber'];
          this.enrollEmployeeForm.get('employeeTagSerialNumber').setValue(tagIds[j]['tagSerialNumber']);
          this.enrollEmployeeForm.get('employeeTagSerialNumber').updateValueAndValidity();
        }
        if (tagIds[j]['tagTypeId'] === 'TT-VTBLE') {
          this.vitalTagId = tagIds[j]['tagSerialNumber'];
          this.enrollEmployeeForm.get('visitorTagSerialNumber').setValue(tagIds[j]['tagSerialNumber']);
          this.enrollEmployeeForm.get('visitorTagSerialNumber').updateValueAndValidity();
        }
      }
    }
    this.enrollEmployeeForm.get('countryCode').valueChanges
      .subscribe(value => {
        if (value === '+971') {
          this.enrollEmployeeForm.get('mobileNo').setValidators(Validators.pattern('^([4-9][0-9]{8})$'));
          this.enrollEmployeeForm.controls['mobileNo'].updateValueAndValidity();
        } else {
          this.enrollEmployeeForm.get('mobileNo').setValidators(Validators.pattern('^([6-9][0-9]{9})$'));
          this.enrollEmployeeForm.controls['mobileNo'].updateValueAndValidity();
        }
      });
    this.countryOptions = this.enrollEmployeeForm.controls['countryCode'].valueChanges.pipe(
      startWith(null),
      map(value => this.countrycodeList.filter(country => country.code.indexOf(value) === 0))
    );
  }
  ngAfterViewInit() {
    if (this.data.eType === 'tempId') {
      this.autoTrigger.panelClosingActions.subscribe(x => {
        if (this.autoTrigger.activeOption) {
          this.enrollEmployeeForm.get('mainidentifier').setValue(this.autoTrigger.activeOption.value)
          this.checkEmpId(this.autoTrigger.activeOption.value);
        }
      });
    }
  }
  public buildForm() {

    this.enrollEmployeeForm = this.form.group({
      firstName: [this.data.firstName ? this.data.firstName : null,
      [Validators.required, Validators.pattern('[a-zA-Z.,\u0600-\u06FF ]*'), Validators.minLength(3), Validators.maxLength(50)]],
      mainidentifier: [this.data.mainidentifier ? this.data.mainidentifier : null, [Validators.required]],
      hostEmployeeId: [this.data.hostEmployeeId ? this.data.hostEmployeeId : null],
      email: [this.data.email ? this.data.email : null, [Validators.email]],
      gender: [this.data.gender ? this.data.gender : null],
      mobileNo: [this.data.mobileNo ? this.data.mobileNo : null || this.data.phoneNumber ? this.data.phoneNumber : null],
      employeeTagSerialNumber: [null, [Validators.required]],
      visitorTagSerialNumber: [null],
      birthDate: [this.data.birthDate ? this.data.birthDate : null],
      joinDate: [this.data.joinDate ? this.data.joinDate : null],
      managerId: [this.data.managerId ? this.data.managerId : null],
      quarantineSDate: [this.data.quarantineStartDate ? this.data.quarantineStartDate : null],
      quarantineEDate: [this.data.quarantineEndDate ? this.data.quarantineEndDate : null],
      comments: [this.data.comments ? this.data.comments : null],
      visitorTypeId: [this.data.visitorTypeId ? this.data.visitorTypeId : 'VI-VI'],
      countryCode: [this.data.countryCode ? this.data.countryCode : '+'],
      department: [null]
    });
  }
  onWindowResized(size) {
    this.height = size;
  }
  searchTempId(id, type, event) {
    if (id !== '') {
      if (type === 'TT-ID' && (event.keyCode <= 90 && event.keyCode >= 48 || event.keyCode == 8 || event.keyCode == 32)) {
        this.commonServices.searchByMainidentifer(id).subscribe(res => {
          if (res.results.length == 0) {
            this.emptyList = true;
          }
          this.tempEmpIdList = res.results;
        });
      } else if (this.autoTrigger.panelOpen && (event.keyCode == 38 || event.keyCode == 40)) {
        this.enrollEmployeeForm.get('mainidentifier').setValue(this.autoTrigger.activeOption.value);
      } else {

        const tempEmpIdList = this.tempEmpIdList;
        this.tempEmpIdList = tempEmpIdList;
      }
    } else {
      this.tempEmpIdList = [];
      this.data = [];
      this.data.eType = 'tempId';
      this.buildForm();
    }
  }

  checkEmpId(data) {
    if (data.hasOwnProperty('mainidentifier')) {
      this.data = data;
    } else {
      let filter = this.tempEmpIdList.filter(res => res.mainidentifier === data.toString());
      this.data = filter[0];
    }
    this.data.eType = 'tempId';
    this.workflowTypeId = 'WF-EM';
    this.emptyList = false;
    this.buildForm();
  }

  searchTagAssociation(event, type) {
    let tagId = event.text;  
    if (tagId !== '') {
      if(type === 'TT-IDCO' && event.type === 'idCard' && event.text.length >= 2) {
        if (event.toHit == true) {      
          this.commonServices.getAllTagByType(tagId, this.workflowTypeId, 'ST-AT').subscribe(res => {
            this.employeeTagListItems = res.results;
            this.employeeTagList = this.employeeTagListItems;
          });
        } else {
          this.employeeTagList = this.employeeTagListItems;
        }
      } else {
        this.employeeTagList = [];
      }
      if(type === 'TT-VTBLE' && event.type === 'vitalTag' && event.text.length >= 2) {
        if (event.toHit == true) {
          this.commonServices.searchTagAssociate(tagId, type, 'ST-AT').subscribe(res => {
            this.vitalTagListItems = res.results;
            this.vitalTagList = this.vitalTagListItems;
          });
        } else {
          this.vitalTagList = this.vitalTagListItems;
        }
      } else {
        this.vitalTagList = [];
      }
    } else {
      this.employeeTagList = [];
      this.vitalTagList = [];
    }
  }

  disAssociateTag(tagId) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      panelClass:['confirmation-popup'], disableClose: true,
      data: {
        title: 'Disassociate Device', message: 'Do you want to disassociate the device?',
        buttonText: { ok: 'Yes', cancel: 'No' },
        'tagId': tagId, 'isRemark': 1, 'disAssociateCoaster': true
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res === 'confirm') {
        this.thisDialogRef.close('confirm');
        this.visitorCheckout = this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
        this.updateRegisterVisitor(this.data);
      }
    });
  }

  saveRegisterEmployee() {
    this.createEnrollEmployee = new CreateEnrollEmployee(null, null, null, null, null, null, null, null, null, null, null, null);
    this.createEnrollEmployee.firstName = this.enrollEmployeeForm.controls['firstName'].value;
    this.createEnrollEmployee.mainidentifier = this.enrollEmployeeForm.controls['mainidentifier'].value;
    this.createEnrollEmployee.mobileNo = this.enrollEmployeeForm.controls['mobileNo'].value;
    this.createEnrollEmployee.email = this.enrollEmployeeForm.controls['email'].value;
    this.createEnrollEmployee.gender = this.enrollEmployeeForm.controls['gender'].value;
    this.createEnrollEmployee.birthDate = this._dateFormat.transform(this.enrollEmployeeForm.controls['birthDate'].value, 'yyyy-MM-dd');
    this.createEnrollEmployee.managerId = this.enrollEmployeeForm.controls['managerId'].value;
    this.createEnrollEmployee.countryCode = this.enrollEmployeeForm.controls['countryCode'].value;
    this.createEnrollEmployee.joinDate = this._dateFormat.transform(this.enrollEmployeeForm.controls['joinDate'].value, 'yyyy-MM-dd');

    this.createEnrollEmployee.quarantineStartDate = this._dateFormat.transform(this.enrollEmployeeForm.controls['quarantineSDate'].value, 'yyyy-MM-dd');
    this.createEnrollEmployee.quarantineEndDate = this._dateFormat.transform(this.enrollEmployeeForm.controls['quarantineEDate'].value, 'yyyy-MM-dd');
    let tagIds = [];
    if (this.employeeTagId === null && this.enrollEmployeeForm.controls['employeeTagSerialNumber'].value != null &&
      this.enrollEmployeeForm.controls['employeeTagSerialNumber'].value !== '') {
      if (tagIds.length > 0) {
        tagIds.push(this.enrollEmployeeForm.controls['employeeTagSerialNumber'].value);
      } else {
        tagIds = [this.enrollEmployeeForm.controls['employeeTagSerialNumber'].value];
      }
    }
    if (this.vitalTagId === null && this.enrollEmployeeForm.controls['visitorTagSerialNumber'].value != null &&
      this.enrollEmployeeForm.controls['visitorTagSerialNumber'].value !== '') {
      if (tagIds.length > 0) {
        tagIds.push(this.enrollEmployeeForm.controls['visitorTagSerialNumber'].value);
      } else {
        tagIds = [this.enrollEmployeeForm.controls['visitorTagSerialNumber'].value];
      }
    }
    this.createEnrollEmployee.tagId = tagIds;
    this.commonServices.createRegisterEmployee(this.createEnrollEmployee).subscribe(res => {
      this.toastr.success('Success', `${res.message}`);
      this.thisDialogRef.close('confirm');
    }, error => {
      this.toastr.error('Error', `${error.error.message}`);
    });
  }

  updateRegisterEmployee(data) {
    this.editEnrollEmployee = new EditEnrollEmployee(null, null, null, null, null, null, null, null, null, null, null, null, null);
    this.editEnrollEmployee.firstName = this.enrollEmployeeForm.controls['firstName'].value;
    this.editEnrollEmployee.mainidentifier = this.enrollEmployeeForm.controls['mainidentifier'].value;
    this.editEnrollEmployee.mobileNo = this.enrollEmployeeForm.controls['mobileNo'].value;
    this.editEnrollEmployee.email = this.enrollEmployeeForm.controls['email'].value;
    this.editEnrollEmployee.gender = this.enrollEmployeeForm.controls['gender'].value;
    this.editEnrollEmployee.managerId = this.enrollEmployeeForm.controls['managerId'].value;
    this.editEnrollEmployee.countryCode = this.enrollEmployeeForm.controls['countryCode'].value;
    this.editEnrollEmployee.birthDate = this._dateFormat.transform(this.enrollEmployeeForm.controls['birthDate'].value, 'yyyy-MM-dd');
    this.editEnrollEmployee.joinDate = this._dateFormat.transform(this.enrollEmployeeForm.controls['joinDate'].value, 'yyyy-MM-dd');

    this.editEnrollEmployee.quarantineStartDate = this._dateFormat.transform(this.enrollEmployeeForm.controls['quarantineSDate'].value, 'yyyy-MM-dd');
    this.editEnrollEmployee.quarantineEndDate = this._dateFormat.transform(this.enrollEmployeeForm.controls['quarantineEDate'].value, 'yyyy-MM-dd');
    this.editEnrollEmployee.id = this.data.id;
    let tagIds = [];

    if (this.employeeTagId === null && this.enrollEmployeeForm.controls['employeeTagSerialNumber'].value != null &&
      this.enrollEmployeeForm.controls['employeeTagSerialNumber'].value !== '') {
      if (tagIds.length > 0) {
        tagIds.push(this.enrollEmployeeForm.controls['employeeTagSerialNumber'].value);
      } else {
        tagIds = [this.enrollEmployeeForm.controls['employeeTagSerialNumber'].value];
      }
    }
    if (this.vitalTagId === null && this.enrollEmployeeForm.controls['visitorTagSerialNumber'].value != null &&
      this.enrollEmployeeForm.controls['visitorTagSerialNumber'].value !== '') {
      if (tagIds.length > 0) {
        tagIds.push(this.enrollEmployeeForm.controls['visitorTagSerialNumber'].value);
      } else {
        tagIds = [this.enrollEmployeeForm.controls['visitorTagSerialNumber'].value];
      }
    }
    if (this.employeeTagId === null || this.vitalTagId === null) {
      this.editEnrollEmployee.tagId = tagIds;
    }
    this.commonServices.updateRegisterEmployee(this.editEnrollEmployee).subscribe(res => {
      this.toastr.success('Success', `${res.message}`);
      this.thisDialogRef.close('confirm');
    }, error => {
      console.log('403 module level error....', error);
      this.toastr.error('Error', `${error.error.message}`);
    });
  }

  saveRegisterVisitor() {
    this.createEnrollVisitor = new CreateEnrollVisitor(null, null, null, null, null, null, null, null, null, null);
    this.createEnrollVisitor.firstName = this.enrollEmployeeForm.controls['firstName'].value;
    this.createEnrollVisitor.hostEmployeeId = this.enrollEmployeeForm.controls['hostEmployeeId'].value;
    this.createEnrollVisitor.phoneNumber = this.enrollEmployeeForm.controls['mobileNo'].value;
    this.createEnrollVisitor.email = this.enrollEmployeeForm.controls['email'].value;
    this.createEnrollVisitor.gender = this.enrollEmployeeForm.controls['gender'].value;
    this.createEnrollVisitor.tagId = this.enrollEmployeeForm.controls['employeeTagSerialNumber'].value;
    this.createEnrollVisitor.comments = this.enrollEmployeeForm.controls['comments'].value;
    this.createEnrollVisitor.visitorTypeId = this.enrollEmployeeForm.controls['visitorTypeId'].value;
    this.createEnrollVisitor.countryCode = this.enrollEmployeeForm.controls['countryCode'].value;
    this.createEnrollVisitor.facilityId = this.facilityId;


    this.commonServices.createRegisterVisitor(this.createEnrollVisitor).subscribe(res => {
      this.toastr.success('Success', `${res.message}`);
      this.thisDialogRef.close('confirm');
    }, error => {
      this.toastr.error('Error', `${error.error.message}`);
    });

  }

  updateRegisterVisitor(data) {
    this.editEnrollVisitor = new EditEnrollVisitor(null, null, null, null, null, null, null, null, null, null, null);
    this.editEnrollVisitor.firstName = this.enrollEmployeeForm.controls['firstName'].value;
    this.editEnrollVisitor.hostEmployeeId = this.enrollEmployeeForm.controls['hostEmployeeId'].value;
    this.editEnrollVisitor.phoneNumber = this.enrollEmployeeForm.controls['mobileNo'].value;
    this.editEnrollVisitor.email = this.enrollEmployeeForm.controls['email'].value;
    this.editEnrollVisitor.gender = this.enrollEmployeeForm.controls['gender'].value;
    if (this.data.tagId === null) {
      this.editEnrollVisitor.tagId = this.enrollEmployeeForm.controls['employeeTagSerialNumber'].value;
    }
    this.editEnrollVisitor.comments = this.enrollEmployeeForm.controls['comments'].value;
    this.editEnrollVisitor.checkoutTime = this.visitorCheckout;
    this.editEnrollVisitor.visitorTypeId = this.enrollEmployeeForm.controls['visitorTypeId'].value;
    this.editEnrollVisitor.countryCode = this.enrollEmployeeForm.controls['countryCode'].value;
    this.editEnrollVisitor.id = this.data.id;

    this.commonServices.updateRegisterVisitor(this.editEnrollVisitor).subscribe(res => {
      if (this.visitorCheckout == null) {
        this.toastr.success('Success', `${res.message}`);
      }
      this.thisDialogRef.close('confirm');
    }, error => {
      this.toastr.error('Error', `${error.error.message}`);
    });
  }

  saveRegisterTempId() {
    this.createEnrollTempId = new CreateEnrollTempId(null, null, null, null, null, null, null, null, null);
    this.createEnrollTempId.isTemporaryTagId = true;
    this.createEnrollTempId.firstName = this.enrollEmployeeForm.controls['firstName'].value;
    this.createEnrollTempId.mainidentifier = this.enrollEmployeeForm.controls['mainidentifier'].value;
    this.createEnrollTempId.mobileNo = this.enrollEmployeeForm.controls['mobileNo'].value;
    this.createEnrollTempId.email = this.enrollEmployeeForm.controls['email'].value;
    this.createEnrollTempId.gender = this.enrollEmployeeForm.controls['gender'].value;
    this.createEnrollTempId.managerId = this.enrollEmployeeForm.controls['managerId'].value;
    this.createEnrollTempId.countryCode = this.enrollEmployeeForm.controls['countryCode'].value;
    let tagIds = [];
    if (this.employeeTagId === null && this.enrollEmployeeForm.controls['employeeTagSerialNumber'].value != null) {
      if (tagIds.length > 0) {
        tagIds.push(this.enrollEmployeeForm.controls['employeeTagSerialNumber'].value);
      } else {
        tagIds = [this.enrollEmployeeForm.controls['employeeTagSerialNumber'].value];
      }
    }
    this.createEnrollTempId.tagId = tagIds;

    this.commonServices.createRegisterEmployee(this.createEnrollTempId).subscribe(res => {
      this.toastr.success('Success', `${res.message}`);
      this.thisDialogRef.close('confirm');
    }, error => {
      this.toastr.error('Error', `${error.error.message}`);
    });
  }

  updateRegisterTempId(data) {
    this.editEnrollTempId = new EditEnrollTempId(null, null, null, null, null, null, null, null, null, null);
    this.editEnrollTempId.isTemporaryTagId = true;
    this.editEnrollTempId.firstName = this.enrollEmployeeForm.controls['firstName'].value;
    this.editEnrollTempId.mainidentifier = this.enrollEmployeeForm.controls['mainidentifier'].value;
    this.editEnrollTempId.mobileNo = this.enrollEmployeeForm.controls['mobileNo'].value;
    this.editEnrollTempId.email = this.enrollEmployeeForm.controls['email'].value;
    this.editEnrollTempId.gender = this.enrollEmployeeForm.controls['gender'].value;
    this.editEnrollTempId.managerId = this.enrollEmployeeForm.controls['managerId'].value;
    this.editEnrollTempId.countryCode = this.enrollEmployeeForm.controls['countryCode'].value;
    this.editEnrollTempId.id = this.data.id;
    let tagIds = [];
    if (this.employeeTagId === null && this.enrollEmployeeForm.controls['employeeTagSerialNumber'].value != null) {
      if (tagIds.length > 0) {
        tagIds.push(this.enrollEmployeeForm.controls['employeeTagSerialNumber'].value);
      } else {
        tagIds = [this.enrollEmployeeForm.controls['employeeTagSerialNumber'].value];
      }
    }
    if (this.employeeTagId === null) {
      this.editEnrollTempId.tagId = tagIds;
    }

    this.commonServices.updateRegisterEmployee(this.editEnrollTempId).subscribe(res => {
      this.toastr.success('Success', `${res.message}`);
      this.thisDialogRef.close('confirm');
    }, error => {
      this.toastr.error('Error', `${error.error.message}`);
    });
  }
}


function validateDate() {
  throw new Error('Function not implemented.');
}

