/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../../../services';
@Component({
  selector: 'app-widget-header',
  templateUrl: './widget-header.component.html',
  styleUrls: ['./widget-header.component.scss']
})
export class WidgetHeaderComponent {
    @Input() showMinimize: boolean;
    @Input() showMaximize: boolean;
    @Input() title: string;
    @Input() name: string;
    @Output() childToParentAction = new EventEmitter<string>();
    @Output() removeWidgets = new EventEmitter<string>();

    constructor(private commonService: CommonService) { }
    ngOnIinit() {
    }

    ngDoCheck() {
        if (this.commonService.floorMap === true) {
            this.sendToParentAction('Minimize');
            this.commonService.floorMap = false;
        }
    }

    sendToParentAction(action) {
        if (action === 'Maximize') {
            this.childToParentAction.emit('Maximize');
        } else {
            this.childToParentAction.emit('Minimize');
        }
    }
    // Temporarily  D I S A B L E D
    removeWidget(name) {
         this.removeWidgets.emit(name);
    }
}
