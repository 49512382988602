/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
 ******************************************************************************/
import { DatePipe } from '@angular/common';
import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges, ViewChild, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '../../../services/common.service';
import { WorkflowService } from '../../../services/workflow.service';
import { RouterStateSnapshot } from '@angular/router';
import { ConfigurationService, ReportService } from '../../../services';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tw-layout-header-component',
  templateUrl: './tw-layout-header.component.html',
  styleUrls: ['./tw-layout-header.component.scss'],
})

export class TwLayoutHeaderComponent implements OnChanges, OnInit {
  public activate_btn: any = [];
  public enableAction = false;
  public reportForm: UntypedFormGroup;
  public staffSub : Subject<any> = new Subject();
  public today = new Date();
  public fromDate = this.datepipe.transform(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'yyyy-MM-dd');
  public fromDateTime = this.datepipe.transform(this.today, 'yyyy-MM-dd 00:00');
  public toDateTime = this.datepipe.transform(this.today, 'yyyy-MM-dd 23:59');
  @Input() layout: any;
  @Input() filterInputs: any=[];
  @Input() reportList: any;
  @Input() enableExcel: any;
  @Input() enablePdf: any;
  @Input() enableRefresh: any;
  @Output() reportHeaderAction = new EventEmitter<any>();
  menuArray = [];
  selectedMenu = null;
  disabled = true;
  poolNameList: any=[];
  locationlist: any=[];
  userList: any=[];
  params = null;
  paramJson = {}
  workflowTypeId = null;
  searchCosterlist: any=[];
  searchAssetList: any=[];
  assetCategory = null;
  searchPatientlist: any=[];
  uhidList: any=[];
  eventEmitted = false;
  type: any;
  RoleList: any=[];
  activityCategoryList = null;
  @ViewChild('allCatSelected') private allCatSelected: MatOption;
  @ViewChild('allTaskSelected') private allTaskSelected: MatOption;
  taskActivitiesList: any=[];
  enableTask = false;

  constructor(private workflowService: WorkflowService,
    public dialog: MatDialog,
    public fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    public commonService: CommonService,
    public configurationService: ConfigurationService,
    public reportService: ReportService,
    public toastr: ToastrService) {

    this.activate_btn = this.commonService.getActivePermission('button');
    let permissions = JSON.parse(localStorage.getItem('permission'));
    const menuName = window.location.pathname;
    let menuPath = menuName.replace('en/', '').replace('ar/', '');
    if(permissions && permissions.length !== 0) {
      let menuItemsList = permissions['menuItems'];
      menuItemsList.forEach(menu => {
        if(menu.link === menuPath) {
          this.selectedMenu = menu;
        } else {
          if(menu.hasOwnProperty('subMenus') && menu.subMenus.length !== 0) {
            menu.subMenus.forEach(subMenu1 => {
              if(subMenu1.link === menuPath) {
                this.selectedMenu = subMenu1;
              } else {
                if(subMenu1.hasOwnProperty('subMenus') && subMenu1.subMenus.length !== 0) {
                  subMenu1.subMenus.forEach(subMenu2 => {
                      if(subMenu2.link === menuPath) {
                        this.selectedMenu = subMenu2;
                      }
                  });
                }
              }
            });
          }
        }
      });
    }
    this.commonService.getAppTerms('PoolName').subscribe(res=>this.poolNameList = res.results);
    this.commonService.getRollList().subscribe(res=> this.RoleList = res.results );
    this.commonService.getAppTermsLink('ROU-TSK').subscribe(res => this.activityCategoryList = res.results);
  }
  ngOnInit(): void {
    this.staffSub.pipe(debounceTime(600)).subscribe(searchTextValue => {
      this.getUserByType(searchTextValue);
    });    
  }
  ngOnChanges(changes: SimpleChanges) {
    if(this.filterInputs) {
      this.filterInputs = this.commonService.sortByKey(this.filterInputs, 'seq');
      if(this.filterInputs?.length !== 0) {
        this.filterInputs?.forEach(data => {
          if(data.default !== undefined) {
            this[data.name] = data.default;
            if(data.reportName) {
              const list = this.reportList.filter(x => x.name === data.reportName && x.link === data.default);
              this[data.name] = list[0]?.link;
            }
            if(data.name === 'groupBy') {
              this.type = data.default;
            }
          }
          if(data.workflowTypeId !== undefined) {
            this.workflowTypeId = data.workflowTypeId
          }
          if(data.assetCategory !== undefined) {
            this.assetCategory = data.assetCategory
          }
        })
      }
    }
    this.buildForm();
  }

  buildForm() {
    this.reportForm = this.fb.group({
      reportId: [this['report']],
      fromDate: [this['fromDate'] ? this['fromDate'] : this.fromDate],
      toDate: [this['toDate'] ? this['toDate'] : this.today],
      fromDateTime: [this['fromDateTime'] ? this['fromDateTime'] : this.fromDateTime],
      toDateTime: [this['toDateTime'] ? this['toDateTime'] : this.toDateTime],
      reportDate: [this['reportDate'] ? this['reportDate'] : this.today],
      poolName: [this['poolName'] ? this['poolName'] : null],
      poolNames: [this['poolNames'] ? this['poolNames'] : null],
      location: [this['location'] ? this['location'] : null],
      role: [this['role'] ? this['role'] : null],
      user: [this['user'] ? this['user'] : null],
      tagSerialNumber: [this['tagSerialNumber'] ? this['tagSerialNumber'] : null],
      asset: [this['asset'] ? this['asset'] : null],
      patient: [this['patient'] ? this['patient'] : null],
      uhid: [this['mainidentifier'] ? this['mainidentifier'] : null],
      activity: [this['activity'] ? this['activity'] : null],
      task: [this['task'] ? this['task'] : null]
    });
  }

  reportHeaderActionEvent(key, data) {
    if(this.layout === 'dynamic') {
      let value = null;
      if(this.filterInputs.length !== 0) {
        if(key !== 'getInsights' && key !== 'refresh' && key !== 'excel' && key !== 'pdf') {
          this.eventEmitted = false;
        }
        if(key !== 'getInsights' && !this.eventEmitted) {
          this.disabled = false;
          this.params = null;
          this.filterInputs.forEach(filter => {
            if(filter.name !== 'getInsights') {
              if(this.reportForm.get(filter.name)?.value !== null) {
                if(filter.name === 'fromDate' || filter.name === 'toDate' || filter.name === 'reportDate') {
                  value = this.datepipe.transform(this.reportForm.get(filter.name)?.value, 'yyyy-MM-dd');
                  this[filter.name] = value;
                } else if(filter.name === 'fromDateTime' || filter.name === 'toDateTime') {
                  value = this.datepipe.transform(this.reportForm.get(filter.name)?.value, 'yyyy-MM-dd  HH:mm');
                  this[filter.name] = value;
                } else if(filter.name === 'location' || filter.name === 'user' || filter.name === 'asset' ||
                  filter.name === 'patient' || filter.name === 'tagSerialNumber' || filter.name === 'uhid') {
                  if(data !== null && key === filter.name) {                    
                    if(filter.name === 'location') {
                      value = data?.id;
                      this[filter.name + 'ID'] = data?.id;
                      this['location'] = data?.name + ',' + data?.fullName;
                    } else if(filter.name === 'patient') {
                      value = data?.id;
                      this[filter.name + 'ID'] = data?.id;
                      this['patient'] = data?.firstName;
                    } else if(filter.name === 'tagSerialNumber') {
                      value = data?.tagId;
                      this[filter.name + 'ID'] = data?.tagId;
                      this['tagSerialNumber'] = data?.tagId;
                    }  else if(filter.name === 'uhid') {
                      value = data;
                      this[filter.name + 'ID'] = data;
                      this['mainidentifier'] = data;
                    } else {
                      value = data?.id;
                      this[filter.name + 'ID'] = data?.id;
                      this[filter.name] = data?.name;
                    }
                  } else if(data !== null && this.reportForm.get(filter.name)?.value !== null) {
                    value = this[filter.name + 'ID'];
                  } else {
                    value = null;
                    this[filter.name + 'ID'] = null;
                    this.reportForm.get(filter.name).setValue(null);
                    this[filter.name] = null;
                  }
                } else if(filter.name === 'poolName' || filter.name === 'poolNames'){
                  if(data !== null && key === filter.name) {
                    value = data;
                    this[filter.name + 'ID'] = value;
                    this[filter.name] = value;
                  } else if(data !== null && this.reportForm.get(filter.name)?.value !== null) {
                    value = this[filter.name + 'ID'];
                  } else {
                    value = null;
                    this[filter.name + 'ID'] = null;
                    this.reportForm.get(filter.name).setValue(null);
                    this[filter.name] = null;
                  }
                }
                this.paramJson[filter.id] = value
                if(this.params === null && value !== null) {
                  this.params = '?' + filter.id + '=' + value;
                } else if(value !== null) {
                  this.params = this.params + '&' + filter.id + '=' + value;
                }
              }
            }
          });
        }
        if(key === 'getInsights' || key === 'refresh' || key === 'excel' || key === 'pdf') {
          this.eventEmitted = true;
          data = this.params;
          let paramJson = this.paramJson
          this.reportHeaderAction.emit({ key, data, paramJson});
          this.buildForm();
          this.disabled = true;
        }
      }
    } else {
      this.reportHeaderAction.emit({ key, data});
      this.disabled = true;
    }
  }

  reportActionEvent(type, value) {
    if(type === 'activity') {
      if(value){
        if (this.allCatSelected.selected){
          this.reportForm.controls['activity']
                .patchValue([...this.activityCategoryList.map(item => item.code), 0]);
                this.allCatSelected.select();
        } else{
          this.reportForm.controls['activity'].patchValue([]);
            this.allCatSelected.deselect();
        }
      }
    } else if(type === 'task') {
      if(value){
        if (this.allTaskSelected.selected){
          this.reportForm.controls['task']
            .patchValue([...this.taskActivitiesList.map(item => item.id), 0]);
            this.allTaskSelected.select();
        } else{
          this.reportForm.controls['task'].patchValue([]);
          this.allTaskSelected.deselect();
        }
      }
    }
  }

  openedChange(isOpended, type) {
    if(!isOpended)
    {
      let value;
      if(type === 'poolNames') {
        value = this.reportForm.get('poolNames').value;
      } else if(type === 'activity') {
        value = this.reportForm.get('activity').value;
        if(value !== null){
          this.getTaskActivity();
        }
      } else if(type === 'task') {
        value = this.reportForm.get('task').value;
      }
      this.reportHeaderActionEvent(type, value)
    }
  }

  getLocationlist(id) {
    let searchList = '';
    searchList += id.target.value;
    if (searchList.length >= 2) {
      this.configurationService.getLocationData(id.target.value).subscribe(res => {
      if(res.results.length == 0){
        }
        this.locationlist = res.results;
      });
    } else {
      this.locationlist = [];
    }
  }

  getUserByTypeCheck(val) {
    this.staffSub.next(val);
  }
  getUserByType(val){
    let searchList = '';
    searchList += val.target.value;
    if (searchList.length >= 2) {
    this.commonService.searchDoctor(val.target.value, 'UT_PORTER','RO-PO').subscribe(res => {
      if(res.results.length == 0){
        }
        this.userList = res.results;
      });
    } else {
      this.userList = [];
    }
  }

  searchToCoster(event) {
    if(event.target.value?.length >= 2) {
      this.commonService.getAllTagByType(event.target.value, this.workflowTypeId, 'ST-AT').subscribe(res => {
        this.searchCosterlist = res.results;
      });
    } else {
      this.searchCosterlist = [];
    }
  }

  searchAsset(event) {
    if (event.target.value?.length >= 2) {
      this.commonService.searchAssetByCategory(this.assetCategory, event.target.value).subscribe((res) => {
          this.searchAssetList = res.results;
      });
    } else {
      this.searchAssetList = [];
    }
  }

  searchPatient(event){
    if (event.target.value !== "") {
      let val = event.target.value;
      if (val.length >= 2) {
        this.commonService.searchPatient(event.target.value).subscribe((res) => {
          this.searchPatientlist = res.results.filter(res => res.mainidentifier != null);
        });
      } else {
        this.searchPatientlist = [];
      }
    } else {
      this.searchPatientlist = [];
    }
  }

  clearUHID(event) {
    if (event.type === 'uhid' && event.target.value === '') {
      this.reportForm.get('uhid').setValue(null);
    }
  }

  getUHID(value) {
    if(value.length >= 2) {
      const uhid = value;
      this.commonService.getUHID(uhid).subscribe(res => {
        if (res.statusCode === 1) {
          this.uhidList = [res.results.mainidentifier];
        }
      },
      error => {
        this.toastr.error('Error', `${error.error.message}`);
        this.uhidList = [];
        this.reportForm.get('uhid').setValue(null);
      });
    }
  }

  getTaskActivity() {
    if(this.allCatSelected.selected){
        this.reportService.getTaskByCategory('ROU-TSK').subscribe(res => {
            this.taskActivitiesList = res.results;
            this.enableTask = true;
        });
    } else if(this.reportForm.controls['activity']?.value?.length>0){
        this.reportService.getTaskByCategory('ROU-TSK', this.reportForm.controls['activity']?.value).subscribe(res => {
            this.taskActivitiesList = res.results;
            this.enableTask = true;
        });
    }
  }

}