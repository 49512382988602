/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTreeModule } from '@angular/material/tree';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../common/material.module';
import { NgxMaterialTimepickerModule } from '../material-timepicker/ngx-material-timepicker.module';
import { ManageWidgetComponent } from './manage-widget/manage-widget.component';
import { ManageLayoutComponent } from './manage-layout/manage-layout.component';
import { CommonLayoutComponent } from './common-layout/common-layout.component';
import { GridsterLayoutComponent } from './girdster-layout/gridster-layout.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { editFormComponent, formComponent } from './form/form.component';
import { PatientComponent, PatientListComponent, PatientInfoComponent } from './patient/patient.component';
import { ManageControlComponent } from './manage-control/manage-control.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { CreateTicketComponent } from './support-ticket/support-ticket.component';
import { AlertEntryComponent } from './alert-entry/alert-entry.component';
import { CommonMapComponent } from './common-map/common-map.component';
import { CommonSearchComponent } from './common-search/common-search.component';
import { ConfirmationDialog } from './confirmation-dialog/confirmation-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { SharedDirectivesModule } from '../../directive/shared-directives.module';
import { EnrollPatientComponent, EnrollRegisterPatientComponent, EnrollRegisterEmployeeComponent } from './enroll-patient/enroll-patient.component';
import { TableComponent } from '../entry-component/table/table.component';
import { AngularTableComponent } from '../entry-component/table-angular/angulartable.component';
import { CdkDetailRowDirective } from './table/cdk-detail-row.directive';
import { DashboardWidgetComponent } from './dashboard-widget/dashboard-widget.component';
import { GlobalSearchComponent } from './globalsearch/globalsearch.component';
import { CommonDialogComponent } from './common-dialog-component/common-dialog.component';
import { LayoutSaveComponent, ConfirmDialogComponent } from './layout-save/layout-save.component';
import { NoSpaceDirective } from './space-directive/no-space.directive';
import { WorkflowManagementComponent } from './workflow-management/workflow-management.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import { WidgetHeaderComponent} from './widget-header/widget-header.component';
import { MatBadgeModule } from '@angular/material/badge';
import { EntityTicketComponent } from './entity-ticket/entity-ticket.component';
import { AcknowledgementComponent } from './acknowledgement/acknowledgement.component';
import { TwLayoutHeaderComponent } from './tw-layout-header/tw-layout-header.component';
import { CommonMultiselectComponent } from './common-multiselect/common-multiselect.component';
import { LightboxOnlineMenuComponent, StatusTrackingComponent } from './status-tracking/status-tracking.component';
import { GoogleMapComponent, GoogleMapDirectionComponent, GoogleMapMarkerComponent } from './google-map/google-map.component';
import { NgChartsModule } from 'ng2-charts';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { GridsterModule } from 'angular-gridster2';
import { ToastrModule } from 'ngx-toastr';
import { ImportSettingComponent } from '../../../ovitag/hospital/import-setting/import-setting.component';



 
// import { ManageLocationComponent} from './manage-location/manage-location.component';


@NgModule({
    imports: [
        NgChartsModule,
        MatCardModule,
        MatSelectModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        MatTreeModule,
        MatToolbarModule,
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        // InfiniteScrollModule,
        GridsterModule,
        MatBadgeModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-right', 
          }),
        NgxMaterialTimepickerModule.forRoot(),
        SharedPipesModule.forRoot(),
        SharedDirectivesModule.forRoot(),
        CalendarModule.forRoot({
            provide : DateAdapter,
            useFactory : adapterFactory
        }),
        // AgmDirectionModule,
        // AgmCoreModule.forRoot({
        //     apiKey: localStorage.getItem(('API_KEY'))
        // }),
        
        

    ],
    exports: [
        CalendarComponent,
        PrivacyComponent,
        TermsComponent,
        PatientListComponent,
        CreateTicketComponent,
        ManageWidgetComponent,
        ManageLayoutComponent,
        CommonLayoutComponent,
        GridsterLayoutComponent,
        formComponent,
        DynamicFormComponent,
        PatientComponent,
        PatientInfoComponent,
        ManageControlComponent,
        AlertEntryComponent,
        CommonMapComponent,
        CommonSearchComponent,
        ConfirmationDialog,
        EnrollPatientComponent,
        EnrollRegisterPatientComponent,
        TableComponent,
        AngularTableComponent,
        // ManageLocationComponent,
        DashboardWidgetComponent,
        GlobalSearchComponent,
        CommonDialogComponent,
        CommonMultiselectComponent,
        EnrollRegisterEmployeeComponent,
        WorkflowManagementComponent,
        CreateUserComponent,
        WidgetHeaderComponent,
        editFormComponent,
        EntityTicketComponent,
        AcknowledgementComponent,
        TwLayoutHeaderComponent,
        StatusTrackingComponent,
        GoogleMapComponent,
        GoogleMapDirectionComponent,
        GoogleMapMarkerComponent,
    ],
    declarations: [
        CalendarComponent,
        PrivacyComponent,
        TermsComponent,
        CreateTicketComponent,
        ManageWidgetComponent,
        ManageLayoutComponent,
        CommonLayoutComponent,
        formComponent,
        GridsterLayoutComponent,
        DynamicFormComponent,
        ManageControlComponent,
        AlertEntryComponent,
        PatientComponent,
        PatientInfoComponent,
        PatientListComponent,
        CommonMapComponent,
        CommonSearchComponent,
        ConfirmationDialog,
        EnrollPatientComponent,
        EnrollRegisterPatientComponent,
        TableComponent,
        AngularTableComponent,
        // ManageLocationComponent,
        DashboardWidgetComponent,
        GlobalSearchComponent,
        CommonDialogComponent,
        CommonMultiselectComponent,
        CdkDetailRowDirective,
        EnrollRegisterEmployeeComponent,
        LayoutSaveComponent,
        ConfirmDialogComponent,
        NoSpaceDirective,
        WorkflowManagementComponent,
        CreateUserComponent,
        WidgetHeaderComponent,
        editFormComponent,
        EntityTicketComponent,
        AcknowledgementComponent,
        TwLayoutHeaderComponent,
        CommonMultiselectComponent,
        StatusTrackingComponent,
        LightboxOnlineMenuComponent,
        GoogleMapComponent,
        GoogleMapDirectionComponent,
        GoogleMapMarkerComponent,
    ],
})
export class EntryComponentModule {
    static forRoot(): ModuleWithProviders<EntryComponentModule> {
        return {
            ngModule: EntryComponentModule,
            providers: []
        };
    }
}

