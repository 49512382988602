<div fxFlex class="input-component-holder">
    <mat-toolbar class="input-header">
        <div class="header-name" *ngIf="data">Create Ticket </div>
        <div class="header-close"><button mat-icon-button mat-dialog-close close-button>
                <mat-icon class="icon-style">highlight_off</mat-icon>
            </button></div>
    </mat-toolbar>

    <div class="input-container-tkt" [formGroup]="ticketForm">
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" class="form-row mrgn-styl-tkt">
            <div fxFlex.xs="100" fxFlex.lg="100" fxFlex.md="50" fxFlex.sm="50" class="row-field">
                <div class="example-form">
                    <mat-form-field class="example-full-width form-field" *ngIf="data != 'newuser'">
                        <mat-select placeholder="Select Customer" formControlName="customerId">
                            <mat-option *ngFor="let cust_list of customerList" [value]="cust_list.id">
                                {{cust_list.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="example-full-width form-field" *ngIf="data == 'newuser'">
                        <input matInput placeholder="Customer Name" formControlName="facilityName"
                            [errorStateMatcher]="matcher" autocomplete="off" required>
                        <mat-error *ngIf="ticketForm.controls.facilityName.invalid">
                            <span [hidden]="!ticketForm.controls['facilityName'].errors.required">Customer name
                                is
                                required.</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex.xs="100" fxFlex.lg="100" fxFlex.md="50" fxFlex.sm="50" class="row-field">
                <div class="example-form">
                    <mat-form-field class="example-full-width form-field">
                        <input matInput placeholder="Name" formControlName="name" autocomplete="off" required>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" class="form-row">
            <div fxFlex.xs="100" fxFlex.lg="100" fxFlex.md="50" fxFlex.sm="50" class="row-field">
                <div class="example-form">
                    <mat-form-field class="example-full-width form-field" *ngIf="data != 'newuser'">
                        <mat-select placeholder="Select location" formControlName="locationId">
                            <mat-option *ngFor="let block_list of blockList" [value]="block_list.id">
                                {{block_list.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="example-full-width form-field" *ngIf="data == 'newuser'">
                        <input matInput placeholder="Location Name" formControlName="locationName" autocomplete="off" required>
                        <mat-error *ngIf="ticketForm.controls.locationName.invalid">
                            <span [hidden]="!ticketForm.controls['locationName'].errors.required">Location name
                                is
                                required.</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50">
                <div class="example-form">
                    <mat-form-field class="example-full-width form-field">
                        <mat-select placeholder="Priority" formControlName="ticketPriorityId">
                            <mat-option *ngFor="let priorList of ticketPriorityList" [value]="priorList.code">
                                {{priorList.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" class="form-row">
            <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" class="row-field">
                <div class="example-form">
                    <mat-form-field class="example-full-width form-field">
                        <input matInput placeholder="Phone" formControlName="phone" autocomplete="off" required>
                        <mat-error *ngIf="ticketForm.controls.phone.invalid">
                            <span [hidden]="!ticketForm.controls['phone'].errors.required">
                                Phone number is required.
                            </span>
                            <span [hidden]="!ticketForm.controls['phone'].errors.pattern">
                                Invalid Phone number
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" class="row-field">
                <div class="example-form">
                    <mat-form-field class="example-full-width form-field">
                        <input matInput placeholder="Email" formControlName="email" [errorStateMatcher]="matcher"
                            autocomplete="off" required>
                        <mat-error *ngIf="ticketForm.controls.email.invalid">
                            <span [hidden]="!ticketForm.controls['email'].errors.required">
                                E-mail is required.
                            </span>
                            <span [hidden]="!ticketForm.controls['email'].errors.email">
                                Invalid E-mail
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="30px" class="form-row">
            <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" class="row-field">
                <div class="example-form">
                    <mat-form-field class="example-full-width form-field">
                        <input matInput placeholder="Subject" formControlName="title" autocomplete="off">
                    </mat-form-field>
                </div>
            </div>

            <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" class="row-field">
                <div class="example-form">
                    <mat-form-field class="example-full-width form-field">
                        <textarea matInput placeholder="Description" rows=5 formControlName="description"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" class="form-row">
            <div fxFlex.xs="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" class="row-field">
                <div class="example-form">


                </div>
            </div>
            <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" class="row-field">
                <div class="example-form">



                </div>
            </div>
        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" class="form-row">
            <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" class="row-field">
                <div class="example-form">
                    <input matInput placeholder="  " formControlName="ticketAttachmentId" [errorStateMatcher]="matcher"
                        maxlength="26" readonly>
                    <mat-label class="tkt-atchmnt">Attachments<mat-icon class="icon-colr">
                            attach_file</mat-icon><br>
                    </mat-label>

                    <div>
                        <label>
                            <input class="vsbly-shw" type="file" id="file3" name="file" (change)="handleFileSelect($event)" style="display:none" />
                            <a mat-raised-button class="upload-btn">
                                <mat-icon>file_upload</mat-icon>
                                Upload File
                            </a>
                        </label>
                        <span class="file-name"><br>{{fileName}}{{fileType}}</span>
                    </div>

                    <!-- <div>
                        <button type="button" class="nice-button cur-ptr">Choose file</button>
                        <input type="file" size="60" id="file3" name="file" class="upload-btn"
                            (change)="handleFileSelect($event);" />
                        <span class="file-name"><br>{{fileName}}{{fileType}}</span>
                    </div> -->


                    <!-- <input class="vsbly-shw" type="file" id="file3"  (change)="handleFileSelect($event);" /> -->
                </div>
            </div>
            <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" class="row-field">
                <div class="example-form header-close">


                    <div *ngFor="let imgObj of imageData;let index = index" class="mgn-cmn-styl">
                        <img [src]="imgObj.file " class="img-prew lgtbox-style" *ngIf="imgObj.mimeType.includes('image')">
                        <mat-icon (click)="cancelFile(index);" i18n-matTooltip matTooltip="Delete?" class="del-icn">delete
                        </mat-icon>

                        <object *ngIf="imgObj.mimeType=='application/pdf'" [data]="imgObj.file" type="application/pdf">
                            <div class="frame-styl"><iframe
                                    src="https://docs.google.com/viewer?&embedded=true"></iframe></div>
                        </object>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="input-footer">
        <button mat-button class="cancle-btn" mat-dialog-close>Cancel</button>
        <button mat-button *ngIf="data" class="active-btn margin-r-1" [disabled]="ticketForm.invalid || isDisabled"
            (click)="saveTicket()" cdkFocusInitial>Save</button>
    </div>
</div>