/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../services';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, public commonService: CommonService, public toastr: ToastrService) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let permissions = JSON.parse(localStorage.getItem('permission'));
        let urlLinks = JSON.parse(localStorage.getItem('urlLinks'));
        if(permissions && permissions.length !== 0) {
            let menuItemsList = permissions['menuItems'];
            let link = [];
            if(!urlLinks || urlLinks.length === 0){
                menuItemsList.forEach(menu => {
                    if(menu.link !== null) {
                        link.push(menu.link);
                    }
                    if(menu.hasOwnProperty('subMenus') && menu.subMenus.length !== 0) {
                        menu.subMenus.forEach(subMenu1 => {
                            if(subMenu1.link !== null) {
                                link.push(subMenu1.link);
                            }
                            if(subMenu1.hasOwnProperty('subMenus') && subMenu1.subMenus.length !== 0) {
                                subMenu1.subMenus.forEach(subMenu2 => {
                                    if(subMenu2.link !== null) {
                                        link.push(subMenu2.link);
                                    }
                                });
                            }
                        });
                    }
                    localStorage.setItem('urlLinks', JSON.stringify(link));
                });
            }
        }
        if (Object.keys(route.queryParams).length !== 0 && route.queryParams.hasOwnProperty('token')) {
            return true;
        } else

        if (localStorage.getItem(btoa('user_token')) && localStorage.length > 14) { // user_token
            // if( localStorage.getItem(btoa('session_time')) <= new Date().getTime().toString() &&
            // localStorage.getItem(btoa('session_time').toString()) === new Date().getTime().toString()) { // session_time
            // if(parseInt(localStorage.getItem(btoa('session_time'))) <= new Date().getTime()) { // session_time
            //     // console.log('Session Time >>>>', localStorage.getItem(btoa('session_time')).toString())

            //     let refresh_token = localStorage.getItem(btoa('refreshToken')); // refresh_token
            //     // this.commonService.getRefreshToken(refresh_token).subscribe(val=> {
            //     // })
            //     localStorage.clear()
            //     this.router.navigate(['/login']);
            //     return false;
            // }
            if (state.url === '/' || state.url === '/login') {
                this.router.navigate(['/ovitag']);
            }
            if(urlLinks && urlLinks.length !== 0) {
                const route = urlLinks.filter(x => x === state.url);
                // console.log(route, state.url, urlLinks)
                if(route.length !== 0) {
                    return true;
                } else {
                    const landingPage = urlLinks[0];
                    this.commonService.validateUserPreference('landingPage', landingPage);
                    localStorage.clear();
                    this.router.navigate(['/login']);            
                }
            }
            return true;
        } else {
            localStorage.clear();
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});

            if (state.url !== '/') {
                this.toastr.warning(
                    '<span style=\'font-family:Open Sans;font-size:16px;\'>' + environment.base_value.auth_card_error_msg + '</span>',
                    null, { timeOut: 3600, closeButton: true, enableHtml: true}
                );
            }
            return false;
        }
    }

}
