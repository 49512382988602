<div class="ovi-font-family" style="height: 95%;margin-block-start: 12px;margin-inline-start: 12px;margin-inline-end: 12px;">
    <div style="height: 40px;width: 100%;">
        <div style="display: contents;">
            <span style="font-size: 20px;font-weight: 700;"> Widget </span>
            <span style="font-size: 16px;font-weight: 600;"> - Dashboard</span>
            <span style="font-size: 16px;font-weight: 600;color: white;" *ngIf="selectedLayout.value"> {{dashboardData[selectedLayout.value]?.code}}</span>
        </div>
        <button mat-icon-button style="width: auto; float: right;" (click)="widgetClose()">
            <mat-icon style="line-height: 8px !important; font-size: 25px; margin-top: 5px;">highlight_off</mat-icon>
        </button>
    </div>
    <mat-divider></mat-divider>  
    <div id="widget" class="loading-spinner" *ngIf="spinLoader">
        <div class="spinnerload">
            <div class="spin1"></div>
            <div class="spin2"></div>
            <div class="spin3"></div>
        </div>
    </div>
    <div style="width: 100%;height: 10%;padding-right: 5px;" *ngIf="!spinLoader">
        <!-- <div style="height: 100%;width: 80px;float: right;margin-right: 10px;">
            <button [ngStyle] = "{ background : isFactoryLay.value && (activate_btn && (activate_btn.indexOf('BT_DBDEL') == -1)) ? '#c0c3c5' : '#ff4026'}" style="border-radius: 3px;border: 5px;margin-top: 17px;height: 35px;width: 80px;color: white;cursor: pointer;
            touch-action: none;user-select: none;" [disabled]="isFactoryLay.value && (activate_btn && (activate_btn.indexOf('BT_DBDEL') == -1))" (click)="deleteWidgets()" i18n-matTooltip matTooltip="Delete Layout">DELETE</button>
        </div>  -->
        <div style="height: 100%;width: 80px;float: right;margin-right: 10px;margin-top:10px">
            <button [ngStyle] = "{ background : (dashboardData[selectedLayout.value]?.isFactory && (activate_btn && (activate_btn.indexOf('BT_DBDEL') == -1))) || (dashboardData[selectedLayout.value]?.isFactory && !isFactoryLay.value) || (dashboardData[selectedLayout.value]?.isFactory && activate_btn.indexOf('BT_DBFAC') == -1) ? '#c0c3c5' : '#3A7AFE'}" style="border-radius: 3px;border: 5px;margin-top: 17px;height: 35px;width: 80px;color: white;cursor: pointer;
            touch-action: none;user-select: none;" [disabled]="(dashboardData[selectedLayout.value]?.isFactory && (activate_btn && (activate_btn.indexOf('BT_DBDEL') == -1))) || (dashboardData[selectedLayout.value]?.isFactory && !isFactoryLay.value) || (dashboardData[selectedLayout.value]?.isFactory && activate_btn.indexOf('BT_DBFAC') == -1)" (click)="deleteWidgets()" i18n-matTooltip matTooltip="Delete Layout">DELETE</button>
        </div>
        <div style="height: 100%;width: 80px;float: right;margin-right: 10px;margin-top:10px">
            <button [ngStyle] = "{ background : (enableSave || nameChange || isFactoryChange) ? '#2681f2' : '#c0c3c5'}" style="border-radius: 3px;border: 5px;margin-top: 17px;height: 35px;width: 80px;color: white;cursor: pointer;
            touch-action: none;user-select: none;" 
            [disabled]="(!enableSave && !nameChange && !isFactoryChange)" (click)="saveWidgets()" i18n-matTooltip matTooltip="Save Layout">SAVE</button>
        </div>
        <div style="float: right;width: 10%;padding-top: 30px;margin-right: 10px;">
            <mat-checkbox class="example-margin" [disabled]="activate_btn && (activate_btn.indexOf('BT_DBFAC') == -1)" [formControl]="isFactoryLay" (change)="changeFactory($event)">Factory</mat-checkbox>
        </div>
        <div class="custom-name" style="float: right;width: 15%;margin-right: 15px;">
            <mat-label i18n class="ovi-lbl-text-size">Layout Name</mat-label>
            <mat-form-field  class="ovi-lbl-text-size" style="width: 100%;float: right;" appearance="outline">
                <input matInput i18n-placeholder placeholder="Layout Name" [formControl]="layoutName"
                autocomplete="off" role="presentation" (keyup)="layNameChange($event.target.value)">
                <mat-hint>name should be unique</mat-hint>
            </mat-form-field>
        </div>
        <div class="custom-name" style="float: right;width: 15%;margin-right: 15px;">
            <mat-label i18n class="ovi-lbl-text-size">Select Layout</mat-label>
            <mat-form-field  class="ovi-lbl-text-size" style="width: 100%;float: right;" appearance="outline">
                <mat-select [formControl]="selectedLayout"  i18n-placeholder placeholder="Select Layout" (selectionChange)="changeLayout()">
                    <mat-option *ngFor = "let lay of userLayouts"  [value]="lay.id">
                        <span [ngClass] = "lay.userId ? '' : 'layout-type-css'">{{lay.userId ? '&nbsp;&nbsp;&nbsp;&nbsp;' : '&nbsp;F&nbsp;'}}</span>
                        {{ lay.dashboardName }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="dash-wid-search-input">
            <span style="width: 85%; float: left;"><input type="text" class="dash-wid-search-icon" placeholder="Search"
                [formControl]="filterKey" (keyup)="applyFilter(filterKey.value)"></span>
            <span style="width: 15%; float: right;">
                <button mat-icon-button id="search-icon" disabled style="width: 8%;">
                    <mat-icon style="line-height: 8px !important;font-size: 25px;color: black;vertical-align: bottom;">search</mat-icon>
                </button>
            </span>
        </div>
    </div>
    <div style="width: 100%;margin-top: 30px;height: 65%;" *ngIf="!spinLoader">
        <div style="width: 40%;height: 100%;background: #dcdcdc57;float: left;border-radius: 10px;box-shadow: 0px 0px 0px 1px grey;margin-left: 15px;">
                <div i18n class="ovi-popup-title-text-size" style="text-align: center;width: 100%; height:8% ;margin-top: 2%;cursor: default;">
                    Available Widgets
                </div>
                <mat-divider></mat-divider>
            <mat-nav-list style="overflow-x:hidden; height: calc(100% - 65px);">
                <mat-list-item *ngFor = "let wid of widgetList" style="cursor: initial;padding: 0 12px;" [disableRipple]="true">
                    <div style="width: 90%;" i18n-matTooltip matTooltip="{{wid.widgetName}}" >
                        <input type="checkbox" style="width: 18px; height: 18px; float: left; cursor: pointer;" [checked]="wid.isActive"  
                        [disabled]="wid.isActive" (click)="selectWidget($event.target.checked , wid, 'left')" #leftCheckboxes>
                        <div style="width: 88%;float: right; margin-top: 2px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">{{wid.widgetName}}</div>
                    </div>
                    <button mat-icon-button style="width: 35px;" (click)="moveWidget(wid, true)" [disableRipple]="true">
                        <!-- <mat-icon i18n-matTooltip matTooltip="Add" style="font-size: 20px;color: black;padding-top: 0px;margin-left: 0px;touch-action: none;user-select: none;" 
                            *ngIf="!wid.isActive" (click)="addWidget(wid)">add</mat-icon> -->
                        <mat-icon i18n-matTooltip matTooltip="Add" style="font-size: 20px;color: black;padding-top: 0px;margin-left: 0px;touch-action: none;user-select: none;" 
                            *ngIf="!wid.isActive">add</mat-icon>
                    </button>
                    <mat-divider></mat-divider>
                </mat-list-item>
            </mat-nav-list>
        </div>
        <div style="width: 15%;height: 100%;float: left;">
            <div style="height: 50%;margin-block-start: 50px;text-align: center;margin-inline-start: 20px;">
                <mat-icon style="line-height: 8px !important;vertical-align: bottom;margin-top: 150px;font-size: 40px;cursor: pointer;" 
                (click)="widgetData['left'].length ? moveWidgets('left', true) : ''" i18n-matTooltip [matTooltip]="widgetData['left'].length ? 'Add widgets' : ''"
                [ngStyle]="{color: widgetData['left'].length ? 'black' : 'gray'}">forward</mat-icon>
                <mat-icon style="line-height: 8px !important;color: black;vertical-align: middle;margin-top: -150px;margin-left: -10px;font-size: 40px;
                transform: rotate(180deg);cursor: pointer;" 
                (click)="widgetData['right'].length ? moveWidgets('right', false) : ''" i18n-matTooltip [matTooltip]="widgetData['right'].length ? 'Remove widgets' : ''"
                [ngStyle]="{color: widgetData['right'].length ? 'black' : 'gray'}">forward</mat-icon>
            </div>
        </div>
        <div style="width: 40%;height: 100%;background: #dcdcdc57;float: right;border-radius: 10px;box-shadow: 0px 0px 0px 1px grey;margin-right: 10px;">
                <div class="ovi-popup-title-text-size" style="text-align: center;width: 100%;margin-top: 2%;height:8%;cursor: default;">
                    Selected Widgets
                </div>
                <mat-divider></mat-divider>
            <mat-nav-list style="overflow-x: hidden; height: calc(100% - 65px);">
                <mat-list-item *ngFor = "let wid of activeWidget" style="cursor: initial;padding: 0 12px;" [disableRipple]="true">
                <div style="width: 90%;" i18n-matTooltip matTooltip="{{wid.widgetName}}">
                <input type="checkbox" style="width: 18px; height: 18px; float: left;cursor: pointer;" (click)="selectWidget($event.target.checked , wid, 'right')" #rightCheckboxes>
                <div style="width: 88%;float: right; margin-top: 2px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">{{wid.widgetName}}</div>
                </div>
                <button mat-icon-button style="width: 35px;" (click)="moveWidget(wid, false)" [disableRipple]="true" >
                <!-- <mat-icon  i18n-matTooltip matTooltip="Delete" style="font-size: 20px;color: black;padding-top: 0px;margin-left: 0px;touch-action: none;user-select: none;" 
                (click)="deleteWidget(wid)">delete_outline</mat-icon> -->
                <mat-icon  i18n-matTooltip matTooltip="Delete" 
                    style="font-size: 20px;color: black;padding-top: 0px;margin-left: 0px;
                    touch-action: none;user-select: none;">delete_outline</mat-icon>
                
                </button>
                <mat-divider></mat-divider>
                </mat-list-item>
            </mat-nav-list>            
        </div>
    </div>
    <div style="font-size: 14px;word-wrap: break-word;padding-top: 7px;;" *ngIf="!spinLoader">
        Note: Widget position and size can be changed in the dashboard by drag and drop.
    </div>
</div>