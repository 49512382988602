<div class="ovi-font-family" [formGroup]="formTemplate" *ngIf="formTemplateObject.length" style="height: 100%;" [ngStyle]="">
    <div class="dialog-header" *ngIf="formTemplateDetail['header'] && isFormBind" [ngStyle]="formTemplateDetail['css']['header']">
        <span>{{formTemplateDetail['name']}}</span>
        <span style="float: right;">
            <button mat-icon-button mat-dialog-close style="width: 30px !important;height: 30px !important;">
                <mat-icon style="line-height: 5px !important;">highlight_off</mat-icon>
            </button>
        </span>
    </div>
    <mat-drawer-container #container class="drawer-container">
        <mat-drawer #menu *ngIf="sideBarEnable" mode="side" opened="true" style = "width: 250px; background-color: white;">
            <div style="width: 248px;float: left;height : calc(100% - 2px);border-right: 2px solid gray;overflow: auto;">
                <mat-list>
                    <mat-list-item style="border-bottom: 1.5px solid black;padding-left: 20px;font-weight: 600;"> Status Transaction</mat-list-item>
                    <mat-list-item style="border-bottom: 1.5px solid #e8e8e8;"
                        [ngStyle]="{background: entityFormHistId == history['id'] ? '#3a7afe' : 'white', color: entityFormHistId == history['id'] ? 'white' : 'black'}"
                        *ngFor="let history of formHistDetails;" (click) = "entityFormHistId = history['id'];formEntityBinding(history)">
                        {{history['entityFormStatusName'] ? history['entityFormStatusName'] : history['entityFormStatusId']}}
                    </mat-list-item>
                </mat-list>
            </div>
        </mat-drawer>
        <mat-divider vertical style="border-right-width: 3px;"></mat-divider>
        <mat-drawer-content [ngClass]="sideBarEnable?'drawer-right-content':'drawer-main-content'">
            <div style="width: calc(100% - 16px);height: calc(100% - 16px);overflow: auto;padding: 15px 5px 0px 10px;" [ngStyle]="formTemplateDetail['css']['content']">
                <div *ngFor="let fields of formTemplateObject; let indexi=index">
                    <div fxLayout="row" fxLayout.lt-sm="column">
                        <div [ngStyle]="field?.tempCustomCss?.content ? field?.tempCustomCss?.content : {'width': 100/fields.length+'%'}" *ngFor="let field of fields; let indexj=index">
                            <!-- gridster - dynamic form code need to be commented -->
                            <div *ngIf="type!='gridster' && false"> 
                                <div *ngIf="field.type=='label'" style="margin-top: 20px;">
                                    <mat-label style="font-size: 22px; font-weight: 600;">
                                        {{field.labelName}}
                                    </mat-label>
                                </div>
                                <div *ngIf="field.type!='label'">
                                    <mat-label *ngIf='field.type!="button" && field.type != "DI-BTN"'>
                                        {{field.labelName}}
                                    </mat-label>
                                    <ng-container [ngSwitch]="field.type">
                                        <div class="mat-custom-form-field-box" fxFlex.xs="100" fxFlex.lg="100" fxFlex.md="50" fxFlex.sm="50">
                                            <span *ngSwitchCase="'DI-TXT'">
                                                <mat-form-field appearance="outline">
                                                    <input matInput class="form-control" [formControlName]="field.key" [type]="field.type">
                                                </mat-form-field>
                                            </span>
                                            <span *ngSwitchCase="'DI-SRC'">
                                                <mat-form-field appearance="outline">
                                                    <input matInput class="form-control" [formControlName]="field.key" [type]="field.type" (keyup)="getSearchlist($event)" [matAutocomplete]="auto">
                                                    <mat-autocomplete #auto="matAutocomplete">
                                                        <mat-option *ngFor="let location of locationlist" value="{{location.name}},{{location.fullName}}" (click)="getSearchText(location,indexi,indexj)">
                                                        {{location.name}},{{location.fullName}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </span>
                                            <span *ngSwitchCase="'checkbox'">
                                                <mat-form-field appearance="outline">
                                                    <input matInput type="checkbox" [value]="field.defaultValue" [formControlName]="field.key">
                                                </mat-form-field>
                                            </span>
                                            <span *ngSwitchCase="'email'">
                                                <mat-form-field appearance="outline">
                                                    <input matInput type="email" [value]="field.defaultValue" [formControlName]="field.key">
                                                </mat-form-field>
                                            </span>
                                            <span *ngSwitchCase="'DI-DTE'">
                                                <mat-form-field appearance="outline">
                                                    <input matInput type="date" [formControlName]="field.key" [max]="today">
                                                </mat-form-field>
                                            </span>
                                            <span *ngSwitchCase="'datetime'">
                                                <mat-form-field appearance="outline">
                                                    <input matInput type="datetime" [value]="field.defaultValue" [formControlName]="field.key">
                                                </mat-form-field>
                                            </span>
                                            <span *ngSwitchCase="'datetime-local'">
                                                <mat-form-field appearance="outline">
                                                    <input matInput type="datetime-local" [value]="field.defaultValue" [formControlName]="field.key">
                                                </mat-form-field>
                                            </span>
                                            <span *ngSwitchCase="'number'">
                                                <mat-form-field appearance="outline">
                                                    <input *ngSwitchCase="'number'" matInput type="number" [value]="field.defaultValue" [formControlName]="field.key">
                                                </mat-form-field>
                                            </span>
                                
                                            <span *ngSwitchCase="'DI-DRD'">
                                                <mat-form-field appearance="outline">
                                                    <mat-select [formControlName]="field.key" class="form-control">
                                                        <mat-option *ngFor="let option of field.value" [value]="option.value">
                                                        {{option.label}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </span>
                                    
                                            <span *ngSwitchCase="'radio'">
                                                <mat-radio-group [formControlName]="field.key" *ngFor="let option of field.value">
                                                    <mat-radio-button [value]="option.value">{{option.label}}</mat-radio-button>
                                                </mat-radio-group>
                                            </span>
                                            <span *ngSwitchCase="'DI-BTN'">
                                                <span *ngIf="field.key=='button'" style="margin-top: 25px;">
                                                    <button mat-button class="active-btn margin-r-1">{{field.labelName}}</button>
                                                </span>
                                                <div *ngIf="field.key=='gis'" style="width:145px;margin-top: 22px;">
                                                    <div class="button-icon">
                                                        <div class="custom-button" (click)="getWidgetData('getIns')">
                                                            Get Insights
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="field.key=='pdf'" style="float:right;width: 40px;">
                                                    <div style="margin-top: 25px;">
                                                        <img src="/assets/Alert/common_icons/pdf_icon.png" width=40px  style="cursor: pointer;" (click)="getWidgetData('pdf')">
                                                    </div>
                                                </div>
                                                <div *ngIf="field.key=='excel'" style="float:right;width: 40px;">
                                                    <div style="margin-top: 25px;">
                                                        <img src="/assets/Alert/common_icons/excel_icon.png" width=40px  style="cursor: pointer;" (click)="getWidgetData('excel')">
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div *ngIf="type=='create' && false">
                                <div *ngIf="field.type!='label'">
                                    <div fxLayout="row" fxLayout.lt-sm="column">
                                        <ng-container [ngSwitch]="field.type">
                                            <span *ngSwitchCase="'DI-TXT'">
                                                <mat-form-field style="width: calc(100% - 10px);">
                                                <input matInput i18n-[placeholder] [type]="field.type" [placeholder]="field.labelName"
                                                    [formControlName]="field.key">
                                                </mat-form-field>
                                            </span>
                                            <span *ngSwitchCase="'DI-DTE'">
                                                <mat-form-field style="width: calc(100% - 10px);">
                                                <input matInput i18n-[placeholder] type="date" [placeholder]="field.labelName"
                                                    [formControlName]="field.key">
                                                </mat-form-field>
                                            </span>
                                            <span *ngSwitchCase="'DI-SRC'">
                                                <mat-form-field style="width: calc(100% - 10px);">
                                                    <input matInput [formControlName]="field.key" [type]="field.type" [placeholder]="field.labelName">
                                                </mat-form-field>
                                            </span>
                                            <span *ngSwitchCase="'DI-BTN'">
                                                <button mat-button class="active-btn margin-r-1">{{field.labelName}}</button>
                                            </span>
                                            <span style="margin-top: 2%">
                                                <mat-icon *ngIf="!isOpen" i18n mat-mini-fab i18n-matTooltip
                                                appTooltip tooltipText="Edit" (click)="editDataItem(indexi,indexj);exitValue()">edit</mat-icon>
                                                <mat-icon *ngIf="isOpen && indexi == updateIndexi && indexj == updateIndexj" i18n mat-mini-fab >more_horiz</mat-icon>
                                            </span>
                                            <span style="margin-top: 2%"><mat-icon 
                                                appTooltip tooltipText="Delete" (click)="deleteDataItem(indexi,indexj)">delete</mat-icon></span>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="true">
                                <div fxLayout="row" fxLayout.lt-sm="column">
                                    <ng-container>
                                        <div *ngIf="field.type == 'DI-LBL'" style="width: calc(100% - 10px);">
                                            <div [ngStyle]="field?.tempCustomCss?.di">
                                                <mat-label> {{field.labelName}}</mat-label>
                                                <div style="float: right;">
                                                    <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" i18n mat-mini-fab i18n-matTooltip style="margin-top: 2%;color: #4E88FF !important;"
                                                    appTooltip tooltipText="Edit" (click)="editDataItem(indexi,indexj);exitValue()">edit</mat-icon>
                                                    <mat-icon matSuffix *ngIf="!isFormBind && isOpen && indexi == updateIndexi && indexj == updateIndexj" i18n mat-mini-fab >more_horiz</mat-icon>
                                                    <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" style="margin-top: 2%;color: #E65100 !important;"
                                                        appTooltip tooltipText="Delete" (click)="confirmDelete(indexi,indexj)">delete</mat-icon>
                                                </div>
                                                <div>
                                                    <mat-hint *ngIf="!isFormBind" style="font-size: 11px;"> Position : {{field.position}}</mat-hint>
                                                </div>
                                            </div>
                                        </div>
                                        <mat-form-field style="width: calc(100% - 10px);" [ngStyle]="field?.tempCustomCss?.di" [appearance]="field?.validator?.outline ? 'outline':''" *ngIf = "['DI-DTE','DI-DTM','DI-TIM','DI-TXT','DI-NUM'].indexOf(field.type) > -1">
                                            <input matInput i18n-[placeholder] [type]="field.typeName" [placeholder]="field.labelName"
                                                [formControlName]="field.key" appTooltip [tooltipText]="field.tooltip" [required] = "field.validator.required">
                                            
                                            <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" i18n mat-mini-fab i18n-matTooltip style="margin-top: 2%;color: #4E88FF !important;opacity: 10;"
                                            appTooltip tooltipText="Edit" (click)="editDataItem(indexi,indexj);exitValue()">edit</mat-icon>
                                            <mat-icon matSuffix *ngIf="!isFormBind && isOpen && indexi == updateIndexi && indexj == updateIndexj" i18n mat-mini-fab >more_horiz</mat-icon>
                                            <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" style="margin-top: 2%;color: #E65100 !important;opacity: 10;"
                                                appTooltip tooltipText="Delete" (click)="confirmDelete(indexi,indexj)">delete</mat-icon>
                                            <mat-hint *ngIf="!isFormBind" style="font-size: 11px;"> Position : {{field.position}}</mat-hint>
                                            <mat-error *ngIf="formTemplate.controls[field.key].invalid" class="error-txt-1">
                                                <span i18n [hidden]="!formTemplate.controls[field.key].errors.required"> {{field.labelName}} is required</span>
                                                <span i18n [hidden]="!formTemplate.controls[field.key].errors.minlength">Minimum {{field.validator.minLength}} characters</span>
                                                <span i18n [hidden]="!formTemplate.controls[field.key].errors.maxlength">Max character length {{field.validator.maxLength}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field style="width: calc(100% - 10px);" [ngStyle]="field?.tempCustomCss?.di" [appearance]="field?.validator?.outline ? 'outline':''" *ngIf = "field.type == 'DI-SRC'">
                                            <input matInput i18n-[placeholder] [type]="field.typeName" [placeholder]="field.labelName"
                                                [formControlName]="field.key" appTooltip [tooltipText]="field.tooltip" [required] = "field.validator.required" (keyup)="getSearchlist($event,indexi,indexj)" [matAutocomplete]="auto">

                                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this, field)">
                                                <mat-option *ngFor="let searchData of field.outputResponse" [value]="searchData[field.dataBind.key]" (click)="getSearchText(searchData,indexi,indexj)">
                                                {{searchData[field.dataBind.value]}}
                                                </mat-option>
                                            </mat-autocomplete>
                                            
                                            <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" i18n mat-mini-fab i18n-matTooltip style="margin-top: 2%;color: #4E88FF !important;opacity: 10;"
                                            appTooltip tooltipText="Edit" (click)="editDataItem(indexi,indexj);exitValue()">edit</mat-icon>
                                            <mat-icon matSuffix *ngIf="!isFormBind && isOpen && indexi == updateIndexi && indexj == updateIndexj" i18n mat-mini-fab >more_horiz</mat-icon>
                                            <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" style="margin-top: 2%;color: #E65100 !important;opacity: 10;"
                                                appTooltip tooltipText="Delete" (click)="confirmDelete(indexi,indexj)">delete</mat-icon>
                                            <mat-hint *ngIf="!isFormBind" style="font-size: 11px;"> Position : {{field.position}}</mat-hint>
                                            <mat-error *ngIf="formTemplate.controls[field.key].invalid" class="error-txt-1">
                                                <span i18n [hidden]="!formTemplate.controls[field.key].errors.required"> {{field.labelName}} is required</span>
                                                <span i18n [hidden]="!formTemplate.controls[field.key].errors.minlength">Minimum {{field.validator.minLength}} characters</span>
                                                <span i18n [hidden]="!formTemplate.controls[field.key].errors.maxlength">Max character length {{field.validator.maxLength}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field style="width: calc(100% - 10px);" [ngStyle]="field?.tempCustomCss?.di" *ngIf = "field.type == 'DI-TXA'">
                                            <textarea matInput i18n-[placeholder] [rows] ="field.validator.row"  [placeholder]="field.labelName" [formControlName]="field.key"
                                                appTooltip [tooltipText]="field.tooltip" placement="bottom" [required] = "field.validator.required"></textarea>
                                            
                                            <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" i18n mat-mini-fab i18n-matTooltip style="margin-top: 2%;color: #4E88FF !important;opacity: 10;"
                                            appTooltip tooltipText="Edit" (click)="editDataItem(indexi,indexj);exitValue()">edit</mat-icon>
                                            <mat-icon matSuffix *ngIf="!isFormBind && isOpen && indexi == updateIndexi && indexj == updateIndexj" i18n mat-mini-fab >more_horiz</mat-icon>
                                            <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" style="margin-top: 2%;color: #E65100 !important;opacity: 10;"
                                                appTooltip tooltipText="Delete" (click)="confirmDelete(indexi,indexj)">delete</mat-icon>
                                            <mat-hint *ngIf="!isFormBind" style="font-size: 11px;"> Position : {{field.position}}</mat-hint>
                                            <mat-error *ngIf="formTemplate.controls[field.key].invalid" class="error-txt-1">
                                                <span i18n [hidden]="!formTemplate.controls[field.key].errors.required"> {{field.labelName}} is required</span>
                                                <span i18n [hidden]="!formTemplate.controls[field.key].errors.minlength">Minimum {{field.validator.minLength}} characters</span>
                                                <span i18n [hidden]="!formTemplate.controls[field.key].errors.maxlength">Max character length {{field.validator.maxLength}}</span>
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field style="width: calc(100% - 10px);" [ngStyle]="field?.tempCustomCss?.di" *ngIf = "field.type == 'DI-DRD'">
                                            <mat-select [formControlName]="field.key" [placeholder]="field.labelName" [multiple]="field.multiSelect ? true : false">
                                                <mat-option *ngFor="let option of field.outputResponse" [value]="option[field.dataBind.key]">
                                                {{option[field.dataBind.value]}}
                                                </mat-option>
                                            </mat-select>
                                            
                                            <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" i18n mat-mini-fab i18n-matTooltip style="margin-top: 2%;color: #4E88FF !important;opacity: 10;"
                                            appTooltip tooltipText="Edit" (click)="editDataItem(indexi,indexj);exitValue()">edit</mat-icon>
                                            <mat-icon matSuffix *ngIf="!isFormBind && isOpen && indexi == updateIndexi && indexj == updateIndexj" i18n mat-mini-fab >more_horiz</mat-icon>
                                            <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" style="margin-top: 2%;color: #E65100 !important;opacity: 10;"
                                                appTooltip tooltipText="Delete" (click)="confirmDelete(indexi,indexj)">delete</mat-icon>
                                            <mat-hint *ngIf="!isFormBind" style="font-size: 11px;"> Position : {{field.position}}</mat-hint>
                                        </mat-form-field>
                                        <span style="width: calc(100% - 10px);" *ngIf = "field.type == 'DI-BTN'">
                                            <button mat-button *ngIf="field.key!='pdf' && field.key!='excel'" class="active-btn margin-r-1" (click)="field.key=='gis' && type=='gridster'?getWidgetData(field):''"
                                            [ngStyle]="field?.tempCustomCss?.di">{{field.labelName}}</button>

                                            <span *ngIf="field.key=='pdf'">
                                                <img src="/assets/Alert/common_icons/pdf_icon.png" width=40px  style="cursor: pointer;" (click)="getWidgetData('pdf')"
                                                [ngStyle]="field?.tempCustomCss?.di">
                                            </span>
                                            <span *ngIf="field.key=='excel'">
                                                <img src="/assets/Alert/common_icons/excel_icon.png" width=40px  style="cursor: pointer;" (click)="getWidgetData('excel')"
                                                [ngStyle]="field?.tempCustomCss?.di">
                                            </span>

                                            <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" i18n mat-mini-fab i18n-matTooltip style="margin-top: 2%;color: #4E88FF !important;opacity: 10;"
                                            appTooltip tooltipText="Edit" (click)="editDataItem(indexi,indexj);exitValue()">edit</mat-icon>
                                            <mat-icon matSuffix *ngIf="!isFormBind && isOpen && indexi == updateIndexi && indexj == updateIndexj" i18n mat-mini-fab >more_horiz</mat-icon>
                                            <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" style="margin-top: 2%;color: #E65100 !important;opacity: 10;"
                                                appTooltip tooltipText="Delete" (click)="confirmDelete(indexi,indexj)">delete</mat-icon>
                                            <mat-hint *ngIf="!isFormBind" style="font-size: 11px;"> Position : {{field.position}}</mat-hint>
                                        </span>
                                        <span style="width: calc(100% - 10px);" *ngIf = "field.type == 'DI-FILE'">
                                            <input type='file' (change)="uploadFile($event,indexi,indexj)" [ngStyle]="field?.tempCustomCss?.di">

                                            <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" i18n mat-mini-fab i18n-matTooltip style="margin-top: 2%;color: #4E88FF !important;opacity: 10;"
                                            appTooltip tooltipText="Edit" (click)="editDataItem(indexi,indexj);exitValue()">edit</mat-icon>
                                            <mat-icon matSuffix *ngIf="!isFormBind && isOpen && indexi == updateIndexi && indexj == updateIndexj" i18n mat-mini-fab >more_horiz</mat-icon>
                                            <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" style="margin-top: 2%;color: #E65100 !important;opacity: 10;"
                                                appTooltip tooltipText="Delete" (click)="confirmDelete(indexi,indexj)">delete</mat-icon>
                                            <mat-hint *ngIf="!isFormBind" style="font-size: 11px;"> Position : {{field.position}}</mat-hint>
                                        </span>
                                        <span style="width: calc(100% - 10px);" *ngIf = "field.type == 'DI-IMG'">
                                            <img src="{{field?.outputResponse?.url}}" [ngStyle]="field?.tempCustomCss?.di">

                                            <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" i18n mat-mini-fab i18n-matTooltip style="margin-top: 2%;color: #4E88FF !important;opacity: 10;"
                                            appTooltip tooltipText="Edit" (click)="editDataItem(indexi,indexj);exitValue()">edit</mat-icon>
                                            <mat-icon matSuffix *ngIf="!isFormBind && isOpen && indexi == updateIndexi && indexj == updateIndexj" i18n mat-mini-fab >more_horiz</mat-icon>
                                            <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" style="margin-top: 2%;color: #E65100 !important;opacity: 10;"
                                                appTooltip tooltipText="Delete" (click)="confirmDelete(indexi,indexj)">delete</mat-icon>
                                            <mat-hint *ngIf="!isFormBind" style="font-size: 11px;"> Position : {{field.position}}</mat-hint>
                                        </span>
                                        <div *ngIf="field.type == 'DI-HZL'" style="width: calc(100% - 10px);">
                                            <mat-divider [ngStyle]="field?.tempCustomCss?.di"></mat-divider>
                                            <span style="float: right;">
                                                <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" i18n mat-mini-fab i18n-matTooltip style="margin-top: 2%;color: #4E88FF !important;opacity: 10;"
                                                appTooltip tooltipText="Edit" (click)="editDataItem(indexi,indexj);exitValue()">edit</mat-icon>
                                                <mat-icon matSuffix *ngIf="!isFormBind && isOpen && indexi == updateIndexi && indexj == updateIndexj" i18n mat-mini-fab >more_horiz</mat-icon>
                                                <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" style="margin-top: 2%;color: #E65100 !important;opacity: 10;"
                                                    appTooltip tooltipText="Delete" (click)="confirmDelete(indexi,indexj)">delete</mat-icon>
                                                <mat-hint *ngIf="!isFormBind" style="font-size: 11px;"> Position : {{field.position}}</mat-hint>
                                            </span>
                                        </div>
                                        <!-- <mat-form-field style="width: calc(100% - 10px);" [ngStyle]="field?.tempCustomCss?.di" *ngIf = "field.type != 'DI-LBL'">
                                            <input matInput *ngSwitchCase="'DI-TXT'" i18n-[placeholder] type="text" [placeholder]="field.labelName"
                                                [formControlName]="field.key" appTooltip [tooltipText]="field.tooltip" [required] = "field.validator.required">
                                            <textarea matInput *ngSwitchCase="'DI-TXA'" i18n-[placeholder] [rows] ="field.validator.row"  [placeholder]="field.labelName" [formControlName]="field.key"
                                                appTooltip [tooltipText]="field.tooltip" placement="bottom" [required] = "field.validator.required"></textarea>
                                            <input matInput *ngSwitchCase="'DI-DTE'" i18n-[placeholder] type="date" [placeholder]="field.labelName"
                                                [formControlName]="field.key" appTooltip [tooltipText]="field.tooltip" placement="bottom" [required] = "field.validator.required">
                                            <input matInput *ngSwitchCase="'DI-DTM'" i18n-[placeholder] type="datetime-local" [placeholder]="field.labelName"
                                                [formControlName]="field.key" appTooltip [tooltipText]="field.tooltip" placement="bottom" [required] = "field.validator.required">
                                            <input matInput *ngSwitchCase="'DI-TIM'" i18n-[placeholder] type="time" [placeholder]="field.labelName"
                                                [formControlName]="field.key" appTooltip [tooltipText]="field.tooltip" placement="bottom" [required] = "field.validator.required">
                                            <mat-select *ngSwitchCase="'DI-DRD'" [formControlName]="field.key" [placeholder]="field.labelName">
                                                <mat-option *ngFor="let option of field.outputResponse" [value]="option.code">
                                                {{option.value}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" i18n mat-mini-fab i18n-matTooltip style="margin-top: 2%;color: #4E88FF !important;opacity: 10;"
                                            appTooltip tooltipText="Edit" (click)="editDataItem(indexi,indexj);exitValue()">edit</mat-icon>
                                            <mat-icon matSuffix *ngIf="!isFormBind && isOpen && indexi == updateIndexi && indexj == updateIndexj" i18n mat-mini-fab >more_horiz</mat-icon>
                                            <mat-icon matSuffix *ngIf="!isFormBind && !isOpen" style="margin-top: 2%;color: #E65100 !important;opacity: 10;"
                                                appTooltip tooltipText="Delete" (click)="deleteDataItem(indexi,indexj)">delete</mat-icon>
                                            <mat-hint *ngIf="!isFormBind" style="font-size: 11px;"> Position : {{field.position}}</mat-hint>
                                        </mat-form-field> -->
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isOpen" style="width: 500px;position: absolute;background: white;border-radius: 5px; right: 0px; z-index: 1000;height: 100%;top: 0px;">
                    <app-edit-form-component [formFieldData]="data" (dataValue)=updateDataValue($event) (exitValue)=exitValue()></app-edit-form-component>
                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
    <div #footer *ngIf="isFormBind && type!='gridster'" style="height: 49px;" [ngStyle]="formTemplateDetail['css']['footer']">
        <span *ngIf="nextWorkflowUser" style="float: left;padding-left: 5px;color: grey;">{{nextWorkflowUser}}</span>
        <button i18n *ngIf="formTemplateDetail['footer'] && formTemplateDetail['footerButton']!= null && formTemplateDetail['footerButton']['code'] != 'EFS-DR'" mat-button class="active-btn" style="margin: 5px;float: right;" cdkFocusInitial (click)="saveFormData()" [disabled]="formTemplate.invalid">
            Submit
        </button>
        <button i18n *ngIf="saveEnable || authorizedUser || (formTemplateDetail['footer'] && formTemplateDetail['footerButton']!= null && formTemplateDetail['footerButton']['code'] == 'EFS-DR') || (formTemplateDetail['footerButton']== null && currentStatusEditEnable)" mat-button class="active-btn" style="margin: 5px;float: right;" (click)="saveFormData(entityFormStatusId)">Save As Draft</button>
        <!-- <button i18n *ngIf="formInput['id'] || saveEnable" mat-button class="active-btn" style="margin: 5px;float: right;" (click)="saveFormData(entityFormStatusId)" [disabled]="formTemplateDetail['footerButton']== null? !currentStatusEditEnable : false ">Save</button> -->
        <button i18n mat-button class="cancle-btn" style="margin: 5px;float: right;" mat-dialog-close>Cancel</button>
        <button i18n mat-button *ngIf="formTemplateDetail['footerButton']!= null && formTemplateDetail['footerButton']['code'] != 'EFS-DR' && formTemplateDetail['footerButton']['code'] != 'EFS-AP'" class="return-btn" style="margin: 5px;float: right;" (click)="returnStatus()">Return</button>
    </div>
</div>