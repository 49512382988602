/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
 */

 import { Component, OnInit, DoCheck, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CommonService, DashboardService } from '../../shared';
import * as screenfull from 'screenfull';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ViewProfileComponent, ChangePasswordComponent } from '../../shared/modules/entry-component/user-menu/user-menu.component';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { locale_Json_Details } from '../../../localeJson/localeJson';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

 export class NavbarComponent implements OnInit, DoCheck{
    public menuName = ''
    public mainMenu = '';
    public subMenu = '';
    public childMenu = '';
    public customerId = null;
    public customerLogo = null
     public userName = null;public user = null;
     public isFullScreen : any;
    public menuList = [];
    public activate_btn = [];
    public menuArray: any = [];
    username = localStorage.getItem(btoa('current_user'));
    public fabMenuClose = true;
    @ViewChild('fabOpen', { static: true }) public elementRef: ElementRef;
    public isOpen = false;
    public customerName = "tw";
    public islayout = false;
    public subscription: Subscription;
    layoutList: any=[];
    public layoutForm: UntypedFormGroup;
    locale: string;
    constructor(private router: Router, public commonService : CommonService, private renderer: Renderer2, private dialog: MatDialog,
        public dashboardService: DashboardService, public form: UntypedFormBuilder ) {
        this.getBreadcrumb()
        this.getRole()
        this.commonService.getMenuItems();
        this.commonService.getMQTTDetails();
        this.menuList = this.commonService.getMenu();
        this.activate_btn = this.commonService.getActivePermission('button');
         this.renderer.listen('window', 'click', ( e: Event) => {
            if (e.target === this.elementRef.nativeElement) {
                this.fabMenuClose = true;
                this.isOpen = true;
            } else {
                this.fabMenuClose = false;
                this.isOpen = false;
            }
        });
        this.layoutForm = this.form.group({
            layout: [null]
        });
    }
    ngOnInit() {
        if (window.location.hostname.includes("max")) {
            this.customerName = "max"
        }
        if (window.location.hostname.includes("kyn")) {
            this.customerName = "kyn"
        }
        this.getCustomerLogo(true);
        let roleId = localStorage.getItem('userlevel');
        let userId = localStorage.getItem(btoa('userId'));
         this.commonService.getUserPreference(userId,roleId);
        this.dashboardService.getDashboardDetailsList(userId).subscribe(res => {
            this.layoutList = res.results;
        });
        this.subscription = this.commonService.dashboard.subscribe((dashboard) => {
            this.layoutForm.get('layout').setValue(dashboard);
            const list = this.layoutList.filter(res => res.id === dashboard);
            this.checkLayout(list);
        });
    }
    getRole() {
        this.commonService.getAllRoleList().subscribe(res => {
            let assignedRole = res.results.filter(val => val.id == localStorage.getItem('userlevel'))
            this.userName = localStorage.getItem(btoa('current_user')) + ' [ ' + assignedRole[0]['name'] + ' ]';
            this.user = localStorage.getItem(btoa('current_user'));
        });
    }
    logoNotfound(isSave) {
        this.customerLogo = '/assets/Alert/common_icons/new-logo.png';
    }
    getCustomerLogo(isSave) {
         if(isSave) {
            this.customerId = localStorage.getItem('customerId')
            this.customerLogo = environment.api_base_url_new + environment.base_value.get_customer_logo + '/' + this.customerId;
        }
    }
    logout() {
        this.userName = '';
        localStorage.clear();
        if (screenfull.isFullscreen) {
            screenfull.exit();
        }
        this.router.navigate(['/login']);
    }
     checkFullscreen(){
        screenfull.toggle();
    }
    getBreadcrumb() {
        this.locale = localStorage.getItem(btoa('lang'));
        this.menuName = window.location.pathname
        let menuPath = this.menuName.split('-').join(' ')
        menuPath = menuPath.split('_').join(' ')
        const menuValue = menuPath.split('/');
        if (menuValue[1] === 'en' || menuValue[1] === 'ar') {
            this.menuArray = menuValue.slice(-1);
            this.subMenu = menuValue[4];
            this.mainMenu = menuValue[3];
        } else {
            if(this.locale === 'en-US' || this.locale === 'en'){
                this.menuArray = menuValue.slice(-1);
                this.subMenu = menuValue[3];
                this.mainMenu = menuValue[2];
            } else if(this.locale === 'ar'){
                this.menuArray = menuValue.slice(-1);
                let menuVal = this.menuArray[0];
                let menus = locale_Json_Details.ar
                this.menuArray = menuVal === 'dashboard'? menus.dashboard : menuVal === 'digital queue'? menus.digital_queue : menuVal === 'health checkup' ? menus.health_checkup : menuVal === 'truck tracking' ? menus.truck_tracking : 
                menuVal === 'employee'? menus.employee : menuVal === 'visitors'? menus.visitors : menuVal === 'temporary id card'? menus.temporary_id_card : menuVal === 'asset management'? menus.asset_management : menuVal === 'porter new'? menus.porter_new : 
                menuVal === 'outpatient'? menus.outpatient : menuVal === 'operation theatre'? menus.operation_theatre : menuVal === 'emergency care'? menus.emergency_care : menuVal === 'ambulance'? menus.ambulance : menuVal === 'infant'? menus.infant : menuVal === 'consumer'? menus.consumer : 
                menuVal === 'staff routine'? menus.staff_routine : menuVal === 'message centre'? menus.message_centre : menuVal === 'student'? menus.student : menuVal === 'form'? menus.form : menuVal === 'ticket'? menus.ticket : menuVal === 'patient'? menus.patient :
                menuVal === 'manage facility'? menus.manage_facility : menuVal === 'location' ? menus.location : menuVal === 'user management' ? menus.user_management : menuVal === 'import setting' ? menus.import_setting : menuVal === 'floor plan' ? menus.floor_plan : 
                menuVal === 'support'? menus.support : menuVal === 'gateway'? menus.gateway : menuVal === 'gateway management'? menus.gateway_management : menuVal === 'broker'? menus.broker : menuVal === 'reader'? menus.reader : menuVal === 'server management'? menus.server_management : 
                menuVal === 'device'? menus.device : menuVal === 'asset'? menus.asset : menuVal === 'rule'? menus.rule : menuVal === 'appterms'? menus.appterms : menuVal === 'alert'? menus.alert : menuVal === 'scheduler'? menus.scheduler : menuVal === 'permission mapping'? menus.permission_mapping :
                menuVal === 'social distance config'? menus.social_distance_config : menuVal === 'package'? menus.package : menuVal === 'resident'? menus.resident : menuVal === 'day care'? menus.day_care : menuVal === 'emergency care'? menus.emergency_care : menuVal === 'inpatient'? menus.inpatient : 
                menuVal === 'medical record' ? menus.medicalRecord : menuVal === 'task'? menus.task : menuVal === 'patient healthcheckup'? menus.patient_healthcheckup : menuVal === 'employee summary'? menus.employee_summary : menuVal === 'routine' ? menus.Routine : menuVal === 'maintenance' ? menus.Maintenance :
                menuVal === 'In patient' ? menus.In_patient : menuVal === 'out patient'? menus.out_patient : menuVal === 'patient daycare'? menus.patient_daycare : menuVal === 'patient infant'? menus.patient_infant : menuVal === 'medical record'? menus.medical_record : menuVal === 'emergency'? menus.emergency :
                menuVal ===  'schedule report'? menus.schedule_report : menuVal === 'staff'? menus.staff : menuVal === 'environment'? menus.environment : menuVal === 'audit log'? menus.audit_log : menuVal === 'error log'? menus.error_log : menuVal === 'porter'? menus.porter : null;
                this.subMenu = menuValue[3]; 
                this.mainMenu = menuValue[2];
            } else if(this.locale === 'id'){
                this.menuArray = menuValue.slice(-1);
                let menuVal = this.menuArray[0];
                let menus = locale_Json_Details.id
                this.menuArray = menuVal === 'dashboard'? menus.dashboard : menuVal === 'digital queue'? menus.digital_queue : menuVal === 'health checkup' ? menus.health_checkup : menuVal === 'truck tracking' ? menus.truck_tracking : 
                menuVal === 'employee'? menus.employee : menuVal === 'visitors'? menus.visitors : menuVal === 'temporary id card'? menus.temporary_id_card : menuVal === 'asset management'? menus.asset_management : menuVal === 'porter new'? menus.porter_new : 
                menuVal === 'outpatient'? menus.outpatient : menuVal === 'operation theatre'? menus.operation_theatre : menuVal === 'emergency care'? menus.emergency_care : menuVal === 'ambulance'? menus.ambulance : menuVal === 'infant'? menus.infant : menuVal === 'consumer'? menus.consumer : 
                menuVal === 'staff routine'? menus.staff_routine : menuVal === 'message centre'? menus.message_centre : menuVal === 'student'? menus.student : menuVal === 'form'? menus.form : menuVal === 'ticket'? menus.ticket : menuVal === 'patient'? menus.patient :
                menuVal === 'manage facility'? menus.manage_facility : menuVal === 'location' ? menus.location : menuVal === 'user management' ? menus.user_management : menuVal === 'import setting' ? menus.import_setting : menuVal === 'floor plan' ? menus.floor_plan : 
                menuVal === 'support'? menus.support : menuVal === 'gateway'? menus.gateway : menuVal === 'gateway management'? menus.gateway_management : menuVal === 'broker'? menus.broker : menuVal === 'reader'? menus.reader : menuVal === 'server management'? menus.server_management : 
                menuVal === 'device'? menus.device : menuVal === 'asset'? menus.asset : menuVal === 'rule'? menus.rule : menuVal === 'appterms'? menus.appterms : menuVal === 'alert'? menus.alert : menuVal === 'scheduler'? menus.scheduler : menuVal === 'permission mapping'? menus.permission_mapping :
                menuVal === 'social distance config'? menus.social_distance_config : menuVal === 'package'? menus.package : menuVal === 'resident'? menus.resident : menuVal === 'day care'? menus.day_care : menuVal === 'emergency care'? menus.emergency_care : menuVal === 'inpatient'? menus.inpatient : 
                menuVal === 'medical record' ? menus.medicalRecord : null;
                this.subMenu = menuValue[3];
                this.mainMenu = menuValue[2];
            }
        }

        if (this.mainMenu != null) {
            const checkMainmenu = this.mainMenu.split('-');
            if (checkMainmenu.length !== 1) {
                this.mainMenu = checkMainmenu[0] + ' ' + checkMainmenu[1];
            }
        }

        if (this.subMenu != null) {
            const submenuCheck = this.subMenu.split('-');
            if (submenuCheck.length !== 1) {
                this.subMenu = submenuCheck[0] + ' ' + submenuCheck[1];
            }
        }
    }


    ngDoCheck() {
         if(this.menuName !== window.location.pathname) {
            this.getBreadcrumb();
        }
         if(screenfull.enabled){
            this.isFullScreen = screenfull.isFullscreen;
        }
    }

    addProfile() {
        this.dialog.open(ViewProfileComponent,
            {
                data: {
                }, panelClass: ['small-popup'], disableClose: false
            });
    }
    changePassword() {
        const dialogRef = this.dialog.open(ChangePasswordComponent, {
            panelClass: ['small-popup'], disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    checkLayout(value) {
        this.commonService.setLayout(value);
    }

      openedChange(isOpended)
      {
        if(!isOpended)
        {
            this.islayout = false;
        }
    }
}
