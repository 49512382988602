<div class="user-profile ovi-font-family">
    <mat-toolbar class="input-header">
        <div i18n class="user-profile-header">View Profile</div>
        <div class="material-icons">
            <button mat-icon-button mat-dialog-close class="close-button ie-close-icon">
                <mat-icon class="icon-style">highlight_off</mat-icon>
            </button>
        </div>
    </mat-toolbar>
    <div class="user-profile-name">
        <div matLine style="text-align: center; font-weight: 600; font-size: 12px;">{{user_info[0].username | titlecase}} </div>
        <div matLine style="text-align: center; font-size: 20px;" *ngIf = "user_info[0].employeeId!=null">{{user_info[0].employeeId}}</div>
    </div>
    <div class="user-profile-body">
        <div matLine style="font-size: 12px; height: 35px; font-weight: 600;">
            <div i18n style="width: 180px; padding-right: 5px; float: left; text-align: right; font-weight: 500;">Role :</div>
            {{user_info[0].userRole}}
        </div>
        <div matLine style="font-size: 12px; height: 35px; font-weight: 600;">
            <div i18n style="width: 180px; padding-right: 5px; float: left; text-align: right; font-weight: 500;">Date of Birth :</div>
            {{user_info[0].birthDate | date}}
        </div>
        <div matLine style="font-size: 12px; height: 35px; font-weight: 600;width: 400px;white-space: nowrap;">
            <div i18n style="width: 180px; padding-right: 5px; float: left; text-align: right; font-weight: 500;">Email :</div >
            {{user_info[0].email}}
        </div>
        <div matLine style="font-size: 12px; height: 35px; font-weight: 600;">
            <div i18n style="width: 180px; padding-right: 5px; float: left; text-align: right; font-weight: 500;">Phone Number :</div>
             {{user_info[0].phoneNumber}}
        </div>
        <div style="font-size: 12px; font-weight: 600;">
            <div i18n style="width: 180px; padding-right: 5px; float: left; text-align: right; font-weight: 500;">Address :</div> 
            <div>{{user_info[0].address}}</div>
        </div>
    </div>
</div>