/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Component,HostListener } from '@angular/core';
import { Event, Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router';
import { environment } from './../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { BnNgIdleService } from 'bn-ng-idle';
import * as screenfull from 'screenfull';
import { CommonService } from './shared';
import { CookieService } from 'ngx-cookie-service';
import { VersionCheckService } from './shared/services/version-check.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent {
  showLoadingSpinner = true;
  currentUrl = window.location.pathname;
  public checkDom = true;

  @HostListener('window:unload', [ '$event' ])
  unloadHandler(event) {
    this.checkUserPreference();
  }

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHander() {
    this.checkUserPreference();
  }
  constructor(public toastr: ToastrService, private bnIdle: BnNgIdleService,
    public dialog: MatDialog, private router: Router, private activeRoute: ActivatedRoute,
    private commonService: CommonService, public cookieService: CookieService, public versionCheckService: VersionCheckService) {
      this.checkToken();
      this.router.events.subscribe((routerEvent: Event) => {
        if (routerEvent instanceof NavigationStart) {
          this.showLoadingSpinner = true;
        }

        if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
          this.showLoadingSpinner = false;
        }
      });
      const time_out = environment.hasOwnProperty('idle_time_out') ? environment.idle_time_out : environment.base_value.idle_time_out;
    // User Idle Timout set to 1 hour
    // 86400000 = 24 hours [ Banner will be visible for 24 hours w/o producing duplicates, then it will go of ]
    this.bnIdle.startWatching(time_out).subscribe((res) => {
      if (res) {
          if ((btoa('current_user') in localStorage) && !('privateUser' in localStorage) && this.currentUrl !== '/ovitag/workflow/monitor' && 
          window.location.pathname !== '/ovitag/workflow/inpatient') {
          this.toastr.warning('<span class = \'ovi-font-family\' style=\'font-size:16px;\'>Automatically logged out due to inactivity!</span>', null, {
          timeOut: 3600, closeButton: true, enableHtml: true
          });
          this.dialog.closeAll();
          localStorage.clear();
          if (screenfull.isFullscreen) {
            screenfull.exit();
            document.exitFullscreen();
          }

          this.router.navigate(['/login']);
        }
      }

    });


  }
  checkUserPreference() {}

  
  checkToken() {
    if (this.currentUrl !== '/') {
      this.activeRoute.queryParams.subscribe(params => {
        if (Object.keys(params).length !== 0 && params.hasOwnProperty('token')) {
          this.checkDom = false;
          let queryParam = {
            'token': params['token'].toString().split(' ').join('+'),
            'color': null,
            'bgcolor': null,
            'pageBgColor': null,
          };
          if (params.hasOwnProperty('color')) {
            queryParam['color'] = '#' + params['color'];
          }
          if (params.hasOwnProperty('bgcolor')) {
            queryParam['bgcolor'] = '#' + params['bgcolor'];
          }
          if (params.hasOwnProperty('pagebgcolor')) {
            queryParam['pagebgcolor'] = '#' + params['pagebgcolor'];
          }
          this.commonService.loginToken(queryParam).subscribe(iresult => {
            if (iresult.statusCode !== 0) {
              localStorage.setItem('permission', JSON.stringify(iresult.results.permissions));
              localStorage.removeItem('urlLinks');
              localStorage.setItem(btoa('user_token'), iresult.results.accessToken); // dXNlcl90b2tlbg==
              if (iresult.results.user.lastName != null) {
                localStorage.setItem(btoa('current_user'), iresult.results.user.firstName + ' ' +
                  iresult.results.user.lastName); // Y3VycmVudF91c2Vy
              } else {
                localStorage.setItem(btoa('current_user'), iresult.results.user.firstName); // Y3VycmVudF91c2Vy
              }
              localStorage.setItem(btoa('session_time'), (new Date().getTime() + (1 * 60 * 60 * 1000)).toString()); // c2Vzc2lvbl90aW1l
              localStorage.setItem(btoa('refreshToken'), iresult.results.refreshToken); // cmVmcmVzaFRva2Vu
              localStorage.setItem(btoa('menuItems'), JSON.stringify(iresult.results.menus));

              // localStorage.setItem('admin', parseInt(iresult.results.user.roles.length ? iresult.results.user.roles[0].id : '0') <= 2 ? '1' : '0');
              localStorage.setItem('userlevel', (iresult.results.user.roles.length ? iresult.results.user.roles[0].id : '0'));
              localStorage.setItem('customerId', iresult.results.user.customerId); // ZmFjaWxpdHlJZA== LOCAL
              localStorage.setItem('regionId', iresult.results.user.regionId); // ZmFjaWxpdHlJZA== LOCAL
              if (params.hasOwnProperty('fid')) {
                localStorage.setItem(btoa('facilityId'), params['fid']);
              } else {
                localStorage.setItem(btoa('facilityId'), iresult.results.user.facilityId); // ZmFjaWxpdHlJZA== LOCAL
              }

              localStorage.setItem(btoa('userId'), iresult.results.user.id);
              localStorage.setItem('privateUser', '1');
              if (queryParam['color'] != null) {
                localStorage.setItem('userColor', queryParam['color']);
              }
              if (queryParam['bgcolor'] != null) {
                localStorage.setItem('userBgColor', queryParam['bgcolor']);
              }
              if (queryParam['pagebgcolor'] != null) {
                localStorage.setItem('userPageBgColor', queryParam['pagebgcolor']);
              }
              localStorage.setItem(btoa('externalCustomerId'), iresult.results.user.externalCustomerId); // ZXh0ZXJuYWxDdXN0b21lcklk
              localStorage.setItem(btoa('externalRegionId'), iresult.results.user.externalRegionId); // ZXh0ZXJuYWxSZWdpb25JZA==
              localStorage.setItem(btoa('externalfacilityId'), iresult.results.user.externalfacilityId); // ZXh0ZXJuYWxmYWNpbGl0eUlk
              localStorage.setItem(btoa('tokenEnrollFlow'), iresult.results.tokenEnrollFlow.enabled); // dG9rZW5FbnJvbGxGbG93
              if(params.hasOwnProperty('rid')) {
                this.router.navigate([this.currentUrl], { queryParams: { rid : params["rid"], rtyp : params["rtyp"] }});
              } else {
                this.router.navigate([this.currentUrl]);
              }
            } else {
              this.router.navigate(['/login']);
              this.toastr.error('Error', `${iresult.message}`);
            }
            this.checkDom = true;
          },
            error => {
              this.router.navigate(['/login']);
              this.toastr.error('Error', `${error.error.message}`);
            });
        }
      });
    }
  }
}
