<div class="bg-colr">
		<mat-toolbar class="input-header bg-colr">
				<div class="header-name bg-colr">   </div>
				<div class="header-close bg-colr"><button mat-icon-button mat-dialog-close close-button>
								<mat-icon class="icon-style_1 bg-colr">highlight_off</mat-icon>
						</button></div>
		</mat-toolbar>
		<div class = "prof">
		<div class="bg-colr">
			<div class="text-center cir_img">
				<img [src]="imageData" alt="Image" class="img-styl">
				<label mat-button>
					<mat-icon i18n-matTooltip matTooltip="Click to upload an Image" autofocus
						class="icn-styl">camera_alt
					</mat-icon>
					<input type="file" id="File" accept="image/*" (change)="uploadImage($event)" class="disp-styl" #input
						autofocus>
				</label>
		
			</div>
		
			<div class="text-center form-body-frame">
				<div class="input-container" [formGroup]="profileForm">
					<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" class="form-row">
						<div fxFlex.xs="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" class="row-field">
							<div class="example-form mrgn-styl">
		
								<mat-form-field class="example-full-width text-center ">
									<input matInput i18n-placeholder placeholder="" formControlName="firstName" readonly>
					
								</mat-form-field><br>
								
								<mat-form-field [floatLabel]="'never'" class="example-full-width text-center">
									<input matInput i18n-placeholder placeholder="ID" formControlName="id" readonly>
								</mat-form-field><br>
		
								<mat-form-field [floatLabel]="'never'" class="example-full-width text-center">
									<input matInput i18n-placeholder placeholder="Designation" formControlName="address" >
									<mat-icon i18n-matTooltip  matTooltip="click to edit" matSuffix class="edt-icn-styl" *ngIf="!edit">edit
									</mat-icon>
								</mat-form-field><br>
		
								<mat-form-field [floatLabel]="'never'" class="example-full-width text-center">
									<input matInput i18n-placeholder placeholder="email" formControlName="email" readonly>
								</mat-form-field><br>
		
								<mat-form-field [floatLabel]="'never'" class="example-full-width text-center">
									<input matInput i18n-placeholder placeholder="Phone" formControlName="phoneNumber" readonly>
								</mat-form-field>
		
								<br>
								<mat-card-actions class="text-center">
		
									<div class="text-center">
										<button i18n mat-button class="cancel-button cn-btn" mat-dialog-close>Cancel</button>
										<button i18n mat-button class="blue-button" 
											(click)="editUser();">Save</button>
									</div>
								</mat-card-actions>
		
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</div>
		</div>