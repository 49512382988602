<div clas="ovi-font-family" style="width: 100%; height:100%;overflow:auto">
      <gridster id="dm-layout" [options]="options" style="background: #e7e7e7; float: left;">
        <gridster-item *ngFor = "let wid of clayout" [item]="wid" [ngClass]="(['WD_DBAMR', 'WD_DBAS'].indexOf(wid.code) == -1 && dashboardDetails[wid.code]['type'] != 'header') || dashboardDetails[wid.code]['type'] == 'table' || dashboardDetails[wid.code]['type'] == 'chart' || dashboardDetails[wid.code]['type'] == 'form'? 'grid-item' : 'grid-item2'">
          <app-widget-header (childToParentAction)="floorView = false;" *ngIf = "(['WD_DBAMR', 'WD_DBAS'].indexOf(wid.code) == -1 && dashboardDetails[wid.code]['type'] != 'card' && dashboardDetails[wid.code]['type'] != 'header' && dashboardDetails[wid.code]['type'] != 'table' && dashboardDetails[wid.code]['type'] != 'form') " (click)="goToReport(wid.code)"
            [title]="dashboardDetails[wid.code].hasOwnProperty('text') ? dashboardDetails[wid.code]['text'] : wid.widgetName"
             [name]="'WD_DBFP'" [showMinimize]="wid.code == 'WD_DBFP' && floorView">
          </app-widget-header>
            
          <!-- COMPONENT CALL  -->
          <app-floor-plan *ngIf="wid.code == 'WD_DBFP'" (childToParentAction)="floorView = true; getFloorId($event)"></app-floor-plan>   
          <app-alert class="grid-content" *ngIf="wid.code == 'WD_DBALERT'"></app-alert>
          
          <!-- HEALTH PLAN AND TEST TABLE -->
          <div class="grid-content" style="height: calc(100% - 5px);" *ngIf="dashboardDetails[wid.code]['show'] && dashboardDetails[wid.code]['type'] == 'table'">
              <angular-table-component [title]="dashboardDetails[wid.code].hasOwnProperty('text') ? dashboardDetails[wid.code]['text'] : wid.widgetName" [headerStyle]="dashboardDetails[wid.code]['styles']" [tableData]="dashboardDetails[wid.code]['data']" [columns]="dashboardDetails[wid.code]['column']" [id]="'dashWid'"  [tableLabel]="dashboardDetails[wid.code]['label']" [tableStyle]="dashboardDetails[wid.code]['tableStyle']" [styles] = "dashboardDetails[wid.code]['styles']" 
              [page]="dashboardDetails[wid.code]['pagination']" [pageSizeOptions]="dashboardDetails[wid.code]['pageSizeOptions']"
              [enableExcel]="dashboardDetails[wid.code]['enableExcel']" [filterBy]="dashboardDetails[wid.code]['filterBy']"></angular-table-component>           
          </div>
          <div i18n *ngIf="!dashboardDetails[wid.code]['show']" class="ovi-font-family" style="color: gray;text-align: center;opacity: 0.5;margin-top: 5px;"> No records available</div>
          <!-- LOCATION UTIL ASSET STATUS Asset Pending Calibration Monthly,Asset Status,Asset AMC Due Monthly chart -->
          <div class="grid-content" *ngIf="dashboardDetails[wid.code]['show'] && dashboardDetails[wid.code]['type'] == 'chart'">
            <canvas baseChart id={{wid.code}} width = "calc(100% - 5px)" height = "calc(100% - 10px)" 
              [type]="dashboardDetails[wid.code]['chartType']" [datasets]="dashboardDetails[wid.code]['datasets']"
              [labels]="dashboardDetails[wid.code]['labels']" [colors]="dashboardDetails[wid.code]['colors']"
              [options]="dashboardDetails[wid.code]['options']" >
            </canvas>
          </div>
          <!-- DYNAMIC CARD VIEW -->
          <div class="grid-content-widget" *ngIf="false">
            <mat-grid-list [cols]="dashboardDetails[wid.code]['maxCol']" rowHeight="100%" [gutterSize]="'15px'">
              <mat-grid-tile
                [colspan] = "dashboardDetails[wid.code]['detail'][0].col" [rowspan]= "1" [ngStyle]="{background: dashboardDetails[wid.code]['detail'][0]['bg-color']}" 
                style="border-radius: 10px;">
                  <mat-grid-tile-header i18n [ngStyle]="{background: 'transparent', color: dashboardDetails[wid.code]['detail'][0]['color']}" style="justify-content: center;font-size: 18px;font-weight: 600">{{dashboardDetails[wid.code]['detail'][0].title}}</mat-grid-tile-header>
                  <div *ngIf = "dashboardDetails[wid.code]['detail'][0].icon != ''">
                    <div style="float: left">
                      <img [src]="dashboardDetails[wid.code]['detail'][0].icon" [width] = "dashboardDetails[wid.code]['detail'][0].iconWidth" i18n-[matTooltip] [matTooltip]="dashboardDetails[wid.code]['detail'][0].label" style="float: left; margin-right:20px;object-fit: contain;" alt="asset-icon">
                    </div>
                    <div style="float: right;font-weight: 800;padding: 10px 10px;" [ngStyle]="{'font-size': dashboardDetails[wid.code]['detail'][0]['fontSize'], color: dashboardDetails[wid.code]['detail'][0]['fontColor']}"> 
                      {{dashboardDetails[wid.code]['detail'][0].data}}
                    </div>
                  </div>                
                  <div *ngIf = "dashboardDetails[wid.code]['detail'][0].icon == ''" style="font-weight: 600;" [ngStyle]="{'font-size': dashboardDetails[wid.code]['detail'][0]['fontSize'], color: dashboardDetails[wid.code]['detail'][0]['fontColor']}">
                    <div *ngFor = "let data of dashboardDetails[wid.code]['detail'][0].data.data; let i = index"> 
                      <span style="float: left;"> {{dashboardDetails[wid.code]['detail'][0].data.label[i] }}  </span>
                      <span style="float: right;"> &nbsp;&nbsp;&nbsp;&nbsp; : &nbsp;&nbsp;{{dashboardDetails[wid.code]['detail'][0].data.data[i] }} </span>
                    </div> 
                  </div>
              </mat-grid-tile>
            </mat-grid-list>
          </div>
          <!-- DYNAMIC CARD VIEW LAYOUT -->
        <div style="width: 100%; height: 100%;box-shadow: 0px 3px 6px #00000029;border-radius: 10px;" *ngIf="dashboardDetails[wid.code]['show'] && dashboardDetails[wid.code]['type'] == 'card' &&
        dashboardDetails[wid.code]['cardStyle']" class="ovi-font-family">
          <div i18n [ngStyle]="dashboardDetails[wid.code]['cardStyle']['header']">
            {{dashboardDetails[wid.code]['detail'][0].title}}
          </div>
          <div [ngStyle]="dashboardDetails[wid.code]['cardStyle']['content']">
            <div [ngStyle]="dashboardDetails[wid.code]['cardStyle']['con-label']">
              <div>
                <div *ngFor = "let data of dashboardDetails[wid.code]['detail'][0].data.data; let i = index" >
                  <span *ngIf="dashboardDetails[wid.code]['detail'][0].data.label[i] !== ''">{{dashboardDetails[wid.code]['detail'][0].data.label[i]}}</span>
                  <span *ngIf="dashboardDetails[wid.code]['detail'][0].data.data[i] !== ''">{{dashboardDetails[wid.code]['detail'][0].data.data[i]}}</span>
              </div>
              </div>
            </div>
            <div *ngIf="dashboardDetails[wid.code]['cardStyle']['con-icon-name']" [ngStyle]="dashboardDetails[wid.code]['cardStyle']['con-icon']">
              <span *ngIf="dashboardDetails[wid.code]['cardStyle']['con-icon-name']['img'] && dashboardDetails[wid.code]['cardStyle']['con-icon-name']['img'] !== ''" [ngStyle]="dashboardDetails[wid.code]['cardStyle']['con-circle']">
                <img [src]="dashboardDetails[wid.code]['cardStyle']['con-icon-name']['img']" alt="icon" [ngStyle]="dashboardDetails[wid.code]['cardStyle']['con-inner-icon']">
              </span>
              <span *ngIf="dashboardDetails[wid.code]['cardStyle']['con-icon-name']['icon'] && dashboardDetails[wid.code]['cardStyle']['con-icon-name']['icon'] !== ''" [ngStyle]="dashboardDetails[wid.code]['cardStyle']['con-circle']">
                <mat-icon [ngStyle]="dashboardDetails[wid.code]['cardStyle']['con-inner-icon']">{{dashboardDetails[wid.code]['cardStyle']['con-icon-name'].icon}}</mat-icon>
              </span>
            </div>
          </div>
          <div *ngIf="dashboardDetails[wid.code]['cardStyle']['footer']['text']" [ngStyle]="dashboardDetails[wid.code]['cardStyle']['footer']">{{dashboardDetails[wid.code]['cardStyle']['footer']['text']}}</div>
        </div>
          <!--Dynamic Header-->
          <div style="width: 100%; height: 100%; display: flex; justify-content: center;" [ngStyle]="{'align-items' : dashboardDetails[wid.code]['detail'][0]['alignItems']}" *ngIf="dashboardDetails[wid.code]['show'] && dashboardDetails[wid.code]['type'] == 'header'">
          <div style="width: 100%; height: 100%;">
            <div style="width: 100%; height: 100%;border-radius: 10px;display: flex; justify-content: flex-start; align-items: center;" [ngStyle]="{background: dashboardDetails[wid.code]['detail'][0]['bg-color']}">
              <!-- <div *ngIf="dashboardDetails[wid.code]['detail'][0].icon != ''" style="width: 20%;height: 100%; float: left;background: transparent;display: flex; justify-content: center; align-items: center;"> -->
                <img *ngIf="dashboardDetails[wid.code]['detail'][0].icon != ''" [src]="dashboardDetails[wid.code]['detail'][0].icon" [width] = "dashboardDetails[wid.code]['detail'][0].iconWidth" style="object-fit: contain;margin-left: 25px;" alt="asset-icon">
              <!-- </div> -->
              <!-- <div style="height: 100%; float: right; display: flex; justify-content: center; align-items: center;"  [ngStyle]="{width: dashboardDetails[wid.code]['detail'][0].icon != '' ? '80%' : '100%',background: 'transparent'}"> -->
                <h1 style="font-weight: 200;margin-left: 25px;" [ngStyle]="{background: 'transparent',color: dashboardDetails[wid.code]['detail'][0]['fontColor'], 'font-size': dashboardDetails[wid.code]['detail'][0]['fontSize']}">{{dashboardDetails[wid.code]['detail'][0].title}}</h1>
              <!-- </div> -->
            </div>
          </div>
          </div>
          <!-- Dynamic Form-->
          <div class="grid-content" style="width: 100%;height: 100%;" *ngIf="dashboardDetails[wid.code]['show'] && dashboardDetails[wid.code]['type'] == 'form'" [ngStyle]="dashboardDetails[wid.code]['formTemplateData']['css']">
            <app-form-component [formTemplateData] = "dashboardDetails[wid.code]['formTemplateData']" type = 'gridster' (formTemValue) = formTempValue($event)></app-form-component>
          </div>
        </gridster-item>
      </gridster>  
      <!-- <div style="height: 215px;width: 60px;position: absolute;right: 10px;float: right;z-index: 1000;top: 30%;"> -->
      <!-- side menu for add & modify widgets  -->
      <div class="dash-wid-option" *ngIf="(active_btn && (active_btn.indexOf('BT_DSHE') > -1)) && type =='popup'" (click)="isOpen = !isOpen" >
        <mat-icon class="dash-wid-arrow"[class.open]="isOpen">keyboard_arrow_left</mat-icon>
      </div>
      <div *ngIf="isOpen && type =='popup'" id="menu" [ngClass]="type!='popup'?'opt-layout-grid':'opt-layout-grid-pop'" style="width: 35px;position: absolute; right: 30px; z-index: 1000;cursor:pointer; background: orange; border-radius: 16px;float: right;">
        <mat-icon class="icon" i18n-matTooltip matTooltip="Refresh" style="margin-top: 7px;margin-left: 7px;font-size: 23px;" (click)="refreshLayout()">refresh</mat-icon>
        <img  *ngIf="type!='popup'" i18n-[matTooltip] [matTooltip]="isAutoRefresh ? 'Auto refresh Off' : 'Auto refresh On'" style = "width: 28px;padding-left: 4px;padding-top: 5px;"
        [ngStyle]="{ opacity : isAutoRefresh ? 0.4 : 2}" (click) = "autoRefresh(!isAutoRefresh)" src="/assets/Alert/common_icons/autorefresh.svg"/>
        <!-- <mat-icon class="icon" i18n-matTooltip matTooltip="Auto Refresh" style="margin-top: 7px;margin-left: 7px;font-size: 23px;" 
        (click) = "autoRefresh(!isAutoRefresh)">{{isAutoRefresh ? 'autorenew' : 'autorenew'}}</mat-icon> -->
        <!-- <mat-icon class="icon" i18n-matTooltip matTooltip="Add Widget" #toggleButton style="margin-top: 7px;margin-left: 7px;font-size: 23px;" [ngStyle]="{ color : (isOption == 'settings' && isOptionOpen === true) ? 'white' : 'black'}" (click)="isOptionOpen = !isOptionOpen;selectedOption('settings', isOptionOpen)">manage_accounts</mat-icon> -->
        <mat-icon *ngIf="type!='popup'" class="icon" i18n-matTooltip matTooltip="Widgets" #toggleButton style="margin-top: 7px;margin-left: 7px;font-size: 23px;" [ngStyle]="{ color : (isOption == 'widgets' && isOptionOpen === true) ? 'white' : 'black'}" (click)="isOptionOpen = !isOptionOpen; editWidget()">widgets</mat-icon>
        <mat-icon class="icon" i18n-matTooltip matTooltip="Align Widgets" style="margin-top: 7px;margin-left: 7px;font-size: 23px;" (click)="alignGrid()">horizontal_distribute</mat-icon>
        <mat-icon class="icon" i18n-[matTooltip] [matTooltip]="screenLock ? 'Unlock screen' : 'Lock screen'" (click) = "screenLock = !screenLock; gridsterConfiguration(screenLock)" style="margin-top: 7px;margin-left: 7px;font-size: 23px;">{{!screenLock ? 'lock_open' : 'lock'}}</mat-icon>
        <mat-icon class="icon" i18n-matTooltip matTooltip="Save Layout" style="margin-top: 7px;margin-left: 7px;font-size: 23px;" [ngClass]="isLayoutChanged ? 'dash-wid-save-enable' : 'dash-wid-save-disable'" (click)="isLayoutChanged ? saveWidgets(this.layout, true) : ''">save</mat-icon>
      </div>
      <!-- <div *ngIf="isOption == 'settings'" #setting class="dash-add-wid">
      </div> -->
      <!-- </div> -->
  </div>
  
  
  
