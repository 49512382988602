<div style="height: calc(100% - 60px);">
    <mat-toolbar style="background: #4e88ff;color: white;height: 45px;">
        <div style="width : calc( 100% - 35px);font-size:20px; font-weight:600">Create Dynamic Form</div>
        <button mat-icon-button mat-dialog-close> <mat-icon style="font-size: 35px;">highlight_off</mat-icon></button>
    </mat-toolbar>
    <mat-tab-group (selectedTabChange)="onTabChanged($event)">
        <mat-tab label="Basic">
            
        </mat-tab>
        <mat-tab label="Preview">
            <div *ngIf="enableFrmCmp">
                <!-- <app-form-component></app-form-component> -->
            </div>
        </mat-tab>
    </mat-tab-group>
</div>