/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter, OnDestroy, Inject, ViewChild } from '@angular/core';
import * as L from 'leaflet';

import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { connect, IClientOptions, MqttClient } from 'mqtt';
import { HospitalService } from '../../../services/hospital.service';
import { CommonService } from '../../../services/common.service';
import { ReportService } from '../../../services/report.service';
import { DashboardService } from '../../../services/dashboard.service';

import { ConfigurationService } from '../../../services/configuration.service';
import { environment } from './../../../../../environments/environment';
import './../../../../../assets/script/moving-marker.js';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';

import { PatientInfoComponent } from './../../entry-component/patient/patient.component';
export interface Foo {
    bar: string;
}
@Component({
    selector: 'app-common-search',
    templateUrl: './common-search.component.html',
    styleUrls: ['./common-search.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CommonSearchComponent implements OnInit {
    // displayedColumns: string[] = ['name', 'mainidentifier', 'Gender', 'Age', 'mobileNumber', 'visit_type', 'lastVisitDate', 'currentLocation'];
    // displayedColumns: string[] = ['tagID'];
    public displayedColumns : any;
    public displayedData : any;
    patientColumns: string[] = ['category', 'name', 'mainidentifier','mobileNumber' ,'Gender', 'Age'];
    patientData = [
      { 'colName': 'category', 'title': 'Category', 'dataName': 'category' },
      { 'colName': 'name', 'title': 'Name', 'dataName': 'name' },
      { 'colName': 'mainidentifier', 'title': 'UHID', 'dataName': 'mainidentifier' },
      { 'colName': 'mobileNumber', 'title': 'Mobile', 'dataName': 'mobileNumber' },
      { 'colName': 'Gender', 'title': 'Gender', 'dataName': 'gender' },
      { 'colName': 'Age', 'title': 'Age', 'dataName': 'age' },
    ];
    assetColumns : string [] = ['category','name', 'tagType', 'serialNumber', 'currentLocation']
    assetData = [
      { 'colName': 'category', 'title': 'Category', 'dataName': 'category' },
      { 'colName': 'name', 'title': 'Asset Type', 'dataName': 'name' },
      { 'colName': 'tagType', 'title': 'Tag Type', 'dataName': 'tagType' },
      { 'colName': 'serialNumber', 'title': 'Tag Serial Number', 'dataName': 'tagSerialNumber' },
      { 'colName': 'currentLocation', 'title': 'Current Location', 'dataName': 'currentLocation' },

    ];
    dataSource: MatTableDataSource<any>;

    public selectedName: any;
    public rowData: any = [];
    public activate_btn: any = [];
    public filtervalue: string;
    public currentDate: any = new Date();
    public selectedDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    public maxHeight: any;
    public height: any;

    @Input() singleChildRowDetail: boolean;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(private hospitalService: HospitalService, public reportService: ReportService, public datepipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        public commonService: CommonService, private dashboardService: DashboardService,
        public thisDialogRef: MatDialogRef<CommonSearchComponent>,
    ) {
        this.activate_btn = this.commonService.getActivePermission('button');
        this.filtervalue = data;

        // this.getSearchData(id);
    }

    ngOnInit() {
        // this.getAllSearchResults();
        console.log('Common Search DATA ::::::: ', this.data);
        if(this.data['keys'].length > 0){
          if(this.data['keys'].includes("Asset")){
            this.displayedColumns = this.assetColumns;
            this.displayedData = this.assetData;
          }else {
            this.displayedColumns = this.patientColumns;
            this.displayedData = this.patientData;
          }
        } else {
          this.displayedColumns = this.patientColumns;
          this.displayedData = this.patientData;
        }
        this.dataSource = new MatTableDataSource<any>(this.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        // this.getHcPatientList(date);
        this.gethcDetails(this.selectedDate);

        
        }

        onWindowResized(size) {
          this.height = size;
        }

    ngAfterViewInit() { }

    //     getAllSearchResults() {
    //     this.commonService.getAllSearchResults().subscribe(res => {
    //         this.dataSource = res.results;
    //         this.globalSearchData = res.results;
    //         this.dataSource.paginator = this.paginator;
    //         this.dataSource.sort = this.sort;
    //     });
    // }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }
    rowClick(data) {
        this.selectedName = data.id;
    }
    patientInfo(data) {
        console.log('calling patient info');
        data['type'] = '1';
        this.dialog.open(PatientInfoComponent,
            { data: data, panelClass: ['medium-popup'], disableClose: true });
    }

    gethcDetails(dateValue) {
        this.selectedDate = dateValue;
        this.commonService.getHcPatientList(this.selectedDate).subscribe((res) => {
            //   let filterData = res.results.data.filter(val=> this.datepipe.transform(new Date(val.visit_date), 'yyyy-MM-dd') == this.selectedDate)
            //   this.currentDate = new MatTableDataSource(filterData);
            // const data = res.results;
            this.dataSource.paginator = this.paginator;
        });
    }
    globalSearch(option) {
		console.log('O B J E C T VALUES  ', option);
		this.dashboardService.getTagLoc(option.tagSerialNumber).subscribe(
			(res) => {
                const data = res.results;
                console.log('D A T A :::::::: ', data);

		});
	}
}

