<!-- <div fxFlex class="input-component-holder" style="height: 100%;"> -->
    <div appWindowResize (resized)="onWindowResized($event)" (resizedWidth)="onWindowResizedWidth($event)" [component]="'pop-up'"
    [style.height.px]="popHeight" [style.width.px]="popWidth">
    <mat-toolbar *ngIf="this.data" class="input-header block-1" style="height: 35px;">
        <div i18n class="header-name close-align-map" style="text-overflow: ellipsis;"> Current Location</div>
        <div class="header-close"><button mat-icon-button mat-dialog-close close-button class="ie-close-icon">
            <mat-icon class="icon-style">highlight_off</mat-icon>
        </button></div>
    </mat-toolbar>
    <div class="leaflet-view" style="overflow: auto;" [style.height.px]="this.contentHeight">
        <div id="locationMap" class="leaflet-map-size"></div>
    </div>
    <div class="block-7 input-footer-pad" style="width: 97%;padding-top: 0px;">
        <button i18n mat-button class="cancle-btn" style=" margin-right: 5px;" mat-dialog-close>Cancel</button>
        <button i18n mat-button class="active-btn" appDebounceClick style="margin-top: 0px;"
        [debounceTime]="500" (debounceClick)="commitLatLongClose(this.searchLatLang)" *ngIf="commitLatLong.length > 0">GET LOCATION</button>
    </div>
</div>
