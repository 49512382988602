/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Component, OnInit, Input, Output, EventEmitter, Inject, ViewEncapsulation, HostListener, ElementRef } from '@angular/core';
import { OvitagComponent } from '../../../../ovitag/ovitag.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService, CommonService, HospitalService, UpdatePasswordModel } from '../../..';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import * as screenfull from 'screenfull';
import { EditProfile } from './add-profile.model';
import { PasswordValidation } from '../password-validators';
import { ErrorStateMatcherService } from '../../../services/error-state-matcher.service';
import { ToastrService } from 'ngx-toastr';



@Component({
    selector: 'app-add-profile',
    templateUrl: './add-profile.component.html',
    styleUrls: ['./add-profile.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class AddProfileComponent implements OnInit {

    // public createProfile: CreateProfile;
    public editProfile: EditProfile;

    public profileForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    url = '';
    public imageData: any = '/assets/images/sample1.png';
    isVisible = true;

    show = false;
    buttonName = 'Edit';
    hide: any;
    edit: false;

    public username: string;
    public phone: string;
    public email: string;
    public user: any;

    constructor(private form: UntypedFormBuilder,
        private router: Router,
        private commonService: CommonService,
        private hospitalServices: HospitalService,
        public toastr: ToastrService,
        public snackbar: MatSnackBar,
        public thisDialogRef: MatDialogRef<any>,
        //   private errorService: ErrorService
    ) {
        this.buildForm();
        this.getCurrentUser();

    }

    ngOnInit() {
        // this.getCurrentUser();

    }

    showVerify() {
        // alert ('Checking...........!!!!!!!!!!!!!')
        this.isVisible = true;
    }

    public buildForm() {
        this.profileForm = this.form.group({
            firstName: ['', [Validators.required]],
            id: [''],
            address: [''],
            email: [''],
            // email: ['', Validators.required, Validators.email],
            // phoneNumber: ['', [Validators.required, Validators.pattern('[6789][0-9]{9}')]],
            phoneNumber: ['', [Validators.required, Validators.pattern('[6789][0-9]{9}')]],
        });
    }

    public editUser() {

        // alert('Current ID :  '+this.profileForm.value.id)

        this.editProfile = new EditProfile(null, null, null, null, null);

        this.editProfile.id = this.profileForm.controls['id'].value;
        this.editProfile.firstName = this.profileForm.controls['firstName'].value;
        this.editProfile.address = this.profileForm.controls['address'].value;
        this.editProfile.phoneNumber = this.profileForm.controls['phoneNumber'].value;
        this.editProfile.email = this.profileForm.controls['email'].value;
        // console.log(this.editProfile)
        // alert(JSON.stringify(this.editProfile));

        this.hospitalServices.editUser(this.editProfile).subscribe(res => {
            // this.snackbar.open(`${res.message}`, 'Close', {
            //   duration: 3000,
            // });
            this.toastr.success('Success', `${res.message}`);
            this.thisDialogRef.close('confirm');
        },
            error => {
                console.log('Error ---------->', error);
                this.toastr.error('Error', `${error.error.message}`);
            });
    }

    getCurrentUser() {
        this.commonService.getCurrentUser().subscribe(res => {
            const user = res.results;
            this.profileForm.reset(
                {
                    firstName: user.firstName,
                    id: user.id,
                    address: user.address,
                    email: user.email,
                    phoneNumber: user.phoneNumber
                }
            );
            // console.log(this.user)
        });
    }

    onSubmit() {

    }


    uploadImage($event): void {
        this.readImage($event.target);
    }
    readImage(inputValue: any): void {
        const file: File = inputValue.files[0];
        const uploadImage: FileReader = new FileReader();
        uploadImage.onloadend = (e) => {
            this.imageData = uploadImage.result;
        };
        uploadImage.readAsDataURL(file);
    }
}

@Component({
    selector: 'app-view-profile',
    templateUrl: './view-profile.component.html',
    styleUrls: ['./view-profile.component.scss']
})

export class ViewProfileComponent implements OnInit {
    public user_info: any = [{
        'username': '',
        'userName': '',
        'employeeId': '',
        'birthDate': '',
        'email': '',
        'phoneNumber': '',
        'address': ''
    }];
    constructor(private commonService: CommonService) {
        this.commonService.getCurrentUser().subscribe(res => {
            console.log(res.results);
            let loggedInUserName = res.results['firstName'];
            if (res.results['lastName'] != null) {
                loggedInUserName += ' ' + res.results['lastName'];
            }
            let loggedInUserRole = '';
            if (res.results['roles'].length > 0) {
                loggedInUserRole = res.results['roles'][0]['name'];
            }
            this.user_info = [{
                'username': loggedInUserName,
                'userRole': loggedInUserRole,                                   // Role
                'employeeId': res.results['employeeId'],
                'birthDate': res.results['birthDate'],
                'email': res.results['email'],
                'phoneNumber': res.results['phoneNumber'],
                'address': res.results['address']
            }];
            console.log(this.user_info);
        }, error => {
            console.log('user details not get...');
        });
    }
    ngOnInit() {
    }
}

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password-component.html',
    styleUrls: ['./change-password-component.scss']
})

export class ChangePasswordComponent implements OnInit {
    public passwordForm: UntypedFormGroup;
    public updatePasswordModel: UpdatePasswordModel;
    public email: string;
    public password: string;
    public hide: boolean = false;
    public matcher = new ErrorStateMatcherService();
    username = localStorage.getItem(btoa('current_user')); // current_user

    constructor(private fb: UntypedFormBuilder, private dialog: MatDialog,
        public thisDialogRef: MatDialogRef<ChangePasswordComponent>, public commonService: CommonService,
        // @Inject(MAT_DIALOG_DATA) public data: any,
        public toastr: ToastrService, public snackbar: MatSnackBar, private router: Router) {
    
        this.buildForm();
      }

    ngOnInit(){

    }
    public buildForm(){
        
    this.passwordForm = this.fb.group({
        'oldPassword': ['', [Validators.required]],
        'newPassword': ['', [Validators.required]],
        'cnfrmNewPassword': ['', [Validators.required]],
      }, { validator: PasswordValidation.MatchPassword }
      );
    }

    logout() {
        this.username = '';
        const landingPage = window.location.pathname.replace('/en', '');
        console.log(landingPage);
    
        this.commonService.validateUserPreference('landingPage', landingPage);
        
        // full_screen_mode(userpreference start)
        this.commonService.validateUserPreference('fullscreenMode', screenfull.isFullscreen);
        // full_screen_mode(userpreference end)
    
        // this.common.userPreference = null;
    
        // if (this.cookieService.check(
        //   'landing_page_' + localStorage.getItem(btoa('facilityId')) + '_' +
        //   localStorage.getItem(btoa('userId'))
        // )) {
        //   this.cookieService.delete('landing_page_' + localStorage.getItem(btoa('facilityId')) + '_' +
        //   localStorage.getItem(btoa('userId')));
        //   this.cookieService.set('landing_page_' + localStorage.getItem(btoa('facilityId')) + '_' +
        //   localStorage.getItem(btoa('userId')), landingPage);
        // } else {
        //   this.cookieService.set('landing_page_' + localStorage.getItem(btoa('facilityId')) + '_' +
        //   localStorage.getItem(btoa('userId')), landingPage);
        // }
    
        //full_screen_mode_ (cookies start)
        // if (this.cookieService.check(
        //   'full_screen_mode_' + localStorage.getItem(btoa('facilityId')) + '_' +
        //   localStorage.getItem(btoa('userId'))
        // )) {
        //   this.cookieService.delete('full_screen_mode_' + localStorage.getItem(btoa('facilityId')) + '_' +
        //   localStorage.getItem(btoa('userId')));
        //   this.cookieService.set('full_screen_mode_' + localStorage.getItem(btoa('facilityId')) + '_' +
        //   localStorage.getItem(btoa('userId')), screenfull.isFullscreen);
        // } else {
        //   this.cookieService.set('full_screen_mode_' + localStorage.getItem(btoa('facilityId')) + '_' +
        //   localStorage.getItem(btoa('userId')), screenfull.isFullscreen);
        // }
        //full_screen_mode_ (cookies end)
        // remove user from local storage to log user out
        localStorage.clear();
        if (screenfull.isFullscreen) {
          screenfull.exit();
        }
        this.router.navigate(['/login']);
    }

    public resetPassword() {
        this.updatePasswordModel = new UpdatePasswordModel(null, null);
        this.updatePasswordModel.oldPassword = this.commonService.encryptData(this.passwordForm.controls['oldPassword'].value);
        this.updatePasswordModel.newPassword = this.commonService.encryptData(this.passwordForm.controls['newPassword'].value);
        this.commonService.changePassword(this.updatePasswordModel).subscribe(
          (result) => {
            if (result.statusCode === 1) {
              this.toastr.success('Success', `${result.message}`);
              this.thisDialogRef.close('confirm');
              this.logout();
            }
          },
          error => {
             this.toastr.error('Error', `${error.error.message}`);
          }
        );
    }
    
}
