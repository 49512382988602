import { Component, ElementRef, ViewChild, ViewEncapsulation,HostListener } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith, debounceTime } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '../../../services/common.service';
import { DashboardService } from '../../../services/dashboard.service';
import { PatientInfoComponent } from '../patient/patient.component';
import { CommonDialogComponent } from '../../entry-component/common-dialog-component/common-dialog.component';
import { CommonSearchComponent } from '../common-search/common-search.component';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-globalsearch-component',
    templateUrl: './globalsearch.component.html',
    styleUrls: ['./globalsearch.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class GlobalSearchComponent {
    /* search bar */
    visible = true;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    seachCtrl = new UntypedFormControl();
    filteredKeys: Observable<string[]>;
    public subject : Subject<any> = new Subject();
    keys: string[] = [];
    filterOptions: any[] = [
        "Today",
        "Patient",
        "Asset",
        "User"
    ];
    selectedKeyCodes: any = { 'Today': 'today', 'Patient': 'patient', 'Asset': 'asset', 'User': 'user', 'Infant': 'infant', 'Doctor': 'doctor' };
    passKeys: any[] = [];
    public activate_btn: any = [];
    opened = false;
    @ViewChild('searchValue') searchValue: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    @ViewChild('autocompleteTrigger') matACTrigger: MatAutocompleteTrigger;

    // *********** //
    /* search list*/
    searchlist: any = null;
    public searchClose = false;
    public searchKey = 'All';
    public openAutocom = false;
    public clearSearch = false;  
    public typeInput = true;
    public isShowSearch = false;
    public lastSearch = '';
    @HostListener('document:click', ['$event', '$event.target'])
    onClick(event: Event, targetElement: HTMLElement) {
  
      // console.log("onClick", targetElement)
      if (!targetElement) {
        return;
      }
      if(this.filterOptions.includes(event.target['outerText'])){
        if(this.keys.length > 0){
          this.seachCtrl.setValue(this.lastSearch)
        } 
        else{
          this.seachCtrl.setValue(this.lastSearch)
        }
      } else{
        this.seachCtrl.setValue(null);
        this.lastSearch = '';
      }
      const clickedInside = this.elementRef.nativeElement.contains(targetElement);
      // console.log(clickedInside)
      if (!clickedInside && this.isShowSearch) {
        this.searchlist = null;
        this.openAutocom = false;
        this.matACTrigger.closePanel();
        this.typeInput = true;
      }
    }

    // *********** //

    constructor(private commonService: CommonService,
        public dialog: MatDialog,private dashboardService: DashboardService,
        private elementRef: ElementRef,public toastr: ToastrService) { 
          this.checkUserPreference();
          this.activate_btn = this.commonService.getActivePermission('button');
          }
    ngOnInit() { 
      this.subject.pipe(debounceTime(600)).subscribe(searchTextValue => {
        this.getSearch(searchTextValue);
      });
    }
    getUserPreference() {
        let preference = this.commonService.userPreference;
        let postData = {
          'key':"globalSearch",
          'roleId': localStorage.getItem('userlevel'),
          'userId': localStorage.getItem(btoa('userId')),
          'value':  this.keys.toString()
        }
        if(preference != null) {
          if(preference.hasOwnProperty('globalSearch')) {
              let id = preference.globalSearch.id;
              this.commonService.updateUserPreference(id, postData).subscribe(res=> {
                this.commonService.userPreference = res.results;
              });
            } else {
                this.commonService.saveUserPreference(postData).subscribe(res=> {
                this.commonService.userPreference = res.results;
              });
            }
        }
    }
    checkUserPreference() {
          let roleId = localStorage.getItem('userlevel');
          let userId = localStorage.getItem(btoa('userId'));
          this.commonService.getPreference(userId, roleId).subscribe(res => {
              let preference = res.results;
              if (preference != null && preference.hasOwnProperty('globalSearch')) {
                if(preference.globalSearch.value != null && preference.globalSearch.value != ''){
                  let value = preference.globalSearch.value.split(",")
                  this.keys = value;
                  this.selectedKeys()
                }
              }
          });
    }
    openSearch(){
      this.isShowSearch = true;
    }
    remove(key: string): void {
        const index = this.keys.indexOf(key);

        if (index >= 0) {
            this.keys.splice(index, 1);
        }
        this.selectedKeys();
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const newValue = event.option.viewValue;
        if (event.option.viewValue == 'Today') {
            this.searchKey = 'All'
        }
        if (this.keys.includes(newValue)) {
            this.keys = [...this.keys.filter(key => key !== newValue)];
        } else {
            if(event.option.viewValue == 'Today'){
            this.keys.push(event.option.viewValue);
            } else{
            this.keys.push(event.option.viewValue);
            this.keys = this.keys.filter(key => key === 'Today' || key === newValue)
            }
        }
        this.selectedKeys();
        this.searchValue.nativeElement.value = '';
        this.getUserPreference();
        // this.seachCtrl.setValue(null);

        // keep the autocomplete opened after each item is picked.
        requestAnimationFrame(() => {
            this.openAuto(this.matACTrigger);
        })

    }
    selectedKeys() {
        this.passKeys = [];
        for (let i = 0; i < this.keys.length; i++) {
            let keyCode = this.selectedKeyCodes[this.keys[i]];
            this.passKeys.push(keyCode)
        }
        if(this.lastSearch != ''){
            this.searchInput(this.lastSearch)
        } 
        // else{
        //   this.typeInput = true;
        //   this.clearSearch = false;
        //   this.searchlist = null;
        // }
    }
    inputText(event) {
      this.subject.next(event);
    }
    getSearch(text) {
      this.searchClose = false;
      this.lastSearch = text;
      if (text === '') {
          this.typeInput = true;
          this.clearSearch = false;
          this.searchlist = null;
      } else {
          this.searchInput(text)
      }
    }
    searchInput(event) {
          let key = '';
          if(this.passKeys.length > 0){
            key = this.passKeys.join();
          }
          if(event.length >= 3){
          this.commonService.getSearchDataWithKey(event, key).subscribe(res => {
            if(res.results.length !== 0){
              this.searchlist = res.results;
              this.typeInput = false;
              this.clearSearch = false;
            } else{
              this.searchlist = 'empty';
              this.typeInput = false;
              this.clearSearch = true;
            }
          })
          } else{
            this.typeInput = true;
            this.clearSearch = false;
            this.searchlist = null;
          }
    }
    openAuto(trigger: MatAutocompleteTrigger) {
        trigger.openPanel();
        this.searchValue.nativeElement.focus();
        this.openAutocom = true
        // console.log(trigger);
    }
    clearDropdown(value) {
      if (value === '') {
        this.searchlist = 'empty';
        this.clearSearch = true;
      }
      else {
        const searchList = this.searchlist;
        this.searchlist = searchList;
        this.clearSearch = false;
      }
    }
    getSearchResult(data){
      if(data.category == 'Patient'){
        this.openAutocom = false;
        this.matACTrigger.closePanel();
          data['type'] = '1';
          data['patinetVisitId'] = data.patientVisitId;
          data['patientId'] = data.id;
          data['token_no'] = data.tokenNo;
          // temporary stop the event action for max demo by venkatesh Jan 10, 2022
          // this.dialog.open(PatientInfoComponent,
          //   { data: data, maxWidth: '95vw', panelClass: ['custom-dialog-container','popup-dialog-container2'], disableClose: true });
      } else {
        if(data.tagSerialNumber != null && data.floorId != null){
        this.openAutocom = false;
        this.matACTrigger.closePanel();
        data['type'] = 'globalSearch';
        this.dialog.open(CommonDialogComponent,
          { data: data, panelClass: ['medium-popup'], disableClose: true });
        } else{
          this.toastr.warning('Warning', data.category + ` currently not available !!`);
        }
      }
    }
    patientInfo(data) {
      this.openAutocom = false;
      this.matACTrigger.closePanel();
        // if (data.tagType == 'Patient' && data.patientVisitId != null) {
        data['type'] = '1';
        data['patinetVisitId'] = data.patientVisitId;
        data['patientId'] = data.id;
        data['token_no'] = data.tokenNo;
        this.dialog.open(PatientInfoComponent,
          { data: data, panelClass: ['medium-popup'], disableClose: true });
        // }
    }  
    assetInfo(data){
      if(data.tagSerialNumber != null && data.floorId != null){
        this.openAutocom = false;
        this.matACTrigger.closePanel();
        this.dialog.open(CommonDialogComponent,
          { data: data, panelClass: ['medium-popup'], disableClose: true });
      }
    }
    globalSearch(option) {
      this.openAutocom = false;
      this.matACTrigger.closePanel();
      this.searchlist['keys'] = this.keys;
      if(this.keys.length == 0){
        let categoryFilter = this.searchlist.filter(res => res.category == "Asset")
        if(categoryFilter.length == this.searchlist.length){
          this.searchlist['keys'] = ['Asset']
        }
      }
        // if (!this.isClicked) {
        //   this.isClicked = true;
          // this.dashboardService.getTagLoc(option.tagSerialNumber).subscribe(
          //   (res) => {
              const dialogRef = this.dialog.open(CommonSearchComponent,
                { data: this.searchlist, panelClass: ['medium-popup'], disableClose: true });
              dialogRef.afterClosed().subscribe(result => {
                // this.isClicked = false;
              });
            // });
        // }
      }
    ngDoCheck() {
        if(this.keys.find(val=> val == "Today") == undefined){
            this.searchKey = 'All';
        }
        if(this.openAutocom && this.matACTrigger !== undefined){
          this.matACTrigger.openPanel();
        }
    }
}