<div fxFlex class="popup-container" appWindowResize (resized)="onWindowResized($event)" [component]="'alertEntry'" [style.max-height.px]="this.height">
  <mat-toolbar class="popup-header">
    <div class="popup-title">Alert</div>
    <div class="popup-close"><button mat-icon-button mat-dialog-close close-button class="ie-close-icon">
        <mat-icon class="icon-style">highlight_off</mat-icon>
      </button></div>
  </mat-toolbar>
  <div class="popup-input-container" style="overflow: hidden;">
    <div class="mrgn-styl">
      <div class="manage-action parent">
          <mat-form-field style="width: 150px;">
            <input matInput (keyup)="applyFilter($event.target.value)"
              placeholder="Filter" autocomplete="off" role="presentation">
          </mat-form-field>

          <div class="child">
            <span class="tw-filter-label" [formGroup]="alertFilterForm" style="margin-left: 557px;">
              <mat-label class="tw-filter-label1" style="height: 14px;">Type</mat-label>
              <mat-select class="tw-filter-label2" i18n-placeholder placeholder="" style="width: 150px;"
                formControlName="alertType" (selectionChange)="typeFilter($event.value)">
                <mat-option [value]="alertType">All</mat-option>
                <mat-option *ngFor="let type of ruleType" [value]="type.code">
                  {{type.value}}
                </mat-option>
              </mat-select>
            </span>
            <!-- <form [formGroup]="alertFilterForm" class="rule_type">
              <span><label class="label_alert" id="#box1">TYPE</label></span>
              <span id="#box2">

                <select class="select_alert_entry" formControlName="alertType" (change)="typeFilter($event.target.value)">
                  <option [value]="alertType">All</option>
                  <option *ngFor="let type of ruleType" [value]="type.code">
                    {{type.value}}
                  </option>
                </select>
              </span> -->

              <!-- <span><label class= "label_alert" id="#box1"  style="color: #92A1B1; background-color: #F2F7FA;">FROM DATE</label></span>
          <span id="#box2" class="picker-styl outer-box">
            <mat-form-field class="picker-styl" appearance="none">
              <input class="input_alert_entry" matInput [matDatepicker]="fromDate" placeholder=" " (dateChange)="showToDate($event)"
                formControlName="fromDate">
              <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
              <mat-datepicker #fromDate></mat-datepicker>
            </mat-form-field>
          </span>

          <span [class.disabled-content]="!isDisabledContent"><label class= "label_alert" id="#box1" style="color: #92A1B1; background-color: #F2F7FA;">TO DATE</label></span>
          <span id="#box2" id="box2" class="picker-styl outer-box" [class.disabled-content]="!isDisabledContent">
            <mat-form-field class="picker-styl" appearance="none">
              <input class="input_alert_entry" matInput [matDatepicker]="toDate" placeholder=" " (dateChange)="filterData($event)"
                formControlName="toDate">
              <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
              <mat-datepicker #toDate></mat-datepicker>
            </mat-form-field>
          </span> -->
            <!-- </form> -->
        </div>
        <!-- // TO Compare DATES refer this  link:

https://stackoverflow.com/questions/46181312/angular-form-validation-compare-two-fields/46183057#46183057 -->

     
      </div>

    
      <div fxLayout="column" class="mat-elevation-z4 manage-component table-h-50" [ngClass.xs]="'components-container-xs'"
      fxLayoutGap="5px" style="max-height: 400px;">
        <div style="overflow: auto;">
          <!-- <div class="mat-table-overflow" style="min-height: 100px; overflow: auto;"> -->
          <mat-table #table [dataSource]="dataSource" matSort>
            <ng-container *ngFor="let col of displayedData" matColumnDef="{{col.colName}}">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="manage-table-header"
                style="justify-content: flex-start;"> {{col.title}}
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <span *ngIf="col.colName == 'Time'">{{element[col.dataName] | date: 'shortTime'}}</span>
                <span *ngIf="col.colName != 'Time'">{{element[col.dataName]}}</span>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;" class="manage-table-header-row">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)=rowClick(row)
              (toggleChange)="onToggleChange($event,row)">
            </mat-row>

          </mat-table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 15, 20, 50, 100]" showFirstLastButtons class="tw-paginator-sticky"></mat-paginator>
        <!-- <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="[10, 15, 20, 50, 100]"
          (page)="pageEvent = getAllAlert($event);" showFirstLastButtons></mat-paginator> -->
      </div>
    </div>
  </div>
</div>
