<div class="ovi-font-family" style="height: calc(100% - 6px);">
    <mat-toolbar class="input-header" [ngStyle]="{'background': customerName === 'kyn' ? '#D61820' : ''}">
        <div i18n class="header-name" *ngIf="!data && data.type !== 'student'">Create User</div>
        <div i18n class="header-name" *ngIf="data && data.type !== 'student'">Modify User</div>
        <div i18n *ngIf="data.type === 'student'" class="header-name close-align">Student Enroll</div>
        <div class="header-close"><button mat-icon-button mat-dialog-close close-button class="ie-close-icon">
                <mat-icon class="icon-style">highlight_off</mat-icon>
            </button></div>
    </mat-toolbar>

    <div class="mat-custom-form-field-box  popup-margin" [formGroup]="userForm"  style="overflow: hidden; height: calc(100% - 100px);">
        <mat-tab-group *ngIf="this.customerName === null" style="height: 100%;" [selectedIndex]=selectedIndex (selectedTabChange)="tabClick($event)">
            <mat-tab i18n-label label="Summary">
                <div class="form-row block-3">
                    <div class="row-field-5"  *ngIf="data.type !== 'student'">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Select Account</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <mat-select i18n-placeholder placeholder="Select Account" (selectionChange)="getRegionList($event.value)"
                                formControlName="customerName"[disableOptionCentering]="true" [disabled]="data.type === 'task'">
                                    <mat-option *ngFor="let cust_list of customerList" [value]="cust_list.id">
                                        {{cust_list.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field-5"  *ngIf="data.type !== 'student'">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Select Region</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <mat-select i18n-placeholder placeholder="Select Region" (selectionChange)="getFacilityList($event.value)"
                                formControlName="regionName"[disableOptionCentering]="true" [disabled]="data.type === 'task'">
                                    <mat-option *ngFor="let region of regionList" [value]="region.id">
                                        {{region.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row-field-5" *ngIf="data.type !== 'student'">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Facility Name <span class="mandatory-field">*</span></mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <mat-select i18n-placeholder placeholder="Facility Name*" formControlName="customerId"
                                [disableOptionCentering]="true" [errorStateMatcher]="matcher" [disabled]="data.type === 'task'">
                                    <mat-option *ngFor="let Facility of facilityList" [value]="Facility.id">
                                        {{Facility.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error i18n *ngIf="userForm.controls.customerId.invalid">
                                    Facility Name is required
                                </mat-error>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="row-field-5" *ngIf="data.type !== 'student'">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Role <span class="mandatory-field">*</span></mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <mat-select i18n-placeholder placeholder="Role *" formControlName="roleIds"
                                [disableOptionCentering]="true" [errorStateMatcher]="matcher" [disabled]="data.type === 'task'">
                                    <mat-option *ngFor="let role of roleList" [value]="role.id"  (onSelectionChange)="roleSelect(role.code)">
                                        {{role.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error i18n *ngIf="userForm.controls.roleIds.invalid">
                                    Role is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row-field-5">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">First Name <span class="mandatory-field">*</span></mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input matInput i18n-placeholder placeholder="First Name" formControlName="firstName"
                                    [errorStateMatcher]="matcher" autocomplete="off" role="presentation" [readonly]="data.type === 'task'">
                                    <mat-error *ngIf="userForm.controls.firstName.invalid">
                                    <span i18n [hidden]="!userForm.controls['firstName'].errors.required">First name is required.</span>
                                    <span i18n [hidden]="!userForm.controls['firstName'].errors.minlength">Minimum allowed characters are 3 </span>
                                    <span i18n [hidden]="!userForm.controls['firstName'].errors.maxlength">Maximmum allowed characters are 25 </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field-5">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Last Name <span class="mandatory-field">*</span></mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input matInput i18n-placeholder placeholder="Last Name" formControlName="lastName"
                                    [errorStateMatcher]="matcher" autocomplete="off" role="presentation" [readonly]="data.type === 'task'">
                                <mat-error *ngIf="userForm.controls.lastName.invalid">
                                    <span i18n [hidden]="!userForm.controls['lastName'].errors.required">Last name is
                                        required.</span>
                                    <span i18n [hidden]="!userForm.controls['lastName'].errors.minlength"> Minimum allowed
                                        character
                                        is 1 </span>
                                    <span i18n [hidden]="!userForm.controls['lastName'].errors.maxlength"> Maximmum allowed
                                        characters
                                        are 25 </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row-field-5">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Gender</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <mat-select i18n-placeholder placeholder="Gender" formControlName="gender"[disableOptionCentering]="true"
                                    [errorStateMatcher]="matcher" [disabled]="data.type === 'task'">
                                    <mat-option *ngFor="let gender of genderList" [value]="gender.code">
                                        {{gender.value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row-field-5">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Email <span class="mandatory-field">*</span></mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input matInput i18n-placeholder placeholder="Email" formControlName="email" (blur)="setEmailValue(true)"
                                    [errorStateMatcher]="matcher" autocomplete="off" role="presentation" [readonly]="data.type === 'task'">
                                <mat-error *ngIf="userForm.controls.email.invalid">
                                    <span i18n [hidden]="!userForm.controls['email'].errors.required">
                                        E-mail is required.
                                    </span>
                                    <span i18n [hidden]="!userForm.controls['email'].errors.email">
                                        Invalid E-mail
                                    </span>
                                    <span i18n [hidden]="!userForm.controls['email'].errors.emailExists">
                                        Sorry, E-mail already exists!
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div  class="row-field-5">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Birth Date (DD/MM/YYYY)</mat-label>
                            <mat-form-field i18n-hintLabel hintLabel="e.g. 30/10/1990" class="example-full-width form-field"appearance="outline">
                                <input matInput [matDatepicker]="picker" i18n-placeholder placeholder="Birth Date (DD/MM/YYYY)"
                                    formControlName="birthDate" [max]="today" [errorStateMatcher]="matcher" autocomplete="off"
                                    [readonly]="data.type === 'task'">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker [disabled]="data.type === 'task'"></mat-datepicker>

                                <mat-error *ngIf="userForm.controls.birthDate.invalid">
                                    <span i18n [hidden]="!userForm.controls['birthDate'].errors.required">
                                        DOB is required Or Invalid DOB
                                    </span>
                                    <span i18n [hidden]="!userForm.controls['birthDate'].errors.pattern">
                                        Invalid DOB
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="enablePhoneNumber === true" class="row-field-cod">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Code  <span class="mandatory-field">*</span></mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input matInput i18n-placeholder placeholder ="Code *" matInput
                                formControlName="countryCode" [matAutocomplete]="countryCode" [readonly]="data.type === 'task'">
                                <mat-autocomplete #countryCode="matAutocomplete"  panelWidth = "250px">
                                    <mat-option *ngFor="let country of countryOptions | async" value={{country.code}} appTooltip [tooltipText]="country.value" placement="bottom">
                                        {{country.code}} ({{country.value}})
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="userForm.controls.countryCode.invalid">
                                    <span i18n [hidden]="!userForm.controls['countryCode'].errors.required">
                                    code required.</span>
                                    <span i18n [hidden]="!userForm.controls['countryCode'].errors.pattern">code (eg. +91)</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="enablePhoneNumber === true" class="row-field-phn">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Phone No  <span class="mandatory-field">*</span></mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input matInput i18n-placeholder placeholder="Phone No" formControlName="phoneNumber"
                                    [errorStateMatcher]="matcher" autocomplete="off" role="presentation" [readonly]="data.type === 'task'">
                                <mat-error *ngIf="userForm.controls.phoneNumber.invalid">
                                    <span i18n [hidden]="!userForm.controls['phoneNumber'].errors.required">
                                        Phone number is required.
                                    </span>
                                    <span i18n [hidden]="!userForm.controls['phoneNumber'].errors.pattern">
                                        Invalid Phone number
                                    </span>
                                    <span i18n [hidden]="!userForm.controls['phoneNumber'].errors.isAlreadyExists">
                                        Sorry, Phone number already exists!
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div *ngIf="enablePhoneNumber === false" class="row-field-5  ">
                        <div class="example-form" >
                            <mat-label i18n class="ovi-lbl-text-size">Phone Number </mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input matInput i18n-placeholder placeholder="Phone Number"  formControlName="phoneNumber"
                                [errorStateMatcher]="matcher"  role="presentation" [readonly]="data.type === 'task'">
                            <button *ngIf="data.type !== 'task'" mat-icon-button matSuffix (click)="phoneNumber('enable')">
                            <mat-icon style="padding-top: 0px;font-size: 25px;color: red;margin-top: -1px;">highlight_off</mat-icon>
                            </button>
                            </mat-form-field>
                        </div>
                    </div>

                   
                    <div class="row-field-5"  *ngIf="data.type !== 'student'">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Status</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <mat-select i18n-placeholder placeholder="Status" formControlName="status" [disableOptionCentering]="true"
                                    [errorStateMatcher]="matcher" [disabled]="data.type === 'task'">
                                    <mat-option *ngFor="let status of statusList" [value]="status.value">
                                        {{status.value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- <div class="row-field-5"  *ngIf="data.type !== 'student'">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">User Name <span class="mandatory-field">*</span></mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input matInput i18n-placeholder placeholder="User Name" autocomplete="new-password" formControlName="userName"
                                    [errorStateMatcher]="matcher"  role="presentatio" [readonly]="data.type === 'task'">

                                <mat-error *ngIf="userForm.controls.userName.invalid">
                                    <span i18n [hidden]="!userForm.controls['userName'].errors.required">
                                        User name is required
                                    </span>

                                    <span i18n [hidden]="!userForm.controls['userName'].errors.pattern">
                                        Invalid User Name!
                                    </span>

                                    <span i18n [hidden]="!userForm.controls['userName'].errors.userNameExists">
                                        Sorry, user name already exists!
                                    </span>
                                </mat-error>
                            </mat-form-field>
                    </div>
                    </div>


                    <div class="row-field-5" *ngIf="!data && this.userForm.controls['isLdapUser'].value === false && data.type !== 'student'">
                        <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Password</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <input matInput i18n-placeholder placeholder="Password" type="password" autocomplete="new-password"
                                formControlName="cnfrmNewPassword" [errorStateMatcher]="matcher" maxlength="26" [readonly]="data.type === 'task'">
                            <mat-error *ngIf="!userForm.controls['cnfrmNewPassword'].pattern">
                                    Password should have min 4 and max 10 characters with atleast 1 special character, 1 capital letter and 1 numeric digit.
                            </mat-error>
                        </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field-5-radio row-field-5"  *ngIf="data.type !== 'student'">
                        <mat-radio-group class="example-radio-group" style="padding: 8px 0px !important;" formControlName="isLdapUser"
                            (change)="userChange($event.value)">
                            <label i18n class="ie-label ovi-lbl-text-size" style="margin-left: 3px;">LDAP User : </label>
                                <mat-radio-button i18n [disabled]="data.type === 'task'" class="example-user-radio-button cst-mgn_1" [value]="true" (click)=" setEmailValue(false)">
                                    Yes</mat-radio-button>
                                <mat-radio-button i18n [disabled]="data.type === 'task'" class="example-user-radio-button cst-mgn_1" [checked]="userForm.controls.isLdapUser.value === false" [value]="false" (click)="setEmailValue(true)">
                                    No</mat-radio-button>
                        </mat-radio-group>
                    </div> -->
                    <div class="row-field-5"  *ngIf="data.type !== 'student'">
                        <mat-label i18n class="ovi-lbl-text-size">Manager</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <input matInput i18n-placeholder placeholder="Manager" id="manager"
                                formControlName="managerId" appTypeHit (typeHit)="searchUserNamelist($event)"
                                [matAutocomplete]="auto" autocomplete="off" [readonly]="data.type === 'task'">
                            <mat-autocomplete autoActiveFirstOption
                                [displayWith]="this.recipientEnabled === true ? getRecipientList.bind(userNameList) : ''"
                                #auto="matAutocomplete">
                                <mat-option *ngFor="let option of userNameList"
                                    [value]="option.id">
                                    <div class="alrt-wid-styl">{{option.name}}</div>
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error
                            *ngIf="userForm.controls.managerId.invalid" class="error-txt">
                            <span i18n [hidden]="!userForm.controls['managerId'].errors.requireMatch">Please select
                                value from the option</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row-field-5"  *ngIf="data.type === 'student'">
                        <div class="example-form">
                         <mat-label i18n class="ovi-lbl-text-size" *ngIf="data.type !== 'student'">Home Location</mat-label>
                          <mat-label i18n class="ovi-lbl-text-size" *ngIf="data.type === 'student'">Location</mat-label>
                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                <input i18n-placeholder matInput placeholder="Type here to search location"
                                id="location" appTypeHit (typeHit)="searchLocationlist($event)" [matAutocomplete]="autoFrom"
                                    formControlName="locationId" [readonly]="data.type === 'task'">
                                <mat-autocomplete autoActiveFirstOption
                                [displayWith]="this.locationEnabled === true ? getLocationList.bind(locationlist) : ''"
                                 #autoFrom="matAutocomplete">
                                    <mat-option *ngFor="let list of locationlist"
                                        [value]="list.id">
                                        <div class="tot-wid-styl" (click)="getLocationID(list.id)">
                                            {{list.name}},{{list.fullName}}</div>
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error
                                    *ngIf="userForm.controls.locationId.invalid" class="error-txt">
                                    <span i18n [hidden]="!userForm.controls['locationId'].errors.requireMatch">Please select
                                        value from the option</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- <div class="row-field-5">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">User Pool</mat-label>
                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                <mat-select i18n-placeholder placeholder="User Pool" formControlName="poolNameId" [disableOptionCentering]="true">
                                    <mat-option i18n [value]="null">None</mat-option>
                                    <mat-option *ngFor="let userPool of userPoolList" [value]="userPool.code">
                                        {{userPool.value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div> -->
                    <div class="row-field-5" *ngIf="data.type !== 'student'">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Employee Id</mat-label>
                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                <input i18n-placeholder matInput placeholder="Employee Id" formControlName="sourceId" [readonly]="data.type === 'task'">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field-5" *ngIf="data.type !== 'student'">
                        <div class="example-form">
                            <div class="img-prev">
                                <div class="logoBtn img-prev_1">
                                    <label i18n mat-button class="ovi-lbl-text-size">
                                        Upload Profile Image
                                        <input type="file" id="File" accept="image/*" (change)="uploadBgImage($event)" class="disp-none"
                                            #input>
                                    </label>
                                </div>
                            </div>
                            <div class="img-prev_3">
                                <img *ngIf="userImage" [src]="userImage" alt="" class="img-prev_2">
                            </div>
                        </div>
                      </div> 
  
                      <div class="row-field-5" *ngIf="data.type !== 'student'">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">About Me</mat-label>
                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                <textarea matInput i18n-placeholder placeholder="About Me" autocomplete="off" formControlName="comments"
                                    role="presentation"></textarea> 
                            </mat-form-field>
                        </div>
                      </div> 


                    <div class="row-field-5"  *ngIf="data.type === 'student'">
                      <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size" *ngIf="data.type === 'student'">Roll Number  <span class="mandatory-field">*</span></mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                          <input i18n-placeholder matInput placeholder="Roll Number" formControlName="mainidentifier" [readonly]="data.userId && data.type === 'student'">
                          <mat-error *ngIf="userForm.controls.mainidentifier.invalid">
                                  <span i18n [hidden]="!userForm.controls['mainidentifier'].errors.required">Roll Number is
                                      required.</span>
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  <div class="row-field-5"  *ngIf="data.type === 'student'">
                    <div class="example-form">
                      <mat-label i18n class="ovi-lbl-text-size" *ngIf="data.type === 'student'">Department</mat-label>
                      <mat-form-field class="example-full-width form-field" appearance="outline">
                        <input i18n-placeholder matInput placeholder="Type here to department"
                               id="department" appTypeHit (typeHit)="searchDepartmentDetails($event)" [matAutocomplete]="autoFrom" formControlName="departmentId">
                        <mat-autocomplete autoActiveFirstOption [displayWith]="this.departmentEnabled === true ? getDepartmentList.bind(departmentList) : ''"
                        #auto="matAutocomplete" #autoFrom="matAutocomplete">
                          <mat-option *ngFor="let list of departmentList"
                                      [value]="list.id">
                            <div class="tot-wid-styl">{{list.name}}</div>
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error
                          *ngIf="userForm.controls.departmentId.invalid" class="error-txt">
                                    <span i18n [hidden]="!userForm.controls['departmentId'].errors.requireMatch">Please select
                                        value from the option</span>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row-field-5" *ngIf="data.type === 'student'">
                    <div class="example-form">
                      <mat-label i18n class="ovi-lbl-text-size">Start Date  <span class="mandatory-field">*</span></mat-label>
                      <mat-form-field class="example-full-width form-field" appearance="outline">
                        <input matInput [matDatepicker]="startpicker" i18n-placeholder placeholder="Start Date"
                               formControlName="studentStartDate" [errorStateMatcher]="matcher" autocomplete="off">
                        <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
                        <mat-datepicker #startpicker></mat-datepicker>
                        <mat-error *ngIf="userForm.controls.studentStartDate.invalid">
                                    <span i18n [hidden]="!userForm.controls['studentStartDate'].errors.required">Start Date is
                                        required.</span>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row-field-5" *ngIf="data.type === 'student'">
                    <div class="example-form">
                      <mat-label i18n class="ovi-lbl-text-size">End Date  <span class="mandatory-field">*</span></mat-label>
                      <mat-form-field class="example-full-width form-field" appearance="outline">
                        <input matInput [matDatepicker]="endpicker" i18n-placeholder placeholder="End Date"
                        [min]="userForm.controls.studentStartDate.value" formControlName="studentEndDate"
                        [errorStateMatcher]="matcher" autocomplete="off">
                        <mat-datepicker-toggle matSuffix [for]="endpicker"></mat-datepicker-toggle>
                        <mat-datepicker #endpicker></mat-datepicker>
                        <mat-error *ngIf="userForm.controls.studentEndDate.invalid">
                                <span i18n [hidden]="!userForm.controls['studentEndDate'].errors.required">End Date is
                                    required.</span>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row-field-5" *ngIf="this.data.type === 'student'">
                    <div class="example-form">
                      <mat-label i18n class="ovi-lbl-text-size">Start Time  <span class="mandatory-field">*</span></mat-label>
                      <mat-form-field class="example-full-width form-field" appearance="outline">
                        <input matInput i18n-placeholder [(ngModel)]="defaultStudentStartTime" placeholder="Start Time" class="user-time-input"
                               [value]="defaultStudentStartTime" [ngxTimepicker]="studstarttimepicker" [disableClick]="true" formControlName="studentStartTime" required>
                        <ngx-material-timepicker-toggle matSuffix [for]="studstarttimepicker">
                        </ngx-material-timepicker-toggle>
                        <ngx-material-timepicker #studstarttimepicker></ngx-material-timepicker>
                        <mat-error *ngIf="userForm.controls.studentStartTime.invalid">
                                    <span i18n [hidden]="!userForm.controls['studentStartTime'].errors.required">Start Time is
                                        required.</span>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row-field-5" *ngIf="this.data.type === 'student'">
                    <div class="example-form">
                      <mat-label i18n class="ovi-lbl-text-size">End Time  <span class="mandatory-field">*</span></mat-label>
                      <mat-form-field class="example-full-width form-field" appearance="outline">
                        <input matInput i18n-placeholder [(ngModel)]="defaultStudentEndTime" placeholder="End time" class="user-time-input"
                               [value]="defaultStudentEndTime" [ngxTimepicker]="studendtimepicker" [disableClick]="true" formControlName="studentEndTime" required>
                        <ngx-material-timepicker-toggle matSuffix [for]="studendtimepicker">
                        </ngx-material-timepicker-toggle>
                        <ngx-material-timepicker #studendtimepicker></ngx-material-timepicker>
                        <mat-error *ngIf="userForm.controls.studentEndTime.invalid">
                                    <span i18n [hidden]="!userForm.controls['studentEndTime'].errors.required">End Time is
                                        required.</span>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <!-- <div class="row-field-5">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Location Pool</mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <mat-select i18n-placeholder placeholder="Location Pool" formControlName="poolLocationId" [disableOptionCentering]="true">
                                <mat-option [value]="null">None</mat-option>
                                <mat-option *ngFor="let locPool of locationPoolList" [value]="locPool.code">
                                    {{locPool.value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                  </div> -->
                </div>
            </mat-tab>
            <mat-tab i18n-label label="Login">
            
                <div class="form-row block-3">
                    <div class="row-field-5" *ngIf="data.type !== 'student'">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">User Name <span class="mandatory-field">*</span></mat-label>
                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                <input matInput i18n-placeholder placeholder="User Name" autocomplete="new-password"
                                    formControlName="userName" [errorStateMatcher]="matcher" role="presentatio"
                                    [readonly]="data.type === 'task'">
            
                                <!-- <mat-error *ngIf="userForm.controls.userName.invalid">
                                                <span i18n [hidden]="!userForm.controls['userName'].errors.required">
                                                    User name is required
                                                </span>
                        
                                                <span i18n [hidden]="!userForm.controls['userName'].errors.pattern">
                                                    Invalid User Name!
                                                </span>
                        
                                                <span i18n [hidden]="!userForm.controls['userName'].errors.userNameExists">
                                                    Sorry, user name already exists!
                                                </span>
                                            </mat-error> -->
                            </mat-form-field>
                        </div>
                    </div>
            
            
                    <div class="row-field-5"
                        *ngIf="!data && this.userForm.controls['isLdapUser'].value === false && data.type !== 'student'">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Password</mat-label>
                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                <input matInput i18n-placeholder placeholder="Password" [type]="hide ? 'password' : 'text'" autocomplete="off"
                                    formControlName="newPassword" [errorStateMatcher]="matcher" maxlength="26"
                                    [readonly]="data.type === 'task'">
                                    <span matSuffix (click)="hide = !hide" class="float-r" class="material-icons" style="color: #844CF3;padding: 0px 5px 0px 0px;">{{hide ? 'visibility_off' : 'visibility'}}</span>
                               <mat-error i18n *ngIf="userForm.controls['newPassword'].invalid"> Password should have min 4 and max 10 characters with atleast 1 special character, 1 capital
                                letter and 1 numeric digit.</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field-5"
                        *ngIf="!data && this.userForm.controls['isLdapUser'].value === false && data.type !== 'student'">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Reenter Password</mat-label>
                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                <input matInput i18n-placeholder placeholder="Reenter Password" [type]="hide1 ? 'password' : 'text'" autocomplete="off"
                                    formControlName="cnfrmNewPassword" [errorStateMatcher]="matcher" maxlength="26"
                                    [readonly]="data.type === 'task'">
                                    <span matSuffix (click)="hide1 = !hide1" class="float-r" class="material-icons" style="color: #844CF3;padding: 0px 5px 0px 0px;">{{hide1 ? 'visibility_off' : 'visibility'}}</span>
                                <mat-error i18n
                                  *ngIf="userForm.controls['cnfrmNewPassword'].pristine || userForm.controls['cnfrmNewPassword'].errors?.MatchPassword">
                                  Password does not match</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                
                </div>
            </mat-tab>
            
            
            <mat-tab i18n-label label="Additional Info">
                <!-- <div>
                    <div style="overflow: hidden;">
                        <div class="form-row block-3">
                            <div class="row-field-5" *ngIf="data.type !== 'student'">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">Select Type</mat-label>
                                    <mat-form-field class="example-full-width form-field" appearance="outline">
                                        <mat-select i18n-placeholder placeholder="Select Type" [(value)]="selectedOption">
                                            <mat-option value="Education">Education</mat-option>
                                            <mat-option value="Proficiencies">Proficiencies</mat-option>
                                            <mat-option value="Specialties">Specialties</mat-option>
                                        </mat-select>
                                        <mat-error class="error-txt-1">
                                            <span i18n>User Pool is required.</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-divider class="divider"></mat-divider> -->
                   <mat-tab-group (selectedTabChange)="tab2Click($event)"[selectedIndex]="selected2Index">     
                    <mat-tab label="Education" [formGroup]="EducationForm">
                        <div class="form-row block-3">
                            <div class="row-field-5">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">Graduation<span
                                            class="mandatory-field">*</span></mat-label>
                                    <mat-form-field class="example-full-width form-field" appearance="outline">
                                        <input matInput i18n-placeholder placeholder="Graduation" autocomplete="new-password"
                                            formControlName="gradation">
                                        <mat-error class="error-txt-1" *ngIf="EducationForm.controls.gradation.invalid">
                                            <span i18n>Graduation is required.</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row-field-5">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">Field Of Study</mat-label>
                                    <mat-form-field class="example-full-width form-field" appearance="outline">
                                        <input matInput i18n-placeholder placeholder="Field Of Study" autocomplete="new-password"
                                            formControlName="fieldOfStudy">
                                        <mat-error class="error-txt-1" *ngIf="EducationForm.controls.fieldOfStudy.invalid">
                                            <span i18n>Field Of Study is required.</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row-field-5">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">Institution Name</mat-label>
                                    <mat-form-field class="example-full-width form-field" appearance="outline">
                                        <input matInput i18n-placeholder placeholder="Institution Name" autocomplete="new-password"
                                            formControlName="institutionName">
                                        <mat-error class="error-txt-1" *ngIf="EducationForm.controls.institutionName.invalid">
                                            <span i18n>Institution Name is required.</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row-field-5">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">Notes</mat-label>
                                    <mat-form-field class="example-full-width form-field m-top-1" appearance="outline">
                                        <textarea i18n-placeholder input matInput placeholder="Notes" rows=1
                                            formControlName="notes"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row-field-5">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">Start Date </mat-label>
                                    <mat-form-field class="example-full-width form-field" appearance="outline">
                                        <input matInput [matDatepicker]="startpickerEDU" i18n-placeholder placeholder="Start Date"
                                            formControlName="startDate" [errorStateMatcher]="matcher" autocomplete="off">
                                        <mat-datepicker-toggle matSuffix [for]="startpickerEDU"></mat-datepicker-toggle>
                                        <mat-datepicker #startpickerEDU></mat-datepicker>
                                        <mat-error *ngIf="EducationForm .controls.startDate.invalid">
                                            <span i18n [hidden]="!EducationForm .controls['startDate'].errors.required">Start
                                                Date is
                                                required.</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row-field-5">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">End Date </mat-label>
                                    <mat-form-field class="example-full-width form-field" appearance="outline">
                                        <input matInput [matDatepicker]="endpickerEDU" i18n-placeholder placeholder="End Date"
                                            formControlName="endDate" [errorStateMatcher]="matcher" autocomplete="off">
                                        <mat-datepicker-toggle matSuffix [for]="endpickerEDU"></mat-datepicker-toggle>
                                        <mat-datepicker #endpickerEDU></mat-datepicker>
                                        <mat-error *ngIf="EducationForm .controls.endDate.invalid">
                                            <span i18n [hidden]="!EducationForm .controls['endDate'].errors.required">End
                                                Date is
                                                required.</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="input-footer" style="text-align: right !important;">
                            <button style="margin-right: 5px"
                                *ngIf="(!data && data.type !== 'student') || (!edushow && !EducationForm.controls['id'].value)" i18n mat-button
                                class="active-btn" [disabled]="EducationForm.invalid" (click)="addOrEditEDURow()">
                                ADD
                            </button>
                            <button *ngIf="(data && data.type !== 'student') && edushow" i18n mat-button class="active-btn"
                                [disabled]="EducationForm.invalid" (click)="addOrEditEDURow()">
                                Update
                            </button>
                        </div>
                        <mat-divider class="divider"></mat-divider>
                        <div *ngIf="showTable">
                            <angular-table-component [id]="'Education'" [type]="'ng-table'" [tableData]="userEducations"
                                [columns]="['Graduation','Field Of Study','Institution Name','Notes','Start Date','End Date','Edit','Delete']"
                                [columnData]="['gradation','fieldOfStudy','institutionName','notes','startDate','endDate','','']"
                                (triggerAction)="triggerAction($event)">
                            </angular-table-component>
                        </div>
                    </mat-tab>
            
                    <mat-tab label="Proficiencies" [formGroup]="ProficienciesForm">
                        <div class="form-row block-3">
                            <div class="row-field-5">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">Title<span
                                            class="mandatory-field">*</span></mat-label>
                                    <mat-form-field class="example-full-width form-field" appearance="outline">
                                        <input matInput i18n-placeholder placeholder="Title" formControlName="title">
                                        <mat-error class="error-txt-1" *ngIf="ProficienciesForm .controls.title.invalid">
                                            <span i18n>Title is required.</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row-field-5">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">Organisation Name</mat-label>
                                    <mat-form-field class="example-full-width form-field" appearance="outline">
                                        <input matInput i18n-placeholder placeholder="Organisation Name" autocomplete="new-password"
                                            formControlName="organisationName">
                                        <mat-error class="error-txt-1" *ngIf="ProficienciesForm .controls.organisationName.invalid">
                                            <span i18n>Organisation Name is required.</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row-field-5">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">Address</mat-label>
                                    <mat-form-field class="example-full-width form-field" appearance="outline">
                                        <input matInput i18n-placeholder placeholder="Address" formControlName="address">
                                        <mat-error class="error-txt-1" *ngIf="ProficienciesForm .controls.address.invalid">
                                            <span i18n>Address is required.</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row-field-5">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">Note</mat-label>
                                    <mat-form-field class="example-full-width form-field m-top-1" appearance="outline">
                                        <textarea i18n-placeholder input matInput placeholder="Note" rows=1
                                            formControlName="notes"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row-field-5">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">Start Date </mat-label>
                                    <mat-form-field class="example-full-width form-field" appearance="outline">
                                        <input matInput [matDatepicker]="startpickerPROF" i18n-placeholder placeholder="Start Date"
                                            formControlName="startDate" [errorStateMatcher]="matcher" autocomplete="off">
                                        <mat-datepicker-toggle matSuffix [for]="startpickerPROF"></mat-datepicker-toggle>
                                        <mat-datepicker #startpickerPROF></mat-datepicker>
                                        <mat-error *ngIf="ProficienciesForm .controls.startDate.invalid">
                                            <span i18n [hidden]="!ProficienciesForm .controls['startDate'].errors.required">Start
                                                Date is
                                                required.</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row-field-5">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">End Date </mat-label>
                                    <mat-form-field class="example-full-width form-field" appearance="outline">
                                        <input matInput [matDatepicker]="endpickerPROF" i18n-placeholder placeholder="End Date"
                                            formControlName="endDate" [errorStateMatcher]="matcher" autocomplete="off">
                                        <mat-datepicker-toggle matSuffix [for]="endpickerPROF"></mat-datepicker-toggle>
                                        <mat-datepicker #endpickerPROF></mat-datepicker>
                                        <mat-error *ngIf="ProficienciesForm .controls.endDate.invalid">
                                            <span i18n [hidden]="!ProficienciesForm .controls['endDate'].errors.required">End Date
                                                is
                                                required.</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="input-footer" style="text-align: right !important;">
                            <button style="margin-right: 5px"
                                *ngIf="(!data && data.type !== 'student') || (!profshow && !ProficienciesForm.controls['id'].value)" i18n mat-button
                                class="active-btn" [disabled]="ProficienciesForm.invalid" (click)="addOrEditPROFRow()">
                                ADD
                            </button>
                            <button *ngIf="(data && data.type !== 'student') && profshow" i18n mat-button class="active-btn"
                                [disabled]="ProficienciesForm.invalid" (click)="addOrEditPROFRow()">
                                Update
                            </button>
                          </div>
                        <mat-divider class="divider"></mat-divider>
                        <div *ngIf="showTable1">
                            <angular-table-component [id]="'Proficiencies'" [type]="'ng-table'" [tableData]="userProficiencies"
                                [columns]="['Title','Organisation Name','Address','Note','Start Date','End Date','Edit','Delete']"
                                [columnData]="['title','organisationName','address','notes','startDate','endDate','','']"
                                (triggerAction)="triggerAction($event)">
                            </angular-table-component>
                        </div>
                    </mat-tab>
            
                    <mat-tab label="Specialties" [formGroup]="SpecialtiesForm">
                        
                        <div class="input-footer" style="text-align: right !important;">
                            <button *ngIf="!data && data.type !== 'student'" i18n mat-button class="active-btn " [disabled]="SpecialtiesForm.invalid"
                                (click)="addSPECcheck(null)">ADD Specialty</button>
                                <button  *ngIf="data && data.type !== 'student'" i18n mat-button class="active-btn " [disabled]="SpecialtiesForm.invalid"
                                (click)="addSPECcheck(userSpecialty)">Update Specialty</button>
                        </div>
                        <mat-divider class="divider"></mat-divider>
                        <div *ngIf="showTable2">
                            <angular-table-component [id]="'Specialties'" [type]="'ng-table'" [tableData]="clinicalData['specialtyId']"
                                [columns]="['Id','Name','Status']" [columnData]="['specialtyId','specialtyName','isActive']"
                                (triggerAction)="triggerAction($event)">
                            </angular-table-component>
                        </div>
                    </mat-tab>
                     <mat-tab label="Procedures" [formGroup]="ProceduresForm">
                        <div class="input-footer" style="text-align: right !important;">
                            <button *ngIf="!data && data.type !== 'student'" i18n mat-button class="active-btn " [disabled]="ProceduresForm.invalid"
                                (click)="addSPECcheck(null)">ADD Procedure</button>
                                <button  *ngIf="data && data.type !== 'student'" i18n mat-button class="active-btn " [disabled]="ProceduresForm.invalid"
                                (click)="addSPECcheck(userSpecialty)">Update Procedure</button>
                        </div>
                        <mat-divider class="divider"></mat-divider>
                        <div *ngIf="showTable2">
                            <angular-table-component [id]="'Procedure'" [type]="'ng-table'" [tableData]="clinicalData['proceduresId']"
                                [columns]="['Id','Name','Status']" 
                                [columnData]="['proceduresId','proceduresName','isActive']"
                                (triggerAction)="triggerAction($event)">
                            </angular-table-component>
                        </div>
                    </mat-tab>
                    <mat-tab label="Services" [formGroup]="ServicesForm"> 
                        <div class="input-footer" style="text-align: right !important;">
                            <button *ngIf="!data && data.type !== 'student'" i18n mat-button class="active-btn " [disabled]="ServicesForm.invalid"
                                (click)="addSPECcheck(null)">ADD Service</button>
                                <button  *ngIf="data && data.type !== 'student'" i18n mat-button class="active-btn " [disabled]="ServicesForm.invalid"
                                (click)="addSPECcheck(userSpecialty)">Update Service</button>
                        </div>
                        <mat-divider class="divider"></mat-divider>
                        <div *ngIf="showTable2">
                            <angular-table-component [id]="'Service'" [type]="'ng-table'" [tableData]="clinicalData['serviceId']"
                                [columns]="['Id','Name','Status']" [columnData]="['serviceId','serviceName','isActive']"
                                (triggerAction)="triggerAction($event)">
                            </angular-table-component>
                        </div>
                    </mat-tab>
                    </mat-tab-group>
                <!-- </div> -->
            </mat-tab>
            <!-- -- -->
            <!-- <mat-tab i18n-label *ngIf="data && data.type !== 'student'" label="User Pool">
                <div style="height: 400px;padding-top: 15px;" [formGroup]="userPoolForm">
                    <div class="form-row" style="margin-top: 5px;">
                        <div i18n class="ovi-popup-title-text-size top-styl">USER POOL DETAILS</div>
                    </div>
                    <mat-divider class="divider"></mat-divider>
                    <div style="float: right;">
                        <span><button i18n mat-mini-fab style="float:right;margin-top: -30px;margin-right: 45px;"
                            (click)="addUserPool()">+</button></span>
                    </div>
                    <div formArrayName="userPoolList" *ngIf="userPoolForm">
                        <div *ngFor="let obj of userPoolForm.controls.userPoolList.controls; let i=index">
                            <mat-divider *ngIf="userPoolForm.controls.userPoolList.controls.length > 1 && i > 0">
                            </mat-divider>
                            <br>
                            <div [formGroupName]="i">
                                <div class="form-row block-3">
                                    <div class="row-field-6">
                                        <div class="example-form">
                                            <mat-label i18n *ngIf="i === 0" class="ovi-lbl-text-size">User Pool  <span class="mandatory-field">*</span></mat-label>
                                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                                <mat-select i18n-placeholder placeholder="User Pool" formControlName="poolNameId" [disableOptionCentering]="true">
                                                    <mat-option [value]="null">None</mat-option>
                                                    <mat-option *ngFor="let userPool of userPoolList" [value]="userPool.code">
                                                        {{userPool.value}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error class="error-txt-1">
                                                    <span i18n>User Pool is required.</span>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row-field-6">
                                        <div class="example-form">
                                            <mat-label i18n *ngIf="i === 0" class="ovi-lbl-text-size">Location Pool</mat-label>
                                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                                <mat-select i18n-placeholder placeholder="Location Pool" formControlName="poolLocationId" [disableOptionCentering]="true">
                                                    <mat-option [value]="null">None</mat-option>
                                                    <mat-option *ngFor="let locPool of locationPoolList" [value]="locPool.code">
                                                        {{locPool.value}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="example-form">
                                                <span><button mat-icon-button close-button class="ie-close-icon"
                                                    (click)="removeUserPool(i, 'clear')" [ngStyle]="{'margin-top': i === 0 ? '23px' : ''}">
                                                     <mat-icon class="icon-style" style="color: red;font-size:30px">highlight_off</mat-icon> </button></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab i18n-label *ngIf="data && data.type !== 'student'" label="User Location">
                <div style="height: 400px;padding-top: 15px;" [formGroup]="userPoolLocationForm">
                    <div class="form-row" style="margin-top: 5px;">
                        <div i18n class="ovi-popup-title-text-size top-styl">USER LOCATION DETAILS</div>
                    </div>
                    <mat-divider class="divider"></mat-divider>
                    <div style="float: right;">
                        <span><button i18n mat-mini-fab style="float:right;margin-top: -30px;margin-right: 45px;"
                            (click)="addUserPoolLocation()">+</button></span>
                    </div>
                    <div formArrayName="userPoolLocationList" *ngIf="userPoolLocationForm">
                        <div *ngFor="let obj of userPoolLocationForm.controls.userPoolLocationList.controls; let i=index">
                            <mat-divider *ngIf="userPoolLocationForm.controls.userPoolLocationList.controls.length > 1 && i > 0">
                            </mat-divider>
                            <br>
                            <div [formGroupName]="i">
                                <div class="form-row block-3">
                                    <div class="row-field-6">
                                        <div class="example-form">
                                            <mat-label i18n *ngIf="i === 0" class="ovi-lbl-text-size">User Pool Location </mat-label>
                                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                                <input i18n-placeholder matInput placeholder="Type here to search location"
                                                id="location" appTypeHit (typeHit)="searchLocationlist($event)" [matAutocomplete]="autoFrom" formControlName="locationId"
                                                    [readonly]="this.userPoolLocationForm.controls['userPoolLocationList'].value[i]?.locationNameId !== null">
                                                <mat-autocomplete autoActiveFirstOption
                                                [displayWith]="this.locationEnabled === true ? getLocationList.bind(locationlist) : ''"
                                                #autoFrom="matAutocomplete">
                                                    <mat-option *ngFor="let list of locationlist"
                                                        [value]="list.id" [ngStyle]="{display: locationOption.indexOf(list.id) > -1 ? 'none' : 'block'}">
                                                        <div class="tot-wid-styl" (click)="getLocationID(list.id);filterLocationOptions(list.id);">
                                                            {{list.name}},{{list.fullName}}</div>
                                                    </mat-option>
                                                </mat-autocomplete>
                                                 <mat-error class="error-txt-1">
                                                    <span i18n>User Location is required.</span>
                                                </mat-error> -->
                                            <!-- </mat-form-field> 
                                        </div>
                                    </div>
                                    <div>
                                        <div class="example-form">
                                                <span><button mat-icon-button close-button class="ie-close-icon"
                                                    (click)="removeUserPoolLocation(i, 'clear')" [ngStyle]="{'margin-top': i === 0 ? '23px' : ''}">
                                                     <mat-icon class="icon-style" style="color: red;font-size:30px">highlight_off</mat-icon> </button></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab i18n-label *ngIf="data && data.type !== 'student'" [formGroup]="userShiftForm" label="Schedule">
                <div class="form-row block-3 user-padd">
                    <div class="row-field-1">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Shift  <span class="mandatory-field">*</span></mat-label>
                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                <mat-select i18n-placeholder placeholder="Shift" formControlName="shiftId" [disableOptionCentering]="true">
                                    <mat-option *ngFor="let shift_list of shiftList" [value]="shift_list.id"
                                        (click)="getShiftTime(shift_list)">
                                        {{shift_list.shiftName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="userShiftForm.controls.shiftId.invalid">
                                    <span i18n [hidden]="!userShiftForm.controls['shiftId'].errors.required">Shift is
                                        required.</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field-1">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Start Time  <span class="mandatory-field">*</span></mat-label>
                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                <input matInput i18n-placeholder [(ngModel)]="defaultStartTime" placeholder="Start Time" class="user-time-input"
                                [value]="defaultStartTime" [ngxTimepicker]="starttimepicker" [disableClick]="true" formControlName="startTime" required>
                                <ngx-material-timepicker-toggle matSuffix [for]="starttimepicker">
                                </ngx-material-timepicker-toggle>
                                <ngx-material-timepicker #starttimepicker></ngx-material-timepicker>
                                <mat-error *ngIf="userShiftForm.controls.startTime.invalid">
                                    <span i18n [hidden]="!userShiftForm.controls['startTime'].errors.required">Start Time is
                                        required.</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field-1">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">End Time  <span class="mandatory-field">*</span></mat-label>
                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                <input matInput i18n-placeholder [(ngModel)]="defaultEndTime" placeholder="End time" class="user-time-input"
                                [value]="defaultEndTime" [ngxTimepicker]="endtimepicker" [disableClick]="true" formControlName="endTime" required>
                                <ngx-material-timepicker-toggle matSuffix [for]="endtimepicker">
                                </ngx-material-timepicker-toggle>
                                <ngx-material-timepicker #endtimepicker></ngx-material-timepicker>
                                <mat-error *ngIf="userShiftForm.controls.endTime.invalid">
                                    <span i18n [hidden]="!userShiftForm.controls['endTime'].errors.required">End Time is
                                        required.</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field-1">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Start Date  <span class="mandatory-field">*</span></mat-label>
                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                <input matInput [matDatepicker]="startpicker" i18n-placeholder placeholder="Start Date"
                                    formControlName="startDate" [errorStateMatcher]="matcher" autocomplete="off">
                                <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
                                <mat-datepicker #startpicker></mat-datepicker>
                                <mat-error *ngIf="userShiftForm.controls.startDate.invalid">
                                    <span i18n [hidden]="!userShiftForm.controls['startDate'].errors.required">Start Date is
                                        required.</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field-1">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">End Date  <span class="mandatory-field">*</span></mat-label>
                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                <input matInput [matDatepicker]="endpicker" i18n-placeholder placeholder="End Date"
                                [min]="userShiftForm.controls.startDate.value" formControlName="endDate"
                                [errorStateMatcher]="matcher" autocomplete="off">
                            <mat-datepicker-toggle matSuffix [for]="endpicker"></mat-datepicker-toggle>
                            <mat-datepicker #endpicker></mat-datepicker>
                            <mat-error *ngIf="userShiftForm.controls.endDate.invalid">
                                <span i18n [hidden]="!userShiftForm.controls['endDate'].errors.required">End Date is
                                    required.</span>
                            </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field-1">
                        <div class="example-form">
                            <div *ngIf="!this.shiftId" class="user-add-padd">
                                <button i18n mat-button class="active-btn margin-r-1" appDebounceClick
                                    [disabled]="this.userShiftForm.invalid" (debounceClick)="addShift();"
                                    [debounceTime]="300" cdkFocusInitial>ADD</button>
                            </div>
                            <div *ngIf="this.shiftId" class="user-edit-padd">
                                <img class="edit-shift-icon" style="cursor: pointer;" matListIcon iconsmall
                                    [ngStyle]="{'opacity': !this.userShiftForm.dirty || this.userShiftForm.invalid ? '60%' : ''}"
                                    (click)="this.userShiftForm.dirty && !this.userShiftForm.invalid ? updateShift() : ''"
                                    src="/assets/Alert/IP/check-mark.svg">
                                <img class="edit-shift-icon" style="cursor: pointer;" matListIcon iconsmall
                                    (click)="cancelEditShift()" src="/assets/Alert/IP/cancel.svg">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tw-popup-content-tbl block-7" style="padding: 5px 10px 5px 10px;">
                    <div class="mat-elevation-z4 tw-manage-component"
                        [ngClass.xs]="'components-container-xs'">
                        <div class="tw-mat-table-overflow box-26" style="max-height: 200px;overflow: auto;">
                            <table mat-table class="tw-table" [dataSource]="ShiftdataSource" matSort>
                                <caption style="display:none">Shift List</caption>

                                <ng-container matColumnDef="S.No">
                                    <th scope="col" i18n mat-header-cell *matHeaderCellDef
                                        class="tw-manage-table-header"> S.No </th>
                                    <td mat-cell *matCellDef="let data; let i = index;"
                                        style="padding-right: 20px;">
                                        {{i+1}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="shiftName">
                                    <th scope="col" i18n mat-header-cell *matHeaderCellDef mat-sort-header
                                        class="tw-manage-table-header"> Shift Name </th>
                                    <td mat-cell *matCellDef="let data"
                                        style="padding-right: 20px;"> {{data.shiftName}} </td>
                                </ng-container>

                                <ng-container matColumnDef="startTime">
                                    <th scope="col" i18n mat-header-cell *matHeaderCellDef mat-sort-header
                                        class="tw-manage-table-header"> Start Time </th>
                                    <td mat-cell *matCellDef="let data"
                                        style="padding-right: 20px;"> {{data.startDate | date : "hh:mm a, d MMM" }} </td>
                                </ng-container>

                                <ng-container matColumnDef="endTime">
                                    <th scope="col" i18n mat-header-cell *matHeaderCellDef mat-sort-header
                                        class="tw-manage-table-header"> End Time </th>
                                    <td mat-cell *matCellDef="let data"
                                        style="padding-right: 20px;"> {{data.endDate | date : "hh:mm a, d MMM" }} </td>
                                </ng-container>

                                <ng-container matColumnDef="Select">
                                    <th scope="col" i18n mat-header-cell *matHeaderCellDef
                                        class="tw-manage-table-header"> </th>
                                    <td mat-cell *matCellDef="let data"
                                        style="padding-right: 20px;padding-left: 20px;">
                                        <button mat-icon-button [matMenuTriggerFor]="menu"
                                        [ngStyle]="{'display': isShiftList === true ? 'none' : ''}">
                                            <mat-icon class="activity-edit">more_horiz</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="editShift(data)"
                                            [disabled]="this.shiftId == data.id">
                                                <span class="shift-edit">Edit</span>
                                            </button>
                                            <mat-divider></mat-divider>
                                            <button mat-menu-item (click)="deleteShift(data)">
                                                <span class="shift-edit">Remove</span>
                                            </button>
                                        </mat-menu>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="ShiftdisplayedColumns; sticky: true;"
                                    class="tw-manage-table-header-row"></tr>
                                <tr mat-row *matRowDef="let data; columns: ShiftdisplayedColumns; let i = index;"
                                [ngStyle]="{'background-color': this.shiftId === this.data.id ? '#D3D3D3' : ''}">
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div i18n class="no-shift" *ngIf="this.shiftData !== true">No Shift Found</div>
                </div>
            </mat-tab> -->
        </mat-tab-group>
        <div *ngIf="this.customerName === 'kyn'">
        <div class="form-row block-3" style="padding-top: 5px;overflow: auto;">
            <div class="row-field-5">
                <div class="example-form">
                    <mat-label i18n class="ovi-lbl-text-size">First Name<span class="mandatory-field">*</span></mat-label>
                    <mat-form-field class="example-full-width form-field"appearance="outline">
                        <input matInput i18n-placeholder placeholder="First Name" formControlName="firstName"
                            [errorStateMatcher]="matcher" autocomplete="off" role="presentation" [readonly]="data.type === 'task'">
                            <mat-error *ngIf="userForm.controls.firstName.invalid">
                            <span i18n [hidden]="!userForm.controls['firstName'].errors.required">First name is required.</span>
                            <span i18n [hidden]="!userForm.controls['firstName'].errors.minlength">Minimum allowed characters are 3 </span>
                            <span i18n [hidden]="!userForm.controls['firstName'].errors.maxlength">Maximmum allowed characters are 25 </span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row-field-5">
                <div class="example-form">
                    <mat-label i18n class="ovi-lbl-text-size">Last Name<span class="mandatory-field">*</span></mat-label>
                    <mat-form-field class="example-full-width form-field"appearance="outline">
                        <input matInput i18n-placeholder placeholder="Last Name" formControlName="lastName"
                            [errorStateMatcher]="matcher" autocomplete="off" role="presentation" [readonly]="data.type === 'task'">
                        <mat-error *ngIf="userForm.controls.lastName.invalid">
                            <span i18n [hidden]="!userForm.controls['lastName'].errors.required">Last name is
                                required.</span>
                            <span i18n [hidden]="!userForm.controls['lastName'].errors.minlength"> Minimum allowed
                                character
                                is 1 </span>
                            <span i18n [hidden]="!userForm.controls['lastName'].errors.maxlength"> Maximmum allowed
                                characters
                                are 25 </span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row-field-5" *ngIf="data.type !== 'student'">
                <div class="example-form">
                    <mat-label i18n class="ovi-lbl-text-size">Role<span class="mandatory-field">*</span></mat-label>
                    <mat-form-field class="example-full-width form-field"appearance="outline">
                        <mat-select i18n-placeholder placeholder="Role *" formControlName="roleIds"
                        [disableOptionCentering]="true" [errorStateMatcher]="matcher" [disabled]="data.type === 'task'">
                            <mat-option *ngFor="let role of roleList" [value]="role.id"  (onSelectionChange)="roleSelect(role.code)">
                                {{role.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error i18n *ngIf="userForm.controls.roleIds.invalid">
                            Role is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row-field-5">
                <div class="example-form">
                    <mat-label i18n class="ovi-lbl-text-size">Gender</mat-label>
                    <mat-form-field class="example-full-width form-field"appearance="outline">
                        <mat-select i18n-placeholder placeholder="Gender" formControlName="gender"[disableOptionCentering]="true"
                            [errorStateMatcher]="matcher" [disabled]="data.type === 'task'">
                            <mat-option *ngFor="let gender of genderList" [value]="gender.code">
                                {{gender.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row-field-5">
                <div class="example-form">
                    <mat-label i18n class="ovi-lbl-text-size">Email<span class="mandatory-field">*</span></mat-label>
                    <mat-form-field class="example-full-width form-field"appearance="outline">
                        <input matInput i18n-placeholder placeholder="Email" formControlName="email" (blur)="setEmailValue(true)"
                            [errorStateMatcher]="matcher" autocomplete="off" role="presentation" [readonly]="data.type === 'task'">
                        <mat-error *ngIf="userForm.controls.email.invalid">
                            <span i18n [hidden]="!userForm.controls['email'].errors.required">
                                E-mail is required.
                            </span>
                            <span i18n [hidden]="!userForm.controls['email'].errors.email">
                                Invalid E-mail
                            </span>
                            <span i18n [hidden]="!userForm.controls['email'].errors.emailExists">
                                Sorry, E-mail already exists!
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="enablePhoneNumber === true" class="row-field-cod">
                <div class="example-form">
                    <mat-label i18n class="ovi-lbl-text-size">Code <span class="mandatory-field">*</span></mat-label>
                    <mat-form-field class="example-full-width form-field"appearance="outline">
                        <input matInput i18n-placeholder placeholder ="Code *" matInput
                        formControlName="countryCode" [matAutocomplete]="countryCode" [readonly]="data.type === 'task'">
                        <mat-autocomplete #countryCode="matAutocomplete"  panelWidth = "250px">
                            <mat-option *ngFor="let country of countryOptions | async" value={{country.code}} appTooltip [tooltipText]="country.value" placement="bottom">
                                {{country.code}} ({{country.value}})
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="userForm.controls.countryCode.invalid">
                            <span i18n [hidden]="!userForm.controls['countryCode'].errors.required">
                            code required.</span>
                            <span i18n [hidden]="!userForm.controls['countryCode'].errors.pattern">code (eg. +91)</span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="enablePhoneNumber === true" class="row-field-phn">
                <div class="example-form">
                    <mat-label i18n class="ovi-lbl-text-size">Phone No <span class="mandatory-field">*</span></mat-label>
                    <mat-form-field class="example-full-width form-field"appearance="outline">
                        <input matInput i18n-placeholder placeholder="Phone No" formControlName="phoneNumber"
                            [errorStateMatcher]="matcher" autocomplete="off" role="presentation" [readonly]="data.type === 'task'">
                        <mat-error *ngIf="userForm.controls.phoneNumber.invalid">
                            <span i18n [hidden]="!userForm.controls['phoneNumber'].errors.required">
                                Phone number is required.
                            </span>
                            <span i18n [hidden]="!userForm.controls['phoneNumber'].errors.pattern">
                                Invalid Phone number
                            </span>
                            <span i18n [hidden]="!userForm.controls['phoneNumber'].errors.isAlreadyExists">
                                Sorry, Phone number already exists!
                            </span>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div *ngIf="enablePhoneNumber === false" class="row-field-5">
                <div class="example-form" style="padding-top: 21px; margin-left:6px;">
                    <span i18n style="color: rgba(0, 0, 0, 0.38);font-size: 14px;">Phone Number : </span>
                    <span>{{this.data.phoneNumber}}</span>
                    <button *ngIf="data.type !== 'task'" mat-icon-button matSuffix (click)="phoneNumber('enable')">
                    <mat-icon style="padding-top: 0px;font-size: 16px;color: red;margin-top: -1px;">highlight_off</mat-icon>
                    </button>
                </div>
            </div>

            <div  class="row-field-5">
                <div class="example-form">
                    <mat-label i18n class="ovi-lbl-text-size">Birth Date (DD/MM/YYYY)</mat-label>
                    <mat-form-field i18n-hintLabel hintLabel="e.g. 30/10/1990" class="example-full-width form-field"appearance="outline">
                        <input matInput [matDatepicker]="picker" i18n-placeholder placeholder="Birth Date (DD/MM/YYYY)"
                            formControlName="birthDate" [max]="today" [errorStateMatcher]="matcher" autocomplete="off"
                            [readonly]="data.type === 'task'">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker [disabled]="data.type === 'task'"></mat-datepicker>

                        <mat-error *ngIf="userForm.controls.birthDate.invalid">
                            <span i18n [hidden]="!userForm.controls['birthDate'].errors.required">
                                DOB is required Or Invalid DOB
                            </span>
                            <span i18n [hidden]="!userForm.controls['birthDate'].errors.pattern">
                                Invalid DOB
                            </span>
                        </mat-error>
                    </mat-form-field>


                </div>
            </div>
            <div class="row-field-5">
                <div class="example-form">
                    <mat-label i18n class="ovi-lbl-text-size">Status</mat-label>
                    <mat-form-field class="example-full-width form-field"appearance="outline">
                        <mat-select i18n-placeholder placeholder="Status" formControlName="status" [disableOptionCentering]="true"
                            [errorStateMatcher]="matcher" [disabled]="data.type === 'task'">
                            <mat-option *ngFor="let status of statusList" [value]="status.value">
                                {{status.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row-field-5">
                <div class="example-form">
                    <mat-label i18n class="ovi-lbl-text-size">User Name</mat-label><div style="display: inline-block;">&nbsp;<span class="mandatory-field">*</span></div>
                    <mat-form-field class="example-full-width form-field"appearance="outline">
                        <input matInput i18n-placeholder placeholder="User Name" autocomplete="new-password" formControlName="userName"
                            [errorStateMatcher]="matcher"  role="presentatio" [readonly]="data.type === 'task'">

                        <mat-error *ngIf="userForm.controls.userName.invalid">
                            <span i18n [hidden]="!userForm.controls['userName'].errors.required">
                                User name is required
                            </span>

                            <span i18n [hidden]="!userForm.controls['userName'].errors.pattern">
                                Invalid User Name!
                            </span>

                            <span i18n [hidden]="!userForm.controls['userName'].errors.userNameExists">
                                Sorry, user name already exists!
                            </span>
                        </mat-error>
                    </mat-form-field>
            </div>
            </div>

            <div class="row-field-5" *ngIf="!data">
                <div class="example-form">
                <mat-label i18n class="ovi-lbl-text-size">Password</mat-label>
                <mat-form-field class="example-full-width form-field"appearance="outline">
                    <input matInput i18n-placeholder placeholder="Password" type="password" autocomplete="new-password"
                        formControlName="cnfrmNewPassword" [errorStateMatcher]="matcher" maxlength="26" [readonly]="data.type === 'task'">
                    <mat-error *ngIf="!userForm.controls['cnfrmNewPassword'].pattern">
                            Password should have min 4 and max 10 characters with atleast 1 special character, 1 capital letter and 1 numeric digit.
                    </mat-error>
                </mat-form-field>
                </div>
            </div>
            <div class="row-field-5">
                <div class="example-form">
                    <mat-label i18n class="ovi-lbl-text-size">Employee Id</mat-label>
                    <mat-form-field class="example-full-width form-field" appearance="outline">
                        <input i18n-placeholder matInput placeholder="Employee Id" formControlName="sourceId" [readonly]="data.type === 'task'">
                    </mat-form-field>
                </div>
            </div>
        </div>
        </div>
    </div>
    <div class="input-footer" style="text-align: right !important;">
        <button i18n mat-button class="cancle-btn" [ngStyle]="{'background': customerName === 'kyn' ? '#D61820' : ''}" style="margin-right: 5px;" mat-dialog-close>Cancel</button>
        <button i18n mat-button *ngIf="!data && data.type !== 'student'" class="active-btn " appDebounceClick [disabled]="
        userForm.controls['cnfrmNewPassword'].pristine || userForm.controls['cnfrmNewPassword'].errors?.MatchPassword || userForm.invalid || isDisabled"
            (debounceClick)="saveUser()" [ngStyle]="{'background': customerName === 'kyn' ? '#D61820' : ''}" [debounceTime]="300" cdkFocusInitial>Create</button>
        <button i18n mat-button *ngIf="data && selectedTab !== 3 && selectedTab !== 4 && selectedTab !== 5 && data.type === 'user' && data.type !== 'student'" class="active-btn " appDebounceClick 
            [ngStyle]="{'background': customerName === 'kyn' ? '#D61820' : ''}" (debounceClick)="editUser(data)" [debounceTime]="300" cdkFocusInitial>Save</button>
        <button i18n mat-button *ngIf="!data.userId && data.type === 'student'" class="active-btn " appDebounceClick [disabled]="userForm.invalid || isDisabled"
            (debounceClick)="saveStudent()" [debounceTime]="300" cdkFocusInitial>Enroll</button>
        <button i18n mat-button *ngIf="data.userId && data.type === 'student'" class="active-btn " appDebounceClick [disabled]="userForm.invalid || isDisabled"
            (debounceClick)="updateStudent(data)" [debounceTime]="300" cdkFocusInitial>Update</button>
        <button i18n mat-button *ngIf="data && selectedTab === 3 && data.type === 'user' && data.type !== 'student'" class="active-btn " appDebounceClick [disabled]="userPoolForm.controls.userPoolList.invalid"
            (debounceClick)="saveUserPool(data)" [debounceTime]="300" cdkFocusInitial>Save</button>
        <button i18n mat-button *ngIf="data && selectedTab === 4 && data.type === 'user' && data.type !== 'student'" class="active-btn " appDebounceClick
            (debounceClick)="saveUserPoolLocation(data)" [debounceTime]="300" cdkFocusInitial>Save</button> 
        </div>
</div>
