<div style="height: 55px;" *ngIf = "false">
  <mat-grid-list [cols]="21" rowHeight="98%" class="ovi-font-family">
      <mat-grid-tile [colspan] = "1" [rowspan]= "1" style="background: #3b7bff;">
        <img alt="ovitag-logo" src="/assets/Alert/common_icons/tw-icon.jpg"/>
    </mat-grid-tile>
      <mat-grid-tile [colspan] = "8" [rowspan]= "1" >
      <div class="breadcrumb-menu" style="text-align : left;position: absolute;left: 2px;">
        <span>{{ mainMenu | titlecase}} </span>
          <span *ngIf = "subMenu"> >> {{ subMenu | titlecase}}</span>
          <span *ngIf = "childMenu"> >> {{ childMenu | titlecase}}</span>
        </div>
    </mat-grid-tile>

      <mat-grid-tile [colspan] = "11" [rowspan]= "1" >
      <div class="nav-search-field">
        <app-globalsearch-component></app-globalsearch-component>
      </div>
      <div style="color:#4E0375; font-size: 15px;font-weight: 600;position: absolute; right:5px;top: 5px;">
        Apollo Hospital, Chennai, APHC  <span></span>
      </div>
      <div style="color : #636363;font-size: 13px;position: absolute;bottom: 5px;right: 5px;">
        John Abraham, Admin
      </div>
    </mat-grid-tile>
      <mat-grid-tile [colspan] = "1" [rowspan]= "1" >
        <img alt="ovitag-logo" src="/assets/Alert/common_icons/tw-icon.jpg"/>
    </mat-grid-tile>
  </mat-grid-list>
</div>
<div style="height: 55px;box-shadow: 1px 1px 1px #00000010;">
  <div class="sidebarlogo" [ngStyle]="{'background': customerName === 'kyn' ? '#D61820' : ''}">
    <img *ngIf ="customerName != 'kyn'" alt="ovitag-logo" src="/assets/Alert/Red_plus_sign.svg.png" class="ovi-logo"/>
  </div>
  <div style="float: left;width: 88%;height: 45px;" class="ovi-font-family">
    <div class="menu-text">
      <div *ngIf="locale === 'en-US' || locale === 'en'">
        <span *ngFor="let menu of menuArray;let i= index">
          {{menu | titlecase}}
          <div *ngIf="menu === 'dashboard' && activate_btn.includes('BT-DBLAYOUT')" class="mat-custom-form-field-box layout-icon"
            [ngStyle]="{width: islayout ? '50%' : ''}" [formGroup]="layoutForm">
            <mat-label [ngClass]="islayout ? 'ip-label-layout' : 'ip-label-layout1'"
            (click)="islayout === true ? islayout = false : islayout = true">
              <img src="/assets/Menus/layout_DBW.png" alt="" style="width:14px;height:16px">
            </mat-label>
            <mat-select *ngIf="islayout" class="ip-select-layout" style="width: 230px;padding-top: 8px !important;" formControlName="layout"
            (openedChange)="openedChange($event);">
              <mat-option *ngFor="let data of layoutList" [value]="data.id"
                (click)="checkLayout(data);islayout = false">{{data.dashboardName}}</mat-option>
            </mat-select>
          </div>
        </span>
      </div>
      <div *ngIf="locale === 'id'">
        <span *ngFor="let menu of menuArray;let i= index">
          {{menu}}
          <div *ngIf="menu === 'dasbor' && activate_btn.includes('BT-DBLAYOUT')" class="mat-custom-form-field-box layout-icon"
            [ngStyle]="{width: islayout ? '50%' : ''}" [formGroup]="layoutForm">
            <mat-label [ngClass]="islayout ? 'ip-label-layout' : 'ip-label-layout1'"
             (click)="islayout === true ? islayout = false : islayout = true">
              <img src="/assets/Menus/layout_DBW.png" art="" style="width:14px;height:16px">
            </mat-label>
            <mat-select *ngIf="islayout" class="ip-select-layout" style="width: 230px;padding-top: 8px !important;" formControlName="layout"
            (openedChange)="openedChange($event);">
              <mat-option *ngFor="let data of layoutList" [value]="data.id"
                (click)="checkLayout(data);islayout = false">{{data.dashboardName}}</mat-option>
            </mat-select>
          </div>
        </span>
     </div>
     <div *ngIf="locale === 'ar'">
      <span *ngFor="let menu of menuArray;let i= index">
        {{menu | titlecase}}
        <div *ngIf="menu === 'لوحة القيادة' && activate_btn.includes('BT-DBLAYOUT')" class="mat-custom-form-field-box layout-icon"
          [ngStyle]="{width: islayout ? '50%' : ''}" [formGroup]="layoutForm">
          <mat-label [ngClass]="islayout ? 'ip-label-layout' : 'ip-label-layout1'"
          (click)="islayout === true ? islayout = false : islayout = true">
            <img src="/assets/Menus/layout_DBW.png" alt="" style="width:14px;height:16px">
          </mat-label>
          <mat-select *ngIf="islayout" class="ip-select-layout" style="width: 230px;padding-top: 8px !important;" formControlName="layout"
          (openedChange)="openedChange($event);">
            <mat-option *ngFor="let data of layoutList" [value]="data.id"
              (click)="checkLayout(data);islayout = false">{{data.dashboardName}}</mat-option>
          </mat-select>
        </div>
      </span>
   </div>
    </div>
    <div style="float: right;height: 100%;" class="cust-font-width">
      <div class="cust-font">
        <app-manage-control (isSaveClick)="getCustomerLogo($event)"></app-manage-control>

      </div>
      <div class="show-user-role">
        {{userName | titlecase}}

      </div>
      <div class="show-user">
        {{user}}  </div>
    </div>
    <div style="float: right;height: 100%;margin-right: 10px;" class="notification-view">
      <app-toolbar-notification></app-toolbar-notification>
    </div>
    <div class="show-fullscreen">
        <button mat-icon-button style="width: 28px;border-radius: 20px;margin-top: 11px;height: 28px;cursor: pointer;background: #dedede;" (click)="checkFullscreen()">
        <mat-icon style="width: 25px;font-size: 16px;line-height: 20px;color: #000000c4;
            vertical-align: top;">{{isFullScreen ? 'fullscreen_exit' : 'fullscreen'}}</mat-icon>
      </button>
    </div>
    <app-globalsearch-component class="search-field" style="float: right;padding: 10px 2px;height:100%;"></app-globalsearch-component>
  </div>
  <div class="cust-logo">
    <img alt="customer-logo" src="{{customerLogo}}"
      style="width: 60%;height: 100%;object-fit: contain;" (error)="logoNotfound($event)"/>
  </div>
</div>

<div style="height: 55px;" class="mobile-view-1">
  <div class="wrapper-1">
    <input #fabOpen [(ngModel)]="isOpen" type="checkbox" />
    <div class="fab" i18n-tooltipText appTooltip tooltipText="fab-menu" placement="bottom" style="color: #103f96; text-align: center;">
      <mat-icon class="fab-menu-icons">more_vert</mat-icon>
    </div>
    <div class="fac" *ngIf="fabMenuClose">

      <div class="fab-display" (click)="addProfile()">
            <mat-icon class="fab-menu-icons" i18n-tooltipText appTooltip tooltipText="profile" placement="left">account_circle</mat-icon>
      </div>

      <div class="fab-display">
        <a i18n matLine class="help-txt-1 ovi-font-family" [attr.href]="helpDocument" target="_blank">
                <mat-icon class="fab-menu-icons" i18n-tooltipText appTooltip tooltipText="help" placement="left">help</mat-icon>
        </a>
      </div>

      <div class="fab-display" (click)="changePassword()">
            <mat-icon class="fab-menu-icons" i18n-tooltipText appTooltip tooltipText="changePassword" placement="left">how_to_reg</mat-icon>
      </div>

        <div class="fab-display" style="color: #cc0707"
            (click)="logout()">
            <mat-icon class="fab-menu-icons" i18n-tooltipText appTooltip tooltipText="logout" placement="left">exit_to_app</mat-icon>
      </div>
    </div>
  </div>
</div>
