<div class="ovi-font-family" style="width: 100%; height: 100%; background-color: inherit;overflow: hidden;">
    <mat-toolbar class="input-header" class="bg-clr" style="height: 10%;background-color: inherit;">
        <div class="header-close" style="width: 100%;margin-left: 95%;">
            <button mat-icon-button mat-dialog-close close-button>
                <mat-icon class="icon-style_1" style="color:#000000; font-size: 40px;">highlight_off</mat-icon>
            </button>
        </div>
    </mat-toolbar>
    <div style="width: 100%; height: 80%; background-color: inherit;overflow: auto;">
        <div style="height: 80%;margin-left: 10%;margin-right: 10%;">
            <img [src]="data.url" class="clickToEnlarge img-enlg"
                *ngIf="data.type != 'application/pdf' && data.type != 'application/msword' && data.type != 'text/plain'">
                <object style="width: 100%; height: 100%;" *ngIf="data.type == 'application/pdf'" [data]="urlSafe" type="application/pdf"></object>
            <div style="height: 100%;width:100%; text-align: center; background-color: white;"
                *ngIf="data.type == 'application/msword'"> Please click download to preview.
                <button i18n mat-button style="background:#317eed; color: white; margin-left: 10px; margin-top: 215px;"
                    (click)="downloadPreview();">Download</button>
            </div>
        </div>
        <object style="width: 100%; height: 100%; background: white;" *ngIf="isDownload" [data]="this.urlSafe" type="application/pdf">
        </object>
        <pre style="width: 100%; height: 100%; overflow:auto; background: white;" *ngIf="data.type == 'text/plain'" >{{data.url}}</pre>
    </div>
    <div class="input-footer" style="float: right;padding: 10px;">
        <button i18n mat-button class="cancle-btn" mat-dialog-close>Cancel</button>
    </div>
</div>