/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
export const base_value = {

  topic_sw_update: 'tw/web/ota',
  topic_gw_cache: 'tw/reader/location',

  lockingTimeout: 10000, /* <----- locking Timeout  (10 seconds) */
  idle_time_out: 14400, /* <----- Idle Time Out  (default : 14400 = 4 hours) */
  server_time_out: 180000, /* Server timeout (default : 30000 = 30 seconds) */
  server_time_out_error_msg: 'Looks like the server is taking to long to respond, Please try again in sometime',
  auth_card_error_msg: 'Automatically logged out due to inactivity!',
  set_interval: 60 * 1000,
  set_geo_loc_interval: 10000,
  set_monitor_interval: 30000,
  layout_autorefresh_time: 1 * 60 * 1000,
  lk : "TEAzMEFubWJVVCRwSzhUTQ==",

  get_app_terms: 'api/lookup-terms/get-app-terms',
  get_bulk_app_terms: 'api/lookup-terms/v2/get-bulk-app-terms',
  get_app_terms_data: 'api/lookup-terms/app-terms',
  get_app_terms_filter_data: 'api/lookup-terms/app-terms-filter',
  validate_app_terms_code: 'api/lookup-terms/validate',
  get_reader_type: 'api/lookup-terms/app-terms-link',
  enum_terms: 'api/lookup-terms/enum-terms',
  get_hardware_versions: 'api/hw/hardware-versions',
  get_app_terms_v2: 'api/lookup-terms/v2/get-app-terms',
  get_app_term_facility: 'api/lookup-terms/v3/get-app-terms-facility',
  get_form_template: 'api/pf-form-template/form-templates',

  // // GATEWAY SERVICE
  mqtt_gateway_broker: 'api/gateway/broker-details',

  // HEALTH CHECK
  get_all_health_test: 'api/health-plan/get-all-health-tests-details',
  update_health_test_location_availability: 'api/health-plan/update-health-test-location-availability',
  get_health_test_group: 'api/health-plan/get-health-test-group',
  get_health_test_available_locations: 'api/health-plan/available-locations',
  manage_patient_test: 'api/health-plan/manage-patient-tests',
  get_ot_health_plan: 'api/health-plan/health-plans',
  save_resource : 'api/permission/create-resource',
  save_resource_role : 'api/permission/create-resource-role-map',
  update_resource : 'api/permission/update-resource',
  get_all_resource: 'api/permission/get-all-resources',
  get_all_permission: 'api/permission/get-all-resource-role-map',
  permission_by_id : 'api/permission/permissions',
  
  // // TAG
  get_all_tag: 'api/tag/get-all-tags',
  save_import_tag: 'api/tag/create-bulk-tags',
  disassociate_tag: 'api/tag/disassociate-tag',
  search_all_details: 'api/tag/search-all-tag-mapping-details',
  search_non_associate_tag: 'api/tag/v1/non-associated-tags',
  
  // // READERS
  get_all_reader: 'api/reader/reader-devices',
  clear_cache: 'api/all/test/clear-all-caches',

   // // Config Management
   config_file:'api/all/config',
  
  // // ASSET
  get_asset_by_id: 'api/asset/get-asset',
  edit_asset: 'api/asset/update-assets',
  save_merge_record: 'api/patient/merge-patient',
  search_asset_by_categoryId: 'api/asset/assets-by-asset-type',
  get_all_movement_history: 'api/location/movement-history',

  // // PATIENT
  update_patient: 'api/patient/update-patient',
  update_clinical_details: 'api/patient/update-clinical-details',
  update_patient_diabetic: 'api/patient/update-patient-diabetic',
  search_patient: 'api/patient/get-all-search-patient',
  registered_patients: 'api/patient/registered-patient',
  assign_default_floor: 'api/patient/assign-default-floor',
  token_validate : 'api/patient/validate-token?tokenNo=',
  get_enroll_visit_option: 'api/patient/register-patient',

  // //PATIENT VISIT

  check_health_plan: 'api/patient-visits/get-health-plan-patients',
  update_patient_queue_status: 'api/patient-visits/update-queue-status',
  save_quick_registration: 'api/patient-visits/quick-patient-register',
  get_all_health_plan: 'api/patient-visits/get-all-health-plans',
  get_patient_info: 'api/patient-visits/get-patient-test-status-list',
  get_all_health_test_locations: 'api/location/get-all-locations-by-id',
  update_queue_status_location: 'api/patient-visits/update-queue-status-location',
  publish_test_status: 'api/patient-visits/publish-test-status',
  cancel_billing: 'api/patient-visits/cancellation',
  hc_patientlist_with_billed: 'api/patient-visits/hc-patientlist-with-billed',
  ip_discharge_patient: 'api/patient-visits/discharge-patient',
  visit_history: 'api/patient-visits/v2/patient-visits',

  // PORTER
  get_porter_history: 'api/porter/request-events',

  //  New services for porter request
  get_all_porter_request: 'api/porter/porter-requests',
  save_porter_request: 'api/porter/porter-requests',
  // porter_request_subject: 'api/porter/patient-by-porter-req-type', # old name may 28 2019 by Rahul

  // Ambulance
 
  
  // Ticket 
  save_ticket_request: 'api/all/ticket/ticket-request',
  update_ticket_request: 'api/all/ticket/ticket-requests',
  get_all_ticket_request: 'api/all/ticket/ticket-requests',
  get_all_attachment: 'api/all/ticket/ticket-url',

  // // CUSTOMER
  save_customer: 'api/customer/create-customer',
  update_customer: 'api/customer/update-customer',
  get_customer_logo: 'api/customer/get-customer-logo-image',
  get_customer_bg_image: 'api/customer/get-customer-bg-image',
  get_all_customer: 'api/customer/get-all-customers',
  get_customer_list: 'api/customer/get-all-customers-list',
  get_region_list: 'api/customer/get-all-region-list',
  get_facility_list: 'api/customer/get-all-facility-list',
  delete_customer: 'api/customer/delete-customer',
  get_all_customer_role: 'api/customer/customer-roles',
  save_customer_role: 'api/customer/assign-customer-role',
  get_multiple_images:'api/all/ticket/ticket-url',
  // // LOCATION

  get_location_by_id: 'api/location/get-location-by-id',
  get_all_location_by_id: 'api/location/get-all-locations-by-location-id',
  get_all_location_type: 'api/location/get-all-locations-type',
  get_to_location_search: 'api/location/get-search-locations',
  get_uhid: 'api/patient/patient-details',
  get_all_tag_by_type: 'api/tag/v2/non-associated-tags',
  get_bed_location_search: 'api/location/bed-search-locations',

  // Node
 

  //deletemultipleimagesin customer
  delete_multiple_images:'api/customer/customer-image',
   //delete user Education & proficiencies 
  delete_userProficiency:'api/user/user-proficiency',
  delete_userEducation:'api/user/user-education',



  // TICKET
  save_ticket: 'api/all/ticket/tickets',

  // USER
  get_user: 'api/user/get-all-users',
  get_user_v2: 'api/user/v2/get-all-users',
  get_user_img: 'api/user/get-user-image/',
  get_login_user: 'api/login/login-users',
  import_bulk_user: 'api/user/save-bulk-users',
  search_by_email: 'api/user/check-email',
  search_by_phoneno: 'api/user/check-phoneNumber',
  search_by_username: 'api/user/check-userName',
  search_doctor: 'api/user/staffs',
  search_name_by_recipient_type: 'api/user/user-list-or-role-list',
  get_user_by_id: 'api/user/get-userDetails-by-Id',
  get_all_shift: 'api/booking/shift-master-details',
  get_shift: 'api/booking/entity-schedule',
  get_all_departments: 'api/user/departments',
  get_all_user_list:'api/user/get-all-users',

  // Task
  get_user_detail_by_locId: 'api/user/users-by-location-id',
  save_task: 'api/porter/task',
  task_history: 'api/porter/task-history',
  update_task_routine_status: 'api/routine-management/update-entity-event-status',
  undo_task_routine: 'api/routine-management/activity-undo',

  // RULE ALERT
  pf_rule_api: 'api/rule-alert/pf-rules',
  alert_config_api: 'api/rule-alert/pfalert-configs',
  alert_config_api_by_id: 'api/rule-alert/pfalert-configs-by-id',
  alert_config_api_update: 'api/rule-alert/pfalert-configs',
  // alert_api: 'api/rule-alert/iot-alert',
  close_alert_api: 'api/rule-alert/close-alert',
  ack_alert : 'api/rule-alert/is-acknowledge',
  assign_alert: 'api/request/inpatient-request',
  get_reader_id: 'api/reader/details-by-floorId-and-readerType',
  get_alert_health_package: 'api/health-plan/health-packages',
  get_alert_health_tests: 'api/health-test/health-plan-tests',
  get_alert_routine: 'api/routine-management/routine-list',
  get_alert_routine_activity: 'api/routine-management/routine-activity-list',
  get_tag_id: 'api/tag/tag-filters',
  get_alert_task_count : 'api/ip-patient/alert-and-task-counts',

  // Health Test Rule

  // SSE
  sse_api: 'api/rule-alert/noti',

  // AUTH

  user_login: 'api/all/auth/login',
  login_token: 'api/all/auth/token-login',
  save_user: 'api/all/auth/signup-user',
  get_role: 'api/all/auth/get-all-roles',
  get_renew_token: 'api/all/auth/renew-token',
  forget_password: 'api/all/auth/forget-password',
  reset_password: 'api/all/auth/reset-password',
  change_password: 'api/auth/change-password',
  get_current_user: 'api/auth/get-current-user',
  edit_user: 'api/auth/updateuser',
  basic_login: 'api/all/auth/basic-login',
  infant_event: 'api/infant-event/infant-events',
  get_ALL_fetch_entity_data:'api/referral/entity-data-by-entity-id',
  

  //for dashboard management
  //Python
  layout_update : 'api/python-wrapper/reports/dashboard-layout/0/0/',
  user_layout : 'api/python-wrapper/reports/user-layout/0/0/',
  //JAVA
  //new 
  current_dashboard : 'api/all/dashboard/current-dashboard',
  dahsboard_by_id : 'api/all/dashboard/dashboards-by-ids',
  dashboard_user_widget: 'api/all/dashboard/user-widgets',
  dashboard_details_list: 'api/all/dashboard/dashboard-list',
  dashboard_widget_data: 'api/widget/widget-data',
  dashboard_layout_delete_by_id: 'api/all/dashboard/dashboards',
  

  // Widget management
  get_widget_list : 'api/widget/pf-widgets',
  get_pf_models : 'api/pf-model/pf-models',
  get_widget_templates : 'api/widget/widget-templates',
  save_widget_detail_by_id: 'api/widget/pf-widget-details',
  save_widget_detail: 'api/widget/pf-widgets',
  save_widget_model: 'api/widget/pf-models',

  // Form mangement
  get_form_templates : 'api/pf-form-template/pf-form-templates',
  get_form_templates_v2 : 'api/pf-form-template/v2/pf-form-templates',
  get_dataitem : 'api/widget/dataitem',
  save_form_template : 'api/pf-form-template/pf-form-templates',
  entity_form : 'api/entity-form/entity-forms',
  get_entity_form_history : 'api/entity-form/entity-form-histories',

  // For maintenance report
  // staff attendance report

  //health check reports,
  vitals_chart : 'api/python-wrapper/reports/vitals-chart/0/0/',
  hc_patientlist : 'api/python-wrapper/reports/hc_patientlist/0/0/',
  patient_summary : 'api/python-wrapper/reports/pat-summary/0/0/',
  get_shortestpath: 'api/python-wrapper/reports/shortestpath/0/0/',



  // menu items list
  menu_item_list: 'api/menu-item/menus',

  // config file
  config_url : 'api/all/config-file',

  // global search today based filter
  global_search_today: 'api/global-search/search-patient-details',
  global_search_by_key: 'api/search/global-search',

  // Outpatient Modules URL list
  get_op_patients_list: 'api/patient/op-patients-list',
  get_outpatient_info: 'api/patient-visits/op-patient-test-status-list',

  get_ip_patient_list: 'api/ip-patient/ip-patients',
  get_ip_patient_info: 'api/ip-patient/ip-patient-summary',
  get_ip_patient_info_by_id: 'api/ip-patient/ip-patient-visits',
  get_ip_speciality_loc: 'api/location/locations-by-location-category',
  get_porter_request_by_id: 'api/porter/porter-requests',



  // COASTER MESSAGE CENTRE
  tag_management_filter1: 'api/tag/tag-management-filter1',
  get_coaster_response: 'api/lookup-terms/v2/get-app-terms',
  save_message: 'api/rule-alert/v1/broadcast-message',
  get_coaster_message: 'api/rule-alert/iot-broadcast-alert',
  get_role_list1: 'api/all/auth/get-all-roles',
  get_role_list: 'api/all/auth/get-all-depts',

  // SCHEDULE
  get_all_report_schedule: 'api/schedule/report',
  save_report_schedule: 'api/schedule/report',
  update_report_schedule: 'api/schedule/report',

  // LOG
  get_audit_log_report: 'api/logging/audit-data',
  get_error_log_report: 'api/logging/application-errors',

  // EMPLOYEE
  employee_controller: 'api/employee/employees',
  import_bulk_employee: 'api/employee/employees/bulk-load',

  // VISITORS
  visitor_controller: 'api/visitor/visitors',

  // TEMPORARY ID CARD
  search_by_mainidentifer: 'api/employee/employees/search-by-mainidentifer',

  // SOFTWARE VERSION IN READER
  save_software_version: 'api/reader/reader-version',
  

  // ACTIVITIES
  get_all_activities: 'api/routine-management/activities',

  // ROUTINE-MANAGEMENT
  get_activity: 'api/routine-management/activity-names',
  get_all_routine: 'api/routine-management/routines',
  get_routine_activities: 'api/routine-management/routine-activities',
  get_routine_name: 'api/routine-management/search-routines',
  get_routine_list: 'api/routine-management/activity-list',
  add_routine: 'api/routine-management/entity-routine',
  activate_routine: '/api/routine-management/activate-routine',
  get_role_routine: 'api/routine-management/entity-routine-activities',
  end_routine: 'api/routine-management/end-routine',
  cancel_routine: 'api/routine-management/cancel-routine',
  routine_event_details: 'api/routine-management/entity-routine-activity-events',
  get_multiple_routine: 'api/routine-management/v2/entity-routine-activities',

  // TW TABLE
  get_tag_associated_details: 'api/public/customer/track/track-associate',



  // User Preference
  user_preferences: 'api/user/preferences',

  // Medical Records
  mr_create_request: 'api/all/external/mr-request',

  // Task
  task_details: 'api/booking/entity',


   //Advertisement
   create_order_Entry: 'api/release/v1/order',
   edit_order_entry: 'api/release/v1/orders',
   create_RO_Activity: 'api/release/v1/campaign/activities',
   edit_RO_Activity: 'api/release/v1/campaign/activities',
   get_all_RO: 'api/release/release-orders',
   get_all_campaign: 'api/release/order-campaign',
   get_all_RO_activity: 'api/release/activities',
   creat_agent: 'api/client-detail/client',
   update_agent: 'api/client-detail/client',
   createAgent_address: 'api/entity-address/address',
   editAgent_address: 'api/entity-address/address',
   get_all_agent: 'api/client-detail/clients',
   get_all_agent_address: 'api/entity-address/address',
   get_pf_workflow: 'api/pf-form-template/pf-workflows',
   multipartUpload: 'api/release/banner-stream',
   get_adv_uploads: 'api/all/file/file-url',

  baseUrl : 'api/python-wrapper/reports/',
  analytics_report : [
      {'id': 'patient_summary', 'name' : 'HC - Patient Summary', 'code' : 'WD_AIPRPS'},
      {'id': 'package_summary', 'name' : 'HC - Package Summary', 'code' : 'WD_AIPRPG'},
      {'id': 'test_summary', 'name' : 'HC - Test Summary', 'code' : 'WD_AIPRTS'},
      {'id': 'report1', 'name' : 'HC - TAT by Patient', 'code' : 'WD_AIPRTP'},
      {'id': 'report2', 'name' : 'HC - Health Test Performance', 'code' : 'WD_AIPRHT'},
      {'id': 'report3', 'name' : 'HC - TAT Detailed Report by Touchpoint', 'code' : 'WD_AIPRTT'},
      {'id': 'coaster-details', 'name' : 'HC - Coaster Return Report', 'code' : 'WD_AIPRCR'},
      {'id': 'geofencevio', 'name' : 'HC - Geo Fence Violation Report', 'code' : 'WD_AIPRGV'},
      {'id': 'open_encounter', 'name' : 'HC - Open Encounter Report', 'code' : 'WD_AIPROE'},
      {'id': 'pat-journey', 'name' : 'HC - Patient Journey Report', 'code' : 'WD_AIPRPJ'},
      {'id': 'tat-all-rep', 'name': 'HC - TAT All Report', 'code':'WD_AIPRTAR'},
      {'id': 'pat-sample-coll', 'name': 'HC - Patient Sample Collection Report', 'code':'WD_AIPRTSC'},
      {'id': 'visitor-summary', 'name': 'HC - Visitor Summary', 'code':'WD_AIPRVS'},
      {'id': 'in_patient', 'name' : 'IP - In Patients Report', 'code' : 'WD_AIPRIP'},
      {'id': 'vitals_chart', 'name' : 'IP - Vitals Chart Report', 'code' : 'WD_AIPRVC'},
      {'id': 'patientfall', 'name': 'IP - Patient Fall Report', 'code' : 'WD_AIPRPF'},
      {'id': 'nurse-call', 'name': 'IP - Nurse Call Efficiency Report', 'code': 'WD_AIPRNC'},
      {'id': 'patient-status-op', 'name' : 'OP - Out Patients Report', 'code' : 'WD_AIPROP'},
      {'id': 'out_patient_TAT', 'name' : 'OP - TAT Report', 'code' : 'WD_AIPROPTAT'},
      {'id': 'OT-Rep', 'name' : 'OT - Complex Utilization Report', 'code' : 'WD_AIPRCR'},
      {'id': 'tat-all-rep-ot', 'name': 'OT - TAT All Report', 'code':'WD_AIPRTAROT'},
      {'id': 'day_care', 'name' : 'DC - Day Care Report', 'code' : 'WD_AIPRDC'},
      {'id': 'newborn-summary', 'name': 'IS - New Born - Mother Summary', 'code' : 'WD_AIPRNB'}
  ],
  environment_reports : [
    {'id' : 'env-moni-rep', 'name' : 'Environment Monitoring Report', 'code':'WD_AIARAS'},
    {'id' : 'env-sum', 'name' : 'Summary Report', 'code':'WD_AIARAS'}
  ],
  employee_reports : [
      //{'id': 'contact-tree', 'name':'Employee Contact Tracing Report', 'code':'WD_AIERCT'},
      {'id': 'emp-summary-rep', 'name' : 'Employee Summary', 'code' : 'WD_AIERES'},
      {'id': 'emp-contact', 'name':'Employee Contact Tracing Report', 'code':'WD_AIERCT'},
      {'id': 'emp-ccd-all', 'name': 'Employee Close Contacts Report', 'code':'WD_AIPRCCR'},
      {'id': 'emp-ccd-all-byd', 'name': 'Employee Total Duration Contact Per Day Report', 'code':'WD_AIPRCCBDR'},
      {'id': 'emp-ccd-agg', 'name': 'Employee Close Contact By Location', 'code':'WD_AIPRCCBL'},
      //{'id': 'sensr-raw', 'name':'Sensor details', 'code':'WD_AIPRVS'},
      {'id': 'emp-loc-rep', 'name': 'Locationwise Contact Tracing Report', 'code':'WD_AIPRLW'},
      {'id': 'visitor-summary', 'name': 'Visitor Summary', 'code':'WD_AIPRVS'},
      {'id': 'temp-id-summary', 'name': 'Temporary ID Summary', 'code':'WD_AIPRVS'},
      {'id': 'temporary-staff-summary', 'name': 'Temporary Staff Summary', 'code':'WD_AIPRVS'},
      {'id': 'battery-summary', 'name': 'Battery Summary', 'code':'WD_AIPRVS'},
      {'id': 'roll-call', 'name':'Roll Call Report', 'code' : 'WD_AIERRC'},  
      {'id': 'emp-attendance-all', 'name' : 'All Employees Attendance Report', 'code' : 'WD_AIERAA'},  
      {'id': 'emp-attendance', 'name' : 'Employee Attendance Report', 'code' : 'WD_AIERIA'},
      {'id': 'emp-geofencevio', 'name': 'Employee Geofence Violation Report', 'code' : 'WD_AIERGF'},
      {'id': 'site-nav', 'name':'Site Visit and Navigation Report', 'code':'WD_AIPRVS'}
    ],
  asset_reports : [
    {'id': 'asset-summary', 'name': 'Asset Summary', 'code':'WD_AIARAS'},
    {'id': 'asset-transfer', 'name': 'Asset Transfer Report', 'code':'WD_AIARAT'},
    {'id': 'asset-missing', 'name': 'Asset Missing Report', 'code':'WD_AIARAMS'},
    {'id': 'asset-misplaced', 'name': 'Asset Misplaced Report', 'code':'WD_AIARAMP'},
    {'id': 'asset-geofencevio', 'name': 'Geofence Violation Report', 'code':'WD_AIARAG'},
    {'id': 'asset-utilz', 'name': 'Asset Utilization Report', 'code':'WD_AIARAU'},
    {'id' : 'asset-utiby-byid', 'name' : 'Asset Utilization By AssetId', 'code':'WD_AIARERAU'},
    {'id': 'asset-loc-history', 'name': 'Asset Location History', 'code':'WD_AIARALH'}
  ],
  porter_reports : [
    {'id': 'porter-req-summary', 'name': 'Porter Request Summary', 'code':'WD_AIPRSM'},
    {'id': 'patientmove', 'name': 'Patient Request Summary', 'code':'WD_AIPRPM'},
    {'id': 'asset-move', 'name': 'Asset Request Summary', 'code':'WD_AIPRAM'},
    {'id': 'othreq-move', 'name': 'Other Request Summary', 'code':'WD_AIPROM'},
    {'id': 'porter-perf', 'name': 'Porter Performance Summary', 'code':'WD_AIPRPPS'},
    {'id': 'porter-byward', 'name': 'Porter Report For Ward', 'code':'WD_AIPRRFW'},
    {'id': 'porter-ind-perf', 'name': 'Porter Individual Efficiency Report', 'code':'WD_AIPREFF'}
  ],
  staff_reports : [
    {'id': 'staff-summary-rep', 'name': 'Staff Summary', 'code':'WD_AISRSS'},
    {'id': 'staff-contact', 'name': 'Staff Contact Tracing Report', 'code' : 'WD_AISRCT'},
    {'id': 'staff-ccd-all', 'name':'Staff Close Contact Report', 'code':'WD_AISRCCD'},
    {'id': 'staff-attendance-all', 'name' : 'All Staff Attendance Report', 'code' : 'WD_AISRAA'},
    {'id': 'staff-attendancebyid', 'name' : 'Staff Attendance Report', 'code' : 'WD_AISRIA'},
    {'id': 'staff-battery-summary', 'name': 'Battery Summary', 'code':'WD_AISRVS'},
    {'id': 'staff-roll-call', 'name':'Roll Call Report', 'code' : 'WD_AISRRC'},
    {'id': 'staff-geofencevio', 'name': 'Staff Geofence Violation Report', 'code' : 'WD_AISRGF'},
    {'id': 'staff-site-nav', 'name':'Site Visit and Navigation Report', 'code':'WD_AISRVS'},
    {'id': 'visitor-history-byloc', 'name':'Visit History by Location', 'code':'WD_AIVHBL'}
  ],
  student_reports : [
    {'id': 'school-attendance-bystaff', 'name': 'Attendance Report By Staff', 'code':'WD_AISRARBS'},
    {'id': 'school-staff-attendance', 'name': 'Staff Attendance Report', 'code':'WD_AISRSAR'},
    {'id': 'student-attendance-all', 'name': 'Students Attendance Report', 'code':'WD_AISRSTAR'},
    {'id': 'student-attendance', 'name': 'Detailed Attendance By Student', 'code':'WD_AISRDABS'},
    {'id': 'student-attendance-byclass', 'name': 'Student Class Attendance Report', 'code':'WD_AISRSCAR'}
  ],
  inpatient_report : [  
    {'id': 'in_patient', 'name' : 'In Patient Summary', 'code' : 'WD_AIPRIP'},
    // {'id': 'OT-Rep', 'name' : 'OT Complex Utilization Report', 'code' : 'WD_AIPRCR'},   
    // {'id': 'tat-all-rep-ot', 'name': 'OT - TAT All Report', 'code':'WD_AIPRTAROT'},   
    {'id': 'vitals-chart', 'name' : 'Vitals Chart Report', 'code' : 'WD_AIPRVC'},
    {'id': 'geofencevio', 'name' : 'Geo Fence Violation Report', 'code' : 'WD_AIPRGV'},
    {'id': 'patientfall', 'name': 'Patient Fall Report', 'code' : 'WD_AIPRPF'},
    {'id' : 'asset-util-patient', 'name' : 'Asset Utilization By Location', 'code':'WD_AIARERABW'},
    {'id': 'nurse-call', 'name': 'Nurse Call Efficiency Report', 'code': 'WD_AIPRNC'}, 
    {'id': 'patient-nav', 'name': 'Patient Visit And Navigation Report', 'code': 'WD_AIPRPVN'},
    {'id': 'call-alert', 'name': 'Patient Call Alert', 'code': 'WD_AIPRPCA'},
    {'id': 'cons-visit-hist', 'name': 'Consultant Visit History', 'code': 'WD_AIPCVH'},
    {'id': 'cons-visit-hist-bywad', 'name': 'Consultant Visit History by Ward', 'code': 'WD_AIPCVHW'}
  ],
  operationtheatre_report : [
    {'id': 'OT-Rep', 'name' : 'OT Complex Utilization Report', 'code' : 'WD_AIPRCR'},
    {'id': 'OT-move', 'name' : 'OT Movement Report', 'code' : 'WD_AIPRMR'},
    {'id': 'OT-utilz', 'name' : 'OT Location Utilization Report', 'code' : 'WD_AIPRLUR'},
    {'id': 'OT-wait', 'name' : 'OT Waittime Report', 'code' : 'WD_AIPRWTR'},
    {'id': 'OT-daily', 'name' : 'OT Room Utilization by Day', 'code' : 'WD_AIPROTDR'}
    // {'id': 'tat-all-rep-ot', 'name': 'OT - TAT All Report', 'code':'WD_AIPRTAROT'}
  ],
  medicalrecord_report : [
    // {'id': 'pat-mrid-list', 'name': 'Medical Record Files Report', 'code': 'WD_AIPRMFR'},
    {'id': 'mrfile-summary', 'name': 'MRD Summary Report', 'code': 'WD_AIPRMSUM'},
    {'id': 'mrfile-age', 'name': 'MRD File Aging Report', 'code': 'WD_AIPRMFA'},
    {'id': 'mrfile-missing', 'name': 'MRD Missing Report', 'code': 'WD_AIPRMMR'},
    {'id': 'mrfile-status', 'name': 'MRD Status Report', 'code': 'WD_AIPRMSTS'},
    {'id': 'mrfile-loc-history', 'name': 'MRD Location History', 'code': 'WD_AIPRMLH'},
    {'id': 'mrtag-status', 'name': 'MRD tag status', 'code': 'WD_AIPRMTS'}
  ],
  resident_report : [  
    {'id': 'patient-status-rs', 'name' : 'Resident Summary', 'code' : 'WD_AIRSPS'}, 
    {'id': 'vitals-chart-rs', 'name' : 'Vitals Chart Report', 'code' : 'WD_AIRSVC'},
    {'id': 'geofencevio', 'name' : 'Geo Fence Violation Report', 'code' : 'WD_AIRSGV'},
    {'id': 'patientfall', 'name': 'Patient Fall Report', 'code' : 'WD_AIRSPF'},
    {'id': 'nurse-call-rs', 'name': 'Nurse Call Efficiency Report', 'code': 'WD_AIRSNC'}      
  ],
  infantsecurity_report : [
    {'id': 'newborn-summary', 'name': 'New Born - Mother Summary', 'code' : 'WD_AIPRNB'},
    {'id': 'geofence-rep', 'name': 'Geofence Report', 'code' : 'WD_AIPRNBGR'},
    {'id': 'mov-rep', 'name': 'Infant Movement Report', 'code' : 'WD_AIPRNBMR'},
    {'id': 'vitals-infant', 'name': 'Vitals Chart Report', 'code' : 'WD_AIPRNBVC'},
    {'id': 'infant-alert', 'name': 'Infant Alerts', 'code' : 'WD_AIPRNBIA'}
  ] ,
 daycare_reports : [
  {'id': 'day_care', 'name' : 'DayCare Summary', 'code' : 'WD_AIPROP'}
],
 outpatient_reports : [
  {'id': 'patient-status-op', 'name' : 'Out Patient Summary', 'code' : 'WD_AIPROP'},
  {'id': 'out_patient_TAT', 'name' : 'TAT Report Out Patient', 'code' : 'WD_AIPROPTAT'},
  {'id': 'geofencevio', 'name' : 'Geo Fence Violation Report', 'code' : 'WD_AIPRGV'}
],
maintenance_reports : [
  {'id': 'maintenancerep', 'name' : 'Maintenance Summary', 'code' : 'WD_AIMRS'},
  {'id': 'battery-history', 'name' : 'Device Battery History', 'code' : 'WD_AIMRBH'}
],
  healthCheckup_reports : [
      {'id': 'patient-status', 'name' : 'Patient Summary', 'code' : 'WD_AIPRPS'},
      {'id': 'hc_plan_summary2', 'name' : 'Package Summary', 'code' : 'WD_AIPRPG'},
      {'id': 'hc_test_summary2', 'name' : 'Test Summary', 'code' : 'WD_AIPRTS'},
      {'id': 'report1', 'name' : 'TAT by Patient', 'code' : 'WD_AIPRTP'},
      {'id': 'report2', 'name' : 'Health Test Performance', 'code' : 'WD_AIPRHT'},
      {'id': 'report3', 'name' : 'TAT Detailed Report by Touchpoint', 'code' : 'WD_AIPRTT'},
      {'id': 'pat-sample-coll', 'name' : 'Patient Sample Collection Report', 'code' : 'WD_AIPRPSC'},
      {'id': 'coaster-details', 'name' : 'Coaster Return Report', 'code' : 'WD_AIPRCR'},
      {'id': 'geofencevio', 'name' : 'Geo Fence Violation Report', 'code' : 'WD_AIPRGV'},
      {'id': 'open-encounter', 'name' : 'Open Encounter Report', 'code' : 'WD_AIPROE'},
      {'id': 'pat-journey', 'name' : 'Patient Journey Report', 'code' : 'WD_AIPRPJ'},
      {'id': 'emp-contact', 'name': 'Contact Tracing Report', 'code' : 'WD_AIPRPC'},
      {'id': 'tat-all-rep', 'name': 'TAT All Report', 'code':'WD_AIPRTAR'},
      {'id': 'pat-tag-status', 'name': 'Tag Status', 'code':'WD_AIPRTSR'}
    ]
};
