<div class="toolbar-notification-container wd-styl">
	<button mat-icon-button (click)="isOpen = !isOpen;" [ngClass]="[cssPrefix+'-btn']" [class.open]="isOpen">
      <mat-icon class="opy-styl" i18n-matTooltip matTooltip = "Setting">settings</mat-icon>
    </button>
	<div class="dropdown1 mat-elevation-z4" [class.open]="isOpen">
	<div class="card colr-styl">
      <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="title">
          <div class="name">Manage Dashboard</div>
        </div>
            <div class="example-button-row dsp-styl ovi-font-family">
                <button mat-raised-button (click)="changeView('OP');" [id]="OPviewPer">OP</button>
                <button mat-raised-button (click)="changeView('IP');" [id]="IPviewPer">IP</button>
              </div>
          <span fxFlex></span>
          <button type="button" mat-icon-button (click)="delete()">
            <mat-icon class="cancel">cancel</mat-icon>
          </button>
      </div>
      <div class="form-check" *ngFor="let permission of permissionList">
        <div *ngIf="permission.checked == true">
            <input type="checkbox" checked (change)="onChangeCategory($event, permission.id)">
            <label class="form-check-label" for="{{permission.id}}">
                {{permission.description}}
            </label>
        </div>
        <div *ngIf="permission.checked == false">
            <input type="checkbox"  (change)="onChangeCategory($event, permission.id)">
            <label class="form-check-label" for="{{permission.id}}">
                {{permission.description}}
            </label>
        </div>
      </div>
      <div class="footer" fxLayout="row" fxLayoutAlign="center center">
        <div class="action" (click)="markAllRead()">Select All</div>
      </div>
    </div>
  </div>
</div>
