<div class="input-component-holder">
    <mat-toolbar class="input-header">
        <div i18n class="pw-header-name" >Change Password</div>
        <div class="pw-header-close">
            <button mat-icon-button mat-dialog-close close-button class="ie-close-icon">
                <mat-icon class="icon-style">highlight_off</mat-icon>
            </button>
        </div>
    </mat-toolbar>
    <div>
        <form [formGroup]="passwordForm" novalidate>
            <div class="text-center custom-name popup-margin">
                <div class="form-row block-3 " style="display: flex;height: 60px; align-items: center;">
                <mat-label class="ovi-lbl-text-size" style="width:82px;">Old Password : </mat-label>
                <mat-form-field class="login-input" appearance ='outline'>
                    <input matInput i18n-placeholder placeholder="Old Password" [type]="hide ? 'text' : 'password'"
                        required formControlName="oldPassword" [errorStateMatcher]="matcher" autocomplete="off">
                    <!-- <mat-icon matSuffix (click)="hide = !hide" class="float-r">
                        {{hide ? 'vpn_key' : 'visibility_off'}}</mat-icon> -->
                        <span matSuffix (click)="hide = !hide" class="float-r" class="material-icons" style="color: darkgray;padding: 5px;font-size: 20px;">{{hide ? 'vpn_key_off' : 'vpn_key'}}</span>
                    <mat-error *ngIf="!passwordForm.controls.oldPassword.valid">
                        <span i18n
                            [hidden]="!passwordForm.controls['oldPassword'].errors.required && !passwordForm.controls['oldPassword'].touched">Password
                            is required.</span>
                    </mat-error>
                </mat-form-field><br></div>
                <div class="form-row block-3 " style="display: flex;height: 60px; align-items: center;">
                <mat-label class="ovi-lbl-text-size" style="width:82px;">New Password : </mat-label>
                <mat-form-field class="login-input" appearance ='outline'>
                    <input matInput i18n-placeholder placeholder="New Password" type="password"
                        formControlName="newPassword" [errorStateMatcher]="matcher" maxlength="26">
                    <mat-error *ngIf="!passwordForm.controls.newPassword.valid">
                        <span i18n
                            [hidden]="!passwordForm.controls['newPassword'].errors.required && !passwordForm.controls['newPassword'].touched">New
                            Password is required.</span>
                    </mat-error>
                </mat-form-field><br>
                </div>
                <div class="form-row block-3 " style="display: flex;height: 60px; align-items: center;">
                <mat-label class="ovi-lbl-text-size" style="width:82px;">Confirm New Password : </mat-label>
                <mat-form-field class="login-input" appearance ='outline'>
                    <input matInput i18n-placeholder placeholder="Confirm New Password" type="password"
                        formControlName="cnfrmNewPassword" [errorStateMatcher]="matcher" maxlength="26">
                    <mat-error i18n
                        *ngIf="passwordForm.controls['cnfrmNewPassword'].pristine || passwordForm.controls['cnfrmNewPassword'].errors?.MatchPassword">
                        Password does not match</mat-error>
                </mat-form-field>
            </div>
        </div>
            <div class="text-center">
                <button mat-button style="border-radius: 20px;"class="active-btn margin-r-1 fp-btn-bodr" (click)="resetPassword();"
                    [disabled]="passwordForm.controls['cnfrmNewPassword'].pristine || passwordForm.controls['cnfrmNewPassword'].errors?.MatchPassword">Reset
                    Password</button>
            </div>
        </form>
    </div>
</div>