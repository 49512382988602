<div *ngIf="type !== 'ng-table'" style="width: 100%;height: 100%;">
    <div class="dash-table-header-label ovi-font-family" *ngIf="id == 'dashWid'">
        <div class="dash-title"><label style="font-weight: 600;">{{title}}</label></div>
        <div style="width: 100%;">
            <span *ngIf="enableExcel">
                <mat-icon style="font-size: 21px;float: right;padding-top: 1px;padding-right: 5px;cursor: pointer;"
                    (click)="downloadExcel()">description</mat-icon>
            </span>
            <span>
                <mat-icon style="font-size: 21px;float: right;padding-top: 1px;padding-right: 5px;cursor: pointer;"
                    (click)="isSearch = !isSearch;">search</mat-icon>
                <input i18n-placeholder matInput placeholder="Filter" *ngIf="isSearch"
                    style="height: 75%;width: 60%;border-bottom: 0.5px solid grey;float: right;margin-right: 5px;"
                    (keyup)="filterTable($event.target.value)" autocomplete="off" role="presentation">
            </span>            
        </div>
    </div>
    <div style="width: 100%;" [ngStyle]="{height : id == 'dashWid' ? 'calc(100% - 58px)' : '99%'}">
        <div fxLayout="column" class="tw-manage-component" [ngClass.xs]="'components-container-xs'" fxLayoutGap="5px"
            style="max-height: 100%;" [ngStyle]="{'box-shadow' : id == undefined ? '0px 0px 0px 0px' : ''}"
            *ngIf="showTable && id!='report2' && id != 'dashWid'">
            <div class="tw-mat-table-overflow">
                <table mat-table class="tw-table" [dataSource]="dataSource" matSort style="table-layout: inherit;">
                    <ng-container *ngFor="let col of HCDisplayedColumns" matColumnDef="{{col}}">
                        <th mat-header-cell
                            [hidden]="(id == 'mrfile-missing' && col == 'floor_id') || (id == 'mrfile-age' && col == 'floor_id')"
                            *matHeaderCellDef mat-sort-header class="tw-manage-table-header">
                            <span>{{col}}</span>
                        </th>
                        <ng-container *ngIf="col=='Location History' && id == 'porter-daily-tat-locHist'">
                            <td mat-cell *matCellDef="let element">
                                <span style="color: blue;text-decoration: underline;cursor: pointer;"
                                    (click)="viewLocationHistory(element)">view Details</span>
                            </td>
                        </ng-container>
                        <ng-container
                            *ngIf="col!='Hourly Contacts' && col !='Action' && col !='Asset type' && col !='Utilization'">
                            <td mat-cell
                                [hidden]="(id == 'mrfile-missing' && col == 'floor_id') || (id == 'mrfile-age' && col == 'floor_id')"
                                style="padding-right: 20px;" *matCellDef="let element;let i = index;">
                                <span
                                    (click)="(((id == 'mrfile-missing' && col == 'Last seen location') || (id == 'mrfile-age' && col == 'Lastseen location')) && element['floor_id'] != null && element['Tag serial no'] != null) ? getLastLocation(element) : ''"
                                    [ngClass]="{'loc-text': ((id == 'mrfile-missing' && col == 'Last seen location') || (id == 'mrfile-age' && col == 'Lastseen location')) && element['floor_id'] != null && element['Tag serial no'] != null}">{{element[col]}}</span>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="col=='Asset type'">
                            <td mat-cell *matCellDef="let element">
                                <span style="color: blue;">{{element[col]}}</span>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="col=='Utilization'">
                            <td mat-cell *matCellDef="let element">
                                <span>{{element[col]}}<span
                                        style="color: blue;font-size:10px;text-decoration: underline;padding-left: 2px;">
                                        View Details</span></span>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="col=='Hourly Contacts'">
                            <td mat-cell style="font-size:12px;flex-grow: 4;" *matCellDef="let element">
                                <canvas id="{{element[col]}}" #empSD
                                    style="padding-top: 5px;padding-bottom: 5px;height: 90px;"></canvas>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="col=='Action'">
                            <td mat-cell *matCellDef="let element">
                                <button i18n mat-button appDebounceClick
                                    style="border-radius: 3px;background:#f33c3c;color:white;"
                                    (debounceClick)="removeLocationBoundary(element.id)" [debounceTime]="300"
                                    cdkFocusInitial> Delete
                                </button>
                            </td>
                        </ng-container>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="HCDisplayedColumns;sticky: true;" id="ang-table-header"
                        class="tw-manage-table-header-row"></tr>
                    <tr mat-row *matRowDef="let row; columns: HCDisplayedColumns; let i = index;"
                        [ngStyle]="{background : id == 'mrfile-age' && row.Fileage == '>11' ? '#5CD6CC' : id == 'mrfile-age' && row.Fileage == '6 to 10' ? '#84D1FA' : id == 'mrfile-age' && row.Fileage == '1 to 5' ? '#8CEDCB' : '' }">
                    </tr>
                </table>
            </div>
            <mat-paginator *ngIf="page" #Paginator [pageSizeOptions]="[10, 20, 30, 40]" [showFirstLastButtons]="true"
                class="tw-paginator-sticky" style="border-radius: 5px;"></mat-paginator>
        </div>

        <!-- dashboard table start -->
        <div fxLayout="column" class="tw-manage-component" [ngClass.xs]="'components-container-xs'" fxLayoutGap="5px"
            style="max-height: 100%;" [ngStyle]="{'box-shadow' : id == undefined ? '0px 0px 0px 0px' : ''}"
            *ngIf="showTable && id == 'dashWid'">
            <div class="tw-mat-table-overflow">
                <table mat-table class="tw-table" [dataSource]="dataSource" matSort>
                    <ng-container *ngFor="let col of HCDisplayedColumns;let i = index;" matColumnDef="{{col}}">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header [ngStyle]="styles['th']">
                            <span>{{HCDisplayedLabel[i]}}</span>
                        </th>
                        <ng-container>
                            <td mat-cell *matCellDef="let element;let i = index;" [ngStyle]="styles['tr']">
                                <span
                                    [ngStyle]="{color:  (tableStyle.hasOwnProperty('colName') && tableStyle.colName == col) ? (tableStyle.hasOwnProperty('colValue') && tableStyle.colValue != null && tableStyle.colValue.hasOwnProperty(element[col])) ? tableStyle.colValue[element[col]] : (tableStyle.hasOwnProperty('colColor') && tableStyle.colColor != '') ? tableStyle.colColor : '' : ''}">{{element[col]}}</span>
                            </td>
                        </ng-container>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="HCDisplayedColumns;sticky: true;" style="height: 45px;"></tr>
                    <tr mat-row *matRowDef="let row; columns: HCDisplayedColumns; let i = index;"
                        [ngStyle]="{background: (tableStyle.hasOwnProperty('rowValue') && tableStyle.rowValue != null && tableStyle.rowValue.hasOwnProperty(row[tableStyle.colName])) ? tableStyle.rowValue[row[tableStyle.colName]] : ''}">
                    </tr>
                </table>
            </div>
            <mat-paginator *ngIf="page" #Paginator [pageSizeOptions]="pageSizeOptions"
                [showFirstLastButtons]="true" class="tw-paginator-sticky" style="border-radius: 5px;">
            </mat-paginator>
        </div>
        <!-- dashboard table end -->

        <div fxLayout="column" class="tw-manage-component transpose-container" [ngClass.xs]="'components-container-xs'"
            fxLayoutGap="5px" *ngIf="showTable && id =='report2'">
            <div class="tw-mat-table-overflow">
                <table mat-table class="tw-table" [dataSource]="dataSource" matSort style="table-layout: inherit;">
                    <ng-container *ngFor="let col of HCDisplayedColumns" matColumnDef="{{col}}">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tw-manage-table-header">
                            <span>{{col}}</span>
                        </th>
                        <ng-container>
                            <td mat-cell style="padding-right: 20px;" *matCellDef="let element;let i = index;">
                                <span>{{element[col]}}</span>
                            </td>
                        </ng-container>
                    </ng-container>
                    <tr mat-row *matRowDef="let row; columns: HCDisplayedColumns; let i = index;"></tr>
                </table>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showTable && type === 'ng-table'" style="width: 100%;height: 100%;">
    <div fxLayout="column" class="tw-manage-component" [ngClass.xs]="'components-container-xs'" fxLayoutGap="5px"
        style="max-height: 100%;">
        <div class="tw-mat-table-overflow">
            <mat-table #table [dataSource]="dataSource" matSort style="height: calc(100% - 70px); overflow: auto;">
                <ng-container *ngFor="let col of displayedData; let colIndex = index;"
                    matColumnDef="{{displayedColumns[colIndex]}}">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="manage-table-header"
                        style="justify-content: flex-start;"> {{displayedColumns[colIndex]}} </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span *ngIf="displayedColumns[colIndex] !== 'edit' && displayedColumns[colIndex] !== 'Status' && displayedColumns[colIndex] !== 'Associated Date'&& displayedColumns[colIndex] !== 'Dissociated Date'&& displayedColumns[colIndex] !== 'Start Date' && displayedColumns[colIndex] !== 'End Date'&& displayedColumns[colIndex] !== 'From Time'&& displayedColumns[colIndex] !== 'To Time'
                       && displayedColumns[colIndex] !== 'Date' &&   displayedColumns[colIndex] !== 'startDate' && displayedColumns[colIndex] !== 'Graduation' && displayedColumns[colIndex] !== 'Field Of Study' && displayedColumns[colIndex] !== 'Institution Name'
                       &&   displayedColumns[colIndex] !== 'endDate'  &&   displayedColumns[colIndex] !== 'Notes' && displayedColumns[colIndex] !== 'Title' 
                       && displayedColumns[colIndex] !== 'Organisation Name' && displayedColumns[colIndex] !== 'Address' &&   displayedColumns[colIndex] !== 'Note' && displayedColumns[colIndex] !== 'View History'  && displayedColumns[colIndex] !== 'Preview'  && displayedColumns[colIndex] !== 'Edit'  && displayedColumns[colIndex] !== 'Delete' && displayedColumns[colIndex] !== 'Action' && displayedColumns[colIndex] !== 'isActive'" class="table-text">{{element[col]}}</span>
                        <span *ngIf="displayedColumns[colIndex] === 'Status' || displayedColumns[colIndex] === 'isActive'" class="table-text">
                            {{element[col] === true ? 'Active' : 'inActive'}}</span>
                        <span *ngIf="displayedColumns[colIndex] === 'edit'">
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon class="activity-edit1">more_horiz</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button *ngFor="let menu of menuButton" mat-menu-item>
                                    <span style="display: inline-block;width: 100%;" 
                                    (click)="actionTrigger(menu.type, element, menu.value)">{{menu.name}}</span>
                                </button>
                            </mat-menu>
                        </span>
                        <span *ngIf="displayedColumns[colIndex] === 'Start Date'|| displayedColumns[colIndex] === 'End Date'">
                            {{element[col] | date: "dd MMM YYYY" }}
                        </span>
                        <span *ngIf="displayedColumns[colIndex] === 'Associated Date' || displayedColumns[colIndex] === 'Dissociated Date'">
                            {{element[col] | date: 'dd/MM/yyyy hh:mm a' }} 
                        </span>
                        <span *ngIf="displayedColumns[colIndex] === 'Date'|| displayedColumns[colIndex] === 'From Time'|| displayedColumns[colIndex] === 'To Time'">
                            {{element[col] | date: "hh:mm a, d MMM" }}
                        </span>
                        <span *ngIf="displayedColumns[colIndex] === 'startDate' || displayedColumns[colIndex] === 'endDate'">
                            {{element[col] | date: "d MMM YYYY" }}
                        </span>
                        <span *ngIf="displayedColumns[colIndex] === 'Graduation'||displayedColumns[colIndex] === 'Field Of Study'|| displayedColumns[colIndex] === 'Institution Name'||displayedColumns[colIndex] === 'Notes' || displayedColumns[colIndex] === 'Title' || displayedColumns[colIndex] === 'Organisation Name' || displayedColumns[colIndex] === 'Address' || displayedColumns[colIndex] === 'Note' " 
                        style="width: 90%;height: 15px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap; ">
                            {{element[col]}}
                        </span> 
                        <span *ngIf="displayedColumns[colIndex] === 'View History'">
                            <button i18n mat-button appDebounceClick style="border-radius: 3px;background:#65a95b;color:white;line-height: 23px;padding: 0 10px;font-size:10px"
                            (click)="actionTrigger('view', element, null)" [debounceTime]="300" cdkFocusInitial> View History
                            </button>
                        </span>
                        <span *ngIf="displayedColumns[colIndex] === 'Preview'">
                            <button i18n mat-button appDebounceClick style="border-radius: 3px;background:#f33c3c;color:white; line-height:23px;padding: 0 5px;font-size:10px; min-width: 45px"
                            (click)="actionTrigger('preview', element, null)" [debounceTime]="300" cdkFocusInitial> Preview
                            </button>
                        </span>
                        <span *ngIf="displayedColumns[colIndex] === 'Edit'">
                            <button i18n mat-button appDebounceClick style="border-radius: 3px;background:#f33c3c;color:white; line-height:22px;padding: 0 5px;font-size:10px;min-width: 40px"
                            (click)="actionTrigger('edit', element, null)" [debounceTime]="300" cdkFocusInitial> Edit
                            </button>
                        </span>
                        <span *ngIf="displayedColumns[colIndex] === 'Delete'">
                            <button i18n mat-button appDebounceClick style="border-radius: 3px;background:green;color:white; line-height:22px;padding: 0 5px;font-size:10px;min-width: 40px"
                            (click)="actionTrigger('delete', element, null)" [debounceTime]="300" cdkFocusInitial> Delete
                            </button>
                        </span>
                        <span *ngIf="displayedColumns[colIndex] === 'Action'">
                           <!-- <span style="text-decoration: underline; font-weight:600; cursor: pointer;"
                           (click)="actionTrigger(null, element, null)">Edit</span> -->
                           <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon class="activity-edit1">more_horiz</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button *ngFor="let menu of menuButton" mat-menu-item>
                                    <span style="display: inline-block;width: 100%;" 
                                    (click)="actionTrigger(menu.type, element, menu.value)">{{menu.name}}</span>
                                </button>
                            </mat-menu>
                        </span>
                        <!-- <span class="table-text">{{element[col]}}</span> -->
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns" class="manage-table-header-row"
                    style="min-height: 40px;height: 40px !important;"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"
                    style="min-height: 40px;height: 40px !important;">
                </mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[10, 15, 20, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>
