<div [formGroup]="layoutForm" *ngIf="showInput" class="ovi-font-family" style="height:100%">
    <div style="width: calc(100% - 15px);padding-top:5px;height: 10%;padding-left: 6px;">
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px" style="margin: 0px 10px;">
            <div style="margin:left;width:calc(100% - 260px);display: flex;">
            <div fxFlex.xs="33" fxFlex.lg="33" fxFlex.md="33" fxFlex.sm="33">
                <mat-label i18n class="ovi-lbl-text-size">Linked Resource Type</mat-label>
                <mat-form-field  style="width: 89%;" appearance="outline">
                    <mat-select i18n-placeholder  class= "ovi-lbl-text-size" placeholder="Linked Resource Type" formControlName="resourceType" (selectionChange)="getResourceFilter($event.value)">
                        <mat-option *ngFor="let typ of resTypList" [value]="typ.code">
                            {{typ.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex.xs="33" fxFlex.lg="33" fxFlex.md="33" fxFlex.sm="33">
                <mat-label i18n class="ovi-lbl-text-size">Linked Resource Name</mat-label>
                <mat-form-field style="width: 89%;"appearance="outline">
                    <mat-select i18n-placeholder  class= "ovi-lbl-text-size" placeholder="Linked Resource Name" formControlName="resourceCode" (selectionChange)="updateFactoryDisable($event.value)">
                        <mat-option *ngFor="let res of resNameList" [value]="res.code">
                            {{res.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex.xs="100" fxFlex.lg="100" fxFlex.md="50" fxFlex.sm="50">
                <mat-label i18n class="ovi-lbl-text-size">Layout Name</mat-label>
                <mat-form-field style="width: 90%;height: 45px;margin-top:3px"appearance="outline">
                    <input i18n-placeholder  class= "ovi-lbl-text-size" placeholder="Layout Name" matInput formControlName="layoutName"
                        autocomplete="off">
                        <mat-error *ngIf="layoutForm.controls.layoutName.invalid" class="error-txt-1">
                            <span  i18n [hidden]="!layoutForm.controls['layoutName'].errors.required">Layout name
                                is required.</span>
                        </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex.xs="100" fxFlex.lg="100" fxFlex.md="50" fxFlex.sm="50">
                <div style="width: 33px;padding-top: 30px;margin-right: 55px;">
                    <mat-checkbox i18n class="example-margin" [disabled]="FactoryLayDisable" formControlName="isFactory">Factory</mat-checkbox>
                </div>
            </div>
            </div>
            <div style="float: right; width: 250px ">
                <div style="width: 80px;float: right;" *ngIf="!layoutTabDisp && dashId !== undefined ">
                    <button i18n (click)="deleteWidgets()"
                        style="border-radius: 3px;border: 5px;margin-top: 20px;height: 35px;width: 80px;color: white;cursor: pointer;
                    touch-action: none;user-select: none;background: #2681f2;">DELETE</button>
                </div>
                <div style="width: 80px;float: right;margin-right: 10px;">
                    <button i18n
                        [ngStyle]="{ background : (enableSave) ? '#2681f2' : '#c0c3c5'}"
                        style="border-radius: 3px;border: 5px;margin-top: 20px;height: 35px;width: 80px;color: white;cursor: pointer;
                    touch-action: none;user-select: none;"
                        [disabled]="(!enableSave) && layoutForm.invalid" (click)="saveWidgets()"
                        i18n-matTooltip matTooltip="Save Layout">SAVE</button>
                </div>
            </div> 
        </div>
    </div>
    <div class="wid-swap" *ngIf="!spinLoader">
        <div style="width: 40%;height:calc(100% - 20px);background: #dcdcdc57;float: left;border-radius: 10px;box-shadow: 0px 0px 0px 1px grey;margin-left: 15px;">
            <div i18n  class="ovi-popup-title-text-size" style="text-align: center;width: 100%; height:6% ;margin-top: 2%;cursor: default;">
                Available Widgets
            </div>
            <mat-divider></mat-divider>
            <div style="height:50px;width: 100%;margin-top: 5px;">
                <div class="layout-search-input">
                    <span style="width: 85%; float: left;"><input type="text" class="layout-search-icon"
                        i18n-placeholder placeholder="Search" [formControl]="filterKey"
                            (keyup)="applyFilter(filterKey.value)"></span>
                    <span style="width: 15%; float: right;">
                        <button mat-icon-button id="search-icon" disabled style="width: 8%;">
                            <mat-icon
                                style="line-height: 8px !important;font-size: 25px;color: black;vertical-align: bottom;">
                                search</mat-icon>
                        </button>
                    </span>
                </div>
            </div>
            <mat-divider></mat-divider>
            <mat-nav-list style="overflow-x:hidden; height: 73%;">
                <mat-list-item *ngFor="let wid of widgetList" style="cursor: initial;padding: 0 12px;"
                    [disableRipple]="true">
                    <div style="width: 90%;" i18n-matTooltip matTooltip="{{wid.widgetName}}">
                        <input type="checkbox"
                            style="width: 18px; height: 18px; float: left; cursor: pointer;"
                            [checked]="wid.isActive" [disabled]="wid.isActive"
                            (click)="selectWidget($event.target.checked , wid, 'left')" #leftCheckboxes>
                        <div
                            style="width: 88%;float: right; margin-top: 2px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                            {{wid.widgetName}}</div>
                    </div>
                    <button mat-icon-button style="width: 35px;" (click)="moveWidget(wid, true)"
                        [disableRipple]="true">
                        <mat-icon i18n-matTooltip matTooltip="Add"
                            style="font-size: 20px;color: black;padding-top: 0px;margin-left: 0px;touch-action: none;user-select: none;"
                            *ngIf="!wid.isActive">add</mat-icon>
                    </button>
                    <mat-divider></mat-divider>
                </mat-list-item>
            </mat-nav-list>
        </div>
        <div style="width: 15%;height:calc(100% - 20px);float: left;">
            <div style="height: 50%;margin-block-start: 50px;text-align: center;margin-inline-start: 20px;">
                <mat-icon
                    style="line-height: 8px !important;vertical-align: bottom;margin-top: 150px;font-size: 40px;cursor: pointer;"
                    (click)="widgetData['left'].length ? moveWidgets('left', true) : ''" i18n-matTooltip
                    [matTooltip]="widgetData['left'].length ? 'Add widgets' : ''"
                    [ngStyle]="{color: widgetData['left'].length ? 'black' : 'gray'}">forward</mat-icon>
                <mat-icon style="line-height: 8px !important;color: black;vertical-align: middle;margin-top: -150px;margin-left: -10px;font-size: 40px;
                transform: rotate(180deg);cursor: pointer;"
                    (click)="widgetData['right'].length ? moveWidgets('right', false) : ''" i18n-matTooltip
                    [matTooltip]="widgetData['right'].length ? 'Remove widgets' : ''"
                    [ngStyle]="{color: widgetData['right'].length ? 'black' : 'gray'}">forward</mat-icon>
            </div>
        </div>
        <div style="width: 40%;height:calc( 100% - 20px);background: #dcdcdc57;float: right;border-radius: 10px;box-shadow: 0px 0px 0px 1px grey;margin-right: 10px;">
            <div i18n class="ovi-popup-title-text-size"
                style="text-align: center;width: 100%; height:6% ;margin-top: 2%;cursor: default;">
                Selected Widgets
            </div>
            <mat-divider></mat-divider>
            <mat-nav-list style="overflow-x:hidden; height: 85%; margin-top:20px">
                <mat-list-item *ngFor="let wid of activeWidget" style="cursor: initial;padding: 0 12px;"
                    [disableRipple]="true">
                    <div style="width: 90%;" i18n-matTooltip matTooltip="{{wid.widgetName}}">
                        <input type="checkbox"
                            style="width: 18px; height: 18px; float: left;cursor: pointer;"
                            (click)="selectWidget($event.target.checked , wid, 'right')" #rightCheckboxes>
                        <div
                            style="width: 88%;float: right; margin-top: 2px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                            {{wid.widgetName}}</div>
                    </div>
                    <button mat-icon-button style="width: 35px;" (click)="moveWidget(wid, false)"
                        [disableRipple]="true">
                        <mat-icon i18n-matTooltip matTooltip="Delete" style="font-size: 20px;color: black;padding-top: 0px;margin-left: 0px;
                    touch-action: none;user-select: none;">delete_outline</mat-icon>

                    </button>
                    <mat-divider></mat-divider>
                </mat-list-item>
            </mat-nav-list>
        </div>
    </div>
</div>
