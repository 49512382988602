<div class="sidebar-menu-wid ovi-font-family" *ngIf="this.isOpen && this.code !== 'MN_ALL'">
    <div *ngIf="this.code !== 'MN_DB' && this.code !== 'MN_DQ' && this.code !== 'MN_FP' && this.code !== 'MN_TA'" class="header-menu-name">{{menName}}</div>
    <mat-divider></mat-divider>
    <div style="overflow-y: auto;overflow-x: hidden;margin-left: -16px;" [style.max-height.px]="this.maxHeight" [style.height.px]="this.menuHeight">
        <mat-nav-list #drawer *ngFor="let menu of menus;" [ngClass]="customerName == 'kyn' ? 'kyn-select' : 'twselect'" class="sidemenu-divider-new">
            <mat-list-item *ngIf="menu.subMenus?.length === 0"
                (click)="menu.open = !menu.open; toggleView(this.menu.parentId);subMenuExpand(menu.parentId);menuDetails(this.menu)" [routerLink]="[menu.link]"
                [routerLinkActive]="['active']" appTooltip [tooltipText]="menu.name">
                <div class="menu-icon">
                    <img  class="svg-icon Logo" matListIcon
                        src="/assets/Menus/{{ menu.iconName }}.svg">
                </div>
                <div class="menu-name">
                    <h3 matLine class="menu-name-styl" *ngIf="!iconOnly"
                     placement="bottom">{{ menu.name }} </h3>
                </div>
                <mat-divider></mat-divider>
                <mat-icon *ngIf="menu.subMenus?.length !== 0" class="sidenav-dropdown-indicator rotate sidemenu"
                    [ngStyle]="{'margin-top': menu.open ? '0px' : '0px'}" [ngClass]="{'indicateOpen':menu.open}">
                    expand_more</mat-icon>
            </mat-list-item>
            <mat-list-item *ngIf="menu.subMenus?.length !== 0 && this.submenuId !== menu.id" (click)="menu.open = !menu.open;" appTooltip [tooltipText]="menu.name">
                <div class="menu-icon">
                    <img class="svg-icon Logo" matListIcon
                        src="/assets/Menus/{{ menu.iconName }}.svg">
                </div>
                <div class="menu-name">
                    <h3 matLine class="menu-name-styl" *ngIf="!iconOnly"
                     placement="bottom">{{ menu.name }} </h3>
                </div>
                <mat-divider></mat-divider>
                <mat-icon *ngIf="menu.subMenus?.length !== 0" class="sidenav-dropdown-indicator rotate sidemenu"
                    [ngStyle]="{'margin-top': menu.open ? '0px' : '0px'}" [ngClass]="{'indicateOpen':menu.open}">
                    expand_more</mat-icon>
            </mat-list-item>
            <mat-list-item *ngIf="menu.subMenus?.length !== 0 && this.submenuId === menu.id" (click)="submenuOpen = !submenuOpen" appTooltip [tooltipText]="menu.name">
                <div class="menu-icon">
                    <img  class="svg-icon Logo" matListIcon
                        src="/assets/Menus/{{ menu.iconName }}.svg">
                </div>
                <div class="menu-name">
                    <h3 matLine class="menu-name-styl" *ngIf="!iconOnly" placement="bottom">{{ menu.name }} </h3>
                </div>
                <mat-divider></mat-divider>
                <mat-icon *ngIf="menu.subMenus?.length !== 0" class="sidenav-dropdown-indicator rotate sidemenu"
                    [ngStyle]="{'margin-top': menu.open ? '0px' : '0px'}" [ngClass]="{'indicateOpen':!submenuOpen}">
                    expand_more</mat-icon>
            </mat-list-item>
            <mat-nav-list #drawer *ngFor="let submenu of menu.subMenus" class="sidemenu-divider-new">
                <mat-list-item *ngIf="menu.subMenus?.length !== 0 && menu.open || !submenuOpen && this.submenuId == submenu.parentId"
                    (click)="submenu.open = !submenu.open;toggleView(this.menu.parentId);subMenuExpand(submenu.parentId);menuDetails(this.menu)" [routerLink]="[submenu.link]"
                    [routerLinkActive]="['active']" appTooltip [tooltipText]="submenu.name">
                    <div class="menu-icon">
                    </div>
                    <div class="submenu-name">
                        <img class="svg-icon Logo" matListIcon
                            src="/assets/Menus/{{ submenu.iconName }}.svg">
                        <h3 matLine class="menu-name-styl" *ngIf="!iconOnly" placement="bottom">{{ submenu.name }} </h3>
                    </div>
                    <mat-divider></mat-divider>
                    <mat-icon *ngIf="submenu.subMenus.length !== 0" class="sidenav-dropdown-indicator rotate sidemenu"
                        [ngStyle]="{'margin-top': submenu.open ? '0px' : '0px'}"
                        [ngClass]="{'indicateOpen':submenu.open}"> expand_more</mat-icon>
                </mat-list-item>
            </mat-nav-list>
        </mat-nav-list>
    </div>
</div>
<div>
<div class="sidebar-menu-wid-all"  *ngIf="this.code === 'MN_ALL'"
    style="overflow-y: auto;overflow-x: hidden;" [style.max-height.px]="this.maxHeight">
    <div class="all-main-menu" [style.min-height.px]="this.height">
    <span *ngFor="let menu of menus" class="sidemenu-divider-new">
        <div *ngIf="menu.subMenus?.length !== 0 || menu.link !== null" class="all-menu"
            (click)="menu.open = !menu.open;toggleView(this.menu.id)" [routerLink]="[menu.link !== null ? menu.link : menu.subMenus[0].link]" routerLinkActive="main-menu-select">
                <img style="height: 18px;width: 18px;" class="svg-icon" matListIcon
                    src="/assets/Menus/B_{{ menu.iconName }}.svg">
                <div matLine class="menu-name-styl ovi-font-family" style="font-size: 12px;" *ngIf="!iconOnly" appTooltip [tooltipText]="menu.name" placement="bottom">{{ menu.name }} </div>
        </div>
    </span>
</div>
<div class="menu-line"></div>
<div class="all-main-menu help-menu-styl" [style.margin-top.px]="this.empUser">
    <span class="sidemenu-divider-new">
        <div class="help-menu" *ngIf = "activate_btn && activate_btn.includes('BT-HLP')">
            <a i18n matLine class="help-txt" [attr.href]="helpDocument" target="_blank">
                <mat-icon>help</mat-icon><br><span class="menu-name-styl ovi-font-family">Help</span></a>
        </div>
        <mat-divider style="margin-left: 20px;margin-right: 20px;"  *ngIf = "activate_btn && activate_btn.includes('BT-HLP')"></mat-divider>
        <div class="help-menu" (click)="addProfile()">
            <mat-icon>account_circle</mat-icon>
            <div i18n matLine class="menu-name-styl ovi-font-family" style="font-size: 12px;">Profile</div>
        </div>
        <mat-divider style="margin-left: 20px;margin-right: 20px;"></mat-divider>
        <div class="help-menu" style="padding-bottom: 25px;" (click)="changePassword()">
            <mat-icon>how_to_reg</mat-icon>
            <div i18n matLine class="menu-name-styl ovi-font-family" style="font-size: 12px;">Change Password</div>
        </div>
        <mat-divider style="margin-left: 20px;margin-right: 20px;"></mat-divider>
        <div class="help-menu" (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            <div i18n matLine class="menu-name-styl ovi-font-family" style="font-size: 12px;">Log Out</div>
        </div>
    </span>
</div>
</div>