<div class="toolbar-user-container">
    <div [class.open]="isOpen"  class="toolbar-user-btn" >
            <div class="name" *ngIf="nowFacility" (click)="newCustomerFind()"  (click)="isOpen = !isOpen" class="show-facility">{{nowFacility}}</div>
            <div class="name" *ngIf="nowFacility" (click)="newCustomerFind()"  (click)="isOpen = !isOpen" class="mobile-view-show-cust">{{nowCustomer}}</div>
            <div (click)="newCustomerFind()" (click)="isOpen = !isOpen" class="tlbr-styl" *ngIf="activate_btn && activate_btn.indexOf('BT_MCFE') > -1">
                    <mat-icon mat-icon-button class="icn-styl">keyboard_arrow_down</mat-icon>

            </div>
    </div>
    <div class="dropdown mat-elevation-z1 dropdown-1" [class.open]="isOpen" >
        <div class="content" style="margin:0px 20px 0px 20px;">
            <form [formGroup]="controlForm">
                <div class="form-row">
                    <div class="row-field">
                        <div class="example-form mrgn-styl">
                            <mat-form-field style="display: block;">
                                <mat-select i18n-placeholder placeholder="Customer" formControlName="customer" (click)="facilityFormClick()" (selectionChange)="customerChange($event.value)" [errorStateMatcher]="matcher">
                                    <mat-option *ngFor="let customer of customerList"  [value]="customer.id">
                                        {{customer.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="example-form">
                            <mat-form-field style="display: block;">
                                  <mat-select i18n-placeholder placeholder="Region" formControlName="region" (click)="facilityFormClick()" (selectionChange)="regionChange($event.value)" [errorStateMatcher]="matcher">
                                    <mat-option *ngFor="let region of regionList" [value]="region.id">
                                        {{region.name}}
                                    </mat-option>
                                </mat-select>

                            </mat-form-field>
                        </div>
                        <div class="example-form">
                            <mat-form-field style="display: block;">
                                  <mat-select i18n-placeholder placeholder="Facility"  formControlName="facility" (click)="facilityFormClick()" (selectionChange)="facilityChange($event.value)" [errorStateMatcher]="matcher">
                                    <mat-option *ngFor="let facility of facilityList" [value]="facility.id">
                                        {{facility.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                  </div>
                  <div class="input-footer algn-txt">
                    <button i18n mat-button class="cancle-btn" (click)="closeControl();">Cancel</button>
                    <button i18n mat-button class="active-btn margin-r-1 mrgn-styl_" cdkFocusInitial [disabled]="this.controlForm.invalid" (click)="facilitySave();">Ok</button>
                  </div>
               </form>
        </div>
    </div>
</div>
