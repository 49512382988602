<div class="ovi-font-family" appWindowResize (resized)="onWindowResized($event)" (resizedWidth)="onWindowResizedWidth($event)" [component]="'pop-up'"
[style.height.px]="popHeight" [style.width.px]="popWidth">
  <div class="div-styl_5">
    <div i18n="@@patientInfoTitle" class="titl-styl">
        {shownTitle, select, Patient Visit Summary
        {Patient Visit Summary} Visit Summary {Visit Summary}}</div>
    <button mat-icon-button mat-dialog-close class="patient-close-icon" style="width:52px">
        <mat-icon class="icon-style ie-close-icon mat-elevation-z0">highlight_off</mat-icon>
    </button>
</div>
<div class="patient-details">
    <div class=""></div>
    <div *ngIf="patientListView" class="patient-list">
        <mat-list role="list" class="mat-list-style" #patient>
            <mat-list-item role="listitem" *ngFor="let patient of patientList"
                [ngClass]="{'selectPatient': selectPatient == patient.patientId}"
                (click)="getPatientDetails(patient.patientId, patient.patientVisitId, patient.visitTypeId, patient.visitEventId)">
                <div mat-line class="patient-name">{{patient.patientName | titlecase}}</div>
                <div mat-line class="patient-uhid">{{patient.uhid}}, {{patient.tagId}}</div>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="patient-info patient-align" [ngStyle]="{width: patientListView ? '70%' : '94%'}" *ngFor="let patient of patientInfo">
        <div class="patient-info-widget">
            <div mat-line class="patient-info-widget-left row-field-5">
                <div class="patient-general-info">
                    <span class="patient-name" [ngStyle]="{width: visitTypeId === 'VT-IP' || visitTypeId === 'VT-EC' ? '65%' : '35%'}"
                        matTooltip="{{patient.patientName | titlecase}}">{{patient.patientName | titlecase}}</span>
                    <span *ngIf="patient.patientName != null && patient.tokenNo != null">&nbsp;</span>
                    <span class="patient-token-no" *ngIf="patient.tokenNo != null">[ {{patient.tokenNo}} ]</span>
                    <span class="patient-age" *ngIf="patient.age != null">{{patient.age}} yrs</span>
                    <span *ngIf="patient.age != null && patient.gender != null">/</span>
                    <span class="patient-gender" *ngIf="patient.gender != null">{{patient.gender}}</span>

                    <span *ngIf="patient.vipTypeId != null && patient.vipTypeId != 'General'">
                        <img style="float: right;padding-right: 10px;" src="/assets/Alert/common_icons/vip.svg" height="17px"
                            width="18px">
                    </span>
                </div>
                <div class="patient-uhid" style="display: inline-block;width: 98%;">
                    <span>{{patient.uhid}}</span>
                    <span *ngIf="patient.uhid != null && patient.visitTypeId != null">,
                    </span>
                    <span i18n>{{patient.visitTypeId === 'VT-OP' ? 'OP' : patient.visitTypeId === 'VT-IP' ? 'IP' : patient.visitTypeId === 'VT-DC' ? 'Day Care' :
                            patient.visitTypeId === 'VT-EC' ? 'EC' : 'HC'}}</span>
                    <span *ngIf="patient.visitIdentifier != null || patient.visitDate != null">&nbsp;(</span>
                    <span *ngIf="patient.visitIdentifier != null">{{patient.visitIdentifier}}</span>
                    <span *ngIf="patient.visitIdentifier != null && patient.visitDate != null">,&nbsp;</span>
                    <span *ngIf="patient.visitDate != null">{{patient.visitDate | date : 'dd/MM/yyyy'}}</span>
                    <span *ngIf="patient.visitIdentifier != null || patient.visitDate != null">)</span>
                </div>
                <div mat-line class="patient-device" style="width: 58%;">
                    <span *ngIf="visitTypeId === 'VT-IP' && visitEventTypeId !== 'VE-OT' && visitTypeId !== 'VT-EC'">{{patient.tagId}}</span>
                    <span *ngIf="visitTypeId === 'VT-HC' || visitTypeId === 'VT-OP' || visitEventTypeId === 'VE-OT' || visitTypeId === 'VT-EC'">{{patient.patientVisitTagId}}</span>
                    <span *ngIf="(visitTypeId === 'VT-HC' || visitTypeId === 'VT-OP' || visitEventTypeId === 'VE-OT' || visitTypeId === 'VT-IP' || visitTypeId === 'VT-EC') && (patient.tagTypeId === 'TT-CO' || patient.tagTypeId === 'TT-PA')"
                        class="patient-uhid font-weight">( <img [src] = "patient.tagId ? '/assets/Alert/common_icons/coaster.svg' : '/assets/Alert/common_icons/coaster-g.svg'"
                        width="18px" height="12px"> )</span>
                    <span *ngIf="visitTypeId === 'VT-HC' && patient.tagTypeId === 'TT-MB'"
                        class="patient-uhid font-weight">(<img src="/assets/Alert/common_icons/mobile_phone.svg" width="18px"
                            height="15px">)</span>
                    &nbsp;<span
                        *ngIf="visitTypeId === 'VT-HC' && patient.tagTypeId != null && patient.languageName != null">&nbsp;</span>
                    <span *ngIf="patient.languageName != null">{{patient.languageName}}</span>
                </div>
                <div class="patient-ward" *ngIf="visitTypeId === 'VT-IP'">
                    <span *ngIf="patient.locationName !== null">{{patient.locationName}}</span>
                </div>
                <span *ngIf="visitTypeId === 'VT-HC' || visitTypeId === 'VT-OP'" [hidden]="patient.tagId === null">
                    <button i18n mat-button class="active-btn" style="margin-left: 8px;margin-top: 10px;"
                        (click)="disAssociateCoaster(patient.patientVisitTagId)" cdkFocusInitial>Disassociate</button>
                </span>
                <span *ngIf="visitEventTypeId === 'VE-OT' || visitTypeId === 'VT-EC'" [hidden]="patient.tagId === null">
                    <button i18n mat-button class="active-btn" style="margin-left: 8px;margin-top: 10px;"
                        (click)="discharge()" cdkFocusInitial>Discharge</button>
                </span>
            </div>
            <div class="patient-info-widget-right row-field-5">
                <div *ngIf="visitTypeId === 'VT-HC' || visitTypeId === 'VT-IP' || visitTypeId === 'VT-EC'" class="package-name" [matTooltip]="patient.healthPlanName">
                    {{patient.healthPlanName}}</div>
                <div *ngIf="visitTypeId === 'VT-OP'" class="package-name" [matTooltip]="patient.consultantName">
                    {{patient.consultantName}}</div>
                <div mat-line class="clinical-details">
                    <div *ngIf="visitTypeId === 'VT-IP' && visitEventTypeId !== 'VE-OT' && visitTypeId !== 'VT-EC'" class="refresh" style="float: right;margin: -13px 8px 0px 0px">
                        <span class="refresh-icon"><i class="material-icons" i18n-matTooltip matTooltip="Refresh"
                                (click)="refresh()" style="cursor: pointer;">autorenew</i>
                        </span>
                    </div>
                    <span *ngIf="visitTypeId === 'VT-HC'">
                        <div *ngIf="patient.reportCollectedDate !== null" class="report-dispatch" style="display: inherit;">
                            <span i18n style="color: gray;"> Report Dispatched : </span>{{patient.reportCollectedDate | date: "hh:mm a, d MMM"}}</div>
                        <div *ngIf="patient.reportCollectedDate === null" class="report-dispatch" style="display: inherit;">
                            <button i18n *ngIf="patient.reportCollectedDate === null" mat-button class="active-btn" appDebounceClick (debounceClick)="reportCollect(patient)"
                                [debounceTime]="300">Dispatch Report</button>
                        </div>
                    </span>
                    <span i18n *ngIf="patient.isDiabetic === true">Diabetic</span>
                    <span
                        *ngIf="patient.isDiabetic === true && (patient.isFasting === true || patient.isVulnerable === true || patient.isPregnant === true)">,
                    </span>
                    <span i18n *ngIf="patient.isFasting === true">Fasting</span>
                    <span
                        *ngIf="patient.isFasting === true && (patient.isVulnerable === true || patient.isPregnant === true)">,
                    </span>
                    <span i18n *ngIf="patient.isVulnerable === true">Vulnerable</span>
                    <span *ngIf="patient.isVulnerable === true && patient.isPregnant === true">, </span>
                    <span i18n *ngIf="patient.isPregnant === true">Pregnant</span>
                    <span *ngIf="patient.iotAlertStatus === true">
                        <img style="float: right;padding-right: 10px;" [matTooltip]="patient.iotAlertMessage"
                            src="/assets/Alert/common_icons/warning.svg" height="15px" width="17px">
                    </span>
                </div>
                <div *ngIf="visitTypeId === 'VT-IP' || visitTypeId === 'VT-EC'">
                    <span *ngFor="let alerts of patient.alerts">
                        <mat-icon style="padding: 0px 4px;" *ngIf="alerts.alertCode === 'RU-MO'" i18n-matTooltip
                            [matTooltip]="alerts.message" class="patient-away"></mat-icon>
                        <mat-icon style="padding: 0px 4px;" *ngIf="alerts.alertCode === 'RU-FA'" i18n-matTooltip
                            [matTooltip]="alerts.message" class="fall-risk"></mat-icon>
                        <mat-icon style="padding: 0px 4px;" *ngIf="alerts.alertCode === 'RU-MI'" i18n-matTooltip
                            [matTooltip]="alerts.message" class="patient-missing"></mat-icon>
                        <mat-icon style="padding: 0px 4px;" *ngIf="alerts.alertCode === 'RU-GO'" i18n-matTooltip
                            [matTooltip]="alerts.message" class="geofence"></mat-icon>
                    </span>
                </div>
                <div mat-line class="patient-location"
                    *ngIf="visitTypeId === 'VT-HC' && patient.currentLocationName != ''">
                    <img src="/assets/Alert/common_icons/location.svg" height="13px" width="15px">
                    {{patient.currentLocationName}}
                </div>
                <div mat-line class="patient-location"
                    *ngIf="visitTypeId === 'VT-IP' || visitTypeId === 'VT-EC' && patient.currentLocationName != null">
                    <img src="/assets/Alert/common_icons/location.svg" height="13px" width="15px">
                    {{patient.currentLocationName}}
                </div>
                <div mat-line class="patient-location"
                    *ngIf="visitTypeId === 'VT-OP' && patient.currentLocationName != null">
                    <img src="/assets/Alert/common_icons/location.svg" height="13px" width="15px">
                    {{patient.currentLocationName}}
                </div>
            </div>
        </div>
        <div *ngIf="visitTypeId === 'VT-IP' || visitTypeId === 'VT-EC'" style="display: flex;" class="mat-tab-align"></div>

        <div *ngIf="visitTypeId === 'VT-HC' || visitTypeId === 'VT-OP' || visitTypeId === 'VT-EC' || (visitTypeId === 'VT-IP' && visitEventTypeId === 'VE-OT') || (visitTypeId === 'VT-IP' && data.workflow === 'DayCare')" class="patient-test-details block-test"
            [formGroup]="patientInfoForm" >
            <div class="patient-test row-field-info">
                <div i18n mat-line class="patient-test-lable">Next</div>
                <div class="patient-test-name">
                    <div mat-line class="patient-test-select-box"
                        *ngIf="isEnableDropdown === false && patient.healthTestId"
                        [matTooltip]="patient.healthTestName">{{patient.healthTestName}}</div>
                </div>
                <div class="edit-icon edit-icon-resize" *ngIf="isEnableDropdown === false && isVisitDateCheck == checkTodayDate && (visitTypeId === 'VT-HC' || visitTypeId === 'VT-OP')">
                    <img (click)="enableDropdown()" style="cursor: pointer;color: #3A7AFE;" src="/assets/Alert/common_icons/edit.svg"
                        height="15px" width="15px">
                </div>
                <div class="patient-test-name edit-icon-resize" *ngIf="isEnableDropdown === true">
                    <div class="edit-icon-with-select-box edit-icon-resize"
                        *ngIf="pendingInfo?.length != 0 && isCheckEnableDropdown === true && isVisitDateCheck == checkTodayDate && (visitTypeId === 'VT-HC' || visitTypeId === 'VT-OP')">
                        <img (click)="enableDropdown()" style="cursor: pointer;color: #3A7AFE;"
                            src="/assets/Alert/common_icons/edit.svg" height="15px" width="15px">
                    </div>
                    <div class="patient-test-select-box">
                        <mat-form-field appearance="outline">
                            <mat-select i18n-placeholder formControlName="testId" placeholder="Test name">
                                <mat-option *ngFor="let test of pendingTestInfo"
                                    (click)="changeEvent('test', test.testType, test.healthTestId)"
                                    [value]="test.healthTestId">
                                    {{test.healthTestName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="patient-location row-field-info">
                <div i18n mat-line class="location-label">Location</div>
                <div mat-line class="location-name" *ngIf="isEnableDropdown === false"
                    matTooltip="{{patient.locationName}}, {{patient.floorName}}">{{patient.locationName}},
                    {{patient.floorName}}</div>
                <div class="patient-location edit-icon-resize" *ngIf="isEnableDropdown === true">
                    <mat-form-field appearance="outline">
                        <mat-select i18n-placeholder formControlName="locationId" placeholder="Location">
                            <mat-option *ngFor="let location of testAvailableLocation"
                                (click)="changeEvent('location', '', '')" [value]="location.id">
                                {{location.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <!-- start current status  -->
            <div class="patient-location row-field-info" *ngIf="isEnableDropdown === true && patient.healthTestId">
                <div i18n mat-line class="location-label">Test Status</div>
                <div class="patient-location edit-icon-resize">
                    <mat-form-field appearance="outline">
                        <mat-select i18n-placeholder formControlName="curStatusId" placeholder="Status">
                            <mat-option class="status-waiting" *ngFor="let status of queueStatus"
                                (click)="statusChange(patient, status.code)" (change)="checkStatus(status.code)"
                                [value]="status.code">
                                {{status.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <!-- end surent status -->
            <div mat-line class="patient-test-status row-field-info" i18n-[matTooltip] [matTooltip] = "patient.inTime | date: 'shortTime'"
                *ngIf="isEnableDropdown === false && patient.currentStatusName != null">{{patient.currentStatusName}}
            </div>
            <div *ngIf="isEnableDropdown === false && patient.waitTime != null" class="patient-test-wait-time row-field-info-1">
                <div i18n mat-line class="wait-time-label">Wait Time</div>
                <div mat-line class="wait-time-value">{{patient.waitTime}} (Min)</div>
            </div>
            <div class="save-bth row-field-info" *ngIf="isEnableDropdown === true && isCheckEnableDropdown === false">
                <span style="padding-left: 10px;">
                    <button i18n mat-button class="active-btn" *ngIf="isEnableDropdown === true"
                        [disabled]="isEnableButton" (click)="updatePatientTest()"
                        style="line-height: 30px !important; min-width: 66px;">Save</button>
                </span>
            </div>
            <div class="refresh refresh-btn">

                <span class="refresh-icon"><i class="material-icons" i18n-matTooltip matTooltip="Refresh"
                        (click)="refresh()" style="cursor: pointer;">autorenew</i>
                </span>
                <span *ngIf="visitTypeId === 'VT-HC' && patient.tagTypeId === 'TT-CO' && isEnableDropdown === false"
                    [ngClass]="patient.healthTestId == null && completeInfo?.length !=0 ? 'email-icon-active': 'email-icon-inactive'">
                    <img *ngIf="isEnableMsg && patient.healthTestId" src="/assets/Alert/common_icons/resent_mail_active.svg" class="resent"
                        height="25px" width="25px" appDebounceClick [debounceTime]="300"
                         (debounceClick) = "sendMsgToCoster(patient, 'test_status')" i18n-matTooltip
                        matTooltip="Resend the status to coaster" alt="resent_mail_active">
                    <img *ngIf="isEnableMsg && patient.healthTestId == null && completeInfo?.length == 0"
                        src="/assets/Alert/common_icons/resent_mail_inactive.svg" class="resent" height="25px" width="25px"
                        appDebounceClick [debounceTime]="300"
                         (debounceClick) ="patient.healthTestId == null && completeInfo?.length !=0 ? sendMsgToCoster(patient, 'test_status') : ''"
                        i18n-matTooltip matTooltip="Resend the status to coaster" alt="resent_mail_inactive">


                </span>

            </div>
        </div>
        <div class="mat-group-style mbl-view">
            <mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="tabChanged($event)">
                <mat-tab *ngIf="visitTypeId === 'VT-HC' || visitTypeId === 'VT-OP' || visitEventTypeId === 'VE-OT' || visitTypeId === 'VT-EC' || (visitTypeId === 'VT-IP' && data.workflow === 'DayCare')" label="{{labeltext}}">
                    <mat-accordion #accordion="matAccordion">
                        <mat-expansion-panel  [expanded]="visitTypeId !== 'VT-EC' ? panelOpenState : ''" *ngIf="visitTypeId !== 'VT-EC'" (opened)="panelOpenState = true"
                            (closed)="panelOpenState = false" class="div-shadow cmn-lnr-grad-styl ovi-font-family">
                            <mat-expansion-panel-header class="pan-hdr-styl">
                                <mat-panel-title *ngIf="visitTypeId === 'VT-HC' || visitTypeId === 'VT-OP'" i18n class="pan-tit-styl">
                                    Pending
                                </mat-panel-title>
                                <mat-panel-title *ngIf="visitEventTypeId === 'VE-OT' || visitTypeId === 'VT-EC' || (visitTypeId === 'VT-IP' && data.workflow === 'DayCare')" i18n class="pan-tit-styl">
                                    Pending
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-list role="list" class="tot-wid-styl list-align">
                                <div class="tot-wid-styl" *ngFor="let pending of pendingInfo; let last = last">
                                    <mat-list-item role="listitem">
                                        <div style="width: 6%;" *ngIf="pending.testType === 'health_test_group'">
                                            <span style="cursor: pointer;margin-left: -18px;"
                                                *ngIf="visitTypeId !== 'VT-OP' || visitTypeId === null"
                                                (click)="menuTrigger.openMenu();groupInfo(pending.healthTestId, patient.healthPlanId);">
                                                <button mat-icon-button #menuTrigger="matMenuTrigger"
                                                    [matMenuTriggerFor]="menu">
                                                    <mat-icon style="font-size: 25px;">info</mat-icon>
                                                </button>
                                            </span>
                                            <span style="padding-left: 5px; cursor: pointer;">
                                                <mat-menu #menu="matMenu">
                                                    <div *ngFor="let group of testGroupInfo">
                                                        <div mat-menu-item>{{group.name}}</div>
                                                    </div>
                                                </mat-menu>
                                            </span>
                                        </div>
                                        <div style="width:6%;" *ngIf="pending.testType === 'health_test'">
                                        </div>
                                        <div class="cmn-wid-styl" style="width:25%;"
                                            [matTooltip]="pending.healthTestName">
                                            <span>{{pending.healthTestName}}</span>
                                        </div>
                                        <div style="width:25%;font-size: 10px;" [formGroup]="patientInfoForm">
                                            <span style="color: #F25454"
                                                *ngIf="pending.patientStatusId === 'QS-PE' && (isEditStatusTestId != pending.healthTestId || isEditStatusTesttype != pending.testType)">{{pending.patientStatusName}}</span>
                                            <span style="color: #6F4C1D"
                                                *ngIf="pending.patientStatusId === 'QS-NR' && (isEditStatusTestId != pending.healthTestId || isEditStatusTesttype != pending.testType)">{{pending.patientStatusName}}</span>
                                            <span style="color: #8252F0"
                                                *ngIf="pending.patientStatusId === 'QS-FL' && (isEditStatusTestId != pending.healthTestId || isEditStatusTesttype != pending.testType)">{{pending.patientStatusName}}</span>
                                            <span style="color: #23374A"
                                                *ngIf="pending.patientStatusId === 'QS-PC' && (isEditStatusTestId != pending.healthTestId || isEditStatusTesttype != pending.testType)">{{pending.patientStatusName}}</span>
                                            <span class="test-status"
                                                *ngIf="isEnableStatusDropdown === true && isEditStatusTestId === pending.healthTestId && isEditStatusTesttype === pending.testType">
                                                <mat-form-field appearance="outline">
                                                    <mat-select i18n-placeholder formControlName="statusId"
                                                        placeholder="Status">
                                                        <mat-option class="status-waiting"
                                                            *ngFor="let status of queueStatus"
                                                            (click)="statusChange(pending, status.code)"
                                                            (change)="checkStatus(status.code)" [value]="status.code">
                                                            {{status.value}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </span>
                                        </div>
                                        <div style="width:25%;font-size: 10px;" [formGroup]="patientInfoForm">
                                            <span class="test-status"
                                                *ngIf="pending.locationId != null && isEnableStatusDropdown === false">{{pending.locationName}}</span>
                                            <span class="test-status"
                                                *ngIf="isEnableStatusDropdown === true && isEditStatusTestId === pending.healthTestId && isEditStatusTesttype === pending.testType && hidePendingTestDD === true">
                                                <mat-form-field appearance="outline">
                                                    <mat-select i18n-placeholder formControlName="pendingLocId"
                                                        placeholder="Location">
                                                        <mat-option class="status-waiting"
                                                            *ngFor="let location of testAvailableLocation"
                                                            (click)="assignLocationToPendingTest(pending, location.id, pending.consultantId)"
                                                            [value]="location.id">
                                                            {{location.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </span>
                                        </div>
                                        <div style="width:25%;font-size: 10px;" [formGroup]="patientInfoForm">
                                            <span class="test-status"
                                                *ngIf="pending.consultantId != null && isEnableStatusDropdown === false">{{pending.consultantName}}</span>
                                            <span class="test-status"
                                                *ngIf="isEnableStatusDropdown === true && isEditStatusTestId === pending.healthTestId && isEditStatusTesttype === pending.testType && hidePendingTestDD === true">
                                                <mat-form-field appearance="outline">
                                                    <input i18n-placeholder matInput placeholder="Type here for Doctor" (keyup)="searchDoctor($event.target.value, 'doctor', $event)"
                                                        [matAutocomplete]="doctor" formControlName="consultantId">
                                                    <mat-autocomplete autoActiveFirstOption [displayWith]="this.doctorEnabled === true ? getDoctorList.bind(doctorList) : ''" #doctor="matAutocomplete">
                                                        <mat-option *ngFor="let option of doctorList" [value]="option.id"
                                                        (click)="assignLocationToPendingTest(pending, pending.locationId, option.id)">
                                                            <div class="tot-wid-styl">{{option.name}}</div>
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-form-field>
                                            </span>
                                        </div>
                                        <div *ngIf="pending.patientStatusId != 'QS-PC' && isVisitDateCheck == checkTodayDate && (visitTypeId === 'VT-HC' || visitTypeId === 'VT-OP' || visitTypeId === 'VT-EC' || (visitTypeId === 'VT-IP' && visitEventTypeId === 'VE-OT'))" class="edit-icon-resize">
                                            <span *ngIf="isEnableStatusDropdown === false"></span>
                                            <img (click)="enableStatusDropdown(pending.healthTestId, pending.testType)"
                                                style="cursor: pointer;color: #3A7AFE;" src="assets/Alert/common_icons/edit.svg"
                                                height="15px" width="15px">
                                        </div>
                                        <div style="width:10%;font-size: 12px;padding-left: 45px;"
                                            *ngIf="pending.reasonCode !== null">
                                            {{pending.reasonCodeName}}</div>
                                        <div style="width:0%;" *ngIf="last === true">
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div i18n class="tot-wid-styl_" *ngIf="pendingInfo?.length == 0">
                                    <div>No pending test !!</div>
                                </div>
                            </mat-list>
                        </mat-expansion-panel>

                        <mat-expansion-panel [expanded]="visitTypeId !== 'VT-EC' ? panelOpenState1 : ''" (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false"
                            class="div-shadow cmn-pnl-styl ovi-font-family">
                            <mat-expansion-panel-header class="pan-hdr-styl">
                                <mat-panel-title *ngIf="visitTypeId === 'VT-HC' || visitTypeId === 'VT-OP'" i18n class="fnt-styl-cmn">
                                    Completed
                                </mat-panel-title>
                                <mat-panel-title *ngIf="visitEventTypeId === 'VE-OT' || visitTypeId === 'VT-EC' || (visitTypeId === 'VT-IP' && data.workflow === 'DayCare')" i18n class="fnt-styl-cmn">
                                    Completed
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-list role="list" class="tot-wid-styl list-align">
                                <div *ngIf="completeInfo?.length !=0">
                                    <mat-list-item role="listitem">
                                        <div class="cmn-wid-styl_complete" style="width: 6%"></div>
                                        <div class="cmn-wid-styl" style="width: 40%"></div>
                                        <div i18n class="cmn-wid-styl-sub-head_complete"
                                            style="text-align: center;width: 20%">
                                            Location Name</div>
                                        <div i18n *ngIf="visitEventTypeId === 'VE-OT' || visitTypeId === 'VT-EC'" class="cmn-wid-styl-sub-head_complete"
                                            style="text-align: center;width: 20%">
                                            Start</div>
                                        <div i18n *ngIf="visitTypeId === 'VT-HC' || visitTypeId === 'VT-OP'" class="cmn-wid-styl-sub-head_complete"
                                            style="text-align: center;width: 20%">
                                            Completed
                                            At</div>
                                        <div i18n *ngIf="visitEventTypeId === 'VE-OT' || visitTypeId === 'VT-EC'" class="cmn-wid-styl-sub-head_complete"
                                            style="text-align: center;width: 20%">
                                            End</div>
                                        <div i18n *ngIf="visitTypeId === 'VT-HC' || visitTypeId === 'VT-OP'" class="cmn-wid-styl-sub-head_complete"
                                            style="text-align: center;width: 20%">
                                            Consult
                                            Time <span style="font-size: 9px;"> (hh:mm)</span></div>
                                        <div i18n *ngIf="visitEventTypeId === 'VE-OT' || visitTypeId === 'VT-EC'" class="cmn-wid-styl-sub-head_complete"
                                            style="text-align: center;width: 20%">
                                            Duration <span style="font-size: 9px;"> (hh:mm)</span></div>
                                        <div i18n class="cmn-wid-styl-sub-head_complete"
                                            style="text-align: center;width: 20%">
                                            Wait Time <span style="font-size: 9px;"> (hh:mm)</span>
                                            </div>
                                        <div i18n class="cmn-wid-styl-sub-head_complete" *ngIf="visitTypeId === 'VT-HC'"
                                            style="text-align: center;width: 20%">
                                            Review Date
                                            </div>
                                        <div class="cmn-wid-styl-sub-head_complete" *ngIf="visitTypeId === 'VT-HC'"
                                            style="text-align: center;width: 20%">
                                            </div>
                                    </mat-list-item>
                                </div>
                                <div class="tot-wid-styl" *ngFor="let complet of completeInfo">
                                    <mat-list-item role="listitem">
                                        <div class="cmn-wid-styl_complete" style="width: 6%"
                                            *ngIf="complet.testType === 'health_test'"></div>
                                        <div class="cmn-wid-styl_complete" style="width: 6%"
                                            *ngIf="complet.testType === 'health_test_group'">
                                            <span style="cursor: pointer;"
                                                *ngIf="visitTypeId !== 'VT-OP' || visitTypeId === null"
                                                (click)="menuTrigger.openMenu();groupInfo(complet.healthTestId, patient.healthPlanId);">
                                                <button mat-icon-button #menuTrigger="matMenuTrigger"
                                                    [matMenuTriggerFor]="menu1">
                                                    <mat-icon style="font-size: 25px;">info</mat-icon>
                                                </button>
                                            </span>
                                            <span style="cursor: pointer;">
                                                <mat-menu #menu1="matMenu">
                                                    <div *ngFor="let group of testGroupInfo">
                                                        <div mat-menu-item>{{group.name}}</div>
                                                    </div>
                                                </mat-menu>
                                            </span>
                                        </div>
                                        <div class="cmn-wid-styl" style="width: 40%"
                                            [matTooltip]="complet.healthTestName">
                                            {{complet.healthTestName}}</div>
                                        <div class="cmn-wid-styl_complete" style="text-align: center;width: 20%">
                                            <span matTooltip="{{complet.locationName}} {{complet.floorName}}">{{complet.locationName}}</span><span *ngIf="complet.floorName != null" matTooltip="{{complet.locationName}} {{complet.floorName}}">, {{complet.floorName}}</span></div>
                                        <div *ngIf="visitEventTypeId === 'VE-OT' || visitTypeId === 'VT-EC'" class="cmn-wid-styl_complete" style="text-align: center;">
                                            {{complet.inTime | date: 'shortTime'}}</div>
                                        <div class="cmn-wid-styl_complete" style="text-align: center;">
                                            {{complet.toTime | date: 'shortTime'}}</div>
                                        <div *ngIf="visitEventTypeId === 'VE-OT' || visitTypeId === 'VT-EC'" class="cmn-wid-styl_complete" style="text-align: center;width: 20%">
                                            {{complet.consultTime}}</div>
                                        <div *ngIf="visitTypeId === 'VT-HC' || visitTypeId === 'VT-OP'" class="cmn-wid-styl_complete" style="text-align: center;width: 20%">
                                            {{complet.consultTime}}</div>
                                        <div class="cmn-wid-styl_complete" style="text-align: center;width: 20%">
                                            {{complet.waitTime}}</div>
                                        <div *ngIf="visitTypeId === 'VT-HC'" class="cmn-wid-styl_complete" style="text-align: center;width: 20%">
                                            <div *ngIf="!enableReviewDate && isEditReviewDateTestId !== complet.healthTestId && complet.reviewDate !== null">
                                                {{complet.reviewDate | date: "hh:mm a, d MMM"}}
                                            </div>
                                            <div *ngIf="enableReviewDate && isEditReviewDateTestId === complet.healthTestId" [formGroup]="reviewDateForm"
                                                class="cmn-wid-styl_complete mat-custom-form-field-box" style="text-align: center;width: 100%;height: 40px;">
                                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                                    <input matInput i18n-placeholder placeholder="" type="datetime-local" id="reviewpicker" formControlName="reviewDate"
                                                    (blur)="reviewDateTimeCall(complet.patientQueueId)" (ngModelChange)="getReviewDate($event)">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div *ngIf="visitTypeId === 'VT-HC'" class="cmn-wid-styl_complete" style="text-align: center;width: 20%">
                                            <div class="edit-icon-resize">
                                                <img (click)="bindReviewDate(complet.reviewDate, complet.healthTestId)"
                                                    style="cursor: pointer;color: #3A7AFE;" src="assets/Alert/common_icons/edit.svg"
                                                    height="15px" width="15px">
                                            </div>
                                        </div>
                                    </mat-list-item>
                                </div>
                                <div i18n class="tot-wid-styl_" *ngIf="completeInfo?.length == 0">No test completed !!
                                </div>
                            </mat-list>
                        </mat-expansion-panel>
                    </mat-accordion>
                </mat-tab>
                <mat-tab *ngIf="visitEventTypeId !== 'VE-OT' && visitTypeId !== 'VT-EC'" i18n-label label="Plan Details">
                    <div [style.height.px]="visitTypeId === 'VT-IP' && visitEventTypeId !== 'VE-OT' && visitTypeId !== 'VT-EC' ? contentHeightIp : contentHeight" style="width: 98%;margin-left: 10px;margin-top: 5px;">
                    <div *ngIf="visitTypeId === 'VT-IP'" class="top-style mat-custom-form-field-box">
                        <div class="patients-form-row block-3">
                            <div class="row-field-5">
                                <div class="example-form" style="margin: 15px 0px 8px 0px;">
                                    <span i18n class="ovi-lbl-text-size ">Admit Date & Time : </span> <span
                                        *ngIf="patient.admitDate != null" style="font-weight: 600;font-size: 12px;">
                                        {{patient.admitDate | date : 'dd/MM/yyyy h:mm a'}}</span>
                                    <!-- <span i18n class="ovi-lbl-text-size font-styl">Admit Date & Time : </span> <span
                                        *ngIf="patient.admitDate != null" style="font-weight: 600;">
                                        {{patient.admitDate | date : 'dd/MM/yyyy h:mm a'}}</span> -->
                                </div>
                            </div>
                            <div class="row-field-5">
                                <div class="example-form" style="margin: 15px 0px 8px 0px;">
                                    <span i18n class="ovi-lbl-text-size ">Admitting Doctor : </span> <span
                                        *ngIf="patient.doctorName != null" style="font-weight: 600;font-size: 12px;">
                                        {{patient.doctorName}}</span>
                                </div>
                            </div>

                            <div class="row-field-5">
                                <div class="example-form" style="margin: 15px 0px 8px 0px;">
                                    <span i18n class="ovi-lbl-text-size ">Length of Stay : </span>
                                    <span *ngIf="patient.lengthOfStay != null"
                                        style="font-weight: 600;font-size: 12px;">{{patient.lengthOfStay}}</span>
                                    <!-- <span *ngIf="patient.totalDuration != null"
                                        style="font-weight: 600;font-size: 10px;">{{patient.totalDuration}}</span> -->
                                </div>
                            </div>
                            <div class="row-field-5">
                                <div class="example-form" style="margin: 15px 0px 8px 0px;">
                                    <span i18n class="ovi-lbl-text-size ">DOB : </span> <span *ngIf="patient.dob != null"
                                        style="font-weight: 600;font-size: 12px;">{{patient.dob | date : 'dd/MM/yyyy'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div i18n class="top-style" *ngFor="let patient of patientInfo">
                        <span class="info-space-around">
                            <mat-checkbox [checked]="patient.isDiabetic"
                                (change)="onChangeClinicalDetails($event, 'isDiabetic')"> Diabetic</mat-checkbox>
                        </span>

                        <span class="info-space-around">
                            <mat-checkbox [checked]="patient.isFasting"
                                (change)="onChangeClinicalDetails($event, 'isFasting')">
                                Fasting</mat-checkbox>
                        </span>

                        <span class="info-space-around">
                            <mat-checkbox [checked]="patient.isVulnerable"
                                (change)="onChangeClinicalDetails($event, 'isVulnerable')"> Vulnerable
                            </mat-checkbox>
                        </span>

                        <span class="info-space-around">
                            <mat-checkbox [checked]="patient.isPregnant" [disabled]="patient.gender === 'Male'"
                                (change)="onChangeClinicalDetails($event, 'isPregnant')"> Pregnant</mat-checkbox>
                        </span>
                    </div>

                    <div class="top-style mat-custom-form-field-box" [formGroup]="patientInfoForm">
                        <div class="patients-form-row block-3">
                            <div class="row-field-5">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size ovi-font-family ">VIP</mat-label>
                                    <mat-form-field class="example-full-width form-field" appearance="outline">
                                        <mat-select formControlName="vipTypeId">
                                            <mat-option i18n value='null'>None</mat-option>
                                            <mat-option *ngFor="let data of vipList" [value]="data.code">{{data.value}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row-field-5">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size ovi-font-family ">Preferred Language</mat-label>
                                    <mat-form-field class="example-full-width form-field" appearance="outline">
                                        <mat-select i18n-placeholder placeholder="Language"
                                            formControlName="preferedLanguage">
                                            <mat-option *ngFor="let data of languageList" [value]="data.code">
                                                {{data.value}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="allowTokenEnroll">
                            <div class="patients-form-row block-3">
                                <div *ngIf="patient.visitTypeId === 'VT-HC' || patient.visitTypeId === 'VT-OP'"
                                    class="row-field-5 custom-material-design">
                                    <div class="example-form">
                                        <div>
                                            <div>
                                                <mat-radio-group class="example-radio-group" formControlName="tokenType"
                                                    *ngIf="isTempPatient">
                                                    <mat-radio-button (click)="tokenType='assign'" i18n value="assign"
                                                        [checked]="patientInfoForm.controls.tokenType.value === 'assign'">
                                                        Assign Token</mat-radio-button>&nbsp;
                                                    <mat-radio-button
                                                        (click)="tokenType='generate';patientInfoForm.controls['token'].value=''"
                                                        i18n value="generate"
                                                        [checked]="patientInfoForm.controls.tokenType.value === 'generate'">
                                                        Generate Token</mat-radio-button>
                                                </mat-radio-group>
                                                <mat-label i18n class="ovi-lbl-text-size" *ngIf="!isTempPatient">Token *
                                                </mat-label>
                                            </div>
                                            <mat-form-field class="example-full-width form-field" appearance="outline"
                                                [ngClass]="isTempPatient ? 'generate-token' : ''" style="float:left;">
                                                <input matInput i18n-placeholder [placeholder]="'Token'"
                                                    [readonly]="!isTempPatient || tokenType == 'generate'"
                                                    formControlName="token" [errorStateMatcher]="matcher"
                                                    autocomplete="off" (keyup)="tokenDetail = null">
                                                <mat-hint *ngIf="tokenMsg !=null || tokenDetail !=null"
                                                    style="font-size: 14px;">
                                                    <span *ngIf="tokenMsg !=null" style="color:red;">*
                                                        {{tokenMsg}}</span>
                                                    <span i18n *ngIf="tokenDetail !=null">*Click on <b>Assign</b> to
                                                        associate
                                                        token
                                                        <b>{{tokenDetail.tokenNo}}<br />({{tokenDetail.fullName}})</b></span>
                                                </mat-hint>
                                                <mat-error *ngIf="patientInfoForm.controls.token.invalid">
                                                    <span i18n
                                                        [hidden]="!patientInfoForm.controls['token'].errors.required">Token
                                                        number is required.</span>
                                                    <span i18n
                                                        [hidden]="!patientInfoForm.controls['token'].errors.pattern">Please
                                                        enter valid Token number </span>
                                                </mat-error>
                                            </mat-form-field>
                                            <div style="float:right;margin-top:3px;"
                                                *ngIf="isTempPatient && tokenType == 'assign'">
                                                <button i18n mat-button class="active-btn" *ngIf="tokenDetail == null"
                                                    (click)="checkToken()">Validate</button>&nbsp;
                                                <button i18n mat-button class="active-btn" *ngIf="tokenDetail != null"
                                                    (click)="AssignToken()">Assign</button>&nbsp;
                                            </div>
                                            <div style="float:right;margin-top:3px;"
                                                *ngIf="isTempPatient  && tokenType == 'generate'">
                                                <button i18n mat-button class="active-btn"
                                                appDebounceClick [debounceTime]="300"
                                                (debounceClick)="generateToken()">Generate</button>&nbsp;
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="row-field-5">
                                    <div class="example-form" *ngIf="!isTempPatient">
                                        <div *ngIf="visitEventTypeId === 'VE-OT' || visitTypeId === 'VT-EC'"> Associate Tag</div>
                                        <div *ngIf="visitEventTypeId !== 'VE-OT' && visitTypeId !== 'VT-EC'">
                                            <mat-radio-group class="example-radio-group"
                                                [disabled]="isEnableCoasterMobile" formControlName="coster">
                                                <mat-radio-button (click)="isEnableCoster('coaster')" i18n
                                                    value="coaster"
                                                    [checked]="patientInfoForm.controls.coster.value === 'coaster'">
                                                    Tag
                                                </mat-radio-button>&nbsp;
                                                <mat-radio-button (click)="isEnableCoster('mobile')" i18n value="mobile"
                                                    [checked]="patientInfoForm.controls.coster.value === 'mobile'">
                                                    Mobile
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <mat-form-field
                                            *ngIf="isEnableCosterDropdown === true || tag_type_name == 'mobile'"
                                            class="example-full-width form-field" appearance="outline">
                                            <input i18n-placeholder matInput placeholder="Tag ID" id="costerTagId"
                                            appTypeHit (typeHit)="searchToCoster($event)" [matAutocomplete]="autoFrom"
                                                formControlName="tagSerialNumber" #tagSerialNumber="matInput"
                                                autofocus autocomplete="off" [readonly]="tagId != null">
                                            <mat-autocomplete autoActiveFirstOption #autoFrom="matAutocomplete">
                                                <mat-option *ngFor="let option of searchCosterlist" [value]="option.tagId">
                                                    <div class="tot-wid-styl" (click)="getTagTypeId(option.tagTypeId)">{{option.tagId}}</div>
                                                </mat-option>
                                            </mat-autocomplete>
                                            <mat-error *ngIf="patientInfoForm.controls.tagSerialNumber.invalid">
                                                <span i18n
                                                    [hidden]="!patientInfoForm.controls['tagSerialNumber'].errors.required">Coaster
                                                    is
                                                    required.</span>
                                                <span i18n [hidden]="!patientInfoForm.controls['tagSerialNumber'].errors.requireMatch">Please
                                                    select value from the option</span>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row-field-5 custom-material-design">
                                <div class="example-form">
                                    <div *ngFor="let patient of patientInfo">
                                        <span class="info-space-around"
                                        *ngIf="(visitTypeId === 'VT-HC' || visitTypeId === 'VT-OP') && patient.tokenNo && checkProcessTest?.length === 0 && isChkPendingProcessTest?.length > 0">
                                            <mat-checkbox i18n formControlName="PCTestCheck" checked="true" (change)="processCounter($event, patient)">
                                                Checkout Process Counter?</mat-checkbox>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row-field-5 custom-material-design">
                                <div class="example-form" *ngIf="assignFloorDetails?.length > 0 && PCTestStatus">
                                        <mat-label i18n class="ovi-lbl-text-size"> Assigned Floor : </mat-label>
                                        <mat-form-field class="example-full-width form-field" appearance="outline">
                                            <mat-select i18n-placeholder placeholder="Assigned Floor" formControlName="assignFloorId">
                                                <mat-option *ngFor="let o of assignFloorDetails; let i = index"
                                                    [value]="o.floorId" (click)="assignedFloorCookie(o.floorId)">{{o.floorName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                </div>
                            </div>
                    </div>
                    </div>
                </mat-tab>
                <mat-tab *ngIf="activate_btn && activate_btn.includes('TB_VITL') && visitEventTypeId !== 'VE-OT' && visitTypeId !== 'VT-EC'" i18n-label label="Vitals"
                 [ngStyle]="{display: vitalsChartData['lineShow'] ? 'block' : 'none'}">
                    <div [hidden]="vitalData" class ="tab-3" [ngClass]="visitTypeId === 'VT-IP'?'vitals-height-ip':'vitals-height'">
                        <div i18n style="text-align: center; margin-top: 60px;">No Vital Available !!</div>
                    </div>
                    <div [hidden]="!vitalData" style = "height: 310px; overflow-y: auto; overflow-x:hidden;">
                        <div style="width: 99%;height: 70px;padding:10px 5px;">
                            <mat-grid-list [cols]="15" rowHeight="100%" [gutterSize]="'10px'">
                                <mat-grid-tile *ngFor = "let record of vitalValue; let i = index"
                                [colspan] = "record.col" [rowspan]= "1" [ngStyle]="{background: record['bg-color']}"
                                style="border-radius: 10px;border : 1px solid #dedede;">
                                    <mat-grid-tile-header i18n [ngStyle]="{background: 'transparent', color: record['color']}" style="justify-content: center;font-size: 18px;">{{record.title}}</mat-grid-tile-header>
                                    <div [style.font-size.px] = "record['id'] != 'last_seen' ? 25 : 15" style="font-weight: 600;padding-top: 30px; color: #5d5c5c">
                                        {{record.data}}
                                    </div>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </div>
                        <div [hidden]="!vitalData">
                            <canvas #lineChart style="height: 200px;">{{this.vitalsChartData['pulseLine']}}</canvas>
                        </div>
                    </div>
                    <div
                        style="display: flex;justify-content: center;font-size: 14px;margin-left: 8px;min-width: 120px;color: black;">
                        <button i18n mat-flat-button style="margin: 0px 3px;min-width: 40px; background-color: #006666"
                        [ngStyle]="{border: vitalParam == 'all' ? '2px solid black' : ''}" (click)="vitalParam = 'all'; vitalsService();">All</button>
                        <button i18n mat-flat-button style="margin: 0px 3px;min-width: 40px; background-color: #00FF5A;"
                        [ngStyle]="{border: vitalParam == 'temperature' ? '2px solid black' : ''}" (click)="vitalParam = 'temperature';  vitalsService();">Temp</button>
                        <button i18n mat-flat-button style="margin: 0px 3px;min-width: 40px; background-color: #306081;"
                        [ngStyle]="{border: vitalParam == 'SPO2' ? '2px solid black' : ''}" (click)="vitalParam = 'SPO2'; vitalsService();">SPO2</button>
                        <button i18n mat-flat-button style="margin: 0px 3px;min-width: 40px; background-color: #FFC300;"
                        [ngStyle]="{border: vitalParam == 'HR' ? '2px solid black' : ''}" (click)="vitalParam = 'HR'; vitalsService();">HR</button>
                        <button i18n mat-flat-button style="margin: 0px 3px;min-width: 40px; background-color: #ffc0cb"
                        [ngStyle]="{border: vitalParam == 'resp_rate' ? '2px solid black' : ''}" (click)="vitalParam = 'resp_rate'; vitalsService();">Resp_Rate</button>
                    </div>
                    <div style="padding-top: 15px;display: flex;justify-content: center;">
                        <span i18n style="cursor: pointer;" [ngStyle]="{color: vitalTime == 2*7*24*60 ? 'blue' : 'black'}" (click)="vitalTime = 2*7*24*60;vitalsService();">2 Weeks</span> |
                        <span i18n style="cursor: pointer;" [ngStyle]="{color: vitalTime == 7*24*60 ? 'blue' : 'black'}" (click)="vitalTime = 7*24*60;vitalsService();">1 Week</span> |
                        <span i18n style="cursor: pointer;" [ngStyle]="{color: vitalTime == 24*60 ? 'blue' : 'black'}" (click)="vitalTime = 24*60;vitalsService();">1 Day</span> |
                        <span i18n style="cursor: pointer;" [ngStyle]="{color: vitalTime == 12*60 ? 'blue' : 'black'}" (click)="vitalTime = 12*60;vitalsService();">12 Hours</span> |
                        <span i18n style="cursor: pointer;" [ngStyle]="{color: vitalTime == 6*60 ? 'blue' : 'black'}" (click)="vitalTime = 6*60;vitalsService();">6 Hours</span> |
                        <span i18n style="cursor: pointer;" [ngStyle]="{color: vitalTime == 3*60 ? 'blue' : 'black'}" (click)="vitalTime = 3*60;vitalsService();">3 Hours</span> |
                        <span i18n style="cursor: pointer;" [ngStyle]="{color: vitalTime == 60 ? 'blue' : 'black'}" (click)="vitalTime = 60;vitalsService();">1 Hour</span> |
                        <span i18n style="cursor: pointer;" [ngStyle]="{color: vitalTime == 30 ? 'blue' : 'black'}" (click)="vitalTime = 30;vitalsService();">30 Mins</span> |
                        <span i18n style="cursor: pointer;" [ngStyle]="{color: vitalTime == 15 ? 'blue' : 'black'}" (click)="vitalTime = 15;vitalsService();">15 Mins</span>
                    </div>

                </mat-tab>
                <mat-tab *ngIf="visitTypeId === 'VT-IP' && visitEventTypeId !== 'VE-OT' && visitTypeId !== 'VT-EC'" i18n-label label="Visit History">
                    <div i18n class="top-style" *ngIf="isNoDataVH"
                        style="text-align: center; margin-top: 100px;">
                        No Visit History Available !!
                    </div>
                    <div id="alert" class="loading-spinner" *ngIf="spinLoader">
                        <div class="spinnerload load-align">
                            <div class="spin1"></div>
                            <div class="spin2"></div>
                            <div class="spin3"></div>
                        </div>
                    </div>
                    <div (window:resize)="onResize($event)" *ngIf="!isNoDataVH">
                        <div class="tw-content1 table-align">
                            <div fxLayout="column" class="mat-elevation-z4 manage-component table-h-50"
                            [ngClass.xs]="'components-container-xs'" fxLayoutGap="5px"
                            [style.height.px]="contentHeightVisitHis">
                              <div class="mat-table-overflow">
                                <table mat-table #table [dataSource]="VHdataSource" matSort>

                                  <ng-container matColumnDef="Add">
                                    <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header" style="width: 4%;"> </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="cursor: pointer;width: 4%;">
                                    <mat-icon *ngIf="element.patientVisitEvents?.length !== 0 && this['Visit' + element.visitIdentifier] !== element.visitIdentifier" id="expand_more"
                                          #expand_more class="exp-colp-style">add_box</mat-icon>
                                        <mat-icon *ngIf="element.patientVisitEvents?.length === 0 || (element.patientVisitEvents?.length !== 0 && this['Visit' + element.visitIdentifier] === element.visitIdentifier)" id="expand_less"
                                          #expand_less class="exp-colp-style">remove</mat-icon>
                                    </mat-cell>
                                  </ng-container>

                                  <ng-container matColumnDef="Child">
                                    <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header"> </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                    </mat-cell>
                                  </ng-container>

                                  <ng-container matColumnDef="Event">
                                    <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header"> Event </mat-header-cell>
                                    <mat-cell *matCellDef="let element" >{{element.visitTypeName}}
                                    </mat-cell>
                                  </ng-container>

                                  <ng-container matColumnDef="Event Date">
                                    <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header"> Event Date </mat-header-cell>
                                    <mat-cell *matCellDef="let element" >{{element.fromTime | date: "d MMM, yyyy"}}
                                    </mat-cell>
                                  </ng-container>

                                  <ng-container matColumnDef="Visit Identifier">
                                    <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header"> Visit Identifier </mat-header-cell>
                                    <mat-cell *matCellDef="let element">{{element.visitIdentifier}}
                                    </mat-cell>
                                  </ng-container>

                                  <ng-container matColumnDef="Details">
                                    <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header"> Details </mat-header-cell>
                                    <mat-cell *matCellDef="let element">{{element.healthPlanName !== null ? element.healthPlanName : element.bedLocationName}}
                                    </mat-cell>
                                  </ng-container>

                                  <ng-container matColumnDef="Location">
                                    <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header"> Location </mat-header-cell>
                                    <mat-cell *matCellDef="let element">{{element.bedLocationName}}
                                    </mat-cell>
                                  </ng-container>

                                  <ng-container matColumnDef="Admitting Doctor">
                                    <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header"> Admitting Doctor </mat-header-cell>
                                    <mat-cell *matCellDef="let element">{{element.doctorName}}
                                    </mat-cell>
                                  </ng-container>

                                  <ng-container matColumnDef="End Date">
                                    <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header"> End Date </mat-header-cell>
                                    <mat-cell *matCellDef="let element">{{element.toTime | date : "hh:mm a, d MMM" }}
                                    </mat-cell>
                                  </ng-container>

                                  <ng-container matColumnDef="Status">
                                    <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header"> Status </mat-header-cell>
                                    <mat-cell *matCellDef="let element">{{element.visitStatusName}}
                                    </mat-cell>
                                  </ng-container>

                                  <mat-header-row *matHeaderRowDef="VHDisplayedColumns;sticky: true" class="manage-table-header-row"></mat-header-row>
                                  <mat-row *matRowDef="let row; columns: VHDisplayedColumns;" (toggleChange)="onToggleChange($event,row)"
                                    [cdkDetailRowTpl]="tpl" [cdkDetailRow]="row">
                                  </mat-row>
                                </table>
                              </div>
                              <mat-paginator #paginator [length]="this.length" [pageSize]="50" [pageSizeOptions]="[50, 100, 150, 200]" showFirstLastButtons class="tw-paginator-sticky"></mat-paginator>

                              <ng-template #tpl let-element>
                                <div class="mat-row detail-row oflw-style" [@detailExpand]>
                                  <mat-table #table [dataSource]="element.patientVisitEvents" matSort>

                                    <ng-container matColumnDef="Add">
                                        <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header" style="width: 1%;padding-right: 6px;"> </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="width: 1%">
                                          </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="Child">
                                        <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header" style="padding-right: 6px;"> </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="width: 3%;">
                                            <mat-icon id="expand_less" style="transform: scale(0.7);" class="exp-colp-style">fiber_manual_record</mat-icon>
                                          </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="Event">
                                        <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header"> Event </mat-header-cell>
                                        <mat-cell *matCellDef="let element">{{element.eventTypeName}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="Event Date">
                                        <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header"> Event Date </mat-header-cell>
                                        <mat-cell *matCellDef="let element">{{element.fromTime | date: "d MMM, yyyy"}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="Visit Identifier">
                                      <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header"> Visit Identifier </mat-header-cell>
                                      <mat-cell *matCellDef="let element">
                                      </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="Details">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header class="manage-table-header"> Details </mat-header-cell>
                                        <mat-cell *matCellDef="let element">{{element.healthPlanName !== null ? element.healthPlanName : element.bedLocationNam}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="Admitting Doctor">
                                        <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header"> Admitting Doctor </mat-header-cell>
                                        <mat-cell *matCellDef="let element">{{element.doctorName}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="End Date">
                                        <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header"> End Date </mat-header-cell>
                                        <mat-cell *matCellDef="let element">{{element.toTime | date : "hh:mm a, d MMM" }}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="Status">
                                        <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header"> Status </mat-header-cell>
                                        <mat-cell *matCellDef="let element">{{element.visitStatusName}}
                                        </mat-cell>
                                    </ng-container>

                                    <mat-row *matRowDef="let row; columns: VHDisplayedColumns;"
                                      [cdkDetailRow]="row" [cdkDetailRowTpl]="tpl">
                                    </mat-row>
                                  </mat-table>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        <!-- <div fxLayout="column" class="mat-elevation-z4 manage-component table-h-50"
                            [ngClass.xs]="'components-container-xs'" fxLayoutGap="5px"
                            [style.max-height.px]="maxHeight">
                            <div class="mat-table-overflow">
                                <table mat-table [dataSource]="VHdataSource">

                                    <ng-container matColumnDef="visitType">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header"
                                            style="width: 100px;">
                                            Visit Type</th>
                                        <td i18n mat-cell *matCellDef="let element">{{element.visitTypeName}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="date">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header">
                                            Visit Date
                                        </th>
                                        <td i18n mat-cell *matCellDef="let element">
                                            {{element.fromTime | date: "d MMM, yyyy"}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="doctor">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header">
                                            Admitting Doctor
                                        </th>
                                        <td i18n mat-cell *matCellDef="let element">
                                            {{element.doctorName}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header">
                                            Status</th>
                                        <td i18n mat-cell i18n-matTooltip [matTooltip]="element.message"
                                            *matCellDef="let element">{{element.visitStatusName}}</td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="VHDisplayedColumns; sticky: true;"
                                        class="manage-table-header-row"></tr>
                                    <tr mat-row *matRowDef="let data; columns: VHDisplayedColumns;"></tr>
                                </table>
                            </div>
                            <mat-paginator [pageSizeOptions]="[10, 15, 20, 50, 100]" showFirstLastButtons
                            class="tw-paginator-sticky" style="border-radius: 5px;">
                            </mat-paginator>
                        </div> -->
                    </div>
                </mat-tab>
                <mat-tab *ngIf="visitTypeId === 'VT-IP' && visitEventTypeId !== 'VE-OT' && visitTypeId !== 'VT-EC'" i18n-label label="Alert History">
                    <div i18n class="top-style" *ngIf="noData"
                        style="text-align: center; margin-top: 100px;">
                        No Alert History Available !!
                    </div>
                    <div id="alert" class="loading-spinner" *ngIf="spinLoader">
                        <div class="spinnerload load-align">
                            <div class="spin1"></div>
                            <div class="spin2"></div>
                            <div class="spin3"></div>
                        </div>
                    </div>
                    <div (window:resize)="onResize($event)" *ngIf="!noData">
                        <div fxLayout="column" class="mat-elevation-z4 manage-component table-h-50"
                            [ngClass.xs]="'components-container-xs'" fxLayoutGap="5px"
                            [style.max-height.px]="maxHeight">
                            <div class="mat-table-overflow">
                                <table mat-table [dataSource]="AHdataSource">
                                    <ng-container matColumnDef="Alert">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header"
                                            style="padding:0px 20px; width: 35px;"></th>
                                        <td mat-cell *matCellDef="let element">
                                            <mat-icon *ngIf="element.ruleTypeId !== 'RU-NC'" class="icon-style"
                                                [ngClass]="element.ruleTypeId === 'RU-FA' ? 'fall-risk' : element.ruleTypeId === 'RU-MO' ? 'patient-away' : element.ruleTypeId === 'RU-MI' ? 'patient-missing' : element.ruleTypeId === 'RU-GO' ? 'geofence' : element.ruleTypeId === 'RU-SE' ? 'sensor' : none">
                                            </mat-icon>
                                            <mat-icon *ngIf="element.ruleTypeId === 'RU-NC'" class="icon-style" i18n-matTooltip [matTooltip]="element.eventName"
                                                [ngClass]="element.event === 'CE-MD' ? 'medicine' : element.event === 'CE-HK' ? 'housekeeping' : element.event === 'CE-AD' ? 'aid' : element.event === 'CE-SO' ? 'sos' : element.event === 'CE-FD' ? 'food' : element.event === 'CE-CL' ? 'call' : element.event === 'CE-TAM' ? 'tamper' : none">
                                            </mat-icon>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="AlertName">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header"
                                            style="width: 100px;">
                                            Alert</th>
                                        <td i18n mat-cell *matCellDef="let element">{{element.pfAlertConfigName}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="Start Time">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header">
                                            Alert Time
                                        </th>
                                        <td i18n mat-cell *matCellDef="let element">
                                            {{element.alertDatetime | date: "hh:mm a, d MMM"}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Ack Time">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header">
                                            Ack Time
                                        </th>
                                        <td i18n mat-cell *matCellDef="let element">
                                            {{element.ackDatetime | date: "hh:mm a, d MMM"}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="End Time">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header">
                                            Close Time
                                        </th>
                                        <td i18n mat-cell *matCellDef="let element">
                                            {{element.closedDatetime | date: "hh:mm a, d MMM"}}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Message">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header">
                                            Message</th>
                                        <td i18n mat-cell i18n-matTooltip [matTooltip]="element.message"
                                            *matCellDef="let element">{{element.message}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="Comments">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header">
                                            Comments</th>
                                        <td i18n mat-cell i18n-matTooltip [matTooltip]="element.comments"
                                            *matCellDef="let element">{{element.comments}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="Event">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header"
                                            style="width: 100px;">
                                            Event</th>
                                        <td i18n mat-cell *matCellDef="let element">{{element.eventName}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="Status">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header"
                                            style="width: 100px;">
                                            Status</th>
                                        <td i18n mat-cell *matCellDef="let element">{{element.isActive ? 'Created' : 'Closed'}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="Task">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header"
                                            style="width: 100px;">
                                            Task
                                        </th>
                                        <td i18n mat-cell *matCellDef="let element">
                                            <span (click)="element.pendingTaskIds.length ? getTaskDetail(element.pendingTaskIds) : ''" [ngStyle]="{color: element.pendingTaskIds.length ? 'blue' : 'black', cursor: 'pointer'}">
                                                <span>{{element.pendingTaskIds.length}} </span> <span>Open </span>|
                                            </span>
                                            <span (click)="element.completedTaskIds.length ? getTaskDetail(element.completedTaskIds) : ''" [ngStyle]="{color: element.completedTaskIds.length ? 'blue' : 'black', cursor: 'pointer'}">
                                                <span>{{element.completedTaskIds.length}} </span> <span>Closed </span>
                                            </span>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Edit">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header"
                                            style="width: 100px;"></th>
                                        <td i18n mat-cell *matCellDef="let element">
                                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                                <mat-icon class="activity-edit1">more_horiz</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <button mat-menu-item (click)="getAck(element)">
                                                    <span class="activity-edit">Edit</span>
                                                </button>
                                            </mat-menu>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="HCDisplayedColumns; sticky: true;"
                                        class="manage-table-header-row"></tr>
                                    <tr mat-row *matRowDef="let data; columns: HCDisplayedColumns;"></tr>
                                </table>
                            </div>
                            <mat-paginator #AHPaginator [length]="this.AHLength" [pageSizeOptions]="[10, 15, 20, 50, 100]" showFirstLastButtons
                            class="tw-paginator-sticky" style="border-radius: 5px;">
                            </mat-paginator>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab *ngIf="activate_btn && activate_btn.includes('TB_RUTN') && visitTypeId === 'VT-IP' && visitEventTypeId !== 'VE-OT' && visitTypeId !== 'VT-EC'" i18n-label label="Routine">
                        <div class="mat-custom-form-field-box" [formGroup]="patientInfoForm">
                        <div>
                            <div class="row-field-1" style=" width: 17%;">
                            <div style="padding-top: 15px;" class="example-form">
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <input matInput i18n-placeholder [matDatepicker]="picker" placeholder="Filter"
                                        autocomplete="off" role="presentation" (dateChange)="getRoutineEventDetails(this.data.patientId, 'Patient', $event.target.value, null)" formControlName="fromDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div (window:resize)="onResize($event)">
                            <div fxLayout="column" class="mat-elevation-z4 manage-component table-h-50"
                                [ngClass.xs]="'components-container-xs'" fxLayoutGap="5px"
                                [style.max-height.px]="this.height">
                                <div class="mat-table-overflow">
                                    <table mat-table [dataSource]="RoutinedataSource" style="table-layout: inherit;">

                                        <ng-container matColumnDef="Activity Name">
                                            <th i18n mat-header-cell *matHeaderCellDef class="tw-manage-table-header">
                                                Activity Name</th>
                                            <td i18n mat-cell *matCellDef="let element">{{element.activityName}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="Performer Name">
                                            <th i18n mat-header-cell *matHeaderCellDef class="tw-manage-table-header">
                                                Performer Name</th>
                                            <td i18n mat-cell *matCellDef="let element">{{element.performerName}}</td>
                                        </ng-container>

                                        <ng-container matColumnDef="Schedule Time">
                                            <th i18n mat-header-cell *matHeaderCellDef class="tw-manage-table-header">
                                                Schedule Time
                                            </th>
                                            <td i18n mat-cell *matCellDef="let element">
                                                {{element.scheduleTime | date: "hh:mm a, d MMM"}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="Start Time">
                                            <th i18n mat-header-cell *matHeaderCellDef class="tw-manage-table-header">
                                                Start Time
                                            </th>
                                            <td i18n mat-cell *matCellDef="let element">
                                                {{element.startTime | date: "hh:mm a, d MMM"}} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="End Time">
                                            <th i18n mat-header-cell *matHeaderCellDef class="tw-manage-table-header">
                                                End Time
                                            </th>
                                            <td i18n mat-cell *matCellDef="let element">
                                                {{element.endTime | date: "hh:mm a, d MMM"}}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="Status">
                                            <th i18n mat-header-cell *matHeaderCellDef class="tw-manage-table-header">
                                                Status</th>
                                            <td i18n mat-cell i18n-matTooltip [matTooltip]="element.comments"
                                                *matCellDef="let element">{{element.activityStatusName}}</td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="RoutineDisplayedColumns; sticky: true;"
                                            class="tw-manage-table-header-row"></tr>
                                        <tr mat-row *matRowDef="let data; columns: RoutineDisplayedColumns;"></tr>
                                    </table>
                                </div>
                                <div i18n class="top-style" *ngIf="noRoutineData" style="text-align: center; margin-top: 100px; " >No Routine History Available !!</div>
                                <mat-paginator *ngIf="!noRoutineData" #paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="[10, 15, 20, 50, 100]"
                                (page)="pageEvent = getRoutineEventDetails(this.data.patientId,'Patient', this.fromDate, $event);" showFirstLastButtons class="tw-paginator-sticky">
                                </mat-paginator>
                            </div>
                        </div>
                </mat-tab>

                <mat-tab *ngIf="activate_btn && activate_btn.includes('TB_AU')" i18n-label label="Asset Utilisation">
                    <div i18n class="top-style" *ngIf="noAssetUtilData"
                        style="text-align: center; margin-top: 100px; ">
                        No Asset Utilisation Available !!
                    </div>
                    <div id="alert" class="loading-spinner1" *ngIf="spinLoader">
                        <div class="spinnerload load-align">
                            <div class="spin1"></div>
                            <div class="spin2"></div>
                            <div class="spin3"></div>
                        </div>
                    </div>
                    <div (window:resize)="onResize($event)" *ngIf="!noAssetUtilData">
                        <div fxLayout="column" class="mat-elevation-z4 manage-component table-h-50"
                            [ngClass.xs]="'components-container-xs'" fxLayoutGap="5px"
                            [style.max-height.px]="maxHeight">
                            <div class="mat-table-overflow">
                                <table mat-table [dataSource]="AUdataSource">

                                    <ng-container matColumnDef="Bed id">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header"
                                            style="width: 100px;">
                                            Bed id</th>
                                        <td i18n mat-cell *matCellDef="let element">{{element['Bed id']}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="Asset name">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header">
                                            Asset name
                                        </th>
                                        <td i18n mat-cell *matCellDef="let element"> {{element['Asset name']}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Util Hrs">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header">
                                            Util Hours</th>
                                        <td i18n mat-cell *matCellDef="let element"> {{element['Util Hrs']}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Off Hrs">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header">
                                            Off Hours</th>
                                        <td i18n mat-cell *matCellDef="let element"> {{element['Off Hrs']}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Idle Hrs">
                                        <th i18n mat-header-cell *matHeaderCellDef class="manage-table-header">
                                            Idle Hours</th>
                                        <td i18n mat-cell *matCellDef="let element"> {{element['Idle Hrs']}} </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="AUDisplayedColumns; sticky: true;"
                                        class="manage-table-header-row"></tr>
                                    <tr mat-row *matRowDef="let data; columns: AUDisplayedColumns;"></tr>
                                </table>
                            </div>
                            <mat-paginator *ngIf="!noAssetUtilData" [pageSizeOptions]="[10, 15, 20, 50, 100]" showFirstLastButtons
                            class="tw-paginator-sticky" style="border-radius: 5px;">
                            </mat-paginator>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab *ngIf="activate_btn && activate_btn.includes('TB_MDCN') && visitTypeId === 'VT-IP' && visitEventTypeId !== 'VE-OT' && visitTypeId !== 'VT-EC'" i18n-label label="Medication">
                    <!-- <div i18n style="text-align: center; padding-top: 15px; font-family: 'Open Sans';"><img src = "assets/Alert/common_icons/medication.jpg" alt="medication"></div> -->
                    <div i18n class="top-style" style="text-align: center; margin-top: 100px;">
                        No Medication Available !!
                    </div>
                </mat-tab>
                <mat-tab *ngIf="visitTypeId === 'VT-EC' || (visitTypeId === 'VT-IP' && visitEventTypeId !== 'VE-OT')" i18n-label label="Movement History">
                    <div class="mat-tab-content-hist">
                        <div class="movement-padding">
                            <span [formGroup]="dateForm" class="block-3">
                                <div class='row-field-date'>
                                <span i18n class="date-filter-label1">FROM</span>
                                <input matInput class="date-filter-label2" type="date" max="{{today | date:'yyyy-MM-dd'}}" formControlName="fromDate"/>&nbsp;&nbsp;
                                </div>
                                <div class='row-field-date'>
                                <span i18n class="date-filter-label1">TO</span>
                                <input matInput class="date-filter-label2" type="date" max="{{today | date:'yyyy-MM-dd'}}" min="{{dateForm.controls.fromDate.value | date:'yyyy-MM-dd'}}" formControlName="toDate"/>&nbsp;&nbsp;
                                </div>
                                <button i18n mat-button class="active-btn" appDebounceClick (debounceClick)="getMovementHistoryData()"
                                    [debounceTime]="300" cdkFocusInitial>Go</button>
                            </span>
                        </div>
                        <div fxLayout="column" class="tw-manage-component" [ngClass.xs]="'components-container-xs'"
                            fxLayoutGap="5px" style="overflow:hidden;padding: 15px;" [style.height.px]="contentHeightMomentHis">
                            <div class="tw-mat-table-overflow">
                                <table mat-table class="tw-table" [dataSource]="datSourceMovementHistory">
                                    <ng-container matColumnDef="blockName">
                                        <th i18n mat-header-cell *matHeaderCellDef
                                            class="tw-manage-table-header"> Location</th>
                                        <td mat-cell *matCellDef="let element" appTooltip tooltipText={{element.blockName}},{{element.floorName}},{{element.locationName}} placement="bottom"> {{element.blockName}}
                                            <span *ngIf="element.floorName!=null">,</span> {{element.floorName}}
                                            <span *ngIf="element.locationName!=null" >,</span> {{element.locationName}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="fromDateTime">
                                        <th i18n mat-header-cell *matHeaderCellDef
                                            class="tw-manage-table-header"> From Time </th>
                                        <td mat-cell *matCellDef="let element"> {{element.fromDateTime | date: "hh:mm a, d MMM"}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="toDateTime">
                                        <th i18n mat-header-cell *matHeaderCellDef
                                            class="tw-manage-table-header"> To Time
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.toDateTime | date: "hh:mm a, d MMM"}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="duration">
                                        <th i18n mat-header-cell *matHeaderCellDef
                                            class="tw-manage-table-header"> Duration </th>
                                        <td mat-cell *matCellDef="let element">{{element.totalDuration}}</td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true" class="tw-manage-table-header-row"></tr>
                                    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
                                </table>
                            </div>
                            <mat-paginator #paginatorMove [pageSizeOptions]="[ 10, 15, 20, 50, 100]" showFirstLastButtons class="tw-paginator-sticky"></mat-paginator>
                        </div>
                    </div>

                    <div *ngIf="this.movementList.length == 0" i18n style="text-align: center; margin-top: 20px;">No Movement History Available !!</div>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div style="margin-top: 5px;margin-bottom: 50px;" class="mbl-view">
            <div class="patient-footer-cancel"
                *ngIf="selectedTab!='0' && selectedTab!='2' && selectedTab!='3' && selectedTab!='4' && patient.tokenNo && patient.visitStatus != 'Completed' && visitTypeId === 'VT-HC'"
                >
                <button i18n mat-button class="active-btn" appDebounceClick (debounceClick)="cancelBilling()"
                    [debounceTime]="300" style="margin-left: 10px"> Cancel Billing </button>
            </div>
            <div class="patient-footer-cancel"
                *ngIf="selectedTab!='1' && selectedTab!='2' && selectedTab!='3' && selectedTab!='4' && selectedTab!='5' && selectedTab!='6' && selectedTab!='7' && visitTypeId === 'VT-IP' && visitEventTypeId !== 'VE-OT' && visitTypeId !== 'VT-EC'"
                >
                <button *ngIf="activate_btn && activate_btn.indexOf('BT_DSRGE') > -1" i18n mat-button class="active-btn" appDebounceClick (debounceClick)="discharge()"
                    [debounceTime]="300"> Discharge </button>
            </div>

            <div class="patient-footer"
                *ngIf="visitTypeId != 'VT-IP' && visitTypeId !== 'VT-EC' && selectedTab!='0' && selectedTab!='2' && selectedTab!='3' && selectedTab!='4' && selectedTab!='6' && selectedTab!='7'" >
                <button i18n mat-button class="cancle-btn" mat-dialog-close> Cancel </button>&nbsp;
                <button i18n mat-button class="active-btn" appDebounceClick (debounceClick)="updateClinicalDetails()"
                    [debounceTime]="300" [disabled]="isTempPatient || this.patientInfoForm.invalid"> Submit </button>
            </div>
            <div class="patient-footer"
                *ngIf="((visitTypeId === 'VT-IP' && visitEventTypeId === 'VE-OT') || visitTypeId === 'VT-EC') && selectedTab!='0' && selectedTab!='1' && selectedTab!='2' && selectedTab!='3' && selectedTab!='4' && selectedTab!='5' && selectedTab!='6' && selectedTab!='7'" >
                <button i18n mat-button class="cancle-btn" mat-dialog-close> Cancel </button>&nbsp;
                <button i18n mat-button class="active-btn" appDebounceClick (debounceClick)="updateClinicalDetails()"
                    [debounceTime]="300" [disabled]="isTempPatient"> Submit </button>
            </div>
            <div class="patient-footer"
                *ngIf="visitTypeId === 'VT-IP' && visitEventTypeId !== 'VE-OT' && visitTypeId !== 'VT-EC' && selectedTab!='1' && selectedTab!='2' && selectedTab!='3' && selectedTab!='4' && selectedTab!='5' && selectedTab!='6' && selectedTab!='7'" >
                <button i18n mat-button class="cancle-btn" mat-dialog-close> Cancel </button>&nbsp;
                <button i18n mat-button class="active-btn" appDebounceClick (debounceClick)="updateClinicalDetails()"
                    [debounceTime]="300" [disabled]="isTempPatient || this.patientInfoForm.invalid"> Submit </button>
            </div>
        </div>
    </div>
   </div>
</div>
