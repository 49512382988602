/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../../../environments/environment';
import { HospitalService, CommonService } from '../../../../shared';
import { ErrorStateMatcherService } from '../../../../shared/services/error-state-matcher.service';
import { EditCustomer } from '../../hospital.model';
import { CreateCustomerComponent } from '../create-customer/create-customer.component';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { GoogleMapComponent } from '../../../../shared/modules/entry-component/google-map/google-map.component';

@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.scss']
})
export class EditCustomerComponent implements OnInit {
  public logoImage: any = null;
  public email: string;
  public isDisabled = false;
  attachedFiles: any;
  uploadedFile: any[] = [];
  public base64Data_global: string;
  public bgImage: any = null;
  getCongnitoRoles: any[] = [];
  cust_id: number;
  text = 'Customer';
  code = '1';
  selectedCustomer: any;
  customerType: any[] = [];
  customerList: any[] = [];
  regionList: any[] = [];
  selectedTab: any = 0;
  selectedRole = new UntypedFormControl();
  roleList: any[] = []; 
  public cusDisplayedColumns = ['id','name', 'status']
  public customerRoles : Array<any> = [];
  public selectedList = [];
  public isShowTable: boolean = false;
  dataSourceIdentifier = new MatTableDataSource<any>();
  public roles = [];
  public removeIds = [];
  public roleIds = [];
  public saveCus: boolean = false;
  public customerForm: UntypedFormGroup;
  public editCustomer: EditCustomer;
  sourceGeoCoordinate: any ={};
  parkingGeoCoordinate: any ={};
  searchText = null;
  loc: any = null;
  geoLoc: any = {};
  parkingGeoLoc: any = {};
  entityAddresses: any = [];
  entityProfiles: any = [];
  TypeList: any = [];
  parkingFacilitylist =[];
  accessbilitylist=[];
  parkingFacilityCodes: any[] = [];
  accessbilityCodes: any[] = [];
    constructor(
    public form: UntypedFormBuilder,
    public toastr: ToastrService,
    public snackbar: MatSnackBar,
    public thisDialogRef: MatDialogRef<CreateCustomerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected sanitizer: DomSanitizer,
    private hospitalServices: HospitalService, private commonService: CommonService, public dialog: MatDialog) {
      this.getAppTerms()
    this.buildForm();
  }

  public matcher = new ErrorStateMatcherService();

  ngOnInit() {
    this.logoImage = environment.api_base_url_new + environment.base_value.get_customer_logo + '/' + this.data.id;
    this.bgImage = environment.api_base_url_new + environment.base_value.get_customer_bg_image + '/' + this.data.id;
    if (this.data.customerTypeId === '2') {
      this.code = '2';
    } else if(this.data.customerTypeId === '3') {
      this.code = '3';
    }
    this.getCustomerRoles();
    this.getRoles();
    this.commonService.getAppTerms('SolutionType').subscribe(res=>this.TypeList = res.results); 
    this.hospitalServices.getMultipleImages(this.data.id).subscribe(res => {
      this.uploadedFile = res.results;
  });
  }
  getAppTerms() {
    this.commonService.getAppTerms('ParkingType,Accessbility').subscribe(res=>{
      this.parkingFacilitylist = res.results.filter(val => val.groupName == 'ParkingType');
      this.accessbilitylist = res.results.filter(val => val.groupName == 'Accessbility');
      this.parkingFacilityCodes = this.parkingFacilitylist.map(item => item.code);
      this.accessbilityCodes = this.accessbilitylist.map(item => item.code);
      this.buildForm()
    }); 
  }
  uploadImage($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myTag: FileReader = new FileReader();

    myTag.onloadend = (e) => {
      this.logoImage = myTag.result;
    };
    myTag.readAsDataURL(file);
  }
  getRoles() {
    this.commonService.getAllRole().subscribe(res => {
      this.roleList = res.results;
    });
  }
  getCustomerRoles(){
    this.hospitalServices.getAllCustomerRole().subscribe(res => {
      this.roles = [];
      this.customerType = res.results;
      for(let i=0; i < this.customerType.length; i++){
        this.roles.push(this.customerType[i].roleName)
      }
      this.selectedRole.setValue(this.roles)
      this.roleSelection(this.roles);
    })
  }
  tabClick(event){
    this.selectedTab = event.index;
  }
  roleSelection(value){
    this.selectedList = [];
    this.roleIds = [];
    this.removeIds = [];
    if(this.selectedRole.value !== null){
      const addList = value.filter(res => !this.roles.includes(res))
      const existList = this.customerType.filter(res => value.includes(res.roleName))
      const delList = this.customerType.filter(res => !value.includes(res.roleName))
      for(let i=0; i < addList.length; i++){
        this.saveCus = true;
        let roleFilter = this.roleList.filter(res =>  res.name == addList[i])
        let addTable = { id: this.customerType.length +1 , roleId: roleFilter[0].id, roleName: addList[i], status: "Add"}
        this.selectedList.push(addTable)
      }
      for(let i=0; i < existList.length; i++){
        existList[i]['status'] = "Exists"
        this.selectedList.push(existList[i]);
      }
      for(let i=0; i < delList.length; i++){
        this.saveCus = true;
        delList[i]['status'] = "Delete"
        this.selectedList.push(delList[i]);
      }
      if(addList.length > 0 || delList.length > 0){
        this.saveCus = true;
      } else{
        this.saveCus = false;
      }
      for(let i=0; i < this.selectedList.length; i++){
        if(this.selectedList[i].status == "Delete"){
          this.removeIds.push(this.selectedList[i].id)
        } else if(this.selectedList[i].status == "Add"){
          this.roleIds.push(this.selectedList[i].roleId)
        }
      }
      this.dataSourceIdentifier = new MatTableDataSource(this.selectedList);
      if(this.dataSourceIdentifier.data.length > 0){
        this.isShowTable = true;
      }
    }
  }
  saveRoles(){
    let cusData = {"removeIds": this.removeIds, "roleIds": this.roleIds}
    this.hospitalServices.saveCustomerRole(cusData).subscribe(res => {
      this.customerType = [];
      this.getCustomerRoles();
    })
  }
  public editUser(data) {
    this.isDisabled = true;

    this.customerForm.value.customerTypeId = this.cust_id;

    const editCustomer = new EditCustomer(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null ,null ,null);

    editCustomer.customerTypeId = data.customerTypeId;
    editCustomer.name = this.customerForm.controls['name'].value;
    editCustomer.parentId = data.parentId;
    editCustomer.billAddress = this.customerForm.controls['billAddress'].value;
    editCustomer.regAddress = this.customerForm.controls['regAddress'].value;
    editCustomer.licencePack = this.customerForm.controls['licencePack'].value;
    editCustomer.gsTin = this.customerForm.controls['gsTin'].value;
    editCustomer.noOfEmployees = this.customerForm.controls['noOfEmployees'].value;
    editCustomer.noOfDevices = this.customerForm.controls['noOfDevices'].value;
    editCustomer.noOfBeds = this.customerForm.controls['noOfBeds'].value;
    editCustomer.isMulti = this.customerForm.controls['isMulti'].value;
    editCustomer.isActive = this.customerForm.controls['isActive'].value;
    editCustomer.logoImage = this.logoImage;
    editCustomer.attachImages = this.uploadedFile;
    editCustomer.bgImage = this.bgImage;
    editCustomer.comments = this.customerForm.controls['comments'].value;
    editCustomer.solutionTypeId = this.customerForm.controls['solutionTypeId'].value;
    const entityAddresses = [{
      "addressSubTypeId": "AST-PMTA",
        "addressTypeId": "AD-REGAD",
      'entityId': this.customerForm.controls['entityId'].value,
      'entityType': this.customerForm.controls['entityType'].value,
      'id' : this.customerForm.controls['id'].value,
      'line1': this.customerForm.controls['line1'].value,
      'line2': this.customerForm.controls['line2'].value,
      'line3': this.customerForm.controls['line3'].value,
      'city': this.customerForm.controls['city'].value,
      'zip': this.customerForm.controls['zip'].value,
      'country':this.customerForm.controls['country'].value, 
    }];
    this.entityAddresses = entityAddresses,
    editCustomer.entityAddresses =  this.entityAddresses
    
    let imageCheck = editCustomer.bgImage.split(':');
    if(imageCheck.length > 0 && imageCheck[0] != 'data') {
      delete editCustomer['bgImage'];
    }
    let logoCheck = editCustomer.logoImage.split(':');
    if(logoCheck.length > 0 && logoCheck[0] != 'data') {
      delete editCustomer['logoImage'];
    }
    editCustomer.id = data.id; 
    let hospitalAddressIndex = data.entityProfiles.findIndex(val => val.identifyingType == "Hospital")
    let parkingAddressIndex = data.entityProfiles.findIndex(val => val.identifyingType == "Parking")
    if (Object.keys(this.sourceGeoCoordinate).length) {
      this.sourceGeoCoordinate['facilityId'] = data.id;
      this.sourceGeoCoordinate['identifyingType'] = "Hospital"
      this.sourceGeoCoordinate['id'] = hospitalAddressIndex != -1 ? data.entityProfiles[hospitalAddressIndex]['id'] : null;
      editCustomer.entityProfiles.push(this.sourceGeoCoordinate)
    }
    if (Object.keys(this.parkingGeoCoordinate).length) {
      this.sourceGeoCoordinate['facilityId'] = data.id;
      this.parkingGeoCoordinate['identifyingType'] = "Parking"
      this.parkingGeoCoordinate['id'] = parkingAddressIndex != -1 ? data.entityProfiles[parkingAddressIndex]['id'] : null;
      editCustomer.entityProfiles.push(this.parkingGeoCoordinate)
    }
    let parkList = this.customerForm.controls.parkingFacilityform.value;
    let accList = this.customerForm.controls.accessbilityform.value;
    let oldEntityProfiles =  data.entityProfiles ? data.entityProfiles.map(val => val.identifyingType) : [];
    if (parkList && oldEntityProfiles) {
      for (let i in this.parkingFacilitylist) {
        let code = this.parkingFacilitylist[i]['code'];
        if (parkList.includes(code)) {
          if (!oldEntityProfiles.includes(code)) {
            editCustomer.entityProfiles.push(
            {
              "id": null,
              "identifyingType": this.parkingFacilitylist[i]['code'],
              "identifyingValue": this.parkingFacilitylist[i]['value'],
              "facilityId": data.id,
              "isActive": true
            }  
          )          
        }
      } else if (oldEntityProfiles.includes(code)) {
        let index = data.entityProfiles.findIndex(val => val.identifyingType === code);
        if (index !== -1) {
          let epValue = data.entityProfiles[index];
          epValue['isActive'] = false;
          editCustomer.entityProfiles.push(epValue);
      }
    }
  }
}
if (accList && oldEntityProfiles) {
  for (let i in this.accessbilitylist) {
    let code = this.accessbilitylist[i]['code'];
    if (accList.includes(code)) {
      if (!oldEntityProfiles.includes(code)) {
        editCustomer.entityProfiles.push(
            {
              "id": null,
              "identifyingType": this.accessbilitylist[i]['code'],
              "identifyingValue": this.accessbilitylist[i]['value'],
              "facilityId": data.id,
              "isActive": true
            }  
          )          
        }
      }else if (oldEntityProfiles.includes(code)) {
        let index = data.entityProfiles.findIndex(val => val.identifyingType === code);
        if (index !== -1) {
          let epValue = data.entityProfiles[index];
          epValue['isActive'] = false;
          editCustomer.entityProfiles.push(epValue);
        }
      }
      }
    }
    this.hospitalServices.updateCustomer(editCustomer).subscribe(result => {
      if (result.statusCode != 1) {
        this.isDisabled = false;
      }

      this.toastr.success('Success', `${result.message}`, { closeButton: true });
      this.thisDialogRef.close('confirm');
    },
      error => {
        // console.log('error----------->', error);
        this.toastr.error('Error', `${error.error.message}`);
      });
  }


  public buildForm() {
    this.customerForm = this.form.group({
      name: [this.data.name ? this.data.name : null, [Validators.required, Validators.minLength(3), Validators.maxLength(64)]],
      parentId: [null],
      
      customerTypeId: [this.data.cust_id ? this.data.cust_id : null],
      billAddress: [this.data.billAddress ? this.data.billAddress : null],
      licencePack: [this.data.licencePack ? this.data.licencePack : null],
      gsTin: [this.data.gsTin ? this.data.gsTin : null],
      noOfEmployees: [this.data.noOfEmployees ? this.data.noOfEmployees : null],
      noOfDevices: [this.data.noOfDevices ? this.data.noOfDevices : null],
      noOfBeds: [this.data.noOfBeds ? this.data.noOfBeds : null],
      isMulti: [this.data.isMulti ? this.data.isMulti.toString() : null],
      logoImage: [this.data.logoImage ? this.data.logoImage : null],
      attachImages: [this.data.attachImages ? this.data.attachImages : null], 
      bgImage: [this.data.bgImage ? this.data.bgImage : null],
      regAddress: [this.data.regAddress ? this.data.regAddress : null],
      isActive: [this.data.isActive.toString() ? this.data.isActive.toString() : null],
      contacts: this.form.array([this.getContact()]),
      solutionTypeId:[this.data.solutionTypeId ? this.data.solutionTypeId : null],
      entityId : [this.data && this.data.entityAddresses.length ? this.data.entityAddresses[0].entityId : null],
      id : [this.data && this.data.entityAddresses.length ? this.data.entityAddresses[0].id : null],
      entityType : [this.data && this.data.entityAddresses.length ? this.data.entityAddresses[0].entityType : null],
      line1: [this.data && this.data.entityAddresses.length ? this.data.entityAddresses[0].line1 : null],
      line2 : [this.data && this.data.entityAddresses.length ? this.data.entityAddresses[0].line2 : null],
      line3 : [this.data && this.data.entityAddresses.length ? this.data.entityAddresses[0].line3 : null],
      city: [this.data && this.data.entityAddresses.length ? this.data.entityAddresses[0].city : null],
      zip : [this.data && this.data.entityAddresses.length ? this.data.entityAddresses[0].zip : null],
      country : [this.data && this.data.entityAddresses.length ? this.data.entityAddresses[0].country : null],
      entityProfiles : [this.data && this.data.entityProfiles.length ? this.data.entityProfiles : null],
      parkingFacilityform : [null],
      accessbilityform : [null],
      buildingLocation: [null],
      parkingLocation : [null],
      comments :[this.data.comments ? this.data.comments : null],

    });
    this.filterAndSetFormControlValues();
    if (this.parkingFacilityCodes.length && this.data && this.data.entityProfiles.length) {
      let parkingCode = this.data.entityProfiles.filter(val => this.parkingFacilityCodes.includes(val.identifyingType));
      let accesbilityCode = this.data.entityProfiles.filter(val => this.accessbilityCodes.includes(val.identifyingType));
      if (parkingCode.length) {
        const parkingFacilityValues = parkingCode.map(profile => profile.identifyingType);
        this.customerForm.controls.parkingFacilityform.setValue(parkingFacilityValues.length ? parkingFacilityValues : null);
      }
      if (accesbilityCode.length) {
        const accessbilityValues = accesbilityCode.map(profile => profile.identifyingType);
        this.customerForm.controls.accessbilityform.setValue(accessbilityValues.length ? accessbilityValues : null);
      }
    }
  }

  filterAndSetFormControlValues() {
    const hospitalData = this.data.entityProfiles.find(profile => profile.identifyingType === 'Hospital');
    const parkingData = this.data.entityProfiles.find(profile => profile.identifyingType === 'Parking');
    if (hospitalData) {
      const addressObject = JSON.parse(hospitalData.identifyingValue);
      const address = addressObject.address;
      this.customerForm.controls.buildingLocation.setValue(address);
    }
    if (parkingData) {
      const addressObject = JSON.parse(parkingData.identifyingValue);
      const address = addressObject.address;
      this.customerForm.controls.parkingLocation.setValue(address);
    }
  } 
  private getContact() {
    return this.form.group({
      contactName: [null],
      email: [this.data.email ? this.data.email : null, [Validators.pattern(/(^[0-9]{10}$)|(^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$)/)]], 
      phoneNumber: [null],
      comment: [null]
    });
  }

  private addContact() {
    const control = <UntypedFormArray>this.customerForm.controls['contacts'];
    control.push(this.getContact());
  }

  private removeContact(i: number) {
    const control = <UntypedFormArray>this.customerForm.controls['contacts'];
    control.removeAt(control.length - 1);
  }


  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  uploadBgImage($event): void {
    this.readData($event.target);
  }

  readData(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myTag: FileReader = new FileReader();
    myTag.onloadend = (e) => {
      this.bgImage = myTag.result;
    };
    myTag.readAsDataURL(file);
  }

  getAllCustomerType(data): void {
    this.commonService.getAppTermsVerion2(data).subscribe(res => {
      this.selectedCustomer = '2';
      this.buildForm();
      this.customerType = res;
    });
  }

  getCustomerList(): void {
    this.hospitalServices.getCustomerList().subscribe(res => {
      this.customerList = res.results;
    });
  }
  getRegionList(cust_id): void {
    this.hospitalServices.getRegionList(cust_id).subscribe(res => {
      this.regionList = res.results;
    });
  }

  safeUrl(value) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  detectFiles(evt) {
    const files = evt.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (readerEvt) => {
        const binaryString = readerEvt.target.result as string;
        const base64Data = btoa(binaryString);
        const url = this.safeUrl('data:' + file.type + ';base64,' + base64Data);
        const upload = {
          fileType: file.type,
          file: url,
        };
        this.attachedFiles = upload
        this.uploadedInfo();
      };
      reader.readAsBinaryString(file);
    }
  }

  uploadedInfo() {
    this.uploadedFile.push({
      attachmentId: this.attachedFiles.id,
      fileUrl: this.attachedFiles.file.changingThisBreaksApplicationSecurity,
      fileType: this.attachedFiles.fileType
    })
  }

  cancelFile(index) {
    this.uploadedFile.splice(index, 1);
  }
  public deleteAttachmentInTicket(imgObj, index) {
    if(imgObj.attachmentId == null){
    this.uploadedFile.splice(index, 1);
  }else {
    this.hospitalServices.deleteMultipleImages(imgObj.attachmentId).subscribe(res => {
      this.hospitalServices.getMultipleImages(this.data.id).subscribe(res => {
        this.uploadedFile = res.results;
    });
     
      this.toastr.success('Success', `Attachment removed successfully!`);
    });
  }
  }

  getLocation(value, event) {
    let valuess = event == "Parking" ? this.customerForm.controls.parkingLocation.value : this.customerForm.controls.buildingLocation.value;
    if (this.searchText === valuess && valuess !== null && this.geoLoc.hasOwnProperty('latlng')) {
      this.loc = {
        "type": "latlong", "threshold": 150, "ambulanceLatlong": 13.04634,
        "searchText": null, "searchLatLng": this.geoLoc
      };
    } else {
      this.searchText = valuess;
      this.loc = {
        "type": "latlong", "threshold": 150, "ambulanceLatlong": { lat: 13.04634, lng: 80.24674 },
        "searchText": valuess, "searchLatLng": null
      }
    }
    const dialogRef = this.dialog.open(GoogleMapComponent,
      { data: this.loc, panelClass: ['medium-popup'], disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (event == 'Hospital') {
          this.sourceGeoCoordinate = {
            "identifyingType": event,
            "latitude": result.latlng.lat,
            "longitude": result.latlng.lng,
            "address": result.formattedAddress,
            isActive: true
          };
          this.geoLoc = {
            'latlng': {
              'lat': result.latlng.lat, 'lng': result.latlng.lng,
              "givenAddress": this.customerForm.get("buildingLocation").value,
              "address": result.formattedAddress
            }
          }
          this.customerForm.get('buildingLocation').setValue(result.formattedAddress);
        } else if (event == 'Parking') {
          this.parkingGeoCoordinate = {
            "identifyingType": event,
            "latitude": result.latlng.lat,
            "longitude": result.latlng.lng,
            "address": result.formattedAddress,
            isActive: true
          };
          this.parkingGeoLoc = {
            'latlng': {
              'lat': result.latlng.lat, 'lng': result.latlng.lng,
              "givenAddress": this.customerForm.get("parkingLocation").value,
              "address": result.formattedAddress
            }
          }
          this.customerForm.get('parkingLocation').setValue(result.formattedAddress);
        }
      } else {
        if (event == 'Hospital') {
          this.customerForm.get("buildingLocation").setValue(valuess);
          if (this.customerForm.get("buildingLocation").value !== null &&
            this.customerForm.get("buildingLocation").value !== '' &&
            this.geoLoc.hasOwnProperty('latlng')) {
            this.sourceGeoCoordinate = {
              "lat": this.geoLoc.latlng.lat,
              "lng": this.geoLoc.latlng.lng,
              "address": this.customerForm.get("buildingLocation").value,
            };
          } else {
            this.parkingGeoCoordinate = {};
          }
        } else if (event == 'Parking') {
          this.customerForm.get("parkingLocation").setValue(valuess);
          if (this.customerForm.get("parkingLocation").value !== null &&
            this.customerForm.get("parkingLocation").value !== '' &&
            this.parkingGeoLoc.hasOwnProperty('latlng')) {
            this.parkingGeoCoordinate = {
              "lat": this.geoLoc.latlng.lat,
              "lng": this.geoLoc.latlng.lng,
              "address": this.customerForm.get("parkingLocation").value,
            };
          } else {
            this.parkingGeoCoordinate = {};
          }
        }
      }
    });
  }
  
  }
