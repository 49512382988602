/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Component, OnInit, ViewChild, Inject, ViewEncapsulation, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormGroupDirective, NgForm, UntypedFormArray } from '@angular/forms';
import {DatePipe} from '@angular/common';
import {ErrorStateMatcher} from '@angular/material/core';
import { AsyncValidatorFn, AsyncValidator, AbstractControl, ValidationErrors } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { HospitalService } from '../../../services/hospital.service';
import * as moment_ from 'moment';
import { Observable } from 'rxjs';


import { startWith, map } from 'rxjs/operators';
import { CreateShift, CreateUser, EditShift, EditUser, CreateStudent, EditStudent } from './create-user.model';
import { CommonService } from '../../../services/common.service';
import { ConfirmationDialog } from '../confirmation-dialog/confirmation-dialog.component';
import { ErrorStateMatcherService } from '../../../services/error-state-matcher.service';
import { environment } from '../../../../../environments/environment';
import { CommonMultiselectComponent } from '../common-multiselect/common-multiselect.component';
import { ToastrService } from 'ngx-toastr';
import { PasswordValidation } from '../password-validators';
const moment = moment_;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export function existingMobileNumberValidator(hospitalService: HospitalService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    return hospitalService.searchPhoneNo(control.value).pipe(map(
      res => {
        return (res && res.results.isAlreadyExists) ? {'isAlreadyExists': true} : null;
      }
    ));
  };
}

export function existingEmailValidator(hospitalService: HospitalService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    return hospitalService.searchEmail(control.value)
    .toPromise()
    .then(
      res => {
        return (res && res.results.isAlreadyExists) ? {'emailExists': true} : null;
      }
    );
  };
}

export function existingUsernameValidator(hospitalService: HospitalService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    return hospitalService.searchUserName(control.value).pipe(map(
      res => {
        return (res && res.results.isAlreadyExists) ? {'userNameExists': true} : null;
      }
    ));
  };
}

@Component({
    selector: 'app-create-user',
    templateUrl: './create-user.component.html',
    styleUrls: ['./create-user.component.scss'],
    providers: [DatePipe,
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
      ],
  })
  export class CreateUserComponent implements OnInit {
  
    public roleList: any[] = null;
    public genderList: any[] = null;
    public statusList: any[] = null;
    public userForm: UntypedFormGroup;
    public userShiftForm: UntypedFormGroup;
    public userPoolForm: UntypedFormGroup;
    public userPoolLocationForm: UntypedFormGroup;
    public  EducationForm : UntypedFormGroup;
    public  ProficienciesForm : UntypedFormGroup;
    public SpecialtiesForm : UntypedFormGroup;
    public ProceduresForm : UntypedFormGroup;
    public ServicesForm : UntypedFormGroup;
    userEducations = [];
    userdata: any= [] ;
    userProficiencies: any = [];
    userSpecialties: any = [];
    public userImage: any = null;
    selectedOption: string = 'Education';
    showTable: boolean = false;
    showTable1: boolean = false;
    showTable2: boolean = false;
            public createuser: CreateUser;
    public createStudent : CreateStudent;
    public editStudent : EditStudent;
    public createshift: CreateShift;
    public editshift: EditShift;
    public edituser: EditUser;
    public customerList: any;
    public regionList: any;
    public facilityList: any;
    public emailList: any;
    public phonenoList: any;
    public isLdap: boolean = false;
    public matcher = new ErrorStateMatcherService();
    loading = false;
    public isDisabled = false;
    public activate_btn = null;
    today = new Date();
    public langId: string;
    public countrycodeList = [];
    public countryOptions: Observable<any>;
    public enablePhoneNumber = false;
    public userNameList: any;
    public recipientEnabled = false;
    public managerId = null;
    requireManagerMatchVal: any;
    public listItems : any;
    customerName = null;
    height: any;
    selectedTab: any;
    ShiftdisplayedColumns: string[] = ["S.No", "shiftName", "startTime", "endTime", "Select"];
    ShiftdataSource: MatTableDataSource<any>;
    shiftList: any=[];
    userPoolList: any = [];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    scheduleStart: string;
    scheduleEnd: string;
    shiftData = false;
    public defaultStartTime = null;
    public defaultEndTime = null;
    public defaultStudentStartTime = null;
    public defaultStudentEndTime = null;
    startTime = null;
    endTime = null;
    shiftId = null;
    startDate = null;
    endDate = null;
    entityId = null;
    public requireLocationMatchVal: any;
    public locationId = null;
    public departmentId = null;
    public deptId = null;
    public isChange = false;
    public toHit = false;
    public locationlist: any;
    public deparmentList: any =[];
    public locationListRes: any;
    public departmentListRes: any = [];
    public departmentList: Array<any> = [];
    public blankField: boolean;
    public blankField2: boolean;
    locationEnabled = false;
    departmentEnabled = false;
    selectedIndex = 0;
    studentStartTime = null;
    studentEndTime = null;
    requireDepartmentMatchVal: any[];
    public roleType:string;
    locationPoolList: any=[];
    userPool: any=[];
    userPoolDetails: any=[];
    removedUserPool: any=[];
    userId = null;
    removedUserPoolLocation: any=[];
      userPoolLocation: any=[];
    userPoolLocationDetails: any=[];
    locationOption: any=[];
    userLoctionDetail: any=[];
    locDetail = null;
    selected2Index = 0;
    selected2Tab: any;
    public clinicalDetails: any = []; 
    public clinicalData: any = {
      'specialtyId' : [],
      'proceduresId' : [],
      'serviceId' : []
    }; 
    public userSpecialty: any = []; 
    public userSpecialtyList: any = []; 
    indextype: string;
    hide = true;
    hide1 = true;
    public selectedTableIndex = null;
    public selectedTableIndexprof = null;
  useredudelete: any;
  userprofdelete: any;
  edushow = false;
  profshow = false;
    // spectableData = []; 
      
    constructor(public form: UntypedFormBuilder,
      public toastr: ToastrService, public snackbar: MatSnackBar, private hospitalService: HospitalService, public thisDialogRef: MatDialogRef<CreateUserComponent>,
      public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private commonService: CommonService,
      private hospitalServices: HospitalService, private _dateFormat: DatePipe, private dateAdapter: DateAdapter<Date>, public datepipe: DatePipe) {
        this.activate_btn = this.commonService.getActivePermission('button');
        this.getCustomerList();
        this.managerId = this.data.managerId;
        this.deptId = this.data.departmentId;
        this.locationId = this.data.locationId;
        this.defaultStudentStartTime = this._dateFormat.transform(this.data.startDate, 'h:mm a');
        this.defaultStudentEndTime = this._dateFormat.transform(this.data.endDate, 'h:mm a');
        if(data !== null ){
          this.buildForm();
          }
        // this.buildForm();
        this.getRoles();
        this.userId = this.data.id;
        if(this.data.userPoolLocations && this.data.userPoolLocations.length !== 0) {
          this.removeUserPool(0, '');
          const control = <UntypedFormArray>this.userPoolForm.controls['userPoolList'];
          for(let i=0; i< this.data.userPoolLocations.length; i++) {
            this.data.poolId = this.data.userPoolLocations[i].id;
            this.data.poolNameId = this.data.userPoolLocations[i].poolNameId;
            this.data.poolLocationId = this.data.userPoolLocations[i].poolLocationId;
            control.push(this.editUserPool(i));
          }
        }
        if(this.data && this.data.id) {
          this.commonService.getUserLocationById(this.data.id).subscribe(res => {
            if(res.results.length !== 0 && res.results.userLocations.length !== 0) {
              this.userLoctionDetail = res.results.userLocations;
              this.removeUserPoolLocation(0, '');
              const control = <UntypedFormArray>this.userPoolLocationForm.controls['userPoolLocationList'];
              for(let i=0; i<res.results.userLocations.length; i++) {
                this.data.poolLocationId = res.results.userLocations[i].id;
                this.data.locationId = res.results.userLocations[i].locationId;
                this.data.locationName = res.results.userLocations[i].locationName;
                this.data.locationNameId = res.results.userLocations[i].locationId;
                this.locationOption.push(this.data.locationId);
                control.push(this.editUserPoolLocation(i));
              }
            }
          });
        }
        if(this.data && this.data.id) {
          this.hospitalService.getAllUsersid(this.data.id).subscribe(res => {
           this.userdata = res.results[0];
              this.userEducations = res.results[0].userEducations;
              this.showTable=true;
              this.userProficiencies =  res.results[0].userProficiencies;
              this.showTable1=true;
              this.clinicalDetails =  res.results[0].clinicalDataLinks;
              this.clinicalData = {
                'specialtyId' : this.clinicalDetails.filter(val => val.specialtyId != null),
                'proceduresId' : this.clinicalDetails.filter(val => val.proceduresId != null),
                'serviceId' : this.clinicalDetails.filter(val => val.serviceId != null)                
              }
              this.showTable2=true;
              
               this.buildForm() 
          });
          this.hospitalService.getAllUsersimage(this.data.id).subscribe(res => {res= res.results});
        }
        this.today.setDate(this.today.getDate());
        // dateAdapter.setLocale('en-in'); 
    }
  
    ngOnInit() {
      if (window.location.hostname.includes("kyn")) {
        this.customerName = "kyn";
      }
      if (this.data && this.data.type === 'task') {
        this.selectedIndex = 1;
        this.getAllShift();
        this.getShiftHistory(this.data.id, this.roleType);
      } else {
        this.selectedIndex = 0;
      }
      this.commonService.getAppTermsVerion2('PoolName').subscribe(res => {
        this.userPoolList = res.results;
      });
      this.commonService.getAppTermsVerion2('PoolLocation').subscribe(res => {
        this.locationPoolList = res.results;
      });
      this.langId = localStorage.getItem(btoa('lang'));
      this.commonService.getAppTermsVerion2('CountryCode').subscribe(res => {
        this.countrycodeList = res.results;
      });
      this.countryOptions = this.userForm.controls['countryCode'].valueChanges.pipe(
        startWith(null),
        map(value => this.countrycodeList.filter(country => country.code.indexOf(value) === 0))
      );
      this.phoneNumber('disable');

      if (this.data.type === 'student') {
        this.userForm.get("roleIds").setValidators(null);
        this.userForm.get("roleIds").updateValueAndValidity();
        this.userForm.get("customerName").setValidators(null);
        this.userForm.get("customerName").updateValueAndValidity();
        this.userForm.get("regionName").setValidators(null);
        this.userForm.get("regionName").updateValueAndValidity();
        this.userForm.get("customerId").setValidators(null);
        this.userForm.get("customerId").updateValueAndValidity();
      } else {
        this.userForm.get("studentStartDate").setValidators(null);
        this.userForm.get("studentStartDate").updateValueAndValidity();
        this.userForm.get("studentEndDate").setValidators(null);
        this.userForm.get("studentEndDate").updateValueAndValidity();
        this.userForm.get("studentStartTime").setValidators(null);
        this.userForm.get("studentStartTime").updateValueAndValidity();
        this.userForm.get("studentEndTime").setValidators(null);
        this.userForm.get("studentEndTime").updateValueAndValidity();
        this.userForm.get("mainidentifier").setValidators(null);
        this.userForm.get("mainidentifier").updateValueAndValidity();
      }
        }
  onWindowResized(size) {
    this.height = size;
  }
  tab2Click(event) {
     this.selected2Index = event.index;
    if(event.index === 0 || event.index === 1)
    {
      this.indextype = null
      this.edushow = false;
      this.profshow = false;
      this.EducationForm.reset()
      this.ProficienciesForm.reset()
    }
    else if(event.index === 2){
      this.indextype = "specialty"
    }else if (event.index === 3){
      this.indextype = "procedures"
    }else if (event.index === 4){
      this.indextype = "service"
    }
    this.selected2Tab = this.indextype ;
  }
  selectTab(index: number): void {
    this.selected2Index = index;
  }
    getAllShift() {
      this.hospitalServices.getAllShift().subscribe(res => {
        this.shiftList = res.results;
      });
    }
    getShiftHistory(id, type) {
      this.hospitalServices.getShiftHistory(id, type).subscribe(res => {
        if (res.results.length > 0) {
          this.shiftData = true;
        } else {
          this.shiftData = false;
        }
        this.ShiftdataSource = new MatTableDataSource<CreateUser>(res.results);
        this.ShiftdataSource.paginator = this.paginator;
        this.ShiftdataSource.sort = this.sort;
      });
    }
    getShiftTime(data) {
      this.userShiftForm.controls['startTime'].setValue(this._dateFormat.transform(data.startTime, 'h:mm a'));
      this.userShiftForm.controls['endTime'].setValue(this._dateFormat.transform(data.endTime, 'h:mm a'));
    }
    tabClick(event) {
      this.selectedTab = event.index;
       if (event.index == 3) {
        this.getAllShift();
        this.getShiftHistory(this.data.id, this.roleType);
      }
    }
    getCustomerList(): void {
      this.hospitalServices.getCustomerList().subscribe(res => {
        this.customerList = res.results;
      });
      if (this.data && this.data.facilityId) {
        this.getRegionList(this.data.customerId);
        this.getFacilityList(this.data.regionId);
      } else {
        this.getRegionList(localStorage.getItem('customerId'));
        this.getFacilityList(localStorage.getItem('regionId'));
      }
    }
    getRegionList(cust_id): void {
      this.hospitalServices.getRegionList(cust_id).subscribe(res => {
        this.regionList = res.results;
      });
    }
    getFacilityList(data) {
      this.hospitalServices.getFacilityList(data).subscribe(res => {
        this.facilityList = res.results;
      });
    }
    getRoles() {
      this.commonService.getAllRole().subscribe(res => {
        this.roleList = res.results;
        if (localStorage.getItem('userlevel') != '1') {
          this.roleList.shift();
        }
      });
      this.commonService.getAppTermsVerion2('Gender').subscribe(res => {
        this.genderList  = res.results;
      });
  
     
      this.commonService.getAppTermsVerion2('Status').subscribe(res => {
        this.statusList = res.results;
      });
    }
    userChange(value){
      if(value){
        this.isLdap = true;
      }
      this.isLdap = false;
    }
  
    getLocationList(id) {
      if (id !== null) {
        const location = this as any as { id: string, name: string, fullName: string }[]
        const locationId = location.find(obj => obj.id === id).name + ',' + location.find(obj => obj.id === id).fullName;
        return locationId;
      } else {
        return '';
      }
    }
  
    searchLocationlist(event) {
      this.locationId = null;
      this.locationEnabled = true;
      this.toHit = event.toHit;
      if (event.type === 'location' && event.text.length >= 2) {
        if (this.toHit === true || this.isChange) {
          this.isChange = false;
          this.hospitalServices.getHomeLocationData(event.text).subscribe(res => {
            if (res.results.length === 0) {
              this.blankField = true;
              this.blankField2 = true;
            }
            this.locationListRes = res.results;
            this.locationlist = this.locationListRes;
          });
        } else {
          this.blankField = false;
          this.blankField2 = false;
          this.locationlist = this.locationListRes;
        }
      } else {
        this.locationlist = [];
        this.locationEnabled = false;
      }
    }
    getLocationID(locationID: any) {
      if (locationID != null) {
        this.locationId = locationID;
      }
      this.blankField = false;
    }
  
    public buildForm() {
      this.userForm = this.form.group({
        firstName: [this.userdata.firstName ? this.userdata.firstName : null, [Validators.required, Validators.minLength(3), Validators.maxLength(25)]],
        lastName: [this.userdata.lastName ? this.userdata.lastName : null, [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
        address: [this.userdata.address ? this.userdata.address : null],
        
        email : [{value: this.userdata.email ? this.userdata.email : null, disabled: this.userdata.email}, [Validators.required, Validators.email],
        existingEmailValidator(this.hospitalServices)], 
        gender: [this.userdata.gender ? this.userdata.gender : null],
        
        birthDate: [this.userdata.birthDate ? new Date(this.userdata.birthDate) : null],
        status: [this.userdata.status ? this.userdata.status : 'Active'],
        userImage: [this.userdata.userImage ? this.userdata.userImage : null],
        phoneNumber: [this.userdata.phoneNumber ? this.userdata.phoneNumber.substring(this.userdata.phoneNumber.length - 10) : null, [Validators.required, Validators.pattern(/(^[0-9]{10}$)/)],
        this.userdata.phoneNumber == null ? existingMobileNumberValidator(this.hospitalServices) : null], 
        // roleIds: [ ( this.data.roles && this.data.roles.length ) ? this.data.roles[0].id : null, [Validators.required]],
        roleIds: [this.userdata.roleId ? this.userdata.roleId : null, [Validators.required]],
        customerName: [{value: this.userdata.customerId ? this.userdata.customerId : localStorage.getItem('customerId'), disabled: !(localStorage.getItem('userlevel') == '1' && this.activate_btn && this.activate_btn.indexOf('BT_MCCE') > -1)}, [Validators.required]],
        regionName: [{ value : this.userdata.regionId ? this.userdata.regionId : localStorage.getItem('regionId'), disabled: !(localStorage.getItem('userlevel') == '1' && this.activate_btn && this.activate_btn.indexOf('BT_MCRE') > -1)}, [Validators.required]],
        customerId: [{value : this.userdata.facilityId ? this.userdata.facilityId : localStorage.getItem(btoa('facilityId')), disabled: !(localStorage.getItem('userlevel') == '1' && this.activate_btn && this.activate_btn.indexOf('BT_MCFE') > -1)}, [Validators.required]],
        
        userName : [{value: this.userdata.userName ? this.userdata.userName : null, disabled: this.userdata.userName}, [Validators.required, Validators.pattern(/^\S*$/)],
        existingUsernameValidator(this.hospitalServices)], 
        isLdapUser:  [this.userdata.isLdapUser ? this.userdata.isLdapUser : this.isLdap],
        
        newPassword: [''],
        cnfrmNewPassword: [''],
        countryCode: [this.userdata.phoneNumber ? this.userdata.phoneNumber.substring(this.userdata.phoneNumber.length - 10, -10) : '+', [Validators.required, Validators.pattern('^[+][0-9]{1,5}$')]],
        managerId: [this.userdata.managerName ? this.userdata.managerName : null, [this.requireManagerMatch.bind(this)]],
        locationId: [this.userdata.locationName ? this.userdata.locationName : null, [this.requireLocationMatch.bind(this)]],
        sourceId: [this.userdata.sourceId ? this.userdata.sourceId : null],
        comments: [this.userdata.comments ? this.userdata.comments : null],
        studentStartTime: [this.userdata.startDate ? this._dateFormat.transform(this.userdata.startDate, 'h:mm a') : null, [Validators.required]],
        studentEndTime: [this.userdata.endDate ?   this._dateFormat.transform(this.userdata.endDate, 'h:mm a') : null, [Validators.required]],
        studentStartDate: [this.userdata.startDate ?  this._dateFormat.transform(this.userdata.startDate, 'yyyy-MM-dd') : this.today, [Validators.required]],
        studentEndDate: [this.userdata.endDate ? this._dateFormat.transform(this.userdata.endDate, 'yyyy-MM-dd') : null, [Validators.required]],
        departmentId: [this.userdata.departmentName ? this.userdata.departmentName : null, [this.requireDepartmentMatch.bind(this)]],
        mainidentifier: [this.userdata.mainIdentifier ? this.userdata.mainIdentifier : null, [Validators.required]],
        // poolNameId: [this.data.poolNameId ? this.data.poolNameId : null],
        // poolLocationId: [this.data.poolLocationId ? this.data.poolLocationId : null]
      },{ validator: PasswordValidation.MatchPassword }
      );
      this.userShiftForm = this.form.group({
        shiftId: [this.data.shiftId ? this.data.shiftId : null, [Validators.required]],
        startTime: [this.startTime ? this.startTime : null, [Validators.required]],
        endTime: [this.endTime ? this.endTime : null, [Validators.required]],
        startDate: [this.startDate ? this.startDate : this.today, [Validators.required]],
        endDate: [this.endDate ? this.endDate : null, [Validators.required]],
      }
      );
        this.userPoolForm = this.form.group({
        userPoolList: this.form.array([this.editUserPool(0)]),
        poolNameId: [null, [Validators.required]],
        poolLocationId: [null]
      }
            );
      this.userPoolLocationForm = this.form.group({
        userPoolLocationList: this.form.array([this.editUserPoolLocation(-1)])
      }
      );
      this.EducationForm = this.form.group({
        gradation: [null, [Validators.required]],
        id: [null],
        fieldOfStudy: [null],
        institutionName: [null],
        notes: [null],
        startDate: [null],
        endDate: [null],
      });
      this.ProficienciesForm = this.form.group({
        title: [null, [Validators.required]],
        id: [null],
        organisationName: [null],
        address: [null],
        notes: [null],
        startDate: [null],
        endDate: [null],
      });
      this.SpecialtiesForm = this.form.group({
        id: [null],
        specialtyName: [null],
        isActive: [null],
      })
      this.ProceduresForm = this.form.group({
        id: [null],
        specialtyName: [null],
        isActive: [null],
      })
      this.ServicesForm = this.form.group({
        id: [null],
        specialtyName: [null],
        isActive: [null],
      })
      if (this.data) {
        this.userImage = environment.api_base_url_new + environment.base_value.get_user_img + this.data.id
      }
    }

    addUserPoolLocation() {
      const control = <UntypedFormArray>this.userPoolLocationForm.controls['userPoolLocationList'];
      control.push(this.getUserPoolLocation());
    }
      
        removeUserPoolLocation(i: number, type) {
      if (this.data && type !== '') {
        if(this.userPoolLocationForm.controls['userPoolLocationList'].value[i].locationNameId !== null) {
          this.locDetail = this.userPoolLocationForm.controls['userPoolLocationList'].value[i].locationNameId;
        } else {
          this.locDetail = this.userPoolLocationForm.controls['userPoolLocationList'].value[i].locationId;
        }

        const locIndex = this.userLoctionDetail.findIndex(res => res.locationId === this.locDetail);
        if (locIndex !== -1) {
          this.userLoctionDetail[locIndex]['isDeletable'] = true;
          this.removedUserPoolLocation.push({
            'id':this.userLoctionDetail[locIndex].id,
            'locationId': this.userLoctionDetail[locIndex].locationId,
            'isDeletable': true
          });
        }
      }
      if(this.userPoolLocationForm.controls['userPoolLocationList'].value[i].locationId !== null) {
        this.locationOption = this.locationOption.filter(x => this.userPoolLocationForm.controls['userPoolLocationList'].value[i].locationId.indexOf(x) === -1);
      }
      const control = <UntypedFormArray>this.userPoolLocationForm.controls['userPoolLocationList'];
      control.removeAt(i);
      if (type == 'clear' && control.length <= 0) {
        this.addUserPoolLocation();
      }
    }
  
    private getUserPoolLocation() {
      return this.form.group({
        id: [null],
        locationId: [null],
        locationNameId: [null]
      });
    }
  
      private editUserPoolLocation(i) {
      if(i != -1) {
        return this.form.group({
          id: [this.data.poolLocationId],
          locationId: [this.data.locationId ? this.data.locationName : null,],
          locationNameId: [this.data.locationNameId ? this.data.locationNameId : null]
        });
      } else {
        return this.form.group({
          id: [null],
          locationId: [null],
          locationNameId: [null]
        });
      }
    }

    filterLocationOptions(value) {
      if (value !== null) {
        this.locationOption.push(value);
      } else {
        this.locationOption = [];
      }
    }

    addUserPool() {
      const control = <UntypedFormArray>this.userPoolForm.controls['userPoolList'];
      control.push(this.getUserPool());
    }
  
    removeUserPool(i: number, type) {
      if (this.data && type !== '') {
        const locDetail = this.userPoolForm.controls['userPoolList'].value[i].poolNameId;

        const locIndex = this.data.userPoolLocations.findIndex(res => res.poolNameId === locDetail);
        if (locIndex !== -1) {
          this.data.userPoolLocations[locIndex]['isDeletable'] = true;
          this.removedUserPool.push({
            'id':this.data.userPoolLocations[locIndex].id,
            'poolNameId': this.data.userPoolLocations[locIndex].poolNameId,
            'poolLocationId': this.data.userPoolLocations[locIndex].poolLocationId,
            'isDeletable': true
          });
        }
      }
      const control = <UntypedFormArray>this.userPoolForm.controls['userPoolList'];
      // if (this.userPoolForm.controls['userPoolList'].value[i].hasOwnProperty('pfAlertConfigRecipientId' &&
      // this.userPoolForm.controls['userPoolList'].value[i].pfAlertConfigRecipientId !== null)) {
      //   this.notificationId = this.notificationId.filter(x => this.alertRuleForm.controls['userList'].value[i].pfAlertConfigRecipientId !== x)
      // }
      control.removeAt(i);
      if (type == 'clear' && control.length <= 0) {
        this.addUserPool();
      }
    }
  
    private getUserPool() {
      return this.form.group({
        id: [null],
        poolNameId: [null, [Validators.required]],
        poolLocationId: [null]
      });
    }
  
    private editUserPool(i) {
      return this.form.group({
        id: [this.data.poolId],
        poolNameId: [this.data.poolNameId ? this.data.poolNameId : null,[Validators.required]],
        poolLocationId: [this.data.poolLocationId ? this.data.poolLocationId : null]
      });
    }
    requireManagerMatch(control: UntypedFormControl): ValidationErrors | null {
      if (this.managerId == null) {
        if (control.value !== null && control.value !== '' &&  this.recipientEnabled) {
        this.requireManagerMatchVal = this.userNameList.filter(resFilter => resFilter.id === control.value);
        if (this.requireManagerMatchVal.length === 0) {
            return { requireMatch: true };
          }
        }
        return null;
      }
    }
  
    private requireLocationMatch(control: UntypedFormControl): ValidationErrors | null {
      if (this.locationId == null) {
        if (control.value !== null && control.value !== '' && this.blankField) {
        this.requireLocationMatchVal = this.locationlist.filter(resFilter => resFilter.id === control.value);
        if (this.requireLocationMatchVal.length === 0) {
            return { requireMatch: true };
          }
        }
        return null;
      }
    }
  
    requireDepartmentMatch(control: UntypedFormControl): ValidationErrors | null {
      if (this.deptId == null) {
        if (control.value !== null && control.value !== '' &&  this.departmentEnabled) {
        this.requireDepartmentMatchVal = this.departmentList.filter(resFilter => resFilter.id === control.value);
        if (this.requireDepartmentMatchVal.length === 0) {
            return { requireMatch: true };
          }
        }
        return null;
      }
    }

    setEmailValue(event) {
      if (event) {
        this.userForm.get('userName').setValue(this.userForm.get('email').value);
        // this.userForm.get('userName').disable();
      } else {
        this.userForm.get('userName').setValue(this.userdata.userName ? this.userdata.userName : null);
        // this.userForm.get('userName').enable();
      }
      this.userForm.get('userName').updateValueAndValidity();
    }
  
    phoneNumber(type) {
      if ((this.data.id || this.data.userId) && type === 'disable') {
        this.enablePhoneNumber = false;
      } else {
        this.userForm.controls['countryCode'].setValue('+');
        this.userForm.controls['phoneNumber'].setValue(null);
        this.enablePhoneNumber = true;
      }
    }
    searchUserNamelist(event) {
      this.managerId = null;
      if (event.type === 'manager' && event.text.length >= 2){
        if (event.toHit == true) {
          this.commonService.getRecipientName(event.text, 'RT-US').subscribe(res => {
            this.listItems = res.results;
            this.userNameList = this.listItems;
            this.recipientEnabled = true;
          });
        } else {
          this.userNameList = this.listItems;
          this.recipientEnabled = true;
        }
      } else {
        this.userNameList = [];
        this.recipientEnabled = false;
      }
    }
    getRecipientList(id) {
      if (id) {
        const recipient = this as any as { id: string, name: string }[]
        const recipientId = recipient.find(obj => obj.id === id).name;
        return recipientId;
      } else {
        return '';
      }
    }
    getDepartmentList(id) {
      if (id) {
        const department = this as any as { id: string, name: string }[]
        const departmentId = department.find(obj => obj.id === id).name;
        return departmentId;
      } else {
        return '';
      }
    }
    addShift() {
      this.createshift = new CreateShift(null, null, null, null, null, null);
      const scheduleStartDate = this._dateFormat.transform(this.userShiftForm.controls['startDate'].value, 'yyyy-MM-dd');
      this.startTime = scheduleStartDate + ' ' + this.userShiftForm.controls['startTime'].value;
      if(this.userShiftForm.controls['endDate'].value !== null) {
        const scheduleEndDate = this._dateFormat.transform(this.userShiftForm.controls['endDate'].value, 'yyyy-MM-dd');
        this.endTime = scheduleEndDate + ' ' + this.userShiftForm.controls['endTime'].value;
      }
      this.scheduleStart = this._dateFormat.transform(this.startTime, 'yyyy-MM-dd HH:mm:ss');
      this.scheduleEnd = this._dateFormat.transform(this.endTime, 'yyyy-MM-dd HH:mm:ss');
      this.createshift.entityId = this.data.id;
      this.createshift.entityType = this.roleType;
      this.createshift.shiftMasterId = this.userShiftForm.controls['shiftId'].value;
      this.createshift.startDate = this.scheduleStart;
      this.createshift.endDate = this.scheduleEnd;
      this.createshift.status = true;
      
      this.hospitalServices.saveShift(this.createshift).subscribe(res => {
        if (res.statusCode == 1) {
          this.toastr.success('Success', `${res.message}`);
          this.getShiftHistory(this.data.id, this.roleType);
          this.userShiftForm.reset();
          this.defaultStartTime = null;
          this.defaultEndTime = null; 
          this.userShiftForm.controls['startTime'].setValue(null);
          this.userShiftForm.controls['endTime'].setValue(null);
          this.userShiftForm.controls['startDate'].setValue(this.today);
        }
      },
      error => {
        this.toastr.error('Error', `${error.error.message}`);
      });
    }
    editShift(data) {
      this.shiftId = data.id;
      this.entityId = data.entityId;
      const startTime = this._dateFormat.transform(data.startDate, 'h:mm a');
      this.startTime = startTime;
      const endTime = this._dateFormat.transform(data.endDate, 'h:mm a');
      this.endTime = endTime;
      const startDate = this._dateFormat.transform(data.startDate, 'yyyy-MM-dd');
      this.startDate = startDate;
      const endDate = this._dateFormat.transform(data.endDate, 'yyyy-MM-dd');
      this.endDate = endDate;
      this.userShiftForm.patchValue({
        'shiftId': data.shiftMasterId,
        'startTime': this.startTime,
        'endTime': this.endTime,
        'startDate': this.startDate,
        'endDate': this.endDate
      });
    }
    cancelEditShift() {
      this.shiftId = null;
      this.entityId = null;
      this.startTime = null;
      this.endTime = null;
      this.endDate = null;
      this.startDate = null;
      this.userShiftForm.reset();
      this.userShiftForm.controls['startDate'].setValue(this.today);
    }
    deleteShift(data) {
      this.shiftId = data.id;
      const shiftData = {
        "endDate": data.endDate,
        "entityId": data.entityId,
        "entityType": data.entityType,
        "shiftMasterId": data.shiftMasterId,
        "startDate": data.startDate,
        "isDeleteFlag": true
      }
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        panelClass:['confirmation-popup'], disableClose: true,
        data: {
          title: 'Remove Shift', message: 'Do you want to delete the shift ?',
          buttonText: { ok: 'Delete', cancel: 'Cancel' },
          'isRemark': 1, 'shift': true, 'shiftId': data.id,
          'shiftData': shiftData, 'deleteShift': true
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getShiftHistory(data.entityId, this.roleType);
        this.shiftId = null;
      });
    }
    updateShift() {
      this.editshift = new EditShift(null, null, null, null, null, null);
      const scheduleStartDate = this._dateFormat.transform(this.userShiftForm.controls['startDate'].value, 'yyyy-MM-dd');
      this.startTime = scheduleStartDate + ' ' + this.userShiftForm.controls['startTime'].value;
      if(this.userShiftForm.controls['endDate'].value !== null) {
        const scheduleEndDate = this._dateFormat.transform(this.userShiftForm.controls['endDate'].value, 'yyyy-MM-dd');
        this.endTime = scheduleEndDate + ' ' + this.userShiftForm.controls['endTime'].value;
      }
      this.scheduleStart = this._dateFormat.transform(this.startTime, 'yyyy-MM-dd HH:mm:ss');
      this.scheduleEnd = this._dateFormat.transform(this.endTime, 'yyyy-MM-dd HH:mm:ss');
      this.editshift.entityId = this.entityId;
      this.editshift.entityType = this.roleType;
      this.editshift.shiftMasterId = this.userShiftForm.controls['shiftId'].value;
      this.editshift.startDate = this.scheduleStart;
      this.editshift.endDate = this.scheduleEnd;
      this.editshift.status = true;
      
      this.hospitalServices.updateShift(this.shiftId, this.editshift).subscribe(res => {
        if (res.statusCode == 1) {
          this.toastr.success('Success', `${res.message}`);
          this.getShiftHistory(this.entityId, this.roleType);
          this.userShiftForm.reset();
          this.shiftId = null;
          this.entityId = null;
          this.defaultStartTime = null;
          this.defaultEndTime = null;
          this.userShiftForm.controls['startTime'].setValue(null);
          this.userShiftForm.controls['endTime'].setValue(null);
          this.userShiftForm.controls['startDate'].setValue(this.today);
        }
      },
      error => {
        this.toastr.error('Error', `${error.error.message}`);
      });
    }
    public saveUser() {
      this.isDisabled = true;
  
      this.createuser = new CreateUser(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null ,null);
      this.createuser.firstName   = this.userForm.controls['firstName'].value;
      this.createuser.lastName    = this.userForm.controls['lastName'].value;
      this.createuser.address     = this.userForm.controls['address'].value;
      this.createuser.email       = this.userForm.controls['email'].value;
      this.createuser.gender      = this.userForm.controls['gender'].value;
      this.createuser.birthDate   = this._dateFormat.transform(this.userForm.controls['birthDate'].value, 'yyyy-MM-dd');
      this.createuser.status      = this.userForm.controls['status'].value;
      this.createuser.phoneNumber = this.userForm.controls['countryCode'].value.trim() + this.userForm.controls['phoneNumber'].value;
      this.createuser.roleIds     = [this.userForm.controls['roleIds'].value];
      this.createuser.customerId  = this.userForm.controls['customerId'].value;
      this.createuser.userName  = this.userForm.controls['userName'].value;
      this.createuser.isLdapUser = this.userForm.controls['isLdapUser'].value;
      this.createuser.password = this.userForm.controls['cnfrmNewPassword'].value;
      this.createuser.managerId = this.userForm.controls['managerId'].value;
      this.createuser.locationId = this.locationId;
      this.createuser.sourceId = this.userForm.controls['sourceId'].value;
      this.createuser.comments = this.userForm.controls['comments'].value;
      this.createuser.userImage = this.userImage;
      this.createuser.userEducations = this.userEducations;
      this.createuser.userProficiencies = this.userProficiencies;
      this.clinicalDetails = [];
      for(let key of Object.keys(this.clinicalData)) {
        for(let i in this.clinicalData[key]) {
          this.clinicalData[key][i]['hospitalId'] = this.userForm.controls['customerId'].value;
          this.clinicalDetails.push(this.clinicalData[key][i])
        }
      } 
      this.createuser.clinicalDataLinks =  this.clinicalDetails; 
 
           // this.createuser.poolNameId = this.userForm.controls['poolNameId'].value;
      // this.createuser.poolLocationId = this.userForm.controls['poolLocationId'].value;
      this.hospitalServices.saveUser(this.createuser).subscribe(res => {
        if (res.statusCode != 1) {
          this.isDisabled = false;
        }
      
      this.toastr.success('Success', `${res.message}`);
      this.thisDialogRef.close('confirm');
      },
      error => {
        this.toastr.error('Error', `${error.error.message}`);
      });
}
    saveUserPool() {
      const userControl = <UntypedFormArray>this.userPoolForm.controls['userPoolList'];
      for (let j = 0; j < userControl.length; j++) {
        if (this.userPoolForm.controls['userPoolList'].value !== null) {
          if (this.userPoolForm.controls['userPoolList'].value[j].poolNameId != null) {
            this.userPool.push({
              'id': this.userPoolForm.controls['userPoolList'].value[j].id,
              'poolNameId': this.userPoolForm.controls['userPoolList'].value[j].poolNameId,
              'poolLocationId' : this.userPoolForm.controls['userPoolList'].value[j].poolLocationId,
            });
          }
        }
      }
      if(this.removedUserPool.length !== 0) {
        for (let a = 0; a < this.removedUserPool.length; a++) {
          this.userPool.push(this.removedUserPool[a]);
        }
      }
      if(this.userPool.length !== 0) {
        this.userPoolDetails.push({
          'userPoolLocations': this.userPool
        });
      }
      // console.log(this.data, this.userPoolDetails[0]);
      // return
      this.hospitalServices.updateUserPool(this.userPoolDetails[0],this.userId).subscribe(res => {
        if (res.statusCode != 1) {
          this.isDisabled = false;
        }
        this.toastr.success('Success', `${res.message}`);
        this.thisDialogRef.close('confirm');
      },
      error => {
        this.toastr.error('Error', `${error.error.message}`);
      });
    }
    saveUserPoolLocation() {
      const userControl = <UntypedFormArray>this.userPoolLocationForm.controls['userPoolLocationList'];
      for (let j = 0; j < userControl.length; j++) {
        if (this.userPoolLocationForm.controls['userPoolLocationList'].value !== null) {
          if (this.userPoolLocationForm.controls['userPoolLocationList'].value[j].locationId != null) {
            if(this.userPoolLocationForm.controls['userPoolLocationList'].value[j].locationNameId !== null) {
              this.userPoolLocation.push({
                'id': this.userPoolLocationForm.controls['userPoolLocationList'].value[j].id,
                'locationId' : this.userPoolLocationForm.controls['userPoolLocationList'].value[j].locationNameId,
              });
            } else {
              this.userPoolLocation.push({
                'id': this.userPoolLocationForm.controls['userPoolLocationList'].value[j].id,
                'locationId' : this.userPoolLocationForm.controls['userPoolLocationList'].value[j].locationId,
              });
            }
          }
        }
      }
      if(this.removedUserPoolLocation.length !== 0) {
        for (let a = 0; a < this.removedUserPoolLocation.length; a++) {
          this.userPoolLocation.push(this.removedUserPoolLocation[a]);
        }
      }
      if(this.userPoolLocation.length !== 0) {
        this.userPoolLocationDetails.push({
          'userLocations': this.userPoolLocation
        });
      }
      // console.log(this.data, this.userPoolLocationDetails[0]);
      // return
      this.hospitalServices.updateUserPool(this.userPoolLocationDetails[0],this.userId).subscribe(res => {
        if (res.statusCode != 1) {
          this.isDisabled = false;
        }
        this.toastr.success('Success', `${res.message}`);
        this.thisDialogRef.close('confirm');
      },
      error => {
        this.toastr.error('Error', `${error.error.message}`);
      });
    }
    
  public saveStudent() {
    this.isDisabled = true;

    this.createStudent = new CreateStudent(null, null, null, null, null, null, null, null, null, null, null, null, null, null,  null, null);
    const facilityId = localStorage.getItem(btoa('facilityId'));
    const scheduleStartDate = this._dateFormat.transform(this.userForm.controls['studentStartDate'].value, 'yyyy-MM-dd');
    this.studentStartTime = scheduleStartDate + ' ' + this.userForm.controls['studentStartTime'].value;
    if (this.userForm.controls['studentEndDate'].value !== null) {
      const scheduleEndDate = this._dateFormat.transform(this.userForm.controls['studentEndDate'].value, 'yyyy-MM-dd');
      this.studentEndTime = scheduleEndDate + ' ' + this.userForm.controls['studentEndTime'].value;
    }
    this.scheduleStart = this._dateFormat.transform(this.studentStartTime, 'yyyy-MM-dd HH:mm:ss');
    this.scheduleEnd = this._dateFormat.transform(this.studentEndTime, 'yyyy-MM-dd HH:mm:ss');
    this.createStudent.startDate = this.scheduleStart;
    this.createStudent.endDate = this.scheduleEnd;
    this.createStudent.customerId  = facilityId;
    this.createStudent.firstName   = this.userForm.controls['firstName'].value;
    this.createStudent.lastName    = this.userForm.controls['lastName'].value;
    this.createStudent.address     = this.userForm.controls['address'].value;
    this.createStudent.email       = this.userForm.controls['email'].value;
    this.createStudent.gender      = this.userForm.controls['gender'].value;
    this.createStudent.phoneNumber = this.userForm.controls['phoneNumber'].value ? this.userForm.controls['countryCode'].value.trim() + this.userForm.controls['phoneNumber'].value : null;
    this.createStudent.userTypeId      = 'UT_STUDENT';
    this.createStudent.isCreateUserOnly = true;
    this.createStudent.isLdapUser = false;
    this.createStudent.mainidentifier = this.userForm.controls['mainidentifier'].value;
    this.createStudent.birthDate   = this._dateFormat.transform(this.userForm.controls['birthDate'].value, 'yyyy-MM-dd');
    this.createStudent.departmentId = this.userForm.controls['departmentId'].value;
    this.createStudent.locationId = this.locationId;

    this.hospitalServices.saveUser(this.createStudent).subscribe(res => {
        if (res.statusCode != 1) {
          this.isDisabled = false;
        }

        this.toastr.success('Success', `${res.message}`);
        this.thisDialogRef.close('confirm');
      },
      error => {
        this.toastr.error('Error', `${error.error.message}`);
      });
    }

    public updateStudent(data) {
      this.isDisabled = true;
      this.editStudent = new EditStudent(null, null, null, null, null, null, null, null, null, null, null,null, null, null,  null, null);
      const facilityId = localStorage.getItem(btoa('facilityId'));
      const scheduleStartDate = this._dateFormat.transform(this.userForm.controls['studentStartDate'].value, 'yyyy-MM-dd');
      this.studentStartTime = scheduleStartDate + ' ' + this.userForm.controls['studentStartTime'].value;
      if(this.userForm.controls['studentEndDate'].value !== null) {
        const scheduleEndDate = this._dateFormat.transform(this.userForm.controls['studentEndDate'].value, 'yyyy-MM-dd');
        this.studentEndTime = scheduleEndDate + ' ' + this.userForm.controls['studentEndTime'].value;
      }
      this.editStudent.id = data.userId;
      this.scheduleStart = this._dateFormat.transform(this.studentStartTime, 'yyyy-MM-dd HH:mm:ss');
      this.scheduleEnd = this._dateFormat.transform(this.studentEndTime, 'yyyy-MM-dd HH:mm:ss');
      this.editStudent.startDate = this.scheduleStart;
      this.editStudent.endDate = this.scheduleEnd;
      this.editStudent.customerId  = facilityId;
      this.editStudent.firstName   = this.userForm.controls['firstName'].value;
      this.editStudent.lastName    = this.userForm.controls['lastName'].value;
      this.editStudent.address     = this.userForm.controls['address'].value;
      this.editStudent.email       = this.userForm.controls['email'].value;
      this.editStudent.gender      = this.userForm.controls['gender'].value;
      this.editStudent.phoneNumber = this.userForm.controls['phoneNumber'].value ? this.userForm.controls['countryCode'].value.trim() + this.userForm.controls['phoneNumber'].value : null;
      this.editStudent.userTypeId      = 'UT_STUDENT';
      // this.editStudent.mainidentifier = this.userForm.controls['mainidentifier'].value;
      this.editStudent.birthDate   = this._dateFormat.transform(this.userForm.controls['birthDate'].value, 'yyyy-MM-dd');
      if (this.deptId === null) {
        this.editStudent.departmentId  = this.userForm.controls['departmentId'].value;
      } else {
        this.editStudent.departmentId  = this.deptId;
      }
      this.editStudent.locationId = this.locationId;

      this.hospitalServices.editUser(this.editStudent).subscribe(res => {
          if (res.statusCode != 1) {
            this.isDisabled = false;
          }
  
          this.toastr.success('Success', `${res.message}`);
          this.thisDialogRef.close('confirm');
        },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });
      }

    public editUser(data) {
      this.isDisabled = true;
        
      this.edituser = new EditUser(null, null,  null, null, null, null,null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null ,null);
      this.edituser.id          = data.id;
      this.edituser.firstName   = this.userForm.controls['firstName'].value;
      this.edituser.lastName    = this.userForm.controls['lastName'].value;
      this.edituser.address     = this.userForm.controls['address'].value;
      this.edituser.email       = this.userForm.controls['email'].value;
      this.edituser.gender      = this.userForm.controls['gender'].value;
      this.edituser.birthDate   = this._dateFormat.transform(this.userForm.controls['birthDate'].value, 'yyyy-MM-dd');
      this.edituser.status      = this.userForm.controls['status'].value;
      
      this.edituser.phoneNumber = this.userForm.controls['phoneNumber'].value ? this.userForm.controls['countryCode'].value.trim() + this.userForm.controls['phoneNumber'].value : null;
      this.edituser.roleIds     = [this.userForm.controls['roleIds'].value];
      this.edituser.customerId  = this.userForm.controls['customerId'].value;
      this.edituser.userImage = this.userImage;
      let imageCheck = this.edituser.userImage.split(':');
      if(imageCheck.length > 0 && imageCheck[0] != 'data') {
        delete this.edituser['userImage'];
      }
        console.log(this.userImage)
      this.edituser.userEducations = this.userEducations
      this.edituser.userProficiencies = this.userProficiencies
      this.clinicalDetails = [];
       for(let key of Object.keys(this.clinicalData)) {
        for(let i in this.clinicalData[key]) {
          this.clinicalData[key][i]['hospitalId'] = this.userForm.controls['customerId'].value;
          this.clinicalDetails.push(this.clinicalData[key][i])
        }
      }
      this.edituser.clinicalDataLinks=  this.clinicalDetails;    



      if (this.managerId === null) {
        this.edituser.managerId  = this.userForm.controls['managerId'].value;
      } else {
        this.edituser.managerId  = this.managerId;
      }
      this.edituser.locationId = this.locationId;
      
      this.edituser.isLdapUser = this.userForm.controls['isLdapUser'].value;
      
      this.edituser.sourceId = this.userForm.controls['sourceId'].value; 
      this.edituser.comments = this.userForm.controls['comments'].value; 
                                                        
      // this.edituser.poolNameId = this.userForm.controls['poolNameId'].value;

      // this.edituser.poolLocationId = this.userForm.controls['poolLocationId'].value;

      this.hospitalServices.editUser(this.edituser).subscribe(result => {
        if (result.statusCode != 1) {
          this.isDisabled = false;
        }
        
        this.toastr.success('Success', `${result.message}`);
        this.thisDialogRef.close('confirm');
       },
       error => {
         this.toastr.error('Error', `${error.error.message}`);
       });
    }

    searchDepartmentDetails(event) {
      this.deptId = null;
      this.departmentEnabled = true;
      if (event.type === 'department' && event.text.length >= 2) {
        if (event.toHit === true) {
          this.commonService.getAllDepartments(event.text).subscribe(res => {
            this.departmentListRes = res.results;
            this.departmentList = this.departmentListRes;
            this.departmentEnabled = true;
          });
        } else {
          this.departmentList = this.departmentListRes;
          this.departmentEnabled = true;
        }
      } else {
        this.departmentList = [];
        this.departmentEnabled = false;
      }
    }
    roleSelect(value) {
      if (value === 'RO-PO') {
        this.roleType = 'Porter';
      } else {
        this.roleType = 'User';
      }
  }

  uploadBgImage($event): void {
    this.readData($event.target);
  }

  readData(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myTag: FileReader = new FileReader();

    myTag.onloadend = (e) => {
      this.userImage = myTag.result;
    };
    myTag.readAsDataURL(file);
  }
 
  editEdu(data) {
    this.selectedTableIndex = null;
    this.edushow = true;
    if (this.userEducations.length) {
      this.selectedTableIndex = this.userEducations.findIndex(val => val.gradation == data.gradation)
    }
    this.EducationForm.patchValue({
      'facilityId': data.facilityId,
      'gradation': data.gradation,
      'fieldOfStudy': data.fieldOfStudy,
      'institutionName': data.institutionName,
      'notes': data.notes,
      'startDate': data.startDate,
      'endDate': data.endDate,
      'id': data.id
    });
  }
  addOrEditEDURow() {
    this.showTable = false;
    const facilityId = localStorage.getItem(btoa('facilityId'));
    const data = {
      'id': this.EducationForm.controls['id'].value || null,
      'facilityId': facilityId,
      'gradation': this.EducationForm.controls['gradation'].value,
      'fieldOfStudy': this.EducationForm.controls['fieldOfStudy'].value,
      'institutionName': this.EducationForm.controls['institutionName'].value,
      'notes': this.EducationForm.controls['notes'].value,
      'startDate': this.datepipe.transform(this.EducationForm.controls['startDate'].value, 'yyyy-MM-dd'),
      'endDate': this.datepipe.transform(this.EducationForm.controls['endDate'].value, 'yyyy-MM-dd'),
    };
    if (this.selectedTableIndex == null) {
      if (!this.userEducations.some(item => item.gradation.toLowerCase() === data.gradation.toLowerCase())) {
        this.userEducations.push(data);
      } else {
        this.toastr.warning('warning', 'Graduation name is already existed');
      }
    } else {
      this.userEducations[this.selectedTableIndex]['gradation'] = this.EducationForm.controls['gradation'].value;
      this.userEducations[this.selectedTableIndex]['fieldOfStudy'] = this.EducationForm.controls['fieldOfStudy'].value;
      this.userEducations[this.selectedTableIndex]['institutionName'] = this.EducationForm.controls['institutionName'].value;
      this.userEducations[this.selectedTableIndex]['notes'] = this.EducationForm.controls['notes'].value;
      this.userEducations[this.selectedTableIndex]['startDate'] = this.EducationForm.controls['startDate'].value;
      this.userEducations[this.selectedTableIndex]['endDate'] = this.EducationForm.controls['endDate'].value;
    }
    if (!data.id) {
      if (this.userEducations.some(item => item.gradation.toLowerCase() === data.gradation.toLowerCase())) {
      } else {
        this.userEducations.push(data);
      }
    } else {
      let rowIndex = this.userEducations.findIndex(val => val.id == data.id);
      if (rowIndex !== -1) {
        this.userEducations[rowIndex] = data;
      }
    }
    setTimeout(() => this.showTableAction('education'), 50);
    this.selectedTableIndex = null;
    this.edushow = false;
    this.EducationForm.reset();
  }

  editProf(data) {
    this.selectedTableIndexprof = null;
    this.profshow = true;
    if (this.userProficiencies.length) {
      this.selectedTableIndexprof = this.userProficiencies.findIndex(val => val.title == data.title)
    }
    this.ProficienciesForm.patchValue({
      'facilityId': data.facilityId,
      'title': data.title,
      'organisationName': data.organisationName,
      'address': data.address,
      'notes': data.notes,
      'startDate': data.startDate,
      'endDate': data.endDate,
      'id': data.id
    });
  }

  addOrEditPROFRow() {
    this.showTable1 = false;
    const facilityId = localStorage.getItem(btoa('facilityId'));
    const data = {
      'id': this.ProficienciesForm.controls['id'].value || null,
      'facilityId': facilityId,
      'title': this.ProficienciesForm.controls['title'].value,
      'organisationName': this.ProficienciesForm.controls['organisationName'].value,
      'address': this.ProficienciesForm.controls['address'].value,
      'notes': this.ProficienciesForm.controls['notes'].value,
      'startDate': this.datepipe.transform(this.ProficienciesForm.controls['startDate'].value, 'yyyy-MM-dd'),
      'endDate': this.datepipe.transform(this.ProficienciesForm.controls['endDate'].value, 'yyyy-MM-dd'),
    };
    if (this.selectedTableIndexprof == null) {
      if (!this.userProficiencies.some(item => item.title.toLowerCase() === data.title.toLowerCase())) {
        this.userProficiencies.push(data);
      } else {
        this.toastr.warning('warning', 'Title name is already existed');
      }
    } else {
      this.userProficiencies[this.selectedTableIndexprof]['title'] = this.ProficienciesForm.controls['title'].value;
      this.userProficiencies[this.selectedTableIndexprof]['organisationName'] = this.ProficienciesForm.controls['organisationName'].value;
      this.userProficiencies[this.selectedTableIndexprof]['address'] = this.ProficienciesForm.controls['address'].value;
      this.userProficiencies[this.selectedTableIndexprof]['notes'] = this.ProficienciesForm.controls['notes'].value;
      this.userProficiencies[this.selectedTableIndexprof]['startDate'] = this.ProficienciesForm.controls['startDate'].value;
      this.userProficiencies[this.selectedTableIndexprof]['endDate'] = this.ProficienciesForm.controls['endDate'].value;
    }
    if (!data.id) {
      if (this.userProficiencies.some(item => item.title.toLowerCase() === data.title.toLowerCase())) {
      } else {
        this.userProficiencies.push(data);
      }
    } else {
      let rowIndex = this.userProficiencies.findIndex(val => val.id == data.id);
      if (rowIndex !== -1) {
        this.userProficiencies[rowIndex] = data;
      }
    }
    setTimeout(() => this.showTableAction('Proficiencies'), 50);
    this.selectedTableIndexprof = null;
    this.profshow = false;
    this.ProficienciesForm.reset();
  }
  triggerAction(event ) {
    console.log(event)
    if (event.key === 'edit') {
      this.selected2Index === 0 ? this.editEdu(event.data) : this.editProf(event.data)
      // this.editSpec(event.data)
    } else if (event.key === 'delete') {
      this.selected2Index === 0 ? this.deleteEdu(event.data) : this.deleteProf(event.data)
    }
  }
  deleteEdu(data){
    this.showTable = false;
    if (data.id == null){
    this.selectedTableIndex = null;
    if(this.userEducations.length){
      this.selectedTableIndex = this.userEducations.findIndex(val => val.gradation == data.gradation)}
     this.userEducations.splice(this.selectedTableIndex,1) [0];
     this.selectedTableIndex = null;
     setTimeout(() => this.showTableAction('education'), 50);
     this.EducationForm.reset()
    }
    else{
      this.useredudelete = data.id,
      this.hospitalService.deleteusereducation(this.useredudelete).subscribe(res => {
        if (res.statusCode == 1) {
          this.hospitalService.getAllUsersid(this.data.id).subscribe(res => {
            this.userEducations = res.results[0].userEducations;
            this.showTable=true;
        });
        }
      });
       this.EducationForm.reset()
    }
  }
  deleteProf(data){
    this.showTable1 = false;
    if (data.id == null){
    this.selectedTableIndexprof = null;
    if(this.userProficiencies.length){
      this.selectedTableIndexprof = this.userProficiencies.findIndex(val => val.title == data.title)}
     this.userProficiencies.splice(this.selectedTableIndexprof,1) [0];
     this.selectedTableIndexprof = null;
     setTimeout(() => this.showTableAction('Proficiencies'), 50);
     this.ProficienciesForm.reset()
  }
  else{
    this.userprofdelete = data.id,
    this.hospitalService.deleteuserproficiency(this.userprofdelete).subscribe(res => {
      if (res.statusCode == 1) {
        this.hospitalService.getAllUsersid(this.data.id).subscribe(res => {
          this.userProficiencies =  res.results[0].userProficiencies;
          this.showTable1=true;

      });
      }
    });
    this.ProficienciesForm.reset()
  }
}
  showTableAction(type) {
    if (type == 'education') {
      this.showTable = !this.showTable
    } else if (type == 'Proficiencies') {
      this.showTable1 = !this.showTable1
    } else if (type == 'Specialties') {
            this.showTable2 = !this.showTable2
    }
        }

  addSPECcheck() {
    let key = this.indextype === 'specialty' ? 'specialtyId' : this.indextype === 'procedures' ? 'proceduresId' : this.indextype === 'service' ?  'serviceId' : null;
    let data = {
      "type": this.indextype,
      "slectedData": this.clinicalData[key].filter(val => val.isActive),
      "key" : key
    }
    const dialogRef = this.dialog.open(CommonMultiselectComponent, {
      panelClass: ['small-popup'], disableClose: true, data: data });
    this.showTable2 = false;
    dialogRef.afterClosed().subscribe(result => {
      let key = this.indextype === 'specialty' ? 'specialtyId' : this.indextype === 'procedures' ? 'proceduresId' : this.indextype === 'service' ?  'serviceId' : null;
      if(key) {
        for (let i in result) {
          let obj = {
            "id": result[i].uid,
            "specialtyId": key == 'specialtyId' ? result[i].id : null,
            "specialtyName": key == 'specialtyId' ? result[i].name : null,
            "proceduresId": key == 'proceduresId' ? result[i].id : null,
            "proceduresName": key == 'proceduresId' ? result[i].name : null,
            "serviceId": key == 'serviceId' ? result[i].id : null,
            "serviceName": key == 'serviceId' ? result[i].name : null,
            "isActive": result[i].isActive,
            "facilityId": result[i].facilityId ? result[i].facilityId : localStorage.getItem(btoa('facilityId'))
          }
          let specIndex = this.clinicalData[key].findIndex(val => val[key] == result[i].id)
          if (specIndex != -1) {
            this.clinicalData[key][specIndex]["isActive"] = result[i].isActive;
          } else {
            this.clinicalData[key].push(obj)
          }
        }
      }
      this.showTable2 = true;
    });
  }
    
  
}
  
