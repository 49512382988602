<div  class="input-component-holder ovi-font-family">
    <mat-toolbar class="input-header">
        <div i18n class="header-name" style="text-overflow: ellipsis;">
            {data.eType, select, tempId {Enroll Temporary Id Card} visitor {Enroll Visitor} employee {Enroll Employee}}
        </div>
        <div class="header-close1"><button mat-icon-button mat-dialog-close close-button class="ie-close-icon">
                <mat-icon class="icon-style">highlight_off</mat-icon>
            </button></div>
    </mat-toolbar>
    <div class="popup_form_body popup-form-container popup-margin">
    <mat-tab-group >
        <mat-tab i18n-label label="Basic Info" class="active-btn margin-r-1">
            <div class="mat-custom-form-field-box" [formGroup]="enrollEmployeeForm" style="overflow: hidden;">
                    <div class="form-row">
                        <div i18n class="ovi-popup-title-text-size top-styl">
                            {data.eType, select, tempId {TEMPORARY ID CARD DETAILS} visitor {VISITOR DETAILS} employee {EMPLOYEE DETAILS}}
                        </div>
                    </div>
                    <mat-divider class="divider"></mat-divider>
                    <div class="form-row block-3"
                        *ngIf="data.eType === 'tempId' || data.eType === 'visitor' || data.eType === 'employee'">
                        <div class="row-field-1" *ngIf="data.eType === 'visitor'">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">Visiting Employee Id</mat-label>
                                <mat-form-field appNoSpace class="example-full-width form-field" appearance="outline">
                                    <input matInput i18n-placeholder placeholder="Visiting Employee Id"
                                        formControlName="hostEmployeeId" autocomplete="off" role="presentation">

                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row-field-1" *ngIf="data.eType === 'employee'">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">Employee Id <span class="mandatory-field">*</span></mat-label>
                                <mat-form-field appNoSpace class="example-full-width form-field" appearance="outline">
                                    <input matInput i18n-placeholder placeholder="Employee Id"
                                        formControlName="mainidentifier" autocomplete="off" role="presentation">
                                    <mat-error *ngIf="enrollEmployeeForm.controls.mainidentifier.invalid" class="error-txt-1">
                                        <span i18n [hidden]="!enrollEmployeeForm.controls['mainidentifier'].errors.required">
                                            Employee Id is required</span>

                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row-field-1" *ngIf="data.eType === 'tempId'">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">Employee Id <span class="mandatory-field">*</span></mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <input i18n-placeholder appNoSpace matInput #input placeholder="Employee Id" (keyup.enter)="checkEmpId(input.value)"
                                        (keyup)="searchTempId($event.target.value, 'TT-ID', $event)" [matAutocomplete]="auto"
                                        formControlName="mainidentifier" [readonly]="employeeId != null">
                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                        <mat-option *ngFor="let option of tempEmpIdList" [value]="option.mainidentifier">
                                            <div class="tot-wid-styl" (click)="checkEmpId(option)">{{option.mainidentifier}}</div>
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-hint *ngIf="emptyList == true">
                                        <span style="color:red;">Please choose the option from list</span>
                                    </mat-hint>
                                    <mat-error *ngIf="enrollEmployeeForm.controls.mainidentifier.invalid" class="error-txt-1">
                                        <span i18n [hidden]="!enrollEmployeeForm.controls['mainidentifier'].errors.required">
                                            Employee Id is required</span>
                                        <span i18n [hidden]="!enrollEmployeeForm.controls['mainidentifier'].errors.pattern">
                                            please enter numbers only</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row-field-1">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">Name <span class="mandatory-field">*</span></mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <input *ngIf="data.eType === 'visitor'" appNoSpace matInput minlength="3" maxlength="50" i18n-placeholder placeholder="Visitor Name"
                                        formControlName="firstName" autocomplete="off" role="presentation">
                                    <input *ngIf="data.eType === 'employee'" appNoSpace matInput minlength="3" maxlength="50" i18n-placeholder placeholder="Employee Name"
                                        formControlName="firstName" autocomplete="off" role="presentation">
                                    <input *ngIf="data.eType === 'tempId'" appNoSpace matInput minlength="3" maxlength="50" i18n-placeholder placeholder="Temp Name"
                                        formControlName="firstName" autocomplete="off" role="presentation">
                                    <mat-error *ngIf="enrollEmployeeForm.controls.firstName.invalid" class="error-txt-1">
                                        <span i18n [hidden]="!enrollEmployeeForm.controls['firstName'].errors.required">
                                            {data.eType, select, visitor {Visitor name is required. (Limit 3 - 50 chars.)} employee {Employee name is required. (Limit 3 - 50 chars.)}}</span>
                                        <span i18n [hidden]="!enrollEmployeeForm.controls['firstName'].errors.minlength">Min.
                                            3 chars required.</span>
                                        <span i18n [hidden]="!enrollEmployeeForm.controls['firstName'].errors.maxlength">Max.
                                            allowed chars 50 </span>
                                        <span i18n [hidden]="!enrollEmployeeForm.controls['firstName'].errors.pattern">
                                            Please enter alphabets only </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row-field-1">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">{data.eType, select, tempId {ID Card Tag <span class="mandatory-field">*</span>} visitor {ID Card Tag} employee {ID Card Tag}}</mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <input i18n-placeholder matInput placeholder="ID Card Tag" id="idCard" appTypeHit (typeHit)="searchTagAssociation($event, 'TT-IDCO')"
                                        [matAutocomplete]="autoFrom" formControlName="employeeTagSerialNumber" [readonly]="employeeTagId != null">
                                    <mat-autocomplete autoActiveFirstOption #autoFrom="matAutocomplete">
                                        <mat-option *ngFor="let option of employeeTagList" [value]="option.tagId">
                                            <div class="tot-wid-styl">{{option.tagId}}</div>
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="enrollEmployeeForm.controls.employeeTagSerialNumber.invalid" class="error-txt-1">
                                        <span i18n [hidden]="!enrollEmployeeForm.controls['employeeTagSerialNumber'].errors.required">
                                            Tag Id is required</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row-field" *ngIf="data.id && employeeTagId != null">
                            <div class="example-form" style="line-height: 5;">
                                <button i18n mat-button class="active-btn margin-r-1"
                                    (click)="disAssociateTag(employeeTagId)" cdkFocusInitial>Disassociate</button>
                            </div>
                        </div>
                        <div class="row-field-code">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">Code</mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <input matInput i18n-placeholder placeholder ="Code" matInput
                                    formControlName="countryCode" [matAutocomplete]="countryCode">
                                    <mat-autocomplete #countryCode="matAutocomplete"  panelWidth = "250px">
                                        <mat-option *ngFor="let country of countryOptions | async" value={{country.code}} matTooltip="{{country.value}}">
                                            {{country.code}} ({{country.value}})
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="enrollEmployeeForm.controls.countryCode.invalid" class="error-txt-1">

                                        <span i18n [hidden]="!enrollEmployeeForm.controls['countryCode'].errors.pattern">code (eg. +91)</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row-field-mobile">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size"> Mobile </mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <input matInput i18n-placeholder placeholder="Mobile" formControlName="mobileNo"
                                        autocomplete="off" role="presentation">
                                    <mat-error *ngIf="enrollEmployeeForm.controls.mobileNo.invalid" class="error-txt-1">

                                        <span i18n [hidden]="!enrollEmployeeForm.controls['mobileNo'].errors.pattern">
                                                Invalid Phone number
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row-field-1">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size"> E-mail </mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <input matInput i18n-placeholder placeholder="E-mail" formControlName="email"
                                        autocomplete="off" role="presentation">
                                    <mat-error *ngIf="enrollEmployeeForm.controls.email.invalid" class="error-txt-1">
                                        <span i18n [hidden]="!enrollEmployeeForm.controls['email'].errors.email">
                                            Invalid email
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row-field-gender">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size"> Gender </mat-label>
                                <div class="radio-button">
                                    <mat-radio-group formControlName="gender">
                                        <mat-radio-button class="cst-mgn_1" *ngFor="let o of genderList; let i = index"
                                            [checked]="enrollEmployeeForm.controls.gender.value === o.code" [value]="o.code">{{o.value}}</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="row-field-1" *ngIf= "data.eType === 'employee'">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">DOB</mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <input matInput  autocomplete="off" role="presentation" [matDatepicker]="DOB" [max]="today" formControlName="birthDate">
                                    <mat-datepicker-toggle matSuffix [for]="DOB"></mat-datepicker-toggle>
                                    <mat-datepicker #DOB></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row-field-1" *ngIf= "data.eType === 'employee'">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">Joining Date</mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <input matInput  autocomplete="off" role="presentation" [matDatepicker]="joiningDate" formControlName="joinDate">
                                    <mat-datepicker-toggle matSuffix [for]="joiningDate"></mat-datepicker-toggle>
                                    <mat-datepicker #joiningDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row-field-1" *ngIf= "data.eType === 'employee'">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">Manager ID</mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <input matInput i18n-placeholder autocomplete="off" role="presentation" placeholder="Manager ID"
                                        formControlName="managerId" autocomplete="off" role="presentation">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row-field-1" *ngIf= "data.eType === 'employee'">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">Quarantine Start Date</mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <input matInput i18n-placeholder autocomplete="off" role="presentation" [matDatepicker]="quarantineSDate" formControlName="quarantineSDate">
                                    <mat-datepicker-toggle matSuffix [for]="quarantineSDate"></mat-datepicker-toggle>
                                    <mat-datepicker #quarantineSDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row-field-1">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">Quarantine End Date</mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <input matInput autocomplete="off" [min]="enrollEmployeeForm.controls['quarantineSDate'].value" role="presentation" [matDatepicker]="quarantineEDate" formControlName="quarantineEDate">
                                    <mat-datepicker-toggle matSuffix [for]="quarantineEDate"></mat-datepicker-toggle>
                                    <mat-datepicker #quarantineEDate></mat-datepicker>
                                    <mat-error *ngIf="enrollEmployeeForm.controls.quarantineEDate.invalid" class="error-txt-1">
                                        <span i18n [hidden]="enrollEmployeeForm.controls['quarantineEDate'].errors.min">
                                            should be greater than Quarantine Start Date.</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row-field-1">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">Vitals Tag</mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <input i18n-placeholder matInput placeholder="Vitals Tag" id="vitalTag" appTypeHit (typeHit)="searchTagAssociation($event, 'TT-VTBLE')"
                                        [matAutocomplete]="autoFrom" formControlName="visitorTagSerialNumber" [readonly]="vitalTagId != null">
                                    <mat-autocomplete autoActiveFirstOption #autoFrom="matAutocomplete">
                                        <mat-option *ngFor="let option of vitalTagList" [value]="option">
                                            <div class="tot-wid-styl">{{option}}</div>
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row-field-1" *ngIf= "data.eType === 'visitor'">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">Reason</mat-label>
                                <mat-form-field id="textHeight" class="example-full-width form-field" appearance="outline">
                                    <textarea i18n-placeholder input matInput placeholder="Comments" rows=3
                                        formControlName="comments"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row-field-1" *ngIf= "data.eType === 'visitor'">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">Visitor Type <span class="mandatory-field">*</span></mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <mat-select i18n-placeholder placeholder="Visitor Type" formControlName="visitorTypeId"[disableOptionCentering]="true">
                                        <mat-option *ngFor="let type of visitorType" [value]="type.code">
                                            {{type.value}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="enrollEmployeeForm.controls.visitorTypeId.invalid" class="error-txt-1">
                                        <span i18n [hidden]="!enrollEmployeeForm.controls['visitorTypeId'].errors.required">
                                            Visitor type is required.</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row-field-1" *ngIf= "data.eType === 'visitor'">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">Department</mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <mat-select i18n-placeholder placeholder="Select Department" formControlName="department" [disableOptionCentering]="true">
                                        <mat-option value="DT=FN"> Finance </mat-option>
                                        <mat-option value="DT=MR"> Marketting </mat-option>
                                        <mat-option value="DT=SL"> Sales </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>


                        <div class="row-field-1"*ngIf="data.eType === 'tempId'">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">Reporting Employee ID</mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <input i18n-placeholder matInput placeholder="Reporting Employee ID" formControlName="managerId">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex.xs="100" fxFlex.lg="30" fxFlex.md="50" fxFlex.sm="50" class="row-field">
                        </div>
                    </div>

            </div>
        </mat-tab>
        <mat-tab i18n-title label="Map" *ngIf="data.id && data.latitude && data.longitude && (data.eType === 'employee' || data.eType === 'visitor')">
            <div class="form_body_enroll_register">
                <!-- <app-google-map [empDetails]="data"></app-google-map> -->
            </div>
        </mat-tab>
    </mat-tab-group>
    </div>
    <div class="input-footer">
        <button i18n mat-button class="cancle-btn" mat-dialog-close>Cancel</button>
        <button *ngIf="!data.id && data.eType === 'employee'" i18n mat-button class="active-btn margin-r-1" appDebounceClick
            [disabled]="enrollEmployeeForm.invalid" (debounceClick)="saveRegisterEmployee()"
            cdkFocusInitial [debounceTime]="300">Enroll</button>
        <button *ngIf="!data.id && data.eType === 'visitor'" i18n mat-button class="active-btn margin-r-1" appDebounceClick
            [disabled]="enrollEmployeeForm.invalid" (debounceClick)="saveRegisterVisitor()"
            cdkFocusInitial [debounceTime]="300">Enroll</button>
        <button *ngIf="!data.id && data.eType === 'tempId'" i18n mat-button class="active-btn margin-r-1" appDebounceClick
            [disabled]="enrollEmployeeForm.invalid || emptyList" (debounceClick)="saveRegisterTempId()"
            cdkFocusInitial [debounceTime]="300">Update</button>
        <button *ngIf="data.id && data.eType === 'employee'" i18n mat-button class="active-btn margin-r-1" appDebounceClick
            [disabled]="enrollEmployeeForm.invalid || !enrollEmployeeForm.dirty" (debounceClick)="updateRegisterEmployee(data.id)"
            cdkFocusInitial [debounceTime]="300">Update</button>
        <button *ngIf="data.id && data.eType === 'visitor'" i18n mat-button class="active-btn margin-r-1" appDebounceClick
            [disabled]="enrollEmployeeForm.invalid || !enrollEmployeeForm.dirty" (debounceClick)="updateRegisterVisitor(data.id)"
            cdkFocusInitial [debounceTime]="300">Update</button>
        <button *ngIf="data.id && data.eType === 'tempId'" i18n mat-button class="active-btn margin-r-1" appDebounceClick
            [disabled]="enrollEmployeeForm.invalid || !enrollEmployeeForm.dirty" (debounceClick)="updateRegisterTempId(data)"
            cdkFocusInitial [debounceTime]="300">Update</button>
    </div>
</div>
