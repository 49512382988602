/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TimeUnit } from '../models/time-unit.enum';

@Pipe({
  name: 'timeFormatter'
})
export class TimeFormatterPipe implements PipeTransform {

 transform(time: number, timeUnit: TimeUnit): any {
  if (!time) {
     return time;
    }
switch (timeUnit) {
      case TimeUnit.HOUR:
       return moment.utc(time * 3600 * 1000).format('HH');
         case TimeUnit.MINUTE:
          return moment.utc(time * 60 * 1000).format('mm');
   }
}
}
