/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
@Injectable()
export class ReportService {
  public baseUrl = 'api/python-wrapper/reports/';
  public tokenEnrollFlow = JSON.parse(localStorage.getItem(btoa('tokenEnrollFlow')));

  constructor(private apiService: ApiService) { }

  getAllDashboardOP(reportName, id) {
    let url = this.baseUrl + reportName + '/0/0/' + localStorage.getItem(btoa('facilityId'));
    return this.apiService.get(url);
  }

  getReaderTemperatureData(data) {
    return [{
      id: '1',
      readerID: 'READ0001',
      readerName: 'Reader1',
      readerLocation: 'ward A',
      readerTemperature: '33.7',
      readerStatus: 'Active'
    }];
  }
  getassetutilsation(id, freq) {
    const url = 'api/python-wrapper/reports/deviceutilisation/0/0/' + localStorage.getItem(btoa('facilityId')) + '/tid=' + id + '&freq=' + freq + '&aid=1';
    return this.apiService.get(url);
  }

  getVitalsChart(selectedDate, toDate, patientUhid, param) {
    return this.apiService.get(environment.base_value.vitals_chart  + localStorage.getItem(btoa('facilityId')) + '/fdt=' + selectedDate + '&tdt=' + toDate + '&uid=' + patientUhid+param);
  }

  getReportData(selectedDate,id){
    let idValue = id;
    return this.apiService.get(environment.base_value[id]  + localStorage.getItem(btoa('facilityId')) + '/fdt=' + selectedDate);
  }
  // LOG
  getAuditLogReport(fromDate, toDate, operation, pageStart, pageSize) {
    return this.apiService.get(environment.base_value.get_audit_log_report + '?frmDate=' + fromDate + ':00' + '&toDate=' + toDate + ':00' 
    + '&operation=' + operation + '&pageStart=' + pageStart + '&pageSize=' + pageSize);
  }
  getErrorLogReport(fromDate, toDate, operation, pageStart, pageSize) {
    return this.apiService.get(environment.base_value.get_error_log_report + '?frmDate=' + fromDate + ':00' + '&toDate=' + toDate + ':00' 
    + '&operation=' + operation + '&pageStart=' + pageStart + '&pageSize=' + pageSize);
  }

  //Schedule
  getAllReportSchedule(){
    return this.apiService.get(environment.base_value.get_all_report_schedule);
  }
  saveReportSchedule(data) {
    return this.apiService.post(environment.base_value.save_report_schedule, data);
  }
  updateReportSchedule(id,data){
    return this.apiService.put(environment.base_value.update_report_schedule + '/' + id,data)
  }

  // rountine
  getTaskByCategory(routineType, activityCategory ?: any) {
    if(activityCategory){
      return this.apiService.get(environment.base_value.get_all_activities + '?routineType=' + routineType + '&activityCategoryIds=' + activityCategory);
    } 
    return this.apiService.get(environment.base_value.get_all_activities + '?routineType=' + routineType);
  }
}
