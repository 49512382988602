<div  class="input-component-holder">
  <div class="input-container-pvcy">
    <iframe #iframe id="print-section" width = 100% height= 100% src="https://trackerwave.com/privacy.html" class="frm-styl">
    </iframe>
  </div>
  <div class="input-footer">
    <button mat-button class="cancle-btn pad-styl" mat-dialog-close>Close</button>
  </div>
</div>

