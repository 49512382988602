<div class="toolbar-notification-container">
  <button mat-icon-button [ngClass]="[cssPrefix+'-btn']" [class.open]="isOpen"
    (click)="count && count >= 0 ? goMoreAlert() : ''" class='toolbar-notification-btn'>
    <mat-icon style="width: 27px;font-size: 18px;line-height: 18px;vertical-align: top;">notifications_none</mat-icon>
    <span class="badge" *ngIf="count && count !== 0">{{count}}</span>
  </button>
  <div class="dropdown mat-elevation-z4" [class.open]="isOpen">
  </div>
</div>

<ng-template #thenBlock>
  <perfect-scrollbar class="content" >
    <div  *ngFor="let notification of notify_data; last as isLast">
      <div class="notification" fxLayout="row" fxLayoutAlign="start center" mat-ripple>
        <span class="icon">
        </span>
        <div class="title" fxLayout="column">
          <div class="name">{{ notification.ruleName}}</div>
          <div class="time"><i>{{ notification.createdOn | date }}</i></div>
        </div>
        <span fxFlex></span>

        <img class="svg-style" src="/assets/Alert/common_icons/close.svg" alt="" (click)="delete(notification)">
      </div>
      <div class="divider" *ngIf="!isLast"></div>
    </div>
  </perfect-scrollbar>
</ng-template>
