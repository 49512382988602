/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Component, OnInit, ElementRef, ViewChild, Inject, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from '../../../services/dashboard.service';
import { CommonService } from './../../../../shared/services/common.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';


@Component({
  selector: 'app-entry-alert',
  templateUrl: './alert-entry.component.html',
  styleUrls: ['./alert-entry.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlertEntryComponent implements OnInit {

  // displayedColumns: string[] = ['Type', 'Description', 'Time', 'Details', 'Location'];
  displayedColumns: string[] = ['Type', 'message', 'Time'];

  displayedData = [
    { 'colName': 'Type', 'title': 'Type', 'dataName': 'pfAlertConfigName' },
    { 'colName': 'message', 'title': 'Message', 'dataName': 'message' },
    { 'colName': 'Time', 'title': 'Time', 'dataName': 'alertDatetime' },
    // { 'colName': 'Details', 'title': 'Details', 'dataName': 'ruleName' },
    // { 'colName': 'Location', 'title': 'Location', 'dataName': 'locationName' }
  ];
  dataSource: MatTableDataSource<any>;
  pipe: DatePipe;

  // MatPaginator Output
  pageEvent: PageEvent;
  length: any;
  pageIndex: any;

  public pageStart = 0;
  public pageSize = 10;
  public from_date;
  public to_date;
  public maxHeight: any;
  public picupPatientId = {};
  public selectedName: any;
  public rowData: any = [];
  public activate_btn: any = [];
  public filtervalue: string;
  public ruleType: any[] = [];
  public alertDetails: any;
  public alertDetails1: any;
  public alertData: any;
  public filterKey = null;
  public alertType = 'All';
  public alertFilterForm: UntypedFormGroup;
  public height: number;

  isDisabledContent: boolean;
  today = new Date();

  filterForm = new UntypedFormGroup({
    ruleType: new UntypedFormControl(),
    fromDate: new UntypedFormControl(),
    toDate: new UntypedFormControl(),
  });

  // Disables 'TO DATE' until it get some value in 'FROM DATE'
  @Output()
  dateChange: EventEmitter<MatDatepickerInputEvent<any>>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private dashboardService: DashboardService,
    public datepipe: DatePipe, public commonService: CommonService,
    public thisDialogRef: MatDialogRef<AlertEntryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: UntypedFormBuilder) {
    this.alertData = data;
  }

  get ruleTypeId() {
    return this.filterForm.get('ruleType').value;
  }
  //  ruleTypeId = this.filterForm.controls['ruleTypeId'].value;
  get fromDate() { return this.filterForm.get('fromDate').value; }
  get toDate() { return this.filterForm.get('toDate').value; }

  ngOnInit() {
    // this.getAllPatients();
    this.getAllAlert();
    this.getRuleType();
    this.alertFilterForm = this.fb.group({
      alertType: [this.alertType ? this.alertType : null]
    });

  }

  onWindowResized(size) {
    this.height = size;
  }


  // getAllPatients(): void {
  //   this.dashboardService.getAllAlert(this.pageStart, this.pageSize).subscribe(res => {
  //     // console.log(res.results);
  //     this.dataSource = new MatTableDataSource<any>(res.results);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;
  //     if(this.filtervalue){
  //       this.applyFilter(this.filtervalue);
  //     }
  //   });
  // }

  showToDate(event) {
    this.isDisabledContent = true;
  }

  filterData(event) {
    this.filterByTypeandDate();
  }


  // Server Side Pagination

  public getAllAlert(event?: PageEvent) {
    this.pageStart = (event != null) ? event.pageIndex : this.pageStart;
    this.pageSize = (event != null) ? event.pageSize : 10;
    this.dataSource = new MatTableDataSource<any>(this.alertData);
    this.length = this.alertData.length;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    // this.dashboardService.getAllAlert(this.pageStart, this.pageSize).subscribe(res => {

    //   if (this.filterKey === null) {
    //     this.alertDetails = res.results;
    //   } else {
    //     this.alertDetails = this.alertDetails1;
    //   }
    //   // this.dataSource = new MatTableDataSource<any>(this.alertDetails);
    //   // this.dataSource = new MatTableDataSource<any>(res.results);
    //   this.length = res.totalRecords;
    //   this.dataSource.sort = this.sort;
    //   this.dataSource.paginator = this.paginator;
    // });
    return event;
  }
  public filterByTypeandDate() {
    this.from_date = this.datepipe.transform(this.fromDate, 'yyyy-MM-dd');
    // const from_date = this.fromDate;
    this.to_date = this.datepipe.transform(this.toDate, 'yyyy-MM-dd');
    // const to_date = this.toDate;
    // const rule_id = this.ruleTypeId;

    // this.dashboardService.filterByTypeandDate(this.from_date, this.to_date, this.ruleTypeId).subscribe(res => {
      // this.dataSource = new MatTableDataSource<any>(res.results);
      // this.length = res.totalRecords;
      // this.dataSource.sort = this.sort;
    // });
  }


  valueChanged() {
    if (new Date(this.filterForm.value.fromDate) < new Date(this.filterForm.value.toDate)) {
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }


  typeFilter(value) {
    this.filterKey = value;
    if (this.filterKey === "All") {
      this.alertDetails1 = this.alertData;
      
    }else{

      // this.dashboardService.getAllAlert(this.pageStart, this.pageSize, value).subscribe((res) => {
      //   if (res.results != null) {
      //     this.alertDetails1 = res.results;
      //     this.dataSource.data = this.sampleData;
      //     this.dataSource.sort = this.sort;
      //     const length = this.length;
      //     this.length = length;
      //     this.dataSource.paginator = this.paginator;
      //   }
      // });
    
      this.alertDetails1 = this.alertData.filter(resFilter => resFilter.ruleTypeId === value);
    }
    this.dataSource.data = this.alertDetails1 ;
    this.dataSource.sort = this.sort;
    const length = this.length;
    this.length = length;
    this.dataSource.paginator = this.paginator;
    // if (value === 'RU-FA') {
    //   this.dashboardService.getAllAlert(this.pageStart, this.pageSize).subscribe((res) => {
    //     if (res.results != null) {
    //       this.alertDetails1 = res.results.filter(filter => filter.pfRuleName === 'Fall');
    //       this.dataSource.data = this.alertDetails1;
    //       this.dataSource.sort = this.sort;
    //       const length = this.length;
    //       this.length = length;
    //       this.dataSource.paginator = this.paginator;
    //     }
    //   });
    // } else if (value === 'RU-MO') {
    //   this.dashboardService.getAllAlert(this.pageStart, this.pageSize).subscribe((res) => {
    //     if (res.results != null) {
    //       this.alertDetails1 = res.results.filter(filter => filter.pfRuleName === 'Movement');
    //       this.dataSource.data = this.alertDetails1;
    //       this.dataSource.sort = this.sort;
    //       const length = this.length;
    //       this.length = length;
    //       this.dataSource.paginator = this.paginator;
    //     }
    //   });
    // } else if (value === 'RU-GO') {
    //   this.dashboardService.getAllAlert(this.pageStart, this.pageSize).subscribe((res) => {
    //     if (res.results != null) {
    //       this.alertDetails1 = res.results.filter(filter => filter.pfRuleName === 'Geofence');
    //       this.dataSource.data = this.alertDetails1;
    //       this.dataSource.sort = this.sort;
    //       const length = this.length;
    //       this.length = length;
    //       this.dataSource.paginator = this.paginator;
    //     }
    //   });
    // } else if (value === 'RU-EV') {
    //   this.dashboardService.getAllAlert(this.pageStart, this.pageSize).subscribe((res) => {
    //     if (res.results != null) {
    //       this.alertDetails1 = res.results.filter(filter => filter.pfRuleName === 'Event');
    //       this.dataSource.data = this.alertDetails1;
    //       this.dataSource.sort = this.sort;
    //       const length = this.length;
    //       this.length = length;
    //       this.dataSource.paginator = this.paginator;
    //     }
    //   });
    // } else if (value === 'RU-SP') {
    //   this.dashboardService.getAllAlert(this.pageStart, this.pageSize).subscribe((res) => {
    //     if (res.results != null) {
    //       this.alertDetails1 = res.results.filter(filter => filter.pfRuleName === 'Spent');
    //       this.dataSource.data = this.alertDetails1;
    //       this.dataSource.sort = this.sort;
    //       const length = this.length;
    //       this.length = length;
    //       this.dataSource.paginator = this.paginator;
    //     }
    //   });
    // } else if (value === 'RU-IN') {
    //   this.dashboardService.getAllAlert(this.pageStart, this.pageSize).subscribe((res) => {
    //     if (res.results != null) {
    //       this.alertDetails1 = res.results.filter(filter => filter.pfRuleName === 'Infant');
    //       this.dataSource.data = this.alertDetails1;
    //       this.dataSource.sort = this.sort;
    //       const length = this.length;
    //       this.length = length;
    //       this.dataSource.paginator = this.paginator;
    //     }
    //   });
    // } else if (value === 'RU-NC') {
    //   this.dashboardService.getAllAlert(this.pageStart, this.pageSize).subscribe((res) => {
    //     if (res.results != null) {
    //       this.alertDetails1 = res.results.filter(filter => filter.pfRuleName === 'Nurse Call');
    //       this.dataSource.data = this.alertDetails1;
    //       this.dataSource.sort = this.sort;
    //       const length = this.length;
    //       this.length = length;
    //       this.dataSource.paginator = this.paginator;
    //     }
    //   });
    // } else {
    //   this.alertDetails1 = [];
    // }
  }

  getRuleType() {
    this.commonService.getAppTerms('RuleType').subscribe(res => {
      this.ruleType = res.results;
    });
  }

  popupClose() {
    this.thisDialogRef.close();
  }
}
