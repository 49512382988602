/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
 ******************************************************************************/
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';

import { CommonService, ConfigurationService, DashboardService } from '../../shared';
/*

Description : Configuration Modules implemented the resolver functionality.
Date        : May 11, 2021
Author      : TrackerWave
Developer   : Venkatesh Raju

*/



@Injectable()
export class ConfigResolver implements Resolve<Observable<any>> {
  constructor(private configurationService: ConfigurationService) {}

  resolve(): Observable<any> {
    return this.configurationService.getConfig('true');
  }
}
@Injectable()
export class ReaderResolver implements Resolve<Observable<any>> {
  constructor(private configurationService: ConfigurationService) {}

  resolve(): Observable<any> {
    return this.configurationService.getAllReaders();
  }
}




@Injectable()
export class RuleResolver implements Resolve<Observable<any>> {
  constructor(private configurationService: ConfigurationService) {}

  resolve(): Observable<any> {
    return this.configurationService.getAllPfRules();
  }
}

@Injectable()
export class AlertResolver implements Resolve<Observable<any>> {
  constructor(private configurationService: ConfigurationService) {}

  resolve(): Observable<any> {
    return this.configurationService.getAllAlerts();
  }
}

@Injectable()
export class AppTermResolver implements Resolve<Observable<any>> {
  constructor(private commonService : CommonService) {}

  resolve(): Observable<any> {
    return this.commonService.getAppTermsVerion2('LookupGroup');
  }
}

@Injectable()
export class layoutsResolver implements Resolve<Observable<any>> {
  constructor(public dashboardService: DashboardService) {}

  resolve(): Observable<any> {
    return this.dashboardService.getDashboardDetailsListLayout('true',localStorage.getItem(btoa('userId')));
  }
}

@Injectable()
export class formManagementResolver implements Resolve<Observable<any>> {
  constructor(private configurationService : ConfigurationService) {}

  resolve(): Observable<any> {
    return this.configurationService.getFormTemplatesV2();
  }
}

@Injectable()
export class dataItemResolver implements Resolve<Observable<any>> {
  constructor(private configurationService : ConfigurationService) {}

  resolve(): Observable<any> {
    return this.configurationService.getDataitems();
  }
}

@Injectable()
export class widgetsResolver implements Resolve<Observable<any>> {
  constructor(public dashboardService: DashboardService) {}

  resolve(): Observable<any> {
    return this.dashboardService.getWidgetList(localStorage.getItem('userlevel'));
  }
}

@Injectable()
export class widgetNewResolver implements Resolve<Observable<any>> {
  constructor(public dashboardService: DashboardService) {}

  resolve(): Observable<any> {
    return  this.dashboardService.getWidgetList(null);
  }
}

@Injectable()
export class ActiviteResolver implements Resolve<Observable<any>> {
  constructor(private configurationService: ConfigurationService) {}

  resolve(): Observable<any> {
    return this.configurationService.getAllActivities();
  }
}

@Injectable()
export class RoutineResolver implements Resolve<Observable<any>> {
  constructor(private configurationService: ConfigurationService) {}

  resolve(): Observable<any> {
    return this.configurationService.getAllRoutine();
  }
}

@Injectable()
export class PackageResolver implements Resolve<Observable<any>> {
  constructor(private commonService: CommonService) {}

  resolve(): Observable<any> {
    return this.commonService.getPackageHealthPlan();
  }
}

@Injectable()
export class HealthTestResolver implements Resolve<Observable<any>> {
  constructor(private configurationService: ConfigurationService) {}

  resolve(): Observable<any> {
    return this.configurationService.getAllHeathTest();
  }
}




