/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HospitalService, CommonService } from '../../../../shared';
import { ErrorStateMatcherService } from '../../../../shared/services/error-state-matcher.service';
import { CreateCustomer } from '../../hospital.model';
import { ToastrService } from 'ngx-toastr';
import { GoogleMapComponent } from '../../../../shared/modules/entry-component/google-map/google-map.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss']
})
export class CreateCustomerComponent implements OnInit {
  
  public matcher = new ErrorStateMatcherService();
  public logoImage: any = null;
  attachedFiles: any;
  public base64Data_global: string;
  sourceGeoCoordinate: any ={};
  parkingGeoCoordinate: any ={};
  public bgImage: any = null;
  public isDisabled = false;
  public selectedCustomer: any = '1';
  customerType: any[] = [];
  customerList: any[] = [];
  regionList: any[] = [];
  text = 'Customer';
  code = '1';
  cust_id: number = null;
  
    
    @ViewChild('name', { static: true }) nameInput: MatInput;

  public parentName: string;
  public customerForm: UntypedFormGroup;
  public contactForm: UntypedFormGroup;
  public email: string;
  public phoneNumber: string;
  public customerData = [];
  public options = [
      { 'name': 'some name 1', ID: 'D1'},
      { 'name': 'some name 2', ID: 'D2'}
  ];
  entityAddresses: any = [];
  TypeList: any=[];
  selectedFiles?: FileList;
  uploadedFile = [];
  searchText = null;
  loc: any = null;
  geoLoc: any = {};
  parkingGeoLoc: any = {};
  defaultLoc = null;
  parkingFacilitylist =[];
  accessbilitylist=[];
  parkingFacilityCodes: any[] = [];
  accessbilityCodes: any[] = [];
  constructor(public form: UntypedFormBuilder, public toastr: ToastrService, public snackbar: MatSnackBar,
    public thisDialogRef: MatDialogRef<CreateCustomerComponent>, @Inject(MAT_DIALOG_DATA) public data: any,protected sanitizer: DomSanitizer,
    private hospitalServices: HospitalService, private commonService: CommonService,public dialog: MatDialog) {
      this.getCustomerDetails();
      this.getAllCustomers();
  }

  ngOnInit() {
    this.nameInput.focus();
    this.buildForm();
    this.commonService.getAppTerms('SolutionType').subscribe(res=>this.TypeList = res.results);
    this.commonService.getAppTerms('ParkingType').subscribe(res=>this.parkingFacilitylist = res.results);
    this.commonService.getAppTerms('Accessbility').subscribe(res=>this.accessbilitylist = res.results);
  }
  getCustomerDetails() {
    this.commonService.getAppTermsVerion2('CustomerType').subscribe(res => {
      const customerTypeId = this.data.customerTypeId;
      this.customerType = res.results;
      // console.log(this.customerType);
      if (res.results[0].code == this.data.customerTypeId) {
        this.parentName = 'Region';
        this.selectedCustomer = 2;
      } else if (res.results[1].code == this.data.customerTypeId) {
        this.parentName = 'Facility';
        this.selectedCustomer = 3;
      } else if (res.results[1].code == customerTypeId) {
        this.parentName = res[2].name;
      } else {
          this.parentName = 'Customer';
      }
    });

    this.hospitalServices.getCustomerList().subscribe(res => {
      this.customerList = res.results;
    });
  }
  getAllCustomers() {
    this.hospitalServices.getAllCustomers().subscribe(res => {
      const isAdmin = localStorage.getItem('userlevel');
      if (isAdmin == '1') {
        this.customerData = res.results;
        this.entityAddresses = res.results[0].entityAddresses;
              } else {
        const custDetail = res.results.filter(res => res.id == localStorage.getItem('customerId'));
        const userLevel = localStorage.getItem('userlevel');
        if (parseInt(userLevel) > 2) {
          custDetail[0].children = custDetail[0].children.filter(res => res.id == localStorage.getItem('regionId'));
          custDetail[0].children[0].children = custDetail[0].children[0].children.filter(res => res.id == localStorage.getItem(btoa('facilityId')));
          this.customerData = res.results;
        } else {
          this.customerData = res.results;
        }
      }
    });
  }
  
  public buildForm() {
    this.customerForm = this.form.group({
      customerTypeId: [null],
      name: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(64)]],
      sourceId: [this.data.sourceAddress ? this.data.sourceAddress : null],
      parkingLocation: [this.data.sourceAddress ? this.data.sourceAddress : null],
      customerId: [null],
      regionId: [null],
      parentId: [null],
      billAddress: [null],
      licencePack: [null],
      gsTin: [null],
      noOfEmployees: [null],
      noOfDevices: [null],
      noOfBeds: [null],
      isMulti: ['true'],
      logoImage: [null],
      bgImage: [null],
      regAddress: [null],
      isActive: ['true'],
      line1 : [null],
      line2 : [null],
      line3 : [null],
      city : [null],
      zip : [null],
      country : [null],
      id: [null],
      solutionTypeId:[null,[Validators.required]],
      comments:[null],
      contacts: this.form.array([this.getContact()]),

    });

  }
  getCustType(data) {
    // console.log(data);
    this.code = data.code;
    this.cust_id = data.code;
    if (this.code == '3') {
      this.customerForm.controls['customerId'].setValidators(Validators.required)
      this.customerForm.controls['regionId'].setValidators(Validators.required)
      this.customerList = this.customerData.filter(res => res.children.length > 0);
      this.regionList = [];
    } else if(this.code == '2') {
      this.customerForm.controls['customerId'].setValidators(Validators.required)
      this.customerForm.controls['regionId'].clearValidators()
      this.customerList = this.customerData;
    } else {
      this.customerForm.controls['customerId'].clearValidators();
      this.customerForm.controls['regionId'].clearValidators();
      this.customerForm.controls['customerId'].setValue(null);
      this.customerForm.controls['regionId'].setValue(null); 
    }
  }


  getRegionList(cust_id): void {
    this.hospitalServices.getRegionList(cust_id).subscribe(res => {
      this.regionList = res.results;
    });
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  uploadBgImage($event): void {
    this.readData($event.target);

  }

  readData(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myTag: FileReader = new FileReader();

    myTag.onloadend = (e) => {
      this.bgImage = myTag.result;
    };
    myTag.readAsDataURL(file);
  }

  uploadImage($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myTag: FileReader = new FileReader();

    myTag.onloadend = (e) => {
      this.logoImage = myTag.result;
    };
    myTag.readAsDataURL(file);
  }
 
  getLocation(value,event) {
    if (this.searchText === value && value !== null && this.geoLoc.hasOwnProperty('latlng')) {
      this.loc = {
        "type": "latlong", "threshold": 150, "ambulanceLatlong": 13.04634,
        "searchText": null, "searchLatLng": this.geoLoc
      };
    } else {
      this.searchText = value;
      this.loc = {
        "type": "latlong", "threshold": 150, "ambulanceLatlong":{lat:13.04634, lng: 80.24674},             
        "searchText": value, "searchLatLng": null
      }
    }
    const dialogRef = this.dialog.open(GoogleMapComponent,
      { data: this.loc, panelClass: ['medium-popup'], disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if(event == 'Hospital') {
          this.sourceGeoCoordinate = {
            "identifyingType": event,
            "latitude": result.latlng.lat,
            "longitude": result.latlng.lng,
            "address": result.formattedAddress,
            isActive: true
          };
          this.geoLoc = {'latlng': {'lat': result.latlng.lat, 'lng': result.latlng.lng, 
          "givenAddress": this.customerForm.get("sourceId").value,
          "address": result.formattedAddress}}
          this.customerForm.get('sourceId').setValue(result.formattedAddress);
        } else if(event == 'Parking'){
          this.parkingGeoCoordinate = {
            "identifyingType": event,
            "latitude": result.latlng.lat,
            "longitude": result.latlng.lng,
            "address": result.formattedAddress,
            isActive: true
          };
          this.parkingGeoLoc = {'latlng': {'lat': result.latlng.lat, 'lng': result.latlng.lng, 
          "givenAddress": this.customerForm.get("parkingLocation").value,
          "address": result.formattedAddress}}
          this.customerForm.get('parkingLocation').setValue(result.formattedAddress);
        }
      } else {
        if(event == 'Hospital') {
          this.customerForm.get("sourceId").setValue(null);
          if(this.customerForm.get("sourceId").value !== null &&
          this.customerForm.get("sourceId").value !== '' &&
          this.geoLoc.hasOwnProperty('latlng')) {
            this.sourceGeoCoordinate = {
              "lat": this.geoLoc.latlng.lat,
              "lng": this.geoLoc.latlng.lng,
              "address": this.customerForm.get("sourceId").value,
            };
          } else {
            this.parkingGeoCoordinate = {};
          }
        } else if(event == 'Parking') {
          this.customerForm.get("parkingLocation").setValue(null);
          if(this.customerForm.get("parkingLocation").value !== null &&
          this.customerForm.get("parkingLocation").value !== '' &&
          this.parkingGeoLoc.hasOwnProperty('latlng')) {
            this.parkingGeoCoordinate = {
              "lat": this.geoLoc.latlng.lat,
              "lng": this.geoLoc.latlng.lng,
              "address": this.customerForm.get("parkingLocation").value,
            };
          } else {
            this.parkingGeoCoordinate = {};
          }
        }
      }
    });
  }

  private getContact() {
    return this.form.group({
      contactName: [null],
      email: [this.email ? this.email : null, [Validators.email]],
      phoneNumber: [this.phoneNumber ? this.phoneNumber : null, [Validators.pattern('[6789][0-9]{9}')]],
      comment: [null]
    });
  }

  public addContact() {
    const control = <UntypedFormArray>this.customerForm.controls['contacts'];
    control.push(this.getContact());
  }

  public removeContact(i: number) {
    const control = <UntypedFormArray>this.customerForm.controls['contacts'];
    control.removeAt(control.length - 1);
  }

  public saveCustomer() {
    this.isDisabled = true;
    this.customerForm.controls['parentId'].setValue(null);
    if (this.code === '2') {
      this.customerForm.controls['parentId'].setValue(this.customerForm.controls['customerId'].value);
    } else if (this.code === '3') {
      this.customerForm.controls['parentId'].setValue(this.customerForm.controls['regionId'].value);
    }
    const createCustomer = new CreateCustomer(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
    createCustomer.customerTypeId = this.cust_id == null ? this.selectedCustomer : this.cust_id;
    createCustomer.name = this.customerForm.controls['name'].value;
    createCustomer.parentId = this.data.id ? this.data.id : this.customerForm.controls['parentId'].value;
    createCustomer.billAddress = this.customerForm.controls['billAddress'].value;
    createCustomer.regAddress = this.customerForm.controls['regAddress'].value;
    createCustomer.licencePack = this.customerForm.controls['licencePack'].value;
    createCustomer.gsTin = this.customerForm.controls['gsTin'].value;
    createCustomer.noOfEmployees = this.customerForm.controls['noOfEmployees'].value;
    createCustomer.noOfDevices = this.customerForm.controls['noOfDevices'].value;
    createCustomer.noOfBeds = this.customerForm.controls['noOfBeds'].value;
    createCustomer.isMulti = this.customerForm.controls['isMulti'].value;
    createCustomer.isActive = this.customerForm.controls['isActive'].value;
    createCustomer.contacts = this.customerForm.value.contacts;
    createCustomer.attachImages = this.uploadedFile;
    createCustomer.logoImage = this.logoImage;
    createCustomer.comments = this.customerForm.controls['comments'].value;
    createCustomer.solutionTypeId = this.customerForm.controls['solutionTypeId'].value;
      const data = [{
        'id' : null,
        "addressSubTypeId": "AST-PMTA",
        "addressTypeId": "AD-REGAD",
        'line1': this.customerForm.controls['line1'].value,
        'line2': this.customerForm.controls['line2'].value,
        'line3': this.customerForm.controls['line3'].value,
        'city': this.customerForm.controls['city'].value,
        'zip': this.customerForm.controls['zip'].value,
        'country':this.customerForm.controls['country'].value, 
      }];
      this.entityAddresses = data,

      createCustomer.entityAddresses =  this.entityAddresses
      createCustomer.entityProfiles = [];
      if(Object.keys(this.sourceGeoCoordinate).length) {
        createCustomer.entityProfiles.push(this.sourceGeoCoordinate)
      }
      if(Object.keys(this.parkingGeoCoordinate).length) {
        createCustomer.entityProfiles.push(this.parkingGeoCoordinate)
      }
      for (const accessCode of this.accessbilityCodes) {
        createCustomer.entityProfiles.push(accessCode);
      }
      for (const parkingCode of this.parkingFacilityCodes) {
        createCustomer.entityProfiles.push(parkingCode);
      }      
    this.hospitalServices.saveCustomer(createCustomer).subscribe(res => {
      if (res.statusCode != 1) {
        this.isDisabled = false;
      }
      
      this.toastr.success('Success', `${res.message}`, { closeButton: true });
      this.thisDialogRef.close('confirm');
    },
      error => {
        // console.log('error----------->', error);
        this.toastr.error('Error', `${error.error.message}`);
      });
  }
  safeUrl(value) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  detectFiles(evt) {
    const files = evt.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (readerEvt) => {
        const binaryString = readerEvt.target.result as string;
        const base64Data = btoa(binaryString);
        const url = this.safeUrl('data:' + file.type + ';base64,' + base64Data);
        const upload = {
          fileType: file.type,
          file: url,
        };
        this.attachedFiles = upload
        this.uploadedInfo();
      };
      reader.readAsBinaryString(file);
    }
  }

  uploadedInfo() {
    this.uploadedFile.push({
      attachmentId: null,
      fileUrl: this.attachedFiles.file.changingThisBreaksApplicationSecurity,
      fileType: this.attachedFiles.fileType
    })
  }

  cancelFile(index) {
    this.uploadedFile.splice(index, 1);
  }

  onSelectionChange(event: MatSelectChange) {
    if (event && event.value) {
      const selectedValues = this.parkingFacilitylist.filter(option => event.value.includes(option.code));
      this.parkingFacilityCodes = [];
      selectedValues.forEach(selectedValue => {
        const pfdata = {
          identifyingType: selectedValue.code,
          identifyingValue: selectedValue.value,
          isActive: true
        };
        const existingIndex = this.parkingFacilityCodes.findIndex(item => item.identifyingType === pfdata.identifyingType);
        if (existingIndex === -1) {
          this.parkingFacilityCodes.push(pfdata);
        } else {
          this.parkingFacilityCodes[existingIndex] = pfdata;
        }
      });
    } else {
      console.error('Invalid event object:', event);
    }
  }

  onAccessibilityChange(event: MatSelectChange) {
    if (event && event.value) {
      const selectedValues = this.accessbilitylist.filter(option => event.value.includes(option.code));
      this.accessbilityCodes = [];
      selectedValues.forEach(selectedValue => {
        const accessibilityData = {
          identifyingType: selectedValue.code,
          identifyingValue: selectedValue.value,
          isActive: true
        };
        const existingIndex = this.accessbilityCodes.findIndex(item => item.identifyingType === accessibilityData.identifyingType);
        if (existingIndex === -1) {
          this.accessbilityCodes.push(accessibilityData);
        } else {
          this.accessbilityCodes[existingIndex] = accessibilityData;

        }
      });
    } else {
      console.error('Invalid event object:', event);
    }
  }
  }
