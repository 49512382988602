<div fxFlex class="input-component-holder ovi-font-family" style="overflow: hidden;">
    <mat-toolbar class="input-header">
        <div i18n class="header-name">Enroll Patient</div>
        <div class="header-close1"><button mat-icon-button mat-dialog-close close-button class="ie-close-icon">
                <mat-icon class="icon-style">highlight_off</mat-icon>
            </button></div>
    </mat-toolbar>
    <div class="mat-custom-form-field-box" [formGroup]="registerPatientForm" style="overflow: hidden;">
        <div class="popup_form_body popup-form-container" >
            <div class="form-row popup-margin">
                <div i18n class="ovi-popup-title-text-size top-styl">PATIENT DETAILS</div>

            <mat-divider class="divider"></mat-divider>

            <div class="form-row block-3">
                <div class="row-field-1 custom-material-design">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">UHID / Mobile <span class="mandatory-field">*</span></mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input matInput i18n-placeholder #uhid name="uhid" (keyup.enter)="onUHID($event.target.value)" placeholder="UHID / Mobile"
                                formControlName="mainidentifier"
                                [errorStateMatcher]="matcher" autocomplete="off">
                            <mat-error *ngIf="registerPatientForm.controls.mainidentifier.invalid">
                                <span i18n
                                    [hidden]="!registerPatientForm.controls['mainidentifier'].errors.required">UHID /
                                    Phone number is required.</span>
                                <span i18n
                                    [hidden]="!registerPatientForm.controls['mainidentifier'].errors.pattern">Please
                                    enter valid uhid / mobile no. </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row-field">
                    <div class="example-submit-form" style="margin-left: 1px;">
                        <button i18n mat-button [disabled]="!uhid.value" class="active-btn" (click)="onUHID(uhid.value)">Search</button>
                    </div>
                </div>
                <div class="row-field">
                    <div class="example-form">
                        <mat-spinner *ngIf=loading style="margin-top: 22px; margin-left: -75px;" diameter="40" strokeWidth="5"
                            class="spnr-styl">loading</mat-spinner>
                    </div>
                </div>
                <div class="row-field-btn">
                    <div style="margin-top: 16px;" class="mbl-view-1">
                        <button i18n-matTooltip mat-mini-fab class="margin-r-1" (click)="enrollRegister()" matTooltip="Enroll New
                        Patient" style="font-size: 25px; font-weight: 600;">+</button>
                    </div>
                </div>
            </div>

            <div class="enroll-details" *ngIf="enrollDetails && tokenEnrollFlow === true">
                <div *ngIf="enrollDetails.tokenNo">
                    <span class="enroll-label" style="padding-top: 4px;">Token No : </span>
                    <span class="enroll-value-token-no">{{enrollDetails.tokenNo}}</span>
                </div>
                <div *ngIf="enrollDetails.firstName">
                    <span class="enroll-label">Name : </span>
                    <span class="enroll-value">{{enrollDetails.firstName}}</span>
                </div>
                <div *ngIf="enrollDetails.mainidentifier">
                    <span class="enroll-label">UHID : </span>
                    <span class="enroll-value">{{enrollDetails.mainidentifier}}</span>
                </div>
                <div *ngIf="enrollDetails.gender">
                    <span class="enroll-label">Gender : </span>
                    <span class="enroll-value">{{enrollDetails.gender}}</span>
                </div>
                <div *ngIf="enrollDetails.birthDate">
                    <span class="enroll-label">DOB : </span>
                    <span class="enroll-value">{{enrollDetails.birthDate| date: 'dd/MM/yyyy'}}</span>
                </div>
                <div *ngIf="enrollDetails.mobileNo">
                    <span class="enroll-label">Mobile No : </span>
                    <span class="enroll-value">{{enrollDetails.mobileNo}}</span>
                </div>
            </div>
            <div i18n class="enroll-register" *ngIf="isDataNotFound === true">Patient details not available !!</div>
            <div i18n class="enroll-register" *ngIf="isDataNotFound === true">Please register the patient by clicking “
                Enroll New Patient “ button</div>
            <div i18n *ngIf="isEnabledTable === false && isDataNotFound === false" class="enroll-register">If patient details
                are not available. Please click on “ Enroll New Patient “ button</div>



            <div *ngIf="isEnabledTable === true" fxLayout="column" class="mat-elevation-z4 manage-component"
                [ngClass.xs]="'components-container-xs'" fxLayoutGap="5px" style="overflow-x: auto;overflow-y: hidden;">
                <div class="mat-table-overflow">
                    <table mat-table #table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="select">
                            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header
                                class="manage-table-header custom-mat-column-width-check-box">
                            </th>
                            <td mat-cell *matCellDef="let data">
                                <mat-checkbox [checked]="data.checked" (change)="onChange($event, data)"></mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header
                                class="manage-table-header custom-mat-column-header-width"> Name
                            </th>
                            <td mat-cell *matCellDef="let data" class="custom-mat-column-width"
                                matTooltip="{{data.fullName}}"> {{data.fullName}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="uhid">
                            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header
                                class="manage-table-header custom-mat-column-header-width"> UHID
                            </th>
                            <td mat-cell *matCellDef="let data" class="custom-mat-column-width"
                                matTooltip="{{data.mainidentifier}}"> {{data.mainidentifier}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="gender">
                            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header
                                class="manage-table-header custom-mat-column-header-width">
                                Gender
                            </th>
                            <td mat-cell *matCellDef="let data"> {{data.gender}}</td>
                        </ng-container>

                        <ng-container matColumnDef="age">
                            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header
                                class="manage-table-header custom-mat-column-header-width"> Age
                            </th>
                            <td mat-cell *matCellDef="let data"> {{data.age}}</td>
                        </ng-container>

                        <ng-container matColumnDef="DOB">
                            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header
                                class="manage-table-header custom-mat-column-header-width"> DOB
                            </th>
                            <td mat-cell *matCellDef="let data"> {{data.birthDate | date: 'dd/MM/yyyy'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="mobile">
                            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header
                                class="manage-table-header custom-mat-column-header-width">
                                Mobile
                            </th>
                            <td mat-cell *matCellDef="let data"> {{data.mobileNo}}</td>
                        </ng-container>

                        <ng-container matColumnDef="packageName">
                            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header
                                class="manage-table-header custom-mat-column-header-width">
                                Package
                            </th>
                            <td mat-cell *matCellDef="let data" class="custom-mat-column-width"
                                [matTooltip]="data.packageName"> {{data.packageName}}</td>
                        </ng-container>

                        <ng-container matColumnDef="date">
                            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header
                                class="manage-table-header custom-mat-column-header-width">
                                Date
                            </th>
                            <td mat-cell *matCellDef="let data">
                                <span
                                    *ngIf="isEditDate == false || isEditIconEnable === false || data.selectedRowIndex != selectedRowIndex || defaultPkgDateShow === true">{{data.packageDate | date : 'dd/MM/yyyy'}}</span>
                                <span
                                    *ngIf="isEditDate == true && isEditIconEnable == true && data.selectedRowIndex === selectedRowIndex && defaultPkgDateShow === false">
                                    <!-- <span *ngIf="isEditDate == false || data.id != isSelectedEditId">{{data.packageDate | date : 'dd/MM/yyyy'}}</span>
                                <span *ngIf="isEditDate == true && data.id == isSelectedEditId"> -->
                                    <mat-form-field class="custom-date-picker">
                                        <input matInput i18n-placeholder [matDatepicker]="picker" formControlName="date"
                                            (dateChange)="checkCurrentDate($event.value)">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="edit">
                            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header
                                class="manage-table-header custom-mat-column-width-edit-icon"></th>
                            <td mat-cell *matCellDef="let data" class="custom-mat-column-width">

                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header
                                class="manage-table-header custom-mat-column-header-width">
                                Enrollment
                            </th>
                            <td mat-cell *matCellDef="let data" style="text-align:left;"> {{data.status}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"
                            class="manage-table-header-row">
                        </tr>
                        <tr mat-row *matRowDef="let data; columns: displayedColumns;"
                            [ngClass]="{'highlight' : data.id === selectedName}"></tr>
                    </table>
                </div>
            </div>


            <div *ngIf="isEnabledTable === true && selectedRow && tokenEnrollFlow === true && isEnrollOption" class="top-style">

                <!-- Start new option Enroll UI -->

                <div class="form-row block-3">
                    <div class="row-field">
                        <div class="example-form">
                            <div class="radio-button">
                                <mat-radio-group formControlName="enrollVisit" (change)="getEnrollOption($event.value)">
                                    <mat-radio-button i18n class="cst-mgn_1" [disabled]="changeEnrollAction"
                                        *ngFor="let p of enrollVisitOption; let i = index" [value]="p.value"
                                        [checked]="registerPatientForm.controls.enrollVisit.value === p.value">
                                        {{p.name}}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="row-field">
                        <div class="example-form">
                        </div>
                    </div>
                </div>

                <div *ngIf="packageName != null" class="form-row block-3">
                    <div class="row-field">
                        <div class="example-form" style="padding: 0px 0px 10px 0px;">
                            <span>Package :&nbsp;</span>
                            <span style="font-weight: 600;">{{packageName}}</span>
                        </div>
                    </div>
                </div>

                <div class="ovi-lbl-text-size">
                    <div class="row-field">
                        <div class="example-form" style="padding: 0px 0px 20px 0px;">
                            <span>Date :&nbsp;</span>
                            <span style="font-weight: 600;">{{todayDate}}</span>
                        </div>
                    </div>
                    <div class="row-field">
                        <div class="example-form">
                        </div>
                    </div>
                </div>

                <!-- End new option Enroll UI -->

                <div class="form-row">
                    <div class="row-field-option-1">
                        <div class="example-form" *ngIf="assignFloorDetails?.length > 0">
                            <mat-label class="ovi-lbl-text-size"> Assigned Floor <span class="mandatory-field">*</span></mat-label>
                            <div class="radio-button">
                                <mat-radio-group formControlName="assignFloorId" (change)="getAssignFloor($event.value)"
                                    required>
                                    <mat-radio-button i18n class="cst-mgn_1"
                                        *ngFor="let o of assignFloorDetails; let i = index" [value]="o.floorId">
                                        {{o.floorName}}</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="row-field">
                        <div class="example-form">
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="isEnabledTable === true && isClinical === true && selectedRow && tokenEnrollFlow === false">

                <div class="form-row">
                    <div i18n class="ovi-popup-title-text-size top-styl">Clinical Details </div>
                </div>
                <mat-divider class="divider"></mat-divider>
                <div>
                    <span class="space-around">
                        <!-- <mat-checkbox [checked]= "isDiabetic" (change)='onChange($event)' [(ngModel)]='isChecked_Diabetic'> Diabetic</mat-checkbox> -->
                        <mat-checkbox [checked]="isDiabetic" (change)="clinicalDetails($event, 'isDiabetic')"> Diabetic
                        </mat-checkbox>

                    </span>

                    <span class="space-around">
                        <!-- <mat-checkbox (change)='onChange($event)' [(ngModel)]='isChecked_Fasting'> Fasting</mat-checkbox> -->
                        <mat-checkbox [checked]="isFasting" (change)="clinicalDetails($event, 'isFasting')"> Fasting
                        </mat-checkbox>
                    </span>

                    <span class="space-around">
                        <!-- <mat-checkbox (change)='onChange($event)' [(ngModel)]='isChecked_Fasting'> Vulnerable -->
                        <mat-checkbox [checked]="isVulnerable" (change)="clinicalDetails($event, 'isVulnerable')">
                            Vulnerable</mat-checkbox>
                    </span>

                    <span class="space-around">
                        <!-- <mat-checkbox (change)='onChange($event)' [(ngModel)]='isChecked_Pregnant'> Pregnant</mat-checkbox> -->
                        <mat-checkbox [checked]="isPregnant" (change)="clinicalDetails($event, 'isPregnant')"> Pregnant
                        </mat-checkbox>
                    </span>
                </div>

                <div class="top-style">
                    <div class="form-row block-3">
                        <div class="row-field-5">
                            <div class="example-form">
                                <mat-label class="ovi-lbl-text-size ">VIP</mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <mat-select formControlName="vipTypeId">
                                        <mat-option value='null'>None</mat-option>
                                        <mat-option *ngFor="let data of vipList" [value]="data.code">{{data.value}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                        </div>
                        <div class="row-field-5">
                            <div class="example-form">
                                <mat-label class="ovi-lbl-text-size ">Preferred Language</mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <mat-select placeholder="Language" formControlName="preferedLanguage">
                                        <mat-option *ngFor="let data of languageList" [value]="data.code">{{data.value}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="ovi-lbl-text-size" *ngIf="checkPkgDate === selectedDate || checkPkgDate == null">
                    <div i18n class="ovi-popup-title-text-size">TAG</div>
                </div>
                <mat-divider class="divider" *ngIf="checkPkgDate === selectedDate || checkPkgDate == null">
                </mat-divider>

                <div *ngIf="checkPkgDate === selectedDate || checkPkgDate == null" class="form-row block-3">
                    <div class="row-field-5 custom-material-design">
                        <div class="example-form">
                            <div style="margin-left: -10px;">
                                <mat-radio-group class="example-radio-group" [disabled]="disableCoster"
                                    formControlName="coster">
                                    <mat-radio-button (click)="isEnableCoster('coaster')" i18n value="coaster"
                                        [checked]="registerPatientForm.controls.coster.value === 'coaster'">Coaster
                                    </mat-radio-button>&nbsp;
                                    <mat-radio-button (click)="isEnableCoster('mobile')" i18n value="mobile"
                                        [checked]="registerPatientForm.controls.coster.value === 'mobile'">Mobile
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <!-- <mat-checkbox  [checked]= "coster" formControlName="coster" class="pad-r-5"
                                (change)="isEnableCoster($event)" [disabled]="disableCoster"></mat-checkbox>
                            <mat-label class="ovi-lbl-text-size">Coaster</mat-label> -->
                            <mat-form-field *ngIf="isEnableCosterDropdown === true"
                                class="example-full-width form-field" appearance="outline">
                                <input matInput placeholder="Coaster ID" id="tagIdSearch" appTypeHit (typeHit)="searchToCoster($event)"
                                    [matAutocomplete]="autoFrom" formControlName="tagSerialNumber">
                                <mat-autocomplete autoActiveFirstOption #autoFrom="matAutocomplete">
                                    <mat-option *ngFor="let option of searchCosterlist" [value]="option.tagId">
                                        <div class="tot-wid-styl" (click)="getTagTypeId(option.tagTypeId)">{{option.tagId}}</div>
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="registerPatientForm.controls.tagSerialNumber.invalid">
                                    <span i18n
                                        [hidden]="!registerPatientForm.controls['tagSerialNumber'].errors.required">Coaster
                                        is
                                        required.</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field">
                        <div class="example-form">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="input-footer" *ngIf="isEnabledTable === true">
            <!-- <span class="left">
                <button i18n mat-button class="active-btn margin-r-1" (click)="enrollRegister()">Enroll New
                    Patient</button>
            </span> -->

                <button i18n mat-button class="cancle-btn" mat-dialog-close>Cancel</button>
                <button i18n mat-button class="active-btn margin-r-1" *ngIf="selectedRow == ''"
                    [disabled]="selectedRow == '' || registerPatientForm.invalid">OK</button>
                <!-- <button i18n mat-button class="active-btn margin-r-1" *ngIf="selectedRow == 'No'"
                    [disabled]="registerPatientForm.invalid" (click)="saveRegisterPatient()">Enroll</button>
                <button i18n mat-button class="active-btn margin-r-1" *ngIf="selectedRow == 'Yes'"
                    [disabled]="registerPatientForm.invalid" (click)="saveRegisterPatient()">OK</button> -->
                <button i18n mat-button class="active-btn margin-r-1" *ngIf="selectedRow == 'No'" appDebounceClick
                    [disabled]="registerPatientForm.invalid" (debounceClick)="saveEnroll()"
                    [debounceTime]="300">Enroll</button>
                <button i18n mat-button class="active-btn margin-r-1" *ngIf="selectedRow == 'Yes'" appDebounceClick
                    [disabled]="registerPatientForm.invalid" (debounceClick)="saveEnroll()"
                    [debounceTime]="300">OK</button>

        </div>
    </div>
</div>
