<div class="tw-header block-1 filter-container tw-header-mat-custom-form-field-box font-class" style="display: inline-block;padding-bottom: 5px;width: 100%;background-color: white;">
    <span style="display: flex;">
        <mat-label class="report-label01" style="display: flex;align-items: center;justify-content: center;">
            <!-- <div class="report-header1">
                <div class="report-header">
                    <span>
                        <img src="/assets/Menus/{{selectedMenu.iconName}}.svg" alt="report" width = 20px style="margin-right: 10px;filter: invert(1);">
                        {{selectedMenu.name}}
                    </span>
                </div>
            </div> -->
            <div style="display: flex;margin-left: 5px;align-items: center;justify-content: center;margin-right: 30px;">
              <span>
                <img src="/assets/Menus/{{selectedMenu.iconName}}.svg" alt="" width = 20px style="margin-right: 10px;filter: invert(1);">
              </span>
              <span style="font-size: 13px;">{{selectedMenu.name}}</span>
            </div>
        </mat-label>
        <div class="report-select01 form-row block-3" [formGroup]="reportForm" style="overflow: hidden;">
            <div class="report-box" style="display: flex;width: 100%;">
                <div class="report-inputs">
                  <div *ngFor="let filter of filterInputs">
                    <div *ngIf="filter.name === 'report'" style="width: 150px;margin-right: 25px;" [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-label>{{filter.label}}</mat-label>
                      <mat-select class="report-select02" style="width: 150px;padding-left: 10px !important;" [disableOptionCentering]="true" formControlName="reportId">
                        <mat-option i18n *ngFor="let report of reportList;" [value]="filter?.routine ? report.code : report.link" (click)="reportHeaderActionEvent('report', report)">{{report.name}}</mat-option>
                      </mat-select>
                    </div>
                    
                    <div class="report-date" style="margin-left: 5px;margin-right: -15px;" *ngIf="filter.name === 'multipleDate' || filter.name === 'fromDate'"
                    [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-label>{{filter.label}}</mat-label>
                      <mat-form-field class="tw-date-picker" appearance="outline" style="margin-left: 0px;margin-top: 4px; width: 115px !important;">
                        <input matInput i18n-placeholder [matDatepicker]="reportFromDate" class="tw-input-date" style="padding: 0px;"
                          placeholder="From Date" formControlName="fromDate" [max]="today" required
                          (dateChange)="reportHeaderActionEvent('fromDate', $event.target.value)">
                        <mat-datepicker-toggle matSuffix [for]="reportFromDate" style="padding-right:2px;"></mat-datepicker-toggle>
                        <mat-datepicker #reportFromDate></mat-datepicker>
                      </mat-form-field>
                    </div>

                    <div class="report-date" style="margin-left: 5px;margin-right: -15px;" *ngIf="filter.name === 'multipleDate' || filter.name === 'toDate'"
                    [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-label>{{filter.label}}</mat-label>
                      <mat-form-field class="tw-date-picker" appearance="outline" style="margin-left: 0px;margin-top: 4px; width: 115px !important;">
                        <input matInput i18n-placeholder [matDatepicker]="reportToDate" class="tw-input-date" style="padding: 0px;"
                          placeholder="To Date" formControlName="toDate" [max]="today" required
                          (dateChange)="reportHeaderActionEvent('toDate', $event.target.value)">
                        <mat-datepicker-toggle matSuffix [for]="reportToDate" style="padding-right:2px;"></mat-datepicker-toggle>
                        <mat-datepicker #reportToDate></mat-datepicker>
                      </mat-form-field>
                    </div>

                    <div class="report-date" style="margin-left: 5px;margin-right: -15px;" *ngIf="filter.name === 'reportDate'"
                    [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-label>{{filter.label}}</mat-label>
                      <mat-form-field class="tw-date-picker" appearance="outline" style="margin-left: 0px;margin-top: 4px; width: 115px !important;">
                        <input matInput i18n-placeholder [matDatepicker]="reportDate" class="tw-input-date" style="padding: 0px;"
                          placeholder="Report Date" formControlName="reportDate" [max]="today" required
                          (dateChange)="reportHeaderActionEvent('toDate', $event.target.value)">
                        <mat-datepicker-toggle matSuffix [for]="reportDate" style="padding-right:2px;"></mat-datepicker-toggle>
                        <mat-datepicker #reportDate></mat-datepicker>
                      </mat-form-field>
                    </div>

                    <div class="report-date" style="margin-left: 5px;margin-right: 20px;" *ngIf="filter.name === 'multipleDateTime' || filter.name === 'fromDateTime'"
                    [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-label>{{filter.label}}</mat-label>
                      <mat-form-field class="tw-date-picker" appearance="outline" style="margin-left: 0px; margin-top: 4px; width: 150px !important;">
                        <input matInput type="datetime-local" id="datepicker" i18n-placeholder class="tw-input-date" style="padding: 0px;"
                        placeholder="From Date" formControlName="fromDateTime" [max]="today" required
                        (change)="reportHeaderActionEvent('fromDateTime', $event.target.value)">
                      </mat-form-field>
                    </div>

                    <div class="report-date" style="margin-left: 5px;margin-right: 20px;" *ngIf="filter.name === 'multipleDateTime' || filter.name === 'toDateTime'"
                    [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-label>{{filter.label}}</mat-label>
                      <mat-form-field class="tw-date-picker" appearance="outline" style="margin-left: 0px; margin-top: 4px; width: 150px !important;">
                        <input matInput type="datetime-local" id="datepicker" i18n-placeholder class="tw-input-date" style="padding: 0px;"
                        placeholder="To Date" formControlName="toDateTime" [max]="today" required
                        (change)="reportHeaderActionEvent('toDateTime', $event.target.value)">
                      </mat-form-field>
                    </div>

                    <div style="width: 150px; margin-left: 5px;margin-right: 20px;" *ngIf="filter.name === 'poolName'"
                    [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-label>{{filter.label}}<span *ngIf="filter.required === true">*</span></mat-label>
                      <mat-form-field class="tw-date-picker" appearance="outline" style="margin-left: 0px; margin-top: 4px; width: 150px !important;">
                        <mat-select [disableOptionCentering]="true" formControlName="poolName" [required]="filter.required">
                          <mat-option i18n *ngFor="let data of poolNameList;" [value]="data.code" 
                          (click)="reportHeaderActionEvent('poolName', data.code)">{{data.value}}</mat-option>
                        </mat-select>
                        <mat-error class="error-txt-1">
                          <span i18n *ngIf="reportForm.controls.poolName.invalid">
                            PoolName is required.</span>
                      </mat-error>
                      </mat-form-field>
                    </div>

                    <div style="width: 150px; margin-left: 5px;margin-right: 20px;" *ngIf="filter.name === 'poolNames'"
                    [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-label>{{filter.label}}<span *ngIf="filter.required === true">*</span></mat-label>
                      <mat-form-field class="tw-date-picker" appearance="outline" style="margin-left: 0px; margin-top: 4px; width: 150px !important;">
                        <mat-select [disableOptionCentering]="true" multiple formControlName="poolNames"
                        (openedChange)="openedChange($event, 'poolNames');" [required]="filter.required">
                          <mat-option i18n *ngFor="let data of poolNameList;" [value]="data.code">{{data.value}}</mat-option>
                        </mat-select>
                        <mat-error class="error-txt-1">
                          <span i18n *ngIf="reportForm.controls.poolNames.invalid">
                            PoolName is required.</span>
                      </mat-error>
                      </mat-form-field>
                    </div>

                    <div style="width: 150px; margin-left: 5px;margin-right: 20px;" *ngIf="filter.name === 'location'"
                    [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-label>{{filter.label}}<span *ngIf="filter.required === true">*</span></mat-label>
                      <mat-form-field class="tw-date-picker" appearance="outline" style="margin-left: 0px; margin-top: 4px; width: 150px !important;">
                        <input i18n-placeholder matInput style="text-overflow: ellipsis;" placeholder="Search for a location" #selectLoc 
                        (keyup)="getLocationlist($event)" [matAutocomplete]="auto" formControlName="location" [required]="filter.required">
                        <button type="button" mat-button matSuffix mat-icon-button aria-label="Clear"
                        *ngIf="selectLoc.value != ''" (click)="selectLoc.value = '';reportHeaderActionEvent('location', null)">
                            <mat-icon style="font-size: 20px;">highlight_off</mat-icon>
                        </button>
                        <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let location of locationlist" (click)="reportHeaderActionEvent('location', location)" value="{{location.name}},{{location.fullName}}">
                        {{location.name}},{{location.fullName}}
                        </mat-option>
                        </mat-autocomplete>
                        <mat-error class="error-txt-1">
                          <span i18n *ngIf="reportForm.controls.location.invalid">
                            Location is required.</span>
                      </mat-error>
                      </mat-form-field>
                    </div>

                    <div style="width: 150px; margin-left: 5px;margin-right: 20px;" *ngIf="filter.name === 'role'"
                    [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-label>{{filter.label}}<span *ngIf="filter.required === true">*</span></mat-label>
                      <mat-form-field class="tw-date-picker" appearance="outline" style="margin-left: 0px; margin-top: 4px; width: 150px !important;">
                        <mat-select [disableOptionCentering]="true" formControlName="role" [required]="filter.required">
                          <mat-option i18n *ngFor="let data of RoleList;" [value]="data.id"
                          (click)="reportHeaderActionEvent('role', data.id)">{{data.name}}</mat-option>
                        </mat-select>
                        <mat-error class="error-txt-1">
                          <span i18n *ngIf="reportForm.controls.role.invalid">
                            Role is required.</span>
                      </mat-error>
                      </mat-form-field>
                    </div>

                    <div style="width: 150px; margin-left: 5px;margin-right: 20px;" *ngIf="filter.name === 'user'" 
                    [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-label>{{filter.label}}<span *ngIf="filter.required === true">*</span></mat-label>
                      <mat-form-field class="tw-date-picker" appearance="outline" style="margin-left: 0px; margin-top: 4px; width: 150px !important;">
                        <input i18n-placeholder matInput style="text-overflow: ellipsis;" placeholder="Search User" #selectUser
                        (keyup)="getUserByTypeCheck($event)" formControlName="user" [matAutocomplete]="autoUser" [required]="filter.required">
                        <button type="button" mat-button matSuffix mat-icon-button aria-label="Clear"
                        *ngIf="selectUser.value != ''" (click)="selectUser.value = '';reportHeaderActionEvent('user', null)">
                            <mat-icon style="font-size: 20px;">highlight_off</mat-icon>
                        </button>
                        <mat-autocomplete #autoUser="matAutocomplete">
                        <mat-option *ngFor="let user of userList" (click)="reportHeaderActionEvent('user', user)" value="{{user.name}}">
                        {{user.name}}
                        </mat-option>
                        </mat-autocomplete>
                        <mat-error class="error-txt-1">
                          <span i18n *ngIf="reportForm.controls.user.invalid">
                            User is required.</span>
                      </mat-error>
                      </mat-form-field>
                    </div>

                    <div style="width: 150px; margin-left: 5px;margin-right: 20px;" *ngIf="filter.name === 'tagSerialNumber'"
                    [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-label>{{filter.label}}<span *ngIf="filter.required === true">*</span></mat-label>
                      <mat-form-field class="tw-date-picker" appearance="outline" style="margin-left: 0px; margin-top: 4px; width: 150px !important;">
                        <input i18n-placeholder matInput style="text-overflow: ellipsis;" placeholder="Search Tag" #selectTag
                        (keyup)="searchToCoster($event)" formControlName="tagSerialNumber" [matAutocomplete]="autoTag" [required]="filter.required">
                        <button type="button" mat-button matSuffix mat-icon-button aria-label="Clear"
                        *ngIf="selectTag.value != ''" (click)="selectTag.value = '';reportHeaderActionEvent('tagSerialNumber', null)">
                            <mat-icon style="font-size: 20px;">highlight_off</mat-icon>
                        </button>
                        <mat-autocomplete #autoTag="matAutocomplete">
                        <mat-option *ngFor="let tag of searchCosterlist" (click)="reportHeaderActionEvent('tagSerialNumber', tag)" value="{{tag.tagId}}">
                        {{tag.tagId}}
                        </mat-option>
                        </mat-autocomplete>
                        <mat-error class="error-txt-1">
                          <span i18n *ngIf="reportForm.controls.tagSerialNumber.invalid">
                              Tag is required.</span>
                      </mat-error>
                      </mat-form-field>
                    </div>

                    <div style="width: 150px; margin-left: 5px;margin-right: 20px;" *ngIf="filter.name === 'asset'"
                    [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-label>{{filter.label}}<span *ngIf="filter.required === true">*</span></mat-label>
                      <mat-form-field class="tw-date-picker" appearance="outline" style="margin-left: 0px; margin-top: 4px; width: 150px !important;">
                        <input i18n-placeholder matInput style="text-overflow: ellipsis;" placeholder="Asset Name" #selectAsset
                        (keyup)="searchAsset($event)" formControlName="asset" [matAutocomplete]="autoAsset" [required]="filter.required">
                        <button type="button" mat-button matSuffix mat-icon-button aria-label="Clear"
                        *ngIf="selectAsset.value != ''" (click)="selectAsset.value = '';reportHeaderActionEvent('asset', null)">
                            <mat-icon style="font-size: 20px;">highlight_off</mat-icon>
                        </button>
                        <mat-autocomplete #autoAsset="matAutocomplete">
                        <mat-option *ngFor="let asset of searchAssetList" (click)="reportHeaderActionEvent('asset', asset)" value="{{asset.name}}">
                        {{asset.name}}
                        </mat-option>
                        </mat-autocomplete>
                        <mat-error class="error-txt-1">
                          <span i18n *ngIf="reportForm.controls.asset.invalid">
                              Asset is required.</span>
                      </mat-error>
                      </mat-form-field>
                    </div>

                    <div style="width: 150px; margin-left: 5px;margin-right: 20px;" *ngIf="filter.name === 'patient'"
                    [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-label>{{filter.label}}<span *ngIf="filter.required === true">*</span></mat-label>
                      <mat-form-field class="tw-date-picker" appearance="outline" style="margin-left: 0px; margin-top: 4px; width: 150px !important;">
                        <input i18n-placeholder matInput style="text-overflow: ellipsis;" placeholder="Patient Name" #selectPatient
                        (keyup)="searchPatient($event)" formControlName="patient" [matAutocomplete]="autoPatient" [required]="filter.required">
                        <button type="button" mat-button matSuffix mat-icon-button aria-label="Clear"
                        *ngIf="selectPatient.value != ''" (click)="selectPatient.value = '';reportHeaderActionEvent('patient', null)">
                            <mat-icon style="font-size: 20px;">highlight_off</mat-icon>
                        </button>
                        <mat-autocomplete #autoPatient="matAutocomplete">
                        <mat-option *ngFor="let patient of searchPatientlist" (click)="reportHeaderActionEvent('patient', patient)" value="{{patient.firstName}}">
                        {{patient.firstName}}
                        </mat-option>
                        </mat-autocomplete>
                        <mat-error class="error-txt-1">
                          <span i18n *ngIf="reportForm.controls.patient.invalid">
                              Patient is required.</span>
                      </mat-error>
                      </mat-form-field>
                    </div>

                    <div style="width: 150px; margin-left: 5px;margin-right: 20px;" *ngIf="filter.name === 'uhid'"
                    [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-label>{{filter.label}}<span *ngIf="filter.required === true">*</span></mat-label>
                      <mat-form-field class="tw-date-picker" appearance="outline" style="margin-left: 0px; margin-top: 4px; width: 150px !important;">
                        <input #uhid i18n-placeholder matInput style="text-overflow: ellipsis;" placeholder="UHID" 
                        (keyup)="getUHID($event.target.value)" formControlName="uhid" [matAutocomplete]="autoUhid" [required]="filter.required">
                        <button type="button" mat-button matSuffix mat-icon-button aria-label="Clear"
                        *ngIf="uhid.value != ''" (click)="uhid.value = '';clearUHID($event);reportHeaderActionEvent('uhid', null)">
                            <mat-icon style="font-size: 20px;">highlight_off</mat-icon>
                        </button>
                        <mat-autocomplete #autoUhid="matAutocomplete">
                        <mat-option *ngFor="let uhid of uhidList" (click)="reportHeaderActionEvent('uhid', uhid)" value="{{uhid}}">
                        {{uhid}}
                        </mat-option>
                        </mat-autocomplete>
                        <mat-error class="error-txt-1">
                          <span i18n *ngIf="reportForm.controls.uhid.invalid">
                              UHID is required.</span>
                      </mat-error>
                      </mat-form-field>
                    </div>

                    <div style="width: 150px; margin-left: 5px;margin-right: 20px;" *ngIf="filter.name === 'activity'"
                    [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-label>{{filter.label}}<span *ngIf="filter.required === true">*</span></mat-label>
                      <mat-form-field class="tw-date-picker" appearance="outline" style="margin-left: 0px; margin-top: 4px; width: 150px !important;">
                        <mat-select [disableOptionCentering]="true" formControlName="activity" (openedChange)="openedChange($event, 'activity');" multiple>
                          <mat-option #allCatSelected value="All" (click)="reportActionEvent('activity', true)">All</mat-option>
                          <mat-option *ngFor="let category of activityCategoryList" [value]="category.code" 
                          (click)="reportActionEvent('activity', false)">
                          {{category.value}}
                          </mat-option>
                        </mat-select>
                        <mat-error class="error-txt-1">
                          <span i18n *ngIf="reportForm.controls.activity.invalid">
                            Role is required.</span>
                      </mat-error>
                      </mat-form-field>
                    </div>

                    <div style="width: 150px; margin-left: 5px;margin-right: 20px;" *ngIf="filter.name === 'task'"
                    [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-label>Task<span *ngIf="filter.required === true">*</span></mat-label>
                      <mat-form-field class="tw-date-picker" appearance="outline" style="margin-left: 0px; margin-top: 4px; width: 150px !important;">
                        <mat-select [disableOptionCentering]="true" formControlName="task" (openedChange)="openedChange($event, 'task');" multiple>
                          <mat-option #allTaskSelected value="All" (click)="reportActionEvent('task', true)">All</mat-option>
                          <mat-option *ngFor="let task of taskActivitiesList" [value]="task.id" 
                          (click)="reportActionEvent('task', false)">
                          {{task.name}}
                          </mat-option>
                        </mat-select>
                        <mat-error class="error-txt-1">
                          <span i18n *ngIf="reportForm.controls.task.invalid">
                            Task is required.</span>
                      </mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="filter.name === 'groupBy'" style="padding-top: 15px;" [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}">
                      <mat-button-toggle-group appearance="legacy" name="fontStyle" aria-label="Font Style" style="height: 33px">
                        <mat-button-toggle value="D" (change)="reportHeaderActionEvent('groupBy', $event.value);" [checked]="this.type === 'D' ? true : false">Day</mat-button-toggle>
                        <mat-button-toggle value="M" (change)="reportHeaderActionEvent('groupBy', $event.value);" [checked]="this.type === 'M' ? true : false">Month</mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>


                    <button *ngIf="filter.name === 'getInsights'" i18n mat-button class="active-btn margin-r-1" style="padding-top: 0px;margin-left: 5px;margin-top: 15px !important;height: 33px;"
                    [ngStyle]="{'display': filter.visibled === false ? 'none' : ''}"
                    cdkFocusInitial (click)="reportHeaderActionEvent('getInsights', null)" [disabled]="this.reportForm.invalid">{{filter.label}}</button>
                  </div>
                  <span *ngIf="enableRefresh" class="dq-main-wrapper" style="margin-top: 10px;">
                    <i class="material-icons cur-ptr" i18n-matTooltip matTooltip="Refresh" (click)="reportHeaderActionEvent('refresh', null)">autorenew</i>
                  </span>

                </div>
                <div class="report-doc">
                  <img *ngIf="enableExcel && (this.params !== null && !disabled)" src="/assets/Alert/common_icons/excel_grey.svg" alt="" width=30px  style="cursor: pointer;">
                  <img *ngIf="enableExcel && (this.params === null && disabled || this.params !== null && disabled)" src="/assets/Alert/common_icons/excel_icon.png" alt="" i18n-matTooltip matTooltip="Download as excel sheet" 
                  width=30px  style="cursor: pointer;" (click)="reportHeaderActionEvent('excel', null)">
                  <img *ngIf="enablePdf && (this.params === null && disabled || this.params !== null && disabled)" 
                  src="/assets/Alert/common_icons/pdf_icon.png" alt="" i18n-matTooltip matTooltip="Download as PDF" 
                  width = 30px style="padding:0px 5px;cursor: pointer;" (click)="reportHeaderActionEvent('pdf', null)">
                  <img *ngIf="enablePdf && (this.params !== null && !disabled)" src="/assets/Alert/common_icons/pdf_grey.svg" alt="" width = 25px style="padding:4px 5px;cursor: pointer;">
                </div>
            </div>
        </div>
    </span>
</div>