/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { DatePipe } from '@angular/common';

@Injectable()
export class DashboardService {
   public baseUrl = 'api/python-wrapper/reports/';
   public facilityId = '';
   lastDay: string;
   lastWeek: string;
   lastMonth: string;
   constructor(private apiService: ApiService, public datepipe: DatePipe) {
      this.facilityId = localStorage.getItem(btoa('facilityId'));
      const date: any = new Date();
      date.setDate(date.getDate() - 1);
      this.lastDay = this.datepipe.transform(date, 'yyyy-MM-dd');
      const date1: any = new Date();
      date1.setDate(date1.getDate() - 7);
      this.lastWeek = this.datepipe.transform(date1, 'yyyy-MM-dd');
      const date2: any = new Date();
      date2.setDate(date2.getDate() - 30);
      this.lastMonth = this.datepipe.transform(date2, 'yyyy-MM-dd');

   }
   //for dashboard management - Python

   getLayoutUpdate(dashboardId, widgetId, x, y, rowid, colid){
      return this.apiService.get(environment.base_value.layout_update + localStorage.getItem(btoa('facilityId'))+'/dashboardId='+ dashboardId + '&widgetId='+ widgetId + '&x='+ x + '&y='+ y + '&rowid='+ rowid + '&colid='+ colid)
   }
   //for dashboard management - JAVA
   // new 
   getCurrentDashboard(userId, roleId, resourceCode?:any) {
      let param = '?userId='+ userId +'&roleId='+ roleId
      if(resourceCode){
         param = param + '&linkedResourceCode='+ resourceCode
      }
      return this.apiService.get(environment.base_value.current_dashboard + param);
   }
   saveCurrentDashboard(postData) {
      return this.apiService.post(environment.base_value.current_dashboard, postData);
   }
   getDashboardbyIds(dashboardId, userId, resourceCode?: any) {
      if(resourceCode){
         return this.apiService.get(environment.base_value.dahsboard_by_id +'?dashboardIds='+ dashboardId +'&userId='+userId+'&linkedResourceCode='+resourceCode);
      } else{
         return this.apiService.get(environment.base_value.dahsboard_by_id +'?dashboardIds='+ dashboardId +'&userId='+userId);
      }
   }
   getDashboardUserWidget(userId){
      return this.apiService.get(environment.base_value.dashboard_user_widget +'?userId='+ userId);
   }
   getDashboardDetailsList(userId){
      return this.apiService.get(environment.base_value.dashboard_details_list +'?userId='+ userId);
   }
   getDashboardDetailsListLayout(isPermitAll,userId){
      return this.apiService.get(environment.base_value.dashboard_details_list +'?isPermitAll='+ isPermitAll +'&userId='+ userId);
   }
   getDashboardWidgetData(widgetId, widgetParam){
      if(widgetParam == null){
         return this.apiService.get(environment.base_value.dashboard_widget_data + '?widgetIds=' + widgetId);
      } else{
         return this.apiService.get(environment.base_value.dashboard_widget_data + '?widgetIds=' + widgetId + '&widgetInputParams=' + encodeURIComponent(widgetParam));
      }
   }
   deleteDashboardLayoutById(id){
      return this.apiService.delete(environment.base_value.dashboard_layout_delete_by_id + '/' + id);
   }
   deleteDashboardLayoutByIds(ids){
      return this.apiService.delete(environment.base_value.dashboard_layout_delete_by_id +'/' + "id" + '?ids=' + ids);
   }
   
   // Widget management 
   getWidgetList(roleId){
      let params = '';
      if (roleId) {
         params = '?roleId=' + roleId;
      }
      return this.apiService.get(environment.base_value.get_widget_list + params);
   }
   getAllModals() {
      return this.apiService.get(environment.base_value.get_pf_models);
   }
   getWidgetTemplates(widgetIds){
      return this.apiService.get(environment.base_value.get_widget_templates + '?widgetIds=' + widgetIds);
   }
   updateWidgetDetailById(data){
      return this.apiService.put(environment.base_value.save_widget_detail_by_id + '/'+ data['id'] , data);
   }
   updateWidgetModelById(data){
      return this.apiService.put(environment.base_value.save_widget_model + '/' + data['id'] , data);
   }
   saveWidgetDetailById(data){
      return this.apiService.put(environment.base_value.save_widget_detail + '/'+ data['id'] , data);
   }
   saveNewWidget(data) {
      return this.apiService.post(environment.base_value.save_widget_detail, data);
   }
   saveNewModel(data) {
      return this.apiService.post(environment.base_value.save_widget_model, data);
   }
   // Health checkup dashboard and report services
   
  
  
   getPatientSummary() {
      return this.apiService.get(environment.base_value.patient_summary + localStorage.getItem(btoa('facilityId')));
   }
   

   public getAllLocationCategory(appTermsId) {
      return this.apiService.get(environment.base_value.get_app_terms + '/' + appTermsId);
   }
   // getAllAlert() {
   //    return this.apiService.get(environment.alert_api);
   // }

   // getAllAlert(pageStart, pageSize, ruleTypeId?: string) {
   //    if (ruleTypeId !== 'All' && ruleTypeId != null) {
   //       return this.apiService.get(environment.base_value.alert_api+'?isForCurrentUser=true&pageStart='+pageStart+'&pageSize='+pageSize+'&ruleTypeId='+ruleTypeId);
   //    } else {
   //       return this.apiService.get(environment.base_value.alert_api+'?isForCurrentUser=true&pageStart='+pageStart+'&pageSize='+pageSize);
   //    }
   // }


   // filterByTypeandDate(fromDate, toDate, ruleTypeId) {
      // return this.apiService.get(environment.base_value.alert_api+'?isForCurrentUser=true&fromDate='+fromDate+'&toDate='+toDate+'&ruleTypeId='+ruleTypeId);
   // }

   getAllDashboardOP(reportName, id) {
      let url = null;
      if (id === 1) {
         url = this.baseUrl + reportName + '/0/0/' + localStorage.getItem(btoa('facilityId'));
      } else if (id === 2) {
         url = this.baseUrl + reportName + '/0/0/' + localStorage.getItem(btoa('facilityId')) + '/fdt=' + this.lastDay;
      } else if (id === 3) {
         url = this.baseUrl + reportName + '/0/0/' + localStorage.getItem(btoa('facilityId')) + '/fdt=' + this.lastWeek
            + '&tdt=' + this.lastDay + '&freq=1M';
      } else if (id === 4) {
         url = this.baseUrl + reportName + '/0/0/' + localStorage.getItem(btoa('facilityId')) + '/fdt=' + this.lastMonth
            + '&tdt=' + this.lastDay + '&freq=1M';
      } else {
         url = this.baseUrl + reportName + '/0/0/' + localStorage.getItem(btoa('facilityId'));
      }
      //  console.log(url, reportName, id, this.lastDay, this.lastWeek, this.lastMonth);
      return this.apiService.get(url);
   }
   getAllDashboardOP1(reportName, id) {
      let url = null;
      if (id == 1) {
         url = this.baseUrl + reportName + '/0/0/' + localStorage.getItem(btoa('facilityId'));
      } else if (id == 2) {
         url = this.baseUrl + reportName + '/0/0/' + localStorage.getItem(btoa('facilityId')) + '/fdt=' + this.lastDay;
      } else if (id == 3) {
         url = this.baseUrl + reportName + '/0/0/' + localStorage.getItem(btoa('facilityId')) + '/fdt=' + this.lastWeek + '&tdt=' + this.lastDay + '&freq=1M';
      } else if (id == 4) {
         url = this.baseUrl + reportName + '/0/0/' + localStorage.getItem(btoa('facilityId')) + '/fdt=' + this.lastMonth + '&tdt=' + this.lastDay + '&freq=1M';
      } else {
         url = this.baseUrl + reportName + '/0/0/' + localStorage.getItem(btoa('facilityId'));
      }
      // console.log(url, reportName, id, this.lastDay, this.lastWeek, this.lastMonth);
      return this.apiService.get(url);
   }
   getAvgContactTime() {
      const url = 'api/python-wrapper/reports/avgtimeinloc/0/0/' + localStorage.getItem(btoa('facilityId'));
      return this.apiService.get(url);
   }
   getAvgWaitTime(src, dest) {
      const url = 'api/python-wrapper/reports/avgtimebtwloc/0/0/' + localStorage.getItem(btoa('facilityId')) +
         '/scat=' + src + '&dcat=' + dest;
      return this.apiService.get(url);
   }
   getCountActiveTaginLoc() {
      const url = 'api/python-wrapper/reports/countactivetaginloc/0/0/' + localStorage.getItem(btoa('facilityId'));
      return this.apiService.get(url);
   }
   getAvgTimeinCareArea() {
      const url = 'api/python-wrapper/reports/avgtimeinca/0/0/' + localStorage.getItem(btoa('facilityId'));
      return this.apiService.get(url);
   }
   getAllLocPatientVisit() {
      const url = 'api/python-wrapper/reports/counttaginloc/0/0/' + localStorage.getItem(btoa('facilityId'));
      return this.apiService.get(url);
   }
   getAvgLengthofStay() {
      const url = 'api/python-wrapper/reports/avgtimeinfacility/0/0/' + localStorage.getItem(btoa('facilityId'));
      return this.apiService.get(url);
   }
   getAvgTimeToBoard() {
      const url = 'api/python-wrapper/reports/avgtimetoboard/0/0/' + localStorage.getItem(btoa('facilityId')) + '/fdt=2019-03-13';
      return this.apiService.get(url);
   }
   getAvgTimeToDischarge() {
      const url = 'api/python-wrapper/reports/avgtimetodischarge/0/0/' + localStorage.getItem(btoa('facilityId')) + '/fdt=2019-03-13';
      return this.apiService.get(url);
   }
   getInpatientStatus() {
      const url = 'api/python-wrapper/reports/assetstatus/0/0/' + localStorage.getItem(btoa('facilityId')) + '/fdt=2019-03-13';
      return this.apiService.get(url);
   }
   getTagLoc(id) {
      const url = 'api/python-wrapper/reports/totaltimebyloc/0/0/' + localStorage.getItem(btoa('facilityId')) + '/tid=' + id + '&cloc=1';
      return this.apiService.get(url);
   }
   getDigitalQueueSummary() {
      const url = 'api/patient/patient-digital-queues-summary';
      return this.apiService.get(url);
   }

   updatePatientQueueStatus(id, status) {
      return this.apiService.put(environment.base_value.update_patient_queue_status + '/' + id, status);
   }

   // getRegisteredPatients(id) {
   //    return this.apiService.get(environment.base_value.registered_patients + '/' + id);
   // }

   // saveRegisteredPatients(data) {
   //    return this.apiService.post(environment.base_value.registered_patients, data);
   // }
   validatePatientToken(token) {
      return this.apiService.get(environment.base_value.token_validate+token);
   }

   patientStatusValue = ['2', '1', '1', '1'];
   patientStatusLabel = ['Pending', 'Waiting', 'Inprogress', 'Completed'];
   patientUtilizationValue = ['6', '4', '2'];
   patientUtilizationLabel = ['ICU', 'Staff Room', 'General Ward'];
   patientVisitCountLabel = ['X-Ray', 'General Ward', 'Blood Collection'];
   patientVisitCountValue = ['3', '3', '7'];
   patientCountLabel = ['Staff Room', 'ICU', 'X-Ray'];
   patientCountValue = ['1', '3', '5'];
   averageContactTimeValue = ['1', '3', '5'];
   averageContactTimeLabel = ['X-Ray', 'ICU', 'Room 6'];
   avgWaitTimeValue = ['40', '10', '20', '35', '25'];


}
