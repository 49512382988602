<div class="ovi-font-family"  style="height: calc(100% - 6px);">
    <mat-toolbar class="input-header">
        <div i18n class="header-name">{{data.type}}</div>
        <div class="header-close"><button mat-icon-button mat-dialog-close close-button class="ie-close-icon">
                <mat-icon class="icon-style">highlight_off</mat-icon>
            </button></div>
    </mat-toolbar>
    <div style="margin:10px ;height: calc(100% - 100px); overflow: hidden;">
        <div class="example-form custom-name">
            <mat-form-field class="filter-mat-form-appearence" appearance="outline" style="width:200px;">
                <input matInput i18n-placeholder #searchInputClear style="padding: 5px;"
                    (keyup)="applyFilter($event.target.value)" placeholder="Filter" autocomplete="off"
                    role="presentation">
                <button mat-icon-button matSuffix aria-label="clear" *ngIf="searchInputClear.value"
                    style="top: -1.5em !important;" (click)="searchInputClear.value=''; applyFilter('');">
                    <span class="tw-filter-close">x</span>

                </button>
            </mat-form-field>
        </div>
        <div class="popup_form_body popup-form-container popup-margin" style ="height: calc(100% - 78px);border: 1px solid;background: whitesmoke; padding: 5px;">
        <div class="block-3"  *ngIf="showCheckbox">
            <div class="row-field-1" *ngFor="let data of TypeList">
                <span>
                    <mat-checkbox style="width: 18px; height: 18px; float: left; cursor: pointer; padding :5px 0;"
                        [checked]="data.isSelected ? true :  checked" (change)="onCheckboxChange(data, $event)">
                        <span style="max-width: 250px; overflow: hidden; text-overflow: ellipsis;display: block;" i18n-matTooltip appTooltip tooltipText ={{data.name}}>{{data.name}}</span>
                    </mat-checkbox>
                </span>

            </div>
            <!-- (change)="onCheckboxChange(data)" [checked]="selectedOptions.includes(data)" -->
        </div>
    </div>

    </div>
    <div class="input-footer" style="text-align: right !important;">
        <button i18n mat-button class="cancle-btn" style="margin-right: 5px;" mat-dialog-close>Cancel</button>
        <button style="margin-right: 5px" mat-button class="active-btn" (click)="saveMultiSpec()">Save</button>

    </div>
</div>
