/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
const graphMasterData = {
  nodes: [
    { id: 1, weight: 1, nType: 1 },
    { id: 2, weight: 1, nType: 1 },
    { id: 3, weight: 1, nType: 1 },
    { id: 4, weight: 1, nType: 1 },
    { id: 5, weight: 1, nType: 1 },
    { id: 6, weight: 1, nType: 1 },
    { id: 7, weight: 1, nType: 1 },
    { id: 8, weight: 1, nType: 1 },
    { id: 9, weight: 1, nType: 1 },
    { id: 10, weight: 1, nType: 1 },
    { id: 11, weight: 1, nType: 1 },
    { id: 12, weight: 1, nType: 1 },
    { id: 13, weight: 1, nType: 1 },
    { id: 14, weight: 1, nType: 1 },
    { id: 15, weight: 1, nType: 1 },
    { id: 16, weight: 1, nType: 1 },
    { id: 17, weight: 1, nType: 1 },
    { id: 18, weight: 1, nType: 1 },
    { id: 19, weight: 1, nType: 1 },
    { id: 20, weight: 1, nType: 1 },
    { id: 21, weight: 1, nType: 1 },
    { id: 22, weight: 1, nType: 1 },
    { id: 23, weight: 1, nType: 1 },
    { id: 24, weight: 1, nType: 1 },
    { id: 25, weight: 1, nType: 1 },
    { id: 26, weight: 1, nType: 1 },
    { id: 27, weight: 1, nType: 1 },
    { id: 28, weight: 1, nType: 1 },
    { id: 29, weight: 1, nType: 1 },
    { id: 30, weight: 1, nType: 1 },
    { id: 31, weight: 1, nType: 1 },
    { id: 32, weight: 1, nType: 1 },
    { id: 33, weight: 1, nType: 1 },
    { id: 34, weight: 1, nType: 1 },
    { id: 35, weight: 1, nType: 1 },
    { id: 36, weight: 1, nType: 1 },
    { id: 37, weight: 1, nType: 1 },
    { id: 38, weight: 1, nType: 1 }
  ],
  edges: [[1, 2], [2, 31], [31, 32], [32, 33], [33, 34], [34, 7], [1, 101], [2, 3], [3, 4], [3, 28], [4, 5], [5, 6], [5, 10], [5, 12], [6, 7], [7, 35], [8, 35], [8, 36], [9, 36], [10, 11], [12, 17], [17, 14], [14, 13], [14, 15], [15, 16], [17, 18], [18, 19], [19, 20], [18, 38], [21, 38], [21, 22], [24, 37], [21, 37], [23, 22], [24, 25], [24, 27], [25, 26], [27, 28], [29, 28], [29, 30]]

};

export default graphMasterData;


// const graphMasterData = {


//   nodes: [
//     { id: 1, weight: 1, nType: 1 },
//     { id: 2, weight: 1, nType: 1 },
//     { id: 3, weight: 1, nType: 1 },
//     { id: 4, weight: 1, nType: 1 },
//     { id: 5, weight: 1, nType: 1 },
//     { id: 6, weight: 1, nType: 1 },
//     { id: 7, weight: 1, nType: 1 },
//     { id: 8, weight: 1, nType: 1 },
//     { id: 9, weight: 1, nType: 1 },
//     { id: 10, weight: 1, nType: 1 },
//     { id: 11, weight: 1, nType: 1 },
//     { id: 12, weight: 1, nType: 1 },
//     { id: 13, weight: 1, nType: 1 },
//     { id: 14, weight: 1, nType: 1 },
//     { id: 15, weight: 1, nType: 1 },
//     { id: 16, weight: 1, nType: 1 },
//     { id: 17, weight: 1, nType: 1 },
//     { id: 18, weight: 1, nType: 1 },
//     { id: 19, weight: 1, nType: 1 },
//     { id: 20, weight: 1, nType: 1 },
//     { id: 21, weight: 1, nType: 1 },
//     { id: 22, weight: 1, nType: 1 },
//     { id: 23, weight: 1, nType: 1 },
//     { id: 24, weight: 1, nType: 1 },
//     { id: 25, weight: 1, nType: 1 },
//     { id: 26, weight: 1, nType: 1 },
//     { id: 27, weight: 1, nType: 1 },
//     { id: 28, weight: 1, nType: 1 },
//     { id: 29, weight: 1, nType: 1 },
//     { id: 30, weight: 1, nType: 1 },
//     { id: 31, weight: 1, nType: 1 },
//     { id: 32, weight: 1, nType: 1 },
//     { id: 33, weight: 1, nType: 1 },
//     { id: 34, weight: 1, nType: 1 },
//     { id: 35, weight: 1, nType: 1 },
//     { id: 36, weight: 1, nType: 1 }
//   ],
//   edges: [
//     [1, 2],
//     [1, 7],
//     [2, 3],
//     [2, 8],
//     [3, 4],
//     [3, 9],
//     [4, 5],
//     [4, 10],
//     [5, 6],
//     [5, 11],
//     [6, 12],
//     [7, 8],
//     [7, 13],
//     [8, 9],
//     [8, 14],
//     [9, 10],
//     [9, 15],
//     [10, 11],
//     [10, 16],
//     [11, 12],
//     [11, 17],
//     [12, 18],
//     [13, 14],
//     [13, 19],
//     [14, 15],
//     [14, 20],
//     [15, 16],
//     [15, 21],
//     [16, 17],
//     [16, 22],
//     [17, 18],
//     [17, 23],
//     [18, 24],
//     [19, 20],
//     [19, 25],
//     [20, 21],
//     [20, 26],
//     [21, 22],
//     [21, 27],
//     [22, 23],
//     [22, 28],
//     [23, 24],
//     [23, 29],
//     [24, 30],
//     [25, 26],
//     [25, 31],
//     [26, 27],
//     [26, 32],
//     [27, 28],
//     [27, 33],
//     [28, 29],
//     [28, 34],
//     [29, 30],
//     [29, 35],
//     [30, 36],
//     [31, 32],
//     [32, 33],
//     [33, 34],
//     [34, 35],
//     [35, 36]
//   ]
// };

// export default graphMasterData;
