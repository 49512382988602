export const locale_Json_Details = {
  en : {
  workflowLabel: 'Workflow',
  checkupLabel: 'Checkup Details',
  taskLabel: 'Task',
  taskListLabel: 'Task List',
  clickTooltip: 'Click to edit',
  doubleClickToolTip: 'Double Click to edit',
  ticketCreater: 'Created by',
  ticketUpdated: 'Updated by',
  readerGroupSaveBtn: 'SAVE',
  readerGroupModyBtn: 'UPDATE',
  tuckDetails: 'Truck Details'
  },

  ar : {
  workflowLabel: 'سير العمل',
  checkupLabel: 'تحقق من التفاصيل',
  taskLabel: 'مهمة',
  taskListLabel: 'قائمة المهام',
  clickTooltip: 'إضغط للتعديل',
  doubleClickToolTip: 'انقر نقرًا مزدوجًا للتحرير',
  ticketCreater : 'انشأ من قبل',
  ticketUpdated : 'تم التحديث بواسطة',
  readerGroupSaveBtn: 'حفظ',
  readerGroupModyBtn: 'التحديث',
  tuckDetails: 'تفاصيل الشاحنة',
  dashboard : ['لوحة القيادة'],
  digital_queue : ['قائمة الانتظار الرقمية'], 
  health_checkup : ['فحص صحي'] , 
  truck_tracking : ['تتبع الشاحنات'],
  employee: ['الموظف'], visitors :  ['زوار'], 
  temporary_id_card : ['بطاقة الهوية المؤقتة'],  
  asset_management : ['إدارة أصول'],
  porter_new : ['بورتر جديد'],
  outpatient : ['مريض خارجي'],
  operation_theatre: ['مسرح العملية'],
  emergency_care: ['الرعاية في حالات الطوارئ'],
  ambulance: ['سياره اسعاف'],
  infant: ['رضيع'],
  consumer: ['مستهلك'],
  staff_routine: ['روتين الموظفين'],
  message_centre: ['مركز الرسالة'],
  student: ['طالب'],
  form: ['استمارة'],
  ticket: ['وضع بطاقة على'],
  patient: ['صبور'],
  manage_facility: ['إدارة المنشأة'],
  location: ['موقع'],
  user_management: ['إدارةالمستخدم'],
  import_setting: ['إعدادات الاستيراد'],
  floor_plan: ['خطة الكلمة'],
  support: ['سند'],
  gateway: ['طريق البوابة'],
  gateway_management: ['إدارة البوابة'],
  broker: ['وسيط'] ,
  reader: ['قارئ'] ,
  server_management: ['إدارة الخادم'],
  device: ['جهاز'],
  asset: ['الأصول'],
  rule: ['قاعدة'],
  appterms: ['شروط التطبيق'],
  alert: ['يُحذًِر'],
  scheduler: ['المجدول'],
  permission_mapping: ['رسم خرائط الإذن'],
  social_distance_config: ['المسافة الاجتماعية التكوين'],
  package: ['رزمة'],
  resident: ['مقيم'],
  day_care: ['الرعاية النهارية'],
  inpatient: ['مريض داخلي'],
  medicalRecord: ['السجل الطبي'],
  task: ['مهمة'],
  patient_healthcheckup: ['فحص صحة المريض'],
  employee_summary: ['ملخص الموظف'],
  Routine: ['روتينية'],
  Maintenance: ['الصيانة'],
  In_patient: ['في المريض'],
  out_patient: ['مريض خارجي'],
  patient_daycare: ['الرعاية النهارية للمريض'],
  patient_infant: ['الرضيع المريض'],
  medical_record: ['السجل الطبي'],
  emergency: ['حالة طوارئ'],
  schedule_report: ['تقرير الجدول الزمني'],
  staff: ['العاملين'],
  environment: ['بيئة'],
  audit_log: ['سجل التدقيق'],
  error_log: ['سجل الأخطاء'],
  porter: ['حمال']
  },

  id : {
  workflowLabel: 'alur kerjaLabel',
  checkupLabel: 'Label pemeriksaan',
  taskLabel: 'Label tugas',
  taskListLabel: 'DaftarTugasLabel',
  clickTooltip: 'klikKeterangan alat',
  doubleClickToolTip: 'Klik dua kali Tip Alat',
  ticketCreater : 'Pencipta tiket',
  ticketUpdated : 'tiket diperbarui',
  readerGroupSaveBtn: 'pembacaGroupSave',
  readerGroupModyBtn: 'pembacaGroupMody',
  tuckDetails: 'selipkan detailnya',
  dashboard : ['dasbor'],
  digital_queue : ['antrian digital'], 
  health_checkup : ['kesehatan pemeriksaan'] , 
  truck_tracking : ['truk pelacakan'],
  employee: ['karyawan'], 
  visitors :  ['pengunjung'], 
  temporary_id_card : ['kartu id sementara'],  
  asset_management : ['manajemen aset'],
  porter_new : ['porter_baru'],
  outpatient : ['rawat jalan'],
  operation_theatre: ['ruang operasi'],
  emergency_care: ['perawatan_darurat'],
  ambulance: ['ambulans'],
  infant: ['yg dlm masa pertumbuhan'],
  consumer: ['konsumen'],
  staff_routine: ['staff_routine'],
  message_centre: ['pesan_center'],
  student: ['murid'],
  form: ['membentuk'],
  ticket: ['tiket'],
  patient: ['pasien'],
  manage_facility: ['mengelola_fasilitas'],
  location: ['lokasi'],
  user_management: ['manajemen_pengguna'],
  import_setting: ['pengaturan_impor'],
  floor_plan: ['rencana_denah'],
  support: ['mendukung'],
  gateway: ['gerbang'],
  gateway_management: ['manajemen gerbang'],
  broker: ['pialang'] ,
  reader: ['pembaca'] ,
  server_management: ['manajemen_server'],
  device: ['perangkat'],
  asset: ['aset'],
  rule: ['aturan'],
  appterms: ['istilah aplikasi'],
  alert: ['waspada'],
  scheduler: ['penjadwal'],
  permission_mapping: ['ijin_mapping'],
  social_distance_config: ['sosial_jarak_config'],
  package: ['paket'],
  resident: ['penduduk'],
  day_care: ['penitipan_hari'],
  inpatient: ['rawat inap'],
  medicalRecord: ['rekam medis']
  }
}