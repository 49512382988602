<!-- <div style="font-family: Open Sans">
    <header i18n style="height:30px;padding:10px 5px; font-size: 20px; background: #3a7afe; color:white;">
        {title, select,Confirmation{Confirmation}, Confirmation Dialog {Confirmation Dialog}}
    </header>
    <section style="font-size: 16px;margin: 20px 10px;height: 50px;">
        <span i18n class="center"> {message, select,
            Are you sure you want to delete existing node? {Are you sure you want to delete existing node?}
            Are you sure you want to apply locations? {Are you sure you want to apply locations?}
            Are you sure you want to delete dataitem? {Are you sure you want to delete dataitem?}
            Do you want to create layout ? {Do you want to create layout ?}
            Are you sure you want to delete? {Are you sure you want to delete?}
            Do you want to delete the activity? {Do you want to delete the activity?}
            Do you want to delete the Campaign? {Do you want to delete the Campaign?} Do you want to delete? {Do you want to delete?}
            Do you want to update the Layout? {Do you want to update the Layout?} 
            Do you want to update the Factory layout ? {Do you want to update the Factory layout ?}
            Do you want to continue with the disassociation even though this user has an active booking? {Do you want to continue with the disassociation even though this user has an active booking?}
        }
            
         </span>
    </section>
    <footer class="center">
        <span *ngIf="data['buttonText'].hasOwnProperty('new')" style="padding-right: 20px;">
            <button mat-raised-button [mat-dialog-close]="createButtonText" tabindex="-1" style="background-color: #3a7afe; color: white;">
                {{createButtonText}}
            </button>


            
        </span>
        <span style="padding-right: 20px;">
            <button i18n mat-raised-button [mat-dialog-close]="'Yes'" tabindex="-1" style="background-color: #3a7afe; color: white;">
                {confirmButtonText, select, Yes {Yes}}
            </button>
        </span>
        <span>
            <button i18n mat-raised-button [mat-dialog-close]="'No || Cancel'" tabindex="-1" style="background-color: #4B4B4B; color: white;">
                {cancelButtonText, select,  No {No} Cancel{Cancel}}
            </button>
        </span>
    </footer>            
 </div> -->           <!-- 1 to 41 code will be created by Rahul   -->

<div class="custom-dialog-box">
    <div class="cf-dlg-frm_" [ngStyle]="{'background-color': customerName === 'kyn'? '#D61820' : ''}">
        <div i18n class="float-l"> {title, select,Confirmation{Confirmation}, Confirmation Dialog {Confirmation Dialog}} </div>
        <div class="float-r">
            <!-- <button mat-icon-button [mat-dialog-close]="data.buttonText.cancel" tabindex="-1"> -->
            <button mat-icon-button mat-dialog-close close-button style="float: right;">
                <mat-icon class="line-h-5">highlight_off</mat-icon>
            </button>
        </div>
    </div>
    <hr class="mrgn-style_">
    <div class="cf-dlg content-item">
        <div class="content-item" style="padding-bottom: 5px;">
            <span i18n>
                {message, select,
                    Are you sure you want to delete existing node? {Are you sure you want to delete existing node?}
                    Are you sure you want to apply locations? {Are you sure you want to apply locations?}
                    Are you sure you want to delete dataitem? {Are you sure you want to delete dataitem?}
                    Do you want to create layout ? {Do you want to create layout ?}
                    Are you sure you want to delete? {Are you sure you want to delete?}
                    Do you want to delete the activity? {Do you want to delete the activity?}
                    Do you want to delete the Campaign? {Do you want to delete the Campaign?} Do you want to delete? {Do you want to delete?}
                    Do you want to update the Layout? {Do you want to update the Layout?} 
                    Do you want to update the Factory layout ? {Do you want to update the Factory layout ?}
                    Do you want to continue with the disassociation even though this user has an active booking? {Do you want to continue with the disassociation even though this user has an active booking?}
                    Are you sure you want to delete the location? {Are you sure you want to delete the location?}
                    Are you sure you want to delete all nodes? {Are you sure you want to delete all nodes?}
                }
            </span>
            </div>
            </div>
    <mat-dialog-actions  class="input-footer content-item">

        <span *ngIf="data['buttonText'].hasOwnProperty('new')" style="padding-right: 20px;">
            <button mat-raised-button class="cf-dlg_1" [mat-dialog-close]="createButtonText" tabindex="-1" >
                {{createButtonText}}
            </button>          
        </span>
        <span style="padding-right: 20px;">
            <button i18n mat-raised-button class="cf-dlg_1" [ngStyle]="{'background-color': customerName === 'kyn'? '#D61820' : ''}"
             [mat-dialog-close]="'Yes'" tabindex="-1">
                {confirmButtonText, select, Yes {Yes}}
            </button>
        </span>
        <span>
            <button i18n mat-raised-button  [mat-dialog-close]="'No || Cancel'" tabindex="-1">
                {cancelButtonText, select,  No {No} Cancel{Cancel}}
            </button>
        </span>
    </mat-dialog-actions>
</div>