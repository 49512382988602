<div fxFlex class="input-component-holder ovi-font-family" style="overflow: hidden;">
    <mat-toolbar class="input-header">
        <div i18n class="header-name" *ngIf="this.visitType !== 'VT-RE'" style="text-overflow: ellipsis;">Enroll Patient</div>
        <div i18n class="header-name" *ngIf="this.visitType === 'VT-RE'" style="text-overflow: ellipsis;">Enroll Resident</div>
        <div class="header-close1"><button mat-icon-button mat-dialog-close close-button
                class="ie-close-icon">
                <mat-icon class="icon-style">highlight_off</mat-icon>
            </button></div>
    </mat-toolbar>
    <div class="mat-custom-form-field-box" style="overflow: hidden;" >
            <div class="popup_form_body popup-form-container popup-margin" [formGroup]="enrollPatientForm">
            <div class="form-row">
                <div i18n class="ovi-popup-title-text-size top-styl" *ngIf="this.visitType !== 'VT-RE'">PATIENT DETAILS</div>
                <div i18n class="ovi-popup-title-text-size top-styl" *ngIf="this.visitType === 'VT-RE'">RESIDENT DETAILS</div>
            </div>
            <mat-divider class="divider"></mat-divider>

            <div *ngIf="this.visitType === 'VT-IP' || this.visitType === 'VT-EC' || this.visitType === 'VT-HC' || this.visitType === 'VT-RE' || this.visitType === 'VT-OP' || this.visitType === 'VT-MR' || this.visitType === 'VT-DC'" class="form-row block-3">
                <div class="row-field-1">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">UHID</mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input matInput #uhid i18n-placeholder placeholder="UHID" formControlName="mainidentifier"
                              (keyup)="clearUHID($event)" (keyup.enter)="getUHID($event.target.value)" autocomplete="off">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-field top">
                <button i18n mat-button class="active-btn margin-r-1" appDebounceClick style="margin-top: 25px;"
                    [disabled]="!uhid.value" (click)="getUHID(uhid.value)" cdkFocusInitial
                    [debounceTime]="300">Search</button>
                </div>

            </div>
            <div class="form-row block-3">
                <div class="row-field-1">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">First Name <span class="mandatory-field">*</span></mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input matInput minlength="1" maxlength="50" i18n-placeholder placeholder="First Name"
                                formControlName="firstName" autocomplete="off" role="presentation">
                            <mat-error *ngIf="enrollPatientForm.controls.firstName.invalid" class="error-txt-1">
                                <span i18n [hidden]="!enrollPatientForm.controls['firstName'].errors.required">
                                    First name is required. (Limit 1 - 50 chars.)</span>
                                <span i18n [hidden]="!enrollPatientForm.controls['firstName'].errors.minlength">Min.
                                    1 chars required.</span>
                                <span i18n [hidden]="!enrollPatientForm.controls['firstName'].errors.maxlength">Max.
                                    allowed chars 50 </span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-field-1">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Middle Name</mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input matInput [maxlength]="50" i18n-placeholder placeholder="Middle Name"
                                formControlName="middleName" autocomplete="off" role="presentation">

                            <!-- <mat-error *ngIf="enrollPatientForm.controls.middleName.invalid">
                                <span i18n [hidden]="!enrollPatientForm.controls['middleName'].hasError('maxlength')">Max.
                                    allowed chars 50 (avoid special chars/nos.) </span>
                            </mat-error> -->
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-field-1">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Last Name</mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input matInput [maxlength]="50" i18n-placeholder placeholder="Last Name"
                                formControlName="lastName" autocomplete="off" role="presentation">
                            <!-- <mat-error *ngIf="enrollPatientForm.controls.lastName.invalid">
                                <span i18n [hidden]="!enrollPatientForm.controls['lastName'].hasError('maxlength')">Max.
                                    allowed chars 50 (avoid special chars/nos.) </span>
                            </mat-error> -->
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-field-1">
                    <div class="example-form">
                        <mat-label i18n *ngIf="tokenEnrollFlow === true" class="ovi-lbl-text-size"> Mobile </mat-label>
                        <mat-label i18n *ngIf="tokenEnrollFlow === false" class="ovi-lbl-text-size"> Mobile <span class="mandatory-field">*</span></mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input matInput i18n-placeholder placeholder="Mobile" formControlName="mobileNo"
                            autocomplete="off" role="presentation">
                            <mat-error *ngIf="enrollPatientForm.controls.mobileNo.invalid" class="error-txt-1">
                                <span i18n [hidden]="!enrollPatientForm.controls['mobileNo'].errors.required">
                                    Mobile No. is required.</span>
                            </mat-error>
                            <mat-error *ngIf="enrollPatientForm.controls.mobileNo.invalid" class="error-txt-1">
                                <span i18n [hidden]="!enrollPatientForm.controls['mobileNo'].errors.pattern">
                                    Enter a valid number!</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-field-1">
                    <div class="example-form">
                        <mat-label i18n *ngIf="tokenEnrollFlow === true" class="ovi-lbl-text-size">DOB</mat-label>
                        <mat-label i18n *ngIf="tokenEnrollFlow === false" class="ovi-lbl-text-size"> DOB <span class="mandatory-field">*</span></mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input matInput i18n-placeholder [matDatepicker]="picker" placeholder="DOB" [max]="today"
                                formControlName="birthDate" autocomplete="off" role="presentation">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="enrollPatientForm.controls.birthDate.invalid" class="error-txt-1">
                                <span i18n [hidden]="!enrollPatientForm.controls['birthDate'].errors.required">
                                    DOB is required (dd/mm/yyyy).</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-field-option">
                    <div class="example-form">
                        <mat-label i18n *ngIf="tokenEnrollFlow === true" class="ovi-lbl-text-size"> Gender </mat-label>
                        <mat-label i18n *ngIf="tokenEnrollFlow === false" class="ovi-lbl-text-size"> Gender <span class="mandatory-field">*</span></mat-label>
                        <div class="radio-button">
                            <mat-radio-group formControlName="gender" (change)="getGenderType($event.value)">
                                <!-- <mat-radio-button i18n class="cst-mgn_1" *ngFor="let o of genderList; let i = index" [value]="o" [checked]="i === 0">{{o.value}}</mat-radio-button> -->
                                <mat-radio-button class="cst-mgn_1" *ngFor="let o of genderList; let i = index" [checked]="enrollPatientForm.controls.gender.value === o.code" [value]="o">
                                    {{o.value}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="this.visitType === 'VT-IP' || this.visitType === 'VT-EC' || this.visitType === 'VT-RE' || this.visitType === 'VT-DC' || this.visitType === 'VT-OP'" class="form-row block-3">
                <!-- <div class="row-field-1">
                    <div class="example-form">
                        <mat-label *ngIf="this.data.visitEvent !== 'VE-OT'" i18n class="ovi-lbl-text-size"> Bed Location *</mat-label>
                        <mat-label *ngIf="this.data.visitEvent === 'VE-OT'" i18n class="ovi-lbl-text-size"> OT Location </mat-label>
                        <mat-form-field *ngIf="this.data.visitEvent !== 'VE-OT'" class="example-full-width form-field" appearance="outline">
                            <input i18n-placeholder matInput placeholder="Type here for Bed" (keyup)="searchAssociationDetails($event.target.value, 'bed', $event)"
                                [matAutocomplete]="bed" formControlName="bedId">
                            <mat-autocomplete autoActiveFirstOption [displayWith]="this.bedEnabled === true ? getBedList.bind(bedList, 'bed') : ''" #bed="matAutocomplete">
                                <mat-option *ngFor="let option of bedList" (onSelectionChange)="getWardLocation(option.locationId)" [value]="option.bedId">
                                    <div class="tot-wid-styl">{{option.bedName}}, {{option.locationName}}</div>
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="enrollPatientForm.controls.bedId.invalid" class="error-txt-1">
                                <span i18n [hidden]="!enrollPatientForm.controls['bedId'].errors.required">Bed Occupancy
                                    is required.</span>
                                    <span i18n [hidden]="!enrollPatientForm.controls['bedId'].errors.requireMatch">Please select value
                                        from the option</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field *ngIf="this.data.visitEvent === 'VE-OT'" class="example-full-width form-field" appearance="outline">
                            <input i18n-placeholder matInput placeholder="Type here for Parent Location" (keyup)="searchAssociationDetails($event.target.value, 'OTRoom', $event)"
                                [matAutocomplete]="bed" formControlName="bedId">
                            <mat-autocomplete autoActiveFirstOption [displayWith]="this.roomEnabled === true ? getBedList.bind(bedList, 'OTRoom') : ''" #bed="matAutocomplete">
                                <mat-option *ngFor="let option of bedList" [value]="option.id">
                                    <div class="tot-wid-styl">{{option.name}}</div>
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="enrollPatientForm.controls.bedId.invalid" class="error-txt-1">
                                <span i18n [hidden]="!enrollPatientForm.controls['bedId'].errors.required">Parent Location
                                    is required.</span>
                                    <span i18n [hidden]="!enrollPatientForm.controls['bedId'].errors.requireMatch">Please select value
                                        from the option</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div> -->
                <div class="row-field-1">
                    <div class="example-form">
                        <mat-label *ngIf="this.data.visitEvent !== 'VE-OT' && this.visitType !== 'VT-EC' && this.visitType !== 'VT-DC'" i18n class="ovi-lbl-text-size"> Admit Doctor <span class="mandatory-field">*</span></mat-label>
                        <mat-label *ngIf="this.visitType === 'VT-DC'" i18n class="ovi-lbl-text-size"> Admit Doctor </mat-label>
                        <mat-label *ngIf="this.data.visitEvent === 'VE-OT' || this.visitType === 'VT-EC'" i18n class="ovi-lbl-text-size">Doctor</mat-label>
                        <mat-form-field *ngIf="this.data.visitEvent !== 'VE-OT' && this.visitType !== 'VT-EC'" class="example-full-width form-field" appearance="outline">
                            <input i18n-placeholder matInput placeholder="Type here for Doctor" (keyup)="searchAssociationDetails($event.target.value, 'doctor', $event, null)"
                                [matAutocomplete]="doctor" formControlName="doctorId">
                            <mat-autocomplete autoActiveFirstOption [displayWith]="this.doctorEnabled === true ? getDoctorList.bind(doctorList, 'doctor') : ''" #doctor="matAutocomplete">
                                <mat-option *ngFor="let option of doctorList" [value]="option.id">
                                    <div class="tot-wid-styl">{{option.name}}</div>
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="enrollPatientForm.controls.doctorId.invalid" class="error-txt-1">
                                <span i18n [hidden]="!enrollPatientForm.controls['doctorId'].errors.required">Admit Doctor
                                    is required.</span>
                                <span i18n [hidden]="!enrollPatientForm.controls['doctorId'].errors.requireMatch">Please select value
                                    from the available option</span>
                                <!-- <span i18n [hidden]="!enrollPatientForm.controls['doctorId'].errors.requireMatch">Please select value
                                    from the available option</span> -->
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field *ngIf="this.data.visitEvent === 'VE-OT' || this.visitType === 'VT-EC'" class="example-full-width form-field" appearance="outline">
                            <input i18n-placeholder matInput placeholder="Type here for Doctor" (keyup)="searchAssociationDetails($event.target.value, 'surgeon', $event, null)"
                                [matAutocomplete]="doctor" formControlName="doctorId">
                            <mat-autocomplete autoActiveFirstOption [displayWith]="this.surgeonEnabled === true ? getDoctorList.bind(doctorList, 'surgeon') : ''" #doctor="matAutocomplete">
                                <mat-option *ngFor="let option of doctorList" [value]="option.id">
                                    <div class="tot-wid-styl">{{option.name}}</div>
                                </mat-option>
                            </mat-autocomplete>
                            <!-- <mat-error *ngIf="enrollPatientForm.controls.doctorId.invalid">
                                <span i18n [hidden]="!enrollPatientForm.controls['doctorId'].errors.required">Admit Doctor
                                    is required.</span>

                            </mat-error> -->
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="this.data.visitEvent === 'VE-OT' || this.visitType === 'VT-EC'" class="row-field-1">
                    <div class="example-form">
                        <mat-label i18n *ngIf="this.visitType !== 'VT-EC'" class="ovi-lbl-text-size font-styl">Surgery</mat-label>
                        <mat-label i18n *ngIf="this.visitType === 'VT-EC'" class="ovi-lbl-text-size font-styl">Plan Name <span class="mandatory-field">*</span></mat-label>
                        <!-- <mat-form-field class="example-full-width form-field" appearance="outline">
                            <mat-select formControlName="healthPlanId">
                                <mat-option *ngFor="let data of healthPlanList" [value]="data.id">{{data.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="enrollPatientForm.controls.healthPlanId.invalid">
                                <span i18n [hidden]="!enrollPatientForm.controls['healthPlanId'].errors.required">
                                    Surgery required.</span>
                            </mat-error>
                        </mat-form-field> -->
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input i18n-placeholder matInput placeholder="Type here for Surgery"
                            (keyup)="this.visitType !== 'VT-EC' ? searchAssociationDetails($event.target.value, 'surgery', $event, null) : ''"
                                [matAutocomplete]="surgery" formControlName="healthPlanId" [readonly]="this.visitType === 'VT-EC'">
                            <mat-autocomplete autoActiveFirstOption [displayWith]="this.surgeryEnabled === true ? getSurgeryList.bind(healthPlanList, 'surgery') : ''" #surgery="matAutocomplete">
                                <mat-option *ngFor="let data of healthPlanList" [value]="data.id">
                                    <div class="tot-wid-styl">{{data.name}}</div>
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="enrollPatientForm.controls.healthPlanId.invalid">
                                <span *ngIf="this.visitType !== 'VT-EC'" i18n [hidden]="!enrollPatientForm.controls['healthPlanId'].errors.required">Surgery
                                    is required.</span>
                                <span *ngIf="this.visitType === 'VT-EC'" i18n [hidden]="!enrollPatientForm.controls['healthPlanId'].errors.required">Plan Name
                                    is required.</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="this.data.visitType !== 'VT-OP'" class="row-field-1">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Associate Tag</mat-label>
                        <mat-form-field
                            class="example-full-width form-field" appearance="outline">
                            <input i18n-placeholder matInput placeholder="Tag ID" id="associateTagId"
                            appTypeHit (typeHit)="searchToCoster($event)" [matAutocomplete]="autoFrom"
                                formControlName="tagSerialNumber">
                            <mat-autocomplete autoActiveFirstOption #autoFrom="matAutocomplete">
                                <mat-option *ngFor="let option of searchAssociateCosterlist" [value]="option.tagId">
                                    <div class="tot-wid-styl" (click)="getTagTypeId(option.tagTypeId)">{{option.tagId}}</div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="this.data.visitType === 'VT-OP'" class="row-field-1">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size"> Service <span class="mandatory-field">*</span></mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input i18n-placeholder matInput placeholder="Type here for Service" (keyup)="searchAssociationDetails($event.target.value, 'service', $event, null)"
                                [matAutocomplete]="service" formControlName="testId">
                            <mat-autocomplete autoActiveFirstOption [displayWith]="this.serviceEnabled === true ? getTestList.bind(testListItems, 'service') : ''" #service="matAutocomplete">
                                <mat-option *ngFor="let data of testListItems" (click)= "getTestNameDetails(data);" [value]="data.id">
                                    <div class="tot-wid-styl">{{data.name}}</div>
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="enrollPatientForm.controls.testId.invalid">
                                <span i18n [hidden]="!enrollPatientForm.controls['testId'].errors.required">Service
                                    is required.</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="this.data.visitType === 'VT-OP'" class="row-field-1">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Visit Date & Time <span class="mandatory-field">*</span></mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input i18n-placeholder matInput type="datetime-local" id="datepicker" formControlName="startDate"
                                placeholder="Visit Date & Time"/>
                            <mat-error *ngIf="enrollPatientForm.controls.startDate.invalid">
                                <span i18n [hidden]="!enrollPatientForm.controls['startDate'].errors.required">Visit Date & Time
                                    is required.</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="this.data.visitType === 'VT-OP'" class="row-field-1">
                    <div class="example-form text-size">
                        <mat-checkbox i18n formControlName="isPreBooked">Pre Booked</mat-checkbox>
                    </div>
                </div>
                <div fxFlex.xs="100" fxFlex.lg="30" fxFlex.md="50" fxFlex.sm="50" class="row-field">
                    <div class="example-form">
                    </div>
                </div>
            </div>

            <!-- Start with new day care UI for multi visit changes -->

            <div formArrayName="visitList" *ngIf="enrollPatientForm && (this.visitType === 'VT-IP' || this.visitType === 'VT-EC' || this.visitType === 'VT-DC' || this.visitType === 'VT-RE' || this.visitType === 'VT-DC')">
                <div *ngFor="let obj of enrollPatientForm.controls.visitList.controls; let i=index">
                    <mat-divider *ngIf="enrollPatientForm.controls.visitList.controls.length > 1 && i > 0"></mat-divider>
                    <div [formGroupName]="i">
                        <div class="form-row block-3">
                            <div class="row-field-1">
                                <div class="example-form">
                                    <mat-label i18n *ngIf="i == 0" i18n class="ovi-lbl-text-size">Visit Date <span class="mandatory-field">*</span></mat-label>
                                    <mat-form-field class="example-full-width form-field" appearance="outline">
                                        <input *ngIf="i == 0" matInput i18n-placeholder [matDatepicker]="picker" placeholder="Visit Date"
                                            formControlName="visitDate" autocomplete="off" role="presentation" [min]="!this.data.id ? today : ''"
                                            (dateChange)="this.bedList=[]">
                                        <input *ngIf="i != 0" matInput i18n-placeholder [matDatepicker]="picker" placeholder="Visit Date"
                                            formControlName="visitDate" autocomplete="off" role="presentation"
                                            [min]="enrollPatientForm.controls.visitList?.value[0]?.visitDate === today && !this.data.id ? tomorrow : !this.data.id ? today : ''"
                                            [matDatepickerFilter]="dateFilter" (dateChange)="this.bedList=[]">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                        <mat-error class="error-txt-1">
                                            <span i18n *ngIf="enrollPatientForm.controls.visitList.value[i].visitDate === null ||
                                                enrollPatientForm.controls.visitList.value[i].visitDate === ''">Visit Date
                                                is required.</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row-field-1">
                                <div class="example-form">
                                    <mat-label *ngIf="i == 0 && this.data.visitEvent !== 'VE-OT' && this.visitType !== 'VT-EC' && this.visitType !== 'VT-DC'" i18n class="ovi-lbl-text-size">Bed Location </mat-label>
                                    <mat-label *ngIf="i == 0 && this.visitType === 'VT-DC'" i18n class="ovi-lbl-text-size">Bed Location</mat-label>
                                    <mat-label *ngIf="i == 0 && this.data.visitEvent === 'VE-OT'" i18n class="ovi-lbl-text-size">OT Location</mat-label>
                                    <mat-label *ngIf="i == 0 && this.visitType === 'VT-EC'" i18n class="ovi-lbl-text-size">Location</mat-label>
                                    <mat-form-field *ngIf="this.data.visitEvent !== 'VE-OT' && this.visitType !== 'VT-EC'" class="example-full-width form-field" appearance="outline">
                                        <input i18n-placeholder matInput placeholder="Type here for Bed" (keyup)="searchAssociationDetails($event.target.value, 'bed', $event, i)"
                                            [matAutocomplete]="bed" formControlName="bedId" [readonly]="enrollPatientForm.controls.visitList.value[i].visitDate === null">
                                        <mat-autocomplete autoActiveFirstOption [displayWith]="this.bedEnabled === true ? getBedList.bind(bedList, 'bed') : ''" #bed="matAutocomplete">
                                            <mat-option *ngFor="let option of bedList" (click)="getWardLocation(option.locationId)" [value]="option.bedId">
                                                <div class="tot-wid-styl">{{option.bedName}}, {{option.locationName}}</div>
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error class="error-txt-1">
                                            <span i18n *ngIf="enrollPatientForm.controls.visitList.value[i].bedId === null ||
                                                enrollPatientForm.controls.visitList.value[i].bedId === ''">Bed Occupancy
                                                is required.</span>
                                            <span i18n *ngIf="this.bedList.length === 0 && enrollPatientForm.controls.visitList.value[i].bedId !== null &&
                                                enrollPatientForm.controls.visitList.value[i].bedId !== ''">Beds not available</span>
                                            <span i18n *ngIf="this.bedList.length !== 0 && enrollPatientForm.controls.visitList.value[i].bedId !== null &&
                                                enrollPatientForm.controls.visitList.value[i].bedId !== ''">Please select value
                                                from the option</span>
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field *ngIf="this.data.visitEvent === 'VE-OT' || this.visitType === 'VT-EC'" class="example-full-width form-field" appearance="outline">
                                        <input i18n-placeholder matInput placeholder="Type here for Parent Location" (keyup)="searchAssociationDetails($event.target.value, 'OTRoom', $event, null)"
                                            [matAutocomplete]="bed" formControlName="bedId">
                                        <mat-autocomplete autoActiveFirstOption [displayWith]="this.roomEnabled === true ? getBedList.bind(bedList, 'OTRoom') : ''" #bed="matAutocomplete">
                                            <mat-option *ngFor="let option of bedList" [value]="option.id">
                                                <div class="tot-wid-styl">{{option.name}}</div>
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error class="error-txt-1">
                                            <span i18n *ngIf="enrollPatientForm.controls.visitList.value[i].bedId !== null &&
                                                enrollPatientForm.controls.visitList.value[i].bedId !== ''">Please select value
                                                from the option</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="example-form">
                                <span *ngIf="i == 0 && this.visitType === 'VT-DC' && !data.patientId"><button style="margin-top: 25px;" i18n mat-mini-fab (click)="addVisit()">+</button></span>
                                <span *ngIf="i > 0">
                                    <button i18n mat-mini-fab color="primary"
                                        (click)="removeVisit(i, 'clear')" [ngStyle]="{'margin-top': i === 0 ? '20px' : ''}"
                                        style="background-color: #FC8C91;"> x </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- End with new day care UI for multi visit changes -->

            <div class="form-row block-3" *ngIf="this.visitType === 'VT-HC'">
                <div *ngIf="this.visitType === 'VT-HC'" class="row-field-1">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Health Plan <span class="mandatory-field">*</span></mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input i18n-placeholder matInput placeholder="Type here for Health plan" (keyup)="searchAssociationDetails($event.target.value, 'healthPlan', $event, null)"
                                [matAutocomplete]="healthPlan" formControlName="healthPlanId">
                            <mat-autocomplete autoActiveFirstOption [displayWith]="this.healthPlanEnabled === true ? getSurgeryList.bind(healthPlanList, 'healthPlan') : ''" #healthPlan="matAutocomplete">
                                <mat-option *ngFor="let data of healthPlanList" [value]="data.id">
                                    <div class="tot-wid-styl" i18n-matTooltip matTooltip="{{data.name}}">{{data.name}}</div>
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="enrollPatientForm.controls.healthPlanId.invalid" class="error-txt-1">
                                <span i18n [hidden]="!enrollPatientForm.controls['healthPlanId'].errors.required">Health Plan
                                    is required.</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="this.tokenEnrollFlow === true && this.data.visitEvent !== 'VE-OT' && this.visitType !== 'VT-IP' && this.visitType !== 'VT-EC' && this.visitType !== 'VT-RE' && this.visitType !== 'VT-OP' && this.visitType !== 'VT-MR'"  class="row-field-option-1">
                    <div *ngIf="assignFloorDetails?.length > 0" class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size"> Assigned Floor <span class="mandatory-field">*</span></mat-label>
                        <div class="radio-button">
                            <mat-radio-group formControlName="assignFloorId" (change)="getAssignFloor($event.value)"
                                required>
                                <mat-radio-button i18n class="cst-mgn_1"
                                    *ngFor="let o of assignFloorDetails; let i = index" [value]="o.floorId">
                                    {{o.floorName}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>

            </div>
            <div *ngIf="this.visitType === 'VT-MR'" class="form-row block-3">
                <div class="row-field-1">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Volume</mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input matInput [maxlength]="50" i18n-placeholder placeholder="Volume"
                                formControlName="volume" autocomplete="off" role="presentation">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-field-1">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Requested Doctor</mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input i18n-placeholder matInput placeholder="Type here for Doctor" (keyup)="searchAssociationDetails($event.target.value, 'doctor', $event, null)"
                                [matAutocomplete]="requestedDoctorId" formControlName="requestedDoctorId">
                            <mat-autocomplete autoActiveFirstOption [displayWith]="this.doctorEnabled === true ? getDoctorList.bind(doctorList, 'doctor') : ''" #requestedDoctorId="matAutocomplete">
                                <mat-option *ngFor="let option of doctorList" [value]="option.id">
                                    <div class="tot-wid-styl">{{option.name}}</div>
                                </mat-option>
                            </mat-autocomplete>
                            <!-- <mat-error *ngIf="enrollPatientForm.controls.requestedDoctorId.invalid">
                                <span i18n [hidden]="!enrollPatientForm.controls['requestedDoctorId'].errors.requireMatch">Please select value
                                    from the available option</span>
                            </mat-error> -->
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-field-1">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Location</mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input i18n-placeholder matInput placeholder="Type here for Location" id="location" appTypeHit (typeHit)="getLocationSearch($event)"
                                [matAutocomplete]="deliveryLocId" formControlName="deliveryLocationId">
                            <mat-autocomplete autoActiveFirstOption [displayWith]="this.locationIdEnabled === true ? getLocationIdList.bind(searchLocation) : ''" #deliveryLocId="matAutocomplete">
                                <mat-option *ngFor="let option of searchLocation" [value]="option.id">
                                    <div class="tot-wid-styl">{{option.name}}, {{option.fullName}}</div>
                                </mat-option>
                            </mat-autocomplete>
                            <!-- <mat-error *ngIf="enrollPatientForm.controls.deliveryLocationId.invalid">
                                <span i18n [hidden]="!enrollPatientForm.controls['deliveryLocationId'].errors.requireMatch">Please select value
                                    from the available option</span>
                            </mat-error> -->
                        </mat-form-field>
                    </div>
                </div>
                 <div class="row-field-1">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Requested From Date</mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input matInput i18n-placeholder [matDatepicker]="requestedFromDate" placeholder="Requested From Date"
                                formControlName="requestedFromDate" [min]="today" autocomplete="off" role="presentation">
                            <mat-datepicker-toggle matSuffix [for]="requestedFromDate"></mat-datepicker-toggle>
                            <mat-datepicker #requestedFromDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-field-1">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Requested To Date</mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input matInput i18n-placeholder [matDatepicker]="requestedToDate" placeholder="Requested To Date"
                                formControlName="requestedToDate" [min]="today" autocomplete="off" role="presentation">
                            <mat-datepicker-toggle matSuffix [for]="requestedToDate"></mat-datepicker-toggle>
                            <mat-datepicker #requestedToDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-field-1">
                    <div class="example-form">
                    </div>
                </div>
            </div>
            <div *ngIf="this.tokenEnrollFlow === false">
                <div class="form-row">
                    <div i18n class="ovi-popup-title-text-size top-styl">Clinical Details</div>
                </div>
                <mat-divider class="divider"></mat-divider>

                <div>
                    <span class="space-around">
                       <mat-checkbox value="isChecked_Diabetic" formControlName="isDiabetic"> Diabetic</mat-checkbox>

                    </span>

                    <span class="space-around">
                        <mat-checkbox value="isChecked_Fasting" formControlName="isFasting"> Fasting</mat-checkbox>
                    </span>

                    <span class="space-around">
                        <mat-checkbox value="isChecked_Fasting" formControlName="isVulnerable"> Vulnerable
                        </mat-checkbox>
                    </span>

                    <span class="space-around">
                       <mat-checkbox value="isChecked_Pregnant" formControlName="isPregnant"> Pregnant</mat-checkbox>
                    </span>
                </div>

                <div class="top-style">
                    <div class="form-row">
                        <div class="row-field-1">
                            <div class="example-form">
                                <mat-label class="ovi-lbl-text-size font-styl">VIP</mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <mat-select formControlName="vipTypeId">
                                        <mat-option value='null'>None</mat-option>
                                        <mat-option *ngFor="let data of vipList" [value]="data.code">{{data.value}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                        </div>
                        <div class="row-field-1">
                            <div class="example-form">
                                <mat-label class="ovi-lbl-text-size font-styl">Preferred Language</mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <mat-select placeholder="Language" formControlName="preferredLanguage">
                                        <mat-option *ngFor="let data of languageList" [value]="data.code">{{data.value}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="form-row">
                        <div i18n class="ovi-popup-title-text-size top-styl">TAG</div>
                    </div>
                    <mat-divider class="divider"></mat-divider>

                    <div class="form-row">
                        <div class="row-field-1">
                            <div class="example-form">
                                <div style="margin-left: -10px;">
                                    <mat-radio-group class="example-radio-group" formControlName="coster">
                                        <mat-radio-button (click)="isEnableCoster('coaster')" i18n value="coaster"
                                            [checked]='true'>Coaster</mat-radio-button>&nbsp;
                                        <mat-radio-button (click)="isEnableCoster('mobile')" i18n value="mobile">Mobile
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <mat-form-field *ngIf="isEnableCosterDropdown === true"
                                    class="example-full-width form-field" appearance="outline">
                                    <input matInput placeholder="Coaster ID" appTypeHit id="coasterId" (typeHit)="searchToCoster($event)"
                                        [matAutocomplete]="autoFrom" formControlName="tagSerialNumber">
                                    <mat-autocomplete autoActiveFirstOption #autoFrom="matAutocomplete">
                                        <mat-option *ngFor="let option of searchCosterlist" [value]="option.tagId">
                                            <div class="tot-wid-styl" (click)="getTagTypeId(option.tagTypeId)">{{option.tagId}}</div>

                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="enrollPatientForm.controls.tagSerialNumber.invalid">
                                        <span i18n
                                            [hidden]="!enrollPatientForm.controls['tagSerialNumber'].errors.required">
                                            Coaster is required.</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row-field-1">
                            <div class="example-form">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="input-footer">
            <button i18n mat-button class="cancle-btn" mat-dialog-close>Cancel</button>

            <button i18n mat-button class="active-btn margin-r-1" *ngIf="!data.patientId" appDebounceClick
                [disabled]="enrollPatientForm.invalid || isDisabled" (debounceClick)="saveRegisterPatient()"
                cdkFocusInitial [debounceTime]="500">Enroll</button>
            <button i18n mat-button class="active-btn margin-r-1" *ngIf="data.patientId && data.enrollOT" appDebounceClick
                [disabled]="enrollPatientForm.invalid" (debounceClick)="saveRegisterPatient()" cdkFocusInitial
                [debounceTime]="500">Enroll</button>
            <button *ngIf="data.patientId && !data.enrollOT && activate_btn && activate_btn.indexOf('BT_ENUP') > -1" i18n mat-button
                class="active-btn margin-r-1" appDebounceClick [disabled]="enrollPatientForm.invalid" (debounceClick)="updateRegisterPatient()"
                cdkFocusInitial [debounceTime]="500">Update</button>
        </div>
    </div>
</div>
