<div class="ovi-font-family" style="height: calc(100% - 9px);">
    <mat-toolbar class="input-header">
        <div i18n="@@locFacilityTiltle" class="header-name" *ngIf ="data">
            Modify {code, select, 1 {Customer} 2 {Region} 3 {Facility}}</div>
        <div class="header-close"><button mat-icon-button mat-dialog-close class="close-button ie-close-icon"><mat-icon class="icon-style">highlight_off</mat-icon></button></div> 
    </mat-toolbar>
    <div class="mat-custom-form-field-box  popup-margin" style="overflow: hidden; height: calc(100% - 100px);">
        <mat-tab-group  [formGroup]="customerForm" style="height: 100%;"> 
        <mat-tab i18n-label label="Create Customer">
        <div  class="mat-custom-form-field-box" style="overflow: hidden;">
        <!-- <mat-tab-group class="space" (selectedTabChange)="tabClick($event)">
            <mat-tab i18n-label label="Basic Details"> -->
            <div class="form-body-edi-cust bdy-frm mat-custom-form-field-box">
                <div  class="form-row block-3">
                    <div class="row-field-ecf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">{code, select, 1 {Customer Name} 2 {Region Name} 3 {Facility Name}}</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input *ngIf="code === '1'" matInput i18n-placeholder placeholder="Customer Name"  formControlName="name" [errorStateMatcher]="matcher" autofocus autocomplete="off">
                                <input *ngIf="code === '2'" matInput i18n-placeholder placeholder="Region Name"  formControlName="name" [errorStateMatcher]="matcher" autofocus autocomplete="off">
                                <input *ngIf="code === '3'" matInput i18n-placeholder placeholder="Facility Name"  formControlName="name" [errorStateMatcher]="matcher" autofocus autocomplete="off">
                                <mat-error i18n  *ngIf="customerForm.controls.name.invalid">
                                        {code, select, 1 {Customer} 2 {Region} 3 {Facility}} name is required. (Limit 3-64 letters)
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- <div   class="row-field-ecf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Status</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <mat-select i18n-placeholder placeholder="Status" formControlName="isActive">
                                    <mat-option i18n value=true>Active</mat-option>
                                    <mat-option i18n value=false>Inactive</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div> -->
                    <div   class="row-field-ecf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Select Type</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                  <mat-select i18n-placeholder placeholder="Select Type" formControlName="solutionTypeId">
                                    <mat-option *ngFor="let TypeList of TypeList" [value]="TypeList.code">
                                        {{TypeList.value}}
                                    </mat-option>
                                </mat-select>
                                <!-- <mat-select i18n-placeholder placeholder="Select Type" formControlName="isActive">
                                    <mat-option i18n value=true>Active</mat-option>
                                    <mat-option i18n value=false>Inactive</mat-option>
                                </mat-select> -->
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div   class="form-row block-3">
                    <div   class="row-field-ecf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">No of Beds</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input matInput i18n-placeholder placeholder="No of Beds" formControlName="noOfBeds" autocomplete="off">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field-ccf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">street</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input  matInput i18n-placeholder placeholder="street" formControlName="line1" 
                                autocomplete="off" role="presentation">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field-ccf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Town/village</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input  matInput i18n-placeholder placeholder="Town/village" formControlName="line2" 
                                autocomplete="off" role="presentation">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field-ccf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">City/District</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input  matInput i18n-placeholder placeholder="city" formControlName="line3" 
                                autocomplete="off" role="presentation">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field-ccf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">State</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input matInput i18n-placeholder placeholder="State" formControlName="city" 
                                autocomplete="off" role="presentation">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field-ccf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Country</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input matInput i18n-placeholder placeholder="Country" formControlName="country" 
                                autocomplete="off" role="presentation">
                            </mat-form-field>
                        </div>
                    </div>
                      <div class="row-field-ccf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Pin Code</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input  matInput i18n-placeholder placeholder="Pin Code" formControlName="zip" 
                                autocomplete="off" role="presentation">
                            </mat-form-field>
                        </div>
                    </div>
                    <div  class="row-field-ccf" >
                        <div class="example-form">
                              <mat-label class="ovi-lbl-text-size">GST Number</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input matInput i18n-placeholder placeholder="GST Number" formControlName="gsTin" autocomplete="off">
                            </mat-form-field>
                        </div>
                    </div>
                    <div  class="row-field-ccf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">About Hospital</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <textarea  matInput i18n-placeholder placeholder="About Hospital" formControlName="comments" 
                                autocomplete="off" role="presentation"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div   class="row-field-ccf">
                        <div class="example-form">
                            <div class="img-prev" >
                                <div class="img-prev_1">
                                    <label i18n mat-button class="ovi-lbl-text-size"> 
                                        Upload Logo Image
                                        <input type="file" id="File" accept="image/*" (change)="uploadImage($event)" class="disp-none" #input> 
                                    </label>
                                </div>
                            </div>
                            <div class="img-prev_3">
                                <img *ngIf="logoImage" [src]="logoImage" alt="" class="img-prev_2">
                            </div>
                        </div>
                    </div>
                
                    <!-- <div class="row-field-ecf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Registration Address</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <textarea  matInput i18n-placeholder placeholder="Registration Address" formControlName="regAddress" ></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div  class="row-field-ecf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Billing Address</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <textarea matInput i18n-placeholder placeholder="Billing Address" formControlName="billAddress"></textarea>
                            </mat-form-field>
                        </div>
                    </div> -->
                </div>
                <!-- <div  class="form-row block-3" *ngIf = "code=='1'"> -->
                    <!-- <div  class="row-field-ecf">
                        <div class="example-form">
                            <mat-label class="ovi-lbl-text-size">GST Number</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input matInput i18n-placeholder placeholder="GST Number" formControlName="gsTin" autocomplete="off">
                            </mat-form-field>
                        </div>
                    </div> -->
                    <!-- <div  class="row-field-ecf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">License Pack</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input matInput i18n-placeholder placeholder="License Pack" formControlName="licencePack"  autocomplete="off">
                            </mat-form-field>
                        </div>
                    </div> -->
                <!-- </div> -->
                
                <!-- <div   class="form-row  block-3">
                    <div  class="row-field-ecf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">No of Employee</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input matInput i18n-placeholder placeholder="No of Employee" formControlName="noOfEmployees"  autocomplete="off">
                            </mat-form-field>
                        </div>
                    </div>
                    <div  class="row-field-ecf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">No of Devices</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input matInput i18n-placeholder placeholder="No of Devices" formControlName="noOfDevices"  autocomplete="off">
                            </mat-form-field>
                        </div>
                    </div>
                </div> -->
                <!-- <div  class="form-row block-3">
                    <div   class="row-field-ecf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">No of Beds</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <input matInput i18n-placeholder placeholder="No of Beds" formControlName="noOfBeds" autocomplete="off">
                            </mat-form-field>
                        </div>
                    </div>
                    <div  class="row-field-ecf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Multi Campus</mat-label>
                            <mat-form-field class="example-full-width form-field"appearance="outline">
                                <mat-select i18n-placeholder placeholder="Multi Campus" formControlName="isMulti">
                                    <mat-option i18n value=true>Yes</mat-option>
                                    <mat-option i18n value=false>No</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div> -->
                
                <!-- <div  class="form-row block-3">
                    <div   class="row-field-ecf">
                        <div class="example-form">
                            <div class="img-prev" >
                                <div class="img-prev_1">
                                    <label i18n mat-button class="ovi-lbl-text-size"> 
                                        Upload Logo Image
                                        <input type="file" id="File" accept="image/*" (change)="uploadImage($event)" class="disp-none" #input> 
                                    </label>
                                </div>
                            </div>
                            <div class="img-prev_3">
                                <img *ngIf="logoImage" [src]="logoImage" alt="" class="img-prev_2">
                            </div>
                        </div>
                    </div> 
                     <div  class="row-field-ecf">
                        <div class="example-form">
                            <div class="img-prev" >
                                <div  class="logoBtn img-prev_1">
                                    <label i18n mat-button class="ovi-lbl-text-size"> 
                                        Upload Background Image
                                        <input type="file" id="File" accept="image/*" (change)="uploadBgImage($event)" class="disp-none" #input> 
                                    </label>
                                </div>
                            </div>
                            <div class="img-prev_3">
                                <img *ngIf="bgImage" [src]="bgImage" alt="" class="img-prev_2">
                            </div>
                        </div>
                    </div> 
                </div>-->
                <div formArrayName="contacts" *ngIf="!data">
                    <div *ngFor="let contact of customerForm.controls.contacts.controls; let i=index">
                    <mat-divider *ngIf="customerForm.controls.contacts.controls.length > 1 && i > 0" >
                    </mat-divider>
                    <br>
                        <div [formGroupName]="i" >
                        
                            <div  class="form-row block-3">
                                <div  class="row-field-ecf">
                                    <div class="example-form">
                                        <mat-label i18n class="ovi-lbl-text-size">Contact name</mat-label>
                                        <mat-form-field class="example-full-width form-field">
                                            <input matInput i18n-placeholder placeholder="Contact name" formControlName="contactName" autocomplete="off" >
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div  class="row-field-ecf">
                                    <div class="example-form">
                                        <mat-label i18n class="ovi-lbl-text-size">email</mat-label>
                                        <mat-form-field class="example-full-width form-field"appearance="outline">
                                            <input matInput i18n-placeholder placeholder="Email" formControlName="email" autocomplete="off">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div  class="form-row block-3">
                                <div  class="row-field-ecf">
                                    <div class="example-form">
                                        <mat-label i18n class="ovi-lbl-text-size">Phone Number</mat-label>
                                        <mat-form-field class="example-full-width form-field"appearance="outline">
                                            <input matInput i18n-placeholder placeholder="Phone Number" formControlName="phoneNumber" autocomplete="off">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div   class="row-field-ecf">
                                    <div class="example-form">
                                        <mat-label i18n class="ovi-lbl-text-size">Designation</mat-label>
                                        <mat-form-field class="example-full-width form-field"appearance="outline">
                                            <input matInput i18n-placeholder placeholder="Designation" autocomplete="off">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div   class="form-row block-3">
                                <div   class="row-field-ecf">
                                    <div class="example-form">
                                        <mat-label i18n class="ovi-lbl-text-size">Comment</mat-label>
                                        <mat-form-field class="example-full-width form-field"appearance="outline">
                                            <input matInput i18n-placeholder placeholder="Comment" formControlName="comment" autocomplete="off">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="form-row">
                        <button i18n mat-stroked-button *ngIf="customerForm.controls.contacts.controls.length > 1"  class="del-btn-styl" (click)="removeContact(i)"> Delete Contact</button>
                        <button i18n mat-stroked-button  class="del-btn-styl" (click)="addContact()">Add more contact</button>
                    </div>
                </div>            
            </div>
            <!-- </mat-tab> -->
            <!-- <mat-tab i18n-label label="Customer Role"  *ngIf="code === '3'">
            <div style="height: 460px; overflow: auto;">
                <div style="height: 15%;margin-top: 8px;">
                <div   class="form-row block-3">
                    <div   class="row-field">
                        <div class="example-form">
                            <mat-form-field class="example-full-width form-field m-top-1" appearance="fill">
                                <mat-label i18n class="ovi-lbl-text-size">Select Role</mat-label>
                                <mat-select multiple [formControl]="selectedRole" (selectionChange)="roleSelection($event.value)">
                                <mat-option *ngFor="let role of roleList" [value]="role.name">{{role.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                </div>
                <div class="customer-roletable">
                    <mat-table #table [dataSource]="dataSourceIdentifier" *ngIf="isShowTable" class="mat mat-tbl-styl" style="height: 100%; overflow:auto;min-width:450px;">
                        <ng-container matColumnDef="id">
                            <mat-header-cell i18n *matHeaderCellDef class="mat-sort-header-button cus-tab-style">
                                Role ID
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="tbl-width">
                                {{element.roleId}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <mat-header-cell i18n *matHeaderCellDef class="mat-sort-header-button cus-tab-style">
                                Name
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element" class="tbl-width">
                                {{element.roleName}}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <mat-header-cell i18n *matHeaderCellDef class="mat-sort-header-button cus-tab-style">
                                Status
                            </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="tbl-width" [ngStyle]="{'color': element.status == 'Exists' ? '#255eff' : element.status == 'Add' ? '#0bc70b' : 'red'}" style="font-weight: bold;">
                                    {{element.status}}
                                </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="cusDisplayedColumns; sticky: true" class="manage-table-header-row">
                        </mat-header-row>
                        <mat-row *matRowDef="let row; columns: cusDisplayedColumns;">
                        </mat-row>
                    </mat-table>
                </div>
                <div style="height: 8%; float: right;margin-top: 15px; margin-right: 16px;">
                    <button i18n mat-button class="active-btn" *ngIf="saveCus" (click)="saveRoles()">Save</button>
                </div>
            </div>
            </mat-tab> -->
        <!-- </mat-tab-group> -->
        </div>
    </mat-tab>
        <mat-tab i18n-label label="Additional Info" *ngIf="code=='3' ">
            <div class="mat-custom-form-field-box">
                <div class="form-row block-3">
                    <div class="row-field-ccf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Building Location</mat-label>
                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                <input matInput i18n-placeholder placeholder="Map Location" #name="matInput" formControlName="buildingLocation"
                                    [errorStateMatcher]="matcher" autofocus autocomplete="off"  >
                                <img style="width:30px;cursor: pointer;" matSuffix  (click)="getLocation($event,'Hospital')"
                                    src="/assets/Alert/common_icons/google-map.svg">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field-ccf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Parking Facility</mat-label>
                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                <mat-select i18n-placeholder placeholder="Valet/Multi Level/Car/Bike etc." formControlName="parkingFacilityform" multiple>
                                    <mat-option *ngFor="let facility of parkingFacilitylist" [value]="facility.code">
                                        {{facility.value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row-field-ccf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Accessibility</mat-label>
                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                <mat-select i18n-placeholder placeholder="Wheel Chair/Ramp/Lift etc"  formControlName="accessbilityform" multiple>
                                    <mat-option *ngFor="let access of accessbilitylist" [value]="access.code">
                                        {{access.value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-field-ccf">
                        <div class="example-form">
                            <mat-label i18n class="ovi-lbl-text-size">Parking Location</mat-label>
                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                <input matInput i18n-placeholder placeholder="Select Parking location"  autocomplete="off" formControlName="parkingLocation"
                                    role="presentation">
                                    <img style="width:30px;cursor: pointer;" matSuffix  (click)="getLocation($event,'Parking')"
                                    src="/assets/Alert/common_icons/google-map.svg">
                            </mat-form-field>
                        </div>
                    </div>     
                <div style="width: 100%;">
                    <div class="example-form">
                        <div class="img-prev">
                            <div class="img-prev_1 ">
                                <label i18n mat-button class="ovi-lbl-text-size">
                                    Upload Building Image
                                    <input type="file" multiple (change)="detectFiles($event)" multiple>
                                </label>
                            </div>
                            <div class="img-prev_4" *ngFor="let imgObj of uploadedFile;let index = index">
                                <img [src]="imgObj.fileUrl" [alt]="" class="img-prev_2">
                                <span class='material-icons' (click)="deleteAttachmentInTicket(imgObj, index)"
                                    style=" font-size: 20px;position: relative;bottom: 32px;right: 23px;color: red;cursor: pointer;">highlight_off</span>
                        </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
    </div>
    <div class="input-footer">
        <button i18n mat-button *ngIf="selectedTab == 0" class="cancle-btn" mat-dialog-close>Cancel</button>
        <button i18n mat-button *ngIf="!data" class="active-btn margin-r-1" appDebounceClick [disabled]="customerForm.invalid || isDisabled"
          (debounceClick)="saveCustomer()" [debounceTime]="300" cdkFocusInitial>Create</button>
        <button i18n  mat-button *ngIf="data && selectedTab == 0" class="active-btn margin-r-1" appDebounceClick [disabled]="customerForm.invalid || isDisabled"
          (debounceClick)="editUser(data)" [debounceTime]="300" cdkFocusInitial>Save</button>
    </div>
</div>    
