import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { locale_Json_Details } from '../../../../../localeJson/localeJson';

@Component({
  selector: 'app-status-tracking',
  templateUrl: './status-tracking.component.html',
  styleUrls: ['./status-tracking.component.scss']
})
export class StatusTrackingComponent implements OnInit {
  @Input() statusDetails;
  @Input() trackLineData;
  @Input() attachment;
  public tktHistoryData: any;
  openLabeltext: string;
  updateLabeltext: string;
  // public trackLineData: any;
  

  constructor(public dialog: MatDialog,@Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.tktHistoryData = this.statusDetails;

    const locale = localStorage.getItem(btoa('lang'))
      if(locale === 'en-US' || locale === 'en'){
        this.openLabeltext = locale_Json_Details.en.ticketCreater;
      } else if(locale === 'ar'){
        this.openLabeltext = locale_Json_Details.ar.ticketCreater;
      }

      if(locale === 'en-US' || locale === 'en'){
        this.updateLabeltext = locale_Json_Details.en.ticketUpdated;
      } else if(locale === 'ar'){
        this.updateLabeltext = locale_Json_Details.ar.ticketUpdated;
    }
  }

  lightboxView(data,type){
    let key = { 'url': data, 'type': type}
    const dialogRef = this.dialog.open(LightboxOnlineMenuComponent,
      { maxWidth: '100vw', width: '100vw', height: '100vh', data: key, panelClass: 'custom-preview-dialog-container', disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}

@Component({
  selector: 'lightbox-dialog',
  templateUrl: 'lightbox-dialog-component.html',
})
export class LightboxOnlineMenuComponent implements OnInit {
  urlSafe: SafeResourceUrl;
  isDownload: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  }

  downloadPreview(){
    this.isDownload = true;
    setTimeout(() =>  this.isDownload = false, 100);
  }
}
