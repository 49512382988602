/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { connect } from 'mqtt';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as Crypto from 'crypto-js';
import {encode} from 'js-base64';
import { Router } from '@angular/router';
import {ToastrService} from 'ngx-toastr'

@Injectable()
export class CommonService {
  public tokenEnrollFlow = JSON.parse(localStorage.getItem(btoa('tokenEnrollFlow')));
  public menuCode=null;
  public _clientMqtt: string;
  public contextChanged = false;
  public floorMap = false;
  public changeAlert = false;
  public changeSummary = false;
  public changeNotify = false;
  public interval;
  public audio = new Audio();
  status: Array<string> = [];
  private _client: any;
  public userPreference = null;
  // public cloudConnect: any = environment.cloudNetwork;
  // public cloudConnect: any = JSON.parse(localStorage.getItem(btoa('mqtt')));
  facilityId = localStorage.getItem(btoa('facilityId'));
  private alertNotify = new BehaviorSubject([])
  private notifySub = new BehaviorSubject([])
  currentMessage = this.alertNotify.asObservable()
  notifyMsg = this.notifySub.asObservable()
  private dashboardLayout = new BehaviorSubject([])
  layout = this.dashboardLayout.asObservable()
  private dashboardId = new BehaviorSubject([])
  dashboard = this.dashboardId.asObservable() 

  constructor(public toastr: ToastrService, public http: HttpClient,
    private apiService: ApiService, private router: Router) {
  }
  cancelRequests() {
    this.apiService.cancelRequests()
  }  

  alertNotification(message) {
    this.alertNotify.next(message)
  }
  notificationMsg(message) {
    this.notifySub.next(message)
  }
  setLayout(message) {
    this.dashboardLayout.next(message)
  }
  setDashboard(message) {
    this.dashboardId.next(message)
  }
  encryptData(data) {
    let result = data
    if(environment.pwd_check) {
      let txt1 = encode(environment.env_key);
      let txt2 = encode(environment.ck);
      let ek = txt1 + txt2 + environment.base_value.lk;
      ek = ek.slice(0,32);
      result = Crypto.AES.encrypt(data, Crypto.enc.Utf8.parse(ek), {
        mode: Crypto.mode.ECB,
        padding: Crypto.pad.Pkcs7
      }).toString(Crypto.Hex);
    }
    return result
  }
  firstTime: boolean = true;
  playAudio(src, volume, loop) {
    if (!this.audio.ended && !this.firstTime) {
      return;
    }
    this.firstTime = false;
    this.audio.src = src;
    this.audio.volume = volume;
    this.audio.load();
    this.audio.play();
    this.audio.loop=loop;
  }
  playAudioCode(srcCode, volumeCode, loopCode) {
    //src Code AUD-F1
    this.audio.src = "assets/audio/" + srcCode + ".wav";
    //volume Code AUD-V1
    const vol = volumeCode.replace("AUD-V", '');
    this.audio.volume = vol / 5;
    //loop Code  AUD-L1 
    this.audio.loop = loopCode === 'AUD-L1';
    this.audio.play();
  }
 stopAudio() {
      this.audio.loop=false;
      // this.audio.pause();
    }
  mqttConnect() {
    if (environment.env_key === 'prod') {
      // this._client = connect(environment.cloudNetwork[localStorage.getItem(btoa('facilityId'))]);
      // this._client = connect(JSON.parse(localStorage.getItem(btoa('mqtt'))));
      this._client = connect(JSON.parse(this._clientMqtt));
    } else {
      // this._client = connect(environment.cloudNetwork);
      // this._client = connect(JSON.parse(localStorage.getItem(btoa('mqtt'))));
      this._client = connect(JSON.parse(this._clientMqtt));
      return this._client;
    }
  }
  mqttPublish(msg, topic) {
    this._client.on('connect', () => {
      console.log(this._client);
      this._client.publish(topic, msg);
      this.interval = setInterval(val => this.disconnectClient(), 3000);
    });
  }
  disconnectClient() {
    // console.log(this._client)
    this._client.end(true);
    // console.log(this._client)
    clearInterval(this.interval);
  }

  // Component: Mainmenu
  getMenu() {
    return this.getActivePermission('main_menu');
  }
  getMenuItems() {
    this.apiService.get(environment.base_value.menu_item_list)
      .toPromise()
      .then(res => {
        localStorage.setItem(btoa('menuItems'), JSON.stringify(res.results));
      })
      .catch(err => { console.log(err); });
  }
  getMQTTDetails() {
    // return this.apiService.get(environment.base_value.config_url + '/' + 'mqtt-' + environment.env_key);
    this.apiService.get(environment.base_value.config_url + '/' + 'mqtt-' + environment.env_key)
      .toPromise()
      .then(res => {
        if(res.results) {
          this._clientMqtt = JSON.stringify(res.results.contentObject);
        }
        // localStorage.setItem(btoa('mqtt'), JSON.stringify(res.results.contentObject));
      })
      .catch(err => { console.log(err); });
  }

  getmqttBroker() {
    return this.apiService.get(environment.base_value.mqtt_gateway_broker);   
  }
  // Component: Toolbar
  getSearchData(key: string, searchType: string) {
    if (searchType === 'Today') {
      return this.apiService.get(environment.base_value.global_search_today + '/' + key);
    } else {
      return this.apiService.get(environment.base_value.search_all_details + '/' + key);
    }
  }
  getSearchDataWithKey(name, key){
    if(key != ''){
      return this.apiService.get(environment.base_value.global_search_by_key + '?name=' + name + '&filterBy=' + key + '&pageStart=1&pageSize=10')
    }
    return this.apiService.get(environment.base_value.global_search_by_key + '?name=' + name + '&pageStart=1&pageSize=10')
  }
  // Server Sent Event
  // Component: Toolbar Notification
  observeMessages(eventUrl: string): Observable<string> {
    return new Observable<string>(observer => {
      const eventsource = new EventSource(eventUrl + '?facilityId=' + this.facilityId);
      eventsource.addEventListener('message', (event: MessageEvent) => { // 'MessageEvent' is nothing but event type
        observer.next(event.data);
      });
    });
  }
  // getNotify() {
    // return this.apiService.get(environment.base_value.alert_api+'?isForCurrentUser=true&isActive=true');
  // }
  ackAlertById(data) {
    return this.apiService.put(environment.base_value.ack_alert, data);
  }
  assignAlertById(data) {
    return this.apiService.post(environment.base_value.assign_alert, data);
  }
  // getAlertbyId(type, id) {
    // return this.apiService.get(environment.base_value.alert_api + '?identifyingId='+id+ '&identifyingType=' + type);
  // }
  cancelAlert(data) {
    return this.apiService.put(environment.base_value.close_alert_api, data);
  }
  dischargePatient(data) {
    return this.apiService.post(environment.base_value.ip_discharge_patient, data);
  }

  // To get the Current User
  // Component: User Menu
  getCurrentUser() {
    return this.apiService.get(environment.base_value.get_current_user);
  }
  getUserLocationById(id) {
    return this.apiService.get(environment.base_value.get_user_by_id + '?userId=' + id);
  }
  // Component: Login
  public login(postData) {
    return this.apiService.post(environment.base_value.user_login, postData);
  }
  forgetPassword(username: string) {
    return this.apiService.get(environment.base_value.forget_password + '?username=' + username);
  }
  resetPassword(data) {
    return this.apiService.put(environment.base_value.reset_password, data);
  }
  changePassword(data) {
    return this.apiService.post(environment.base_value.change_password, data);
  }
  public basicLogin(postData) {
    return this.apiService.post(environment.base_value.basic_login, postData);
  }
  infantAuthorized(data) {
    return this.apiService.post(environment.base_value.infant_event, data);
  }
  // Component: App
  public loginToken(postData) {
    return this.apiService.post(environment.base_value.login_token, postData);
  }

  // Location tracking master set boundary
  

  // Component: Dashboard-hc, Dashboard-ip, Dashboard-op
  // Entry Component: Manage Widget
  getPermissionDetails(key: string) {
    return [
      {
        'user':
        {
          'username': 'Venkatesh',
          'role': 'admin'
        },
        'token':
        {
          'access_toekn': '',
          'refresh_token': '',
          'token_id': ''
        },
        'permission':
        {
          'menu':
            [
              'MN_DB',
              'MN_HP',
              'MN_CF',
              'MN_RE',
              'MN_OT',
              'MN_HPMF',
              'MN_HPMU',
              'MN_HPML',
              'MN_HPBL',
              'MN_HPCS',
              'MN_HPMP',
              'MN_HPIS',
              'MN_CFGW',
              // "MN_CFR",
              // "MN_CFT",
              // "MN_CFA",
              'MN_CFRR',
              'MN_CFTG',
              'MN_CFAS',
              'MN_CFRS',
              'MN_CFAC',
              'MN_CFHK',
              'MN_OTPM',
              'MN_OTAM',
              'MN_OTIP',
              'MN_OTOP',
              'MN_OTIF',
              'MN_OTT',
              'MN_REEM',
              // "MN_RERR",
              'MN_REPT',
              'MN_RESF',
              'MN_REEN',
              'MN_REAS',
              'MN_REMN'
            ],
          'widget':
            [
              { 'id': 'WD_DBFP', 'CS': 'OP', 'description': 'Floor Plan' },
              { 'id': 'WD_DBE', 'CS': 'OP', 'description': 'Events' },
              { 'id': 'WD_DBPC', 'CS': 'OP', 'description': 'Patient Count' },
              { 'id': 'WD_DBACT', 'CS': 'OP', 'description': 'Avg. wait time to provider' },
              { 'id': 'WD_DBAWT', 'CS': 'OP', 'description': 'Average Wait Time' },
              //  { 'id':"WD_DBPR", 'CS': 'OP', 'description':"Patient Ratio" },
              //  { 'id':"WD_DBOPV", 'CS': 'OP', 'description':"Outpatient Visit"},
              //  { 'id':"WD_DBPTAT", 'CS': 'OP', 'description':"Patient Turn Around Time"},
              { 'id': 'WD_DBPVC', 'CS': 'OP', 'description': 'Patient Visit Count' },
              { 'id': 'WD_DBPIT', 'CS': 'OP', 'description': 'Patient In Time' },
              { 'id': 'WD_DBPS', 'CS': 'OP', 'description': 'Patient Status' },
              { 'id': 'WD_DBFP', 'CS': 'IP', 'description': 'Floor Plan' },
              { 'id': 'WD_DBE', 'CS': 'IP', 'description': 'Events' },
              { 'id': 'WD_DBBOS', 'CS': 'IP', 'description': 'Bed occupancy status' },
              { 'id': 'WD_DBACT', 'CS': 'IP', 'description': 'Avg. wait time to provider' },
              { 'id': 'WD_DBAS', 'CS': 'IP', 'description': 'Asset status' },
              { 'id': 'WD_DBIPAWT', 'CS': 'IP', 'description': 'Inpatient Average Waiting Time' }
            ],
          'button':
            [
              'BT_OTPMC',
              'BT_OTPME',
              'BT_OTPMD',
              'BT_OTAMC',
              'BT_OTAME',
              'BT_HPMFC',
              'BT_HPMFE',
              'BT_HPMUC',
              'BT_HPMUE',
              'BT_HPMPC',
              'BT_HPMPE',
              'BT_HPMLC',
              'BT_HPMLE',
              'BT_HPISDR',
              'BT_HPISUR',
              'BT_HPISDP',
              'BT_HPISUP',
              'BT_HPISDT',
              'BT_HPISUT',
              'BT_HPISDA',
              'BT_HPISUA',
              'BT_HPISDG',
              'BT_HPISUG',
              'BT_HPISDU',
              'BT_HPISUU',
              'BT_CFGWC',
              'BT_CFGWE',
              'BT_CFTC',
              'BT_CFTE',
              'BT_CFRC',
              'BT_CFRE',
              'BT_CFAC',
              'BT_CFAE',
              'BT_MCFE', // MANAGE CONTROL
              'BT_MCRE',
              'BT_MCCE',
            ],
          'list':
            [
              'LS_CFGW',
              'LS_CFTG',
              'LS_CFRE',
              'LS_CFAS',
              'LS_HPMF',
              'LS_HPMU',
              'LS_HPML',
              'LS_HPIS',
              'LS_HPBL',
              'LS_HPCS',
              'LS_OTPM',
              'LS_OTAS',
              'LS_OTIP',
              'LS_OTOP',
              'LS_OTIF',
              'LS_DBWD',
              'LS_REEM',
              'LS_RERR'
            ]
        }
      }
    ];
  }
  // Used in ALL
  getActivePermission(permission_ctrl: string) {
    let active_menu_list = [];
    let common_menu_list: any;

    // let get_permission_details = this.getPermissionDetails(localStorage.getItem(btoa('current_user'))); // Y3VycmVudF91c2Vy current_user
    // let active_menu = get_permission_details[0].permission.menu;
    // let active_btn = get_permission_details[0].permission.button;

    const permission = JSON.parse(localStorage.getItem('permission'));
    if(permission) {
    const active_menu = permission.menuItems;
    const active_btn = permission.button;

    // get sub menu list for ovitag modules

    const sub_menu_items = JSON.parse(localStorage.getItem(btoa('menuItems')));

    if (permission_ctrl === 'main_menu') {
      common_menu_list = active_menu;
      if (parseInt(localStorage.getItem('userlevel')) === 8) {
        for (let m = 0; m < common_menu_list.length; m++) {
          if (common_menu_list[m].name === 'Hospital') {
            common_menu_list[m].name = 'Organization';
          }
        }
      }
      if (parseInt(localStorage.getItem('userlevel')) === 10) {
        for (let m = 0; m < common_menu_list.length; m++) {
          if (common_menu_list[m].name === 'Hospital') {
            common_menu_list[m].name = 'Facility';
          }
        }
      }
    }

    if (permission_ctrl === 'button') {
      active_menu_list = active_btn;
    } else {
      // active_menu_list = common_menu_list
      active_menu_list = common_menu_list;
    }

    return active_menu_list;
    } else {
      localStorage.clear();
      this.router.navigate(['/login']);
      this.toastr.warning (
        '<span style=\'font-family:Open Sans;font-size:16px;\'>' + environment.base_value.auth_card_error_msg + '</span>',
        null, {timeOut: 3600, closeButton: true, enableHtml: true}
      );
      return [];
    }
  }

  getPermissionbyId(roleId, userId) {
    return this.apiService.get(environment.base_value.permission_by_id  + '/' + roleId +'?userId='+ userId);
  }
  // Component: Message Centre
  getPatientList(tagtype, typeid) {
    return this.apiService.get(environment.base_value.tag_management_filter1 + '?tagAssociationType=' + tagtype + '&tagTypeId=' + typeid);
  }
  getCoasterResponse() {
    return this.apiService.get(environment.base_value.get_coaster_response + '/' + 'CoasterResponse');
  }
  saveMessage(savedData) {
    return this.apiService.post(environment.base_value.save_message, savedData);
  }
  getCoasterMessage() {
    return this.apiService.get(environment.base_value.get_coaster_message);
  }

  getRoleList() {
    return this.apiService.get(environment.base_value.get_role_list);
  }
  getAllRoleList() {
    return this.apiService.get(environment.base_value.get_role_list1);
  }
  getAllTag() {
    return this.apiService.get(environment.base_value.get_all_tag);
  }
  getRandomColor() {
    // let color = Math.floor(0x1000000 * Math.random()).toString(16); // method 1 anycolor
    // return '#' + ('000000' + color).slice(-6);
    
    // let color = "hsl(" + Math.random() * 360 + ", 100%, 75%)"; // method 2 opacity restricted
    // return color;
    
    let letters = 'BCDEF'.split(''); // method3 color code restricted
    let color = '#';
    for (let i = 0; i < 6; i++ ) {
        color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  }
  // Componenet: Dashboard-hc, Report >> Employee-report, Porter-report, Staff-report
  getReportData(id, param) {
    if (id != 'roll-call' && id != 'staff-roll-call') {
      let reportName = environment.base_value.baseUrl + id;
      let url = reportName + '/0/0/' + localStorage.getItem(btoa('facilityId')) + param;
      return this.apiService.get(url);
    } else {
      let reportName = environment.base_value.baseUrl + id;
      let url = reportName + '/0/0/' + param;
      return this.apiService.get(url);
    }
  }
  

  // Component: Location Mapping
  // Entry Component: Porter Request
  getLocationSearch(id) {
    return this.apiService.get(environment.base_value.get_to_location_search + '/' + id);
  }


 
  // Component: Reader, Location Management, Common Map, Floor Map, Health Checkup
  // To publish in MQTT [ this service to be called from Reader / Tags & Location Management ]
  sendMessage(context, cloud, type) {

    let publishData: any = null;
    let publishTopic: any = null;

    if (type === 'CACHE') {
      publishTopic = environment.base_value.topic_gw_cache;
      publishData = '{"fid" : "' + localStorage.getItem(btoa('facilityId')) + '", "ctx": "' + context + '", "typ": "CACHE"}';
    } else {
      publishTopic = environment.base_value.topic_sw_update;
      publishData = context;
    }

    if (environment.env_key === 'prod') {
      // this._client = connect(cloud[localStorage.getItem(btoa('facilityId'))]);
      this._client = connect(cloud);
    } else {
      this._client = connect(cloud);
    }

    this._client.on('connect', () => {
      // console.log(this._client);
      if (environment.env_key === 'dev') {
        console.log(publishData);
      }
      this._client.publish(publishTopic, publishData);
      this.interval = setInterval(val => this.disconnectClient(), 3000);
    });
  }
  // Entry Component: Common-map, Floor-map
 
  // used in both Reader, Location Management, Health Checkup, Monitor
  getShortestPath(src, dist, floor) {
    return this.apiService.get(environment.base_value.get_shortestpath + localStorage.getItem(btoa('facilityId')) + '/srclid=' + src + '&tarlid=' + dist + '&flr=' + floor);
  }

  // used in both Reader, Location Management, Common-map, Floor-map
  getAllLocationById(id) {
    return this.apiService.get(environment.base_value.get_all_location_by_id + '/' + id);
  }

  // Component: Asset, Support Ticket
  getEnumTerms(FileType) {
    return this.apiService.get(environment.base_value.enum_terms + '/' + FileType);
  }

  //Component: Resource, Support Ticket, User Management
  getAllRole() {
    return this.apiService.get(environment.base_value.get_role);
  }

  // Component: Health Checkup, Package, Worklist
  // Entry Component: Edit Daily Management, Enroll Patient, Confirmation Dialog, Common Search, Patient
  // used in both Health Checkup and Package
  getPackageHealthPlan() {
    return this.apiService.get(environment.base_value.get_ot_health_plan);
  }
  
  // used in both Worklist, Health Checkup, Edit Daily Management, Enroll Patient, Confirmation Dialog
  getConfigFile(data) {
    return this.apiService.get(environment.base_value.config_url + '/' + data);
  }
  getAlertCount(id){
    id = id == 'All' || id == null ? '' : id;
    return this.apiService.get(environment.base_value.get_alert_task_count + '?locationId='+id);
  }
  // used in both Health Checkup, Common Search, Patient
  getHcPatientList(date, planTypeId?: string) {
    if (this.tokenEnrollFlow === null) {
      this.tokenEnrollFlow = JSON.parse(localStorage.getItem(btoa('tokenEnrollFlow')));
    }
    if (this.tokenEnrollFlow === true) {
      // return this.apiService.get(environment.base_value.hc_patientlist_with_billed + localStorage.getItem(btoa('facilityId')) + '/fdt=' + date);
      if (planTypeId != null && (planTypeId === 'HP-OT' || planTypeId === 'HP-EC')) {
        return this.apiService.get(environment.base_value.hc_patientlist_with_billed + '?fromDate=' + date + '&planTypeId=' + planTypeId);
      } else {
        return this.apiService.get(environment.base_value.hc_patientlist_with_billed + '?fromDate=' + date);
      }
    } else {
      return this.apiService.get(environment.base_value.hc_patientlist + localStorage.getItem(btoa('facilityId')) + '/fdt=' + date);
    }
  }
  // used in both Health Checkup, Patient
  saveMergeRecord(data) {
    return this.apiService.post(environment.base_value.save_merge_record, data);
  }
  // used in both Health Checkup, Enroll Patient
  getRegisteredPatients(id) {
    return this.apiService.get(environment.base_value.registered_patients + '/' + id);
  }
  getEnrollVisitOption(patientId, visitId) {
    return this.apiService.get(environment.base_value.get_enroll_visit_option + '/' + patientId + '/' + visitId);
  }
  // used in both Health Checkup, Patient, Enroll Patient
  saveRegisteredPatients(data) {
    return this.apiService.post(environment.base_value.registered_patients, data);
  }
  updateRegisteredPatients(data) {
    return this.apiService.put(environment.base_value.get_ip_patient_list  + '/' + data.patientId, data);
  }
  createMedicalRecordRequest(data) {
    return this.apiService.post(environment.base_value.mr_create_request, data);
  }

  // Component: Porter
  // Entry Component: Live Tag Details

 
  getPorterRequest(date, userRole, locationId, statusIds?: any, reqType?: string, customerId?: any, poolNames?: any) {
    // if (date !== null) {
    //   return this.apiService.get(environment.base_value.get_all_porter_request + '?date=' + date);
    // } else if (userRole) {
    //   return this.apiService.get(environment.base_value.get_all_porter_request + '?isCreatedUser=' + userRole);
    // } else if (locationId !== null) {
    //   return this.apiService.get(environment.base_value.get_all_porter_request + '?locationId=' + locationId);
    // } else {
    //   return this.apiService.get(environment.base_value.get_all_porter_request);
    // }

    if (locationId === null) {
      locationId = '';
    }

    let get_porter_request = '?locationId=' + locationId;
    if (date !== null) {
      get_porter_request = get_porter_request + '&date=' + date;
    }
    if (userRole) {
      get_porter_request = get_porter_request + '&isCreatedUser=' + userRole;
    }
    if (statusIds !== null) {
      get_porter_request = get_porter_request + '&statusIds=' + statusIds;
    }
    if (reqType !== null && reqType !== undefined) {
      get_porter_request = get_porter_request + '&requestType=' + reqType;
    }
    if (customerId !== null && customerId !== undefined) {
      get_porter_request = get_porter_request + '&customerId=' + customerId;
    }
    if (poolNames !== null && poolNames !== undefined) {
      get_porter_request = get_porter_request + '&poolNames=' + poolNames;
    }
    else {
      return this.apiService.get(environment.base_value.get_all_porter_request + get_porter_request);
    }
  }

  // getGeoAmbulanceLocation(id) {
  //   return this.apiService.get(environment.base_value.get_geo_location + localStorage.getItem(btoa('facilityId')) + '/ttype=' + id);
  // }
  // Component: Tag Tracking
  getTagHistory(id: string) {
    // console.log('common service: ' + id);

    if (id === 'Wheel') {
      return [
        {
          id: 1,
          location: 'Ward A',
          startTime: '10:00 AM',
          endTime: '10:30 AM'
        }, {
          id: 2,
          location: 'Ward B',
          startTime: '10:30 AM',
          endTime: '11:30 AM'
        }, {
          id: 3,
          location: 'ICU',
          startTime: '11:31 AM',
          endTime: '12:30 PM'
        }, {
          id: 4,
          location: 'Ward B',
          startTime: '01:00 PM',
          endTime: '03:30 PM'
        }, {
          id: 5,
          location: 'Ward A',
          startTime: '03:31 PM',
          endTime: '04:00 PM'
        }];
    } else if (id === 'TAG002') {
      return [
        {
          id: 1,
          location: 'General Ward',
          startTime: '10:00 AM',
          endTime: '10:30 AM'
        }, {
          id: 2,
          location: 'OP Ward',
          startTime: '10:31 AM',
          endTime: '11:30 AM'
        }, {
          id: 3,
          location: 'ICU',
          startTime: '11:31 AM',
          endTime: '12:30 PM'
        }, {
          id: 4,
          location: 'OP Ward',
          startTime: '01:00 PM',
          endTime: '03:30 PM'
        }, {
          id: 5,
          location: 'Ward B',
          startTime: '03:31 PM',
          endTime: '04:00 PM'
        }, {
          id: 6,
          location: 'General Ward',
          startTime: '04:31 PM',
          endTime: '05:00 PM'
        }];
    } else if (id === 'TAG003') {
      return [
        {
          id: 1,
          location: 'ICU',
          startTime: '10:00 AM',
          endTime: '12:30 PM'
        }, {
          id: 2,
          location: 'OP Ward',
          startTime: '12:31 PM',
          endTime: '04:30 PM'
        }];
    } else if (id === 'TAG004') {
      return [
        {
          id: 1,
          location: 'Ward A',
          startTime: '10:00 AM',
          endTime: '10:30 AM'
        }, {
          id: 2,
          location: 'Ward C',
          startTime: '10:31 AM',
          endTime: '11:30 AM'
        }, {
          id: 3,
          location: 'ICU',
          startTime: '11:31 AM',
          endTime: '12:30 PM'
        }, {
          id: 4,
          location: 'Ward C',
          startTime: '01:00 PM',
          endTime: '03:30 PM'
        }, {
          id: 5,
          location: 'Ward A',
          startTime: '03:31 PM',
          endTime: '04:00 PM'
        }];
    } else if (id === 'TAG005') {
      return [
        {
          id: 1,
          location: 'OP Ward',
          startTime: '10:00 AM',
          endTime: '10:30 AM'
        }, {
          id: 2,
          location: 'Ward A',
          startTime: '10:31 AM',
          endTime: '11:30 AM'
        }, {
          id: 3,
          location: 'ICU',
          startTime: '11:31 AM',
          endTime: '12:30 PM'
        }, {
          id: 4,
          location: 'Ward B',
          startTime: '01:00 PM',
          endTime: '01:30 PM'
        }, {
          id: 5,
          location: 'Ward A',
          startTime: '01:31 PM',
          endTime: '02:00 PM'
        }, {
          id: 6,
          location: 'Ward B',
          startTime: '02:31 PM',
          endTime: '03:00 PM'
        }, {
          id: 7,
          location: 'Ward A',
          startTime: '03:31 PM',
          endTime: '04:00 PM'
        }];
    }
  }

  // Entry Component: Floor Map



  // Entry Component: Common Map
  getLocationById(id) {
    return this.apiService.get(environment.base_value.get_location_by_id + '/' + id);
  }
  getAllReaderTempFloor(id) {
    const url = 'api/python-wrapper/reports/envdetails/0/0/' + localStorage.getItem(btoa('facilityId')) + '/env=TEMP&flr=' + id;
    return this.http.get(url);
  }

  // Entry Component: Patient
  getAllRegisterPlan() {
    return this.apiService.get(environment.base_value.get_all_health_plan);
  }
  getHealthTestAvailableLocation(testType, testId) {
    return this.apiService.get(environment.base_value.get_health_test_available_locations +
      '?healthTestOption=' + testType + '&healthTestId=' + testId);
  }
  updateClinicalDetails(data) {
    return this.apiService.post(environment.base_value.update_clinical_details, data);
  }
  publishTestStatusToCoaster(data) {
    return this.apiService.post(environment.base_value.publish_test_status, data);
  }
  quickPatientRegistration(postData) {
    return this.apiService.post(environment.base_value.save_quick_registration, postData);
  }
  getAllpatientByLocation(id, status, testId, testType, visitStatusId, planTypeId) {
    // return this.apiService.get(environment.base_value.check_health_plan + '/Today/' + id + '/' + status + '/' + testId);
    let get_patient_test = '?checkUpDayOption=Today';
    if (id !== '' && id !== 0) {
      get_patient_test = get_patient_test + '&locationId=' + id;
    }
    if (status !== '') {
      get_patient_test = get_patient_test + '&statusId=' + status;
    }
    if (testId !== '' && testId !== null) {
      get_patient_test = get_patient_test + '&healthTestId=' + testId;
    }
    if (testType !== '' && testType !== null) {
      get_patient_test = get_patient_test + '&testType=' + testType;
    }
    if (visitStatusId !== '' && visitStatusId !== null) {
      get_patient_test = get_patient_test + '&visitStatusId=' + visitStatusId;
    }
    if (planTypeId !== '' && planTypeId !== null) {
      get_patient_test = get_patient_test + '&planTypeId=' + planTypeId;
    }

    return this.apiService.get(environment.base_value.check_health_plan + get_patient_test);
    // return this.apiService.get(environment.base_value.check_health_plan + '?checkUpDayOption=Today&locationId=' +
    //  id + '&statusId=' + status + '&healthTestId=' + testId);
  }
  getPatientInfo(id, patientVisitId, isTempPatient, visitType, visitEventTypeId, visitEventId, workflow?: string) {
    if (visitType === 'VT-OP') {
      return this.apiService.get(environment.base_value.get_outpatient_info + '?patientId=' + id + '&patientVistId=' + patientVisitId +
        '&isTempPatient=' + isTempPatient);
    } else if (visitType === 'VT-IP' && workflow !== 'DayCare') {
      if (visitEventTypeId === 'VE-OT') {
        return this.apiService.get(environment.base_value.get_patient_info + '?patientId=' + id + '&patientVistId=' + patientVisitId +
          '&visitEventId=' + visitEventId + '&isTempPatient=' + isTempPatient);
      } else {
        return this.apiService.get(environment.base_value.get_ip_patient_info + '?patientId=' + id + '&patientVistId=' + patientVisitId);
      }
    } else {
      return this.apiService.get(environment.base_value.get_patient_info + '?patientId=' + id + '&patientVistId=' + patientVisitId +
        '&isTempPatient=' + isTempPatient);
    }
  }
  getHealthCheckupList(date) {
    return this.apiService.get(environment.base_value.check_health_plan + '/' + date);
  }
  getHealthTestGroup(id, planId) {
    return this.apiService.get(environment.base_value.get_health_test_group + '/' + id + '/' + planId);
  }

  searchPatient(data) {
    return this.apiService.get(environment.base_value.search_patient + '/' + data);
  }
  searchPorter(name, floorId, fromTime, toTime, poolName?: string, gender?: string, poolLocationId?: string, requestType?: string) {
    let searchPorter = null;
    if (name != null) {
      if (searchPorter === null) {
        searchPorter = '?name=' + name;
      } else {
        searchPorter = searchPorter + '&name=' + name;
      }
    }
    if (floorId != null) {
      if (searchPorter === null) {
        searchPorter = '?floorId=' + floorId;
      } else {
        searchPorter = searchPorter + '&floorId=' + floorId;
      }
    }
    if (fromTime != null) {
      if (searchPorter === null) {
        searchPorter = '?fromTime=' + fromTime;
      } else {
        searchPorter = searchPorter + '&fromTime=' + fromTime;
      }
    }
    if (toTime != null) {
      if (searchPorter === null) {
        searchPorter = '?toTime=' + toTime;
      } else {
        searchPorter = searchPorter + '&toTime=' + toTime;
      }
    }
    if (poolName != null) {
      if (searchPorter === null) {
        searchPorter = '?poolName=' + poolName;
      } else {
        searchPorter = searchPorter + '&poolName=' + poolName;
      }
    }
    if (gender != null) {
      if (searchPorter === null) {
        searchPorter = '?gender=' + gender;
      } else {
        searchPorter = searchPorter + '&gender=' + gender;
      }
    }
    if (poolLocationId != null && poolLocationId != undefined) {
      if (searchPorter === null) {
        searchPorter = '?poolLocationId=' + poolLocationId;
      } else {
        searchPorter = searchPorter + '&poolLocationId=' + poolLocationId;
      }
    }
    if (requestType != null && requestType !== undefined) {
      if (searchPorter === null) {
        searchPorter = '?requestType=' + requestType;
      } else {
        searchPorter = searchPorter + '&requestType=' + requestType;
      }
    }
   
  }

  // Entry Component: Confirmation Dialog
  getAllHeathTestWithoutAdminTest(data) {
    return this.apiService.get(environment.base_value.get_all_health_test + '?isIgnoreAdmin=true&testName=' + data);
  }
  saveAssignDefaultFloor(data) {
    return this.apiService.post(environment.base_value.assign_default_floor, data);
  }
  managePatientTest(data) {
    return this.apiService.post(environment.base_value.manage_patient_test, data);
  }
  updatePatientDiabetic(data) {
    return this.apiService.put(environment.base_value.update_patient_diabetic + '/' + data.id, data);
  }
  updatePatientTestLocation(data) {
    return this.apiService.put(environment.base_value.update_queue_status_location, data);
  }

  // Component: Digital Queue, Confirmation Dialog, Edit Daily Management

  // used in both Digital Queue, Edit Daily Management


  // used in both Digital Queue, Confirmation Dialog, Edit Daily Management
  updateHealthTestByFloorwise(data) {
    return this.apiService.put(environment.base_value.update_health_test_location_availability + '/' + data.locationId, data);
  }

  // Entry Component: Enroll Patient, Patient
  // used in both Enroll Patient, Patient
  getUHID(uhid) {
    return this.apiService.get(environment.base_value.get_uhid + '/' + uhid);
  }


  getAllDepartments(searchText) {
    return this.apiService.get(environment.base_value.get_all_departments + '?searchText=' + searchText);
  }
  
  getAllTagByType(tagId, type, status) {
    return this.apiService.get(environment.base_value.get_all_tag_by_type + '?tagId=' + tagId + '&workflowTypeId=' + type + '&status=' + status);
  }


  searchTagAssociate(tagId, type, status?: string) {
    return this.apiService.get(environment.base_value.search_non_associate_tag + '?tagId=' + tagId + '&tagTypeId=' + type + '&status=' + status);
  }
  searchByMainidentifer(mainidentifer) {
    return this.apiService.get(environment.base_value.search_by_mainidentifer + '?mainidentifier=' + mainidentifer);
  }
  createRegisterVisitor(data) {
    return this.apiService.post(environment.base_value.visitor_controller, data);
  }
  createRegisterEmployee(data) {
    return this.apiService.post(environment.base_value.employee_controller, data);
  }
  updateRegisterEmployee(data) {
    return this.apiService.put(environment.base_value.employee_controller + '/' + data.id, data);
  }
  updateRegisterVisitor(data) {
    return this.apiService.put(environment.base_value.visitor_controller + '/' + data.id, data);
  }

  // Component: Alert Config, Asset, Gateway, Reader, tags, Location Management, Support Ticket, Employee, Porter, Dashboard >> Alert
  // Entry Component: Alert Entry, Support Ticket, Enroll Patient
  getAppTerms(data) {
    return this.apiService.get(environment.base_value.get_bulk_app_terms + '/' + data);
  }

  getApptermsData(data){
    return this.apiService.get(environment.base_value.get_app_terms_data + '?groupName=' + data);
  }
  createAppterms(data) {
    return this.apiService.post(environment.base_value.get_app_terms_data, data);
  }
  getApptermsFilterData(data,facilityId?){
    if(facilityId){
      return this.apiService.get(environment.base_value.get_app_terms_filter_data + '?groupName=' + data +'&facilityId='+facilityId);
    } else{
      return this.apiService.get(environment.base_value.get_app_terms_filter_data + '?groupName=' + data);
    }
  }
  getDynamicTableColumn(type? : string){
    return this.apiService.get(environment.base_value.config_url + '/' + 'grid-config-' + type);
  }
  validateApptermsCode(code){
    return this.apiService.get(environment.base_value.validate_app_terms_code + '?code=' + code);
  }
  getAppTermsLink(type, groupName?: string) {
    if (groupName !== null && groupName !== undefined) {
      return this.apiService.get(environment.base_value.get_reader_type + '?apptermCode=' + type + '&groupName=' + groupName);
    } else {
      return this.apiService.get(environment.base_value.get_reader_type + '?apptermCode=' + type);
    }
  }
  getHealthPlanLocations() {
    return this.apiService.get(environment.base_value.get_all_health_test_locations);
  }
  getFormTemplate(type) {
    return this.apiService.get(environment.base_value.get_form_template + '?type=' + type);
  }

  getKynPost() {
    return this.apiService.get('api/python-wrapper/kyn-data/user-live-request/0/0/0/tgt=kyn&flt=1');
  }
   getAllReportedPost(postData){
    return this.apiService.post('api/python-wrapper/kyn-trans/post-report/0/0/0/tgt=kyn&flt=1', postData);
  }
  getAllReportedNotice(postData){
    return this.apiService.post('api/python-wrapper/kyn-trans/notice-report/0/0/0/tgt=kyn&flt=1', postData);
  }
  getAllReportedVideo(postData){
    return this.apiService.post('api/python-wrapper/kyn-trans/video-report/0/0/0/tgt=kyn&flt=1', postData);
  }
  getAllReportedShorts(postData){
    return this.apiService.post('api/python-wrapper/kyn-trans/shorts-report/0/0/0/tgt=kyn&flt=1', postData);
  }
  getAllReportedUser(postData){
    return this.apiService.post('api/python-wrapper/kyn-trans/user-report/0/0/0/tgt=kyn&flt=1', postData);
  }
  getPostReport(id){
    return this.apiService.get('api/python-wrapper/kyn-data/report-post-id/0/0/0/tgt=kyn&flt=1' + '&uid=' + id);
  }
  getNoticeReport(id){
    return this.apiService.get('api/python-wrapper/kyn-data/report-journal-id/0/0/0/tgt=kyn&flt=1' + '&uid=' + id);
  }
  getVideoReport(id){
    return this.apiService.get('api/python-wrapper/kyn-data/report-video-id/0/0/0/tgt=kyn&flt=1' + '&uid=' + id);
  }
  getShortsReport(id){
    return this.apiService.get('api/python-wrapper/kyn-data/report-shorts-id/0/0/0/tgt=kyn&flt=1' + '&uid=' + id);
  }
  getUserReport(id){
    return this.apiService.get('api/python-wrapper/kyn-data/report-user-id/0/0/0/tgt=kyn&flt=1' + '&uid=' + id);
  }
  createTelecastSchedule(postData) {
    return this.apiService.post('api/python-wrapper/kyn-trans/create/0/0/0/flt=0&typ=telecastSchedule', postData);
  }
  createTelecastScheduleDetail(postData) {
    return this.apiService.post('api/python-wrapper/kyn-trans/create/0/0/0/flt=0&typ=telecastScheduleDetail', postData);
  }
  createNewLiveRequest(postData) {
    return this.apiService.post('api/python-wrapper/kyn-trans/create/0/0/0/flt=0&typ=userLiveRequest', postData);
  }
  getLiveRequest(id){
    if(id){
      return this.apiService.get('api/python-wrapper/kyn-data/user-live-request/0/0/0/tgt=kyn&flt=1'+ '&uid=' + id);
    } else {
      return this.apiService.get('api/python-wrapper/kyn-data/user-live-request/0/0/0/tgt=kyn&flt=1');
    }
  }
  getScheduleRequest(id){
    if(id){
      return this.apiService.get('api/python-wrapper/kyn-data/user-schedule-request/0/0/0/tgt=kyn&flt=1' + '&uid=' + id);
    } else {
      return this.apiService.get('api/python-wrapper/kyn-data/user-schedule-request/0/0/0/tgt=kyn&flt=1');
    }
  }
  getKynUser() {
    return this.apiService.get('api/python-wrapper/kyn-data/get-all-users/0/0/0/tgt=kyn&flt=1');
  }
  getKynPostContent(id) {
    return this.apiService.get('api/python-wrapper/kyn-data/user-post/0/0/0/tgt=kyn&flt=1 ' + '&uid=' + id);
  }
  getKynNoticeContent(id) {
    return this.apiService.get('api/python-wrapper/kyn-data/user-journal/0/0/0/tgt=kyn&flt=1' + '&uid=' + id);
  }
  getKynVideoContent(id) {
    return this.apiService.get('api/python-wrapper/kyn-data/user-video/0/0/0/tgt=kyn&flt=1' + '&uid=' + id);
  }
  getKynShortsContent(id) {
    return this.apiService.get('api/python-wrapper/kyn-data/user-shorts/0/0/0/tgt=kyn&flt=1' + '&uid=' + id);
  }
  getKynScheduleContent(id) {
    return this.apiService.get('api/python-wrapper/kyn-data/user-schedule/0/0/0/tgt=kyn&flt=1' + '&uid=' + id);
  }
  getKynLiveContent(id) {
    return this.apiService.get('api/python-wrapper/kyn-data/user-live-request-by-id/0/0/0/tgt=kyn&flt=1' + '&uid=' + id);
  }
  kynVideoDelete(id, type) {
    let data = {}
    return this.apiService.post('api/python-wrapper/kyn-trans/delete-data/0/0/0/' + 'uid=' + id + '&tgt=kyn&flt=0' + '&typ=' + type, data);
  }
  kynReportDelete(id, type){
    let data = {}
    return this.apiService.post('api/python-wrapper/kyn-trans/delete-data/0/0/0/' + 'uid=' + id + '&tgt=kyn&flt=0' + '&typ=' + type, data);
  }
  updateRole(id, data){
    return this.apiService.post('api/python-wrapper/kyn-trans/update-role-user-id/0/0/0/tgt=kyn&flt=0&' + 'uid=' + id , data);
  }
  getAnalyticsLocation(){
    return this.apiService.get('api/python-wrapper/kyn-data/localities/0/0/0/tgt=kyn&flt=1');
  }
  getAnalyticsCategories(){
    return this.apiService.get('api/python-wrapper/kyn-data/categories/0/0/0/tgt=kyn&flt=1');
  }
  getOverview(data){
    return this.apiService.post('api/python-wrapper/kyn-trans/analytics-report/0/0/0/tgt=kyn&flt=1', data);
  }
  getReportVideo(data){
    return this.apiService.post('api/python-wrapper/kyn-trans/content-filter/0/0/0/tgt=kyn&flt=1&typ=video', data);
  }
  getReportShorts(data){
    return this.apiService.post('api/python-wrapper/kyn-trans/content-filter/0/0/0/tgt=kyn&flt=1&typ=shorts', data);
  }
  getReportJournal(data){
    return this.apiService.post('api/python-wrapper/kyn-trans/content-filter/0/0/0/tgt=kyn&flt=1&typ=journal', data);
  }
  getReportPost(data){
    return this.apiService.post('api/python-wrapper/kyn-trans/content-filter/0/0/0/tgt=kyn&flt=1&typ=post', data);
  }
  kynAproval(type, id, data){
    return this.apiService.post('api/python-wrapper/kyn-trans/user-approval/0/0/0/tgt=kyn&flt=1' + '&typ=' + type + '&uid=' + id, data);
  }
  getEntityFormHistory(id) {
    return this.apiService.get(environment.base_value.get_entity_form_history + '/' + id);
  }
  getTodayTeleShedu(date){
    return this.apiService.get('api/python-wrapper/kyn-data/tele-get-schedule-detail/0/0/0/flt=1' + '&fdt=' + date)
  }
  getAllAproveLiveList(postData){
    return this.apiService.post('api/python-wrapper/kyn-trans/user-live-request/0/0/0/flt=1&rid=Approved', postData)
  }
  getTagId(facilityIds, tagTypes?:any, tagAssociationTypes?:string) {
    if(tagTypes !== undefined && tagTypes !== null) {
      return this.apiService.get(environment.base_value.get_tag_id + '?facilityIds=' + facilityIds + '&tagTypes=' + tagTypes + '&tagAssociationTypes=' + tagAssociationTypes);
    } else {
      return this.apiService.get(environment.base_value.get_tag_id + '?facilityIds=' + facilityIds + '&tagAssociationTypes=' + tagAssociationTypes);
    }
  }
  getWorkflowFrom(date, formStatusIds,formTypes) {

    let get_workflow_from ='?date=' + date + '&toDate=' + date;
    
    if (formStatusIds !== null) {
      get_workflow_from = get_workflow_from + '&formStatusIds=' + formStatusIds;
    }
    if (formTypes !== null) {
      get_workflow_from = get_workflow_from + '&statusIds=' + formTypes;
    }
    return this.apiService.get(environment.base_value.entity_form + get_workflow_from);
  }
  // Component: Health Test, Health Check, Reader, Resource, Digital Queue, Manage Facility, Patient, Support Ticket, User Management, Health Checkup
  // Entry Component: Confirmation Dialog, Edit Daily Management, Enroll Patient, Patient
  getAppTermsVerion2(data) {
    return this.apiService.get(environment.base_value.get_app_terms_v2 + '/' + data);
  }
  getAppTermFacility(data) {
    return this.apiService.get(environment.base_value.get_app_term_facility + '/' + data);
  }
  getAppTermsWithoutLogin(data) {
    return this.apiService.get(environment.base_value.get_app_terms + '/' + data);
  }

  getOTHealthPlan(key, name?: string) {
    return this.apiService.get(environment.base_value.get_ot_health_plan + '?planTypeId=' + key + '&name=' + name);
  }

  getAllLocationType() {
      return this.apiService.get(environment.base_value.get_all_location_type);
  }

  // Entry Component: Confirmation Dialog
  cancleBilling(data) {
    return this.apiService.post(environment.base_value.cancel_billing, data);
  }
  // Ambulance Workflow
  searchAssetByCategory(type, data) {
    if(type !== null) {
      return this.apiService.get(environment.base_value.search_asset_by_categoryId + '?assetTypeId=' + type + '&name=' + data);
    } else {
      return this.apiService.get(environment.base_value.search_asset_by_categoryId + '?name=' + data);
    }
  }

  getPorterRequestType() {
    return this.apiService.get(environment.base_value.get_app_terms + '/PorterRequestType');
  }
  getPorterHistory(id) {
    return this.apiService.get(environment.base_value.get_porter_history + '?requestId=' + id);
  }

  searchBedLocation(name, fromDate, toDate) {
    return this.apiService.get(environment.base_value.get_bed_location_search + '?searchText=' + name + '&fromDate=' + fromDate + '&toDate=' + toDate);
  }


  searchDoctor(name, userType?: string, roleType?: string) {
    if (roleType != null) {
      return this.apiService.get(environment.base_value.search_doctor + '?name=' + name + '&roleTypeId=' + roleType);
    } else {
      return this.apiService.get(environment.base_value.search_doctor + '?name=' + name + '&userTypeId=' + userType);
    }
  }
  // getRollListid(rlid){
  //   return this.apiService.get(environment.base_value.roleid + '?rlid=' + rlid);
  // }
  getRollList(){
    return this.apiService.get("api/all/auth/get-all-roles");
  }
 
  getVisitHistory(id) {
    return this.apiService.get(environment.base_value.visit_history + '?patientId=' + id);
  }
  getRoutineEventDetails(id, type, filterDate?: string, pageStart?: any, pageSize?: any) {
    if (filterDate != null) {
      return this.apiService.get(environment.base_value.routine_event_details + '?identifyingId=' + id + '&identifyingType=' + type +
      '&date=' + filterDate + '&pageStart=' + pageStart + '&pageSize=' + pageSize);
    } else {
      return this.apiService.get(environment.base_value.routine_event_details + '?identifyingId=' + id + '&identifyingType=' + type +
      '&pageStart=' + pageStart + '&pageSize=' + pageSize);
    }
  }
  getInpatientListByVisitId(id) {
    return this.apiService.get(environment.base_value.get_ip_patient_info_by_id + '/' + id);
  }

  getFacilityList(reg_id) {
      return this.apiService.get(environment.base_value.get_facility_list + '/' + reg_id);
  }

  getSpecialityLoc(careSettingIds?: string, categoryIds?: string, text?: string) {
    let searchLoc = null;
    if (careSettingIds !== null) {
      if (searchLoc === null) {
        searchLoc = '?careSettingIds=' + careSettingIds + '&locationTypeIds=3';
      } else {
        searchLoc = searchLoc + '&careSettingIds=' + careSettingIds + '&locationTypeIds=3';
      }
    }
    if (categoryIds !== null) {
      if (searchLoc === null) {
        searchLoc = '?categoryIds=' + categoryIds;
      } else {
        searchLoc = searchLoc + '&categoryIds=' + categoryIds;
      }
    }
    if (text !== null && text !== undefined) {
      if (searchLoc === null) {
        searchLoc = '?stext=' + text;
      } else {
        searchLoc = searchLoc + '&stext=' + text;
      }
    }
    if (searchLoc !== null) {
      return this.apiService.get(environment.base_value.get_ip_speciality_loc + searchLoc);
    } else {
      return this.apiService.get(environment.base_value.get_ip_speciality_loc);
    }
  }

  assetStatusChange(assetData, id) {
      return this.apiService.put(environment.base_value.edit_asset + '/' + id, assetData);
  }


  isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  // Component: Consumer

  // Commponent: Medical Record
  //Ticket
  saveTicketRequest(data) {
    return this.apiService.post(environment.base_value.save_ticket_request, data);
  }
  updateTicketRequest(data) {
    return this.apiService.put(environment.base_value.update_ticket_request + '/' + data.ticketId, data);
  }
  getTicketRequest(date, reqType?: string, reqId?:any) {
    let quaryParam = '?date=' + date + '&requestType=' + reqType;
    if(reqId) {
      quaryParam = '?requestId=' + reqId + '&requestType=' + reqType;
    }
    return this.apiService.get(environment.base_value.get_all_ticket_request + quaryParam);
  }
  getAttachment(id, type) {
    return this.apiService.get(environment.base_value.get_all_attachment + '/' + id + '?type=' + type);
  }
  //Task
  getUserDetailByLocId(data) {
    if(data.parentId !== null && data.locationTypeId !== null) {
      return this.apiService.get(environment.base_value.get_user_detail_by_locId + '?locationId=' + data.id + '&parentId=' + data.parentId + '&locationTypeId=' + data.locationTypeId);   
    } else {
      return this.apiService.get(environment.base_value.get_user_detail_by_locId + '?locationId=' + data.id);   
    }
  }
  saveTask(data) {
    return this.apiService.post(environment.base_value.save_task, data);
   }
  getTask(id, type) {
    return this.apiService.get(environment.base_value.save_task + '?entityId=' + id + '&entityType=' + type);
  }
  getHistoryTask(id, type, date) {
    if (date !== null) {
      return this.apiService.get(environment.base_value.task_history + '?entityId=' + id + '&entityType=' + type + '&startDate=' + date);
    } else {
      return this.apiService.get(environment.base_value.task_history + '?entityId=' + id + '&entityType=' + type);
    }
  }
  updateTask(requestId, data) {
    return this.apiService.put(environment.base_value.save_porter_request + '/' + requestId, data);
  }
  updateTaskRoutinestatus(id, data) {
    return this.apiService.put(environment.base_value.update_task_routine_status + '/' + id, data);
  }
  undoTaskRoutine(id, data) {
    return this.apiService.put(environment.base_value.undo_task_routine + '/' + id, data);
  }
  public sortByKey(array, key) {
    let filterData = array.filter(val => val[key])
    let res1 = filterData.sort(function(a, b) {
      let x = a[key];
      let y = b[key];
      if(typeof(a[key]) === 'string') {
        x = a[key].toUpperCase();
        y = b[key].toUpperCase();
      }
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
    if(array.filter(val => val[key] == null).length) {
      let nullValue = array.filter(val => val[key] == null)
      res1 = res1.concat(nullValue)
    }
    return res1
  }
  // Not in Use
  // getEditRequestId(id: any) {
  //   return [{
  //     id: 153,
  //     porterRequestType: 2,
  //     subjectId: 1,
  //     locationFromId: 117,
  //     locationToId: 116,
  //     startTime: '12:10 PM',
  //     endTime: '12:50 PM',
  //     noOfPorter: 2,
  //     noOfAsset: 2,
  //     isAutoAssigned: true,
  //     porterId: 1
  //   }];
  // }
  // getRequestType() {
  //   return [{ id: 1, name: 'Patient' },
  //   { id: 2, name: 'Asset' },
  //   { id: 3, name: 'Others' }];
  // }
  // getAssetList() {
  //   return [{ slno: 1, assetId: '1111', assetName: 'X-Ray' },
  //   { id: 2, assetId: '1112', assetName: 'Infusion Pump' },
  //   { id: 3, assetId: '1113', assetName: 'ECG Monitor' },
  //   { id: 4, assetId: '1114', assetName: 'Infusion Stand' },
  //   { id: 5, assetId: '1115', assetName: 'X-Ray Unit' },
  //   { id: 6, assetId: '1116', assetName: 'Infant weighing scale' },
  //   { id: 7, assetId: '1117', assetName: 'X-Ray Unit1' },
  //   { id: 8, assetId: '1118', assetName: 'X-Ray Unit2' }
  //   ];
  // }
  // updatePorterRequestById(id, status) {
  //   return this.apiService.put(environment.base_value.porter_request_status_update + id + '/' + status, status);
  // }
  // getLocationSearch(id) {
  //   return this.apiService.get(environment.base_value.get_to_location_search + '/' + id);
  // }
  // getCreatedPorterRequest() {
  //   return this.apiService.get(environment.base_value.get_all_created_request);
  // }

  // Not in Use
  // getGlobalSearchData() {
  //   const url = 'api/python-wrapper/reports/taglocation/0/0/' + localStorage.getItem(btoa('facilityId')) + '?fdt=2019-03-18&tid=T2';
  //   return this.apiService.get(url);
  // }
  // getCommonMenu() {
  //   return [{
  //     state: '/ovitag/dashboard',
  //     name: 'Dashboard',
  //     icon: 'dashboard',
  //     referMenu: 'MN_DB',
  //     url: 'M_dashboard'
  //   },
  //   {
  //     state: '/ovitag/workflow',
  //     name: 'Ovitag',
  //     icon: 'track_changes',
  //     referMenu: 'MN_OT',
  //     url: 'M_ovitag'
  //   },
  //   {
  //     state: '/ovitag/hospital',
  //     name: 'Hospital',
  //     icon: 'add_box',
  //     referMenu: 'MN_HP',
  //     url: 'M_hospital'
  //   },
  //   {
  //     state: '/ovitag/configuration',
  //     name: 'Configuration',
  //     icon: 'settings',
  //     referMenu: 'MN_CF',
  //     url: 'M_configuration'
  //   },
  //   {
  //     state: '/ovitag/analytic-insights',
  //     name: 'Analytic Insights',
  //     icon: 'pie_chart',
  //     referMenu: 'MN_RE',
  //     url: 'M_report'
  //   }
  //     // ,
  //     // {
  //     //   state : 'maps',
  //     //   name: 'Floor Plan',
  //     //   icon: 'pie_chart',
  //     //  }
  //   ];
  // }
  // getRefreshToken(refreshToken) {
  //   return this.apiService.post(environment.base_value.get_renew_token, refreshToken);
  // }
  // getAuthToken() {
  //   return localStorage.getItem(btoa('user_token'));
  // }
  // getAppTerms(data) {
  //   return this.apiService.get(environment.base_value.get_app_terms + '/' + data);
  // }
  // showSuccess() {
  //   this.toastr.successToastr('This is success toast.', 'Success!');
  // }
  // showError() {
  //   this.toastr.errorToastr('This is error toast.', 'Oops!');
  // }
  // showWarning() {
  //   this.toastr.warningToastr('This is warning toast.', 'Alert!');
  // }
  // showInfo() {
  //   this.toastr.infoToastr('This is info toast.', 'Info');
  // }
  // showToast(position: any = 'top-left') {
  //   this.toastr.infoToastr('This is a toast.', 'Toast', {
  //     position: position
  //   });
  // }
  deleteUserPreference(id) {
    this.apiService.delete(environment.base_value.user_preferences + '/' + id)
      .toPromise()
    .then(res => {
      this.userPreference = res.results;
    })
    .catch(err => { console.log(err); });
  }
  // User Preferences
  getUserPreference(userId, roleId) {
    if(this.userPreference === null) {
      if (userId != null && roleId != null ) {
      this.apiService.get(environment.base_value.user_preferences + '?userId=' + userId + '&roleId=' + roleId)
       .toPromise()
       .then(res => {
         this.userPreference = res.results;
       })
       .catch(err => { console.log(err); });
      }
    }
  }
  getPreference(userId, roleId) {
    if (userId != null && roleId != null ) {
      return this.apiService.get(environment.base_value.user_preferences + '?userId=' + userId + '&roleId=' + roleId)
    } 
  }

  validateUserPreference(key, value ?: any) {
    if (key != null && value != null) {
      const postData = {
        'key': key,
        'roleId': localStorage.getItem('userlevel'),
        'userId': localStorage.getItem(btoa('userId')),
        'value': value,
        'facilityId': localStorage.getItem(btoa('facilityId'))
      };
      if (this.userPreference != null && key != null && this.userPreference.hasOwnProperty(key)) {
        if (this.userPreference[key]['value'] !== value) {
          this.updateUserPreference(this.userPreference[key].id, postData).subscribe(res => {
            this.userPreference = res.results;
          });
        }
      } else {
        this.saveUserPreference(postData).subscribe(res => {
          this.userPreference = res.results;
        });
      }
    } else {
      this.getUserPreference(localStorage.getItem(btoa('userId')), localStorage.getItem('userlevel'));
    }
  }
  userPreferenceValidate(key, value = null) {
    const preference = this.userPreference;
    const postData = {
      'key': key,
      'roleId': localStorage.getItem('userlevel'),
      'userId': localStorage.getItem(btoa('userId')),
      'value': value
    };
    if (preference != null && value) {
      if (preference.hasOwnProperty(key)) {
        if (preference[key]['value'] !== value) {
          const id = preference[key]['value'];
          this.updateUserPreference(id, postData).subscribe(res => {
            this.userPreference = res.results;
          });
        }
      } else {
        this.saveUserPreference(postData).subscribe(res => {
          this.userPreference = res.results;
        });
      }
    }
  }

  saveUserPreference(data) {
    return this.apiService.post(environment.base_value.user_preferences, data);
  }

  updateUserPreference(id, data) {
    return this.apiService.put(environment.base_value.user_preferences + '/' + id, data);
  }
  getRecipientName(text, type) {
    return this.apiService.get(environment.base_value.search_name_by_recipient_type + '?stext=' + text + '&recipientTypeId=' + type);
  }
  // TW TABLE
  // getLiveLocation(type, id) {
  //   return this.apiService.get(environment.base_value.get_tag_associated_details + '?type=' + type + '&associateId=' + id);
  // }

  // Advertisement
  getAllReleaseOrders(){
    return this.apiService.get(environment.base_value.get_all_RO);
  }
  getworkflowbyEntity(entityTypeName){
    return this.apiService.get(environment.base_value.get_pf_workflow + '?entityTypeId=' + entityTypeName);
  }
  saveReleaseOrderEntry(postdata) {
    return this.apiService.post(environment.base_value.create_order_Entry, postdata);
  }
  updateOrderEntry(id, data) {
    return this.apiService.put(environment.base_value.edit_order_entry + '/' + id, data);
  }
  saveRoActivtiy(id, postdata) {
    return this.apiService.post(environment.base_value.create_RO_Activity + '?campaignDetailId=' + id, postdata);
  }

  UploadAd(id, uploadFile, attachmentId) {
    const formData = new FormData();
    formData.append('file', uploadFile);
    let url = environment.base_value.multipartUpload + '?activityId=' + id
    if(attachmentId !== null){
      url += '&id=' + attachmentId
    }
    return this.apiService.put(url,formData);
  }
  getAdUpload(id, type){
    return this.apiService.get(environment.base_value.get_adv_uploads + '/' + id + '?type=' + type);
  }
  updateRoActivtiy(id, data) {
    return this.apiService.put(environment.base_value.edit_RO_Activity + '/' + id, data);
  }
  getAllcampaign(id) {
    return this.apiService.get(environment.base_value.get_all_campaign + '?id=' + id);
  }
  getAllActivity(id) {
    return this.apiService.get(environment.base_value.get_all_RO_activity + '?id=' + id);
  }
  createAgents(postdata) {
    return this.apiService.post(environment.base_value.creat_agent, postdata);
  }
  editAgents(id,data) {
    return this.apiService.put(environment.base_value.update_agent + '/' + id, data);
  }
  saveAgentAddress(postData) {
    return this.apiService.post(environment.base_value.createAgent_address, postData);
  }
  updateAgentAddress(data) {
    return this.apiService.put(environment.base_value.editAgent_address, data);
  }
  getAgent(id){
    if(id && id !== 'All'){
    return this.apiService.get(environment.base_value.get_all_agent + "?type=" + id)
    } else {
    return this.apiService.get(environment.base_value.get_all_agent)
    }
  }
  getSearchAgent(type, text){
    return this.apiService.get(environment.base_value.get_all_agent +"?type=" + type + '&sText=' + text)
  }
  getAllAgentInfo(id, type){
    return this.apiService.get(environment.base_value.get_all_agent_address +"?entityId=" + id + '&entityType=' + type);
  }

  getApiWithUrl(url) {
    return this.apiService.get(url);
  }
  clearcache(data){
    return this.apiService.post(environment.base_value.clear_cache, data);
  }
}

