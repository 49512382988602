/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
export * from './api.service';
export * from './common.service';
// export * from './http.service';
export * from './configuration.service';
export * from './hospital.service';
export * from './asset.service';
export * from './report.service';
export * from './error.service';
export * from './dashboard.service';
export * from './excel.service';
export * from './pdf.service';
export * from './jspdf.service';
export * from './workflow.service';
export * from './chart.service';
export * from './chartjs.service';
export * from './layout-excel.service';
export * from './layout-pdf.service';