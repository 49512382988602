<!-- <div style="height: 100%;" (window:resize)="onResize($event)">
    <mat-toolbar class="mat-elevation-z4" style="background-color: whitesmoke; height: 8%;">
        <div style="width: 100%;">
            <div class="leaf-toolbar1-header">
                <span class="leaf-toolbar1-header-styl1">Floor View {{popupType ? "- " + popupType : ''}}</span>
            </div>
            <div class="leaf-toolbar1-close">
                <button mat-icon-button mat-dialog-close style="height: 100%;">
                    <mat-icon style="line-height: 15px !important;">highlight_off</mat-icon>
                </button>
            </div>
        </div>
    </mat-toolbar>
    <app-common-leaflet [blockSelect]="selectedBlock" [floorSelect]="selectedFloor" [locFlr]="selectedLoc" [tagId]="tagId" [tagType]="tagType" [type]="type" [options]="contextOptions"></app-common-leaflet>
</div> -->
<div class="ovi-font-family" style="height: 100%;">
    <div class="dialog-header" *ngIf="contentDetail['content'] == 'floorplan' || contentDetail['content'] == 'history'">
        <span *ngIf="contentDetail['content'] == 'floorplan'" style="float: left;">FLOOR PLAN VIEW {{popupType ? "- " +
            popupType : ''}} </span>
        <span *ngIf="contentDetail['content'] == 'history'" style="float: left;"> Movement History</span>
        <span style="float: right;">
            <button mat-icon-button mat-dialog-close style="width: 30px !important;height: 30px !important;">
                <mat-icon style="line-height: 5px !important;">highlight_off</mat-icon>
            </button>
        </span>
    </div>
    <div class="leaflet-content" *ngIf="contentDetail['content'] == 'floorplan'">
        <app-common-leaflet [leafRef]="'popup'" [blockSelect]="selectedBlock" [floorSelect]="selectedFloor"
            [locFlr]="selectedLoc" [tagId]="tagId" [tagType]="tagType" [type]="type" [options]="contextOptions"
            [reqTagDetail]="reqDetail" [reqType]="reqType"></app-common-leaflet>
    </div>
    <!-- <div class="leaflet-content" *ngIf = "content == 'location'">
        <common-location style="width: 100%; height:100%;"></common-location>
    </div> -->
    <div *ngIf="contentDetail['content'] == 'form'" style="height:calc(100% - 100px);width:100%">
        <app-form-component [formInput]="contentDetail"
            (formInputUpdatedData)=formInputUpdatedData($event)></app-form-component>
    </div>
    <div *ngIf="contentDetail['content'] == 'history'" style="height:calc(100% - 100px);width:100%">
        <div style="padding-left: 20px;">
                <div i18n id="porter-box1">Performer</div>
                <div id="porter-box2">
                    <mat-form-field class="porter-select" appearance="none">
                        <mat-select placeholder="Select Performer" [formControl]="performerId">
                            <mat-option *ngFor="let performer of perfomerList" [value]="performer.id">
                                {{performer.name}} ( {{performer.tagId}} )
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>&nbsp;&nbsp;
            <button i18n mat-button class="active-btn margin-r-1" (click)="poterTrackHistory()"> Go </button>
        </div>

        <div fxLayout="column" class="tw-manage-component porterhis" [ngClass.xs]="'components-container-xs'"
            fxLayoutGap="5px">
            <div style="overflow: auto;">
                <table mat-table class="tw-table" [dataSource]="dataSourceViewHistory">
                    <ng-container matColumnDef="blockName">
                        <th i18n mat-header-cell *matHeaderCellDef class="tw-manage-table-header"> Location</th>
                        <td mat-cell *matCellDef="let element"> {{element['Location Name']}},{{element['Floor Name']}} </td>
                    </ng-container>
                    <ng-container matColumnDef="fromTime">
                        <th i18n mat-header-cell *matHeaderCellDef class="tw-manage-table-header"> From Time </th>
                        <td mat-cell *matCellDef="let element"> {{element['From time'] | date: "hh:mm a, d MMM"}} </td>
                    </ng-container>

                    <ng-container matColumnDef="toTime">
                        <th i18n mat-header-cell *matHeaderCellDef class="tw-manage-table-header"> To Time </th>
                        <td mat-cell *matCellDef="let element"> {{element['To Time'] | date: "hh:mm a, d MMM"}} </td>
                    </ng-container>
                    <ng-container matColumnDef="duration">
                        <th i18n mat-header-cell *matHeaderCellDef class="tw-manage-table-header"> Duration </th>
                        <td mat-cell *matCellDef="let element">{{element['Duration']}}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"
                        class="tw-manage-table-header-row"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 15, 20, 50, 100]" showFirstLastButtons
                class="tw-paginator-sticky"></mat-paginator>
        </div>
    </div>
</div>