<div class="input-component-holder">
    <mat-toolbar class="input-header block-1" style="height: 35px; padding-top: 5px;">
        <div style="display: flex;width: 100%">
            <div i18n class="header-name close-align-ack">
                Confirmation </div>
            <div class="header-close"><button mat-icon-button mat-dialog-close close-button class="ie-close-icon">
                <mat-icon class="icon-style">highlight_off</mat-icon>
            </button></div>
        </div>
    </mat-toolbar>
    <div class="mat-custom-form-field-box popup-margin" style="height: 175px;overflow: hidden;">
        <mat-tab-group [formGroup]="ackForm" (selectedTabChange)="tabClick($event)"
            [selectedIndex]="selectedIndex">
            <mat-tab i18n-label label="Acknowledgement">
                <div style="overflow: hidden;">
                    <div class="form-row block-3" style="width: 100%;">
                        <div style="width: 100%;display: flex;padding-top: 60px;">
                            <div class="remarks-label-styl" style="padding-top: 15px;">
                                <mat-label i18n class="ovi-lbl-text-size">Remarks : </mat-label>
                            </div>
                            <div class="remarks-styl">
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <textarea i18n-placeholder matInput placeholder="Remarks" formControlName="comments"></textarea>
                                    <mat-error *ngIf="ackForm.controls.comments.invalid">
                                        <span i18n [hidden]="!ackForm.controls['comments'].errors.required">Remarks is
                                            required.</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab i18n-label label="Assign" *ngIf = "false">
                <div style="overflow: hidden;">
                    <div class="form-row block-3" style="width: 100%;">
                        <div style="width: 100%;display: flex;padding-top: 20px;">
                            <div class="remarks-label-styl" style="padding-top: 15px;">
                                <mat-label i18n class="ovi-lbl-text-size">Remarks : </mat-label>
                            </div>
                            <div class="remarks-styl">
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <textarea i18n-placeholder matInput placeholder="Remarks" formControlName="comments"></textarea>
                                    <mat-error *ngIf="ackForm.controls.comments.invalid">
                                        <span i18n [hidden]="!ackForm.controls['comments'].errors.required">Remarks is
                                            required.</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="form-row block-3">
                        <div class="row-field-5">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">Assign Type <span class="mandatory-field">*</span></mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <mat-select i18n-placeholder placeholder="Type" formControlName="assignType"
                                        [disableOptionCentering]="true" (selectionChange)="getAssignType($event.value)">
                                        <mat-option *ngFor="let data of assignTypeList" [value]="data.code">
                                            {{data.value}}</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="ackForm.controls.assignType.invalid">
                                        <span i18n [hidden]="!ackForm.controls['assignType'].errors.required">Assign Type
                                            is required.</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row-field-5">
                            <div class="example-form">
                                <mat-label i18n class="ovi-lbl-text-size">Assign Value <span class="mandatory-field">*</span></mat-label>
                                <mat-form-field class="example-full-width form-field" appearance="outline">
                                    <input matInput i18n-placeholder placeholder="" id="assignValue"
                                        formControlName="assignValue" appTypeHit (typeHit)="searchList($event)"
                                        [matAutocomplete]="auto" autocomplete="off">
                                    <mat-autocomplete autoActiveFirstOption
                                        [displayWith]="this.assignValueEnabled === true ? getAssignValueList.bind(assignValueList) : ''"
                                        #auto="matAutocomplete">
                                        <mat-option *ngFor="let option of assignValueList" [value]="option.id">
                                            <div class="act-wid-styl" (click)="getId(option)">{{option.name}}</div>
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="ackForm.controls.assignValue.invalid">
                                        <span i18n [hidden]="!ackForm.controls['assignValue'].errors.required">Assign Value
                                            is required.</span>
                                        <span i18n [hidden]="!ackForm.controls['assignValue'].errors.requireMatch">Please
                                            select value from the option</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="input-footer-pad footer-height">
        <button i18n mat-button style=" margin-right: 10px;" class="cancle-btn" mat-dialog-close>Cancel</button>
        <button i18n mat-button class="active-btn margin-r-1" appDebounceClick
        (debounceClick)="saveAck(data)" [debounceTime]="300"cdkFocusInitial
        [disabled]="this.ackForm.invalid">OK</button>
    </div>
</div>