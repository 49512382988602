<mat-sidenav-container class="container" fullscreen>
    <mat-sidenav [mode]="sideNavMode" class="sidenav oflw-styl view-mobile" #sidenav [opened]="sideNavOpened">
        <!-- <mat-toolbar class="mat-elevation-z4 img-header-color">
            <img src="./assets/images/fav.png" width="36px">
            <h1 class="logo">Trackerwave.<span class="fnt-styl">com</span></h1>
        </mat-toolbar> -->
        <cdk-sidemenu [code]="code"></cdk-sidemenu>
    </mat-sidenav>
    <mat-sidenav-content class="sid-nav-styl">
        <mat-drawer-container fullscreen>
            <!-- <cdk-toolbar [sidenav]="sidenav" [drawer]="drawer" [sidebar]="sidenav2" [menuName]="menuValue" class="mt-dw-styl" [matDrawerShow]="matDrawerShow"></cdk-toolbar> -->
            <!-- <cdk-toolbar [sidenav]="sidenav" [drawer]="drawer" [sidebar]="sidenav2" [menuName]="menuValue" class="mt-dw-styl"></cdk-toolbar> -->
            <app-navbar *ngIf="!existPrivateUser"></app-navbar>
            <div class="frame-size block-1">
                <!--style added for new menu-->
                <div class="sidenav-toggle" >
                    <button class="toggle-button" mat-icon-button style="object-fit: contain; color: rgb(3, 0, 0);"
                        (click)="sidenav.toggle()">
                        <mat-icon style="line-height: 20px;width: 28px;">
                            menu
                        </mat-icon>
                    </button>
                </div>


                <div *ngIf="menuOpen && !existPrivateUser" #sidenav2 class="side-menu-tab tb-styl" [ngStyle]="{'background': customerName === 'kyn' ? '#D61820' : ''}">
                    <!-- NEW MENU CODE START -->
                    <div *ngFor="let menulist of menu">
                        <div *ngIf="menulist.link === null" #hnav
                                [ngClass]="{'nav-select' : customerName === null && menulist.id == this.parentId, 'kyn-nav-select': customerName === 'kyn' && menulist.id == this.parentId}" id="hnav"
                                class="f-l men-lst-styl" (click)="menuClicked(menulist, null)">
                            <img class="menu-img svg-styl" appTooltip [tooltipText]="menulist.name" placement="bottom-right" matListIcon
                                src="/assets/Menus/{{ menulist.iconName }}.svg">
                        </div>
                        <div *ngIf="menulist.link !== null" #hnav routerLinkActive="customerName === 'kyn' ? 'kyn-nav-select' : 'nav-select'"
                        (click)="menuClicked(menulist, null);menuDetails(menulist);"
                                [ngClass]="{'nav-select' : menulist.id == this.parentId, 'kyn-nav-select': customerName === 'kyn' && menulist.id == this.parentId}" id="hnav"
                                class="f-l men-lst-styl" [routerLink]="[menulist.link]">
                            <img class="menu-img svg-styl" appTooltip [tooltipText]="menulist.name" placement="bottom-right" matListIcon
                                src="/assets/Menus/{{ menulist.iconName }}.svg">
                        </div>
                    </div>
                    <!-- NEW MENU CODE END -->
                    <div class="mt-lst-styl">
                        <mat-icon class="mt-lst-styl_1" [ngStyle]="{'background-color': customerName === 'kyn' ? '#790101' : ''}" matListIcon iconsmall (click)="closeMenu();">
                            keyboard_arrow_left</mat-icon>
                    </div>
                </div>
                <!-- NEW MENU CODE START -->
                <div *ngIf="menuClickedCode !== []">
                    <div *ngFor="let menu of menuClickedCode">
                            <cdk-sidebar-menu *ngIf="menu.code === this.menuCode" [code]="menu.code"
                                [menName]="menu.name">
                        </cdk-sidebar-menu>
                    </div>
                </div>
                <!-- NEW MENU CODE END -->
                <div [class]="menuOpen? 'menuopen': 'menuclose'" [ngStyle]="existPrivateUser && {'width': '100%'}">
                    <router-outlet></router-outlet>
                </div>
                <div *ngIf="!menuOpen"
                    class="cc-window cc-floating cc-type-info cc-theme-block cc-bottom cc-right cc-color-override-688238583 ">
                    <div class="en-lst-styl_3" (click)="extendMenu();">
                        <mat-icon matListIcon iconsmall pd-styl style="font-size: 30px;">keyboard_arrow_right</mat-icon>
                    </div>
                </div>

            </div>
        </mat-drawer-container>
    </mat-sidenav-content>
    <mat-sidenav class="sid-nav-styl_" #sidenav2 position="end" mode="over">
        <cdk-sidebar [sidebar]="sidenav2" (valueChange)="displayMenuName($event)"></cdk-sidebar>
    </mat-sidenav>
</mat-sidenav-container>