<div style="overflow:hidden !important" class="custom-dialog-box">
    <mat-toolbar class="input-header">
        <div i18n class="header-name" *ngIf="!this.data">Create Layout</div>
        <div i18n class="header-name" *ngIf="this.data">Modify Layout</div>
        <button mat-icon-button mat-dialog-close>
            <mat-icon style="font-size: 21px;">highlight_off</mat-icon>
        </button>
    </mat-toolbar>
    <div class="form-body-large-das mat-custom-form-field-box" [ngStyle]="{'height': tabClickedLabel === 'Config' ? '73%' : '93%'}">
        <mat-tab-group class="layout-tab" style="height:calc(100% - 55px)" (selectedTabChange)="tabClick($event)">
            <mat-tab i18n-label label="Widgets">
                <app-common-layout [dashId]="dashId" [type]="data?'update':'create'" (updateSaveAction)="updateSaveAction($event)"></app-common-layout>
            </mat-tab>
            <mat-tab i18n-label label="Config" *ngIf="this.data !== null">
                <div style="height: 100%; width: 100%;padding-top: 10px;" [formGroup]="configForm">
                    <div class="form-row" style="margin-left: 10px;">
                        <div i18n class="ovi-popup-title-text-size top-styl">FILTER INPUTS</div>
                    </div>
                    <mat-divider class="divider"></mat-divider>
                    <div class="form-row block-3" style="margin: 10px;">
                        <div [ngStyle]="{'padding-top' : configForm.value.enablePdf?'18px':none}" style="display: flex;">
                            <div class="example-form">
                                <span class="config-space-around">
                                    <mat-checkbox i18n formControlName="enableExcel">Enable Excel</mat-checkbox>
                                </span>
                            </div>
                            <div class="example-form">
                                <span class="config-space-around">
                                    <mat-checkbox i18n formControlName="enableRefresh">Enable Refresh</mat-checkbox>
                                </span>
                            </div>
                            <div class="example-form">
                                <span class="config-space-around">
                                    <mat-checkbox i18n formControlName="enableHeader">Enable Header</mat-checkbox>
                                </span>
                            </div>
                            <div class="example-form">
                                <span class="config-space-around">
                                    <mat-checkbox i18n formControlName="enablePdf">Enable Pdf</mat-checkbox>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="configForm.value.enablePdf">
                            <span class="config-space-around">
                                <div class="example-form">  
                                    <mat-label i18n class="ovi-lbl-text-size">Select Layout Size:</mat-label>      
                                    <mat-form-field class="example-full-width form-field" appearance="outline">
                                        <mat-select i18n-placeholder placeholder="Select Size" formControlName="layoutSize" required>
                                            <mat-option *ngFor="let data of filterLayoutList" [value]="data.code">
                                            {{data.value}}</mat-option>
                                        </mat-select>
                                        <mat-error class="error-txt-1">
                                            Select Input is required.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </span>
                        </div>
                    </div>
                    <div formArrayName="inputList">
                        <mat-divider class="divider"></mat-divider>
                        <div *ngIf="filterInputsList?.length > configForm.controls.inputList.controls?.length">
                            <button i18n mat-mini-fab style="float:right;margin-top: -20px;margin-right: 25px;"
                                (click)="addInputs()">+</button>
                        </div>
                        <div *ngFor="let obj of configForm.controls.inputList.controls; let i=index">
                            <div [formGroupName]="i" style="margin-top: 20px;">
                                <div class="form-row block-3" style="margin: 10px;">
                                    <div class="row-field-10">
                                        <div class="example-form">  
                                            <mat-label i18n *ngIf="i === 0" class="ovi-lbl-text-size">Select Inputs *</mat-label>      
                                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                                <mat-select i18n-placeholder placeholder="Select Inputs" formControlName="name" required>
                                                    <mat-option *ngFor="let data of filterInputsList" [value]="data.name"
                                                    [ngStyle]="{display: inputOption?.indexOf(data.name) > -1 && data.name != 'Appterms' ? 'none' : 'block'}"
                                                    (click)="bindInputs(data, i)">
                                                    {{data.name}}</mat-option>
                                                </mat-select>
                                                <mat-error class="error-txt-1">
                                                    Select Input is required.
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="row-field-10">
                                        <div class="example-form"> 
                                            <mat-label i18n *ngIf="i === 0" class="ovi-lbl-text-size">Label Name</mat-label>       
                                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                                <input matInput i18n-placeholder placeholder="Label Name" formControlName="label"
                                                    autocomplete="off" role="presentation">
                                                    <mat-error class="error-txt-1">
                                                        Label is required.
                                                    </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div *ngIf="this['inputData' + i]?.name !== 'fromDate' && this['inputData' + i]?.name !== 'toDate' &&
                                    this['inputData' + i]?.name !== 'fromDateTime' && this['inputData' + i]?.name !== 'toDateTime'" class="row-field-10">
                                        <div class="example-form"> 
                                            <mat-label i18n *ngIf="i === 0" class="ovi-lbl-text-size">Default Value</mat-label>       
                                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                                <input matInput i18n-placeholder placeholder="Default Value" formControlName="default"
                                                    autocomplete="off" role="presentation">
                                                    <mat-error class="error-txt-1">
                                                        Default Value is required.
                                                    </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div *ngIf="this['inputData' + i]?.name === 'fromDate' || this['inputData' + i]?.name === 'toDate'" class="row-field-10">
                                        <div class="example-form"> 
                                            <mat-label i18n *ngIf="i === 0" class="ovi-lbl-text-size">Default Value</mat-label>       
                                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                                <input matInput i18n-placeholder [matDatepicker]="date" class="tw-input-date" style="padding: 0px;"
                                                placeholder="" formControlName="default" [max]="today">
                                                <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                                                <mat-datepicker #date></mat-datepicker>
                                                    <mat-error class="error-txt-1">
                                                        Default Value is required.
                                                    </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div *ngIf="this['inputData' + i]?.name === 'fromDateTime' || this['inputData' + i]?.name === 'toDateTime'" class="row-field-10">
                                        <div class="example-form"> 
                                            <mat-label i18n *ngIf="i === 0" class="ovi-lbl-text-size">Default Value</mat-label>       
                                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                                <input matInput type="datetime-local" id="datepicker" i18n-placeholder class="tw-input-date" style="padding: 0px;"
                                                    placeholder="From Date" formControlName="default" [max]="today">
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="row-field-10">
                                        <div class="example-form"> 
                                            <mat-label i18n *ngIf="i === 0" class="ovi-lbl-text-size">Input ID</mat-label>       
                                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                                <input matInput i18n-placeholder placeholder="Input ID" formControlName="id"
                                                    autocomplete="off" role="presentation">
                                                    <mat-error class="error-txt-1">
                                                        Input ID is required.
                                                    </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="row-field-10">
                                        <div class="example-form">
                                            <mat-label i18n *ngIf="i === 0" class="ovi-lbl-text-size">Sequence</mat-label>
                                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                                <input matInput type="number" min="1" max ="20" maxlength="2" i18n-placeholder placeholder="Sequence"
                                                formControlName="seq" autocomplete="off">
                
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div *ngIf="this['inputData' + i]?.name === 'Appterms'" class="row-field-10">
                                        <div class="example-form">
                                            <mat-label i18n *ngIf="i === 0" class="ovi-lbl-text-size">Appterms Group</mat-label>
                                            <mat-form-field class="example-full-width form-field" appearance="outline">
                                                <mat-select i18n-placeholder placeholder="Select Appterms Group" formControlName="groupName" required>
                                                    <mat-option *ngFor="let data of apptermGroupList" [value]="data.code">
                                                    {{data.code}}</mat-option>
                                                </mat-select>
                                                <mat-error class="error-txt-1">
                                                    <span>Select Appterms Group</span>
                                                  </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div [ngStyle]="{'margin-top': i === 0 ? '25px' : '5px'}">
                                        <div class="example-form">
                                            <span class="config-space-around">
                                                <mat-checkbox i18n formControlName="required">Is Required</mat-checkbox>
                                            </span>
                                        </div>
                                    </div>

                                    <div [ngStyle]="{'margin-top': i === 0 ? '25px' : '5px'}">
                                        <div class="example-form">
                                            <span class="config-space-around">
                                                <mat-checkbox i18n formControlName="visibled">Is Visible</mat-checkbox>
                                            </span>
                                        </div>
                                    </div>

                                    <div>
                                        <span><button mat-icon-button close-button class="ie-close-icon"
                                            (click)="removeInputs(i, 'clear')" [ngStyle]="{'margin-top': i === 0 ? '20px' : ''}">
                                             <mat-icon class="icon-style" style="color: red;font-size:30px;">highlight_off</mat-icon> </button></span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab i18n-label label="Layout" *ngIf="!layoutTabDisp">
                <div *ngIf="!layoutTabEnable" style="height: 100%; width: 100%;">
                    <div *ngIf="configForm.controls.enableHeader.value" style="height: 18%; width: 98%;">
                        <tw-layout-header-component [layout]="'dynamic'" [enableExcel]="configForm.controls.enableExcel.value" [enablePdf]="configForm.controls.enablePdf.value" [enableRefresh]="configForm.controls.enableRefresh.value"
                        [filterInputs]="configForm.controls.inputList.value" [dashboardId]="" (reportHeaderAction)="reportHeaderAction($event)" [pdfConfigSelected]="configForm.controls.layoutSize.value"></tw-layout-header-component>
                    </div>
                    <div [ngStyle]="{height: configForm.controls.enableHeader.value ? '82%' : '100%'}">
                        <app-gridster-layout [dashId]="dashId" [resourceCode]="" [dashData]="" [type]="'popup'"></app-gridster-layout>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="input-footer" style="padding: 10px 0px 10px 0px;text-align: right !important;" *ngIf="tabClickedLabel === 'Config'">
        <button i18n mat-button class="cancle-btn" style="margin-right: 5px;" mat-dialog-close >Cancel</button>
        <button i18n mat-button class="active-btn margin-r-1" appDebounceClick [disabled]="configForm.value.enablePdf && configForm.value.enableHeader ? false : configForm.invalid"
        (debounceClick)="saveInputs()" [debounceTime]="300" cdkFocusInitial>Save</button>
    </div>
</div>
