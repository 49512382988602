/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Component, OnInit, ViewChild, Inject, ViewEncapsulation, HostListener, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {DatePipe} from '@angular/common';
import { routerTransition } from '../../../router.animations';
import {HospitalService, CommonService} from '../../../shared';
import * as moment_ from 'moment';

const moment = moment_;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
import { CreateUserComponent } from '../../../shared/modules/entry-component/create-user/create-user.component';
import { locale_Json_Details } from '../../../../localeJson/localeJson';
import { ErrorStateMatcherService } from '../../../shared/services/error-state-matcher.service';

import { ActivatedRoute, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class UserManagementResolver implements Resolve<Observable<any>> {
  constructor(private hospitalService: HospitalService,) {}

  resolve(): Observable<any> {
    return this.hospitalService.getAllUserslist(null,0,10);
  }
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  animations: [routerTransition()],
})
export class UserManagementComponent implements OnInit {
  referTokenType = localStorage.getItem('userBgColor');
  referTokenUser = localStorage.getItem('dXNlcklk')
  // displayedColumns: string[] = ['userName', 'birthdate', 'currentLocation', 'Speciality', 'email', 'phoneNumber', 'status'];
  displayedColumns: string[] = ['userName', 'roles', 'email', 'phoneNumber', 'id', 'status'];

  dataSource: MatTableDataSource<any>;
  public matcher = new ErrorStateMatcherService();
  public selectedName: any;
  public rowData: any = [];
  public activate_btn: any = [];
  public maxHeight: any;
  public applyFilterValue: any;
  headercolor: string;
  bgcolor: string;
  pagebgcolor: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public height: number;
  width: number;
  tooltipOver: string;
  customerName: string;
  filterValue: null;
  pageSize:number=10;
  pageStart:number=0;
  length: any = 0;
  pageHit = false;
  name = null;

  constructor(
    private hospitalService: HospitalService,
    public dialog: MatDialog, public commonService: CommonService,private route: ActivatedRoute
  ) {
    this.activate_btn = this.commonService.getActivePermission('button');
    if((this.activate_btn.indexOf('BT_ALLE') > -1 || this.activate_btn.indexOf('BT_HPMUE') > -1)){
      const locale = localStorage.getItem(btoa('lang'))
      if(locale === 'en-US' || locale === 'en'){
        this.tooltipOver = locale_Json_Details.en.clickTooltip;
      } else if(locale === 'ar'){
        this.tooltipOver = locale_Json_Details.ar.clickTooltip;
      }
    }
  }

  ngOnInit() {
    if (window.location.hostname.includes("kyn")) {
      this.customerName = "kyn";
    }
    this.getAllUsers(false,null,this.pageStart,this.pageSize);
    if ('userColor' in localStorage || 'userBgColor' in localStorage || 'userPageBgColor' in localStorage) {
      this.headercolor = localStorage.getItem('userColor');
      this.bgcolor     = localStorage.getItem('userBgColor');
      this.pagebgcolor = localStorage.getItem('userPageBgColor');
    
    } else {
      this.headercolor = '#3f586a';
      this.bgcolor = '#ffffff';
      this.pagebgcolor = '#ffffff';
    }

    
  }
  eventTriggers(event){
    this.pageStart = event.pageIndex;
    this.pageSize  = event.pageSize;
    this.getAllUsers(false,this.applyFilterValue,this.pageStart,this.pageSize);
  }
  // applyFilter(filterValue: string) {
  //   filterValue = filterValue.trim(); 
  //   filterValue = filterValue.toLowerCase(); 
  //   this.applyFilterValue = filterValue;
  //   this.dataSource.filter = filterValue;
  // }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.applyFilterValue = filterValue;
    // this.dataSource.filter = filterValue;
    if (this.applyFilterValue.length > 2){
      this.getAllUsers(false,this.applyFilterValue,this.pageStart,this.pageSize);
    } else if(this.applyFilterValue.length == 0) {
      this.getAllUsers(false,null,this.pageStart,this.pageSize);
    }
  }

  createUser(rowData: any, event: any) {
    this.rowData = rowData;
    if (this.rowData.id) {
      this.rowData['type'] = 'user';
    }
    this.selectedName = rowData.userName;
    if (rowData.birthdate) {
      this.rowData.birthdate = new Date(this.rowData.birthdate);
    }
    const dialogRef = this.dialog.open(CreateUserComponent,
    {data : rowData, panelClass: ['small-popup'], disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') { 
        this.getAllUsers();
      }
    });
  }

  rowClick(data) {
    this.selectedName = data.userName;
  }

  getAllUsers(routerEvent?: boolean,name?:string, pageStart?: number, pageSize?: number): void {
    if (routerEvent) {
      const results = this.route.snapshot.data.userManagements.results;
      this.pageHit = true;
      results.sort((a, b) => {
        if (a.id < b.id) {
          return 1;
        } else
          if (a.id > b.id) {
            return -1;
          } else {
            return 0;
          }
      });
      this.dataSource = new MatTableDataSource<any>(results);
      if(this.applyFilterValue != null){
        this.dataSource.filter = this.applyFilterValue;
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
    this.hospitalService.getAllUserslist(name,this.pageStart,this.pageSize).subscribe(res => {
    // this.hospitalService.getAllUsers(null,null,name,this.pageStart,this.pageSize).subscribe(res => {
      const results = res.results;
      results.sort((a, b) => {
        if (a.id < b.id) {
          return 1;
        } else
          if (a.id > b.id) {
            return -1;
          } else {
            return 0;
          }
      });
      this.dataSource = new MatTableDataSource<any>(res.results);
      this.length = res.totalRecords
      this.pageHit = true;
      if(this.applyFilterValue != null){
        this.dataSource.filter = this.applyFilterValue;
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      if(this.referTokenType === '#refer-plus'){
        let filterRowData = res.results.filter(res => this.referTokenUser == res.id)
        this.createUser(filterRowData[0], '')
        this.dataSource = filterRowData
      }
    });
   }
}
}
