<div class="comments-container">
    <div *ngFor="let k of tktHistoryData['key']">
        <span style="float: left;margin-left: 4px;">
            <span class="circles"
                [ngClass]="k === 'RQ-OP' ? 'redlight': (k === 'RQ-IP' ? 'orangelight': 'greenlight')">
                <span class="circle-desc">{{tktHistoryData['data'][k][0].statusName}}</span>
            </span>
            <!-- <span class="bar" *ngIf="k != barLineData"></span> -->
        </span>
        <div class="container" *ngFor="let object of tktHistoryData['data'][k]; let j= index">
            <div class="assessment-card gap"
                [ngStyle]="{'border-left': k != trackLineData ? 'solid 2px #9b9b9b' : ''}">
                <div class="header">
                    <span i18n class="assignee"> 
                        {{k === 'RQ-OP'? openLabeltext : updateLabeltext}} : {{k === 'RQ-OP'? object.createdUser : null}} &nbsp;
                        {{k === 'RQ-OP' ? 'Assigned to :' : ''}} {{object.name}} at {{object.eventTime | date : "hh:mm a, d MMM"}}</span>
                </div>
                <span class="content">
                    {{object.comments}}
                    <span class="attach" *ngFor="let attach of attachment; let i= index">
                        <div *ngIf="attach.entityId === object.id">
                           <img *ngIf="attach.fileType === 'image/png' || attach.fileType === 'image/jpeg'" [src]="attach.fileUrl" (click)="lightboxView(attach.fileUrl, attach.fileType)" appTooltip tooltipText="Click To View" class="img-siz"/>
                           <img *ngIf="attach.fileType === 'application/pdf'" src="/assets/Alert/common_icons/pdf_icon.png" (click)="lightboxView(attach.fileUrl, attach.fileType)" appTooltip tooltipText="Click To View" class="img-siz"/>
                        </div>
                    </span>
                </span>
            </div>
        </div>
        <mat-divider class="divider" style="width: 85%;margin-left: 11%;margin-bottom: 6px;"></mat-divider>
    </div>
</div>