<div class="timepicker-backdrop-overlay" *ngIf="isOpened" overlay></div>
<div class="timepicker-overlay" *ngIf="isOpened">
    <div class="timepicker" [@timepicker]="animationState" (@timepicker.done)="animationDone($event)" tabindex="0"
         focusAnchor>
        <header class="timepicker__header">
            <div class="timepicker-dial">
                <div class="timepicker-dial__time">
				<span class="timepicker-dial__item"
                      [ngClass]="{'timepicker-dial__item_active': activeTimeUnit === timeUnit.HOUR}"
                      (click)="changeTimeUnit(timeUnit.HOUR)">
					{{selectedHour?.time | timeFormatter: timeUnit.HOUR}}</span>:<span
                    class="timepicker-dial__item"
                    [ngClass]="{'timepicker-dial__item_active': activeTimeUnit === timeUnit.MINUTE}"
                    (click)="changeTimeUnit(timeUnit.MINUTE)">{{selectedMinute?.time | timeFormatter: timeUnit.MINUTE}}</span>
                </div>
                <div class="timepicker-dial__period" *ngIf="format !== 24">
			<span class="timepicker-dial__item"
                  [ngClass]="{'timepicker-dial__item_active': selectedPeriod === timePeriod.AM}"
                  (click)="changePeriod(timePeriod.AM)" i18n>AM</span>
                    <span class="timepicker-dial__item"
                          [ngClass]="{'timepicker-dial__item_active': selectedPeriod === timePeriod.PM}"
                          (click)="changePeriod(timePeriod.PM)" i18n>PM</span>
                </div>
            </div>
        </header>
        <div class="timepicker__main-content">
            <div class="timepicker__body" [ngSwitch]="activeTimeUnit">
                <div *ngSwitchCase="timeUnit.HOUR">
                    <ngx-material-timepicker-24-hours-face *ngIf="format === 24;else ampmHours"
                                                           (hourChange)="onHourChange($event)"
                                                           [selectedHour]="selectedHour"
                                                           [minTime]="minTime"
                                                           [maxTime]="maxTime"
                                                           [format]="format"
                                                           (hourSelected)="activeTimeUnit = timeUnit.MINUTE"></ngx-material-timepicker-24-hours-face>
                    <ng-template #ampmHours>
                        <ngx-material-timepicker-12-hours-face
                            (hourChange)="onHourChange($event)"
                            [selectedHour]="selectedHour"
                            [period]="selectedPeriod"
                            [minTime]="minTime"
                            [maxTime]="maxTime"
                            (hourSelected)="activeTimeUnit = timeUnit.MINUTE"></ngx-material-timepicker-12-hours-face>
                    </ng-template>
                </div>
                <ngx-material-timepicker-minutes-face *ngSwitchCase="timeUnit.MINUTE"
                                                      [selectedMinute]="selectedMinute"
                                                      [selectedHour]="selectedHour?.time"
                                                      [minTime]="minTime"
                                                      [maxTime]="maxTime"
                                                      [period]="selectedPeriod"
                                                      (minuteChange)="onMinuteChange($event)"></ngx-material-timepicker-minutes-face>
            </div>
            <div class="timepicker__actions">
                <div (click)="close()">
                    <ng-container *ngTemplateOutlet="cancelBtnTmpl ? cancelBtnTmpl : cancelBtnDefault"></ng-container>
                </div>
                <div (click)="setTime()">
                    <ng-container
                        *ngTemplateOutlet="confirmBtnTmpl ? confirmBtnTmpl : confirmBtnDefault"></ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #cancelBtnDefault>
    <ngx-material-timepicker-button i18n>Cancel</ngx-material-timepicker-button>
</ng-template>
<ng-template #confirmBtnDefault>
    <ngx-material-timepicker-button i18n>Ok</ngx-material-timepicker-button>
</ng-template>
