<div class="frame-styl-com ovi-font-family">
    <div *ngIf="data !== null" class="frame-styl-com_1">
        <div class="titl-styl">{{title}}</div>
        <button mat-icon-button mat-dialog-close class="btn-styl-com">            
            <mat-icon class="icon-style">highlight_off</mat-icon>
        </button>
    </div>

    <div class="input-container inner-frame-size">

    <div *ngIf="requestInfo" class="widget-class wid-styl">
        <mat-grid-list cols="5" rowHeight="28px">
            <mat-grid-tile colspan="1" rowspan="1">
                <div i18n class="grd-styl_">Patient Name</div>
                <div class="grd-styl_1" *ngIf="requestDetails['nonPerformer']?.length > 0">: {{requestDetails['nonPerformer'][0].name}}</div><br>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
                <div i18n class="grd-styl_">From</div>
                <div class="grd-styl_1">: {{requestDetails.sourceLocationName}}</div><br>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
                <div i18n class="grd-styl_">Start Time</div>
                <div styclass="grd-styl_1">: {{requestDetails.startTime | date : 'hh:mm a'}}</div><br>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
                <div i18n class="grd-styl_">No. of Porter</div>
                <div class="grd-styl_1">: {{requestDetails.porterCount}}</div><br>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="2">
                <!-- <select class="widget-class custom-select" (change)="onSelectMonitorTag($event)">
                    <option *ngFor="let tag of porterRequestTagsList" value="{{tag.tag_id}}">{{tag.name}} ({{tag.type}})
                    </option>
                </select> -->
            </mat-grid-tile>

            <mat-grid-tile colspan="1" rowspan="1">
                <div i18n class="grd-styl_">Status</div>
                <div class="grd-styl_1">: {{requestDetails.statusName}}
                </div><br>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
                <div i18n class="grd-styl_">To</div>
                <div class="grd-styl_1">: {{requestDetails.destinationLocationName}}</div><br>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
                <div i18n class="grd-styl_">End Time</div>
                <div class="grd-styl_1">: {{requestDetails.endTime | date : 'hh:mm a'}}</div><br>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
                <div i18n class="grd-styl_">No. of Asset</div>
                <div class="grd-styl_1">: {{requestDetails.assetCount}}</div><br>
            </mat-grid-tile>



        </mat-grid-list>
    </div>
    <div id="mapid" class="shared-floor-map"></div>
    <!-- <br>
    <div>
        <button onclick="link('r')">Right</button>
        <button onclick="link('l')">Left</button>
        <button onclick="link('t')">Top</button>
        <button onclick="link('b')">Bottom</button>
        <button onclick="fix()">Fix_node_data</button>
        <button onclick="undo()">Undo</button>
        <button onclick="pop()">Create_links</button>
        <button onclick="post_to_db()">Save to DB</button>
        <p>Enter Node name:</p>
        <input id="input" onchange="fix_name()" />

   </div> -->
</div>
</div>
