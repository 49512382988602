<div class="ovi-font-family" style="height: calc(100% - 9px);">

    <mat-toolbar class="input-header">
        <div i18n class="header-name">
            Create {parentName, select, Region {Region} Facility {Facility} Customer {Customer}}</div>
        <div class="header-close"><button mat-icon-button mat-dialog-close close-button class="ie-close-icon">
                <mat-icon class="icon-style">highlight_off</mat-icon>
            </button></div>
    </mat-toolbar>

    
    <div class="mat-custom-form-field-box  popup-margin" style="overflow: hidden; height: calc(100% - 100px);">
        <mat-tab-group [formGroup]="customerForm" style="height: 100%;">
            <mat-tab i18n-label label="Create Customer">
    <div class="mat-custom-form-field-box" style="overflow: hidden;">
            <!-- <div class="form-row block-3">
                <div class="row-field" *ngIf="!data">
                    <div class="example-form cst-mgn">
                        <label i18n class="ovi-lbl-text-size">
                            Customer Type :
                        </label>
                        <mat-radio-group class="example-radio-group" formControlName="customerTypeId" (change)= "getCustType($event.value)" >
                            <mat-radio-button i18n class="example-radio-button cst-mgn_1" *ngFor="let o of customerType; let i = index" [value]="o" [checked]="i === 0">{{o.value}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div  class="row-field-ccf" *ngIf = "data">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Parent Name</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <input matInput i18n-placeholder placeholder="Parent Name" [value]="data.name" readonly > 
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-field-ccf" *ngIf = "data">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Customer Type</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <input matInput i18n-placeholder placeholder="Customer Type" [value]="parentName"readonly >
                        </mat-form-field>
                    </div>
                </div>
            </div> -->
            <div class="form-row block-3" *ngIf="!data">
                <div class="row-field-ccf" *ngIf="(!data) && (code === '2' || code === '3' || data.customerTypeId == 1)">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Select Customer</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <mat-select i18n-placeholder placeholder="Select Customer" formControlName="customerId"[disableOptionCentering]="true">
                                <mat-option *ngFor="let cust_list of customerList" [value]="data.customerTypeId ? data.id : cust_list.id"
                                    (onSelectionChange)=getRegionList(cust_list.id)>
                                    {{cust_list.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error i18n *ngIf="customerForm.controls.customerId.hasError('required')">Select customer is required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div  class="row-field-ccf" *ngIf="code === '3'">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Select Region</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <mat-select i18n-placeholder placeholder="Select Region" formControlName="regionId"[disableOptionCentering]="true">
                                <mat-option *ngFor="let region of regionList" [value]="region.id">
                                    {{region.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error i18n *ngIf="customerForm.controls.regionId.hasError('required')">Select region is required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div  class="form-row block-3">
                <div class="row-field-ccf">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Name</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <input matInput i18n-placeholder placeholder="Name" formControlName="name" #name="matInput" [errorStateMatcher]="matcher" autofocus autocomplete="off">
                            <mat-error i18n *ngIf="customerForm.controls.name.invalid">
                                    {code, select, 1 {Customer} 2 {Region} 3 {Facility}} name is required. (Limit 3-64 letters) 
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div  class="row-field-ccf">
                <div class="example-form">
                    <mat-label i18n class="ovi-lbl-text-size">Select Type</mat-label>
                    <mat-form-field class="example-full-width form-field" appearance="outline">
                        <mat-select i18n-placeholder placeholder="Select Type" formControlName="solutionTypeId">
                            <mat-option *ngFor="let TypeList of TypeList" [value]="TypeList.code">
                                {{TypeList.value}}
                            </mat-option>
                        </mat-select>
                        <!-- <mat-select i18n-placeholder placeholder="Select Type">
                            <mat-option value="Education">Education</mat-option>
                            <mat-option value="Proficiencies">Proficiencies</mat-option>
                            <mat-option value="Specialties">Specialties</mat-option>
                        </mat-select> -->
                    </mat-form-field>
                </div>
            </div>
                <div  class="row-field-ccf">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">No Of Beds</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <!-- <mat-select i18n-placeholder placeholder="Status" formControlName="isActive"[disableOptionCentering]="true">
                                <mat-option i18n  value=true>Active</mat-option>
                                <mat-option i18n value=false>Inactive</mat-option>
                            </mat-select> -->
                            <input  matInput i18n-placeholder placeholder="No Of Beds" formControlName="noOfBeds" 
                            autocomplete="off" role="presentation">
                        </mat-form-field>
                    </div>
                </div>
           
                <div class="row-field-ccf">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">street</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <input  matInput i18n-placeholder placeholder="street" formControlName="line1" 
                            autocomplete="off" role="presentation">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-field-ccf">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Town/village</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <input  matInput i18n-placeholder placeholder="Town/village" formControlName="line2" 
                            autocomplete="off" role="presentation">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-field-ccf">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">City/District</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <input  matInput i18n-placeholder placeholder="city/District" formControlName="line3" 
                            autocomplete="off" role="presentation">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-field-ccf">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">State</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <input matInput i18n-placeholder placeholder="State" formControlName="city" 
                            autocomplete="off" role="presentation">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-field-ccf">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Country</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <input matInput i18n-placeholder placeholder="Country" formControlName="country" 
                            autocomplete="off" role="presentation">
                        </mat-form-field>
                    </div>
                </div>
                  <div class="row-field-ccf">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Pin Code</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <input  matInput i18n-placeholder placeholder="Pin Code" formControlName="zip" 
                            autocomplete="off" role="presentation">
                        </mat-form-field>
                    </div>
                </div>
                <div  class="row-field-ccf">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">GST Number</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <input matInput i18n-placeholder placeholder="GST Number" formControlName="gsTin" autocomplete="off">
                        </mat-form-field>
                    </div>
                </div>
                <div  class="row-field-ccf">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">About Hospital</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <textarea  matInput i18n-placeholder placeholder="About Hospital" formControlName="comments" 
                            autocomplete="off" role="presentation"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div  class="row-field-ccf">
                    <div class="example-form">
                        <div class="img-prev">
                            <div class="img-prev_1 ">
                                <label i18n mat-button class="ovi-lbl-text-size"> 
                                    Upload   Image
                                    <input type="file" id="File" accept="image/*" (change)="uploadImage($event)" class="disp-none" #input> 
                                </label>
                            </div>
                        </div>
                        <div class="img-prev_3">
                            <img *ngIf="logoImage" [src]="logoImage" alt="" class="img-prev_2">
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- <div  class="form-row block-3" *ngIf = "code=='1'">
                <div  class="row-field-ccf">
                    <mat-label i18n class="ovi-lbl-text-size">GST Number</mat-label>
                    <div class="example-form">
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <input matInput i18n-placeholder placeholder="GST Number" formControlName="gsTin" autocomplete="off">
                        </mat-form-field>
                    </div>
                </div>
                <div  class="row-field-ccf">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">License Pack</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <input matInput i18n-placeholder placeholder="License Pack" formControlName="licencePack"  autocomplete="off">
                        </mat-form-field>
                    </div>
                </div>
            </div> -->
            
            <!-- <div  class="form-row block-3">
                <div  class="row-field-ccf">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">GST Number</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <input matInput i18n-placeholder placeholder="GST Number" formControlName="gsTin" autocomplete="off">
                        </mat-form-field>
                    </div>
                </div> -->
                <!-- <div  class="row-field-ccf">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">No of Devices</mat-label>
                        <mat-form-field class="example-full-width form-field"appearance="outline">
                            <input matInput i18n-placeholder placeholder="No of Devices" formControlName="noOfDevices"  autocomplete="off">
                        </mat-form-field>
                    </div>
                </div> 
            </div>-->
            <div  class="form-row block-3">
                <div class="row-field-ccf" *ngIf = "code =='3'">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">No of Beds</mat-label>
                        <mat-form-field class="mat=fld-width" style="display: inline;"appearance="outline">
                            <input matInput i18n-placeholder placeholder="No of Beds" formControlName="noOfBeds" autocomplete="off">
                        </mat-form-field>
                    </div>
                </div>
                <div  class="row-field-ccf" *ngIf = "code !='3' && code !='2' && parentName != 'Facility'">
                    <!-- <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Multi Campus</mat-label>
                        <mat-form-field style="display: inline;"appearance="outline">
                            <mat-select i18n-placeholder placeholder="Multi Campus" formControlName="isMulti"[disableOptionCentering]="true">
                                <mat-option i18n value=true>Yes</mat-option>
                                <mat-option i18n value=false>No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> -->
                </div>
            </div>
            
            <div class="form-row block-3"  *ngIf="code == '1'">
                <!-- <div class="row-field-ccf">
                    <div class="example-form">
                        <div class="img-prev">
                            <div class="img-prev_1 ">
                                <label i18n mat-button class="ovi-lbl-text-size"> 
                                    Upload Logo Image
                                    <input type="file" id="File" accept="image/*" (change)="uploadImage($event)" class="disp-none" #input> 
                                </label>
                            </div>
                        </div>
                        <div class="img-prev_3">
                            <img *ngIf="logoImage" [src]="logoImage" alt="" class="img-prev_2">
                        </div>
                    </div>
                </div>  -->
                <!-- <div  class="row-field-ccf">
                    <div class="example-form">
                        <div class="img-prev">
                            <div  class="logoBtn img-prev_1">
                                <label i18n mat-button class="ovi-lbl-text-size"> 
                                    Upload Background Image
                                    <input type="file" id="File" accept="image/*" (change)="uploadBgImage($event)" class="disp-none" #input> 
                                </label>
                            </div>
                        </div>
                        <div class="img-prev_3">
                            <img *ngIf="bgImage" [src]="bgImage" alt="" class="img-prev_2">
                        </div>
                    </div>
                </div> -->
            </div>
            <div formArrayName="contacts" *ngIf = "customerForm && false">
                <div *ngFor="let contact of customerForm.controls.contacts.controls; let i=index">
                <mat-divider *ngIf="customerForm.controls.contacts.controls.length > 1 && i > 0" >
                </mat-divider>
                <br>
                    <div [formGroupName]="i" >
                    
                        <div class="form-row block-3">
                            <div class="row-field-ccf">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">Contact name</mat-label>
                                    <mat-form-field class="example-full-width form-field"appearance="outline">
                                        <input matInput i18n-placeholder placeholder="Contact name" formControlName="contactName" autocomplete="off" >
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row-field-ccf">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">Email</mat-label>
                                    <mat-form-field class="example-full-width form-field"appearance="outline">
                                        <input matInput i18n-placeholder placeholder="Email" formControlName="email" autocomplete="off" [errorStateMatcher]="matcher">
                                        <mat-error i18n *ngIf="contact.controls.email.invalid">
                                            Invalid Email 
                                        </mat-error>
                                    </mat-form-field>                                    
                                </div>
                            </div>
                        </div>
                        <div class="form-row block-3">
                            <div class="row-field-ccf">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">Phone Number</mat-label>
                                    <mat-form-field class="example-full-width form-field"appearance="outline">
                                        <input matInput i18n-placeholder placeholder="Phone Number" formControlName="phoneNumber" autocomplete="off" [errorStateMatcher]="matcher">
                                        <mat-error i18n  *ngIf="contact.controls.phoneNumber.invalid">
                                            Invalid Phone number 
                                        </mat-error>
                                    </mat-form-field>                                   
                                </div>
                            </div>
                            <div  class="row-field-ccf">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">Designation</mat-label>
                                    <mat-form-field class="example-full-width form-field"appearance="outline">
                                        <input matInput i18n-placeholder placeholder="Designation" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div   class="form-row block-3">
                            <div  class="row-field-ccf">
                                <div class="example-form">
                                    <mat-label i18n class="ovi-lbl-text-size">Comment</mat-label>
                                    <mat-form-field class="mat=fld-width"appearance="outline">
                                        <input matInput i18n-placeholder placeholder="Comment" formControlName="comment" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="form-row">
                    <button i18n mat-stroked-button *ngIf="customerForm.controls.contacts.controls.length > 1" class="cont-styl" (click)="removeContact(i)"> Delete Contact</button>
                    <button i18n mat-stroked-button class="cont-styl" (click)="addContact()">Add more contact</button>
                </div>
              </div>            
        </div>
    </mat-tab>
    <mat-tab i18n-label label="Additional Info" *ngIf="code=='3' || parentName == 'Facility'">
        <div class="mat-custom-form-field-box" style="height: 100%;display: contents;">
            <div class="form-row block-3">
                <div class="row-field-ccf">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Building Location</mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input matInput i18n-placeholder placeholder="Map Location" #name="matInput" formControlName="sourceId"
                                [errorStateMatcher]="matcher" autofocus autocomplete="off">
                            <img style="width:30px;cursor: pointer;" matSuffix  (click)="getLocation($event,'Hospital')"
                                src="/assets/Alert/common_icons/google-map.svg">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-field-ccf">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Parking Facility</mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <mat-select i18n-placeholder placeholder="Valet/Multi Level/Car/Bike etc."  multiple (selectionChange)="onSelectionChange($event)">
                              <mat-option *ngFor="let facilities of parkingFacilitylist" [value]="facilities.code">
                                {{facilities.value}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                </div>
                <div class="row-field-ccf">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Accessibility</mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <mat-select i18n-placeholder placeholder="Wheel Chair/Ramp/Lift etc"   multiple  (selectionChange)="onAccessibilityChange($event)">
                                <mat-option *ngFor="let access of accessbilitylist" [value]="access.code">
                                    {{access.value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-field-ccf">
                    <div class="example-form">
                        <mat-label i18n class="ovi-lbl-text-size">Parking Location</mat-label>
                        <mat-form-field class="example-full-width form-field" appearance="outline">
                            <input matInput i18n-placeholder placeholder="Select Parking location" formControlName="parkingLocation" autocomplete="off"
                                role="presentation"> 
                                <img style="width:30px;cursor: pointer;" matSuffix  (click)="getLocation($event,'Parking')"
                                src="/assets/Alert/common_icons/google-map.svg">
                        </mat-form-field>
                    </div>
                </div>
                <div style="width: 100%;">
                    <div class="example-form">
                        <div class="prev_multiimage">
                            <div class="img-prev_1 ">
                                <label i18n mat-button class="ovi-lbl-text-size">
                                    Upload Building Image
                                    <input type="file" multiple (change)="detectFiles($event)">
                                </label>
                            </div>
                            <div class="img-prev_4" *ngFor="let imgObj of uploadedFile;let index = index">
                                <img [src]="imgObj.fileUrl" [alt]="" class="img-prev_2">
                                <span class='material-icons' (click)="cancelFile(index);"
                                    style=" font-size: 20px;position: relative;bottom: 80px;right: 25px;color: red;cursor: pointer;">highlight_off</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
    </mat-tab-group>
    </div>
    <div class="input-footer">
        <button i18n mat-button class="cancle-btn" mat-dialog-close>Cancel</button>
        
            <button i18n mat-button class="active-btn margin-r-1" appDebounceClick [disabled]="customerForm.invalid || isDisabled"
             (debounceClick)="saveCustomer()" [debounceTime]="300" cdkFocusInitial>Create</button>
    </div>
</div>
