<div class="custom-dialog-box">
    <div class="cf-dlg-frm_">
        <div i18n class="float-l">{data.title, select, Version Update {Version Update} Confirm Test Complete {Confirm Test Complete} 
            Confirmation {Confirmation} Partial Complete {Partial Complete} Assign Floor {Assign Floor} Enroll {Enroll} Edit status {Edit status}
            Add Test {Add Test} Disassociate Device {Disassociate Device} Cancel Billing {Cancel Billing} Test Location {Test Location}
            Patient Diabetic {Patient Diabetic} Patient using Mobile {Patient using Mobile} Cancel Notification {Cancel Notification}
            Certificate Update {Certificate Update} Remove Activity {Remove Activity} Remove Routine {Remove Routine}
            Cancel Routine {Cancel Routine}  Discharge {Discharge} Asset Status {Asset Status} Confirm Re-association of Tag to New File {Confirm Re-association of Tag to New File}
            Porter Request Status {Porter Request Status} Remove Shift {Remove Shift} MR Request Status {MR Request Status} Manage Alarm {Manage Alarm}
            Cancel Task {Cancel Task} Complete Task {Complete Task} Check-in {Check-in} Review Date {Review Date} Confirm Delete {Confirm Delete} 
            Send for Approval {Send for Approval} OT Enroll {OT Enroll} Alert Remarks {Alert Remarks} Send Acknowledgement {Send Acknowledgement}
            Manage Authorized Movement {Manage Authorized Movement}}</div>
        <div class="float-r">
            <!-- <button mat-icon-button [mat-dialog-close]="data.buttonText.cancel" tabindex="-1"> -->
            <button mat-icon-button mat-dialog-close close-button style="float: right;">
                <mat-icon class="line-h-5">highlight_off</mat-icon>
            </button>
        </div>
    </div>
    <hr class="mrgn-style_">
    <div class="cf-dlg content-item">
        <div class="content-item" *ngIf="customMsg">
            {{data.message}}
        </div>
        <div class="content-item" *ngIf = "!customMsg">
            <br>
            <span *ngIf="data.assetStatus === true">{{data.assetName}}&nbsp;[{{data.assetId}}]&nbsp;</span>
            <span *ngIf="data.MRTagDisassociate === true">{{data.message}}.&nbsp;Do you want to disassociate?</span>
            <span i18n>
            {data.message, select,  Do you want to update the version from {Do you want to update the version from } Do you want to update the version {Do you want to update the version }
            Selected tests for the patient will be marked as complete, Are you sure? {Selected tests for the patient will be marked as complete, Are you sure?}
            Are you looking to checkout? {Are you looking to checkout?} Patient will be moved to waiting queue, Do you want to continue? {Patient will be moved to waiting queue, Do you want to continue?}
            Are you sure you want to delete? {Are you sure you want to delete?} Do you want to assign the selected patient to the floor? {Do you want to assign the selected patient to the floor?}
            Are you want to save new one? {Are you want to save new one ?}  Do you want to create a copy of this form template? {Do you want to create a copy of this form template?}
            Please choose the visit type {Please choose the visit type} Are you sure to change the status? {Are you sure to change the status?} Select reason for change {Select reason for change}
            Do you want to disassociate the device? {Do you want to disassociate the device?} Do you want to cancel the billing ? {Do you want to cancel the billing ?} Do you want to assign to location {Do you want to assign to location }
            Do you want to change test / location? {Do you want to change test / location?} Are you sure to change the location? {Are you sure to change the location?}
            Reason for not preferring Mobile {Reason for not preferring Mobile} Is patient willing to use Ask Apollo (Mobile) {Is patient willing to use Ask Apollo (Mobile)}
            Do you want to mark the patient as diabetic? {Do you want to mark the patient as diabetic?} Do you want to mark the patient as non - diabetic? {Do you want to mark the patient as non - diabetic?}
            selected certificate shall be uploaded to all selected Readers and Gateways. Are you sure? {selected certificate shall be uploaded to all selected Readers and Gateways. Are you sure?}
            Do you want to delete the activity ? {Do you want to delete the activity ?} Do you want to end the routine ? {Do you want to end the routine ?} Do you want to cancel the routine ? {Do you want to cancel the routine ?}
            Do you want to discharge ? {Do you want to discharge ?} Do you wish to mark this asset as Not Functioning / Needs Service? {Do you wish to mark this asset as Not Functioning / Needs Service?}
            Do you want to change the status? {Do you want to change the status?} Do you want to delete the shift ? {Do you want to delete the shift ?} Do you want to create a request in waitinglist?, A porter will be assigned as soon as they are available
            {Do you want to create a request in waitinglist?, A porter will be assigned as soon as they are available} Do you want to Disengage Alarm ? {Do you want to Disengage Alarm ?}
            Do you want to Engage Alarm ? {Do you want to Engage Alarm ?} Are you sure to change the consultant? {Are you sure to change the consultant?} Do you want to add this patient to Queue? {Do you want to add this patient to Queue?}
            Do you want to cancel the task ? {Do you want to cancel the task ?} Do you want to complete the task ? {Do you want to complete the task ?} Do you want to create layout ? {Do you want to create layout ?}
            Do you want to create a request in waitinglist?, A Ambulance will be assigned as soon as they are available {Do you want to create a request in waitinglist?, A Ambulance will be assigned as soon as they are available}
            Are you sure to change the Review Date ? {Are you sure to change the Review Date ?} Do you confirm report collection ? {Do you confirm report collection ?} Select a review date and time {Select a review date and time} Do you want to check-in patient {Do you want to check-in patient }
            Do you want to send this document for approval {Do you want to send this document for approval} Are you want to save and submit this document for approval? {Are you want to save and submit this document for approval?}
            Do you want to return the form? {Do you want to return the form?} Do you want to delete this? {Do you want to delete this?}
            Are you sure you want to send Ack for the Request? {Are you sure you want to send Ack for the Request?} 
            A previous OT event has been detected for this patient. Press YES to proceed with the existing event or NO to create a new one. {A previous OT event has been detected for this patient. Press YES to proceed with the existing event or NO to create a new one.}}</span>
            <span *ngIf="data.asignedLocationName !== null">{{data.asignedLocationName}}</span>
            <span i18n *ngIf="data.type === 'swVerUpdate'">{{data.fromVersion}} to {{data.version}} ?</span>
            <span *ngIf="data.type === 'newswVerUpdate'">{{data.version}} ?</span>
            <span *ngIf="data.patientPendingLocation === true">{{data.message}}</span>
        </div>
        <div class="content-item" [formGroup]="confirmForm" *ngIf="enableReason || (data.testStatusChange === true && data.statusId === 'QS-NR')">
            <!-- <mat-label class="cf-dlg-reason">Reason</mat-label> -->
            <mat-form-field style="width: 80%"  appearance="outline">
                <mat-select i18n-placeholder formControlName="reasonCode" placeholder="Reason" required>
                    <mat-option *ngFor="let reason of reasonList" [value]="reason.code" (click) = "addReasonCode(reason)">
                        {{reason.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="content-item" [formGroup]="confirmForm" *ngIf="data.isRemark !== 1 ">
            <mat-form-field appearance="outline" style="width: 60%;">
            <textarea i18n-placeholder matInput placeholder="Remarks" formControlName="comments" required></textarea>
            </mat-form-field>
        </div>
        <div class="content-item" [formGroup]="confirmForm" *ngIf="data.assignFloor === true">
            <mat-form-field appearance="outline">
                <mat-select i18n-placeholder formControlName="floor" placeholder="Floor">
                <mat-option *ngFor="let floor of floorList" [value]="floor.floorId">
                    {{floor.floorName}}
                </mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="confirmForm.controls.floor.invalid">
                    <span i18n [hidden]="!confirmForm.controls['floor'].errors.required">Floor is
                        required.</span>
                </mat-error> -->
            </mat-form-field>
        </div>
        <div class="content-item" [formGroup]="confirmForm" *ngIf="data.isNewTestAdded === true">
            <!-- <mat-label class="wrapper">Test Name *</mat-label> -->
            <mat-form-field appearance="outline">
                <input i18n-placeholder matInput placeholder="Test Name *" id="testName" appTypeHit (typeHit)="searchToTestName($event)" [matAutocomplete]="autoFrom" formControlName="testId">
                <mat-autocomplete autoActiveFirstOption #autoFrom="matAutocomplete">
                    <mat-option *ngFor="let option of searchTestlist" (click) = "addTestEvents(option)" [value]="option.name">
                    <div class="tot-wid-styl">{{option.name}}</div>

                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="confirmForm.controls.testId.invalid">
                    <span i18n [hidden]="!confirmForm.controls['testId'].errors.required">Test Name is
                        required.</span>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="content-item" [formGroup]="confirmForm" *ngIf="data.disengageAlarm === true">
            <mat-form-field *ngIf="false" style="width: 80%" appearance="outline">
                <input matInput i18n-placeholder placeholder="Confirm Password" formControlName="confirmPwd" [type]="hide ? 'password' : 'text'"
                    autocomplete="off">
                <mat-icon matSuffix (click)="hide = !hide" class="float-r">{{hide ? 'visibility_off' : 'visibility'}}
                </mat-icon>
                <!-- <mat-error *ngIf="confirmForm.controls.confirmPwd.invalid">
                    <span i18n [hidden]="!confirmForm.controls['confirmPwd'].errors.required">Confirm Password is
                        required.</span>
                </mat-error> -->
            </mat-form-field>

            <div class="form-row block-3" style="width: 95%;">
                <div style="width: 30%;text-align:start;">
                    <div class="example-form" style="margin-top: 15%;">
                        <span i18n>Permitted Duration<span style="color: red;">*</span> : </span>
                    </div>
                </div>
                <div style="width: 55%;">
                    <div class="example-form">
                        <mat-form-field appearance="outline" style="display: block;">
                            <mat-select i18n-placeholder formControlName="duration" placeholder="Duration" [disableOptionCentering]="true" required>
                            <mat-option *ngFor="let data of durationList" [value]="data.code" (click)="getDuration(data);">
                                {{data.value}}
                            </mat-option>
                            </mat-select>
                            <mat-error *ngIf="confirmForm.controls.duration.invalid">
                                <span i18n [hidden]="!confirmForm.controls['duration'].errors.required">Duration is
                                    required.</span>
                        </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <span style="margin-top: 15px;width: 0px;">(Minutes)</span>
            </div>
            <div class="form-row block-3" style="width: 95%;">
                <div style="width: 30%;text-align:start;">
                    <div class="example-form" style="margin-top: 15%;">
                        <span i18n>Purpose : </span>
                    </div>
                </div>
                <div style="width: 55%;">
                    <div class="example-form">
                        <mat-form-field appearance="outline" style="display: block;">
                            <textarea i18n-placeholder matInput placeholder="" formControlName="purpose"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="form-row block-3" style="width: 95%;">
                <div style="width: 30%;text-align:start;">
                    <div class="example-form" style="margin-top: 15%;">
                        <span i18n>Authenticate To Proceed : </span>
                    </div>
                </div>
                <div style="width: 48%;">
                    <div class="example-form">
                        <mat-form-field appearance="outline" style="display: block;">
                            <input matInput i18n-placeholder placeholder="Password" formControlName="confirmPwd" 
                                autocomplete="off" [readonly]="this.pwdExist" [ngStyle]="{'opacity': this.pwdExist ? '30%' : ''}"
                                [type]="'password'">
                            <mat-hint>
                                <span i18n *ngIf="errorExist === true" style="color:red;">Authentication failed.&nbsp;</span>
                            </mat-hint>
                        </mat-form-field>
                    </div>
                </div>
                <mat-label class="go-label ovi-font-family" [ngStyle]="{'opacity': this.pwdExist ? '30%' : '',
                'background': !this.pwdExist ? '#3A7AFE' : '#707070', 'cursor': !this.pwdExist ? 'pointer' : 'auto'}" 
                (click)="checkPassword()">
                    <img src="/assets/Alert/IP/go-action.png" style="width:16px;height:16px;">
                </mat-label>
                <span style="margin-top: 15px;margin-left: -15px;width: 0px;">
                    <mat-icon matSuffix style="padding-top:0px;color: #269f26c7;"
                    [ngStyle]="{'opacity': !this.pwdExist || confirmForm.controls['confirmPwd'].value === null 
                    || confirmForm.controls['confirmPwd'].value === '' ? '30%' : ''}">check_circle</mat-icon>
                </span>
            </div>
        </div>
        <div class="content-item" mat-custom-form-field-box [formGroup]="confirmForm" *ngIf="data.reviewDate">
            <mat-form-field appearance="outline" style="width: 60%;">        
                <input matInput type="datetime-local" i18n-placeholder placeholder="Review Date / Time"
                formControlName="reviewDate" autocomplete="off">
            </mat-form-field>
        </div>
        <div class="content-item" class="cf-dlg mat-custom-form-field-box" [formGroup]="confirmForm" *ngIf="formTempNameEnable">
            <mat-form-field appearance="outline" style="width: 60%;">
                <input matInput style="height: 40px;" formControlName="formTempName" placeholder="Form Template Name*" autocomplete="off">
                <mat-error *ngIf="confirmForm.controls.formTempName.invalid">
                    <span i18n [hidden]="!confirmForm.controls['formTempName'].errors.required">Template Name is
                        required.</span>
                    <span [hidden]="confirmForm.controls['formTempName'].errors.required">Form Template Name Must be Unique.</span>
                </mat-error>
            </mat-form-field>
        </div>
        <!-- style="padding: 30px 0px 10px 0px;" -->
        <div class="content-item"  [formGroup]="confirmForm" *ngIf="data.patientPendingLocation === true">
            <mat-checkbox i18n [checked]="true" formControlName="isPendingTest">Yes Continue</mat-checkbox>
        </div>
    </div>
    <mat-dialog-actions class="content-item" class="input-footer" *ngIf="data.disengageAlarm !== true">
        
            <!-- color="warn" -->
        <!-- <button mat-raised-button class="cf-dlg_1" *ngIf="data.isNewTestAdded === true" [disabled]="confirmForm.invalid" 
        (click) = "saveComments()" tabindex="1">{{confirmButtonText}}</button> -->
        <button i18n mat-raised-button appDebounceClick class="cf-dlg_1" *ngIf="data.isNewTestAdded === true" [disabled]="confirmForm.invalid" 
        (debounceClick) = "saveComments()" tabindex="1" [debounceTime]="300">{confirmButtonText, select, Ok {Ok} Yes {Yes} Save {Save} New visit {New visit} Follow up {Follow up} Continue {Continue} Delete {Delete} Confirm {Confirm}}</button>
        <!-- <button mat-raised-button class="cf-dlg_1" *ngIf="data.assignFloor === true" 
        (click) = "saveComments()" tabindex="1">{{confirmButtonText}}</button> -->
        <button i18n mat-raised-button appDebounceClick class="cf-dlg_1" *ngIf="data.assignFloor === true" 
        (debounceClick) = "saveComments()" tabindex="1" [debounceTime]="300">{confirmButtonText, select, Ok {Ok} Yes {Yes} Save {Save} New visit {New visit} Follow up {Follow up} Continue {Continue} Delete {Delete} Confirm {Confirm}}</button>
        <!-- <button mat-raised-button class="cf-dlg_1" *ngIf="data.assignFloor === false && data.isNewTestAdded === false" [disabled]="isDisabled"  
        (click) = "saveComments()" tabindex="1">{{confirmButtonText}}</button> -->
        <button i18n mat-raised-button appDebounceClick class="cf-dlg_1" *ngIf="data.oTEnroll !== true && data.assignFloor === false && data.isNewTestAdded === false && data.statusId !== 'QS-NR' && formTempNameEnable === false" [disabled]="isDisabled"  
        (debounceClick) = "saveComments()" tabindex="1" [debounceTime]="300">{confirmButtonText, select, Ok {Ok} Yes {Yes} Save {Save} New visit {New visit} Follow up {Follow up} Continue {Continue} Delete {Delete} Confirm {Confirm}}</button>
        
        <button i18n mat-raised-button appDebounceClick class="cf-dlg_1" *ngIf="formTempNameEnable" [disabled]="confirmForm.controls['formTempName'].invalid"  
        (debounceClick) = "saveComments()" tabindex="1" [debounceTime]="300">{confirmButtonText, select, Ok {Ok} Yes {Yes}}</button>
        
        <button i18n mat-raised-button appDebounceClick class="cf-dlg_1" *ngIf="data.assignFloor === false && data.isNewTestAdded === false && data.statusId === 'QS-NR'" [disabled]="confirmForm.controls.reasonCode.invalid || isDisabled"  
        (debounceClick) = "saveComments()" tabindex="1" [debounceTime]="300">{confirmButtonText, select, Ok {Ok} Yes {Yes} Save {Save} New visit {New visit} Follow up {Follow up} Continue {Continue} Delete {Delete} Confirm {Confirm}}</button>
        <!-- <button mat-raised-button class="cf-dlg_1" *ngIf="data.isNewTestAdded === false" [disabled]="confirmForm.invalid || isDisabled"  (click) = "saveComments()" tabindex="1">{{confirmButtonText}}</button> -->

        <button i18n mat-raised-button [mat-dialog-close]="data.buttonText.cancel" *ngIf="data.oTEnroll !== true && data.enrollVisitType" class="cf-dlg_1" tabindex="-1">{cancelButtonText, select, Cancel {Cancel} No {No} Continue {Continue} New visit {New visit} Follow up {Follow up}}</button>
        <button i18n mat-raised-button [mat-dialog-close]="data.buttonText.cancel" *ngIf="data.oTEnroll !== true && !data.enrollVisitType" tabindex="-1">{cancelButtonText, select, Cancel {Cancel} No {No} Continue {Continue} New visit {New visit} Follow up {Follow up} No, Update Only {No, Update Only}}</button>

        <button i18n mat-raised-button [mat-dialog-close]="data.buttonText.cancel" (keyup.enter)="data.buttonText.cancel" *ngIf="data.oTEnroll === true" class="cf-dlg_1" tabindex="-1">{confirmButtonText, select, Yes {Yes}}</button>
        <button i18n mat-raised-button appDebounceClick *ngIf="data.oTEnroll === true" [disabled]="isDisabled" (debounceClick) = "saveComments()" tabindex="1" [debounceTime]="300">{cancelButtonText, select, Cancel {Cancel} No {No}}</button>
    </mat-dialog-actions>
    <mat-dialog-actions style="padding: 0px 15px;display: block;margin-bottom: 0px;margin-right: 10px;" *ngIf="data.disengageAlarm === true">
        <button *ngIf="infantEventTypeId === 'IET-AT'" style="float: left;" i18n mat-raised-button appDebounceClick class="cf-dlg_1" [disabled]="!this.pwdExist || confirmForm.controls['confirmPwd'].value === null 
        || confirmForm.controls['confirmPwd'].value === ''" 
        (debounceClick) = "saveComments('Remove Authorized')" tabindex="1" [debounceTime]="300">Remove Authorization</button>
        <button style="float: right;" i18n mat-raised-button appDebounceClick class="cf-dlg_1" [disabled]="!this.pwdExist || confirmForm.controls['confirmPwd'].value === null 
        || confirmForm.controls['confirmPwd'].value === ''" 
        (debounceClick) = "saveComments('Authorized')" tabindex="1" [debounceTime]="300">Authorize</button>
    </mat-dialog-actions>

</div>
