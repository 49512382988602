
<mat-toolbar class="mat-elevation-z4" *ngIf = "!existPrivateUser"> 

    <button mat-icon-button (click)="sidenav.toggle();" class="menu-button">
        <i class="material-icons app-toolbar-menu">menu </i>
    </button>
    <img src="{{hospital_logo}}" alt="" (click)="goToDashboard()" class="ovitag-img logo-styl" fxHide fxShow.gt-sm>


    <app-manage-control (isSaveClick)="getSaveEvent($event)"></app-manage-control>
    <span class="spacer"></span>
    <img src="/assets/Alert/common_icons/ovitag.png" alt="" (click)="goToDashboard()" class="ovitag-img" fxHide fxShow.gt-xs>
    <span class="spacer"></span>

    <app-toolbar-notification></app-toolbar-notification>


</mat-toolbar>
<mat-toolbar class="mat-elevation-z5">
    <cdk-mainmenu></cdk-mainmenu>
</mat-toolbar>
<mat-toolbar class="mat-elevation-z5-tab" *ngIf = "!existPrivateUser">
    <div class="tab-header">
        <div class="tab-header-button">
            <mat-icon (click)="sidebar.toggle();" class="app-icn-style">apps icon
            </mat-icon>
        </div>
        <div class="tab-header-area">
            <div class="header-area" *ngIf="menuvalue != 'dashboard' && menuvalue != 'digital queue'">
                <div class="men-styl">{{menuvalue | titlecase}}
                    <span *ngIf="menuvalue1" class="fnt-colr-style"> >></span>
                    <span *ngIf="menuvalue1" class="fnt-colr-style_1"> {{menuvalue1 | titlecase}}</span>
                </div>
            </div>
            <div class="header-area" *ngIf="menuvalue == 'dashboard' || menuvalue == 'digital queue'">
                <div class="flx-styl">
                    <div *ngIf="activate_btn && activate_btn.indexOf('BT_DBDB') > -1" i18n class="tl-br-nav-styl" (click)="goDashboard()"
                        [ngClass]="{ 'toolmenu' : menuvalue === 'dashboard' && isAmr == false && isVitals == false }">Dashboard</div>
                    <div *ngIf="activate_btn && activate_btn.indexOf('BT_DBDQ') > -1 && this.AMRDemo == false" i18n class="tl-br-nav-styl" (click)="goDigitalQueue()"
                        [ngClass]="{ 'toolmenu' : menuvalue != 'dashboard' && isVitals == false }">Digital Queue</div>
                    <div *ngIf="this.AMRDemo == true" i18n class="tl-br-nav-styl" (click)="getAMR()"
                        [ngClass]="{ 'toolmenu' : isAmr == true }">AMR</div>
                    <div *ngIf="this.vitalDemo == true" i18n class="tl-br-nav-styl" (click)="getVitals()"
                            [ngClass]="{ 'toolmenu' : isVitals == true }">Vitals</div>
                </div>

            </div>

            <div *ngIf="userLevel != 8" class="search-area search-area-m-5">
                <div class="search-area-h-w">
                    <div fxHide fxShow.gt-sm *ngIf="searchOpen" class="search-area-input-w-h-100">
                        <input i18n-placeholder _ngcontent-c7="" #filter type="search" placeholder="Search" [formControl]="searchControl"
                            class="search-input-box ovi-font-family" #clearInput (blur)="clearInput.value='';getBlurEvent($event);" (search)="clearDropdown(clearInput.value)" [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"> </mat-autocomplete>
                        <div fxLayout="row" fxLayout="row wrap" class="search-area-input-style">
                            <div fxFlex="100" fxFlex.lt-sm="100" fxFlex.sm="50" fxHide fxShow.gt-sm  class="srch-styl">
                                <mat-icon matListIcon iconsmall class="srch-styl_1" (click)="clearInput.value = searchControl.value; filterResults(searchControl.value)" >search</mat-icon>
                            </div>
                        </div>
                        <div class="search-result" *ngIf="searchlist && !this.searchClose">
                            <div *ngIf="searchlist != 'empty'">

                                <div class="search-result-body" *ngFor="let option of searchlist">
                                    <div class="srch-list-style">
                                        <img *ngIf="option.tagType == 'Patient'" class="pad-styl" height="20" width="20"
                                            src="/assets/Alert/common_icons/patient.svg" alt="">
                                        <img *ngIf="option.tagType == 'CareProvider'" class="pad-styl" height="20"
                                            width="20" src="/assets/Alert/common_icons/user.svg" alt="">
                                        <img *ngIf="option.tagType == 'Asset'" class="pad-styl" height="25" width="25"
                                            src="/assets/Alert/common_icons/asset.svg" alt="">
                                        <img *ngIf="option.tagType == 'Porter'" class="pad-styl" height="25" width="25"
                                            src="/assets/Alert/common_icons/porter.svg" alt="">
                                    </div>

                                    <div class="search-data cur-ptr" (click)="(option.tagType == 'Asset') ? assetInfo(option) : patientInfo(option, $event);"
                                        (click)="clearInput.value=''">
                                        {{option.name}}, {{option.gender}}, {{option.age}},
                                        {{option.tagSerialNumber}}, {{option.mainidentifier}},
                                        {{option.mobileNumber}}, {{option.visitType}},
                                        {{option.lastVisitDate}}, {{option.currentLocation}}
                                    </div>
                                </div>

                                <div class="tl-br-styl">
                                        <a [ngClass]="searchlist != 'empty' ? 'tl-br-styl_1 search-data cur-ptr' : ''" appDebounceClick
                                        (debounceClick)="globalSearch(searchlist[0]);" [debounceTime]="300"
                                        (click)="clearInput.value=''">
                                        <small i18n>More</small></a>
                               </div>
                            </div>
                            <div i18n class="tl-br-styl_2 ovi-lbl-text-size" *ngIf="searchlist == 'empty' && clearSearch == true">
                                Your search returned no matches.
                            </div>
                        </div>
                    </div>

                </div>
                <div class="drop-down-select ovi-font-family" fxHide fxShow.gt-sm >
                    <mat-select (selectionChange)="getSearchTypeWiseDataFilter($event.value)" [(value)]="selectedType"
                        [formControl]="selectedTypeControl" placeholder="">
                        <mat-option i18n *ngFor="let type of searchTypes;" [value]="type.value">
                            {type.value, select, All {All} Today {Today}}
                        </mat-option>
                    </mat-select>
                </div>

            </div>
        </div>
    </div>
</mat-toolbar>
