import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, QueryList, ViewChildren, ViewEncapsulation } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CommonService, ConfigurationService, DashboardService } from "../../../services";
import { ConfirmationDialog } from "../confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogComponent } from "../layout-save/layout-save.component";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-manage-layout',
  templateUrl: './manage-layout.component.html',
  styleUrls: ['./manage-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ManageLayoutComponent implements OnInit {
  public layoutTabDisp: boolean = true;
  public layoutTabEnable: boolean = true;
  public dashId: any;
  public configForm: any = UntypedFormGroup;
  filterInputsList = [];
  apptermGroupList = [];
  tabClickedLabel: any;
  configInputs: any={};
  inputOption: any=[];
  dynamicInput: any;
  pdfConfig: any;
  filterInputs: any=[];
  filterLayoutList: any=[];

  constructor(public thisDialogRef: MatDialogRef<any>, public form: UntypedFormBuilder,private configurationService: ConfigurationService,public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any, private commonService: CommonService, public dashboardService: DashboardService, public dialog: MatDialog) {
      if(this.data){
        this.dashId=this.data.id;
        this.layoutTabDisp = false;
      }
      this.getApptermsGroup();
  }

  ngOnInit() {
    if(this.data !== null) {
      if(this.data?.configValue !== null) {
        const input = JSON.parse(this.data?.configValue);
        if(input.hasOwnProperty('dynamicHeader')) {
          this.dynamicInput = input['dynamicHeader'];
          this.filterInputs = input['dynamicHeader']['filterInputs'];  
        }
        if(input.hasOwnProperty('pdfConfig')){
          this.pdfConfig = input['pdfConfig'];
          this.filterLayoutList = input['pdfConfig']['type'];
        }
        this.buildForm();
        this.removeInputs(0, null);

        for (let i = 0; i < this.filterInputs.length; i++) {
          const control = <UntypedFormArray>this.configForm.controls['inputList'];
          this['inputData' + i] = this.filterInputs[i];
          this.inputOption.push(this.filterInputs[i]?.name);
          control.push(this.editInputs(i));
        }
      } else {
        this.buildForm();
      }
    }
  }

  buildForm() {
    this.configForm = this.form.group({
      enableExcel: [this.dynamicInput ? this.dynamicInput.enableExcel : null],
      enablePdf: [this.dynamicInput ? this.dynamicInput.enablePdf : null],
      enableRefresh: [this.dynamicInput ? this.dynamicInput.enableRefresh : null],
      enableHeader: [this.dynamicInput ? this.dynamicInput.enableHeader : null],
      layoutSize: [this.pdfConfig?this.pdfConfig.selected: null],
      inputList: this.form.array([this.editInputs(0)]),
    });
  }

  getApptermsGroup(){
    this.commonService.getAppTermsVerion2('LookupGroup').subscribe(res => {
      this.apptermGroupList= res.results;
    })
  }

  editInputs(i) {
    return this.form.group({
      name: [this['inputData' + i] ? this['inputData' + i].name: null],
      label: [this['inputData' + i] ? this['inputData' + i].label: null],
      default: [this['inputData' + i] ? this['inputData' + i].default: null],
      id: [this['inputData' + i] ? this['inputData' + i].id: null],
      seq: [this['inputData' + i] ? this['inputData' + i].seq: null],
      required: [this['inputData' + i] ? this['inputData' + i].required: null],
      visibled: [this['inputData' + i] ? this['inputData' + i].visibled: null],
      groupName: [this['inputData' + i] ? this['inputData' + i].groupName: null]
    });
  }

  removeInputs(i: number, type) {
    if (this.configForm.controls['inputList'].value[i].hasOwnProperty('name') &&
    this.configForm.controls['inputList'].value[i].name !== null) {
    this.inputOption = this.inputOption.filter(x => this.configForm.controls['inputList'].value[i].name.indexOf(x) === -1);
  }
    const control = <UntypedFormArray>this.configForm.controls['inputList'];
    control.removeAt(i);
    if (type == 'clear' && control.length <= 0) {
      this.addInputs();
    }
  }

  addInputs() {
    const control = <UntypedFormArray>this.configForm.controls['inputList'];
    control.push(this.getInputs());
  }

  private getInputs() {
    return this.form.group({
      name: [null],
      label: [null],
      default: [null],
      id: [null],
      seq: [null],
      required: [null],
      visibled: [null],
      groupName: [null]
    });
  }

  tabClick(tab){
    this.tabClickedLabel = tab.tab.textLabel;
    let label = tab.tab.textLabel;
    this.layoutTabEnable = label == 'Layout' ? false : true;      
    if(label == 'Config'){
      this.getconfigData();
    }
  }

  getconfigData() {
    this.commonService.getConfigFile('header-config').subscribe(res => {
      if(res.statusCode == 1) {
        this.filterInputsList = res.results?.contentObject?.dynamicHeader?.filterInputs;
        this.filterLayoutList = res.results?.contentObject?.pdfConfig?.type;
      }
    });
  }

  bindInputs(data, i) {
    this['inputData' + i] = data;
    if (data !== null) {
      this.inputOption.push(data?.name);
    } else {
      this.inputOption = [];
    }
    let array = this.configForm.get('inputList') as UntypedFormArray;
    array.controls[i].patchValue({ "label": data?.label,"default": data?.default,"id": data?.id,"required": data?.required });
  }

  saveInputs(){
    let isFactory = true;
    if(this.data.userId !== null) {
      isFactory = false;
    }
    this.configInputs = {
      "dynamicHeader": {
        "filterInputs": [],
        "enableExcel": this.configForm.controls['enableExcel'].value === true ? true : false,
        "enablePdf": this.configForm.controls['enablePdf'].value === true ? true : false,
        "enableRefresh": this.configForm.controls['enableRefresh'].value === true ? true : false,
        "enableHeader": this.configForm.controls['enableHeader'].value === true ? true : false
      },
      "pdfConfig":{
        "type": this.configInputs?.pdfConfig?.type,
        "selected": this.configForm.controls['layoutSize'].value
      }
    }
    const inputs = <UntypedFormArray>this.configForm.controls['inputList'];
    for (let i = 0; i < inputs.length; i++) {
      if (this.configForm.controls['inputList'].value[i].name !== null) {
        this.configInputs['dynamicHeader']['filterInputs'].push({
          'name': this.configForm.controls['inputList'].value[i].name,
          'id': this.configForm.controls['inputList'].value[i].id,
          'label': this.configForm.controls['inputList'].value[i].label,
          'default': this.configForm.controls['inputList'].value[i].default,
          'seq': this.configForm.controls['inputList'].value[i].seq,
          'required': this.configForm.controls['inputList'].value[i].required === true ? true : false,
          'visibled': this.configForm.controls['inputList'].value[i].visibled === true ? true : false,
          'groupName': this.configForm.controls['inputList'].value[i].groupName
        });
      }
    } 
    const data = {
      "dashboardId": this.data.dashboardId,
      "dashboardName": this.data.dashboardName,
      "isFactory": isFactory,
      "configValue": JSON.stringify(this.configInputs)
    }
    // return
    this.dashboardService.saveCurrentDashboard(data).subscribe(res => {
      if (res.statusCode === 1) {
      this.toastr.success('Success', `${res.message}`);
      }
    },
    error => {
      this.toastr.error('Error', `${error.error.message}`);
    });
  }
  reportHeaderAction(event){}
  updateSaveAction(event){
    if(event){
      this.data = event; 
      this.dashId=this.data.id;
      this.layoutTabDisp = false;
      this.ngOnInit();
    }
  }
}
