<div class="ovi-calendar ovi-font-family" style="width: 100%;height : 100%;overflow: auto;padding: 0px;">
  <div style="height: 60px;">
    <mat-grid-list [cols]="21" rowHeight="98%">
      <mat-grid-tile [colspan] = "7" [rowspan]= "1" >
        <div style="text-align:center;">
          <div class="cal-div-btn" style = "color: white; background: #3a7afe;" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()" >
            Previous
          </div>
          <div class="cal-div-btn" mwlCalendarToday [(viewDate)]="viewDate" >
            Today
          </div>
          <div class="cal-div-btn" style = "color: white; background: #3a7afe;" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()" >
            Next
          </div>
        </div>
        
      </mat-grid-tile>
      <mat-grid-tile [colspan] = "6" [rowspan]= "1" style="font-size: 14px;">
        <h4>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h4>
      </mat-grid-tile>

      <mat-grid-tile [colspan] = "8" [rowspan]= "1">
        <div style="text-align:center;">
          <div class="cal-div-btn" style = "color: white; background: #3a7afe;" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day" >
            Day
          </div>
          <div class="cal-div-btn" style = "color: white; background: #3a7afe;" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week" >
            Week
          </div>
          <div class="cal-div-btn" style = "color: white; background: #3a7afe;" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month" >
            Month
          </div>
        </div>      
      </mat-grid-tile>
    </mat-grid-list>
  </div>
<br />
<div [ngSwitch]="view">
  <mwl-calendar-month-view
    *ngSwitchCase="CalendarView.Month"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    [activeDayIsOpen]="activeDayIsOpen"
    (dayClicked)="dayClicked($event.day)"
    (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)"
  >
  </mwl-calendar-month-view>
  <mwl-calendar-week-view
    *ngSwitchCase="CalendarView.Week"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)"
  >
  </mwl-calendar-week-view>
  <div *ngSwitchCase="CalendarView.Day">
    <div *ngFor="let number of [0,1,2]" style="float: left; width: 32%;padding-right:5px;">
      <div style="text-align: center;font-size: 16px;">BAY {{number + 1}}</div>
      <mwl-calendar-day-view
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-day-view>
    </div>
  </div>
</div>

<!-- Everything you see below is just for the demo, you don't need to include it in your app -->

<br /><br /><br />

<!-- <h3>
  Edit events
  <button class="btn btn-primary float-right" (click)="addEvent()">
    Add new
  </button>
  <div class="clearfix"></div>
</h3>

<div class="table-responsive">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th>Title</th>
        <th>Primary color</th>
        <th>Secondary color</th>
        <th>Starts at</th>
        <th>Ends at</th>
        <th>Remove</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let event of events">
        <td>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="event.title"
            (keyup)="refresh.next()"
          />
        </td>
        <td>
          <input
            type="color"
            [(ngModel)]="event.color.primary"
            (change)="refresh.next()"
          />
        </td>
        <td>
          <input
            type="color"
            [(ngModel)]="event.color.secondary"
            (change)="refresh.next()"
          />
        </td>
        <td>
          <input
            class="form-control"
            type="text"
            mwlFlatpickr
            [(ngModel)]="event.start"
            (ngModelChange)="refresh.next()"
            dateFormat="Y-m-dTH:i"
            altFormat="F j, Y H:i"
            placeholder="Not set"
          />
        </td>
        <td>
          <input
            class="form-control"
            type="text"
            mwlFlatpickr
            [(ngModel)]="event.end"
            (ngModelChange)="refresh.next()"
            dateFormat="Y-m-dTH:i"
            altFormat="F j, Y H:i"
            placeholder="Not set"
          />
        </td>
        <td>
          <button class="btn btn-danger" (click)="deleteEvent(event)">
            Delete
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Event action occurred</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Action:
      <pre>{{ modalData?.action }}</pre>
    </div>
    <div>
      Event:
      <pre>{{ modalData?.event | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      OK
    </button>
  </div>
</ng-template> -->
</div>
