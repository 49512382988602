<div fxFlex class="input-component-holder" appWindowResize (resized)="onWindowResized($event)" [component]="'search'">
    <mat-toolbar class="input-header">
        <div i18n class="header-name">Search</div>
        <div class="header-close"><button mat-icon-button mat-dialog-close close-button class="ie-close-icon">
                <mat-icon class="icon-style">highlight_off</mat-icon>
            </button></div>
    </mat-toolbar>
    <div class="popup-input-container popup-margin" style="overflow: hidden;">
        <div class="mrgn-styl">
            <div class="manage-action custom-name" style="display: flex;align-items: center;">
                
                <mat-form-field appearance="outline">
                    <input matInput (keyup)="applyFilter($event.target.value)" i18n-placeholder placeholder="Filter" autocomplete="off" role="presentation">
                </mat-form-field>
            </div>

            <div class="mat-elevation-z4 manage-component table-h-50"  style="overflow: auto; max-height: calc(100vh - 230px);" [ngClass.xs]="'components-container-xs'">
                <div class="mat-table-overflow block-se">
                    <!-- <mat-table #table [dataSource]="dataSource" matSort>


                    <ng-container matColumnDef="name">
                        <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header">
                            Name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let data">{{data.name}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="mainidentifier">
                        <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header">
                            UHID
                        </mat-header-cell>
                        <mat-cell *matCellDef="let data">{{data.mainidentifier}} </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="Gender">
                        <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header">
                            Gender
                        </mat-header-cell>
                        <mat-cell *matCellDef="let data">{{data.gender}} </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="Age">
                        <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header">
                            Age
                        </mat-header-cell>
                        <mat-cell *matCellDef="let data">{{data.age}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="mobileNumber">
                        <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header">
                            Mobile
                        </mat-header-cell>
                        <mat-cell *matCellDef="let data">{{data.mobileNumber}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="visit_type">
                        <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header">
                            Visit Status
                        </mat-header-cell>
                        <mat-cell *matCellDef="let data" (click)="patientInfo(data)"
                            [ngClass]="data.status == 'In progress' ? 'clr-blue' : 'clr-red'">{{data.visitType}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="lastVisitDate">
                        <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header">
                            Last Visit Date </mat-header-cell>
                        <mat-cell *matCellDef="let data">{{data.lastVisitDate}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="currentLocation">
                        <mat-header-cell i18n *matHeaderCellDef mat-sort-header class="manage-table-header">
                            Current Location </mat-header-cell>
                        <mat-cell *matCellDef="let data">{{data.currentLocation}} </mat-cell>
                    </ng-container>


                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;" class="manage-table-header-row">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)=rowClick(row)
                        (toggleChange)="onToggleChange($event,row)">
                    </mat-row>

                </mat-table> -->

                    <mat-table #table [dataSource]="dataSource" matSort>
                        <ng-container *ngFor="let col of displayedData" matColumnDef="{{col.colName}}">
                            <mat-header-cell *matHeaderCellDef mat-sort-header class="manage-table-header">
                                {{col.title}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let data" [ngClass]="{
                                'patient' : col.colName == 'category' && data.tagSerialNumber != null && data.category == 'Patient',
                                'patient-old' : col.colName == 'category' && data.tagSerialNumber == null && data.category == 'Patient',
                                'asset' : col.colName == 'category' && data.tagSerialNumber != null && data.category == 'Asset',
                                'asset-old' : col.colName == 'category' && data.tagSerialNumber == null && data.category == 'Asset'
                            }">{{col.colName == 'category' ? '' : data[col.dataName]}}

                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"
                            class="manage-table-header-row">
                        </mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"
                            (click)=rowClick(row) (toggleChange)="onToggleChange($event,row)">
                        </mat-row>

                    </mat-table>
                </div>
            </div>
                    <mat-paginator #paginator [pageSizeOptions]="[10, 15, 20, 50, 100]" showFirstLastButtons>
                    </mat-paginator>
        </div>
    </div>
</div>
