/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
 ******************************************************************************/
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';

import { CommonService, WorkflowService } from '../../shared';
import { CookieService } from 'ngx-cookie-service';

/*

Description : Workflow Modules implemented the resolver functionality.
Date        : May 13, 2021
Author      : TrackerWave
Developer   : Venkatesh Raju

*/




@Injectable()
export class EmployeeResolver implements Resolve<Observable<any>> {
    public currentDate: any = new Date();
    public fromDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    constructor(private workflowService: WorkflowService,  public datepipe: DatePipe) {}

    resolve(): Observable<any> {
        return this.workflowService.getEmployeeList('CS-AL', this.fromDate);
    }
}

@Injectable()
export class HealthCheckupResolver implements Resolve<Observable<any>> {
    public currentDate: any = new Date();
    public selectedDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    constructor(private workflowService: WorkflowService, private commonService: CommonService, public datepipe: DatePipe) {}

    resolve(): Observable<any> {
        return this.commonService.getHcPatientList(this.selectedDate);
    }
}



@Injectable()
export class IPResolver implements Resolve<Observable<any>> {
    constructor(private workflowService: WorkflowService) {}

    resolve(): Observable<any> {
        return this.workflowService.getInpatientList('', 'All', null, null, 0, 50);
    }
}

@Injectable()
export class ResidentResolver implements Resolve<Observable<any>> {
    constructor(private workflowService: WorkflowService) {}

    resolve(): Observable<any> {
        return this.workflowService.getInpatientList('', 'All', 'VT-RE');
    }
}


@Injectable()
export class DayCareResolver implements Resolve<Observable<any>> {
    public currentDate: any = new Date();
    public selectedDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    constructor(private workflowService: WorkflowService, public datepipe: DatePipe) {}

    resolve(): Observable<any> {
        return this.workflowService.getInpatientList('', 'All', 'VT-DC', this.selectedDate);
    }
}



@Injectable()
export class TaskResolver implements Resolve<Observable<any>> {
    public currentDate: any = new Date();
    public selectedDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    constructor(private workflowService: WorkflowService, public datepipe: DatePipe) {}

    resolve(): Observable<any> {
        return this.workflowService.getTaskDetails(this.selectedDate, true, false, false, false,'0','50', null, 'TFT-BO');
    }
}

@Injectable()
export class OPResolver implements Resolve<Observable<any>> {
    public currentDate: any = new Date();
    public selectedDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    constructor(private workflowService: WorkflowService, public datepipe: DatePipe) {}

    resolve(): Observable<any> {
        return this.workflowService.getOpPatientList(this.selectedDate);
    }
}

@Injectable()
export class OTResolver implements Resolve<Observable<any>> {
    public currentDate: any = new Date();
    public selectedDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    constructor(private workflowService: WorkflowService, private commonService: CommonService, public datepipe: DatePipe) {}

    resolve(): Observable<any> {
        return this.commonService.getHcPatientList(this.selectedDate, 'HP-OT');
    }
}

@Injectable()
export class EmergencyCareResolver implements Resolve<Observable<any>> {
    public currentDate: any = new Date();
    public selectedDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    constructor(private workflowService: WorkflowService, private commonService: CommonService, public datepipe: DatePipe) {}

    resolve(): Observable<any> {
        return this.commonService.getHcPatientList(this.selectedDate, 'HP-EC');
    }
}

@Injectable()
export class MessageCentreResolver implements Resolve<Observable<any>> {
    constructor(private workflowService: WorkflowService, private commonService: CommonService) {}

    resolve(): Observable<any> {
        return this.commonService.getCoasterMessage();
    }
}

@Injectable()
export class PorterResolver implements Resolve<Observable<any>> {
    public currentDate: any = new Date();
    public selectedDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    public requestStatus = ['RQ-AR', 'RQ-AS', 'RQ-CA', 'RQ-CR', 'RQ-HLD', 'RQ-IP', 'RQ-NR', 'RQ-NS', 'RQ-PLN', 'RQ-RCR', 'RQ-RJ', 'RQ-RTN', 'RQ-SH', 'RQ-WT'];
    public porterGroup = [];
    constructor(private workflowService: WorkflowService, private commonService: CommonService, public datepipe: DatePipe, private cookieService: CookieService) {}

    resolve(): Observable<any> {
        if(this.cookieService.get('porter_group_' + localStorage.getItem(btoa('facilityId')) + '_' + localStorage.getItem(btoa('userId'))) == null ||
        this.cookieService.get('porter_group_' + localStorage.getItem(btoa('facilityId')) + '_' + localStorage.getItem(btoa('userId'))) === '') {
            this.commonService.getAppTerms('PoolName').subscribe(res => {
                this.porterGroup = [...res.results.map(item => item.code),0];
            });
        } else {
          this.porterGroup = JSON.parse(this.cookieService.get('porter_group_' + localStorage.getItem(btoa('facilityId')) + '_' + localStorage.getItem(btoa('userId'))));
        }
        return this.commonService.getPorterRequest(this.selectedDate, true, null, this.requestStatus, null, null, this.porterGroup);
    }
}


@Injectable()
export class VisitorResolver implements Resolve<Observable<any>> {
    public currentDate: any = new Date();
    public selectedDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    constructor(private workflowService: WorkflowService, public datepipe: DatePipe) {}

    resolve(): Observable<any> {
        return this.workflowService.getVisitorsList(this.selectedDate);
    }
}

@Injectable()
export class AmbulanceResolver implements Resolve<Observable<any>> {
    public currentDate: any = new Date();
    public selectedDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    public requestStatus = ['RQ-CO', 'RQ-AR', 'RQ-AS', 'RQ-CA', 'RQ-CR', 'RQ-HLD', 'RQ-IP', 'RQ-NR', 'RQ-PLN', 'RQ-RJ', 'RQ-SH', 'RQ-WT'];
    constructor(private commonService: CommonService, public datepipe: DatePipe) {}

    resolve(): Observable<any> {
        return this.commonService.getPorterRequest(this.selectedDate, false, null, this.requestStatus, 'RQT-AMB', localStorage.getItem('customerId'));
    }
}

@Injectable()
export class FormResolver implements Resolve<Observable<any>> {
    public currentDate: any = new Date();
    public fromDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    public selectedDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    constructor(private commonService: CommonService, public datepipe: DatePipe) {}

    resolve(): Observable<any> {
        return this.commonService.getWorkflowFrom(this.fromDate,'All','All');
    }
}

@Injectable()
export class TicketResolver implements Resolve<Observable<any>> {
    public currentDate: any = new Date();
    public selectedDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    constructor(private commonService: CommonService, public datepipe: DatePipe) {}

    resolve(): Observable<any> {
        return this.commonService.getTicketRequest(this.selectedDate, 'RQT-TKT');
    }
}
