/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { base_value } from './environment.base';

export const environment = {
  base_value,
  production: false,

  // env_key: "prod",
  env_key: 'dev',
  // env_key: 'pre-prod',
  //api_base_url_new: 'http://ovitagapi-sp-env-1.2cnseumjbr.ap-south-1.elasticbeanstalk.com/',
  // api_base_url_new:'http://api.trackerwave.com/',
  //  api_base_url_new: 'http://devapi.trackerwave.com:8080/dev/',
  //  api_base_url_new: 'http://referplusapi.trackerwave.com:8080/ref/',
     api_base_url_new: 'https://api.referplus.online/live/',
  // api_base_url_new: 'http://admin.kynhood.com:8080/live/',
  // api_base_url_new: 'http://stage-admin.kynhood.com:8080/stage/',
  // api_base_url_new: 'https://mdlsapi.trackerwave.com/live/',
  // api_base_url_new: 'https://demoapi.trackerwave.com/',
  // api_base_url_new: 'http://localhost:4040/',
  // api_base_url_new: 'https://twapi.relainstitute.com/live/',
  // api_base_url_new:'https://liveapi.trackerwave.com/',
  // api_base_url_new:'https://liveapi.trackerwave.com/live/',
  // api_base_url_new: 'https://api.southindia.cloudapp.azure.com/prelive/',
  /* new url for pre-prod */
  // api_base_url_new: 'http://twapollo.southindia.cloudapp.azure.com:8080/prelive/', 
  // api_base_url_new: 'https://twwebapi.southindia.cloudapp.azure.com/live/',
  versionCheckURL : 'http://devapi.trackerwave.com/version.json',
  // sk : "6Lcz-j8nAAAAACVdRx3YsnudNdHOpLKZc1itJSOm",
  sk : "6LeLuB4nAAAAALZ77IMo8NxC5oAefAr1VyoRpQIc",
  ck : "1",
  pwd_check : false,
  idle_time_out: 4 * 60 * 60,
  cloudNetwork: {'host': '00.000.000.000', 'connectTimeout': 10000, 'protocol': 'ws', 'port': 8883, 'keepalive': 5},

  betweenLocation: {
    'one': { 'floc': '17941', 'tloc': '17942', 'name': 'From Room 2 to Room 3' },
    'two': { 'floc': '17941', 'tloc': '17943', 'name': 'From Room 2 to Room 4' },
    'three': { 'floc': '17941', 'tloc': '17946', 'name': 'From Room 2 to Room 6' },
    'four': { 'floc': '17942', 'tloc': '17943', 'name': 'From Room 3 to Room 4' },
    'five': { 'floc': '17943', 'tloc': '17946', 'name': 'From Room 4 to Room 6' }
  },
  analytics_report : [
    {'id': 'patient_summary', 'name' : 'HC - Patient Summary', 'code' : 'WD_AIPRPS'},
    {'id': 'package_summary', 'name' : 'HC - Package Summary', 'code' : 'WD_AIPRPG'},
    {'id': 'test_summary', 'name' : 'HC - Test Summary', 'code' : 'WD_AIPRTS'},
    {'id': 'report1', 'name' : 'HC - TAT by Patient', 'code' : 'WD_AIPRTP'},
    {'id': 'report2', 'name' : 'HC - Health Test Performance', 'code' : 'WD_AIPRHT'},
    {'id': 'report3', 'name' : 'HC - TAT Detailed Report by Touchpoint', 'code' : 'WD_AIPRTT'},
    {'id': 'coaster-details', 'name' : 'HC - Coaster Return Report', 'code' : 'WD_AIPRCR'},
    {'id': 'geofencevio', 'name' : 'HC - Geo Fence Violation Report', 'code' : 'WD_AIPRGV'},
    {'id': 'open_encounter', 'name' : 'HC - Open Encounter Report', 'code' : 'WD_AIPROE'},
    {'id': 'pat-journey', 'name' : 'HC - Patient Journey Report', 'code' : 'WD_AIPRPJ'},
    {'id': 'tat-all-rep', 'name': 'HC - TAT All Report', 'code':'WD_AIPRTAR'},
    {'id': 'pat-sample-coll', 'name': 'HC - Patient Sample Collection Report', 'code':'WD_AIPRTSC'},
    {'id': 'visitor-summary', 'name': 'HC - Visitor Summary', 'code':'WD_AIPRVS'},
    {'id': 'in_patient', 'name' : 'IP - In Patients Report', 'code' : 'WD_AIPRIP'},
    {'id': 'vitals_chart', 'name' : 'IP - Vitals Chart Report', 'code' : 'WD_AIPRVC'},
    {'id': 'patientfall', 'name': 'IP - Patient Fall Report', 'code' : 'WD_AIPRPF'},
    {'id': 'nurse-call', 'name': 'IP - Nurse Call Efficiency Report', 'code': 'WD_AIPRNC'},
    {'id': 'patient-status-op', 'name' : 'OP - Out Patients Report', 'code' : 'WD_AIPROP'},
    {'id': 'out_patient_TAT', 'name' : 'OP - TAT Report', 'code' : 'WD_AIPROPTAT'},
    {'id': 'OT-Rep', 'name' : 'OT - Complex Utilization Report', 'code' : 'WD_AIPRCR'},
    {'id': 'tat-all-rep-ot', 'name': 'OT - TAT All Report', 'code':'WD_AIPRTAROT'},
    {'id': 'day_care', 'name' : 'DC - Day Care Report', 'code' : 'WD_AIPRDC'},
    {'id': 'newborn-summary', 'name': 'IS - New Born - Mother Summary', 'code' : 'WD_AIPRNB'},
],
  employee_reports : [
    //{'id': 'contact-tree', 'name':'Employee Contact Tracing Report', 'code':'WD_AIERCT'},
    {'id': 'emp-summary-rep', 'name' : 'Employee Summary', 'code' : 'WD_AIERES'},
    {'id': 'emp-contact', 'name':'Employee Contact Tracing Report', 'code':'WD_AIERCT'},
    {'id': 'emp-ccd-all', 'name': 'Employee Close Contacts Report', 'code':'WD_AIPRCCR'},
    {'id': 'emp-ccd-all-byd', 'name': 'Employee Total Duration Contact Per Day Report', 'code':'WD_AIPRCCBDR'},
    {'id': 'emp-ccd-agg', 'name': 'Employee Close Contact By Location', 'code':'WD_AIPRCCBL'},
    {'id': 'emp-loc-rep', 'name': 'Locationwise Contact Tracing Report', 'code':'WD_AIPRLW'},
    {'id': 'visitor-summary', 'name': 'Visitor Summary', 'code':'WD_AIPRVS'},
    {'id': 'temp-id-summary', 'name': 'Temporary ID Summary', 'code':'WD_AIPRVS'},
    {'id': 'temporary-staff-summary', 'name': 'Temporary Staff Summary', 'code':'WD_AIPRVS'},
    {'id': 'battery-summary', 'name': 'Battery Summary', 'code':'WD_AIPRVS'},
    {'id': 'roll-call', 'name':'Roll Call Report', 'code' : 'WD_AIERRC'},  
    {'id': 'emp-attendance-all', 'name' : 'All Employees Attendance Report', 'code' : 'WD_AIERAA'},  
    {'id': 'emp-attendance', 'name' : 'Employee Attendance Report', 'code' : 'WD_AIERIA'},
    {'id': 'emp-geofencevio', 'name': 'Employee Geofence Violation Report', 'code' : 'WD_AIERGF'},
    {'id': 'site-nav', 'name':'Site Visit and Navigation Report', 'code':'WD_AIPRVS'}
  ],
  asset_reports : [
    {'id': 'asset-summary', 'name': 'Asset Summary', 'code':'WD_AIARAS'},
    {'id': 'asset-transfer', 'name': 'Asset Transfer Report', 'code':'WD_AIARAT'},
    {'id': 'asset-missing', 'name': 'Asset Missing Report', 'code':'WD_AIARAMS'},
    {'id': 'asset-misplaced', 'name': 'Asset Misplaced Report', 'code':'WD_AIARAMP'},
    {'id': 'asset-geofencevio', 'name': 'Geofence Violation Report', 'code':'WD_AIARAG'},
    {'id': 'asset-utilz', 'name': 'Asset Utilization Report', 'code':'WD_AIARAU'},
    {'id': 'asset-loc-history', 'name': 'Asset Location History', 'code':'WD_AIARALH'}
  ],
  porter_reports : [
    {'id': 'patientmove', 'name': 'Patient Movement Summary', 'code':'WD_AIPRPM'},
    {'id': 'asset-move', 'name': 'Asset Movement Report', 'code':'WD_AIPRAM'},
    {'id': 'othreq-move', 'name': 'Other Request Movement', 'code':'WD_AIPROM'},
    {'id': 'porter-perf', 'name': 'Porter Performance Summary', 'code':'WD_AIPRPPS'}
  ],
  staff_reports : [
    {'id': 'staff-summary', 'name': 'Staff Summary', 'code':'WD_AISRSS'},
    {'id': 'staff-attendance', 'name': 'Staff Attendance Report', 'code':'WD_AISRSA'}
  ],
  inpatient_report : [ 
    {'id': 'in_patient', 'name' : 'In Patient Summary', 'code' : 'WD_AIPRIP'}, 
    {'id': 'OT-Rep', 'name' : 'OT Complex Utilization Report', 'code' : 'WD_AIPRCR'},   
    {'id': 'tat-all-rep-ot', 'name': 'OT - TAT All Report', 'code':'WD_AIPRTAROT'},   
    {'id': 'vitals-chart', 'name' : 'Vitals Chart Report', 'code' : 'WD_AIPRVC'},
    {'id': 'geofencevio', 'name' : 'Geo Fence Violation Report', 'code' : 'WD_AIPRGV'},
    {'id': 'patientfall', 'name': 'Patient Fall Report', 'code' : 'WD_AIPRPF'},
    {'id': 'nurse-call', 'name': 'Nurse Call Efficiency Report', 'code': 'WD_AIPRNC'}            
  ],
  infantsecurity_report : [
    {'id': 'newborn-summary', 'name': 'New Born - Mother Summary', 'code' : 'WD_AIPRNB'}
  ] ,
 daycare_reports : [
  {'id': 'day_care', 'name' : 'DayCare Summary', 'code' : 'WD_AIPROP'}
],
 outpatient_reports : [
  {'id': 'patient-status-op', 'name' : 'Out Patient Summary', 'code' : 'WD_AIPROP'},
  {'id': 'out_patient_TAT', 'name' : 'TAT Report Out Patient', 'code' : 'WD_AIPROPTAT'},
  {'id': 'geofencevio', 'name' : 'Geo Fence Violation Report', 'code' : 'WD_AIPRGV'}
],
healthCheckup_reports : [
      {'id': 'patient-status', 'name' : 'Patient Summary', 'code' : 'WD_AIPRPS'},
      {'id': 'hc_plan_summary2', 'name' : 'Package Summary', 'code' : 'WD_AIPRPG'},
      {'id': 'hc_test_summary2', 'name' : 'Test Summary', 'code' : 'WD_AIPRTS'},
      {'id': 'report1', 'name' : 'TAT by Patient', 'code' : 'WD_AIPRTP'},
      {'id': 'report2', 'name' : 'Health Test Performance', 'code' : 'WD_AIPRHT'},
      {'id': 'report3', 'name' : 'TAT Detailed Report by Touchpoint', 'code' : 'WD_AIPRTT'},
      {'id': 'pat-sample-coll', 'name' : 'Patient Sample Collection Report', 'code' : 'WD_AIPRPSC'},
      {'id': 'coaster-details', 'name' : 'Coaster Return Report', 'code' : 'WD_AIPRCR'},
      {'id': 'geofencevio', 'name' : 'Geo Fence Violation Report', 'code' : 'WD_AIPRGV'},
      {'id': 'open-encounter', 'name' : 'Open Encounter Report', 'code' : 'WD_AIPROE'},
      {'id': 'pat-journey', 'name' : 'Patient Journey Report', 'code' : 'WD_AIPRPJ'},
      {'id': 'emp-contact', 'name': 'Contact Tracing Report', 'code' : 'WD_AIPRPC'},
      {'id': 'tat-all-rep', 'name': 'TAT All Report', 'code':'WD_AIPRTAR'}
    ]
};
