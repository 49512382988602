<div class="div-styl_2">
  <div class="titl-styl" style="padding-left: 10px;">Layout Change Alert</div>
</div>
<div style="margin-top: 12px; font-size: 15px; font-weight: bold; color: hsl(205, 36%, 25%); margin-top: 10px; margin-bottom: 10px; text-align: center;">Do you want to save the layout changes</div>
<mat-grid-list cols = 2 rowHeight="40px" class="oflw-style" style="margin-top: 10px;">
  <mat-grid-tile class="button-icon" style="margin-left: 7px;margin-right: 10px;">
    <button mat-raised-button [mat-dialog-close]="'Yes'" (click)="saveLayout()" tabindex="-1" style="background-color: #019F96; color: white;">Yes</button>
  </mat-grid-tile>    
  <mat-grid-tile class="button-icon" >
    <button mat-raised-button [mat-dialog-close]="'No'" (click)="closeDialogue()" tabindex="-1" style="background-color: #019F96; color: white;">No</button>
  </mat-grid-tile>    
</mat-grid-list>