import { Component, OnInit, Inject } from '@angular/core';
import { VERSION } from '@angular/material/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from '../../../../shared/services/dashboard.service';

@Component({
  selector: 'app-layout-save',
  templateUrl: './layout-save.component.html',
  styleUrls: ['./layout-save.component.scss']
})
export class LayoutSaveComponent implements OnInit {

  public layoutData : any = [];

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
  private dialogRef: MatDialogRef<LayoutSaveComponent>, public dashboardService: DashboardService,) {
    if(data){
      // console.log(data)
      this.layoutData['dashboardId'] = data[0]
      this.layoutData['widgetId'] = data[1]
      this.layoutData['xPos'] = data[2]
      this.layoutData['yPos'] = data[3]
      this.layoutData['rows'] = data[4]
      this.layoutData['cols'] = data[5]
    }
  }

  ngOnInit() {
  }

  saveLayout(){
    this.dashboardService.getLayoutUpdate(this.layoutData['dashboardId'], this.layoutData['widgetId'], this.layoutData['xPos'], this.layoutData['yPos'], this.layoutData['rows'], this.layoutData['cols']).subscribe(res => {
      if (res.results.statusCode == 200) {
        console.log(this.layoutData['xPos']) 
      }
      this.dialogRef.close();
    })
  }

  closeDialogue(){
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  
  public title = 'Confirmation';
  public message = 'Are you sure?';
  public confirmButtonText = 'Yes';
  public cancelButtonText = 'Cancel';
  public createButtonText = 'Create new Layout';
  customerName: string;
    
  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>) { }

  ngOnInit() {
    if (window.location.hostname.includes("kyn")) {
      this.customerName = "kyn";
    }
    this.title = this.data.title ? this.data.title : this.title;
    this.message = this.data.message ? this.data.message : this.message;
    if (this.data.hasOwnProperty('buttonText')) {
      this.confirmButtonText = this.data.buttonText.ok ? this.data.buttonText.ok : this.confirmButtonText;
      this.cancelButtonText = this.data.buttonText.cancel ? this.data.buttonText.cancel : this.cancelButtonText;
      this.createButtonText = this.data.buttonText.new ? this.data.buttonText.new : this.createButtonText;
    } else {
      this.confirmButtonText = this.data.button.ok ? this.data.button.ok : this.confirmButtonText;
      this.cancelButtonText = this.data.button.cancel ? this.data.button.cancel : this.cancelButtonText;
    }
  }
}
