import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { UntypedFormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

@Component({
    selector: 'app-dynamic-layout',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class DynamicFormComponent implements OnInit {
  public enableFrmCmp = false;
      
  constructor(public form: UntypedFormBuilder){}
  ngOnInit(): void {
    this.buildform();
  }
  buildform(){
  }
  
  onTabChanged(event){
    if(event.tab.textLabel=='Preview'){
      this.enableFrmCmp = true;
    }
  }
}