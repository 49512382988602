<div class="manage-body">
  <div  *ngIf="referTokenType !== '#refer-plus'" class="align-header-1" style="margin-bottom:3px">

    <div class="row-field">
      <div class="tw-view-full-width-flex">
        <div class="row">
          <div style="display: flex">
            <span class="tw-filter-new">
              <input matInput i18n-placeholder #searchInputClear style="padding: 5px;"
                (keyup)="applyFilter($event.target.value)" placeholder="Filter" autocomplete="off" role="presentation">
              <button mat-icon-button matSuffix aria-label="clear" *ngIf="searchInputClear.value"
                (click)="searchInputClear.value=''; applyFilter('');">
                <span class="tw-filter-close">x</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row-field">
      <div class="tw-view-full-width-pad">
        <div class="tw-btn"
          *ngIf="activate_btn && (activate_btn.indexOf('BT_ALLC') > -1 || activate_btn.indexOf('BT_HPMUC') > -1)">
          <div class="tw-event-action-btn" [ngStyle]="{'background': customerName === 'kyn' ? '#D61820' : ''}">
            <button i18n mat-button class="action-btn" style="padding: 0 22px;" (click)="createUser('', $event)">Create</button>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="tw-content1 table-align">
    <div fxLayout="column" class="tw-mat-elevation manage-component table-h-50"
     fxLayoutGap="5px" style="max-height: calc(100vh - 135px);">
      <div class="mat-table-overflow">
        <table mat-table [dataSource]="dataSource" matSort>
          
          <ng-container matColumnDef="userName">
            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header
              class="manage-table-header custom-mat-column-header-width"> Name </th>
            <td mat-cell *matCellDef="let data" appTooltip tooltipText="{{tooltipOver}}" placement="bottom"
              (click)="(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) && createUser(data, $event)"
              class="custom-mat-column-width"> {{data.firstName}} {{data.lastName}}</td>
          </ng-container>
          <!-- <ng-container matColumnDef="birthdate">
            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs style="display: table-cell;" class="manage-table-header custom-mat-column-header-width"> Birthdate </th>
            <td mat-cell *matCellDef="let data" i18n-[matTooltip] [matTooltip] = "(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) ? 'Click to edit' : '' " (click) = "(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) && createUser(data, $event)"
              fxHide.xs style="display: table-cell;" class="custom-mat-column-width">{{data.birthDate | date}}</td>
          </ng-container> -->
          
          <ng-container matColumnDef="roles">
            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs style="display: table-cell;" class="manage-table-header custom-mat-column-header-width"> Role </th>
            <td mat-cell *matCellDef="let data" appTooltip tooltipText="{{tooltipOver}}" placement="bottom" (click) = "(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) && createUser(data, $event)"
              fxHide.xs style="display: table-cell;" class="custom-mat-column-width">
              <!-- <span *ngIf="data.roles.length > 0">{{data.roles[0].name}}</span> -->
              <span>{{data.roleName}}</span>
            </td>
          </ng-container>
<!--           
          <ng-container matColumnDef="currentLocationName">
              <th i18n  mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs style="display: table-cell;" class="manage-table-header custom-mat-column-header-width"> Current Location </th>
              <td mat-cell *matCellDef="let data" i18n-[matTooltip] appTooltip [tooltipText] = "(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) ? 'Click to edit' : '' " placement="bottom" (click) = "(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) && createUser(data, $event)"
                fxHide.xs style="display: table-cell;" class="custom-mat-column-width">
                {{data['currentLocationName']}}<span *ngIf="data['currentLocationName'] !== null && data['floorName'] !== null">, </span>{{data['floorName']}}
              </td>
            </ng-container>

          <ng-container matColumnDef="currentLocation">
            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header style="display: table-cell;"
              class="manage-table-header custom-mat-column-header-width"> Current Location </th>
            <td mat-cell *matCellDef="let data" i18n-[matTooltip]
              appTooltip [tooltipText]="(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) ? 'Click to edit' : '' " placement="bottom"
              (click)="(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) && createUser(data, $event)"
              style="display: table-cell;" class="custom-mat-column-width"></td>
          </ng-container> -->
          
          <ng-container matColumnDef="email">
            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header style="display: table-cell;"
              class="manage-table-header custom-mat-column-header-width"> E-mail </th>
            <td mat-cell *matCellDef="let data"
              (dblclick)="(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) && createUser(data, $event)"
              (click)="(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) && rowClick(data)"
              style="display: table-cell;" class="custom-mat-column-width"
              appTooltip [tooltipText]="data.email" placement="bottom"> {{data.email}} </td>
          </ng-container>

          <ng-container matColumnDef="phoneNumber">
            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header style="display: table-cell;"
              class="manage-table-header custom-mat-column-header-width"> Phone No </th>
            <td mat-cell *matCellDef="let data"
              (dblclick)="(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) && createUser(data, $event)"
              (click)="(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) && rowClick(data)"
              style="display: table-cell;" class="custom-mat-column-width"> {{data.phoneNumber}} </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header style="display: table-cell;"
              class="manage-table-header custom-mat-column-header-width"> User ID </th>
            <td mat-cell *matCellDef="let data"
              (dblclick)="(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) && createUser(data, $event)"
              (click)="(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) && rowClick(data)"
              style="display: table-cell;" class="custom-mat-column-width"> {{data.id}} </td>
          </ng-container>

          <!-- <ng-container matColumnDef="sourceId">
            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header style="display: table-cell;"
              class="manage-table-header custom-mat-column-header-width"> Last log-in </th>
            <td mat-cell *matCellDef="let data"
              (dblclick)="(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) && createUser(data, $event)"
              (click)="(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) && rowClick(data)"
              style="display: table-cell;" class="custom-mat-column-width"> {{data.sourceId}} </td>
          </ng-container> -->

          <ng-container matColumnDef="status">
            <th i18n mat-header-cell *matHeaderCellDef mat-sort-header
              class="manage-table-header custom-mat-column-header-width"> Status </th>
            <td mat-cell *matCellDef="let data"
              (dblclick)="(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) && createUser(data, $event)"
              (click)="(activate_btn.indexOf('BT_ALLE') > -1 || activate_btn.indexOf('BT_HPMUE') > -1) && rowClick(data)"
              class="custom-mat-column-width"> Active </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;" class="manage-table-header-row"
            [ngStyle]="headercolor && {'background':headercolor}"></tr>
          <tr mat-row *matRowDef="let data; columns: displayedColumns;"
            [ngClass]="{'highlight' : data.firstName === selectedName}" [ngStyle]="bgcolor && {'background':bgcolor}">
          </tr>
        </table>
      </div>
      <!-- <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator> -->
      <mat-paginator *ngIf="pageHit &&  referTokenType !== '#refer-plus'" [pageSizeOptions]="[10, 20, 50, 100]" (page)="eventTriggers($event)" [length]="length" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
