<div style="border: 1px solid #bdbdbdf2;height: 100%;width: 100%;">
    <!-- <div style="color: #3a3838f2;height: 45px;padding-left: 10%;">
        <span style="width : calc( 100% - 35px);font-size: 22px;font-weight: 600;">{{labelName}}</span>
        <span style="float: right;margin-right: 10%;"><button mat-icon-button (click)="exitPopup()"> <mat-icon style="font-size: 35px;">highlight_off</mat-icon></button></span>
    </div> -->
    <mat-toolbar style="background: #4e88ff;color: white;height: 45px;">
        <div style="width : calc( 100% - 35px);font-size:20px; font-weight:600">Edit {{labelName}}</div>
        <button mat-icon-button (click)="exitPopup()"> <mat-icon style="font-size: 35px;">highlight_off</mat-icon></button>
    </mat-toolbar>
    <mat-divider></mat-divider>

    <div [formGroup]="formDataItem" style="padding: 5px;height: calc(100% - 100px);overflow: auto;">
        <span>
            <div *ngFor="let dataItemObj of dataItemFields">
                <span *ngIf="dataItemObj === 'defaultValue'">
                    <span class="mat-custom-form-field-box" fxFlex.xs="100" fxFlex.lg="100" fxFlex.md="50" fxFlex.sm="50">
                        <span *ngIf="fieldType == 'DI-TXT'">
                            <mat-form-field style="width: calc(100% - 10px);">
                                <input matInput  type="text" [formControlName]="dataItemObj" placeholder="DefaultValue">
                            </mat-form-field>
                        </span>
                        <span *ngIf="fieldType == 'DI-DTE'">
                            <mat-form-field style="width: calc(100% - 10px);">
                                <mat-select i18n-placeholder placeholder="Default Value" [formControlName]="dataItemObj" placeholder="DefaultValue">
                                    <mat-option *ngFor="let value of defaultDate" [value]="value">{{value}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </span>
                    </span>
                </span>
                <span fxFlex.xs="100" fxFlex.lg="100" fxFlex.md="50" fxFlex.sm="50">
                    <span *ngIf="dataItemObj === 'labelName'">
                        <mat-form-field style="width: calc(100% - 10px);">
                            <input matInput  type="text" [formControlName]="dataItemObj" placeholder="LabelName">
                        </mat-form-field>
                    </span>
                    <span *ngIf="dataItemObj === 'name'">
                        <mat-form-field style="width: calc(100% - 10px);">
                            <input matInput  type="text" [formControlName]="dataItemObj" placeholder="Name">
                        </mat-form-field>
                    </span>
                    <span *ngIf="dataItemObj === 'position'">
                        <mat-form-field style="width: calc(100% - 10px);">
                            <input matInput  type="text" [formControlName]="dataItemObj" placeholder="Position">
                        </mat-form-field>
                    </span>
                    <span *ngIf="dataItemObj === 'customCss'">
                        <mat-form-field style="width: calc(100% - 10px);">
                            <input matInput  type="text" [formControlName]="dataItemObj" placeholder="CustomCss">
                            <mat-error *ngIf="formDataItem.controls.customCss.invalid">
                                <span style="font-size: 14px;">customCss JSON data is invalid</span>
                            </mat-error>
                        </mat-form-field>
                    </span>
                    <span *ngIf="dataItemObj === 'tooltip'">
                        <mat-form-field style="width: calc(100% - 10px);">
                            <input matInput type="text" [formControlName]="dataItemObj" placeholder="Tooltip">
                        </mat-form-field>
                    </span>
                    <span *ngIf="dataItemObj === 'contextValue'">
                        <mat-form-field style="width: calc(100% - 10px);">
                            <input matInput type="text" [formControlName]="dataItemObj" placeholder="ContextValue">
                            <mat-error *ngIf="formDataItem.controls.contextValue.invalid">
                                <span style="font-size: 14px;">ContextValue JSON data is invalid</span>
                            </mat-error>
                        </mat-form-field>
                    </span>
                    <span *ngIf="dataItemObj === 'multiSelect' && fieldType == 'DI-DRD'">
                        <mat-form-field style="width: calc(100% - 10px);">
                            <mat-select i18n-placeholder placeholder="MultiSelect" formControlName="multiSelect">
                                <mat-option *ngFor="let value of options" [value]="value">{{value}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </span>
                    <span *ngIf="dataItemObj === 'customValue' && fieldType == 'DI-DRD'">
                        <mat-form-field style="width: calc(100% - 10px);">
                            <input matInput type="text" [formControlName]="dataItemObj" placeholder="customValue">
                            <mat-error *ngIf="formDataItem.controls.customValue.invalid">
                                <span style="font-size: 14px;">CustomValue JSON data is invalid</span>
                            </mat-error>
                        </mat-form-field>
                    </span>
                    <span *ngIf="dataItemObj === 'validation'">
                        <mat-form-field style="width: calc(100% - 10px);">
                            <textarea matInput i18n-[placeholder] rows = 4 placeholder="validation" formControlName="validation"></textarea>
                            <mat-error *ngIf="formDataItem.controls.validation.invalid">
                                <span style="font-size: 14px;">validation JSON data is invalid</span>
                            </mat-error>
                        </mat-form-field>
                    </span>
                </span>
            </div>
            <!-- <span *ngIf="enableForm">
                <mat-divider></mat-divider>
                <div style="padding-bottom: 2%;padding-top: 2%;font-weight: bold;">VALIDATION</div>
                <div *ngFor="let obj of objKeys">
                    <div>
                        <span class="mat-custom-form-field-box" fxFlex.xs="100" fxFlex.lg="100" fxFlex.md="50" fxFlex.sm="50">
                            <span *ngIf="obj === 'isActive'">
                                <mat-form-field style="width: calc(100% - 10px);">
                                    <mat-select i18n-placeholder placeholder="isActive" formControlName="isActive">
                                        <mat-option *ngFor="let value of options" [value]="value">{{value}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </span>
                            <span *ngIf="obj === 'mandatory'">
                                <mat-form-field style="width: calc(100% - 10px);">
                                    <mat-select i18n-placeholder placeholder="Mandatory" formControlName="mandatory">
                                        <mat-option *ngFor="let value of options" [value]="value">{{value}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </span>
                            <span *ngIf="obj === 'maxLength'">
                                <mat-form-field style="width: calc(100% - 10px);">
                                    <input matInput type="number" [formControlName]="obj" placeholder="MaxLength">
                                </mat-form-field>
                            </span>
                            <span *ngIf="obj === 'minLength'">
                                <mat-form-field style="width: calc(100% - 10px);">
                                    <input matInput type="number" [formControlName]="obj" placeholder="MinLength">
                                </mat-form-field>
                            </span>
                        </span>
                    </div>
                </div>
            </span> -->
        </span>
    </div>
    <div style="float: right;height: 45px;">
        <button i18n mat-button class="cancle-btn" style="margin: 5px;" (click)="exitPopup()">Cancel</button>
        <button i18n mat-button class="active-btn" style="margin: 5px;" cdkFocusInitial (click)="saveDataItemValidation()" [disabled]="formDataItem.invalid">Save</button>
    </div>
</div>