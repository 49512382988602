/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { VERSION, ErrorStateMatcher, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl, FormGroupDirective, NgForm, FormArray, ValidatorFn } from '@angular/forms';
import { DashboardService } from '../../../services/dashboard.service';
import { HospitalService } from '../../../services/hospital.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { CommonService } from '../../../services';
import { environment } from '../../../../../environments/environment';
import { DatePipe } from '@angular/common';
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import * as moment_ from 'moment';
import { ErrorStateMatcherService } from '../../../services/error-state-matcher.service';
import { ToastrService } from 'ngx-toastr';
import { posix } from 'path';
const moment = moment_;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmation-dialog.html',
  styleUrls: ['./confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ConfirmationDialog implements OnInit {
  message = 'Are you sure?';
  confirmButtonText = 'Yes';
  ackButtonText = 'Ack';
  cancelButtonText = 'Cancel';
  floorList: any;
  floorId = null;
  customMsg = false;
  public isDisabled = false;
  public matcher = new ErrorStateMatcherService();
  public searchTestlist: any;
  public searchTestListItems = [];
  public selectedId: any;
  public selectedTesttype: any;
  public reasonList: any;
  hide = true;
  public today: any = new Date();
  public currentDate = this.datepipe.transform(this.today, 'yyyy-MM-dd HH:mm:ss');
  public formTempNameEnable = false;
  public formTemplateName = null;
  public enableReason = false;

  confirmForm: UntypedFormGroup;
  durationCode = null;
  durationValue = null;
  durationList: any=[];
  pwdExist = false;
  errorExist = false;
  infantEventTypeId = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConfirmationDialog>, private fb: UntypedFormBuilder, public datepipe: DatePipe,
    private configurationService: ConfigurationService, private commonService: CommonService,
    private dashboardService: DashboardService, private hospitalService: HospitalService, public toastr: ToastrService) {

    if (data) {
      this.message = data.message || this.message;
      this.data.assignFloor = data.assignFloor || false;
      this.data.isNewTestAdded = data.isNewTestAdded || false;
      this.floorId = data.floorId || this.floorId;
      this.formTempNameEnable = data.formTempNameEnable || false;
      if(this.formTempNameEnable){
        this.formTemplateName = data.formTempName || null;
      }
      if(data.hasOwnProperty('customMsg')) {
        this.customMsg = data.customMsg;
      }
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
    }

    this.commonService.getConfigFile('assign-floor').subscribe(res => {
      if (res.results != null) {
        this.floorList = res.results.contentObject;
      }
      if (this.data.floorId != null) {
        this.floorId = data.floorId;
      } else {
        if (this.floorList != null) {
          this.floorId = this.floorList[1].floorId;
        }
      }
      this.buildForm();
      if (this.data.hasOwnProperty('disengageAlarm') && this.data.disengageAlarm === true) {
        if(this.data.infantData && this.data.infantData.infantEventList.length !== 0) {
          const infanteventData = this.data.infantData.infantEventList.reverse()
          infanteventData.forEach(list => {
            if(list.eventTypeId === "IET-AT") {
              this.infantEventTypeId = list.eventTypeId;
              this.durationCode = list.identifyingType;
              this.durationValue = list.identifyingValue;
              this.confirmForm.controls['purpose'].setValue(list.comments);
              this.confirmForm.controls['duration'].setValue(list.identifyingType);
            } else if(list.eventTypeId === "IET-ATC") {
              this.infantEventTypeId = list.eventTypeId;
              this.durationCode = null;
              this.durationValue = null;
              this.confirmForm.controls['purpose'].setValue(null);
              this.confirmForm.controls['duration'].setValue(null);
            }
          })
        }
      }
    });
  }

  ngOnInit() {
    if(this.data.hasOwnProperty('status') && this.data.status == 'RQ-CA') {
      this.getAppTermLink('RQT-PO', 'RequestCancelReason');    
    } else {
      this.reasonCode();
    }
    if (this.data.hasOwnProperty('disengageAlarm') && this.data.disengageAlarm === true) {
      this.commonService.getAppTermsVerion2('DurationMinutes').subscribe(res => {
        this.durationList = res.results;
      });
    }
    this.buildForm();
  }
  getAppTermLink(parentCode, groupName) {
    this.commonService.getAppTermsLink(parentCode).subscribe((res) => {
      if(res.statusCode == 1) {
        this.reasonList = res.results.filter(val => val.groupName == 'RequestCancelReason');    
        if(this.reasonList.length) {
          this.confirmForm.get('reasonCode').setValue(this.reasonList[0].code);
        }
        this.enableReason = true;                    
      }
    });
  }
  
  buildForm() {
    this.confirmForm = this.fb.group({
      comments: '',
      reasonCode: [null, [Validators.required]],
      confirmPwd: [null],
      // floor: [this.floorId ? this.floorId : null, [Validators.required]]
      floor: [this.floorId ? this.floorId : null],
      testId: [null, [Validators.required]],
      isPendingTest: [true],
      reviewDate:[null],
      formTempName:[null, [Validators.required]],
      duration: [null],
      purpose: [null]
    },{ validator: this.formTempNameEnable?this.customValidate(this.formTemplateName):''});
  } 
  customValidate(ftname:any): ValidatorFn{
    return (fg: UntypedFormGroup): { [key: string]: any } | null => {
      let error = null;
      if (fg.get('formTempName').value != null && fg.get('formTempName').value !== '') {
        let optionValue = fg.get('formTempName').value;
        if(ftname == optionValue){
          error = fg.controls['formTempName'].setErrors({ invalid: true });
        }
      }
      return error;
    }
  }
  reasonCode() {
    this.commonService.getAppTermsVerion2('ReasonCode').subscribe(res => {
      if (res.statusCode === 1) {
        this.reasonList = res.results;
        if (this.data.statusId === 'QS-NR') {
          this.confirmForm.get('reasonCode').setValue(this.reasonList[0].code);
          this.confirmForm.get('reasonCode').updateValueAndValidity();
        }
      }
    });
  }

  searchToTestName(event) {
    if(event.type === 'testName' && event.text.length >= 2) {
      if (event.toHit == true) {
        this.commonService.getAllHeathTestWithoutAdminTest(event.text).subscribe(res => {
          this.searchTestListItems = res.results;
          this.searchTestlist = this.searchTestListItems;
        });
      } else {
        this.searchTestlist = this.searchTestListItems;
      }
    } else {
      this.searchTestlist = [];
    }
  }

  addTestEvents(data) {
    console.log(data);
    this.selectedId = data.id;
    this.selectedTesttype = data.testType;
    console.log(this.selectedId, this.selectedTesttype);
  }
  addReasonCode(data) {
    this.confirmForm.controls.reasonCode.setValue(data.code);
  }

  // onConfirmClick(): void {
  //   this.dialogRef.close();
  // }

  ackAlert(isAck) {
    let ackAlertDetail = [{
      'ackUserId': parseInt(localStorage.getItem(btoa('userId'))),
      'id': this.data.alertDetails.alertId,
    }];
    if(isAck) {
      ackAlertDetail[0]['isAck'] = true;
    } else {
      ackAlertDetail[0]['comments'] = this.confirmForm.controls['comments'].value;
    }
    this.commonService.ackAlertById(ackAlertDetail).subscribe(resAlert => {
      if (resAlert.statusCode === 1) {
        this.toastr.success('Success', `${resAlert.message}`);
        this.dialogRef.close('confirm');
      }
    },
    error => {
      this.toastr.error('Error', `${error.error.message}`);
    }); 
  }

  getDuration(data) {
    this.durationCode = data.code;
    this.durationValue = data.value;
  }

  checkPassword() {
  // if (this.data.isEngage) {
  //       this.dialogRef.close(this.confirmButtonText);
  //     } else {
        const disengageAlarm = {
          'password': this.confirmForm.controls['confirmPwd'].value
        };
        this.commonService.basicLogin(disengageAlarm).subscribe(res => {
          this.errorExist = false;
          if (res.statusCode === 1) {
            this.pwdExist = true;
          } else {
            this.pwdExist = false;
            this.errorExist = true;
            this.confirmForm.controls['confirmPwd'].setValue(null);
          }
        },
          error => {
            this.errorExist = true;
            this.confirmForm.controls['confirmPwd'].setValue(null);
            this.toastr.error('Error', `${error.error.message}`);
          });
      // }
  }

  saveComments(type?: string) {

    if (this.data.hasOwnProperty('diabetic') && this.data.diabetic === true) {
      // console.log(this.data.diabetic);
      const diabetic_status = {
        'isDiabetic': this.data.isDiabetic, 'healthPlanId': this.data.healthPlanId,
        'patientVisitId': this.data.patientVisitId, 'id': this.data.patientId
      };
      // console.log(diabetic_status);
      // return;
      this.commonService.updatePatientDiabetic(diabetic_status).subscribe(res => {
        this.isDisabled = true;
        if (res.statusCode === 1) {
          this.isDisabled = true;
          this.toastr.success('Success', `${res.message}`);
          this.dialogRef.close('Yes');
        }
      },
        error => {
          this.isDisabled = false;
          this.toastr.error('Error', `${error.error.message}`);
        });

    } else if (this.data.hasOwnProperty('mobile') && this.data.mobile === true) {


      const mobile_status = {
        'id': this.data.patientId, 'useMobile': this.data.useMobile,
        'comments': this.confirmForm.controls['comments'].value
      };
      console.log(mobile_status);
      this.hospitalService.updatePatient(mobile_status).subscribe(res => {

        this.isDisabled = true;
        if (res.statusCode === 1) {
          this.isDisabled = true;
          this.toastr.success('Success', `${'Mobile preference updated'}`);
          this.dialogRef.close('Yes');
        }
      },
        error => {
          this.isDisabled = false;
          this.toastr.error('Error', `${error.error.message}`);
        });

    } else if (this.data.hasOwnProperty('assignFloor') && this.data.assignFloor === true) {
      // console.log(this.data.patient);
      const floorDetailInsert = [];
      const floorId = this.confirmForm.controls['floor'].value;

      if (this.data.floorId === floorId) {
        const message = 'Selected floor already assigned';
        this.toastr.success('Success', `${message}`);
        this.dialogRef.close('Yes');
      } else {

        const floorDetails = this.floorList.filter(res => res.floorId === floorId);
        if (floorId) {
          // console.log(floorDetails);
          for (let i = 0; i < this.data.patient.length; i++) {
            floorDetailInsert.push(
              {
                'tagId': this.data.patient[i]['tagId'],
                'x': floorDetails[0].x,
                'y': floorDetails[0].y,
                'floorId': floorDetails[0].floorId,
                'floorName': floorDetails[0].floorName,
                'locationId': floorDetails[0].locationId,
                'locationName': floorDetails[0].locationName,
                'careSetting': floorDetails[0].careSetting,
                'locationCat': floorDetails[0].locationCat,
                'tagType': floorDetails[0].tagType,
                'tagValue': this.data.patient[i]['patientId'],
                'patientVisitId': this.data.patient[i]['visit_id']
              }
            );
          }
        } else {
          for (let i = 0; i < this.data.patient.length; i++) {
            floorDetailInsert.push(
              {
                'tagId': this.data.patient[i]['tagId'],
                'x': floorDetails[0].x,
                'y': floorDetails[0].y,
                'floorId': floorDetails[0].floorId,
                'floorName': floorDetails[0].floorName,
                'locationId': floorDetails[0].locationId,
                'locationName': floorDetails[0].locationName,
                'careSetting': floorDetails[0].careSetting,
                'locationCat': floorDetails[0].locationCat,
                'tagType': floorDetails[0].tagType,
                'tagValue': this.data.patient[i]['patientId'],
                'patientVisitId': this.data.patient[i]['visit_id']
              }
            );
          }
        }
        console.log(JSON.stringify(floorDetailInsert));
        this.commonService.saveAssignDefaultFloor(floorDetailInsert).subscribe(res => {
          if (res.statusCode === 1) {
            this.toastr.success('Success', `${res.message}`);
            this.dialogRef.close('Yes');
          }
        },
          error => {
            this.toastr.error('Error', `${error.error.message}`);
          });
      }
    } else if (this.data.hasOwnProperty('patientLocation') && this.data.patientLocation === true) {

      this.commonService.updatePatientTestLocation(this.data.updatePatientDetails).subscribe(result => {
        if (result.statusCode === 1) {
          this.toastr.success('Success', `${result.message}`);
          this.dialogRef.close('Yes');
        }
      },
        error => {
          setTimeout(() => this.dialogRef.close('Yes'), environment.base_value.lockingTimeout);
          this.toastr.error('Error', `${error.error.message}`, { timeOut: environment.base_value.lockingTimeout });
        });

    } else if (this.data.hasOwnProperty('patientPendingLocation') && this.data.patientPendingLocation === true) {
      if (!this.confirmForm.controls['isPendingTest'].value) {
        this.commonService.updatePatientTestLocation(this.data.updatePatientDetails).subscribe(result => {
          if (result.statusCode === 1) {
            this.toastr.success('Success', `${result.message}`);
            this.dialogRef.close('Continue');
          }
        },
          error => {
            setTimeout(() => this.dialogRef.close('Yes'), environment.base_value.lockingTimeout);
            this.toastr.error('Error', `${error.error.message}`, { timeOut: environment.base_value.lockingTimeout });
          });
      } else {
        this.dialogRef.close(this.data.updatePatientDetails);
      }
    } else if (this.data.hasOwnProperty('oTEnroll') && this.data.enrollData != null) {
      this.data.enrollData['checkExisting'] = false;
      this.commonService.saveRegisteredPatients(this.data.enrollData).subscribe(res => {
        this.toastr.success('Success', `${res.message}`);
        this.dialogRef.close('confirm');
      },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });
    } else if (this.data.hasOwnProperty('disAssociateCoaster') && this.data.tagId != null) {
      const disAssociateDevice = { 'tagSerialNumber': this.data.tagId };

      this.configurationService.disassociateTag(disAssociateDevice).subscribe(res => {
        this.toastr.success('Success', `${res.message}`);
        this.dialogRef.close('confirm');
      },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });
    } else if (this.data.hasOwnProperty('swversionUpdate') && this.data.id != null) {

      this.configurationService.UpdateSoftwareVersion(this.data).subscribe(results => {
        this.toastr.success('Success', `${results.message}`);
        this.dialogRef.close('confirm');
      },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });
    } else if (this.data.hasOwnProperty('shift') && this.data.deleteShift === true) {

      this.hospitalService.updateShift(this.data.shiftId, this.data.shiftData).subscribe(results => {
        this.toastr.success('Success', `${results.message}`);
        this.dialogRef.close('confirm');
      },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });
    } else if (this.data.hasOwnProperty('routineActivities') && this.data.deleteActivity === true) {
      if (this.data.RType !== 'template') {
        this.configurationService.deleteRoleRoutineActivities(this.data.entityRoutineActivityId).subscribe(resDelRout => {
          this.toastr.success('Success', `${resDelRout.message}`);
          this.dialogRef.close('confirm');
        },
          error => {
            this.toastr.error('Error', `${error.error.message}`);
          });
      } else {
        this.configurationService.deleteRoutineActivities(this.data.routineActivityId).subscribe(resRout => {
          this.toastr.success('Success', `${resRout.message}`);
          this.dialogRef.close('confirm');
        },
          error => {
            this.toastr.error('Error', `${error.error.message}`);
          });
      }
    } else if (this.data.hasOwnProperty('RoleEndRoutine') && this.data.deleteRoutine === true) {
      this.configurationService.deleteRoleRoutine(this.data.entityRoutineId).subscribe(resIPRout => {
        this.toastr.success('Success', `${resIPRout.message}`);
        this.dialogRef.close('confirm');
      },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });
    } else if (this.data.hasOwnProperty('RoleCancelRoutine') && this.data.cancelRoutine === true) {
      this.configurationService.cancelRoleRoutine(this.data.entityRoutineEventId).subscribe(resIPRout => {
        this.toastr.success('Success', `${resIPRout.message}`);
        this.dialogRef.close('confirm');
      },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });
    } else if (this.data.hasOwnProperty('completeTask') && this.data.completeTask === true) {
      this.data.completeData['remarks'] = this.confirmForm.controls['comments'].value,
      this.commonService.updateTask(this.data.requestId, this.data.completeData).subscribe(resCompTask => {
        this.toastr.success('Success', `${resCompTask.message}`);
        this.dialogRef.close('confirm');
      },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });
    } else if (this.data.hasOwnProperty('deleteTask') && this.data.deleteTask === true) {
      this.data.deleteData['remarks'] = this.confirmForm.controls['comments'].value,
      this.commonService.updateTask(this.data.requestId, this.data.deleteData).subscribe(resDeleteTask => {
        this.toastr.success('Success', `${resDeleteTask.message}`);
        this.dialogRef.close('confirm');
      },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });
    } else if (this.data.hasOwnProperty('certificateUpdate') && this.data.certificateUpdate === true) {
      console.log("certificate upgraded");
      this.dialogRef.close('confirm');
    } else if (this.data.hasOwnProperty('testStatusChange') && this.data.testStatusChange === true) {
      console.log(this.data);
      // const status = {
      //   'statusId': this.data.statusId, 'queueIds': this.data.queueId, 'locationId': ''
      // };
      const status = {
        'statusId': this.data.statusId,
        'reasonCode': this.confirmForm.controls['reasonCode'].value,
        'lastModifiedOn' : this.data.lastModifiedOn
      };

      this.dashboardService.updatePatientQueueStatus(this.data.queueId, status).subscribe(resQue => {
        if (resQue.statusCode === 1) {
          this.toastr.success('Success', `${resQue.message}`);
          this.dialogRef.close('Yes');
        }
      },
        error => {
          setTimeout(() => this.dialogRef.close('Yes'), environment.base_value.lockingTimeout);
          this.toastr.error('Error', `${error.error.message}`, { timeOut: environment.base_value.lockingTimeout });
        });

    } else if (this.data.hasOwnProperty('reviewDateInfo') && this.data.reviewDateInfo === true) {
      this.dashboardService.updatePatientQueueStatus(this.data.patientQueueId, this.data.updateReviewDate).subscribe(resDate => {
        if (resDate.statusCode === 1) {
          this.toastr.success('Success', `${resDate.message}`);
          this.dialogRef.close('Yes');
        }
      },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });
    } else if (this.data.hasOwnProperty('updateReportDate')&& this.data.updateReportDate !== null) {
      const data = {
        'patientId': this.data.updateReportDate.patientId,
        'patientVisitId': this.data.updateReportDate.patientVisitId,
        'reportCollectedDate': this.datepipe.transform(this.currentDate, 'yyyy-MM-dd HH:mm:ss'),
      }
      this.commonService.updateClinicalDetails(data).subscribe(resDate => {
        if (resDate.statusCode === 1) {
          this.toastr.success('Success', `${resDate.message}`);
          this.dialogRef.close('Yes');
        }
      },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });
    } else if (this.data.hasOwnProperty('isNewTestAdded') && this.data.isNewTestAdded === true) {
      console.log(this.data);

      const addTestDetails = {
        'patientId': this.data.patientId, 'patientVisitId': this.data.patientVisitId,
        'healthTestId': this.selectedId, 'healthTest': this.selectedTesttype
      };

      console.log(addTestDetails);
      // return;

      this.commonService.managePatientTest(addTestDetails).subscribe(resTest => {
        if (resTest.statusCode === 1) {
          this.toastr.success('Success', `${resTest.message}`);
          this.dialogRef.close('Yes');
        }
      },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });

    } else if (this.data.hasOwnProperty('activateRoom') && this.data.activateRoom === true) {

      this.commonService.updateHealthTestByFloorwise(this.data.locationStatusUpdate).subscribe(resFloor => {
        if (resFloor.statusCode === 1) {
          this.toastr.success('Success', `${resFloor.message}`);
          this.dialogRef.close('Yes');
        }
      },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });

    } else if (this.data.hasOwnProperty('enrollVisitType') && this.data.enrollVisitType === true) {
      this.dialogRef.close('Follow up');
    } else if (this.data.hasOwnProperty('cancelBilling') && this.data.cancelBilling === true) {
      console.log(this.data);

      const cancelBilling = {
        'patientId': this.data.patientId,
        'patientVisitId': this.data.patientVisitId,
        'tagId': this.data.tagId
      };

      console.log(cancelBilling);
      // return;

      this.commonService.cancleBilling(cancelBilling).subscribe(res => {
        if (res.statusCode === 1) {
          this.toastr.success('Success', `${res.message}`);
          this.dialogRef.close('confirm');
        }
      },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });
    } else if (this.data.hasOwnProperty('cancelAlert') && this.data.cancelAlert === true) {
      if(!this.data.hasOwnProperty('ipView') || this.data.hasOwnProperty('ipView') && this.data.ipView == null) {
      const cancelAlert = {
        'ids': [this.data.alertDetails.alertId],
        'comments': this.confirmForm.controls['comments'].value,
      };

      // console.log(cancelAlert);
      // return;

      this.commonService.cancelAlert(cancelAlert).subscribe(resAlert => {
        if (resAlert.statusCode === 1) {
          this.toastr.success('Success', `${resAlert.message}`);
          this.dialogRef.close('confirm');
        }
      },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });
      } else if(this.data.ipView == 'location') {
        this.ackAlert(false)
      }
    } else if (this.data.hasOwnProperty('discharge') && this.data.discharge === true) {
      const dischargeData = {
        'patientId': this.data.patientId,
        'patientVisitId': this.data.patientVisitId,
        'visitType': this.data.visitType
      };

      console.log(dischargeData);
      // return;

      this.commonService.dischargePatient(dischargeData).subscribe(res => {
        if (res.statusCode === 1) {
          this.toastr.success('Success', `${res.message}`);
          this.dialogRef.close('confirm');
        }
      },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });
    } else if (this.data.hasOwnProperty('assetStatus') && this.data.assetStatus === true) {
      const assetStatusUpdate = {
        'assetTransferTypeId': 'ATT-SV',
      };

      console.log(assetStatusUpdate);
      // return;

      this.commonService.assetStatusChange(assetStatusUpdate, this.data.assetId).subscribe(res => {
        if (res.statusCode === 1) {
          this.toastr.success('Success', `${res.message}`);
          this.dialogRef.close('confirm');
        }
      },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });
    } else if (this.data.hasOwnProperty('MRTagDisassociate') && this.data.MRTagDisassociate === true) {
      this.dialogRef.close(this.confirmButtonText);
    } else if (this.data.hasOwnProperty('porterReqStatusChange') && this.data.porterReqStatusChange === true) {
      this.dialogRef.close(this.confirmForm.controls.reasonCode.value);
    } else if (this.data.hasOwnProperty('MRReqStatusChange') && this.data.MRReqStatusChange === true) {
      this.dialogRef.close(this.confirmButtonText);
    } else if (this.data.hasOwnProperty('checkAvailablePorter') && this.data.checkAvailablePorter === true) {
      this.dialogRef.close(this.confirmButtonText);
    } else if (this.data.hasOwnProperty('disengageAlarm') && this.data.disengageAlarm === true) {
      let eventType = null;
      if(type === 'Authorized') {
        eventType = 'IET-AT'
      } else {
        eventType = 'IET-ATC'
      }
      const authorize = {
        "comments": this.confirmForm.controls['purpose'].value,
        "eventTypeId": eventType,
        "facilityId": localStorage.getItem(btoa('facilityId')),
        "identifyingType": this.durationCode,
        "identifyingValue": this.durationValue,
        "infantPatientId": this.data.infantData.id,
        "infantPatientVisitId": this.data.infantData.patientVisitId,
        "infantTagId": this.data.infantData.associatedTagSerialNumber,
        "parentPatientVisitId": this.data.infantData.parentVisitId,
        "userId": localStorage.getItem(btoa('userId'))
      }
      this.commonService.infantAuthorized(authorize).subscribe(res => {
        if (res.statusCode === 1) {
          this.dialogRef.close(this.confirmButtonText);
        } else {
          this.toastr.error('Error', `${res.message}`);
        }
      },
      error => {
        this.toastr.error('Error', `${error.error.message}`);
      });
    } else if(this.data.hasOwnProperty('dashboardLayout') && this.data.dashboardLayout === true){
        this.dialogRef.close(this.confirmButtonText);
    } else if(this.data.hasOwnProperty('formTempNameEnable') && this.data.formTempNameEnable === true){
      //confirmation from form builder for save as
      const formTemp = {
        'formTempName': this.confirmForm.controls['formTempName'].value,
        'confirmButtonText': this.confirmButtonText
      };
      this.dialogRef.close(formTemp);
    } else if(this.data.hasOwnProperty('formStatusEnable') && this.data.formStatusEnable === true){
      //confirmation from form save
      const formRemark = {
        'comments': this.confirmForm.controls['comments'].value,
        'confirmButtonText': this.confirmButtonText
      };
      this.dialogRef.close(formRemark);
    } else if(this.data.hasOwnProperty('manageAppterm') && this.data.manageAppterm === true){
      const apptermRemark = {
        'comments': this.confirmForm.controls['comments'].value,
        'confirmButtonText': this.confirmButtonText
      }
      this.dialogRef.close(apptermRemark);
    } else {
      const status = {
        'statusId': this.data.statusId, 'locationId': parseInt(this.data.locationId, 10),
        'reasonCode': this.confirmForm.controls['reasonCode'].value,
        'comments': this.confirmForm.controls['comments'].value, 'queueIds': this.data.queueIds, 'isParalelTestIncluded': this.data.isParalelTestIncluded,
        'reviewDate': this.datepipe.transform(this.confirmForm.controls['reviewDate'].value, 'yyyy-MM-dd HH:mm:ss')
      };
      // console.log(status);
      // return;
      this.dashboardService.updatePatientQueueStatus(this.data.queueId, status).subscribe(resStatus => {
        if (resStatus.statusCode === 1) {
          this.toastr.success('Success', `${resStatus.message}`);
          this.dialogRef.close('Yes');
        }
      },
        error => {
          this.toastr.error('Error', `${error.error.message}`);
        });
    }
  }

}
