/*******************************************************************************
 * ======================================================================================================
 *                                     Copyright (C) 2019 Trackerwave Pvt Ltd.
 *                                             All rights reserved
 * ======================================================================================================
 * Notice:  All Rights Reserved.
 * This material contains the trade secrets and confidential business information of Trackerwave Pvt Ltd,
 * which embody substantial creative effort, design, ideas and expressions.  No part of this material may
 * be reproduced or transmitted in any form or by any means, electronic, mechanical, optical or otherwise
 * ,including photocopying and recording, or in connection with any information storage or retrieval
 * system, without written permission.
 *
 * www.trackerwave.com, Traceability and Change log maintained in Source Code Control System}
 * ======================================================================================================
******************************************************************************/
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class WorkflowService {
  public env_key = environment.env_key;

  constructor(private apiService: ApiService) { }

  // Component: Asset
 

  // Component: Employee
  getEmployeeList(status, fromDate) {
    return this.apiService.get(environment.base_value.employee_controller + '?contactStatus=' + status + '&frmDate=' + fromDate);
  }



  // Component: Individual Record
  getPatientTestStatus(patientId) {
    return this.apiService.get('api/patient-visits/get-patient-test-status-list?patientId=' + patientId);
  }


  // Component: Health Checkup
 

  // Component: Infant


  // Component: InPatient
  getInpatientList(name, id, visitType?: string, dateFilter?: string, pageStart?: number, pageSize?: number) {
    // if (id !== 'All') {
    //   return this.apiService.get(environment.base_value.get_ip_patient_list + '?name=' + name + '&locationId=' + id);
    // } else if (visitType != null) {
    //   return this.apiService.get(environment.base_value.get_ip_patient_list + '?name=' + name + '&visitType=' + visitType);
    // } else {
    //   return this.apiService.get(environment.base_value.get_ip_patient_list + '?name=' + name);
    // }
    
    if (id !== 'All' && id !== null) {
      if(pageStart !== undefined && pageSize !== undefined) { 
        if(name !== null && name !== undefined && name !== '') {
          let ip_details = '&locationId=' + id + '&pageStart=' + pageStart + '&pageSize=' + pageSize;
          return this.apiService.get(environment.base_value.get_ip_patient_list + '?name=' + name + ip_details);
        } else {
          let ip_details = '?locationId=' + id + '&pageStart=' + pageStart + '&pageSize=' + pageSize;
          return this.apiService.get(environment.base_value.get_ip_patient_list + ip_details);
        }
      } else {
        if(name !== null && name !== undefined && name !== '') {
          return this.apiService.get(environment.base_value.get_ip_patient_list + '?name=' + name + '&locationId=' + id);
        } else {
          return this.apiService.get(environment.base_value.get_ip_patient_list + '?locationId=' + id);
        }
      }
    } else if (visitType != null && dateFilter != null) {
      if(name !== null && name !== undefined && name !== '') {
        let ip_details = '&visitType=' + visitType + '&visitDate=' + dateFilter;
        return this.apiService.get(environment.base_value.get_ip_patient_list + '?name=' + name + ip_details);
      } else {
        let ip_details = '?visitType=' + visitType + '&visitDate=' + dateFilter;
        return this.apiService.get(environment.base_value.get_ip_patient_list + ip_details);
      }
    } else if (visitType != null) {
      if(name !== null && name !== undefined && name !== '') {
        return this.apiService.get(environment.base_value.get_ip_patient_list + '?name=' + name + '&visitType=' + visitType);
      } else {
        return this.apiService.get(environment.base_value.get_ip_patient_list + '?visitType=' + visitType);
      }
    } else {
      if(pageStart !== undefined && pageSize !== undefined) { 
        if(name !== null && name !== undefined && name !== '') {
          let ip_details = '&pageStart=' + pageStart + '&pageSize=' + pageSize;
          return this.apiService.get(environment.base_value.get_ip_patient_list + '?name=' + name + ip_details);
        } else {
          let ip_details = '?pageStart=' + pageStart + '&pageSize=' + pageSize;
          return this.apiService.get(environment.base_value.get_ip_patient_list + ip_details);
        }
      } else {
        if(name !== null && name !== undefined && name !== '') {
          return this.apiService.get(environment.base_value.get_ip_patient_list + '?name=' + name);
        } else {
          return this.apiService.get(environment.base_value.get_ip_patient_list);
        }
      }
    }
  }

  // Component: Staff Routine

  // Component: OutPatient
  getOpPatientList(date) {
    return this.apiService.get(environment.base_value.get_op_patients_list + '?fromDate=' + date);
  }

  // Component: Porter


  // Component: Temporary Id Card

  // Component: Visitors
  getVisitorsList(date) {
    return this.apiService.get(environment.base_value.visitor_controller + '?fromDate=' + date + '&toDate=' + date);
  }

  // Component: Medical Record
  

  // Component: Task
  getTaskDetails(date, isCurrentUser, isRole, isCurrentTeam, isManageTeam, pageStart, pageSize, entityType?, myTaskfilterType?) {
    let get_task_details = '&startDate=' + date;

    if (isCurrentUser) {
      if(pageStart !== undefined && pageSize !== undefined) {
        // get_task_details = '?isCurrentUser=' + isCurrentUser + '&pageStart=' + pageStart + '&pageSize=' + pageSize + get_task_details;
        get_task_details = '?isCurrentUser=' + isCurrentUser + get_task_details;
      } else {
      get_task_details = '?isCurrentUser=' + isCurrentUser + get_task_details;
      }
      if(myTaskfilterType !== undefined && myTaskfilterType) {
        get_task_details = get_task_details + '&taskFilterTypeId=' + myTaskfilterType
      }
    }

    if (isRole) {
      if(pageStart !== undefined && pageSize !== undefined) {
        get_task_details = '?isCurrentUser=' + isCurrentUser + '&pageStart=' + pageStart + '&pageSize=' + pageSize + get_task_details;
      } else {
        get_task_details = '?isRole=' + isRole + get_task_details;
      }
    }

    if (isCurrentTeam) {
      get_task_details = '?isCurrentTeam=' + isCurrentTeam + get_task_details;
    }

    if (isManageTeam) {
      get_task_details = '?isManageTeam=' + isManageTeam + get_task_details;
    }
    if (entityType) {
      get_task_details = '?entityType=' + entityType + get_task_details;
    }
    return this.apiService.get(environment.base_value.task_details + get_task_details);
   }
 
}
