import { Component, OnInit, Inject, OnDestroy, Optional } from '@angular/core';
import * as L from 'leaflet';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeoSearchControl, OpenStreetMapProvider, GoogleProvider } from 'leaflet-geosearch';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonService } from '../../../services/common.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { connect } from 'mqtt';
import { AgmInfoWindow} from '@agm/core/lib/directives/info-window';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit {
  public map: any;
  public fixed_latlong = [];
  public current_latlong = [];
  public icon1 = 'assets/Alert/common_icons/map-icon.svg';
  public icon2 = 'assets/Alert/common_icons/icon2.svg';
  public ambulance3Icon = 'assets/Alert/common_icons/red-ambulance.png';
  // public ambulance1Icon = 'assets/Alert/common_icons/red-ambulance-new.png';
  // public ambulance2Icon = 'assets/Alert/common_icons/green-ambulance.png';
  public redAmbulance = 'assets/Alert/common_icons/circle-ambulance-red.png';
  public greenAmbulance = 'assets/Alert/common_icons/circle-ambulance-green.png';
  public hospitalIcon = 'assets/Alert/common_icons/hospital.png';
  public commitLatLong = [];
  public ambulanceLat: any;
  public ambulanceLong: any;
  public selectLatLng: any;
  public selectCircle: any;
  public title: string;
  public threshold: string;
  searchLatLang: any;
  searchText = null;
  popWidth: number;
  popHeight: number;
  contentHeight: number;
  searchedLatLng = null;
  ambulance: any;
  availableAmbulance: any=[];
  public googleKey: any = [];
  formattedAddress = null;
  searchControl: any;
  constructor(@Optional() public thisDialogRef: MatDialogRef<GoogleMapComponent>, 
  @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.getConfigFile('google').subscribe(res => {
      if (res.results != null) {
        this.googleKey = res.results.contentObject['map']['API_KEY'];
        localStorage.setItem('API_KEY', res.results.contentObject['API_KEY']);
      }
    });
    setTimeout(() => this.loadMap(), 300);
    setTimeout(() => [window.dispatchEvent(new Event('resize')), this.map.invalidateSize()], 400);
    // this.loadMap();
    // window.dispatchEvent(new Event('resize'));
    // this.map.invalidateSize();
  }
  loadMap() {
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
    let tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 18,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    });

    if (this.data && this.data["ambulanceLatlong"] !== null) {
      // let latLong = this.data["ambulanceLatlong"].split(',');
      this.ambulanceLat = this.data.ambulanceLatlong.lat;
      this.ambulanceLong = this.data.ambulanceLatlong.lng;
    }
    if(this.data && this.data.ambulanceLatlong !== null) {
      this.map = L.map('locationMap', {
        center: new L.LatLng(this.ambulanceLat, this.ambulanceLong),
        zoom: 3,
        layers: [tiles],
        zoomControl: true, attributionControl: false
      });
    } else {
      this.map = L.map('locationMap', {
        center: new L.LatLng(45.78771148665357, 15.967683792394526),
        zoom: 3,
        layers: [tiles],
        zoomControl: true, attributionControl: false
      });
    }
    // this.map.setView([28.727926, 77.143147], 15);
    // return
    if (this.data && this.data["type"] == "latlong") {
      if (this.data["threshold"] != null) {
        this.threshold = this.data["threshold"].toString()
      } else {
        this.threshold = this.data["threshold"];
      }
      if (this.data["data"] != null) {

        let location = this.data["data"].split(',');
        this.commitLatLong = [parseFloat(location[0]), parseFloat(location[1])];
        const icon = L.icon({
          iconUrl: this.icon2,
          iconSize: [20, 20], iconAnchor: [10, 10]
        });
        this.selectLatLng = L.marker(this.commitLatLong).addTo(this.map);
        this.selectLatLng.setIcon(icon);
        this.map.setView(this.commitLatLong, 15);
        if (this.threshold != null) {
          this.selectCircle = L.circle(this.commitLatLong, {
            color: "red",
            fillColor: "#f03",
            fillOpacity: 0.5,
            radius: parseFloat(this.threshold)
          }).addTo(this.map);
        }
      } else {
        this.map.setView([this.ambulanceLat, this.ambulanceLong], 12);
      }
      this.map.on('click', this.getLatLong.bind(this, 'd') );
      this.mapLatLong(this.data.searchText);
      if(this.data.hasOwnProperty('searchLatLng') && this.data.searchLatLng !== null) {
        this.searchedLatLng = this.data.searchLatLng;
        this.getLatLong(null, this.data.searchLatLng);
      }
    } else {
      const iconAmbGreen = L.icon({
        iconUrl: this.greenAmbulance,
        iconSize: [45, 45], iconAnchor: [10, 10],
      });
      const iconAmbRed = L.icon({
        iconUrl: this.redAmbulance,
        iconSize: [45, 45], iconAnchor: [10, 10],
      });
      // this.commonService.getGeoAmbulanceLocation().subscribe(res => {
      //   if(res.results.length !== 0) {
      //     const availableAmbulance = res.results;
      //     for(let i = 0 ; i< availableAmbulance.length; i++) {
      //       const msg = availableAmbulance[i].assetName;
      //       this.availableAmbulance.push([availableAmbulance[i].latitude,availableAmbulance[i].longitude]);
      //       this.ambulance = L.marker(this.availableAmbulance[i]).addTo(this.map);
      //       if(this.availableAmbulance[i].isBusy === true) {
      //         this.ambulance.setIcon(iconAmbRed);
      //       } else {
      //         this.ambulance.setIcon(iconAmbGreen);
      //       }
      //       this.map.setView(this.availableAmbulance[i], 18);
      //       this.ambulance.on('mouseover',e => {
      //           const popup = L.popup({autoClose: true})
      //           .setLatLng(this.availableAmbulance[i])
      //           .setContent(msg)
      //           .openOn(this.map);
      //       });
      //       this.ambulance.on('mouseout',e => {
      //           this.map.closePopup();
      //       });
      //     }
      //   }
      // });
      // this.covidService.getPatientLocation(this.data["data"]["userId"]).subscribe(res => {
      //   // console.log(res.results)
      //   // console.log(res.results["location"])
      //   if (res.results[0].latitude != null && res.results[0].longitude) {
      //     this.current_latlong = [parseFloat(res.results[0].latitude), parseFloat(res.results[0].longitude)];
      //   }
      //   if (res.results[0].location) {
      //     let location = res.results[0].location.split(',')
      //     // console.log(location)
      //     this.fixed_latlong = [parseFloat(location[0]), parseFloat(location[1])];
      //   }

      //   this.getLocationDetail(res.results[0].boundaryLimit);
      // })

    }
  }
  getLocationDetail(threshold) {

    if (this.fixed_latlong.length > 0) {
      let greenIcon1 = '';
      if (this.data["data"]["alertType"] == null) {
        greenIcon1 = L.icon({
          iconUrl: 'assets/AlertIcons/safezone.svg',
          iconSize: [20, 20], iconAnchor: [10, 10]
        });
      } else {
        greenIcon1 = L.icon({
          iconUrl: 'assets/AlertIcons/' + this.data["data"]["alertType"] + '.svg',
          iconSize: [20, 20], iconAnchor: [10, 10]
        });
      }
      let sourceMarker1 = L.marker(this.fixed_latlong).addTo(this.map);
      sourceMarker1.bindPopup("<b>Name :</b>" + this.data["data"]["firstName"] + "<br> <b>Mobile No :</b>" + this.data["data"]["mobileNumber"] + "<br> <b>LatLong :</b>" + this.data["data"]["location"]);
      sourceMarker1.setIcon(greenIcon1);
      this.map.setView(this.fixed_latlong, 15);
      // this.covidService.getConfigFile("alert-threshold").subscribe(result => {
      if (threshold != null) {
        this.selectCircle = L.circle(this.fixed_latlong, {
          color: "red",
          fillColor: "#f03",
          fillOpacity: 0.5,
          radius: parseFloat(threshold)
        }).addTo(this.map);
      }

      // });
    }

    if (this.current_latlong.length > 0) {
      const greenIcon2 = L.icon({
        iconUrl: this.icon2,
        iconSize: [20, 20], iconAnchor: [10, 10]
      });
      let sourceMarker2 = L.marker(this.current_latlong).addTo(this.map);
      sourceMarker2.bindPopup("<b>LatLong :</b>" + this.current_latlong[0].toString() + ',' + this.current_latlong[1].toString());
      sourceMarker2.setIcon(greenIcon2);
      this.map.setView(this.current_latlong, 15);
    }
  }
  mapLatLong(value ?: string) {
    // console.log(value, 'checking inside function....');
    const provider = new GoogleProvider({
      params: {
        key: this.googleKey,
      },
    });
    if(this.data.searchText !== null && this.data.searchText !== '' && this.searchedLatLng === null) {
      const result = provider.search({ query: value });
      result.then(e => {
        if(e.length !== 0) {
          this.searchLatLang = {'latlng':{'lat': e[0].y,'lng': e[0].x, 'label': e[0].label}}
        }
        if(this.searchLatLang){
          this.getLatLong(null, this.searchLatLang );
        }
      });
    }
    this.searchControl = new GeoSearchControl({
      provider: provider,
      autoclose: true
    });
    this.map.addControl(this.searchControl);
    this.map.on('geosearch/showlocation', e => {
      this.map.removeLayer(this.selectLatLng);
      this.map.removeLayer(this.selectCircle);
      this.commitLatLong = [e.location.y, e.location.x];
      this.searchLatLang = {'latlng':{'lat': e.location.y,'lng': e.location.x, 'label': e.location.label}}
      this.formattedAddress = e.location.label;
      // const icon = L.icon({
      //   iconUrl: this.icon1,
      //   iconSize: [30, 30], iconAnchor: [10, 10],
      // });
      this.selectLatLng = L.marker(this.commitLatLong).addTo(this.map);
      // this.selectLatLng.setIcon(icon);
      this.map.setView(this.commitLatLong, 15);
      this.selectCircle = L.circle(this.commitLatLong, {
        color: "red",
        fillColor: "#f03",
        fillOpacity: 0.5,
        radius: parseFloat(this.threshold)
      }).addTo(this.map);
    });
    // this.map.on('geosearch/marker/dragend', function(e) { console.log(e);
    // });
  }

  getLatLong(data1, data) {
    this.searchControl.getContainer().onclick = e => { e.stopPropagation(); };
    if (data1 === 'd') {
      const latlng = [data.latlng.lat, data.latlng.lng];
      const provider = new GoogleProvider({
        params: {
          key: this.googleKey,
          latlng: latlng,
        },
      });
      const result = provider.search({ query: '' });
      result.then(e => {
        if(e.length !== 0) {
          this.formattedAddress = e[0].raw.formatted_address;
        } else {
          this.formattedAddress = null;
        }
      });
    } else {
      const provider = new GoogleProvider({
        params: {
          key: this.googleKey,
        },
      });
      const result = provider.search({ query: this.data.searchText });
      result.then(e => {
        if(e.length !== 0) {
          this.formattedAddress = e[0].raw.formatted_address;
        } else {
          this.formattedAddress = null;
        }
      });
    }
    if (this.commitLatLong.length > 0) {
      this.map.removeLayer(this.selectLatLng);
    }
    if (this.threshold != null && this.commitLatLong.length > 0) {
      this.map.removeLayer(this.selectCircle);
    }
    this.commitLatLong = [data.latlng.lat, data.latlng.lng];
    // if(data.originalEvent) {
    //   this.searchText = data.originalEvent.target.outerText.replace('+', '').replace('−', '').replace('\n', '').replace('X', '');
    // }
    if(data.type !== 'click') {
      this.searchLatLang = {'latlng':{'lat': data.latlng.lat,'lng': data.latlng.lng, 
      'label': this.searchText !== null ? this.searchText : data.latlng.label}};
    } else {
      this.searchLatLang = {'latlng':{'lat': data.latlng.lat,'lng': data.latlng.lng, 
      'label': this.searchText !== null ? this.searchText : 
      (this.searchLatLang ? this.searchLatLang.latlng.label : null)}};
    }
    const icon = L.icon({
      iconUrl: this.icon1,
      iconSize: [30, 30], iconAnchor: [10, 10],
    });
    const iconAmbGreen = L.icon({
      iconUrl: this.greenAmbulance,
      iconSize: [45, 45], iconAnchor: [10, 10],
    });
    const iconAmbRed = L.icon({
      iconUrl: this.redAmbulance,
      iconSize: [45, 45], iconAnchor: [10, 10],
    });
    this.selectLatLng = L.marker(this.commitLatLong).addTo(this.map);
    this.selectLatLng.setIcon(icon);
    this.map.setView(this.commitLatLong, 15);
    if(data.type !== 'click' && this.searchedLatLng === null
    && this.searchLatLang && this.searchLatLang.latlng.label !== null &&
     this.searchLatLang.latlng.label !== ''){
      const popup = L.popup({autoClose: false})
          .setLatLng(this.commitLatLong)
          .setContent(this.searchLatLang.latlng.label)
          .openOn(this.map);
    }
    if (this.threshold != null) {
      this.selectCircle = L.circle(this.commitLatLong, {
        color: "red",
        fillColor: "#f03",
        fillOpacity: 0.5,
        radius: parseFloat(this.threshold)
      }).addTo(this.map);
    }
    if(this.data.availableAmbulance) {
      for(let i = 0 ; i< this.data.availableAmbulance.length; i++) {
        const msg = this.data.availableAmbulance[i].assetName;
        this.availableAmbulance.push([this.data.availableAmbulance[i].latitude,this.data.availableAmbulance[i].longitude]);
        this.ambulance = L.marker(this.availableAmbulance[i]).addTo(this.map);
        if(this.availableAmbulance[i].isBusy === true) {
          this.ambulance.setIcon(iconAmbRed);
        } else {
          this.ambulance.setIcon(iconAmbGreen);
        }
        this.ambulance.on('mouseover',e => {
            const popup = L.popup({autoClose: true})
            .setLatLng(this.availableAmbulance[i])
            .setContent(msg)
            .openOn(this.map);
        });
        this.ambulance.on('mouseout',e => {
            this.map.closePopup();
        });
      }
    }
  }

  commitLatLongClose(data) {
    if(data) {
      data['formattedAddress'] = this.formattedAddress;
    }
    this.thisDialogRef.close(data);
  }

  onWindowResizedWidth(size) {
    if(this.map) {
      this.map.invalidateSize();
    }
    this.popWidth = size - 50;
  }
  onWindowResized(size) {
    if(this.map) {
      this.map.invalidateSize();
    }
    this.popHeight = size;
    if(size >= 1218) {
      this.contentHeight = size - 150;
    } else {
      this.contentHeight = size - 140;
    }
  }
}

@Component({
  selector: 'app-google-map-direction',
  templateUrl: './google-map-direction.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapDirectionComponent  implements OnInit {
  urlSafe: SafeResourceUrl;  
  popWidth: number;
  popHeight: number;
  contentHeight: number;
  trackLocation: any;

  constructor(public thisDialogRef: MatDialogRef<GoogleMapComponent>, @Inject(MAT_DIALOG_DATA) public data: any, 
  public sanitizer: DomSanitizer, private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.getConfigFile('google').subscribe(res => {
      if (res.results != null) {
        this.trackLocation += '?key=' + res.results.contentObject['API_KEY'];
        localStorage.setItem('API_KEY', res.results.contentObject['API_KEY']);
      }
    });
    if (this.data.googleDirectioAPI !== null) {
      this.trackLocation = this.data.googleDirectioAPI;
    }
    // if (this.data.key !== null) {
    //   this.trackLocation += '?key=' + this.data.key;
    // }
    if (this.data.origin !== null && this.data.origin !== '') {
      this.trackLocation += '&origin=' + this.data.origin;
    }
    if (this.data.destination !== null && this.data.destination !== '') {
      this.trackLocation += '&destination=' + this.data.destination;
    }
    if (this.data.waypoints !== null && this.data.waypoints !== '') {
      this.trackLocation += '&waypoints=' + this.data.waypoints;
    }
    if (this.data.origin !== null && this.data.origin !== '' && this.data.destination !== null && this.data.destination !== '') {
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.trackLocation + '&avoid=tolls|highways');
    }
  }

  onWindowResizedWidth(size) {
    this.popWidth = size - 60;
  }
  onWindowResized(size) {
    this.popHeight = size;
    if(size >= 1218) {
      this.contentHeight = size - 150;
    } else {
      this.contentHeight = size - 140;
    }
  }
}
@Component({
  selector: 'app-google-map-marker',
  templateUrl: './google-map-marker.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapMarkerComponent implements OnInit, OnDestroy{
  // zoom: number = 8;
  lat: number;
  lng: number;
  popWidth: number;
  popHeight: any;
  contentHeight: number;
  markers = [];
  originLat: number;
  originLng: number;
  public ambulanceIcon = 'assets/Alert/common_icons/small-circle-ambulance-green.png';
  // public ambulanceIcon = 'assets/Alert/common_icons/ambulance-marker.png';
  public hospitalIcon = 'assets/Alert/common_icons/hospital-marker.png';
  public patientIcon = 'assets/Alert/common_icons/patient-marker.png';
  tagId = null;
  googleMapWayPoints = null;
  public subscription: Subscription;
  interval: any;
  waypointsLat: number;
  waypointsLng: number;
  waypoints: { location: { lat: number; lng: number; }; stopover: boolean; }[];
  renderOptions: any;
  markerOptions = null;
  client: any;
  public infoWindow: AgmInfoWindow = undefined
  set1: any=[];
  set2: any=[];
  directions=[];
  getSets = false;
  toggleMap = true;
  googleKey = null;

  constructor(public thisDialogRef: MatDialogRef<GoogleMapComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  private commonService: CommonService, public datepipe : DatePipe) { }

  ngOnInit() {
    this.commonService.getConfigFile('google').subscribe(res => {
      if (res.results != null) {
        this.googleKey = res.results.contentObject['map']['API_KEY'];
        localStorage.setItem('API_KEY', res.results.contentObject['API_KEY']);
      }
    });
    this.tagId = this.data.tagId;

    const loc = this.data.destination.split(',');
    this.lat = parseFloat(loc[0]);
    this.lng = parseFloat(loc[1]);

    const origin = this.data.waypoints.split(',');
    this.originLat = parseFloat(origin[0]);
    this.originLng = parseFloat(origin[1]);

    const waypoints = this.data.origin.split(',');
    this.waypointsLat = parseFloat(waypoints[0]);
    this.waypointsLng = parseFloat(waypoints[1]);
    this.waypoints = [
      {
        location: { lat: this.waypointsLat, lng: this.waypointsLng },
        stopover: false,
      }
    ]

    this.markerOptions = {
      origin: {
          icon: this.ambulanceIcon,
      },
      waypoints: {
          icon: this.patientIcon,
      },
      destination: {
          icon: this.hospitalIcon,
      }
    }
    this.markers = [
      {
        lat: this.waypointsLat,
        lng: this.waypointsLng,
        draggable: false,
        url: this.patientIcon
      }
    ]
    if(this.data.reqStatus === 'RQ-CO' || this.data.reqStatus === 'RQ-RJ') {
      const loc = this.data.destination.split(',');
      this.lat = parseFloat(loc[0]);
      this.lng = parseFloat(loc[1]);

      const origin = this.data.origin.split(',');
      this.originLat = parseFloat(origin[0]);
      this.originLng = parseFloat(origin[1]);

      const waypoints = this.data.destination.split(',');
      this.waypointsLat = parseFloat(waypoints[0]);
      this.waypointsLng = parseFloat(waypoints[1]);

      let points = this.data.reqDetail.Distance !== null && this.data.reqDetail.Distance !== 0 ? this.data.reqDetail.Distance * 20 : 20;
      let actualTime = this.datepipe.transform(this.data.reqDetail.actualTime, 'yyyy-MM-dd HH:mm');
      let actualDropTime = this.datepipe.transform(this.data.reqDetail.actualDropTime, 'yyyy-MM-dd HH:mm');
      let arrivalTime = this.datepipe.transform(this.data.reqDetail.arrivalTime, 'yyyy-MM-dd HH:mm');
      // this.commonService.getGeoPath(actualTime, actualDropTime, this.data.reqDetail.ambulanceId, arrivalTime, points).subscribe(res=> {
      //   if(res.results.statusCode === 200) {
      //     this.getSets = true;
      //     this.waypoints = [];
      //     this.set1 = [];
      //     const nodes = res.results.data.set1;
      //     // this.set1.push({location: { lat: this.originLat, lng: this.originLng},stopover: false});
      //     for(let i=0; i<nodes.length; i++) {
      //       if(nodes.length !== 0) {
      //         this.set1.push({
      //         location: { lat: nodes[i].lat, lng: nodes[i].lng },
      //         stopover: false,
      //         });
      //       }
      //     }
      //     // this.set1.push({location: { lat: this.lat, lng: this.lng},stopover: false});
      //     this.set2 = [];
      //     const nodes1 = res.results.data.set2;
      //     this.set2.push({location: { lat: this.originLat, lng: this.originLng},stopover: false});
      //     for(let i=0; i<nodes1.length; i++) {
      //       if(nodes1.length !== 0) {
      //         this.set2.push({
      //         location: { lat: nodes1[i].lat, lng: nodes1[i].lng },
      //         stopover: false,
      //         });
      //       }
      //     }
      //     this.set2.push({location: { lat: this.lat, lng: this.lng},stopover: false});
      //     this.directions=[{
      //       waypoints: this.set1
      //     },
      //     {
      //     waypoints: this.set2
      //     }
      //   ]
      //   } else {
      //     this.getSets = false;
      //     this.waypoints = [
      //       {
      //         location: { lat: this.waypointsLat, lng: this.waypointsLng },
      //         stopover: false,
      //       }
      //     ],
      //     this.directions= [{
      //       waypoints: this.waypoints,
      //       renderOptions: {
      //         suppressMarkers: true,
      //         preserveViewport: true,
      //         polylineOptions: { strokeColor: '#808080' }
      //       }
      //     }]
      //   }
      // });

      // this.waypoints = [
      //   {
      //     location: { lat: this.waypointsLat, lng: this.waypointsLng },
      //     stopover: false,
      //   }
      // ]
      this.markerOptions = {
        origin: {
            icon: this.patientIcon,
            infoWindow : 
            "<div style='font-weight:600;'>Pickup Details : </div><br>" + 
            "<div>Time : " + this.datepipe.transform(this.data.reqDetail["actualPickupTime"], 'hh:mm a, d MMM') +"</div>" +
            "<div>Location : " + this.data.reqDetail["Pickup"] + "</div>" 
            // infoWindow: this.data.reqDetail["Pickup"] + '\n' + this.datepipe.transform(this.data.reqDetail["actualPickupTime"], 'hh:mm a, d MMM')

        },
        destination: {
            icon: this.hospitalIcon,
            infoWindow : 
            "<div style='font-weight:600;'>Drop Details : </div><br>" + 
            "<div>Time : " + this.datepipe.transform(this.data.reqDetail["actualDropTime"], 'hh:mm a, d MMM') +"</div>" +
            "<div>Location : " + this.data.reqDetail["Drop"] + "</div>"
            // infoWindow: this.data.reqDetail["Drop"] + '\n' + this.datepipe.transform(this.data.reqDetail["actualDropTime"], 'hh:mm a, d MMM')
        }
      }
      this.markers = [];
    }
    
    if(this.data.reqStatus !== 'RQ-CO') {
      this.renderOptions = {
        suppressMarkers: true,
        preserveViewport: true,
      }
    }
    // if(this.data.reqStatus === 'RQ-IP' || this.data.reqStatus === 'RQ-CO') {
    //   const loc = this.data.destination.split(',');
    //   this.lat = parseFloat(loc[0]);
    //   this.lng = parseFloat(loc[1]);
  
    //   const origin = this.data.waypoints.split(',');
    //   this.originLat = parseFloat(origin[0]);
    //   this.originLng = parseFloat(origin[1]);
  
    //   const waypoints = this.data.destination.split(',');
    //   this.waypointsLat = parseFloat(waypoints[0]);
    //   this.waypointsLng = parseFloat(waypoints[1]);
    //   this.waypoints = [
    //     {
    //       location: { lat: this.waypointsLat, lng: this.waypointsLng },
    //       stopover: false,
    //     }
    //   ]
    //   this.markerOptions = {
    //     origin: {
    //         icon: this.ambulanceIcon,
    //     },
    //     destination: {
    //         icon: this.hospitalIcon,
    //     }
    //   }
    // const iconLoc = this.data.origin.split(',');
    // this.markers = [
    //   {
    //     lat: parseFloat(iconLoc[0]),
    //     lng: parseFloat(iconLoc[1]),
    //     draggable: false,
    //     url: this.patientIcon
    //   }
    // ]
    // } else {
    //   const loc = this.data.origin.split(',');
    //   this.lat = parseFloat(loc[0]);
    //   this.lng = parseFloat(loc[1]);
  
    //   const origin = this.data.waypoints.split(',');
    //   this.originLat = parseFloat(origin[0]);
    //   this.originLng = parseFloat(origin[1]);
  
    //   const waypoints = this.data.origin.split(',');
    //   this.waypointsLat = parseFloat(waypoints[0]);
    //   this.waypointsLng = parseFloat(waypoints[1]);
    //   this.waypoints = [
    //     {
    //       location: { lat: this.waypointsLat, lng: this.waypointsLng },
    //       stopover: false,
    //     }
    //   ]
    //   this.markerOptions = {
    //     origin: {
    //         icon: this.ambulanceIcon,
    //     },
    //     destination: {
    //       icon: this.patientIcon,
    //     }
    //   }
    //   const iconLoc = this.data.destination.split(',');
    //   this.markers = [
    //     {
    //       lat: parseFloat(iconLoc[0]),
    //       lng: parseFloat(iconLoc[1]),
    //       draggable: false,
    //       url: this.hospitalIcon
    //     }
    //   ]
    // }
    // this.getGoogleDirectionMultiPath();
    if(this.data.reqStatus !== 'RQ-CO' && this.data.reqStatus !== 'RQ-RJ') {
      this.getMqtt();
    }
  }
  
  getMqtt() {
    if(this.client) {
        this.client.end(true);
    }
    this.commonService.getmqttBroker().subscribe(res=> {
        if (res.results != null && res.results.brokerInfo.length) {
            let cloudConnect = {
                protocol        : res.results.brokerInfo[0]['protocol'],
                host            : res.results.brokerInfo[0]['host'],
                password        : res.results.brokerInfo[0]['password'],
                username        : res.results.brokerInfo[0]['username'],
                connectTimeout  : 30000,
                port            : 8883,
                keepalive       : 60
            }
            this.client = connect(cloudConnect);
            this.subscribeData()
        } else {
            res.message = 'mqtt ' + res.message;
        }
    })
  }
  subscribeData(){
    if(this.client) {
        this.client.subscribe('tw/r/gloc/#');
        this.client.on('message', (topic, message, packet) => {
              let msg = message.toString();
              let tagData = eval('[' + msg + ']')
              tagData = tagData[0]
              if(tagData.tag_id === this.tagId) {
                if(tagData.lat.toFixed(3) !== this.originLat.toFixed(3) || tagData.lng.toFixed(3) !== this.originLng.toFixed(3)) {
                  this.originLat = tagData.lat;
                  this.originLng = tagData.lng;
                }
              }
        });
    }
  }
  checkInterval() {
    clearInterval(this.interval);
    this.interval = setInterval(val => this.getGoogleDirectionMultiPath(), environment.base_value.set_geo_loc_interval);
  }
  ngOnDestroy(): void {
    if(this.client) {
      this.client.end(true);
    }
    clearInterval(this.interval);
  }
  getGoogleDirectionMultiPath() {
    this.checkInterval();
    if(this.tagId !== null) {
      // this.commonService.getGeoLocation(this.tagId).subscribe(res => {
      //   if(res.results.data.length !== 0) {
      //     const latLng = res.results.data;
      //     const geo = latLng[0];
      //     this.googleMapWayPoints = geo.lat + ',' + geo.lng;
      //     if(this.googleMapWayPoints !== null) {
      //       const origin = this.googleMapWayPoints.split(',');
      //       this.originLat = parseFloat(origin[0]);
      //       this.originLng = parseFloat(origin[1]);
      //     }
      //   }
      // });
    }
  }
  onWindowResizedWidth(size) {
    this.popWidth = size - 60;
  }
  onWindowResized(size) {
    this.popHeight = size;
    if(size >= 1218) {
      this.contentHeight = size - 150;
    } else {
      this.contentHeight = size - 140;
    }
  }
}